import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../nodeIds';
import * as answerPaths from './paths';

export const insuredPeople: AnswerPathTreeNode = {
  path: 'insuredPeople',
  isCollection: true,
  nodeId: NodeIds.insuredPeople,
  children: [
    answerPaths.surrogateId,
    answerPaths.title,
    answerPaths.firstName,
    answerPaths.middleName,
    answerPaths.lastName,
    answerPaths.suffix,
    answerPaths.gender,
    answerPaths.dateOfBirth,
    answerPaths.currentAge,
    answerPaths.isSmoker,
    answerPaths.englishOrFrench,
    answerPaths.height,
    answerPaths.weight,
    answerPaths.weightChange,
    answerPaths.weightChangeLbs,
    answerPaths.weightGainLoss,
    answerPaths.weightChangeReason,
    answerPaths.heightUnit,
    answerPaths.weightUnit,
    answerPaths.sin,
    answerPaths.ssn,
    answerPaths.occupation,
    answerPaths.occupationOther,
    answerPaths.isFraternalMember,
    answerPaths.disability,
    answerPaths.drugsIllicit,
    answerPaths.employmentStatus,
    answerPaths.isCurrentlyActiveDutyMilitary,
    answerPaths.employer,
    answerPaths.birthLocation,
    answerPaths.citizenship,
    answerPaths.identification,
    answerPaths.insuredConfirmName,
    answerPaths.identityVerification,
    answerPaths.retirement,
    answerPaths.contactInformation,
    answerPaths.address,
    answerPaths.addressAutocomplete,
    answerPaths.legalGuardian,
    answerPaths.spouse,
    answerPaths.maritalStatus,
    answerPaths.numberOfChildren,
    answerPaths.children,
    answerPaths.child,
    answerPaths.pastChild,
    answerPaths.replacementInsurance,
    answerPaths.secondary,
    answerPaths.financialNeeds,
    answerPaths.personalMedicalQuestionnaire,
    answerPaths.otherLifeInsurancePolicies,
    answerPaths.liabilities,
    answerPaths.assets,
    answerPaths.coverageDuration,
    answerPaths.admissibility,
    answerPaths.previousName,
    answerPaths.responsibilityConfirmation,
    answerPaths.rejectedInsuranceApplication,
    answerPaths.health,
    answerPaths.alcoholUse,
    answerPaths.lifestyle,
    answerPaths.consent,
    answerPaths.previousApplication,
    answerPaths.membershipSurveyChoice,
    answerPaths.educationFund,
    answerPaths.familyIncome,
    answerPaths.insuredTotalLifeCoverageExcludingWorkGroupBenefits,
    answerPaths.netWorth,
    answerPaths.bankruptcyDate,
    answerPaths.bankruptcy,
    answerPaths.insuredAgreement,
    answerPaths.insuredAgreementDetails,
    answerPaths.insuredWorkCondition,
    answerPaths.needs,
    answerPaths.beneficiaries,
    answerPaths.mainBeneficiaries,
    answerPaths.contingentBeneficiaries,
    answerPaths.trust,
    answerPaths.product,
    answerPaths.vendor,
    answerPaths.pad,
    answerPaths.payment,
    answerPaths.owner,
    answerPaths.secondOwner,
    answerPaths.informationField,
    answerPaths.other,
    answerPaths.cancellingAndReplacement,
    answerPaths.producerReport,
    answerPaths.policy,
    answerPaths.sportAmusementOrAvocation,
    answerPaths.marijuanaUse,
    answerPaths.aviationSection,
    answerPaths.criminalHistory,
    answerPaths.medProvider,
    answerPaths.aviation,
    answerPaths.cancer,
    answerPaths.drugAlcohol,
    answerPaths.drug,
    answerPaths.alcohol,
    answerPaths.military,
    answerPaths.respiratory,
    answerPaths.asthma,
    answerPaths.copd,
    answerPaths.emphysema,
    answerPaths.criminalOffenseDetails,
    answerPaths.pastTravelWithin2Years,
    answerPaths.futureTravelWithin2Years,
    answerPaths.drugUse,
    answerPaths.temp,
    answerPaths.healthInsured,
    answerPaths.insuredSiblings,
    answerPaths.insuredParentSiblings,
    answerPaths.lifestyleInsured,
    answerPaths.insuredFinancialActivity,
    answerPaths.investment,
  ],
};

import _ from 'lodash';

import {
  formatFormulaVariableName,
  futureDateCondition,
  lastIncidentDateCondition,
  maximumAgeCondition,
  minimumAgeCondition,
} from '@breathelife/condition-engine';
import { AnswerPath, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  AgeRangeConditionValue,
  AgeRoundingType,
  BlueprintCollectionOperator,
  BlueprintConditionValue,
  BlueprintSingleConditionValue,
  BodyMassIndexRangeConditionValue,
  BooleanOperator,
  CharacterCountInBetweenConditionValue,
  CollectionOperator,
  ComparisonOperator,
  Condition,
  ConditionBlueprintType,
  Conditions,
  CountEqualConditionValue,
  DateTimeReference,
  DateUnit,
  EmptinessConditionValue,
  EngineConditionValue,
  EqualityConditionValue,
  FutureDateConditionValue,
  InstancesCountConditionValue,
  isBlueprintConditionsValue,
  JointProductAgeRangeConditionValue,
  LastIncidentDateConditionValue,
  MatchesConditionPropertyQuantifier,
  MatchesConditionValue,
  MatchesRegexConditionValue,
  MathConditionOperator,
  MathOperatorConditionValue,
  NumberComparisonConditionOperator,
  NumberComparisonConditionValue,
  Query,
  QueryOperator,
  ReflexiveConditionValue,
  RepeatableSelection,
  ShiftMagnitude,
} from '@breathelife/types';

import { fetchExistingJointProductSurrogateId } from './fetchJointProductSurrogateId';

export class ConditionsBuilder {
  public static buildConditions(
    conditionValues: BlueprintConditionValue | undefined,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): Conditions | undefined {
    if (!conditionValues) {
      return undefined;
    }

    if (isBlueprintConditionsValue(conditionValues)) {
      const conditionsArray = conditionValues.conditions
        .map((condition) => this.buildConditions(condition, nodeIdToAnswerPathMap))
        .filter(Boolean) as Condition[];
      if (conditionValues?.booleanOperator) {
        return {
          conditions: conditionsArray,
          operator: this.toBooleanOperator(conditionValues.booleanOperator),
        };
      }
      return {
        conditions: conditionsArray,
      };
    }

    const condition = this.buildCondition(conditionValues as BlueprintSingleConditionValue, nodeIdToAnswerPathMap);
    const targetNodeIds = this.getTargetNodeIdsFromCondition(conditionValues);

    const ancestorCollectionNodeIds =
      targetNodeIds && targetNodeIds.length
        ? this.getAncestorCollectionNodeIds(targetNodeIds[0], nodeIdToAnswerPathMap)
        : [];
    const isCollectionCondition = !!ancestorCollectionNodeIds.length && !!conditionValues?.collectionOperators;

    if (isCollectionCondition) {
      const areTargetNodeIdsInTheSameCollection = targetNodeIds.every(
        (nodeId) =>
          this.getAncestorCollectionNodeIds(nodeId, nodeIdToAnswerPathMap)[0] === ancestorCollectionNodeIds[0],
      );

      if (!areTargetNodeIdsInTheSameCollection) {
        throw new Error('Target nodeIds must be part of the same collection');
      }

      // Collection ancestors should be put in a collection condition if they are not using the 'local' context.
      let collectionCondition = condition;
      for (const collectionNodeId of ancestorCollectionNodeIds) {
        const blueprintCollectionOperator = conditionValues?.collectionOperators?.[collectionNodeId];
        const collectionOperator = this.toCollectionOperator(blueprintCollectionOperator);

        if (collectionOperator && blueprintCollectionOperator !== BlueprintCollectionOperator.thisItem) {
          collectionCondition = {
            collection: collectionNodeId,
            collectionOperator,
            conditions: [collectionCondition],
          };
        }
      }

      return {
        conditions: [collectionCondition],
        operator: BooleanOperator.and,
      };
    }

    return {
      conditions: [condition],
      operator: BooleanOperator.and,
    };
  }

  private static buildCondition(
    conditionValue: BlueprintSingleConditionValue,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): Condition {
    switch (conditionValue.type) {
      case ConditionBlueprintType.ageRange: {
        return this.generateAgeRangeCondition(conditionValue);
      }
      case ConditionBlueprintType.bmiRange: {
        return this.generateBMIRangeCondition(conditionValue);
      }
      case ConditionBlueprintType.characterCountInBetween: {
        return this.generateCharacterCountInBetweenCondition(conditionValue);
      }
      case ConditionBlueprintType.countEqual: {
        return this.generateCountEqualCondition(conditionValue, nodeIdToAnswerPathMap);
      }
      case ConditionBlueprintType.dateComparison: {
        const { startDateNodeId, endDateNodeId, unit, value, operator } = conditionValue;

        return {
          query: {
            select: [endDateNodeId, startDateNodeId],
            operator: QueryOperator.subtractDates,
            operatorParams: {
              dateUnit: unit,
            },
          },
          operator: this.numberComparisonConditionOperatorToComparisonOperator(operator),
          value,
        };
      }
      case ConditionBlueprintType.emptiness: {
        const { targetNodeId, isEmpty } = conditionValue as EmptinessConditionValue;
        const comparisonOperator = isEmpty ? ComparisonOperator.isEmpty : ComparisonOperator.notEmpty;
        return {
          operator: comparisonOperator,
          nodeId: targetNodeId,
        };
      }
      case ConditionBlueprintType.engineCondition: {
        return this.generateEngineCondition(conditionValue);
      }
      case ConditionBlueprintType.equality: {
        const { targetNodeId, value, isEqual, nodeIdOfValue } = conditionValue as EqualityConditionValue;
        const comparisonOperator = isEqual ? ComparisonOperator.equal : ComparisonOperator.notEqual;
        return {
          operator: comparisonOperator,
          nodeId: targetNodeId,
          value: value,
          controlValue: nodeIdOfValue,
        };
      }
      case ConditionBlueprintType.futureDate: {
        return this.generateFutureDateCondition(conditionValue);
      }
      case ConditionBlueprintType.instancesCount: {
        return this.generateInstancesCountCondition(conditionValue);
      }

      case ConditionBlueprintType.jointProductAgeRange: {
        return this.generateJointProductAgeRangeCondition(conditionValue);
      }
      case ConditionBlueprintType.lastIncidentDate: {
        return this.generateLastIncidentDateCondition(conditionValue);
      }
      case ConditionBlueprintType.matches: {
        const { targetNodeId, value, quantifier } = conditionValue as MatchesConditionValue;

        let comparisonOperator: ComparisonOperator;
        switch (quantifier) {
          case MatchesConditionPropertyQuantifier.any:
            comparisonOperator = ComparisonOperator.matchesAny;
            break;
          case MatchesConditionPropertyQuantifier.none:
            comparisonOperator = ComparisonOperator.matchesNone;
            break;
        }

        return {
          operator: comparisonOperator,
          nodeId: targetNodeId,
          value,
        };
      }
      case ConditionBlueprintType.matchesRegex: {
        return this.generateMatchesRegexCondition(conditionValue);
      }
      case ConditionBlueprintType.mathOperator: {
        return this.generateMathOperatorCondition(conditionValue, nodeIdToAnswerPathMap);
      }
      case ConditionBlueprintType.numberComparison: {
        return this.generateNumberComparisonCondition(conditionValue, nodeIdToAnswerPathMap);
      }
      case ConditionBlueprintType.percentOf: {
        const { targetNodeId, value, nodeIdOfValue, operator, percent } = conditionValue;

        return {
          query: {
            select: [targetNodeId],
            operator: QueryOperator.multiply,
            operatorParams: {
              numeric: [percent / 100],
            },
          },
          operator: this.numberComparisonConditionOperatorToComparisonOperator(operator),
          ...(value ? { value } : { controlValue: nodeIdOfValue }),
        };
      }
      case ConditionBlueprintType.reflexive: {
        const { targetNodeId, value } = conditionValue as ReflexiveConditionValue;
        return {
          operator: ComparisonOperator.equal,
          nodeId: targetNodeId,
          value,
        };
      }
    }
  }

  private static getTargetNodeIdsFromCondition(conditionValues: BlueprintSingleConditionValue): string[] {
    switch (conditionValues.type) {
      case ConditionBlueprintType.ageRange:
        return [conditionValues.targetBirthdateNodeId];

      case ConditionBlueprintType.bmiRange:
        return [conditionValues.heightNodeId, conditionValues.weightNodeId];

      case ConditionBlueprintType.characterCountInBetween:
      case ConditionBlueprintType.countEqual:
      case ConditionBlueprintType.equality:
      case ConditionBlueprintType.emptiness:
      case ConditionBlueprintType.futureDate:
      case ConditionBlueprintType.lastIncidentDate:
      case ConditionBlueprintType.matches:
      case ConditionBlueprintType.matchesRegex:
      case ConditionBlueprintType.numberComparison:
      case ConditionBlueprintType.percentOf:
      case ConditionBlueprintType.reflexive:
        return [conditionValues.targetNodeId];

      case ConditionBlueprintType.dateComparison:
        return [conditionValues.startDateNodeId, conditionValues.endDateNodeId];

      case ConditionBlueprintType.mathOperator:
        return conditionValues.nodeIds;

      case ConditionBlueprintType.engineCondition:
      case ConditionBlueprintType.instancesCount:
      case ConditionBlueprintType.jointProductAgeRange:
        return [];
    }
  }

  private static toBooleanOperator(operator: string): BooleanOperator {
    switch (operator) {
      case 'or':
        return BooleanOperator.or;
      case 'not':
        return BooleanOperator.not;
      default:
        return BooleanOperator.and;
    }
  }

  private static toCollectionOperator(operator: BlueprintCollectionOperator | undefined): CollectionOperator | null {
    if (!operator) return null;

    switch (operator) {
      case BlueprintCollectionOperator.every:
        return CollectionOperator.every;
      case BlueprintCollectionOperator.some:
        return CollectionOperator.some;
      case BlueprintCollectionOperator.none:
        return CollectionOperator.none;
      case BlueprintCollectionOperator.thisItem:
        return null;
    }
  }

  private static getAgeRoundingType(roundingType?: AgeRoundingType, unit = DateUnit.year): AgeRoundingType {
    let ageRoundingType: AgeRoundingType;
    switch (roundingType) {
      case 'closestBirthday':
        ageRoundingType = AgeRoundingType.closestBirthday;
        break;
      case 'lastBirthday':
        ageRoundingType = AgeRoundingType.lastBirthday;
        break;
      case 'nextBirthday':
        ageRoundingType = AgeRoundingType.nextBirthday;
        break;
      case 'none':
        ageRoundingType = AgeRoundingType.none;
        break;
      default:
        if (unit === DateUnit.month || unit === DateUnit.day) {
          ageRoundingType = AgeRoundingType.none;
        } else {
          ageRoundingType = AgeRoundingType.closestBirthday;
        }
    }
    return ageRoundingType;
  }

  private static generateAgeRangeCondition(conditionValue: AgeRangeConditionValue): Condition {
    const {
      targetBirthdateNodeId,
      value: { minAge, maxAge, unit = DateUnit.year },
      roundingType,
    } = conditionValue;

    const ageRoundingType = this.getAgeRoundingType(roundingType, unit);

    return {
      conditions: [
        minimumAgeCondition(targetBirthdateNodeId, minAge, unit, ageRoundingType),
        maximumAgeCondition(targetBirthdateNodeId, maxAge, unit, ageRoundingType),
      ],
      operator: BooleanOperator.and,
    };
  }

  private static generateCharacterCountInBetweenCondition(
    conditionValue: CharacterCountInBetweenConditionValue,
  ): Condition {
    const {
      targetNodeId,
      value: { minLength, maxLength },
    } = conditionValue;

    const minLimit = minLength && minLength >= 0 ? minLength - 1 : -1;
    const maxLimit = maxLength ? maxLength + 1 : Number.MAX_SAFE_INTEGER;

    return {
      nodeId: targetNodeId,
      operator: ComparisonOperator.characterCountBetween,
      value: [minLimit, maxLimit],
    };
  }

  private static generateMathOperatorCondition(
    conditionValue: MathOperatorConditionValue,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): Condition {
    const { value, nodeIds, operator, mathOperator, nodeIdOfValue } = conditionValue;
    if (typeof value === 'undefined' && !nodeIdOfValue) {
      throw new Error(`need either a value or a nodeIdOfValue to generate a math operator condition`);
    }

    const comparisonOperator = this.numberComparisonConditionOperatorToComparisonOperator(operator);

    let queryOperator: QueryOperator;
    switch (mathOperator) {
      case MathConditionOperator.sum: {
        queryOperator = QueryOperator.sum;
        break;
      }
      case MathConditionOperator.multiply: {
        queryOperator = QueryOperator.multiply;
        break;
      }
      case MathConditionOperator.subtract: {
        queryOperator = QueryOperator.subtract;
        break;
      }
    }

    const condition: Condition = {
      query: {
        select: nodeIds,
        // Note: `selectRepeatable` could be conditionally set here if we wanted aggregation features. (See `generateNumberComparisonCondition`)
        operator: queryOperator,
      },
      operator: comparisonOperator,
    };

    if (nodeIdOfValue && !nodeIdToAnswerPathMap.has(nodeIdOfValue)) {
      throw new Error(`nodeIdOfValue ${nodeIdOfValue} is not in the nodeIdToAnswerPathMap`);
    }

    if (nodeIdOfValue) {
      condition.controlValue = nodeIdOfValue;
    } else if (typeof value !== 'undefined') {
      condition.value = value;
    }

    return condition;
  }

  private static generateInstancesCountCondition(conditionValue: InstancesCountConditionValue): Condition {
    const { limitOf, minimumOf, nodeIdsToAdd, nodeIdsToSubtract } = conditionValue;

    const selectRepeatable: RepeatableSelection[] = [];
    const select: string[] = [];
    [...nodeIdsToAdd, ...nodeIdsToSubtract].forEach(({ nodeId, repeatableCollectionNodeId }) => {
      if (!repeatableCollectionNodeId) {
        select.push(nodeId);
      } else {
        selectRepeatable.push({ fromCollection: repeatableCollectionNodeId, select: nodeId });
      }
    });

    const query: Query = {
      select,
      selectRepeatable,
      operator: QueryOperator.formula,
      operatorParams: {
        formula: `getRepeatedCount(VALUES, nodeIdsToAdd, nodeIdsToSubtract)`,
        formulaParams: {
          nodeIdsToAdd,
          nodeIdsToSubtract,
        },
      },
    };
    const conditions = [];
    if (limitOf) {
      conditions.push({
        operator: ComparisonOperator.lessThanOrEqual,
        value: limitOf,
        query,
      });
    }
    if (minimumOf) {
      conditions.push({
        operator: ComparisonOperator.greaterThanOrEqual,
        value: minimumOf,
        query,
      });
    }

    return {
      conditions,
      operator: BooleanOperator.and,
    };
  }

  private static generateJointProductAgeRangeCondition(conditionValue: JointProductAgeRangeConditionValue): Condition {
    const { jointProductNodeIds, value, roundingType, repeatableInsuredCollectionId, insuredBirthdayNodeId } =
      conditionValue;
    const { unit, maxAge } = value;
    const transformedRoundingType = this.getAgeRoundingType(roundingType, unit);

    const fetchExistingJointProductSurrogateIdQuery = fetchExistingJointProductSurrogateId(
      repeatableInsuredCollectionId,
      jointProductNodeIds,
    );

    const fetchOldestJoinProductBirthday: Query = {
      select: [],
      operator: QueryOperator.formula,
      operatorParams: {
        formula: `keepOldestDate(VALUES)`,
        formulaParams: {},
      },

      selectRepeatable: [
        {
          fromCollection: repeatableInsuredCollectionId,
          select: insuredBirthdayNodeId,
          selectWithConditions: {
            nodeId: insuredBirthdayNodeId,
            selectIf: {
              operator: BooleanOperator.or,
              conditions: jointProductNodeIds.map((jointProductNodeId) => ({
                nodeId: jointProductNodeId.surrogateId,
                operator: ComparisonOperator.equal,
                query: fetchExistingJointProductSurrogateIdQuery,
              })),
            },
            defaultValue: undefined,
          },
        },
      ],
    };

    return {
      conditions: [
        {
          query: fetchOldestJoinProductBirthday,
          operator: ComparisonOperator.greaterThanOrEqual,
          controlValueQuery: {
            select: [DateTimeReference.currentDateTime],
            operator: QueryOperator.shiftDateBackwards,
            operatorParams: {
              dateUnit: unit,
              shiftDateParams: {
                shiftMagnitude: ShiftMagnitude.earliestDateRespectingShiftValue,
                dateFormat: 'YYYY-MM-DD',
                shiftValue: maxAge,
                timeRoundingType: transformedRoundingType,
              },
            },
          },
        },
      ],
      operator: BooleanOperator.and,
    };
  }

  private static generateBMIRangeCondition(conditionValue: BodyMassIndexRangeConditionValue): Condition {
    const { value, heightUnit, weightUnit, heightNodeId, weightNodeId } = conditionValue;

    const formattedWeightNodeId = formatFormulaVariableName(weightNodeId);
    const weightInKilograms = weightUnit === 'lb' ? `(${formattedWeightNodeId} * 0.453592)` : formattedWeightNodeId;

    const formattedHeightNodeId = formatFormulaVariableName(heightNodeId);
    const heightInMeters =
      heightUnit === 'cm' ? `(${formattedHeightNodeId} * 0.01)` : `(${formattedHeightNodeId} * 0.0254)`;

    return {
      query: {
        select: [heightNodeId, weightNodeId],
        operator: QueryOperator.formula,
        operatorParams: {
          formula: `${weightInKilograms} / ${heightInMeters}^2`,
        },
      },
      value: [value.minBMI, value.maxBMI],
      operator: ComparisonOperator.inRange,
    };
  }

  private static generateNumberComparisonCondition(
    conditionValue: NumberComparisonConditionValue,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): Condition {
    const { targetNodeId, value, operator, nodeIdOfValue, collectionOperators } = conditionValue;
    if (typeof value === 'undefined' && !nodeIdOfValue) {
      throw new Error(`need either a value or a nodeIdOfValue to generate a math operator condition`);
    }

    if (nodeIdOfValue && !nodeIdToAnswerPathMap.has(nodeIdOfValue)) {
      throw new Error(`nodeIdOfValue ${nodeIdOfValue} is not in the nodeIdToAnswerPathMap`);
    }

    const collectionNodeIds = this.getAncestorCollectionNodeIds(targetNodeId, nodeIdToAnswerPathMap);
    const collectionNodeIdsWithoutThisItem = collectionNodeIds.filter(
      (nodeId) => collectionOperators?.[nodeId] && collectionOperators[nodeId] !== BlueprintCollectionOperator.thisItem,
    );
    const latestCollectionNodeId = _.last(collectionNodeIdsWithoutThisItem);
    const collectionOperator = latestCollectionNodeId ? collectionOperators?.[latestCollectionNodeId] : null;

    const comparisonOperator = this.numberComparisonConditionOperatorToComparisonOperator(operator);

    // `shouldAggregate` depends on the last `collectionOperator` provided, but this is an imperfect measure. We could have a toggle to determine whether to aggregate the numeric value or not.
    const shouldAggregate = !!collectionOperator && collectionOperator !== BlueprintCollectionOperator.thisItem;

    if (shouldAggregate && latestCollectionNodeId) {
      const collectionCondition: Condition = {
        query: {
          select: [],
          selectRepeatable: [
            {
              fromCollection: latestCollectionNodeId,
              select: targetNodeId,
            },
          ],
          operator: QueryOperator.sum,
        },
        operator: comparisonOperator,
      };

      if (nodeIdOfValue) {
        collectionCondition.controlValue = nodeIdOfValue;
      } else if (typeof value !== 'undefined') {
        collectionCondition.value = value;
      }

      return {
        conditions: [collectionCondition],
      };
    } else {
      const condition: Condition = {
        operator: comparisonOperator,
        nodeId: targetNodeId,
      };

      if (nodeIdOfValue) {
        condition.controlValue = nodeIdOfValue;
      } else if (typeof value !== 'undefined') {
        condition.value = value;
      }

      return condition;
    }
  }

  private static generateEngineCondition(conditionValue: EngineConditionValue): Condition {
    return conditionValue.condition;
  }

  private static generateMatchesRegexCondition(conditionValue: MatchesRegexConditionValue): Condition {
    return {
      value: conditionValue.regex,
      operator: ComparisonOperator.matchesRegex,
      nodeId: conditionValue.targetNodeId,
    };
  }

  private static generateCountEqualCondition(
    conditionValue: CountEqualConditionValue,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): Condition {
    const { value, controlValue, targetNodeId, operator } = conditionValue;
    const numberComparisonOperator = this.numberComparisonConditionOperatorToComparisonOperator(operator);

    const collectionNodeIds = this.getAncestorCollectionNodeIds(targetNodeId, nodeIdToAnswerPathMap);
    const latestCollectionNodeId = _.last(collectionNodeIds);
    if (!latestCollectionNodeId) {
      throw new Error(`on countEqual condition: no collection answer path was found for nodeId ${targetNodeId}`);
    }

    return {
      conditions: [
        {
          query: {
            select: [],
            selectRepeatable: [
              {
                fromCollection: latestCollectionNodeId,
                select: targetNodeId,
              },
            ],
            operator: QueryOperator.countEqual,
            operatorParams: { controlValue },
          },
          operator: numberComparisonOperator,
          value,
        },
      ],
    };
  }

  private static generateFutureDateCondition(conditionValue: FutureDateConditionValue): Condition {
    const { targetNodeId, value, unit, operator } = conditionValue;
    const comparisonOperator = this.numberComparisonConditionOperatorToComparisonOperator(operator);

    return futureDateCondition(targetNodeId, value, unit, comparisonOperator);
  }

  private static generateLastIncidentDateCondition(conditionValue: LastIncidentDateConditionValue): Condition {
    const { targetNodeId, value, unit, operator } = conditionValue;
    const comparisonOperator = this.numberComparisonConditionOperatorToComparisonOperator(operator);

    return lastIncidentDateCondition(targetNodeId, value, unit, comparisonOperator);
  }

  private static numberComparisonConditionOperatorToComparisonOperator(
    operator: NumberComparisonConditionOperator,
  ): ComparisonOperator {
    let comparisonOperator: ComparisonOperator;

    switch (operator) {
      case NumberComparisonConditionOperator.equal: {
        comparisonOperator = ComparisonOperator.equal;
        break;
      }
      case NumberComparisonConditionOperator.lessThan: {
        comparisonOperator = ComparisonOperator.lessThan;
        break;
      }
      case NumberComparisonConditionOperator.lessThanOrEqual: {
        comparisonOperator = ComparisonOperator.lessThanOrEqual;
        break;
      }
      case NumberComparisonConditionOperator.greaterThan: {
        comparisonOperator = ComparisonOperator.greaterThan;
        break;
      }
      case NumberComparisonConditionOperator.greaterThanOrEqual: {
        comparisonOperator = ComparisonOperator.greaterThanOrEqual;
        break;
      }
      case NumberComparisonConditionOperator.multipleOf: {
        comparisonOperator = ComparisonOperator.multipleOf;
        break;
      }
    }

    return comparisonOperator;
  }

  private static getAncestorCollectionNodeIds(
    targetNodeId: string,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  ): string[] {
    const ancestorCollectionNodeIds: string[] = [];

    const targetNodeIdAnswerPath = nodeIdToAnswerPathMap.get(targetNodeId);
    if (!targetNodeIdAnswerPath) {
      return ancestorCollectionNodeIds;
    }

    const ancestorCollectionAnswerPath = AnswerPath.getAncestorCollectionAnswerPath(targetNodeIdAnswerPath);
    if (!ancestorCollectionAnswerPath?.nodeId) {
      ancestorCollectionNodeIds;
    } else {
      ancestorCollectionNodeIds.push(ancestorCollectionAnswerPath?.nodeId);

      const furtherAncestorCollections = this.getAncestorCollectionNodeIds(
        ancestorCollectionAnswerPath?.nodeId,
        nodeIdToAnswerPathMap,
      );
      ancestorCollectionNodeIds.push(...furtherAncestorCollections);
    }

    return ancestorCollectionNodeIds;
  }
}

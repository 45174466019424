import _ from 'lodash';

import {
  AddressAutocompleteFieldBlueprint,
  BlueprintCreation,
  BlueprintModification,
  BlueprintReorder,
  BlueprintReplacement,
  BlueprintUpdate,
  FieldBlueprint,
  FieldBlueprintReorder,
  FieldBlueprintReplacement,
  FieldBlueprintUpdate,
  FieldPartIdentifier,
  FieldValidation,
  getFieldBlueprintBase,
  InformationFieldBlueprint,
  InformationFieldBlueprintVariant,
  isAcceptingDefaultValueField,
  isAddressAutocompleteFieldBlueprint,
  isBlueprintCreation,
  isBlueprintRemoval,
  isBlueprintReorder,
  isBlueprintReplacement,
  isBlueprintUpdate,
  isFieldBlueprintCreation,
  isFieldBlueprintReorder,
  isFieldBlueprintUpdate,
  isFieldPartIdentifier,
  isQuestionBlueprintCreation,
  isQuestionBlueprintReorder,
  isQuestionBlueprintUpdate,
  isQuestionPartIdentifier,
  isSectionBlueprintCreation,
  isSectionBlueprintReorder,
  isSectionBlueprintUpdate,
  isSectionGroupBlueprintUpdate,
  isSectionPartIdentifier,
  isSelectOptionBlueprintCreation,
  isSelectOptionBlueprintReorder,
  isSelectOptionBlueprintUpdate,
  isSelectOptionFieldBlueprint,
  isSelectOptionPartIdentifier,
  isSubsectionBlueprintCreation,
  isSubsectionBlueprintReorder,
  isSubsectionBlueprintUpdate,
  isSubsectionPartIdentifier,
  PartIdentifier,
  QuestionBlueprint,
  QuestionBlueprintReorder,
  QuestionBlueprintUpdate,
  QuestionnaireBlueprint,
  FieldTypes,
  QuestionPartIdentifier,
  SectionBlueprint,
  SectionBlueprintReorder,
  SectionBlueprintUpdate,
  SectionGroupBlueprintUpdate,
  SectionPartIdentifier,
  SelectOptionBlueprint,
  SelectOptionBlueprintReorder,
  SelectOptionBlueprintUpdate,
  SelectOptionFieldBlueprint,
  SelectOptionPartIdentifier,
  StringFieldValidation,
  SubsectionBlueprint,
  SubsectionBlueprintReorder,
  SubsectionBlueprintUpdate,
  SubsectionPartIdentifier,
} from '@breathelife/types';

import { checkForSameParentInReorder } from './checkForSameParentInBlueprintReorder';
import { blueprintResourceNotFoundErrorMessage } from './errorMessages';
import { fieldTypeToValidation, isOptionFieldType } from './fieldTypes';

export class BlueprintModifier {
  public static modifyBlueprint(modification: BlueprintModification, blueprint: QuestionnaireBlueprint): void {
    if (isBlueprintReplacement(modification)) {
      this.replaceBlueprint(modification, blueprint);
    } else if (isBlueprintUpdate(modification)) {
      this.updateBlueprint(modification, blueprint);
    } else if (isBlueprintCreation(modification)) {
      this.createBlueprint(modification, blueprint);
    } else if (isBlueprintRemoval(modification)) {
      this.removeBlueprint(modification.partIdentifier, blueprint);
    } else if (isBlueprintReorder(modification)) {
      this.reorderBlueprint(modification, blueprint);
    }
  }

  public static replaceBlueprint(blueprintReplacement: BlueprintReplacement, blueprint: QuestionnaireBlueprint): void {
    if (isFieldPartIdentifier(blueprintReplacement.partIdentifier)) {
      this.replaceFieldBlueprint(blueprintReplacement, blueprint);
    }
  }

  public static createBlueprint(blueprintCreation: BlueprintCreation, blueprint: QuestionnaireBlueprint): void {
    if (isSectionBlueprintCreation(blueprintCreation)) {
      blueprint.sectionBlueprints.push(blueprintCreation.create.blueprint);
    } else if (isSubsectionBlueprintCreation(blueprintCreation)) {
      const section = this.findSectionBlueprint(blueprint, blueprintCreation.partIdentifier);
      section.subsections.push(blueprintCreation.create.blueprint);
    } else if (isQuestionBlueprintCreation(blueprintCreation)) {
      const subsection = this.findSubsectionBlueprint(blueprint, blueprintCreation.partIdentifier);
      subsection.questions.push(blueprintCreation.create.blueprint);
    } else if (isFieldBlueprintCreation(blueprintCreation)) {
      const question = this.findQuestionBlueprint(blueprint, blueprintCreation.partIdentifier);
      question.fields.push(blueprintCreation.create.blueprint);
    } else if (isSelectOptionBlueprintCreation(blueprintCreation)) {
      const field = this.findFieldBlueprint(blueprint, blueprintCreation.partIdentifier);
      if (isSelectOptionFieldBlueprint(field)) {
        field.selectOptions.push(blueprintCreation.create.blueprint);
      }
    }
  }

  public static updateBlueprint(blueprintUpdate: BlueprintUpdate, blueprint: QuestionnaireBlueprint): void {
    if (isSelectOptionBlueprintUpdate(blueprintUpdate)) {
      return this.updateSelectOptionBlueprint(blueprintUpdate, blueprint);
    } else if (isFieldBlueprintUpdate(blueprintUpdate)) {
      return this.updateFieldBlueprint(blueprintUpdate, blueprint);
    } else if (isQuestionBlueprintUpdate(blueprintUpdate)) {
      return this.updateQuestionBlueprint(blueprintUpdate, blueprint);
    } else if (isSubsectionBlueprintUpdate(blueprintUpdate)) {
      return this.updateSubsectionBlueprint(blueprintUpdate, blueprint);
    } else if (isSectionBlueprintUpdate(blueprintUpdate)) {
      return this.updateSectionBlueprint(blueprintUpdate, blueprint);
    } else if (isSectionGroupBlueprintUpdate(blueprintUpdate)) {
      return this.updateSectionGroupBlueprint(blueprintUpdate, blueprint);
    }
  }

  public static reorderBlueprint(blueprintReorder: BlueprintReorder, blueprint: QuestionnaireBlueprint): void {
    // By design, we currently only allow reordering of items within the same parent.
    checkForSameParentInReorder(blueprintReorder);

    if (isSelectOptionBlueprintReorder(blueprintReorder)) {
      this.reorderSelectOptionBlueprints(blueprintReorder, blueprint);
    } else if (isFieldBlueprintReorder(blueprintReorder)) {
      this.reorderFieldBlueprints(blueprintReorder, blueprint);
    } else if (isQuestionBlueprintReorder(blueprintReorder)) {
      this.reorderQuestionBlueprints(blueprintReorder, blueprint);
    } else if (isSubsectionBlueprintReorder(blueprintReorder)) {
      this.reorderSubsectionBlueprints(blueprintReorder, blueprint);
    } else if (isSectionBlueprintReorder(blueprintReorder)) {
      this.reorderSectionBlueprints(blueprintReorder, blueprint);
    }
  }

  private static reorderSectionBlueprints(
    blueprintReorder: SectionBlueprintReorder,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier, reorder } = blueprintReorder;
    const { newPreviousSiblingIdentifier } = reorder;

    const sourceSectionIndex = this.findSectionBlueprintIndex(blueprint, partIdentifier);
    const movedSection = blueprint.sectionBlueprints.splice(sourceSectionIndex, 1)[0];

    if (newPreviousSiblingIdentifier) {
      const targetSiblingIndex = this.findSectionBlueprintIndex(blueprint, newPreviousSiblingIdentifier) + 1;
      blueprint.sectionBlueprints.splice(targetSiblingIndex, 0, movedSection);
      return;
    } else {
      blueprint.sectionBlueprints.splice(0, 0, movedSection);
    }
  }

  private static reorderSubsectionBlueprints(
    blueprintReorder: SubsectionBlueprintReorder,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier, reorder } = blueprintReorder;
    const { newPreviousSiblingIdentifier, parentIdentifier } = reorder;

    const sourceSectionBlueprint = this.findSectionBlueprint(blueprint, partIdentifier);
    const sourceSubsectionIndex = this.findSubsectionBlueprintIndex(sourceSectionBlueprint, partIdentifier);

    const movedSubsection = sourceSectionBlueprint.subsections.splice(sourceSubsectionIndex, 1)[0];

    if (parentIdentifier) {
      const targetSectionBlueprint = this.findSectionBlueprint(blueprint, parentIdentifier);
      if (newPreviousSiblingIdentifier) {
        const targetSiblingIndex =
          this.findSubsectionBlueprintIndex(targetSectionBlueprint, newPreviousSiblingIdentifier) + 1;
        targetSectionBlueprint.subsections.splice(targetSiblingIndex, 0, movedSubsection);
        return;
      }
      targetSectionBlueprint.subsections.splice(0, 0, movedSubsection);
    }
  }

  private static reorderQuestionBlueprints(
    blueprintReorder: QuestionBlueprintReorder,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier, reorder } = blueprintReorder;
    const { newPreviousSiblingIdentifier, parentIdentifier } = reorder;

    const subsectionBlueprint = this.findSubsectionBlueprint(blueprint, partIdentifier);
    const sourceQuestionIndex = this.findQuestionBlueprintIndex(subsectionBlueprint, partIdentifier);

    const movedQuestion = subsectionBlueprint.questions.splice(sourceQuestionIndex, 1)[0];

    if (parentIdentifier) {
      const targetSubsectionBlueprint = this.findSubsectionBlueprint(blueprint, parentIdentifier);
      if (newPreviousSiblingIdentifier) {
        const targetSiblingIndex =
          this.findQuestionBlueprintIndex(targetSubsectionBlueprint, newPreviousSiblingIdentifier) + 1;
        targetSubsectionBlueprint.questions.splice(targetSiblingIndex, 0, movedQuestion);
        return;
      }

      targetSubsectionBlueprint.questions.splice(0, 0, movedQuestion);
    }
  }

  private static reorderFieldBlueprints(
    blueprintReorder: FieldBlueprintReorder,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier, reorder } = blueprintReorder;
    const { newPreviousSiblingIdentifier, parentIdentifier } = reorder;

    const questionBlueprint = this.findQuestionBlueprint(blueprint, partIdentifier);
    const sourceFieldIndex = this.findFieldBlueprintIndex(questionBlueprint, partIdentifier);

    const movedField = questionBlueprint.fields.splice(sourceFieldIndex, 1)[0];

    if (parentIdentifier) {
      const targetQuestionBlueprint = this.findQuestionBlueprint(blueprint, parentIdentifier);
      if (newPreviousSiblingIdentifier) {
        const targetSiblingIndex =
          this.findFieldBlueprintIndex(targetQuestionBlueprint, newPreviousSiblingIdentifier) + 1;
        targetQuestionBlueprint.fields.splice(targetSiblingIndex, 0, movedField);
        return;
      }
      targetQuestionBlueprint.fields.splice(0, 0, movedField);
    }
  }

  private static reorderSelectOptionBlueprints(
    blueprintReorder: SelectOptionBlueprintReorder,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier, reorder } = blueprintReorder;
    const { newPreviousSiblingIdentifier } = reorder;

    const fieldBlueprint = this.findFieldBlueprint(blueprint, partIdentifier);
    if (!isSelectOptionFieldBlueprint(fieldBlueprint)) {
      return;
    }

    const sourceSelectOptionIndex = this.findSelectOptionBlueprintIndex(fieldBlueprint, partIdentifier);

    const targetIndex = newPreviousSiblingIdentifier
      ? this.findSelectOptionBlueprintIndex(fieldBlueprint, newPreviousSiblingIdentifier) + 1
      : 0;

    const [movedSelectOption] = fieldBlueprint.selectOptions.splice(sourceSelectOptionIndex, 1);
    fieldBlueprint.selectOptions.splice(targetIndex, 0, movedSelectOption);
  }

  public static removeBlueprint(identifier: PartIdentifier, blueprint: QuestionnaireBlueprint): void {
    if (isSelectOptionPartIdentifier(identifier)) {
      const fieldBlueprint = this.findFieldBlueprint(blueprint, identifier);
      const selectOptionBlueprint = this.findSelectOptionBlueprint(blueprint, identifier);

      if (selectOptionBlueprint && fieldBlueprint && isSelectOptionFieldBlueprint(fieldBlueprint)) {
        _.remove(
          fieldBlueprint.selectOptions,
          (selectOption) => selectOption.partName === identifier.selectOptionPartName,
        );

        if (fieldBlueprint.selectOptions.length === 0 && !isOptionFieldType(fieldBlueprint.fieldType)) {
          // Last option removed from a non-option field blueprint, remove the property.
          delete (fieldBlueprint as any).selectOptions;
        }
      }
    } else if (isFieldPartIdentifier(identifier)) {
      this.removeFieldBlueprint(blueprint, identifier);
    } else if (isQuestionPartIdentifier(identifier)) {
      const subsectionBlueprint = this.findSubsectionBlueprint(blueprint, identifier);
      _.remove(subsectionBlueprint.questions, (question) => question.partName === identifier.questionPartName);
    } else if (isSubsectionPartIdentifier(identifier)) {
      const sectionBlueprint = this.findSectionBlueprint(blueprint, identifier);
      _.remove(sectionBlueprint.subsections, (subsection) => subsection.partName === identifier.subsectionPartName);
    } else if (isSectionPartIdentifier(identifier)) {
      _.remove(
        blueprint.sectionBlueprints,
        (sectionBlueprint) => sectionBlueprint.partName === identifier.sectionPartName,
      );
    }
  }

  private static removeFieldBlueprint(blueprint: QuestionnaireBlueprint, identifier: FieldPartIdentifier): void {
    const questionBlueprint = this.findQuestionBlueprint(blueprint, identifier);

    // When a field is removed any references to that field should also be removed.
    this.removeAddressAutocompleteReferences(questionBlueprint, identifier);

    _.remove(questionBlueprint.fields, (field) => field.partName === identifier.fieldPartName);
  }

  private static removeAddressAutocompleteReferences(
    questionBlueprint: QuestionBlueprint,
    identifier: FieldPartIdentifier,
  ): void {
    const autocompleteFields = questionBlueprint.fields.filter(isAddressAutocompleteFieldBlueprint);

    autocompleteFields.forEach((autocompleteField) => {
      if (autocompleteField.addressAutocompleteFields?.streetAddress === identifier.fieldPartName) {
        autocompleteField.addressAutocompleteFields.streetAddress = '';
      }
      if (autocompleteField.addressAutocompleteFields?.city === identifier.fieldPartName) {
        autocompleteField.addressAutocompleteFields.city = '';
      }
      if (autocompleteField.addressAutocompleteFields?.stateOrProvince === identifier.fieldPartName) {
        autocompleteField.addressAutocompleteFields.stateOrProvince = '';
      }
      if (autocompleteField.addressAutocompleteFields?.postalCodeOrZip === identifier.fieldPartName) {
        autocompleteField.addressAutocompleteFields.postalCodeOrZip = '';
      }
    });
  }

  private static updateSectionGroupBlueprint(
    blueprintUpdate: SectionGroupBlueprintUpdate,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { sectionGroupKey, update } = blueprintUpdate;
    const existingSectionGroupBlueprint = blueprint.sectionGroupBlueprints[sectionGroupKey];

    if (!existingSectionGroupBlueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.sectionGroup} ${sectionGroupKey}`);
    }

    this.setBlueprintValue(blueprint.sectionGroupBlueprints, sectionGroupKey, {
      ...existingSectionGroupBlueprint,
      ...update,
    });
  }

  private static updateSectionBlueprint(
    blueprintUpdate: SectionBlueprintUpdate,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier } = blueprintUpdate;
    const { property, value } = blueprintUpdate.update;

    const sectionBlueprint = this.findSectionBlueprint(blueprint, partIdentifier);
    this.setBlueprintValue(sectionBlueprint, property, value);
  }

  private static updateSubsectionBlueprint(
    blueprintUpdate: SubsectionBlueprintUpdate,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier } = blueprintUpdate;
    const { property, value } = blueprintUpdate.update;

    const subsectionBlueprint = this.findSubsectionBlueprint(blueprint, partIdentifier);
    this.setBlueprintValue(subsectionBlueprint, property, value);
  }

  private static updateQuestionBlueprint(
    blueprintUpdate: QuestionBlueprintUpdate,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier } = blueprintUpdate;
    const { property, value } = blueprintUpdate.update;
    const questionBlueprint = this.findQuestionBlueprint(blueprint, partIdentifier);
    this.setBlueprintValue(questionBlueprint, property, value);
  }

  private static updateFieldBlueprint(blueprintUpdate: FieldBlueprintUpdate, blueprint: QuestionnaireBlueprint): void {
    const { partIdentifier } = blueprintUpdate;
    const { property, value } = blueprintUpdate.update;

    const fieldBlueprint = this.findFieldBlueprint(blueprint, partIdentifier);
    if (property === 'fieldType') {
      this.updateOptionsOnFieldTypeChange(fieldBlueprint, blueprintUpdate);
      this.updateVariantOnFieldTypeChange(fieldBlueprint, blueprintUpdate);
      this.updateValidateAsOnFieldTypeChange(fieldBlueprint, blueprintUpdate);
      this.updateButtonTextOnFieldTypeChange(fieldBlueprint, blueprintUpdate);
      this.updateAddressAutocompleteValuesOnFieldTypeChange(fieldBlueprint, blueprintUpdate);
      this.deleteDefaultValueOnFieldTypeChange(fieldBlueprint);
    }

    // `as keyof FieldBlueprint` because some properties are not available for all field types.
    //  We also have some properties which are not on the root of the blueprint object (e.g. the data in `addressFieldData`).
    this.setBlueprintValue(
      fieldBlueprint,
      property as keyof FieldBlueprint,
      value as FieldBlueprint[keyof FieldBlueprint],
    );
  }

  private static replaceFieldBlueprint(
    blueprintReplacement: FieldBlueprintReplacement,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier } = blueprintReplacement;

    const questionBlueprint = this.findQuestionBlueprint(blueprint, {
      sectionGroupPartName: partIdentifier.sectionGroupPartName,
      sectionPartName: partIdentifier.sectionPartName,
      subsectionPartName: partIdentifier.subsectionPartName,
      questionPartName: partIdentifier.questionPartName,
    });

    const indexInFields = _.findIndex(
      questionBlueprint.fields,
      (field) => field.partName === partIdentifier.fieldPartName,
    );

    if (indexInFields > -1) {
      questionBlueprint.fields.splice(indexInFields, 1, blueprintReplacement.replacement);
    }
  }

  private static updateVariantOnFieldTypeChange(
    fieldBlueprint: FieldBlueprint,
    blueprintUpdate: FieldBlueprintUpdate,
  ): void {
    const { value } = blueprintUpdate.update;

    const changingToInformationVariant =
      value === FieldTypes.information && fieldBlueprint.fieldType !== FieldTypes.information;

    const changingFromInformationVariant =
      value !== FieldTypes.information && fieldBlueprint.fieldType === FieldTypes.information;

    if (!changingToInformationVariant && !changingFromInformationVariant) {
      // No change involving the info field, nothing to do.
      return;
    } else if (changingToInformationVariant) {
      // Set default.
      const informationFieldBlueprint: InformationFieldBlueprint = {
        ...getFieldBlueprintBase(fieldBlueprint),
        validateAs: StringFieldValidation.string,
        fieldType: FieldTypes.information,
        variant: InformationFieldBlueprintVariant.info,
      };

      Object.keys(fieldBlueprint).forEach((key: string) => {
        delete (fieldBlueprint as any)[key];
      });

      Object.keys(informationFieldBlueprint).forEach((key: string) => {
        (fieldBlueprint as any)[key] = (informationFieldBlueprint as any)[key];
      });
    } else if (changingFromInformationVariant) {
      // Remove unused property.
      delete (fieldBlueprint as any).variant;
    }
  }

  private static updateOptionsOnFieldTypeChange(
    fieldBlueprint: FieldBlueprint,
    blueprintUpdate: FieldBlueprintUpdate,
  ): void {
    const { value } = blueprintUpdate.update;

    if (isOptionFieldType(value)) {
      // `selectOptions` must be set so options can be added to a select option fields.
      const selectOptionField = fieldBlueprint as SelectOptionFieldBlueprint;
      if (!selectOptionField.selectOptions) {
        selectOptionField.selectOptions = [];
      }
    } else {
      // Remove fields when switching away from a select option field type;
      // but only if the options array is empty, to prevent accidental data loss.
      const selectOptionField = fieldBlueprint as SelectOptionFieldBlueprint;
      if (selectOptionField.selectOptions && !selectOptionField.selectOptions.length) {
        delete (fieldBlueprint as any).selectOptions;
      }
    }
  }

  private static updateButtonTextOnFieldTypeChange(
    fieldBlueprint: FieldBlueprint,
    blueprintUpdate: FieldBlueprintUpdate,
  ): void {
    const { value } = blueprintUpdate.update;

    const changingFromButtonVariant = value !== FieldTypes.button && fieldBlueprint.fieldType === FieldTypes.button;

    if (changingFromButtonVariant) {
      // Remove unused property.
      delete (fieldBlueprint as any).buttonText;
    }

    // No change involving the button field, nothing to do.
    return;
  }

  private static updateAddressAutocompleteValuesOnFieldTypeChange(
    fieldBlueprint: FieldBlueprint,
    blueprintUpdate: FieldBlueprintUpdate,
  ): void {
    const { value } = blueprintUpdate.update;

    if (value !== FieldTypes.autocomplete && fieldBlueprint.fieldType !== FieldTypes.autocomplete) {
      // Field type change does not involve address autocomplete type.
      return;
    }

    if (isAddressAutocompleteFieldBlueprint(fieldBlueprint)) {
      // Changing from address autocomplete field to another type, remove unused properties.
      delete (fieldBlueprint as any).addressAutocompleteNodeId;
      delete (fieldBlueprint as any).addressAutocompleteFields;
      delete (fieldBlueprint as any).countryCode;
    } else {
      // Changing to address autocomplete. Set defaults.
      const addressAutoCompleteField: AddressAutocompleteFieldBlueprint = {
        ...getFieldBlueprintBase(fieldBlueprint),
        fieldType: FieldTypes.autocomplete,
        validateAs: StringFieldValidation.string,
        countryCode: 'CA',
        addressAutocompleteFields: {
          streetAddress: fieldBlueprint.partName,
          stateOrProvince: '',
          postalCodeOrZip: '',
          city: '',
        },
      };

      Object.keys(fieldBlueprint).forEach((key: string) => {
        delete (fieldBlueprint as any)[key];
      });

      Object.keys(addressAutoCompleteField).forEach((key: string) => {
        (fieldBlueprint as any)[key] = (addressAutoCompleteField as any)[key];
      });
    }
  }

  private static deleteDefaultValueOnFieldTypeChange(fieldBlueprint: FieldBlueprint): void {
    if (isAcceptingDefaultValueField(fieldBlueprint)) {
      delete fieldBlueprint.defaultValue;
    }
  }

  private static updateValidateAsOnFieldTypeChange(
    fieldBlueprint: FieldBlueprint,
    blueprintUpdate: FieldBlueprintUpdate,
  ): void {
    // When the field type of a field is changed the available `validateAs` options may also change.
    // This function makes sure a valid `validateAs` value is selected.

    const { value } = blueprintUpdate.update;

    const newValidationTypeValues = Object.keys(fieldTypeToValidation[value as FieldTypes]);
    if (newValidationTypeValues.length && !newValidationTypeValues.includes(fieldBlueprint.validateAs)) {
      fieldBlueprint.validateAs = newValidationTypeValues[0] as FieldValidation;
    }
  }

  private static updateSelectOptionBlueprint(
    blueprintUpdate: SelectOptionBlueprintUpdate,
    blueprint: QuestionnaireBlueprint,
  ): void {
    const { partIdentifier } = blueprintUpdate;
    const { property, value } = blueprintUpdate.update;

    const selectOptionBlueprint = this.findSelectOptionBlueprint(blueprint, partIdentifier);
    this.setBlueprintValue(selectOptionBlueprint, property, value);
  }

  private static findSectionBlueprint(
    questionnaireBlueprint: QuestionnaireBlueprint,
    identifier: Omit<SectionPartIdentifier, 'tag'>,
  ): SectionBlueprint {
    const blueprint = questionnaireBlueprint.sectionBlueprints.find(
      (section) => section.partName === identifier.sectionPartName,
    );
    if (!blueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.section} ${identifier.sectionPartName}`);
    }

    return blueprint;
  }

  private static findSubsectionBlueprint(
    blueprint: QuestionnaireBlueprint,
    identifier: Omit<SubsectionPartIdentifier, 'tag'>,
  ): SubsectionBlueprint {
    const sectionBlueprint = this.findSectionBlueprint(blueprint, identifier);

    const subsectionBlueprint = sectionBlueprint.subsections.find(
      (subsection) => subsection.partName === identifier.subsectionPartName,
    );

    if (!subsectionBlueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.subsection} ${identifier.subsectionPartName}`);
    }

    return subsectionBlueprint;
  }

  private static findQuestionBlueprint(
    blueprint: QuestionnaireBlueprint,
    identifier: Omit<QuestionPartIdentifier, 'tag'>,
  ): QuestionBlueprint {
    const subsectionBlueprint = this.findSubsectionBlueprint(blueprint, identifier);
    const questionBlueprint = subsectionBlueprint.questions.find(
      (question) => question.partName === identifier.questionPartName,
    );
    if (!questionBlueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.question} ${identifier.questionPartName}`);
    }

    return questionBlueprint;
  }

  private static findFieldBlueprint(
    blueprint: QuestionnaireBlueprint,
    identifier: Omit<FieldPartIdentifier, 'tag'>,
  ): FieldBlueprint {
    const questionBlueprint = this.findQuestionBlueprint(blueprint, identifier);
    const fieldBlueprint = questionBlueprint.fields.find((field) => field.partName === identifier.fieldPartName);
    if (!fieldBlueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.field} ${identifier.fieldPartName}`);
    }

    return fieldBlueprint;
  }

  private static findSelectOptionBlueprint(
    blueprint: QuestionnaireBlueprint,
    identifier: Omit<SelectOptionPartIdentifier, 'tag'>,
  ): SelectOptionBlueprint {
    const fieldBlueprint = this.findFieldBlueprint(blueprint, identifier);

    if (!isSelectOptionFieldBlueprint(fieldBlueprint)) {
      throw Error(`field ${fieldBlueprint.partName} is not an option field.`);
    }

    const selectOptionBlueprint = fieldBlueprint.selectOptions.find(
      (selectOption) => selectOption.partName === identifier.selectOptionPartName,
    );
    if (!selectOptionBlueprint) {
      throw Error(`${blueprintResourceNotFoundErrorMessage.selectOption} ${identifier.selectOptionPartName}`);
    }

    return selectOptionBlueprint;
  }

  private static findSectionBlueprintIndex(
    questionnaireBlueprint: QuestionnaireBlueprint,
    identifier: SectionPartIdentifier,
  ): number {
    const sectionIndex = questionnaireBlueprint.sectionBlueprints.findIndex(
      (section) => section.partName === identifier.sectionPartName,
    );
    if (sectionIndex === -1) {
      throw Error(`section blueprint index not found for ${identifier.sectionPartName}`);
    }

    return sectionIndex;
  }

  private static findSubsectionBlueprintIndex(
    sectionBlueprint: SectionBlueprint,
    identifier: Omit<SubsectionPartIdentifier, 'tag'>,
  ): number {
    const subsectionIndex = sectionBlueprint.subsections.findIndex(
      (subsection) => subsection.partName === identifier.subsectionPartName,
    );
    if (subsectionIndex === -1) {
      throw Error(`subsection blueprint not found in ${sectionBlueprint.partName}`);
    }

    return subsectionIndex;
  }

  private static findQuestionBlueprintIndex(
    subsectionBlueprint: SubsectionBlueprint,
    identifier: Omit<QuestionPartIdentifier, 'tag'>,
  ): number {
    const questionIndex = subsectionBlueprint.questions.findIndex(
      (question) => question.partName === identifier.questionPartName,
    );
    if (questionIndex === -1) {
      throw Error(`question blueprint not found for ${identifier.questionPartName}`);
    }

    return questionIndex;
  }

  private static findFieldBlueprintIndex(
    questionBlueprint: QuestionBlueprint,
    identifier: Omit<FieldPartIdentifier, 'tag'>,
  ): number {
    const fieldIndex = questionBlueprint.fields.findIndex((field) => field.partName === identifier.fieldPartName);
    if (fieldIndex === -1) {
      throw Error(`field blueprint not found for ${identifier.fieldPartName}`);
    }

    return fieldIndex;
  }

  private static findSelectOptionBlueprintIndex(
    fieldBlueprint: FieldBlueprint,
    identifier: SelectOptionPartIdentifier,
  ): number {
    if (!isSelectOptionFieldBlueprint(fieldBlueprint)) {
      throw Error(`field ${fieldBlueprint.partName} is not an option field.`);
    }

    const selectOptionIndex = fieldBlueprint.selectOptions.findIndex(
      (selectOption) => selectOption.partName === identifier.selectOptionPartName,
    );
    if (selectOptionIndex === -1) {
      throw Error(`select option blueprint not found for ${identifier.selectOptionPartName}`);
    }

    return selectOptionIndex;
  }

  private static setBlueprintValue<T extends Record<string, unknown>, K extends keyof T>(
    item: T,
    property: K,
    value: T[K],
  ): void {
    _.set(item, property, value);
  }
}

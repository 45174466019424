import {
  BlueprintReorder,
  isFieldBlueprintReorder,
  isQuestionBlueprintReorder,
  isSectionBlueprintReorder,
  isSelectOptionBlueprintReorder,
  isSubsectionBlueprintReorder,
} from '@breathelife/types';

export function checkForSameParentInReorder(blueprintReorder: BlueprintReorder): void {
  const { partIdentifier } = blueprintReorder;

  if (
    isSectionBlueprintReorder(blueprintReorder) ||
    isSubsectionBlueprintReorder(blueprintReorder) ||
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.sectionGroupPartName !== partIdentifier.sectionGroupPartName) {
      throw Error(
        `Cannot reorder blueprints from different sectionGroups (${partIdentifier.sectionGroupPartName}, ${
          newPreviousSiblingIdentifier?.sectionGroupPartName || parentIdentifier?.sectionGroupPartName
        })`,
      );
    }
  }

  if (
    isSubsectionBlueprintReorder(blueprintReorder) ||
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.sectionPartName !== partIdentifier.sectionPartName) {
      throw Error(
        `Cannot reorder blueprints from different sections (${partIdentifier.sectionPartName}, ${
          newPreviousSiblingIdentifier?.sectionPartName || parentIdentifier?.sectionPartName
        })`,
      );
    }
  }

  if (
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.subsectionPartName !== blueprintReorder.partIdentifier.subsectionPartName) {
      throw Error(
        `Cannot reorder blueprints from different subsections (${blueprintReorder.partIdentifier.subsectionPartName}, ${
          newPreviousSiblingIdentifier?.subsectionPartName || parentIdentifier?.subsectionPartName
        })`,
      );
    }
  }

  if (isFieldBlueprintReorder(blueprintReorder) || isSelectOptionBlueprintReorder(blueprintReorder)) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.questionPartName !== blueprintReorder.partIdentifier.questionPartName) {
      throw Error(
        `Cannot reorder blueprints from different questions (${blueprintReorder.partIdentifier.questionPartName}, ${
          newPreviousSiblingIdentifier?.questionPartName || parentIdentifier?.questionPartName
        })`,
      );
    }
  }

  if (isSelectOptionBlueprintReorder(blueprintReorder)) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;
    if (identifier && identifier.fieldPartName !== blueprintReorder.partIdentifier.fieldPartName) {
      throw Error(
        `Cannot reorder blueprints from different fields (${blueprintReorder.partIdentifier.fieldPartName}, ${
          newPreviousSiblingIdentifier?.fieldPartName || parentIdentifier?.fieldPartName
        })`,
      );
    }
  }
}

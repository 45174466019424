export enum NodeIds {
  insuredPeople = 'insured-people',
  insuredSurrogateId = 'insured-surrogate-id',
  insuredTitle = 'insured-title',
  insuredFirstName = 'insured-first-name',
  insuredConfirmName = 'insured-confirm-name',
  insuredMiddleName = 'insured-middle-name',
  insuredLastName = 'insured-last-name',
  insuredSuffix = 'insured-suffix',
  insuredEmail = 'insured-email',
  insuredSIN = 'insured-sin-social-insurance-number',
  insuredSSN = 'insured-ssn-social-security-number',
  insuredPhoneNumber = 'insured-phone-number',
  insuredPhoneType = 'insured-phone-type',
  insuredPhoneExtension = 'insured-phone-extension',
  insuredAlternatePhoneNumber = 'insured-alternate-phone-number',
  insuredAlternatePhoneType = 'insured-alternate-phone-type',
  insuredAlternatePhoneExtension = 'insured-alternate-phone-extension',
  insuredAlternatePhoneTypeTwo = 'insured-alternate-phone-type-two',
  insuredAlternatePhoneNumberTwo = 'insured-alternate-phone-number-two',
  insuredAlternatePhoneExtensionTwo = 'insured-alternate-phone-extension-two',
  insuredContactTimeOfDay = 'insured-contact-time-of-day',
  insuredGender = 'insured-gender',
  insuredDateOfBirth = 'insured-date-of-birth',
  insuredIsTobaccoUser = 'insured-is-tobacco-user',
  insuredSpouseExists = 'insured-spouse-exists',
  insuredSpouseDateOfBirth = 'insured-spouse-date-of-birth',
  insuredAddressAutocomplete = 'insured-address-autocomplete',
  insuredAddressProvinceOrState = 'insured-address-province-or-state',
  insuredAddressStreet = 'insured-address-street',
  insuredAddressApartment = 'insured-address-apartment',
  insuredAddressCity = 'insured-address-city',
  insuredAddressPostalCode = 'insured-address-postal-code',
  insuredAddressCountry = 'insured-address-country',
  addLegalGuardian = 'add-legal-guardian',
  isLegalGuardianSameAsOwner = 'is-legal-guardian-same-as-owner',
  legalGuardianIs = 'legal-guardian-is',
  legalGuardianIsTheInsured = 'legal-guardian-is-the-insured',
  legalGuardianIsTheLegalGuardian = 'legal-guardian-is-the-legal-guardian',
  legalGuardianConfirmName = 'legal-guardian-confirm-name',
  legalGuardianFirstName = 'legal-guardian-first-name',
  legalGuardianLastName = 'legal-guardian-last-name',
  legalGuardianPrimaryPhoneNumber = 'legal-guardian-primary-phone-number',
  legalGuardianPrimaryPhoneType = 'legal-guardian-primary-phone-type',
  legalGuardianEmail = 'legal-guardian-email',
  legalGuardianDateOfBirth = 'legal-guardian-date-of-birth',
  legalGuardianGender = 'legal-guardian-gender',
  legalGuardianMaritalStatus = 'legal-guardian-marital-status',
  legalGuardianRelationshipToInsured = 'legal-guardian-relationship-to-insured',
  legalGuardianOccupation = 'legal-guardian-occupation',
  legalGuardianOccupationOther = 'legal-guardian-occupation-other',
  legalGuardianBirthCountry = 'legal-guardian-birth-country',
  legalGuardianBirthCountryOther = 'legal-guardian-birth-country-other',
  legalGuardianBirthProvinceOrState = 'legal-guardian-birth-province-or-state',
  legalGuardianAddress = 'legal-guardian-address',
  legalGuardianApartment = 'legal-guardian-apartment',
  legalGuardianCity = 'legal-guardian-city',
  legalGuardianCountry = 'legal-guardian-country',
  legalGuardianProvince = 'legal-guardian-province',
  legalGuardianPostalCode = 'legal-guardian-postal-code',
  legalGuardianPrimaryPhoneExtension = 'legal-guardian-primary-phone-extension',
  legalGuardianSecondaryPhoneNumber = 'legal-guardian-secondary-phone-number',
  legalGuardianSecondaryPhoneType = 'legal-guardian-secondary-phone-type',
  legalGuardianSecondaryPhoneExtension = 'legal-guardian-secondary-phone-extension',
  legalGuardianTertiaryPhoneNumber = 'legal-guardian-tertiary-phone-number',
  legalGuardianTertiaryPhoneType = 'legal-guardian-tertiary-phone-type',
  legalGuardianTertiaryPhoneExtension = 'legal-guardian-tertiary-phone-extension',
  insuredBirthCountry = 'insured-birth-country',
  insuredBirthCountryOtherIs = 'insured-birth-country-other-is',
  insuredBirthCountryOther = 'insured-birth-country-other',
  insuredBirthCity = 'insured-birth-city',
  insuredIsUnitedStatesCitizen = 'insured-is-united-states-citizen',
  insuredNumberOfYearsUnitedStatesCitizen = 'insured-number-of-years-united-states-citizen',
  insuredIsCanadianCitizen = 'insured-is-canadian-citizen',
  insuredNumberOfYearsCanadianCitizen = 'insured-number-of-years-canadian-citizen',
  insuredBirthProvinceOrState = 'insured-birth-province-or-state',
  insuredHasDriversLicence = 'insured-has-drivers-licence',
  insuredIdentificationType = 'insured-identification-type',
  insuredIdentificationDriverLicenseNumber = 'insured-identification-driver-license-number',
  insuredIdentificationDriverLicenseIssuingAuthority = 'insured-identification-driver-license-issuing-authority',
  insuredIdentificationDriverLicenseIssuedOnDate = 'insured-identification-driver-license-issued-on-date',
  insuredIdentificationDriverLicenseExpiredOnDate = 'insured-identification-driver-license-expired-on-date',
  insuredIdentificationGovernmentIssuedPictureIdNumber = 'insured-identification-government-issued-picture-id-number',
  insuredIdentificationGovernmentIssuedPictureIdIssuingAuthority = 'insured-identification-government-issued-picture-id-issuing-authority',
  insuredIdentificationGovernmentIssuedPictureIdIssuedOnDate = 'insured-identification-government-issued-picture-id-issued-on-date',
  insuredIdentificationGovernmentIssuedPictureIdExpiredOnDate = 'insured-identification-government-issued-picture-id-expired-on-date',
  insuredOccupation = 'insured-occupation',
  insuredOccupationOther = 'insured-occupation-other',
  insuredEmployerCompanyName = 'insured-employer-company-name',
  insuredMaritalStatus = 'insured-marital-status',
  insuredElectsAplProvisionsForWholeLifePlans = 'insured-elects-apl-provisions-for-whole-life-plans',

  insuredBankruptcyDate = 'insured-bankruptcy-date',
  insuredHasDeclaredBankruptcy = 'insured-has-declared-bankruptcy',
  insuredBankruptcyType = 'insured-bankruptcy-type',
  insuredBankruptcyPersonalAmount = 'insured-bankruptcy-personal-amount',
  insuredBankruptcyProfessionalCommercialAmount = 'insured-bankruptcy-professional-commercial-amount',
  insuredBankruptcyConsumerProposalAmount = 'insured-bankruptcy-consumer-proposal-amount',
  insuredBankruptcyFiledOrProposedDate = 'insured-bankruptcy-filed-or-proposed-date',

  insuredOtherIncome = 'insured-other-income',
  insuredOtherSourceOfIncome = 'insured-other-source-of-income',
  insuredFinancing = 'insured-financing',
  insuredOtherPurposeOfInsurance = 'insured-other-purpose-of-insurance',
  insuredAgreement = 'insured-agreement',
  insuredAgreementDetails = 'insured-agreement-details',
  termsOfTheAgreementFinancing = 'terms-of-the-agreement-financing',
  insuredEmploymentStatus = 'insured-employment-status',
  insuredWorkCondition = 'insured-work-condition',
  insuredDisability = 'insured-disability',

  insuredBrotherHoldInsuranceContract = 'insured-brother-hold-insurance-contract',
  insuredBrotherLifeAmountInForce = 'insured-brother-life-amount-in-force',
  insuredBrotherCriticalIllnessAmountInForce = 'insured-brother-critical-illness-amount-in-force',

  insuredSisterHoldInsuranceContract = 'insured-sister-hold-insurance-contract',
  insuredSisterLifeAmountInForce = 'insured-sister-life-amount-in-force',
  insuredSisterCriticalIllnessAmountInForce = 'insured-sister-critical-illness-amount-in-force',

  childRiderInfo = 'child-rider-info',
  childRiderFirstName = 'child-rider-first-name',
  childRiderMiddleName = 'child-rider-middle-name',
  childRiderLastName = 'child-rider-last-name',
  childRiderDateOfBirth = 'child-rider-date-of-birth',
  childRiderSex = 'child-rider-sex',
  childRiderHeight = 'child-rider-height',
  childRiderWeight = 'child-rider-weight',
  childRiderRelationship = 'child-rider-relationship',
  childRiderDateAndReasonLastConsultation = 'child-rider-date-and-reason-last-consultation',
  childRiderResults = 'child-rider-results',
  childRiderTreatmentAndFollowUp = 'child-rider-treatment-and-follow-up',
  childRiderPhysicianInformation = 'child-rider-physician-information',
  childRiderPrematureBirth = 'child-rider-premature-birth',
  childRiderMedicalInformation = 'child-rider-medical-information',
  childRiderMedicalInformationDetails = 'child-rider-medical-information-details',
  childRiderOtherMedicalInformation = 'child-rider-other-medical-information',
  childRiderOtherMedicalInformationDetails = 'child-rider-other-medical-information-details',
  childRiderNotYetConsulted = 'child-rider-not-yet-consulted',
  childRiderNotYetConsultedDetails = 'child-rider-not-yet-consulted-details',
  childRiderFamilyHistory = 'child-rider-family-history',
  childRiderHistoryDetails = 'child-rider-history-details',
  childRiderAddress = 'child-rider-address',
  childRiderApartment = 'child-rider-apartment',
  childRiderCity = 'child-rider-city',
  childRiderPostalCode = 'child-rider-postal-code',
  childRiderOccupation = 'child-rider-occupation',
  childRiderStreet = 'child-rider-street',

  childRiderLimiter = 'child-rider-limiter',
  childrenEndorsementLimiter = 'children-endorsement-limiter',

  childCriticalIllnessRiderInfo = 'child-critical-illness-rider-info',
  childCriticalIllnessRiderFirstName = 'child-critical-illness-rider-first-name',
  childCriticalIllnessRiderMiddleName = 'child-critical-illness-rider-middle-name',
  childCriticalIllnessRiderLastName = 'child-critical-illness-rider-last-name',
  childCriticalIllnessRiderDateOfBirth = 'child-critical-illness-rider-date-of-birth',
  childCriticalIllnessRiderSex = 'child-critical-illness-rider-sex',
  childCriticalIllnessRiderHeight = 'child-critical-illness-rider-height',
  childCriticalIllnessRiderWeight = 'child-critical-illness-rider-weight',
  childCriticalIllnessRiderRelationship = 'child-critical-illness-rider-relationship',
  childCriticalIllnessRiderDateAndReasonLastConsultation = 'child-critical-illness-rider-date-and-reason-last-consultation',
  childCriticalIllnessRiderResults = 'child-critical-illness-rider-results',
  childCriticalIllnessRiderTreatmentAndFollowUp = 'child-critical-illness-rider-treatment-and-follow-up',
  childCriticalIllnessRiderPhysicianInformation = 'child-critical-illness-rider-physician-information',
  childCriticalIllnessRiderPrematureBirth = 'child-critical-illness-rider-premature-birth',
  childCriticalIllnessRiderMedicalInformation = 'child-critical-illness-rider-medical-information',
  childCriticalIllnessRiderMedicalInformationDetails = 'child-critical-illness-rider-medical-information-details',
  childCriticalIllnessRiderOtherMedicalInformation = 'child-critical-illness-rider-other-medical-information',
  childCriticalIllnessRiderOtherMedicalInformationDetails = 'child-critical-illness-rider-other-medical-information-details',
  childCriticalIllnessRiderNotYetConsulted = 'child-critical-illness-rider-not-yet-consulted',
  childCriticalIllnessRiderNotYetConsultedDetails = 'child-critical-illness-rider-not-yet-consulted-details',
  childCriticalIllnessRiderFamilyHistory = 'child-critical-illness-rider-family-history',
  childCriticalIllnessRiderHistoryDetails = 'child-critical-illness-rider-history-details',
  childCriticalIllnessRiderAddress = 'child-critical-illness-rider-address',
  childCriticalIllnessRiderApartment = 'child-critical-illness-rider-apartment',
  childCriticalIllnessRiderCity = 'child-critical-illness-rider-city',
  childCriticalIllnessRiderPostalCode = 'child-critical-illness-rider-postal-code',
  childCriticalIllnessRiderOccupation = 'child-critical-illness-rider-occupation',
  childCriticalIllnessRiderStreet = 'child-critical-illness-rider-street',

  replacementInsuranceChildRiderInfo = 'replacement-insurance-child-rider-info',
  replacementInsuranceChildRiderName = 'replacement-insurance-child-rider-name',
  replacementInsuranceChildRiderFirstName = 'replacement-insurance-child-rider-first-name',
  replacementInsuranceChildRiderLastName = 'replacement-insurance-child-rider-last-name',
  replacementInsuranceChildRiderChoices = 'replacement-insurance-child-rider-choices',
  replacementInsuranceChildRiderAmount = 'replacement-insurance-child-rider-amount',
  replacementInsuranceChildRiderCompanyName = 'replacement-insurance-child-rider-company-name',
  replacementInsuranceChildRiderDate = 'replacement-insurance-child-rider-date',
  replacementInsuranceChildRiderPending = 'replacement-insurance-child-rider-pending',
  replacementInsuranceChildRiderHistory = 'replacement-insurance-child-rider-history',

  replacementInsuranceChildEndorsementHistory = 'replacement-insurance-child-endorsement-history',
  replacementInsuranceChildCriticalIllnessRiderInfo = 'replacement-insurance-child-critical-illness-rider-info',
  replacementInsuranceChildCriticalIllnessRiderName = 'replacement-insurance-child-critical-illness-rider-name',
  replacementInsuranceChildCriticalIllnessRiderFirstName = 'replacement-insurance-child-critical-illness-rider-first-name',
  replacementInsuranceChildCriticalIllnessRiderLastName = 'replacement-insurance-child-critical-illness-rider-last-name',
  replacementInsuranceChildCriticalIllnessRiderChoices = 'replacement-insurance-child-critical-illness-rider-choices',
  replacementInsuranceChildCriticalIllnessRiderAmount = 'replacement-insurance-child-critical-illness-rider-amount',
  replacementInsuranceChildCriticalIllnessRiderCompanyName = 'replacement-insurance-child-critical-illness-rider-company-name',
  replacementInsuranceChildCriticalIllnessRiderDate = 'replacement-insurance-child-critical-illness-rider-date',
  replacementInsuranceChildCriticalIllnessRiderPending = 'replacement-insurance-child-critical-illness-rider-pending',

  replacementInsuranceInvalidityMonthlyBenefit = 'replacement-insurance-invalidity-monthly-benefit',
  replacementInsuranceInvalidityType = 'replacement-insurance-invalidity-type',
  replacementInsuranceInvalidityAccidentEliminationPeriod = 'replacement-insurance-invalidity-accident-elimination-period',
  replacementInsuranceInvalidityAccidentBenefitPeriod = 'replacement-insurance-invalidity-accident-benefit-period',
  replacementInsuranceInvaliditySicknessEliminationPeriod = 'replacement-insurance-invalidity-sickness-elimination-period',
  replacementInsuranceInvaliditySicknessBenefitPeriod = 'replacement-insurance-invalidity-sickness-benefit-period',

  replacementInsuranceIncomeProtectionInvalidityType = 'replacement-insurance-income-protection-invalidity-type',
  replacementInsuranceIncomeProtectionMonthlyBenefit = 'replacement-insurance-income-protection-monthly-benefit',
  replacementInsuranceIncomeProtectionTaxableBenefit = 'replacement-insurance-income-protection-taxable-benefit',

  pastChildRiderApplicationInfo = 'past-child-rider-application-info',
  pastChildRiderApplicationName = 'past-child-rider-application-name',
  pastChildRiderApplicationFirstName = 'past-child-rider-application-first-name',
  pastChildRiderApplicationLastName = 'past-child-rider-application-last-name',
  pastChildRiderApplicationChoices = 'past-child-rider-application-choices',
  pastChildRiderApplicationDate = 'past-child-rider-application-date',
  pastChildRiderApplicationReason = 'past-child-rider-application-reason',
  pastChildRiderApplication = 'past-child-rider-application',

  pastChildCriticalIllnessRiderApplication = 'past-child-critical-illness-rider-application',
  pastChildCriticalIllnessRiderApplicationInfo = 'past-child-critical-illness-rider-application-info',
  pastChildCriticalIllnessRiderApplicationName = 'past-child-critical-illness-rider-application-name',
  pastChildCriticalIllnessRiderApplicationFirstName = 'past-child-critical-illness-rider-application-first-name',
  pastChildCriticalIllnessRiderApplicationLastName = 'past-child-critical-illness-rider-application-last-name',
  pastChildCriticalIllnessRiderApplicationChoices = 'past-child-critical-illness-rider-application-choices',
  pastChildCriticalIllnessRiderApplicationDate = 'past-child-critical-illness-rider-application-date',
  pastChildCriticalIllnessRiderApplicationReason = 'past-child-critical-illness-rider-application-reason',

  secondaryChildRiderInfo = 'secondary-child-rider-info',
  secondaryChildRiderFirstName = 'secondary-child-rider-first-name',
  secondaryChildRiderMiddleName = 'secondary-child-rider-middle-name',
  secondaryChildRiderLastName = 'secondary-child-rider-last-name',
  secondaryChildRiderDateOfBirth = 'secondary-child-rider-date-of-birth',
  secondaryChildRiderSex = 'secondary-child-rider-sex',
  secondaryChildRiderHeight = 'secondary-child-rider-height',
  secondaryChildRiderWeight = 'secondary-child-rider-weight',
  secondaryChildRiderRelationship = 'secondary-child-rider-relationship',
  secondaryChildRiderDateAndReasonLastConsultation = 'secondary-child-rider-date-and-reason-last-consultation',
  secondaryChildRiderResults = 'secondary-child-rider-results',
  secondaryChildRiderTreatmentAndFollowUp = 'secondary-child-rider-treatment-and-follow-up',
  secondaryChildRiderPhysicianInformation = 'secondary-child-rider-physician-information',
  secondaryChildRiderPrematureBirth = 'secondary-child-rider-premature-birth',
  secondaryChildRiderMedicalInformation = 'secondary-child-rider-medical-information',
  secondaryChildRiderMedicalInformationDetails = 'secondary-child-rider-medical-information-details',
  secondaryChildRiderOtherMedicalInformation = 'secondary-child-rider-other-medical-information',
  secondaryChildRiderOtherMedicalInformationDetails = 'secondary-child-rider-other-medical-information-details',
  secondaryChildRiderNotYetConsulted = 'secondary-child-rider-not-yet-consulted',
  secondaryChildRiderNotYetConsultedDetails = 'secondary-child-rider-not-yet-consulted-details',
  secondaryChildRiderFamilyHistory = 'secondary-child-rider-family-history',
  secondaryChildRiderHistoryDetails = 'secondary-child-rider-history-details',
  secondaryChildRiderAddress = 'secondary-child-rider-address',
  secondaryChildRiderApartment = 'secondary-child-rider-apartment',
  secondaryChildRiderCity = 'secondary-child-rider-city',
  secondaryChildRiderPostalCode = 'secondary-child-rider-postal-code',
  secondaryChildRiderOccupation = 'secondary-child-rider-occupation',
  secondaryChildRiderStreet = 'secondary-child-rider-street',

  secondaryChildRiderLimiter = 'secondary-child-rider-limiter',
  secondaryChildrenEndorsementLimiter = 'secondary-children-endorsement-limiter',

  secondaryChildCriticalIllnessRiderInfo = 'secondary-child-critical-illness-rider-info',
  secondaryChildCriticalIllnessRiderFirstName = 'secondary-child-critical-illness-rider-first-name',
  secondaryChildCriticalIllnessRiderMiddleName = 'secondary-child-critical-illness-rider-middle-name',
  secondaryChildCriticalIllnessRiderLastName = 'secondary-child-critical-illness-rider-last-name',
  secondaryChildCriticalIllnessRiderDateOfBirth = 'secondary-child-critical-illness-rider-date-of-birth',
  secondaryChildCriticalIllnessRiderSex = 'secondary-child-critical-illness-rider-sex',
  secondaryChildCriticalIllnessRiderHeight = 'secondary-child-critical-illness-rider-height',
  secondaryChildCriticalIllnessRiderWeight = 'secondary-child-critical-illness-rider-weight',
  secondaryChildCriticalIllnessRiderRelationship = 'secondary-child-critical-illness-rider-relationship',
  secondaryChildCriticalIllnessRiderDateAndReasonLastConsultation = 'secondary-child-critical-illness-rider-date-and-reason-last-consultation',
  secondaryChildCriticalIllnessRiderResults = 'secondary-child-critical-illness-rider-results',
  secondaryChildCriticalIllnessRiderTreatmentAndFollowUp = 'secondary-child-critical-illness-rider-treatment-and-follow-up',
  secondaryChildCriticalIllnessRiderPhysicianInformation = 'secondary-child-critical-illness-rider-physician-information',
  secondaryChildCriticalIllnessRiderPrematureBirth = 'secondary-child-critical-illness-rider-premature-birth',
  secondaryChildCriticalIllnessRiderMedicalInformation = 'secondary-child-critical-illness-rider-medical-information',
  secondaryChildCriticalIllnessRiderMedicalInformationDetails = 'secondary-child-critical-illness-rider-medical-information-details',
  secondaryChildCriticalIllnessRiderOtherMedicalInformation = 'secondary-child-critical-illness-rider-other-medical-information',
  secondaryChildCriticalIllnessRiderOtherMedicalInformationDetails = 'secondary-child-critical-illness-rider-other-medical-information-details',
  secondaryChildCriticalIllnessRiderNotYetConsulted = 'secondary-child-critical-illness-rider-not-yet-consulted',
  secondaryChildCriticalIllnessRiderNotYetConsultedDetails = 'secondary-child-critical-illness-rider-not-yet-consulted-details',
  secondaryChildCriticalIllnessRiderFamilyHistory = 'secondary-child-critical-illness-rider-family-history',
  secondaryChildCriticalIllnessRiderHistoryDetails = 'secondary-child-critical-illness-rider-history-details',
  secondaryChildCriticalIllnessRiderAddress = 'secondary-child-critical-illness-rider-address',
  secondaryChildCriticalIllnessRiderApartment = 'secondary-child-critical-illness-rider-apartment',
  secondaryChildCriticalIllnessRiderCity = 'secondary-child-critical-illness-rider-city',
  secondaryChildCriticalIllnessRiderPostalCode = 'secondary-child-critical-illness-rider-postal-code',
  secondaryChildCriticalIllnessRiderOccupation = 'secondary-child-critical-illness-rider-occupation',
  secondaryChildCriticalIllnessRiderStreet = 'secondary-child-critical-illness-rider-street',

  secondaryPastChildRiderApplication = 'secondary-past-child-rider-application',
  secondaryPastChildRiderApplicationInfo = 'secondary-past-child-rider-application-info',
  secondaryPastChildRiderApplicationName = 'secondary-past-child-rider-application-name',
  secondaryPastChildRiderApplicationFirstName = 'secondary-past-child-rider-application-first-name',
  secondaryPastChildRiderApplicationLastName = 'secondary-past-child-rider-application-last-name',
  secondaryPastChildRiderApplicationChoices = 'secondary-past-child-rider-application-choices',
  secondaryPastChildRiderApplicationDate = 'secondary-past-child-rider-application-date',
  secondaryPastChildRiderApplicationReason = 'secondary-past-child-rider-application-reason',

  secondaryPastChildCriticalIllnessRiderApplication = 'secondary-past-child-critical-illness-rider-application',
  secondaryPastChildCriticalIllnessRiderApplicationInfo = 'secondary-past-child-critical-illness-rider-application-info',
  secondaryPastChildCriticalIllnessRiderApplicationName = 'secondary-past-child-critical-illness-rider-application-name',
  secondaryPastChildCriticalIllnessRiderApplicationFirstName = 'secondary-past-child-critical-illness-rider-application-first-name',
  secondaryPastChildCriticalIllnessRiderApplicationLastName = 'secondary-past-child-critical-illness-rider-application-last-name',
  secondaryPastChildCriticalIllnessRiderApplicationChoices = 'secondary-past-child-critical-illness-rider-application-choices',
  secondaryPastChildCriticalIllnessRiderApplicationDate = 'secondary-past-child-critical-illness-rider-application-date',
  secondaryPastChildCriticalIllnessRiderApplicationReason = 'secondary-past-child-critical-illness-rider-application-reason',

  secondaryReplacementInsuranceChildRiderHistory = 'secondary-replacement-insurance-child-rider-history',
  secondaryReplacementInsuranceChildRiderInfo = 'secondary-replacement-insurance-child-rider-info',
  secondaryReplacementInsuranceChildRiderName = 'secondary-replacement-insurance-child-rider-name',
  secondaryReplacementInsuranceChildRiderFirstName = 'secondary-replacement-insurance-child-rider-first-name',
  secondaryReplacementInsuranceChildRiderLastName = 'secondary-replacement-insurance-child-rider-last-name',
  secondaryReplacementInsuranceChildRiderChoices = 'secondary-replacement-insurance-child-rider-choices',
  secondaryReplacementInsuranceChildRiderAmount = 'secondary-replacement-insurance-child-rider-amount',
  secondaryReplacementInsuranceChildRiderCompanyName = 'secondary-replacement-insurance-child-rider-company-name',
  secondaryReplacementInsuranceChildRiderDate = 'secondary-replacement-insurance-child-rider-date',
  secondaryReplacementInsuranceChildRiderPending = 'secondary-replacement-insurance-child-rider-pending',

  secondaryInsuranceChildEndorsementHistory = 'secondary-insurance-child-endorsement-history',
  secondaryReplacementInsuranceChildCriticalIllnessRiderInfo = 'secondary-replacement-insurance-child-critical-illness-rider-info',
  secondaryReplacementInsuranceChildCriticalIllnessRiderName = 'secondary-replacement-insurance-child-critical-illness-rider-name',
  secondaryReplacementInsuranceChildCriticalIllnessRiderFirstName = 'secondary-replacement-insurance-child-critical-illness-rider-first-name',
  secondaryReplacementInsuranceChildCriticalIllnessRiderLastName = 'secondary-replacement-insurance-child-critical-illness-rider-last-name',
  secondaryReplacementInsuranceChildCriticalIllnessRiderChoices = 'secondary-replacement-insurance-child-critical-illness-rider-choices',
  secondaryReplacementInsuranceChildCriticalIllnessRiderAmount = 'secondary-replacement-insurance-child-critical-illness-rider-amount',
  secondaryReplacementInsuranceChildCriticalIllnessRiderCompanyName = 'secondary-replacement-insurance-child-critical-illness-rider-company-name',
  secondaryReplacementInsuranceChildCriticalIllnessRiderDate = 'secondary-replacement-insurance-child-critical-illness-rider-date',
  secondaryReplacementInsuranceChildCriticalIllnessRiderPending = 'secondary-replacement-insurance-child-critical-illness-rider-pending',

  numberOfChildren = 'number-of-children',
  childrenEducationCost = 'children-education-cost',
  insuredIsFraternalMember = 'insured-is-fraternal-member',
  lessExpensiveEducationFundRecommendedAmount = 'less-expensive-education-recommended-amount',
  moreExpensiveEducationFundRecommendedAmount = 'more-expensive-education-recommended-amount',
  useCalculator = 'use-calculator',
  numberOfChildrenCovered = 'number-of-children-covered',
  numberOfYearsPerChild = 'number-of-years-per-child',
  amountPerSchoolYear = 'amount-per-school-year',
  customEducationFundRecommendedAmount = 'custom-education-fund-recommended-amount',
  insuredAnnualIncome = 'insured-annual-income',
  insuredAnnualIncomeFromAllSources = 'insured-annual-income-from-all-sources',
  insuredAnnualIncomeMultiplier10 = 'insured-annual-income-multiplier-10',
  insuredAnnualIncomeMultiplier20 = 'insured-annual-income-multiplier-20',
  insuredAnnualIncomeMultiplier30 = 'insured-annual-income-multiplier-30',
  insuredTotalLifeCoverageExcludingWorkGroupBenefits = 'insured-total-life-coverage-excluding-work-group-benefits',
  insuredNetWorth = 'insured-net-worth',
  financialDataSavingsLifeInsurance = 'financial-data-savings-life-insurance',
  financialDataSavingsAccumulatedSavings = 'financial-data-savings-accumulated-savings',
  financialDataSavingsRegistered = 'financial-data-savings-registred',
  financialDataSavingsNonRegistered = 'financial-data-savings-non-registred',
  financialDataSavingsOtherSavings = 'financial-data-savings-other-savings',

  personalMedicalQuestionnaire = 'personal-medical-questionnaire',

  financialDataDebtsMortgage = 'financial-data-debts-mortgage',
  financialDataDebtsUnpaidDebts = 'financial-data-debts-unpaid-debts',
  financialDataDebtsFamilyNeeds = 'financial-data-debts-abf-family-needs',
  financialDataDebtsHeritage = 'financial-data-debts-heritage',
  financialDataDebtsFuneral = 'financial-data-debts-funeral',
  financialDataSummaryTotalSavings = 'financial-data-summary-total-savings',
  financialDataSummaryTotalExpenses = 'financial-data-summary-total-expenses',
  financialDataSummaryTotalNeededAmount = 'financial-data-summary-total-needed-amount',

  otherLifeInsurancePoliciesExists = 'other-life-insurance-policies-exists',
  otherLifeInsurancePoliciesCoverageAmount = 'other-life-insurance-policies-coverage',
  otherLifeInsurancePolicesWillBeReplaced = 'other-life-insurance-policies-will-be-replaced',
  otherLifeInsurancePoliciesWillBeVoided = 'other-life-insurance-policies-will-be-voided',
  otherLifeInsurancePolicyNumber = 'other-life-insurance-policy-number',
  otherLifeInsuranceAppliedWithin90Days = 'other-life-insurance-applied-within-90-days',
  otherLifeInsuranceHasPendingReplacementInsurance = 'other-life-insurance-has-pending-replacement-insurance',
  otherLifeInsuranceWillReplacementBeSection1035Exchange = 'other-life-insurance-will-replacement-be-section-1035-exchange',
  externalOrInternalForm = 'external-or-internal-form',
  replacementInsurance = 'replacement-insurance',
  replacementInsuranceChoices = 'replacement-insurance-choices',
  replacementInsuranceCompanyName = 'replacement-insurance-company-name',
  replacementInsurancePolicyNumber = 'replacement-insurance-policy-number',
  replacementInsuranceIssueDate = 'replacement-insurance-issue-date',
  replacementInsuranceCoverageAmount = 'replacement-insurance-coverage-amount',
  replacementInsurancePlanType = 'replacement-insurance-plan-type',
  replacementInsuranceBusinessOrPersonal = 'replacement-insurance-business-or-personal',

  replacementInsuranceAccidentalDeath = 'replacement-insurance-accidental-death',
  replacementInsurancePending = 'replacement-insurance-pending',
  replacementInsuranceReplacing = 'replacement-insurance-replacing',
  replacementInsuranceCompanyNameOther = 'replacement-insurance-company-name-other',

  otherIndividualLifeInsurancePoliciesInForce = 'other-individual-life-insurance-policies-in-force',
  otherIndividualLifeInsurancePoliciesCoverageAmount = 'other-individual-life-insurance-policies-coverage',
  otherIndividualLifeInsurancePoliciesInForceWithCarrier = 'other-individual-life-insurance-policies-in-force-with-carrier',
  otherIndividualLifeInsurancePoliciesInForceWithCarrierCoverage = 'other-individual-life-insurance-policies-in-force-with-carrier-coverage',

  // TODO= remove these when the warnings are no longer fields that require node ids
  existingCoverageExceedsMaximum = 'existing-coverage-exceeds-maximum',
  existingCarrierCoverageExceedsMaximum = 'existing-carrier-coverage-exceeds-maximum',

  liabilitiesMortgageBalanceAmount = 'liabilities-mortgage-balance-amount',
  liabilitiesTotalDebtAmount = 'liabilities-total-debt-amount',
  assetsSavings = 'assets-savings-amount',
  useNeedsAnalysis = 'use-needs-analysis',
  finalExpensesAmount = 'final-expenses-amount',
  numberOfYearsToProvideForDependents = 'number-of-years-to-provide-dependents',
  insuredCurrentHealth = 'insured-current-health',
  insuredFinancialGoals = 'insured-financial-goals',
  insuredCoverageDurationType = 'insured-coverage-duration-type',
  insuredCoverageDuration = 'insured-coverage-duration',
  insuredPurposeOfInsurance = 'insured-purpose-of-insurance',
  insuredCostPreference = 'insured-cost-preference',
  insuredEnglishOrFrench = 'insured-english-or-french',
  insuredHeight = 'insured-height',
  insuredHeightUnit = 'insured-height-unit',
  insuredWeight = 'insured-weight',
  insuredWeightUnit = 'insured-weight-unit',
  insuredWeightChange = 'insured-weight-change',
  insuredWeightChangeOfLbs = 'insured-weight-change-of-lbs',
  insuredWeightGainLoss = 'insured-weight-gain-loss',
  insuredWeightChangeReason = 'insured-weight-change-reason',
  insuredMedProviderName = 'insured-med-provider-name',
  insuredMedProviderAddress = 'insured-med-provider-address',
  insuredMedProviderApartment = 'insured-med-provider-apartment',
  insuredMedProviderCity = 'insured-med-provider-city',
  insuredMedProviderState = 'insured-med-provider-state',
  insuredMedProviderZipCode = 'insured-med-provider-zipcode',
  insuredMedProviderPhone = 'insured-med-provider-phone',
  insuredMedProviderVisitDate = 'insured-med-provider-visit-date',
  insuredMedProviderVisitReason = 'insured-med-provider-visit-reason',
  insuredMedProviderTreatment = 'insured-med-provider-treatment',
  insuredMedProviderTreatmentGiven = 'insured-med-provider-treatment-given',
  insuredMedProviderVisitDiagnosis = 'insured-med-provider-visit-diagnosis',
  insuredMedProviderVisitPrescriptionQuestions = 'insured-med-provider-visit-prescription-questions',
  insuredMedProviderVisitPrescriptionList = 'insured-med-provider-visit-prescription-list',

  healthInsuredDepressionIsSingleEpisode = 'health-insured-depression-is-single-episode',
  healthInsuredDepressionDiagnosticDate = 'health-insured-depression-diagnostic-date',
  healthInsuredDepressionMedicationWasPrescribed = 'health-insured-depression-medication-was-prescribed',
  healthInsuredDepressionHasConsultedDoctors = 'health-insured-depression-has-consulted-doctors',
  healthInsuredDepressionHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-depression-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredDepressionWentToEmergencyDepartment = 'health-insured-depression-went-to-emergency-department',
  healthInsuredDepressionWasHospitalized = 'health-insured-depression-was-hospitalized',
  healthInsuredDepressionMedicationDetails = 'health-insured-depression-medication-details',
  healthInsuredDepressionMedicationName = 'health-insured-depression-medication-name',
  healthInsuredDepressionMedicationDosage = 'health-insured-depression-medication-dosage',
  healthInsuredDepressionMedicationStartDate = 'health-insured-depression-medication-start-date',
  healthInsuredDepressionMedicationEndDate = 'health-insured-depression-medication-end-date',
  healthInsuredDepressionMedicationStillUses = 'health-insured-depression-medication-still-uses',
  healthInsuredDepressionMedicationHasChanged = 'health-insured-depression-medication-has-changed',
  healthInsuredDepressionMedicationHasChangedDetail = 'health-insured-depression-medication-has-changed-details',

  healthInsuredDepressionHasOrWillConsultDoctorsDetails = 'health-insured-depression-has-or-will-consult-doctors-details',
  healthInsuredDepressionHasOrWillConsultDoctorsProfession = 'health-insured-depression-has-or-will-consult-doctors-profession',
  healthInsuredDepressionHasOrWillConsultDoctorsProfessionOther = 'health-insured-depression-has-or-will-consult-doctors-profession-other',
  healthInsuredDepressionHasOrWillConsultDoctorsName = 'health-insured-depression-has-or-will-consult-doctors-name',
  healthInsuredDepressionHasOrWillConsultDoctorsAddress = 'health-insured-depression-has-or-will-consult-doctors-address',
  healthInsuredDepressionHasOrWillConsultDoctorsFirstDate = 'health-insured-depression-has-or-will-consult-doctors-first-date',
  healthInsuredDepressionHasOrWillConsultDoctorsLastDate = 'health-insured-depression-has-or-will-consult-doctors-last-date',
  healthInsuredDepressionHasOrWillConsultDoctorsResult = 'health-insured-depression-has-or-will-consult-doctors-result',
  healthInsuredDepressionHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-depression-has-or-will-consult-doctors-frequency-number',
  healthInsuredDepressionHasOrWillConsultDoctorsFrequency = 'health-insured-depression-has-or-will-consult-doctors-frequency',
  healthInsuredDepressionHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-depression-has-or-will-consult-doctors-further-consultation',
  healthInsuredDepressionHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-depression-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredDepressionWentToEmergencyDepartmentDetails = 'health-insured-depression-went-to-emergency-department-details',
  healthInsuredDepressionWentToEmergencyDepartmentDate = 'health-insured-depression-went-to-emergency-department-date',
  healthInsuredDepressionWentToEmergencyDepartmentLocation = 'health-insured-depression-went-to-emergency-department-location',

  healthInsuredDepressionWasHospitalizedDetails = 'health-insured-depression-was-hospitalized-details',
  healthInsuredDepressionWasHospitalizedDate = 'health-insured-depression-was-hospitalized-date',
  healthInsuredDepressionWasHospitalizedFrequencyNumber = 'health-insured-depression-was-hospitalized-frequency-number',
  healthInsuredDepressionWasHospitalizedFrequency = 'health-insured-depression-was-hospitalized-frequency',
  healthInsuredDepressionWasHospitalizedLocation = 'health-insured-depression-was-hospitalized-location',

  healthInsuredDepressionSuicidalThoughtsDetails = 'health-insured-depression-suicidal-thoughts-details',
  healthInsuredDepressionSuicidalThoughtsDate = 'health-insured-depression-suicidal-thoughts-date',

  healthInsuredSuicideAttemptMedicationWasPrescribed = 'health-insured-suicide-attempt-medication-was-prescribed',
  healthInsuredSuicideAttemptHasConsultedDoctors = 'health-insured-suicide-attempt-has-consulted-doctors',
  healthInsuredSuicideAttemptHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-suicide-attempt-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredSuicideAttemptWasHospitalized = 'health-insured-suicide-attempt-was-hospitalized',
  healthInsuredSuicideAttemptDate = 'health-insured-suicide-attempt-date',
  healthInsuredSuicideAttemptReason = 'health-insured-suicide-attempt-reason',

  healthInsuredDepressionSuicideAttemptsDetails = 'health-insured-depression-suicide-attempts-details',
  healthInsuredDepressionSuicideAttemptsDate = 'health-insured-depression-suicide-attempts-date',
  healthInsuredDepressionHadAbsenceDetails = 'health-insured-depression-had-absence-details',
  healthInsuredDepressionAbsenceStartDate = 'health-insured-depression-absence-start-date',
  healthInsuredDepressionAbsenceFrequencyNumber = 'health-insured-depression-absence-frequency-number',
  healthInsuredDepressionAbsenceFrequency = 'health-insured-depression-absence-frequency',

  healthInsuredAnxietyMedicationDetails = 'health-insured-anxiety-medication-details',
  healthInsuredAnxietyMedicationName = 'health-insured-anxiety-medication-name',
  healthInsuredAnxietyMedicationDosage = 'health-insured-anxiety-medication-dosage',
  healthInsuredAnxietyMedicationStartDate = 'health-insured-anxiety-medication-start-date',
  healthInsuredAnxietyMedicationEndDate = 'health-insured-anxiety-medication-end-date',
  healthInsuredAnxietyMedicationStillUses = 'health-insured-anxiety-medication-still-uses',
  healthInsuredAnxietyMedicationHasChanged = 'health-insured-anxiety-medication-has-changed',
  healthInsuredAnxietyMedicationHasChangedDetails = 'health-insured-anxiety-medication-has-changed-details',

  healthInsuredAnxietyHasOrWillConsultDoctorsDetails = 'health-insured-anxiety-has-or-will-consult-doctors-details',
  healthInsuredAnxietyHasOrWillConsultDoctorsProfession = 'health-insured-anxiety-has-or-will-consult-doctors-profession',
  healthInsuredAnxietyHasOrWillConsultDoctorsProfessionOther = 'health-insured-anxiety-has-or-will-consult-doctors-profession-other',
  healthInsuredAnxietyHasOrWillConsultDoctorsName = 'health-insured-anxiety-has-or-will-consult-doctors-name',
  healthInsuredAnxietyHasOrWillConsultDoctorsAddress = 'health-insured-anxiety-has-or-will-consult-doctors-address',
  healthInsuredAnxietyHasOrWillConsultDoctorsFirstDate = 'health-insured-anxiety-has-or-will-consult-doctors-first-date',
  healthInsuredAnxietyHasOrWillConsultDoctorsLastDate = 'health-insured-anxiety-has-or-will-consult-doctors-last-date',
  healthInsuredAnxietyHasOrWillConsultDoctorsResult = 'health-insured-anxiety-has-or-will-consult-doctors-result',
  healthInsuredAnxietyHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-anxiety-has-or-will-consult-doctors-frequency-number',
  healthInsuredAnxietyHasOrWillConsultDoctorsFrequency = 'health-insured-anxiety-has-or-will-consult-doctors-frequency',
  healthInsuredAnxietyHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-anxiety-has-or-will-consult-doctors-further-consultation',
  healthInsuredAnxietyHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-anxiety-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredAnxietyWentToEmergencyDepartmentDetails = 'health-insured-anxiety-went-to-emergency-department-details',
  healthInsuredAnxietyWentToEmergencyDepartmentDate = 'health-insured-anxiety-went-to-emergency-department-date',
  healthInsuredAnxietyWentToEmergencyDepartmentLocation = 'health-insured-anxiety-went-to-emergency-department-location',

  healthInsuredAnxietyWasHospitalizedDetails = 'health-insured-anxiety-was-hospitalized-details',
  healthInsuredAnxietyWasHospitalizedDate = 'health-insured-anxiety-was-hospitalized-date',
  healthInsuredAnxietyWasHospitalizedFrequencyNumber = 'health-insured-anxiety-was-hospitalized-frequency-number',
  healthInsuredAnxietyWasHospitalizedFrequency = 'health-insured-anxiety-was-hospitalized-frequency',
  healthInsuredAnxietyWasHospitalizedLocation = 'health-insured-anxiety-was-hospitalized-location',

  healthInsuredAnxietySuicidalThoughtsDetails = 'health-insured-anxiety-suicidal-thoughts-details',
  healthInsuredAnxietySuicidalThoughtsDate = 'health-insured-anxiety-suicidal-thoughts-date',
  healthInsuredAnxietySuicideAttemptsDetails = 'health-insured-anxiety-suicide-attempts-details',
  healthInsuredAnxietySuicideAttemptsDate = 'health-insured-anxiety-suicide-attempts-date',
  healthInsuredAnxietyHadAbsenceDetails = 'health-insured-anxiety-had-absence-details',
  healthInsuredAnxietyAbsenceStartDate = 'health-insured-anxiety-absence-start-date',
  healthInsuredAnxietyAbsenceFrequencyNumber = 'health-insured-anxiety-absence-frequency-number',
  healthInsuredAnxietyAbsenceFrequency = 'health-insured-anxiety-absence-frequency',

  healthInsuredPanicAttacksMedicationDetails = 'health-insured-panic-attacks-medication-details',
  healthInsuredPanicAttacksMedicationName = 'health-insured-panic-attacks-medication-name',
  healthInsuredPanicAttacksMedicationDosage = 'health-insured-panic-attacks-medication-dosage',
  healthInsuredPanicAttacksMedicationStartDate = 'health-insured-panic-attacks-medication-start-date',
  healthInsuredPanicAttacksMedicationEndDate = 'health-insured-panic-attacks-medication-end-date',
  healthInsuredPanicAttacksMedicationStillUses = 'health-insured-panic-attacks-medication-still-uses',
  healthInsuredPanicAttacksMedicationHasChanged = 'health-insured-panic-attacks-medication-has-changed',
  healthInsuredPanicAttacksMedicationHasChangedDetails = 'health-insured-panic-attacks-medication-has-changed-details',

  healthInsuredPanicAttacksHasOrWillConsultDoctorsDetails = 'health-insured-panic-attacks-has-or-will-consult-doctors-details',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsProfession = 'health-insured-panic-attacks-has-or-will-consult-doctors-profession',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsProfessionOther = 'health-insured-panic-attacks-has-or-will-consult-doctors-profession-other',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsName = 'health-insured-panic-attacks-has-or-will-consult-doctors-name',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsAddress = 'health-insured-panic-attacks-has-or-will-consult-doctors-address',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsFirstDate = 'health-insured-panic-attacks-has-or-will-consult-doctors-first-date',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsLastDate = 'health-insured-panic-attacks-has-or-will-consult-doctors-last-date',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsResult = 'health-insured-panic-attacks-has-or-will-consult-doctors-result',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-panic-attacks-has-or-will-consult-doctors-frequency-number',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsFrequency = 'health-insured-panic-attacks-has-or-will-consult-doctors-frequency',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-panic-attacks-has-or-will-consult-doctors-further-consultation',
  healthInsuredPanicAttacksHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-panic-attacks-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredPanicAttacksWentToEmergencyDepartmentDetails = 'health-insured-panic-attacks-went-to-emergency-department-details',
  healthInsuredPanicAttacksWentToEmergencyDepartmentDate = 'health-insured-panic-attacks-went-to-emergency-department-date',
  healthInsuredPanicAttacksWentToEmergencyDepartmentLocation = 'health-insured-panic-attacks-went-to-emergency-department-location',

  healthInsuredPanicAttacksWasHospitalizedDetails = 'health-insured-panic-attacks-was-hospitalized-details',
  healthInsuredPanicAttacksWasHospitalizedDate = 'health-insured-panic-attacks-was-hospitalized-date',
  healthInsuredPanicAttacksWasHospitalizedFrequencyNumber = 'health-insured-panic-attacks-was-hospitalized-frequency-number',
  healthInsuredPanicAttacksWasHospitalizedFrequency = 'health-insured-panic-attacks-was-hospitalized-frequency',
  healthInsuredPanicAttacksWasHospitalizedLocation = 'health-insured-panic-attacks-was-hospitalized-location',

  healthInsuredPanicAttacksSuicidalThoughtsDetails = 'health-insured-panic-attacks-suicidal-thoughts-details',
  healthInsuredPanicAttacksSuicidalThoughtsDate = 'health-insured-panic-attacks-suicidal-thoughts-date',
  healthInsuredPanicAttacksSuicideAttemptsDetails = 'health-insured-panic-attacks-suicide-attempts-details',
  healthInsuredPanicAttacksSuicideAttemptsDate = 'health-insured-panic-attacks-suicide-attempts-date',
  healthInsuredPanicAttacksHadAbsenceDetails = 'health-insured-panic-attacks-had-absence-details',
  healthInsuredPanicAttacksAbsenceStartDate = 'health-insured-panic-attacks-absence-start-date',
  healthInsuredPanicAttacksAbsenceFrequencyNumber = 'health-insured-panic-attacks-absence-frequency-number',
  healthInsuredPanicAttacksAbsenceFrequency = 'health-insured-panic-attacks-absence-frequency',

  healthInsuredBurnoutMedicationDetails = 'health-insured-burnout-medication-details',
  healthInsuredBurnoutMedicationName = 'health-insured-burnout-medication-name',
  healthInsuredBurnoutMedicationDosage = 'health-insured-burnout-medication-dosage',
  healthInsuredBurnoutMedicationStartDate = 'health-insured-burnout-medication-start-date',
  healthInsuredBurnoutMedicationEndDate = 'health-insured-burnout-medication-end-date',
  healthInsuredBurnoutMedicationStillUses = 'health-insured-burnout-medication-still-uses',
  healthInsuredBurnoutMedicationHasChanged = 'health-insured-burnout-medication-has-changed',
  healthInsuredBurnoutMedicationHasChangedDetails = 'health-insured-burnout-medication-has-changed-details',

  healthInsuredBurnoutHasOrWillConsultDoctorsDetails = 'health-insured-burnout-has-or-will-consult-doctors-details',
  healthInsuredBurnoutHasOrWillConsultDoctorsProfession = 'health-insured-burnout-has-or-will-consult-doctors-profession',
  healthInsuredBurnoutHasOrWillConsultDoctorsProfessionOther = 'health-insured-burnout-has-or-will-consult-doctors-profession-other',
  healthInsuredBurnoutHasOrWillConsultDoctorsName = 'health-insured-burnout-has-or-will-consult-doctors-name',
  healthInsuredBurnoutHasOrWillConsultDoctorsAddress = 'health-insured-burnout-has-or-will-consult-doctors-address',
  healthInsuredBurnoutHasOrWillConsultDoctorsFirstDate = 'health-insured-burnout-has-or-will-consult-doctors-first-date',
  healthInsuredBurnoutHasOrWillConsultDoctorsLastDate = 'health-insured-burnout-has-or-will-consult-doctors-last-date',
  healthInsuredBurnoutHasOrWillConsultDoctorsResult = 'health-insured-burnout-has-or-will-consult-doctors-result',
  healthInsuredBurnoutHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-burnout-has-or-will-consult-doctors-frequency-number',
  healthInsuredBurnoutHasOrWillConsultDoctorsFrequency = 'health-insured-burnout-has-or-will-consult-doctors-frequency',
  healthInsuredBurnoutHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-burnout-has-or-will-consult-doctors-further-consultation',
  healthInsuredBurnoutHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-burnout-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredBurnoutWentToEmergencyDepartmentDetails = 'health-insured-burnout-went-to-emergency-department-details',
  healthInsuredBurnoutWentToEmergencyDepartmentDate = 'health-insured-burnout-went-to-emergency-department-date',
  healthInsuredBurnoutWentToEmergencyDepartmentLocation = 'health-insured-burnout-went-to-emergency-department-location',

  healthInsuredBurnoutWasHospitalizedDetails = 'health-insured-burnout-was-hospitalized-details',
  healthInsuredBurnoutWasHospitalizedDate = 'health-insured-burnout-was-hospitalized-date',
  healthInsuredBurnoutWasHospitalizedFrequencyNumber = 'health-insured-burnout-was-hospitalized-frequency-number',
  healthInsuredBurnoutWasHospitalizedFrequency = 'health-insured-burnout-was-hospitalized-frequency',
  healthInsuredBurnoutWasHospitalizedLocation = 'health-insured-burnout-was-hospitalized-location',

  healthInsuredBurnoutSuicidalThoughtsDetails = 'health-insured-burnout-suicidal-thoughts-details',
  healthInsuredBurnoutSuicidalThoughtsDate = 'health-insured-burnout-suicidal-thoughts-date',
  healthInsuredBurnoutSuicideAttemptsDetails = 'health-insured-burnout-suicide-attempts-details',
  healthInsuredBurnoutSuicideAttemptsDate = 'health-insured-burnout-suicide-attempts-date',
  healthInsuredBurnoutHadAbsenceDetails = 'health-insured-burnout-had-absence-details',
  healthInsuredBurnoutAbsenceStartDate = 'health-insured-burnout-absence-start-date',
  healthInsuredBurnoutAbsenceFrequencyNumber = 'health-insured-burnout-absence-frequency-number',
  healthInsuredBurnoutAbsenceFrequency = 'health-insured-burnout-absence-frequency',

  healthInsuredInsomniaMedicationDetails = 'health-insured-insomnia-medication-details',
  healthInsuredInsomniaMedicationName = 'health-insured-insomnia-medication-name',
  healthInsuredInsomniaMedicationDosage = 'health-insured-insomnia-medication-dosage',
  healthInsuredInsomniaMedicationStartDate = 'health-insured-insomnia-medication-start-date',
  healthInsuredInsomniaMedicationEndDate = 'health-insured-insomnia-medication-end-date',
  healthInsuredInsomniaMedicationStillUses = 'health-insured-insomnia-medication-still-uses',
  healthInsuredInsomniaMedicationHasChanged = 'health-insured-insomnia-medication-has-changed',
  healthInsuredInsomniaMedicationHasChangedDetails = 'health-insured-insomnia-medication-has-changed-details',

  healthInsuredInsomniaHasOrWillConsultDoctorsDetails = 'health-insured-insomnia-has-or-will-consult-doctors-details',
  healthInsuredInsomniaHasOrWillConsultDoctorsProfession = 'health-insured-insomnia-has-or-will-consult-doctors-profession',
  healthInsuredInsomniaHasOrWillConsultDoctorsProfessionOther = 'health-insured-insomnia-has-or-will-consult-doctors-profession-other',
  healthInsuredInsomniaHasOrWillConsultDoctorsName = 'health-insured-insomnia-has-or-will-consult-doctors-name',
  healthInsuredInsomniaHasOrWillConsultDoctorsAddress = 'health-insured-insomnia-has-or-will-consult-doctors-address',
  healthInsuredInsomniaHasOrWillConsultDoctorsFirstDate = 'health-insured-insomnia-has-or-will-consult-doctors-first-date',
  healthInsuredInsomniaHasOrWillConsultDoctorsLastDate = 'health-insured-insomnia-has-or-will-consult-doctors-last-date',
  healthInsuredInsomniaHasOrWillConsultDoctorsResult = 'health-insured-insomnia-has-or-will-consult-doctors-result',
  healthInsuredInsomniaHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-insomnia-has-or-will-consult-doctors-frequency-number',
  healthInsuredInsomniaHasOrWillConsultDoctorsFrequency = 'health-insured-insomnia-has-or-will-consult-doctors-frequency',
  healthInsuredInsomniaHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-insomnia-has-or-will-consult-doctors-further-consultation',
  healthInsuredInsomniaHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-insomnia-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredInsomniaHadAbsenceDetails = 'health-insured-insomnia-had-absence-details',
  healthInsuredInsomniaAbsenceStartDate = 'health-insured-insomnia-absence-start-date',
  healthInsuredInsomniaAbsenceFrequencyNumber = 'health-insured-insomnia-absence-frequency-number',
  healthInsuredInsomniaAbsenceFrequency = 'health-insured-insomnia-absence-frequency',

  healthInsuredFibromyalgiaIsSingleEpisode = 'health-insured-fibromyalgia-is-single-episode',
  healthInsuredFibromyalgiaPreviousEpisodesCount = 'health-insured-fibromyalgia-previous-episodes-count',
  healthInsuredFibromyalgiaDateSymptoms = 'health-insured-fibromyalgia-date-symptoms',
  healthInsuredFibromyalgiaPainLocation = 'health-insured-fibromyalgia-pain-location',
  healthInsuredFibromyalgiaPainLocationLeftRight = 'health-insured-fibromyalgia-pain-location-left-right',
  healthInsuredFibromyalgiaPainLocationOther = 'health-insured-fibromyalgia-pain-location-other',
  healthInsuredFibromyalgiaPainCause = 'health-insured-fibromyalgia-pain-cause',
  healthInsuredFibromyalgiaPainCauseDetails = 'health-insured-fibromyalgia-pain-cause-details',
  healthInsuredFibromyalgiaHasConsultedDoctors = 'health-insured-fibromyalgia-has-consulted-doctors',
  healthInsuredFibromyalgiaHasTakenTestsOrExams = 'health-insured-fibromyalgia-has-taken-tests-or-exams',
  healthInsuredFibromyalgiaMedicationWasPrescribed = 'health-insured-fibromyalgia-medication-was-prescribed',
  healthInsuredFibromyalgiaHadOperation = 'health-insured-fibromyalgia-had-operation',
  healthInsuredFibromyalgiaOperationPerformedOrPlanned = 'health-insured-fibromyalgia-operation-performed-or-planned',
  healthInsuredFibromyalgiaTypeOfOperationPerformed = 'health-insured-fibromyalgia-type-of-operation-performed',
  healthInsuredFibromyalgiaOperationPerformedDate = 'health-insured-fibromyalgia-operation-performed-date',
  healthInsuredFibromyalgiaTypeOfOperationPlanned = 'health-insured-fibromyalgia-type-of-operation-planned',
  healthInsuredFibromyalgiaOperationPlannedDate = 'health-insured-fibromyalgia-operation-planned-date',
  healthInsuredFibromyalgiaOperationUnknownDate = 'health-insured-fibromyalgia-operation-unknown-date',
  healthInsuredFibromyalgiaHadAbsence = 'health-insured-fibromyalgia-had-absence',
  healthInsuredFibromyalgiaAbsenceStartDate = 'health-insured-fibromyalgia-absence-start-date',
  healthInsuredFibromyalgiaAbsenceFrequencyNumber = 'health-insured-fibromyalgia-absence-frequency-number',
  healthInsuredFibromyalgiaAbsenceFrequency = 'health-insured-fibromyalgia-absence-frequency',
  healthInsuredFibromyalgiaAbsenceHasRecovered = 'health-insured-fibromyalgia-absence-has-recovered',
  healthInsuredFibromyalgiaAbsenceRecoveryDate = 'health-insured-fibromyalgia-absence-recovery-date',
  healthInsuredFibromyalgiaAbsenceNotRecoveredDetails = 'health-insured-fibromyalgia-absence-not-recovered-details',

  healthInsuredReplacementJointIsSingleEpisode = 'health-insured-replacement-joint-is-single-episode',
  healthInsuredReplacementJointPreviousEpisodesCount = 'health-insured-replacement-joint-previous-episodes-count',
  healthInsuredReplacementJointDateSymptoms = 'health-insured-replacement-joint-date-symptoms',
  healthInsuredReplacementJointPainLocation = 'health-insured-replacement-joint-pain-location',
  healthInsuredReplacementJointPainLocationLeftRight = 'health-insured-replacement-joint-pain-location-left-right',
  healthInsuredReplacementJointPainLocationOther = 'health-insured-replacement-joint-pain-location-other',
  healthInsuredReplacementJointPainCause = 'health-insured-replacement-joint-pain-cause',
  healthInsuredReplacementJointPainCauseDetails = 'health-insured-replacement-joint-pain-cause-details',
  healthInsuredReplacementJointHasConsultedDoctors = 'health-insured-replacement-joint-has-consulted-doctors',
  healthInsuredReplacementJointHasTakenTestsOrExams = 'health-insured-replacement-joint-has-taken-tests-or-exams',
  healthInsuredReplacementJointMedicationWasPrescribed = 'health-insured-replacement-joint-medication-was-prescribed',
  healthInsuredReplacementJointHadOperation = 'health-insured-replacement-joint-had-operation',
  healthInsuredReplacementJointOperationPerformedOrPlanned = 'health-insured-replacement-joint-operation-performed-or-planned',
  healthInsuredReplacementJointTypeOfOperationPerformed = 'health-insured-replacement-joint-type-of-operation-performed',
  healthInsuredReplacementJointOperationPerformedDate = 'health-insured-replacement-joint-operation-performed-date',
  healthInsuredReplacementJointTypeOfOperationPlanned = 'health-insured-replacement-joint-type-of-operation-planned',
  healthInsuredReplacementJointOperationPlannedDate = 'health-insured-replacement-joint-operation-planned-date',
  healthInsuredReplacementJointOperationUnknownDate = 'health-insured-replacement-joint-operation-unknown-date',
  healthInsuredReplacementJointHadAbsence = 'health-insured-replacement-joint-had-absence',
  healthInsuredReplacementJointAbsenceStartDate = 'health-insured-replacement-joint-absence-start-date',
  healthInsuredReplacementJointAbsenceFrequencyNumber = 'health-insured-replacement-joint-absence-frequency-number',
  healthInsuredReplacementJointAbsenceFrequency = 'health-insured-replacement-joint-absence-frequency',
  healthInsuredReplacementJointAbsenceHasRecovered = 'health-insured-replacement-joint-absence-has-recovered',
  healthInsuredReplacementJointAbsenceRecoveryDate = 'health-insured-replacement-joint-absence-recovery-date',
  healthInsuredReplacementJointAbsenceNotRecoveredDetails = 'health-insured-replacement-joint-absence-not-recovered-details',

  healthInsuredMusculoskeletalSprainIsSingleEpisode = 'health-insured-musculoskeletal-sprain-is-single-episode',
  healthInsuredMusculoskeletalSprainPreviousEpisodesCount = 'health-insured-musculoskeletal-sprain-previous-episodes-count',
  healthInsuredMusculoskeletalSprainDateSymptoms = 'health-insured-musculoskeletal-sprain-date-symptoms',
  healthInsuredMusculoskeletalSprainPainLocation = 'health-insured-musculoskeletal-sprain-pain-location',
  healthInsuredMusculoskeletalSprainPainLocationLeftRight = 'health-insured-musculoskeletal-sprain-pain-location-left-right',
  healthInsuredMusculoskeletalSprainPainLocationOther = 'health-insured-musculoskeletal-sprain-pain-location-other',
  healthInsuredMusculoskeletalSprainPainCause = 'health-insured-musculoskeletal-sprain-pain-cause',
  healthInsuredMusculoskeletalSprainPainCauseDetails = 'health-insured-musculoskeletal-sprain-pain-cause-details',
  healthInsuredMusculoskeletalSprainHasConsultedDoctors = 'health-insured-musculoskeletal-sprain-has-consulted-doctors',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExams = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams',
  healthInsuredMusculoskeletalSprainMedicationWasPrescribed = 'health-insured-musculoskeletal-sprain-medication-was-prescribed',
  healthInsuredMusculoskeletalSprainHadOperation = 'health-insured-musculoskeletal-sprain-had-operation',
  healthInsuredMusculoskeletalSprainOperationPerformedOrPlanned = 'health-insured-musculoskeletal-sprain-operation-performed-or-planned',
  healthInsuredMusculoskeletalSprainTypeOfOperationPerformed = 'health-insured-musculoskeletal-sprain-type-of-operation-performed',
  healthInsuredMusculoskeletalSprainOperationPerformedDate = 'health-insured-musculoskeletal-sprain-operation-performed-date',
  healthInsuredMusculoskeletalSprainTypeOfOperationPlanned = 'health-insured-musculoskeletal-sprain-type-of-operation-planned',
  healthInsuredMusculoskeletalSprainOperationPlannedDate = 'health-insured-musculoskeletal-sprain-operation-planned-date',
  healthInsuredMusculoskeletalSprainOperationUnknownDate = 'health-insured-musculoskeletal-sprain-operation-unknown-date',
  healthInsuredMusculoskeletalSprainHadAbsence = 'health-insured-musculoskeletal-sprain-had-absence',
  healthInsuredMusculoskeletalSprainAbsenceStartDate = 'health-insured-musculoskeletal-sprain-absence-start-date',
  healthInsuredMusculoskeletalSprainAbsenceFrequencyNumber = 'health-insured-musculoskeletal-sprain-absence-frequency-number',
  healthInsuredMusculoskeletalSprainAbsenceFrequency = 'health-insured-musculoskeletal-sprain-absence-frequency',
  healthInsuredMusculoskeletalSprainAbsenceHasRecovered = 'health-insured-musculoskeletal-sprain-absence-has-recovered',
  healthInsuredMusculoskeletalSprainAbsenceRecoveryDate = 'health-insured-musculoskeletal-sprain-absence-recovery-date',
  healthInsuredMusculoskeletalSprainAbsenceNotRecoveredDetails = 'health-insured-musculoskeletal-sprain-absence-not-recovered-details',
  healthInsuredDepressionHadSuicidalThoughtsAndAttempt = 'health-insured-depression-had-suicidal-thoughts-and-attempt',
  healthInsuredDepressionSuicidalThoughtsAndAttemptChoices = 'health-insured-depression-suicidal-thoughts-and-attempt-choices',
  healthInsuredDepressionSuicidalThoughtsFrequencyNumber = 'health-insured-depression-suicidal-thoughts-frequency-number',
  healthInsuredDepressionSuicidalThoughtsFrequency = 'health-insured-depression-suicidal-thoughts-frequency',
  healthInsuredDepressionSuicideAttemptsNumber = 'health-insured-depression-suicide-attempts-number',
  healthInsuredDepressionHadAbsence = 'health-insured-depression-had-absence',
  healthInsuredDepressionAbsenceHasRecovered = 'health-insured-depression-absence-has-recovered',
  healthInsuredDepressionAbsenceRecoveryDate = 'health-insured-depression-absence-recovery-date',
  healthInsuredDepressionAbsenceNotRecoveredDetails = 'health-insured-depression-absence-not-recovered-details',
  healthInsuredAnxietyIsSingleEpisode = 'health-insured-anxiety-is-single-episode',
  healthInsuredAnxietyDiagnosticDate = 'health-insured-anxiety-diagnostic-date',
  healthInsuredAnxietyMedicationWasPrescribed = 'health-insured-anxiety-medication-was-prescribed',
  healthInsuredAnxietyHasConsultedDoctors = 'health-insured-anxiety-has-consulted-doctors',
  healthInsuredAnxietyHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-anxiety-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredAnxietyWentToEmergencyDepartment = 'health-insured-anxiety-went-to-emergency-department',
  healthInsuredAnxietyWasHospitalized = 'health-insured-anxiety-was-hospitalized',
  healthInsuredAnxietyHadSuicidalThoughtsAndAttempt = 'health-insured-anxiety-had-suicidal-thoughts-and-attempt',
  healthInsuredAnxietySuicidalThoughtsAndAttemptChoices = 'health-insured-anxiety-suicidal-thoughts-and-attempt-choices',
  healthInsuredAnxietySuicidalThoughtsFrequencyNumber = 'health-insured-anxiety-suicidal-thoughts-frequency-number',
  healthInsuredAnxietySuicidalThoughtsFrequency = 'health-insured-anxiety-suicidal-thoughts-frequency',
  healthInsuredAnxietySuicideAttemptsNumber = 'health-insured-anxiety-suicide-attempts-number',
  healthInsuredAnxietyHadAbsence = 'health-insured-anxiety-had-absence',
  healthInsuredAnxietyAbsenceHasRecovered = 'health-insured-anxiety-absence-has-recovered',
  healthInsuredAnxietyAbsenceRecoveryDate = 'health-insured-anxiety-absence-recovery-date',
  healthInsuredAnxietyAbsenceNotRecoveredDetails = 'health-insured-anxiety-absence-not-recovered-details',
  healthInsuredPanicAttacksIsSingleEpisode = 'health-insured-panic-attacks-is-single-episode',
  healthInsuredPanicAttacksDiagnosticDate = 'health-insured-panic-attacks-diagnostic-date',
  healthInsuredPanicAttacksMedicationWasPrescribed = 'health-insured-panic-attacks-medication-was-prescribed',
  healthInsuredPanicAttacksHasConsultedDoctors = 'health-insured-panic-attacks-has-consulted-doctors',
  healthInsuredPanicAttacksHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-panic-attacks-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredPanicAttacksWentToEmergencyDepartment = 'health-insured-panic-attacks-went-to-emergency-department',
  healthInsuredPanicAttacksWasHospitalized = 'health-insured-panic-attacks-was-hospitalized',
  healthInsuredPanicAttacksHadSuicidalThoughtsAndAttempt = 'health-insured-panic-attacks-had-suicidal-thoughts-and-attempt',
  healthInsuredPanicAttacksSuicidalThoughtsAndAttemptChoices = 'health-insured-panic-attacks-suicidal-thoughts-and-attempt-choices',
  healthInsuredPanicAttacksSuicidalThoughtsFrequencyNumber = 'health-insured-panic-attacks-suicidal-thoughts-frequency-number',
  healthInsuredPanicAttacksSuicidalThoughtsFrequency = 'health-insured-panic-attacks-suicidal-thoughts-frequency',
  healthInsuredPanicAttacksSuicideAttemptsNumber = 'health-insured-panic-attacks-suicide-attempts-number',
  healthInsuredPanicAttacksHadAbsence = 'health-insured-panic-attacks-had-absence',
  healthInsuredPanicAttacksAbsenceHasRecovered = 'health-insured-panic-attacks-absence-has-recovered',
  healthInsuredPanicAttacksAbsenceRecoveryDate = 'health-insured-panic-attacks-absence-recovery-date',
  healthInsuredPanicAttacksAbsenceNotRecoveredDetails = 'health-insured-panic-attacks-absence-not-recovered-details',
  healthInsuredBurnoutIsSingleEpisode = 'health-insured-burnout-is-single-episode',
  healthInsuredBurnoutDiagnosticDate = 'health-insured-burnout-diagnostic-date',
  healthInsuredBurnoutMedicationWasPrescribed = 'health-insured-burnout-medication-was-prescribed',
  healthInsuredBurnoutHasConsultedDoctors = 'health-insured-burnout-has-consulted-doctors',
  healthInsuredBurnoutHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-burnout-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredBurnoutWentToEmergencyDepartment = 'health-insured-burnout-went-to-emergency-department',
  healthInsuredBurnoutWasHospitalized = 'health-insured-burnout-was-hospitalized',
  healthInsuredBurnoutHadSuicidalThoughtsAndAttempt = 'health-insured-burnout-had-suicidal-thoughts-and-attempt',
  healthInsuredBurnoutSuicidalThoughtsAndAttemptChoices = 'health-insured-burnout-suicidal-thoughts-and-attempt-choices',
  healthInsuredBurnoutSuicidalThoughtsFrequencyNumber = 'health-insured-burnout-suicidal-thoughts-frequency-number',
  healthInsuredBurnoutSuicidalThoughtsFrequency = 'health-insured-burnout-suicidal-thoughts-frequency',
  healthInsuredBurnoutSuicideAttemptsNumber = 'health-insured-burnout-suicide-attempts-number',
  healthInsuredBurnoutHadAbsence = 'health-insured-burnout-had-absence',
  healthInsuredBurnoutAbsenceHasRecovered = 'health-insured-burnout-absence-has-recovered',
  healthInsuredBurnoutAbsenceRecoveryDate = 'health-insured-burnout-absence-recovery-date',
  healthInsuredBurnoutAbsenceNotRecoveredDetails = 'health-insured-burnout-absence-not-recovered-details',
  healthInsuredInsomniaMedicationWasPrescribed = 'health-insured-insomnia-medication-was-prescribed',
  healthInsuredInsomniaHasConsultedDoctors = 'health-insured-insomnia-has-consulted-doctors',
  healthInsuredInsomniaHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-insomnia-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredInsomniaHadAbsence = 'health-insured-insomnia-had-absence',
  healthInsuredInsomniaAbsenceHasRecovered = 'health-insured-insomnia-absence-has-recovered',
  healthInsuredInsomniaAbsenceRecoveryDate = 'health-insured-insomnia-absence-recovery-date',
  healthInsuredInsomniaAbsenceNotRecoveredDetails = 'health-insured-insomnia-absence-not-recovered-details',
  healthInsuredMusculoskeletalOsteoarthritisIsSingleEpisode = 'health-insured-musculoskeletal-osteoarthritis-is-single-episode',
  healthInsuredMusculoskeletalOsteoarthritisPreviousEpisodesCount = 'health-insured-musculoskeletal-osteoarthritis-previous-episodes-count',
  healthInsuredMusculoskeletalOsteoarthritisDateSymptoms = 'health-insured-musculoskeletal-osteoarthritis-date-symptoms',
  healthInsuredMusculoskeletalOsteoarthritisPainLocation = 'health-insured-musculoskeletal-osteoarthritis-pain-location',
  healthInsuredMusculoskeletalOsteoarthritisPainLocationLeftRight = 'health-insured-musculoskeletal-osteoarthritis-pain-location-left-right',
  healthInsuredMusculoskeletalOsteoarthritisPainLocationOther = 'health-insured-musculoskeletal-osteoarthritis-pain-location-other',
  healthInsuredMusculoskeletalOsteoarthritisPainCause = 'health-insured-musculoskeletal-osteoarthritis-pain-cause',
  healthInsuredMusculoskeletalOsteoarthritisPainCauseDetails = 'health-insured-musculoskeletal-osteoarthritis-pain-cause-details',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctors = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors',
  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExams = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams',
  healthInsuredMusculoskeletalOsteoarthritisMedicationWasPrescribed = 'health-insured-musculoskeletal-osteoarthritis-medication-was-prescribed',
  healthInsuredMusculoskeletalOsteoarthritisHadOperation = 'health-insured-musculoskeletal-osteoarthritis-had-operation',
  healthInsuredMusculoskeletalOsteoarthritisOperationPerformedOrPlanned = 'health-insured-musculoskeletal-osteoarthritis-operation-performed-or-planned',
  healthInsuredMusculoskeletalOsteoarthritisTypeOfOperationPerformed = 'health-insured-musculoskeletal-osteoarthritis-type-of-operation-performed',
  healthInsuredMusculoskeletalOsteoarthritisOperationPerformedDate = 'health-insured-musculoskeletal-osteoarthritis-operation-performed-date',
  healthInsuredMusculoskeletalOsteoarthritisTypeOfOperationPlanned = 'health-insured-musculoskeletal-osteoarthritis-type-of-operation-planned',
  healthInsuredMusculoskeletalOsteoarthritisOperationPlannedDate = 'health-insured-musculoskeletal-osteoarthritis-operation-planned-date',
  healthInsuredMusculoskeletalOsteoarthritisOperationUnknownDate = 'health-insured-musculoskeletal-osteoarthritis-operation-unknown-date',
  healthInsuredMusculoskeletalOsteoarthritisHadAbsence = 'health-insured-musculoskeletal-osteoarthritis-had-absence',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceStartDate = 'health-insured-musculoskeletal-osteoarthritis-absence-start-date',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceFrequencyNumber = 'health-insured-musculoskeletal-osteoarthritis-absence-frequency-number',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceFrequency = 'health-insured-musculoskeletal-osteoarthritis-absence-frequency',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceHasRecovered = 'health-insured-musculoskeletal-osteoarthritis-absence-has-recovered',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceRecoveryDate = 'health-insured-musculoskeletal-osteoarthritis-absence-recovery-date',
  healthInsuredMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails = 'health-insured-musculoskeletal-osteoarthritis-absence-not-recovered-details',

  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-details',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-profession',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-profession-details',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsName = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-name',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-address',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-first-date',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-last-date',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsResult = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-result',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency-number',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctor-further-consultation',
  healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-insured-musculoskeletal-osteoarthritis-has-consulted-doctors-next-consultation-date',

  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details',
  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-type',
  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-date',
  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-result',
  healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredMusculoskeletalOsteoarthritisMedicationDetails = 'health-insured-musculoskeletal-osteoarthritis-medication-details',
  healthInsuredMusculoskeletalOsteoarthritisMedicationName = 'health-insured-musculoskeletal-osteoarthritis-medication-name',
  healthInsuredMusculoskeletalOsteoarthritisMedicationDosage = 'health-insured-musculoskeletal-osteoarthritis-medication-dosage',
  healthInsuredMusculoskeletalOsteoarthritisMedicationStartDate = 'health-insured-musculoskeletal-osteoarthritis-medication-start-date',
  healthInsuredMusculoskeletalOsteoarthritisMedicationEndDate = 'health-insured-musculoskeletal-osteoarthritis-medication-end-date',

  healthInsuredMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode = 'health-insured-musculoskeletal-osteoporosis-osteopenia-is-single-episode',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount = 'health-insured-musculoskeletal-osteoporosis-osteopenia-previous-episodes-count',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaDateSymptoms = 'health-insured-musculoskeletal-osteoporosis-osteopenia-date-symptoms',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocation = 'health-insured-musculoskeletal-osteoporosis-osteopenia-pain-location',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight = 'health-insured-musculoskeletal-osteoporosis-osteopenia-pain-location-left-right',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocationOther = 'health-insured-musculoskeletal-osteoporosis-osteopenia-pain-location-other',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainCause = 'health-insured-musculoskeletal-osteoporosis-osteopenia-pain-cause',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-pain-cause-details',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-was-prescribed',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHadOperation = 'health-insured-musculoskeletal-osteoporosis-osteopenia-had-operation',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned = 'health-insured-musculoskeletal-osteoporosis-osteopenia-operation-performed-or-planned',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed = 'health-insured-musculoskeletal-osteoporosis-osteopenia-type-of-operation-performed',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-operation-performed-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned = 'health-insured-musculoskeletal-osteoporosis-osteopenia-type-of-operation-planned',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-operation-planned-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-operation-unknown-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHadAbsence = 'health-insured-musculoskeletal-osteoporosis-osteopenia-had-absence',
  healthInsuredMusculoskeletalOsteoporosisosteopeniaAbsenceStartDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-start-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-frequency-number',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-frequency',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-has-recovered',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-recovery-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-absence-not-recovered-details',

  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-details',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession-details',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-name',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-address',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-first-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-last-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-result',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency-number',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctor-further-consultation',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-next-consultation-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-type',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-result',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationDetails = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-details',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationName = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-name',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationDosage = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-dosage',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-start-date',
  healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate = 'health-insured-musculoskeletal-osteoporosis-osteopenia-medication-end-date',

  healthInsuredSprainIsSingleEpisode = 'health-insured-sprain-is-single-episode',
  healthInsuredSprainPreviousEpisodesCount = 'health-insured-sprain-previous-episodes-count',
  healthInsuredSprainPainLocation = 'health-insured-sprain-pain-location',
  healthInsuredSprainDateSymptoms = 'health-insured-sprain-date-symptoms',
  healthInsuredSprainPainCause = 'health-insured-sprain-pain-cause',
  healthInsuredSprainPainCauseDetails = 'health-insured-sprain-pain-cause-details',
  healthInsuredSprainHasConsultedDoctors = 'health-insured-sprain-has-consulted-doctors',
  healthInsuredSprainHasTakenTestsOrExams = 'health-insured-sprain-has-taken-tests-or-exams',
  healthInsuredSprainMedicationWasPrescribed = 'health-insured-sprain-medication-was-prescribed',
  healthInsuredSprainHadOperation = 'health-insured-sprain-had-operation',
  healthInsuredSprainOperationPerformedOrPlanned = 'health-insured-sprain-operation-performed-or-planned',
  healthInsuredSprainTypeOfOperationPerformed = 'health-insured-sprain-type-of-operation-performed',
  healthInsuredSprainOperationPerformedDate = 'health-insured-sprain-operation-performed-date',
  healthInsuredSprainTypeOfOperationPlanned = 'health-insured-sprain-type-of-operation-planned',
  healthInsuredSprainOperationPlannedDate = 'health-insured-sprain-operation-planned-date',
  healthInsuredSprainOperationUnknownDate = 'health-insured-sprain-operation-unknown-date',
  healthInsuredSprainHadAbsence = 'health-insured-sprain-had-absence',
  healthInsuredSprainAbsenceStartDate = 'health-insured-sprain-absence-start-date',
  healthInsuredSprainAbsenceFrequencyNumber = 'health-insured-sprain-absence-frequency-number',
  healthInsuredSprainAbsenceFrequency = 'health-insured-sprain-absence-frequency',
  healthInsuredSprainAbsenceRecoveryDate = 'health-insured-sprain-absence-recovery-date',
  healthInsuredSprainAbsenceHasRecovered = 'health-insured-sprain-absence-has-recovered',
  healthInsuredSprainAbsenceNotRecoveredDetails = 'health-insured-sprain-absence-not-recovered-details',

  healthInsuredSprainHasConsultedDoctorsDetails = 'health-insured-sprain-has-consulted-doctors-details',
  healthInsuredSprainHasConsultedDoctorsProfession = 'health-insured-sprain-has-consulted-doctors-profession',
  healthInsuredSprainHasConsultedDoctorsProfessionDetails = 'health-insured-sprain-has-consulted-doctors-profession-details',
  healthInsuredSprainHasConsultedDoctorsName = 'health-insured-sprain-has-consulted-doctors-name',
  healthInsuredSprainHasConsultedDoctorsAddress = 'health-insured-sprain-has-consulted-doctors-address',
  healthInsuredSprainHasConsultedDoctorsFirstDate = 'health-insured-sprain-has-consulted-doctors-first-date',
  healthInsuredSprainHasConsultedDoctorsLastDate = 'health-insured-sprain-has-consulted-doctors-last-date',
  healthInsuredSprainHasConsultedDoctorsResult = 'health-insured-sprain-has-consulted-doctors-result',
  healthInsuredSprainHasConsultedDoctorsFrequencyNumber = 'health-insured-sprain-has-consulted-doctors-frequency-number',
  healthInsuredSprainHasConsultedDoctorsFrequency = 'health-insured-sprain-has-consulted-doctors-frequency',
  healthInsuredSprainHasConsultedDoctorFurtherConsultation = 'health-insured-sprain-has-consulted-doctor-further-consultation',
  healthInsuredSprainHasConsultedDoctorsNextConsultationDate = 'health-insured-sprain-has-consulted-doctors-next-consultation-date',
  healthInsuredSprainHasTakenTestsOrExamsDetails = 'health-insured-sprain-has-taken-tests-or-exams-details',
  healthInsuredSprainHasTakenTestsOrExamsType = 'health-insured-sprain-has-taken-tests-or-exams-type',
  healthInsuredSprainHasTakenTestsOrExamsDate = 'health-insured-sprain-has-taken-tests-or-exams-date',
  healthInsuredSprainHasTakenTestsOrExamsResult = 'health-insured-sprain-has-taken-tests-or-exams-result',
  healthInsuredSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredSprainMedicationDetails = 'health-insured-sprain-medication-details',
  healthInsuredSprainMedicationName = 'health-insured-sprain-medication-name',
  healthInsuredSprainMedicationDosage = 'health-insured-sprain-medication-dosage',
  healthInsuredSprainMedicationStartDate = 'health-insured-sprain-medication-start-date',
  healthInsuredSprainMedicationEndDate = 'health-insured-sprain-medication-end-date',

  healthInsuredLumbagoIsSingleEpisode = 'health-insured-lumbago-is-single-episode',
  healthInsuredLumbagoPreviousEpisodesCount = 'health-insured-lumbago-previous-episodes-count',
  healthInsuredLumbagoPainLocation = 'health-insured-lumbago-pain-location',
  healthInsuredLumbagoDateSymptoms = 'health-insured-lumbago-date-symptoms',
  healthInsuredLumbagoPainCause = 'health-insured-lumbago-pain-cause',
  healthInsuredLumbagoPainCauseDetails = 'health-insured-lumbago-pain-cause-details',
  healthInsuredLumbagoHasConsultedDoctors = 'health-insured-lumbago-has-consulted-doctors',
  healthInsuredLumbagoHasTakenTestsOrExams = 'health-insured-lumbago-has-taken-tests-or-exams',
  healthInsuredLumbagoMedicationWasPrescribed = 'health-insured-lumbago-medication-was-prescribed',
  healthInsuredLumbagoHadOperation = 'health-insured-lumbago-had-operation',
  healthInsuredLumbagoOperationPerformedOrPlanned = 'health-insured-lumbago-operation-performed-or-planned',
  healthInsuredLumbagoTypeOfOperationPerformed = 'health-insured-lumbago-type-of-operation-performed',
  healthInsuredLumbagoOperationPerformedDate = 'health-insured-lumbago-operation-performed-date',
  healthInsuredLumbagoTypeOfOperationPlanned = 'health-insured-lumbago-type-of-operation-planned',
  healthInsuredLumbagoOperationPlannedDate = 'health-insured-lumbago-operation-planned-date',
  healthInsuredLumbagoOperationUnknownDate = 'health-insured-lumbago-operation-unknown-date',
  healthInsuredLumbagoHadAbsence = 'health-insured-lumbago-had-absence',
  healthInsuredLumbagoAbsenceStartDate = 'health-insured-lumbago-absence-start-date',
  healthInsuredLumbagoAbsenceFrequencyNumber = 'health-insured-lumbago-absence-frequency-number',
  healthInsuredLumbagoAbsenceFrequency = 'health-insured-lumbago-absence-frequency',
  healthInsuredLumbagoAbsenceRecoveryDate = 'health-insured-lumbago-absence-recovery-date',
  healthInsuredLumbagoAbsenceHasRecovered = 'health-insured-lumbago-absence-has-recovered',
  healthInsuredLumbagoAbsenceNotRecoveredDetails = 'health-insured-lumbago-absence-not-recovered-details',

  healthInsuredLumbagoHasConsultedDoctorsDetails = 'health-insured-lumbago-has-consulted-doctors-details',
  healthInsuredLumbagoHasConsultedDoctorsProfession = 'health-insured-lumbago-has-consulted-doctors-profession',
  healthInsuredLumbagoHasConsultedDoctorsProfessionDetails = 'health-insured-lumbago-has-consulted-doctors-profession-details',
  healthInsuredLumbagoHasConsultedDoctorsName = 'health-insured-lumbago-has-consulted-doctors-name',
  healthInsuredLumbagoHasConsultedDoctorsAddress = 'health-insured-lumbago-has-consulted-doctors-address',
  healthInsuredLumbagoHasConsultedDoctorsFirstDate = 'health-insured-lumbago-has-consulted-doctors-first-date',
  healthInsuredLumbagoHasConsultedDoctorsLastDate = 'health-insured-lumbago-has-consulted-doctors-last-date',
  healthInsuredLumbagoHasConsultedDoctorsResult = 'health-insured-lumbago-has-consulted-doctors-result',
  healthInsuredLumbagoHasConsultedDoctorsFrequencyNumber = 'health-insured-lumbago-has-consulted-doctors-frequency-number',
  healthInsuredLumbagoHasConsultedDoctorsFrequency = 'health-insured-lumbago-has-consulted-doctors-frequency',
  healthInsuredLumbagoHasConsultedDoctorFurtherConsultation = 'health-insured-lumbago-has-consulted-doctor-further-consultation',
  healthInsuredLumbagoHasConsultedDoctorsNextConsultationDate = 'health-insured-lumbago-has-consulted-doctors-next-consultation-date',
  healthInsuredLumbagoHasTakenTestsOrExamsDetails = 'health-insured-lumbago-has-taken-tests-or-exams-details',
  healthInsuredLumbagoHasTakenTestsOrExamsType = 'health-insured-lumbago-has-taken-tests-or-exams-type',
  healthInsuredLumbagoHasTakenTestsOrExamsDate = 'health-insured-lumbago-has-taken-tests-or-exams-date',
  healthInsuredLumbagoHasTakenTestsOrExamsResult = 'health-insured-lumbago-has-taken-tests-or-exams-result',
  healthInsuredLumbagoHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-lumbago-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredLumbagoMedicationDetails = 'health-insured-lumbago-medication-details',
  healthInsuredLumbagoMedicationName = 'health-insured-lumbago-medication-name',
  healthInsuredLumbagoMedicationDosage = 'health-insured-lumbago-medication-dosage',
  healthInsuredLumbagoMedicationStartDate = 'health-insured-lumbago-medication-start-date',
  healthInsuredLumbagoMedicationEndDate = 'health-insured-lumbago-medication-end-date',

  healthInsuredSciaticNerveIsSingleEpisode = 'health-insured-sciatic-nerve-is-single-episode',
  healthInsuredSciaticNervePreviousEpisodesCount = 'health-insured-sciatic-nerve-previous-episodes-count',
  healthInsuredSciaticNervePainLocation = 'health-insured-sciatic-nerve-pain-location',
  healthInsuredSciaticNerveDateSymptoms = 'health-insured-sciatic-nerve-date-symptoms',
  healthInsuredSciaticNervePainCause = 'health-insured-sciatic-nerve-pain-cause',
  healthInsuredSciaticNervePainCauseDetails = 'health-insured-sciatic-nerve-pain-cause-details',
  healthInsuredSciaticNerveHasConsultedDoctors = 'health-insured-sciatic-nerve-has-consulted-doctors',
  healthInsuredSciaticNerveHasTakenTestsOrExams = 'health-insured-sciatic-nerve-has-taken-tests-or-exams',
  healthInsuredSciaticNerveMedicationWasPrescribed = 'health-insured-sciatic-nerve-medication-was-prescribed',
  healthInsuredSciaticNerveHadOperation = 'health-insured-sciatic-nerve-had-operation',
  healthInsuredSciaticNerveOperationPerformedOrPlanned = 'health-insured-sciatic-nerve-operation-performed-or-planned',
  healthInsuredSciaticNerveTypeOfOperationPerformed = 'health-insured-sciatic-nerve-type-of-operation-performed',
  healthInsuredSciaticNerveOperationPerformedDate = 'health-insured-sciatic-nerve-operation-performed-date',
  healthInsuredSciaticNerveTypeOfOperationPlanned = 'health-insured-sciatic-nerve-type-of-operation-planned',
  healthInsuredSciaticNerveOperationPlannedDate = 'health-insured-sciatic-nerve-operation-planned-date',
  healthInsuredSciaticNerveOperationUnknownDate = 'health-insured-sciatic-nerve-operation-unknown-date',
  healthInsuredSciaticNerveHadAbsence = 'health-insured-sciatic-nerve-had-absence',
  healthInsuredSciaticNerveAbsenceStartDate = 'health-insured-sciatic-nerve-absence-start-date',
  healthInsuredSciaticNerveAbsenceFrequencyNumber = 'health-insured-sciatic-nerve-absence-frequency-number',
  healthInsuredSciaticNerveAbsenceFrequency = 'health-insured-sciatic-nerve-absence-frequency',
  healthInsuredSciaticNerveAbsenceRecoveryDate = 'health-insured-sciatic-nerve-absence-recovery-date',
  healthInsuredSciaticNerveAbsenceHasRecovered = 'health-insured-sciatic-nerve-absence-has-recovered',
  healthInsuredSciaticNerveAbsenceNotRecoveredDetails = 'health-insured-sciatic-nerve-absence-not-recovered-details',

  healthInsuredSciaticNerveHasConsultedDoctorsDetails = 'health-insured-sciatic-nerve-has-consulted-doctors-details',
  healthInsuredSciaticNerveHasConsultedDoctorsProfession = 'health-insured-sciatic-nerve-has-consulted-doctors-profession',
  healthInsuredSciaticNerveHasConsultedDoctorsProfessionDetails = 'health-insured-sciatic-nerve-has-consulted-doctors-profession-details',
  healthInsuredSciaticNerveHasConsultedDoctorsName = 'health-insured-sciatic-nerve-has-consulted-doctors-name',
  healthInsuredSciaticNerveHasConsultedDoctorsAddress = 'health-insured-sciatic-nerve-has-consulted-doctors-address',
  healthInsuredSciaticNerveHasConsultedDoctorsFirstDate = 'health-insured-sciatic-nerve-has-consulted-doctors-first-date',
  healthInsuredSciaticNerveHasConsultedDoctorsLastDate = 'health-insured-sciatic-nerve-has-consulted-doctors-last-date',
  healthInsuredSciaticNerveHasConsultedDoctorsResult = 'health-insured-sciatic-nerve-has-consulted-doctors-result',
  healthInsuredSciaticNerveHasConsultedDoctorsFrequencyNumber = 'health-insured-sciatic-nerve-has-consulted-doctors-frequency-number',
  healthInsuredSciaticNerveHasConsultedDoctorsFrequency = 'health-insured-sciatic-nerve-has-consulted-doctors-frequency',
  healthInsuredSciaticNerveHasConsultedDoctorFurtherConsultation = 'health-insured-sciatic-nerve-has-consulted-doctor-further-consultation',
  healthInsuredSciaticNerveHasConsultedDoctorsNextConsultationDate = 'health-insured-sciatic-nerve-has-consulted-doctors-next-consultation-date',
  healthInsuredSciaticNerveHasTakenTestsOrExamsDetails = 'health-insured-sciatic-nerve-has-taken-tests-or-exams-details',
  healthInsuredSciaticNerveHasTakenTestsOrExamsType = 'health-insured-sciatic-nerve-has-taken-tests-or-exams-type',
  healthInsuredSciaticNerveHasTakenTestsOrExamsDate = 'health-insured-sciatic-nerve-has-taken-tests-or-exams-date',
  healthInsuredSciaticNerveHasTakenTestsOrExamsResult = 'health-insured-sciatic-nerve-has-taken-tests-or-exams-result',
  healthInsuredSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-sciatic-nerve-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredSciaticNerveMedicationDetails = 'health-insured-sciatic-nerve-medication-details',
  healthInsuredSciaticNerveMedicationName = 'health-insured-sciatic-nerve-medication-name',
  healthInsuredSciaticNerveMedicationDosage = 'health-insured-sciatic-nerve-medication-dosage',
  healthInsuredSciaticNerveMedicationStartDate = 'health-insured-sciatic-nerve-medication-start-date',
  healthInsuredSciaticNerveMedicationEndDate = 'health-insured-sciatic-nerve-medication-end-date',

  healthInsuredTorticollisIsSingleEpisode = 'health-insured-torticollis-is-single-episode',
  healthInsuredTorticollisPreviousEpisodesCount = 'health-insured-torticollis-previous-episodes-count',
  healthInsuredTorticollisPainLocation = 'health-insured-torticollis-pain-location',
  healthInsuredTorticollisDateSymptoms = 'health-insured-torticollis-date-symptoms',
  healthInsuredTorticollisPainCause = 'health-insured-torticollis-pain-cause',
  healthInsuredTorticollisPainCauseDetails = 'health-insured-torticollis-pain-cause-details',
  healthInsuredTorticollisHasConsultedDoctors = 'health-insured-torticollis-has-consulted-doctors',
  healthInsuredTorticollisHasTakenTestsOrExams = 'health-insured-torticollis-has-taken-tests-or-exams',
  healthInsuredTorticollisMedicationWasPrescribed = 'health-insured-torticollis-medication-was-prescribed',
  healthInsuredTorticollisHadOperation = 'health-insured-torticollis-had-operation',
  healthInsuredTorticollisOperationPerformedOrPlanned = 'health-insured-torticollis-operation-performed-or-planned',
  healthInsuredTorticollisTypeOfOperationPerformed = 'health-insured-torticollis-type-of-operation-performed',
  healthInsuredTorticollisOperationPerformedDate = 'health-insured-torticollis-operation-performed-date',
  healthInsuredTorticollisTypeOfOperationPlanned = 'health-insured-torticollis-type-of-operation-planned',
  healthInsuredTorticollisOperationPlannedDate = 'health-insured-torticollis-operation-planned-date',
  healthInsuredTorticollisOperationUnknownDate = 'health-insured-torticollis-operation-unknown-date',
  healthInsuredTorticollisHadAbsence = 'health-insured-torticollis-had-absence',
  healthInsuredTorticollisAbsenceStartDate = 'health-insured-torticollis-absence-start-date',
  healthInsuredTorticollisAbsenceFrequencyNumber = 'health-insured-torticollis-absence-frequency-number',
  healthInsuredTorticollisAbsenceFrequency = 'health-insured-torticollis-absence-frequency',
  healthInsuredTorticollisAbsenceRecoveryDate = 'health-insured-torticollis-absence-recovery-date',
  healthInsuredTorticollisAbsenceHasRecovered = 'health-insured-torticollis-absence-has-recovered',
  healthInsuredTorticollisAbsenceNotRecoveredDetails = 'health-insured-torticollis-absence-not-recovered-details',

  healthInsuredTorticollisHasConsultedDoctorsDetails = 'health-insured-torticollis-has-consulted-doctors-details',
  healthInsuredTorticollisHasConsultedDoctorsProfession = 'health-insured-torticollis-has-consulted-doctors-profession',
  healthInsuredTorticollisHasConsultedDoctorsProfessionDetails = 'health-insured-torticollis-has-consulted-doctors-profession-details',
  healthInsuredTorticollisHasConsultedDoctorsName = 'health-insured-torticollis-has-consulted-doctors-name',
  healthInsuredTorticollisHasConsultedDoctorsAddress = 'health-insured-torticollis-has-consulted-doctors-address',
  healthInsuredTorticollisHasConsultedDoctorsFirstDate = 'health-insured-torticollis-has-consulted-doctors-first-date',
  healthInsuredTorticollisHasConsultedDoctorsLastDate = 'health-insured-torticollis-has-consulted-doctors-last-date',
  healthInsuredTorticollisHasConsultedDoctorsResult = 'health-insured-torticollis-has-consulted-doctors-result',
  healthInsuredTorticollisHasConsultedDoctorsFrequencyNumber = 'health-insured-torticollis-has-consulted-doctors-frequency-number',
  healthInsuredTorticollisHasConsultedDoctorsFrequency = 'health-insured-torticollis-has-consulted-doctors-frequency',
  healthInsuredTorticollisHasConsultedDoctorFurtherConsultation = 'health-insured-torticollis-has-consulted-doctor-further-consultation',
  healthInsuredTorticollisHasConsultedDoctorsNextConsultationDate = 'health-insured-torticollis-has-consulted-doctors-next-consultation-date',
  healthInsuredTorticollisHasTakenTestsOrExamsDetails = 'health-insured-torticollis-has-taken-tests-or-exams-details',
  healthInsuredTorticollisHasTakenTestsOrExamsType = 'health-insured-torticollis-has-taken-tests-or-exams-type',
  healthInsuredTorticollisHasTakenTestsOrExamsDate = 'health-insured-torticollis-has-taken-tests-or-exams-date',
  healthInsuredTorticollisHasTakenTestsOrExamsResult = 'health-insured-torticollis-has-taken-tests-or-exams-result',
  healthInsuredTorticollisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-torticollis-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredTorticollisMedicationDetails = 'health-insured-torticollis-medication-details',
  healthInsuredTorticollisMedicationName = 'health-insured-torticollis-medication-name',
  healthInsuredTorticollisMedicationDosage = 'health-insured-torticollis-medication-dosage',
  healthInsuredTorticollisMedicationStartDate = 'health-insured-torticollis-medication-start-date',
  healthInsuredTorticollisMedicationEndDate = 'health-insured-torticollis-medication-end-date',

  healthInsuredArnoldsNeuralgiaIsSingleEpisode = 'health-insured-arnolds-neuralgia-is-single-episode',
  healthInsuredArnoldsNeuralgiaPreviousEpisodesCount = 'health-insured-arnolds-neuralgia-previous-episodes-count',
  healthInsuredArnoldsNeuralgiaPainLocation = 'health-insured-arnolds-neuralgia-pain-location',
  healthInsuredArnoldsNeuralgiaDateSymptoms = 'health-insured-arnolds-neuralgia-date-symptoms',
  healthInsuredArnoldsNeuralgiaPainCause = 'health-insured-arnolds-neuralgia-pain-cause',
  healthInsuredArnoldsNeuralgiaPainCauseDetails = 'health-insured-arnolds-neuralgia-pain-cause-details',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctors = 'health-insured-arnolds-neuralgia-has-consulted-doctors',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExams = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams',
  healthInsuredArnoldsNeuralgiaMedicationWasPrescribed = 'health-insured-arnolds-neuralgia-medication-was-prescirbed',
  healthInsuredArnoldsNeuralgiaHadOperation = 'health-insured-arnolds-neuralgia-had-operation',
  healthInsuredArnoldsNeuralgiaOperationPerformedOrPlanned = 'health-insured-arnolds-neuralgia-operation-performed-or-planned',
  healthInsuredArnoldsNeuralgiaTypeOfOperationPerformed = 'health-insured-arnolds-neuralgia-type-of-operation-performed',
  healthInsuredArnoldsNeuralgiaOperationPerformedDate = 'health-insured-arnolds-neuralgia-operation-performed-date',
  healthInsuredArnoldsNeuralgiaTypeOfOperationPlanned = 'health-insured-arnolds-neuralgia-type-of-operation-planned',
  healthInsuredArnoldsNeuralgiaOperationPlannedDate = 'health-insured-arnolds-neuralgia-operation-planned-date',
  healthInsuredArnoldsNeuralgiaOperationUnknownDate = 'health-insured-arnolds-neuralgia-operation-unknown-date',
  healthInsuredArnoldsNeuralgiaHadAbsence = 'health-insured-arnolds-neuralgia-had-absence',
  healthInsuredArnoldsNeuralgiaAbsenceStartDate = 'health-insured-arnolds-neuralgia-absence-start-date',
  healthInsuredArnoldsNeuralgiaAbsenceFrequencyNumber = 'health-insured-arnolds-neuralgia-absence-frequency-number',
  healthInsuredArnoldsNeuralgiaAbsenceFrequency = 'health-insured-arnolds-neuralgia-absence-frequency',
  healthInsuredArnoldsNeuralgiaAbsenceRecoveryDate = 'health-insured-arnolds-neuralgia-absence-recovery-date',
  healthInsuredArnoldsNeuralgiaAbsenceHasRecovered = 'health-insured-arnolds-neuralgia-absence-has-recovered',
  healthInsuredArnoldsNeuralgiaAbsenceNotRecoveredDetails = 'health-insured-arnolds-neuralgia-absence-not-recovered-details',

  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsDetails = 'health-insured-arnolds-neuralgia-has-consulted-doctors-details',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsProfession = 'health-insured-arnolds-neuralgia-has-consulted-doctors-profession',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails = 'health-insured-arnolds-neuralgia-has-consulted-doctors-profession-details',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsName = 'health-insured-arnolds-neuralgia-has-consulted-doctors-name',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsAddress = 'health-insured-arnolds-neuralgia-has-consulted-doctors-address',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFirstDate = 'health-insured-arnolds-neuralgia-has-consulted-doctors-first-date',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsLastDate = 'health-insured-arnolds-neuralgia-has-consulted-doctors-last-date',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsResult = 'health-insured-arnolds-neuralgia-has-consulted-doctors-result',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber = 'health-insured-arnolds-neuralgia-has-consulted-doctors-frequency-number',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFrequency = 'health-insured-arnolds-neuralgia-has-consulted-doctors-frequency',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation = 'health-insured-arnolds-neuralgia-has-consulted-doctor-further-consultation',
  healthInsuredArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate = 'health-insured-arnolds-neuralgia-has-consulted-doctors-next-consultation-date',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDetails = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams-details',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsType = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams-type',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDate = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams-date',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsResult = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams-result',
  healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-arnolds-neuralgia-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredArnoldsNeuralgiaMedicationDetails = 'health-insured-arnolds-neuralgia-medication-details',
  healthInsuredArnoldsNeuralgiaMedicationName = 'health-insured-arnolds-neuralgia-medication-name',
  healthInsuredArnoldsNeuralgiaMedicationDosage = 'health-insured-arnolds-neuralgia-medication-dosage',
  healthInsuredArnoldsNeuralgiaMedicationStartDate = 'health-insured-arnolds-neuralgia-medication-start-date',
  healthInsuredArnoldsNeuralgiaMedicationEndDate = 'health-insured-arnolds-neuralgia-medication-end-date',

  healthInsuredOsteoporosisIsSingleEpisode = 'health-insured-osteoporosis-is-single-episode',
  healthInsuredOsteoporosisPreviousEpisodesCount = 'health-insured-osteoporosis-previous-episodes-count',
  healthInsuredOsteoporosisPainLocation = 'health-insured-osteoporosis-pain-location',
  healthInsuredOsteoporosisDateSymptoms = 'health-insured-osteoporosis-date-symptoms',
  healthInsuredOsteoporosisPainCause = 'health-insured-osteoporosis-pain-cause',
  healthInsuredOsteoporosisPainCauseDetails = 'health-insured-osteoporosis-pain-cause-details',
  healthInsuredOsteoporosisHasConsultedDoctors = 'health-insured-osteoporosis-has-consulted-doctors',
  healthInsuredOsteoporosisHasTakenTestsOrExams = 'health-insured-osteoporosis-has-taken-tests-or-exams',
  healthInsuredOsteoporosisMedicationWasPrescribed = 'health-insured-osteoporosis-medication-was-prescribed',
  healthInsuredOsteoporosisHadOperation = 'health-insured-osteoporosis-had-operation',
  healthInsuredOsteoporosisOperationPerformedOrPlanned = 'health-insured-osteoporosis-operation-performed-or-planned',
  healthInsuredOsteoporosisTypeOfOperationPerformed = 'health-insured-osteoporosis-type-of-operation-performed',
  healthInsuredOsteoporosisOperationPerformedDate = 'health-insured-osteoporosis-operation-performed-date',
  healthInsuredOsteoporosisTypeOfOperationPlanned = 'health-insured-osteoporosis-type-of-operation-planned',
  healthInsuredOsteoporosisOperationPlannedDate = 'health-insured-osteoporosis-operation-planned-date',
  healthInsuredOsteoporosisOperationUnknownDate = 'health-insured-osteoporosis-operation-unknown-date',
  healthInsuredOsteoporosisHadAbsence = 'health-insured-osteoporosis-had-absence',
  healthInsuredOsteoporosisAbsenceStartDate = 'health-insured-osteoporosis-absence-start-date',
  healthInsuredOsteoporosisAbsenceFrequencyNumber = 'health-insured-osteoporosis-absence-frequency-number',
  healthInsuredOsteoporosisAbsenceFrequency = 'health-insured-osteoporosis-absence-frequency',
  healthInsuredOsteoporosisAbsenceHasRecovered = 'health-insured-osteoporosis-absence-has-recovered',
  healthInsuredOsteoporosisAbsenceRecoveryDate = 'health-insured-osteoporosis-absence-recovery-date',
  healthInsuredOsteoporosisAbsenceNotRecoveredDetails = 'health-insured-osteoporosis-absence-not-recovered-details',

  healthInsuredOsteoporosisHasConsultedDoctorsDetails = 'health-insured-osteoporosis-has-consulted-doctors-details',
  healthInsuredOsteoporosisHasConsultedDoctorsProfession = 'health-insured-osteoporosis-has-consulted-doctors-profession',
  healthInsuredOsteoporosisHasConsultedDoctorsProfessionDetails = 'health-insured-osteoporosis-has-consulted-doctors-profession-details',
  healthInsuredOsteoporosisHasConsultedDoctorsName = 'health-insured-osteoporosis-has-consulted-doctors-name',
  healthInsuredOsteoporosisHasConsultedDoctorsAddress = 'health-insured-osteoporosis-has-consulted-doctors-address',
  healthInsuredOsteoporosisHasConsultedDoctorsFirstDate = 'health-insured-osteoporosis-has-consulted-doctors-first-date',
  healthInsuredOsteoporosisHasConsultedDoctorsLastDate = 'health-insured-osteoporosis-has-consulted-doctors-last-date',
  healthInsuredOsteoporosisHasConsultedDoctorsResult = 'health-insured-osteoporosis-has-consulted-doctors-result',
  healthInsuredOsteoporosisHasConsultedDoctorsFrequencyNumber = 'health-insured-osteoporosis-has-consulted-doctors-frequency-number',
  healthInsuredOsteoporosisHasConsultedDoctorsFrequency = 'health-insured-osteoporosis-has-consulted-doctors-frequency',
  healthInsuredOsteoporosisHasConsultedDoctorFurtherConsultation = 'health-insured-osteoporosis-has-consulted-doctor-further-consultation',
  healthInsuredOsteoporosisHasConsultedDoctorsNextConsultationDate = 'health-insured-osteoporosis-has-consulted-doctors-next-consultation-date',
  healthInsuredOsteoporosisHasTakenTestsOrExamsDetails = 'health-insured-osteoporosis-has-taken-tests-or-exams-details',
  healthInsuredOsteoporosisHasTakenTestsOrExamsType = 'health-insured-osteoporosis-has-taken-tests-or-exams-type',
  healthInsuredOsteoporosisHasTakenTestsOrExamsDate = 'health-insured-osteoporosis-has-taken-tests-or-exams-date',
  healthInsuredOsteoporosisHasTakenTestsOrExamsResult = 'health-insured-osteoporosis-has-taken-tests-or-exams-result',
  healthInsuredOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-osteoporosis-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredOsteoporosisMedicationDetails = 'health-insured-osteoporosis-medication-details',
  healthInsuredOsteoporosisMedicationName = 'health-insured-osteoporosis-medication-name',
  healthInsuredOsteoporosisMedicationDosage = 'health-insured-osteoporosis-medication-dosage',
  healthInsuredOsteoporosisMedicationStartDate = 'health-insured-osteoporosis-medication-start-date',
  healthInsuredOsteoporosisMedicationEndDate = 'health-insured-osteoporosis-medication-end-date',

  healthInsuredWhiplashIsSingleEpisode = 'health-insured-whiplash-is-single-episode',
  healthInsuredWhiplashPreviousEpisodesCount = 'health-insured-whiplash-previous-episodes-count',
  healthInsuredWhiplashPainLocation = 'health-insured-whiplash-pain-location',
  healthInsuredWhiplashDateSymptoms = 'health-insured-whiplash-date-symptoms',
  healthInsuredWhiplashPainCause = 'health-insured-whiplash-pain-cause',
  healthInsuredWhiplashPainCauseDetails = 'health-insured-whiplash-pain-cause-details',
  healthInsuredWhiplashHasConsultedDoctors = 'health-insured-whiplash-has-consulted-doctors',
  healthInsuredWhiplashHasTakenTestsOrExams = 'health-insured-whiplash-has-taken-tests-or-exams',
  healthInsuredWhiplashMedicationWasPrescribed = 'health-insured-whiplash-medication-was-prescribed',
  healthInsuredWhiplashHadOperation = 'health-insured-whiplash-had-operation',
  healthInsuredWhiplashOperationPerformedOrPlanned = 'health-insured-whiplash-operation-performed-or-planned',
  healthInsuredWhiplashTypeOfOperationPerformed = 'health-insured-whiplash-type-of-operation-performed',
  healthInsuredWhiplashOperationPerformedDate = 'health-insured-whiplash-operation-performed-date',
  healthInsuredWhiplashTypeOfOperationPlanned = 'health-insured-whiplash-type-of-operation-planned',
  healthInsuredWhiplashOperationPlannedDate = 'health-insured-whiplash-operation-planned-date',
  healthInsuredWhiplashOperationUnknownDate = 'health-insured-whiplash-operation-unknown-date',
  healthInsuredWhiplashHadAbsence = 'health-insured-whiplash-had-absence',
  healthInsuredWhiplashAbsenceStartDate = 'health-insured-whiplash-absence-start-date',
  healthInsuredWhiplashAbsenceFrequencyNumber = 'health-insured-whiplash-absence-frequency-number',
  healthInsuredWhiplashAbsenceFrequency = 'health-insured-whiplash-absence-frequency',
  healthInsuredWhiplashAbsenceHasRecovered = 'health-insured-whiplash-absence-has-recovered',
  healthInsuredWhiplashAbsenceRecoveryDate = 'health-insured-whiplash-absence-recovery-date',
  healthInsuredWhiplashAbsenceNotRecoveredDetails = 'health-insured-whiplash-absence-not-recovered-details',

  healthInsuredWhiplashHasConsultedDoctorsDetails = 'health-insured-whiplash-has-consulted-doctors-details',
  healthInsuredWhiplashHasConsultedDoctorsProfession = 'health-insured-whiplash-has-consulted-doctors-profession',
  healthInsuredWhiplashHasConsultedDoctorsProfessionDetails = 'health-insured-whiplash-has-consulted-doctors-profession-details',
  healthInsuredWhiplashHasConsultedDoctorsName = 'health-insured-whiplash-has-consulted-doctors-name',
  healthInsuredWhiplashHasConsultedDoctorsAddress = 'health-insured-whiplash-has-consulted-doctors-address',
  healthInsuredWhiplashHasConsultedDoctorsFirstDate = 'health-insured-whiplash-has-consulted-doctors-first-date',
  healthInsuredWhiplashHasConsultedDoctorsLastDate = 'health-insured-whiplash-has-consulted-doctors-last-date',
  healthInsuredWhiplashHasConsultedDoctorsResult = 'health-insured-whiplash-has-consulted-doctors-result',
  healthInsuredWhiplashHasConsultedDoctorsFrequencyNumber = 'health-insured-whiplash-has-consulted-doctors-frequency-number',
  healthInsuredWhiplashHasConsultedDoctorsFrequency = 'health-insured-whiplash-has-consulted-doctors-frequency',
  healthInsuredWhiplashHasConsultedDoctorFurtherConsultation = 'health-insured-whiplash-has-consulted-doctor-further-consultation',
  healthInsuredWhiplashHasConsultedDoctorsNextConsultationDate = 'health-insured-whiplash-has-consulted-doctors-next-consultation-date',
  healthInsuredWhiplashHasTakenTestsOrExamsDetails = 'health-insured-whiplash-has-taken-tests-or-exams-details',
  healthInsuredWhiplashHasTakenTestsOrExamsType = 'health-insured-whiplash-has-taken-tests-or-exams-type',
  healthInsuredWhiplashHasTakenTestsOrExamsDate = 'health-insured-whiplash-has-taken-tests-or-exams-date',
  healthInsuredWhiplashHasTakenTestsOrExamsResult = 'health-insured-whiplash-has-taken-tests-or-exams-result',
  healthInsuredWhiplashHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-whiplash-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredWhiplashMedicationDetails = 'health-insured-whiplash-medication-details',
  healthInsuredWhiplashMedicationName = 'health-insured-whiplash-medication-name',
  healthInsuredWhiplashMedicationDosage = 'health-insured-whiplash-medication-dosage',
  healthInsuredWhiplashMedicationStartDate = 'health-insured-whiplash-medication-start-date',
  healthInsuredWhiplashMedicationEndDate = 'health-insured-whiplash-medication-end-date',

  coverageAmount = 'coverage-amount',
  productId = 'product-id',
  productTerm = 'product-term',
  productAddons = 'product-addons',
  productAddonDetails = 'product-addon-details',
  productPremium = 'product-premium',
  productType = 'product-type',
  productRiskClass = 'product-risk-class',
  productDividends = 'product-dividends',
  productTableRating = 'product-table-rating',
  productFlatRatingAmount = 'product-flat-rating-amount',
  productFlatRatingEnd = 'product-flat-rating-end',

  totalSumInsured = 'total-sum-insured',
  vendorCode = 'vendor-code',
  smokerPremium = 'smoker-premium',
  nonSmokerPremium = 'non-smoker-premium',
  paymentFactor = 'payment-factor',
  minimumPremium = 'minimum-premium',

  hasDifferentPayer = 'has-different-payer',
  payerRole = 'payer-role',
  payerType = 'payer-type',
  payerIsTheLegalGuardian = 'payer-is-the-legal-guardian',
  payerIsTheInsured = 'payer-is-the-insured',
  payerIsThePolicyOwner = 'payer-is-the-policy-owner',
  payerFirstName = 'payerFirstName',
  payerMiddleName = 'payer-middle-name',
  payerLastName = 'payer-last-name',
  payerGender = 'payer-gender',
  payerDateOfBirth = 'payer-date-of-birth',
  payerEmail = 'payer-email',
  payerTelephoneNumber = 'payer-telephone-number',
  payerTelephoneType = 'payer-telephone-type',
  payerAlternateTelephoneNumber = 'payer-alternate-telephone-number',
  payerAlternateTelephoneType = 'payer-alternate-telephone-type',
  payerTelephoneNumberOne = 'payer-telephone-number-one',
  payerTelephoneTypeOne = 'payer-telephone-type-one',
  payerTelephoneNumberTwo = 'payer-telephone-number-two',
  payerTelephoneTypeTwo = 'payer-telephone-type-two',
  payerTelephoneNumberThree = 'payer-telephone-number-three',
  payerTelephoneTypeThree = 'payer-telephone-type-three',
  payerAddressAutocomplete = 'payer-address-autocomplete',
  payerStreetAddress = 'payer-street-address',
  payerApartment = 'payer-apartment',
  payerCity = 'payer-city',
  payerCountry = 'payer-country',
  payerProvince = 'payer-province',
  payerPostalCode = 'payer-postal-code',
  payerSSN = 'payer-ssn-social-security-number',
  payerRelationToInsured = 'payer-relation-to-insured',
  payerRelationToInsuredOther = 'payer-relation-to-insured-other',
  payerTrustOrEntityName = 'payer-trust-or-entity-name',
  payerTrustDate = 'payer-trust-date',
  payerTIN = 'payer-tin',
  payerTrustee = 'payer-trustee',
  payerOfficer = 'payer-officer',
  payerTitle = 'payer-title',
  payerTrustOrEntityAddressStreet = 'payer-trust-or-entity-address-street',
  payerTrustOrEntityAddressApartmentNumber = 'payer-trust-or-entity-address-apartment-number',
  payerTrustOrEntityAddressCity = 'payer-trust-or-entity-address-city',
  payerTrustOrEntityAddressProvinceOrState = 'payer-trust-or-entity-address-province-or-state',
  payerTrustOrEntityAddressPostalCode = 'payer-trust-or-entity-address-postal-code',
  payerTrustOrEntityPrimaryPhoneNumber = 'payer-trust-or-entity-primary-phone-number',
  payerTrustOrEntityPrimaryPhoneType = 'payer-trust-or-entity-primary-phone-type',
  payerTrustOrEntityAlternatePhoneNumber = 'payer-trust-or-entity-alternate-phone-number',
  payerTrustOrEntityAlternatePhoneType = 'payer-trust-or-entity-alternate-phone-type',
  payerTrustOrEntityEmail = 'payer-trust-or-entity-email',
  payerTrustOrEntityRelationToInsured = 'payer-trust-or-entity-relation-to-insured',
  payerOtherTrustOrEntityRelationToInsured = 'payer-other-trust-or-entity-relation-to-insured',
  payerTelephoneExtensionOne = 'payer-telephone-extension-one',
  payerTelephoneExtensionTwo = 'payer-telephone-extension-two',
  payerTelephoneExtensionThree = 'payer-telephone-extension-three',

  firstPaymentOptions = 'first-payment-options',
  paymentBankingInformationNow = 'payment-banking-information-now',
  contractInformationField = 'contract-information-field',
  paymentAnnualPremiumReceptionFull = 'payment-annual-premium-reception-full',
  paymentPartOfAnnualPremiumReceptionPart = 'payment-part-of-annual-premium-reception-part',
  paymentAnnualPremiumUponSettling = 'payment-annual-premium-upon-settling',
  paymentWithdrawalDate = 'payment-withdrawal-date',
  paymentPremiumAmount = 'payment-premium-amount',
  paymentFrequency = 'payment-frequency',
  paymentAccountOwnerFirstName = 'payment-account-owner-first-name',
  paymentAccountOwnerLastName = 'payment-account-owner-last-name',
  paymentAccountType = 'payment-account-type',
  paymentAccountNumber = 'payment-account-number',
  paymentInfo = 'payment-info',
  paymentFinancialInstitution = 'payment-financial-institution',
  paymentBankRoutingNumber = 'payment-bank-routing-number',
  paymentAccountOwnerSignature = 'payment-account-owner-signature',
  paymentAccountOwnerSignatureDate = 'payment-account-owner-signature-date',
  paymentAccountOtherOwnerSignature = 'payment-other-owner-signature',
  paymentAccountOtherOwnerSignatureDate = 'payment-other-owner-signature-date',

  child1BirthDate = 'child-1-birth-date',
  child2BirthDate = 'child-2-birth-date',
  child3BirthDate = 'child-3-birth-date',
  child4BirthDate = 'child-4-birth-date',
  child5BirthDate = 'child-5-birth-date',
  child6BirthDate = 'child-6-birth-date',
  child7BirthDate = 'child-7-birth-date',
  child8BirthDate = 'child-8-birth-date',
  child9BirthDate = 'child-9-birth-date',
  child10BirthDate = 'child-10-birth-date',
  child11BirthDate = 'child-11-birth-date',
  child12BirthDate = 'child-12-birth-date',
  child13BirthDate = 'child-13-birth-date',
  child14BirthDate = 'child-14-birth-date',
  child15BirthDate = 'child-15-birth-date',
  child16BirthDate = 'child-16-birth-date',
  child17BirthDate = 'child-17-birth-date',
  child18BirthDate = 'child-18-birth-date',
  child19BirthDate = 'child-19-birth-date',
  child20BirthDate = 'child-20-birth-date',

  admissibilityCanadianStatus = 'admissibility-canadian-status',
  admissibilityCanadianResidence = 'admissibility-canadian-residence',
  admissibilityDeclined = 'admissibility-declined',
  admissibilityIsProposedInsured = 'admissibility-is-proposed-insured',
  admissibilityHasChangedName = 'admissibility-has-changed-name',

  responsibilityConfirmation = 'responsibility-confirmation',

  insuredHasChangedName = 'insured-has-changed-name',
  previousFirstName = 'previous-first-name',
  previousMiddleName = 'insured-previous-middle-name',
  previousLastName = 'previous-last-name',
  rejectedInsuranceApplication = 'rejected-insurance-application',

  familyHealthIsFatherAlive = 'family-health-is-father-alive',
  familyHealthFatherCurrentAge = 'family-health-father-current-age',
  familyHealthFatherMedicalHistory = 'family-health-father-medical-history',
  familyHealthFatherAgeOfDeath = 'family-health-father-age-of-death',
  familyHealthFatherCauseOfDeath = 'family-health-father-cause-of-death',
  familyHealthIsMotherAlive = 'family-health-is-mother-alive',
  familyHealthMotherCurrentAge = 'family-health-mother-current-age',
  familyHealthMotherMedicalHistory = 'family-health-mother-medical-history',
  familyHealthMotherAgeOfDeath = 'family-health-mother-age-of-death',
  familyHealthMotherCauseOfDeath = 'family-health-mother-cause-of-death',
  familyHealthSiblingInfo = 'family-health-sibling-info',
  familyHealthSiblingIsAlive = 'family-health-sibling-is-alive',
  familyHealthSiblingCurrentAge = 'family-health-sibling-current-age',
  familyHealthSiblingMedicalHistory = 'family-health-sibling-medical-history',
  familyHealthSiblingAgeOfDeath = 'family-health-sibling-age-of-death',
  familyHealthSiblingCauseOfDeath = 'family-health-sibling-cause-of-death',
  healthDiagnosedTreatedOrConsultedForSymptoms = 'health-diagnosed-treated-or-consulted-for-symptoms',
  healthDiagnosedTreatedOrConsultedDetails = 'health-diagnosed-treated-or-consulted-details',
  healthConsultedPhysicianRecommendedForAge = 'health-consulted-physician-recommended-for-age',
  healthConsultedPhysicianRecommendedForAgeResults = 'health-consulted-physician-recommended-for-age-results',
  healthConsultedPhysicianRecommendedForAgeDetails = 'health-consulted-physician-recommended-for-age-details',

  healthMedicalTestsLast5Years = 'health-medical-tests-last-5-years',
  healthMedicalTestsLast5YearsDetails = 'health-medical-tests-last-5-years-details',
  healthInsuredMedicalTestsLast5YearsBloodTestDetails = 'health-insured-medical-tests-last-5-years-blood-test-details',
  healthInsuredMedicalTestsLast5YearsRestingElectrocardiogramDetails = 'health-insured-medical-tests-last-5-years-resting-electrocardiogram-details',
  healthInsuredMedicalTestsLast5YearsStressElectrocardiogramDetails = 'health-insured-medical-tests-last-5-years-stress-electrocardiogram-details',
  healthInsuredMedicalTestsLast5YearsEchocardiogramDetails = 'health-insured-medical-tests-last-5-years-echocardiogram-details',
  healthInsuredMedicalTestsLast5YearsColonoscopyDetails = 'health-insured-medical-tests-last-5-years-colonoscopy-details',
  healthInsuredMedicalTestsLast5YearsXRayDetails = 'health-insured-medical-tests-last-5-years-x-ray-details',
  healthInsuredMedicalTestsLast5YearsMammographyDetails = 'health-insured-medical-tests-last-5-years-mammography-details',
  healthInsuredMedicalTestsLast5YearsUltrasoundDetails = 'health-insured-medical-tests-last-5-years-ultrasound-details',
  healthInsuredMedicalTestsLast5YearsCtScanDetails = 'health-insured-medical-tests-last-5-years-ct-scan-details',
  healthInsuredMedicalTestsLast5YearsMriDetails = 'health-insured-medical-tests-last-5-years-mri-details',

  healthInsuredHadOtherMedicalTestsLast5YearsOtherTestsDetails = 'health-insured-had-other-medical-tests-last-5-years-other-tests-details',
  healthInsuredHadOtherMedicalTestsLast5YearsTests = 'health-insured-had-other-medical-tests-last-5-years-tests',
  healthInsuredHasConsultedOtherProfessionals = 'health-insured-has-consulted-other-professionals',
  healthInsuredLast5YearsHasConsultedOtherProfessionalsDetails = 'health-insured-last-5-years-has-consulted-other-professionals-details',
  healthInsuredMoreMedicalActionPending = 'health-insured-more-medical-action-pending',
  healthInsuredMoreMedicalActionChoices = 'health-insured-more-medical-action-choices',
  healthInsuredMoreMedicalActionTreatmentDetails = 'health-insured-more-medical-action-treatments-details',
  healthInsuredMoreMedicalActionAwaitingResultsDetails = 'health-insured-more-medical-action-awaiting-results-details',
  healthInsuredDiagnosticExamsOrTestsDetails = 'health-insured-diagnostic-exams-or-tests-details',
  healthInsuredMoreMedicalActionSurgeryDetails = 'health-insured-more-medical-action-surgery-details',
  healthConsultedForAbnormalProblemsLast5Years = 'health-consulted-for-abnormal-problems-last-5-years',
  healthAbnormalProblemsLast5YearsDetails = 'health-abnormal-problems-last-5-years-details',
  healthHospitalizedInLast5Years = 'health-hospitalized-in-last-5-years',
  healthHospitalizedLast5YearsDetails = 'health-hospitalized-last-5-years-details',
  healthCovid19DiagnosedOrContact = 'health-covid-19-diagnosed-or-contact',
  healthConfidenceInWeightMeasurement = 'health-confidence-in-weight-measurement',
  healthHasDisabilitySymptoms = 'health-as-disability-symptoms',
  healthInfirmOrInWheelchair = 'health-infirm-or-in-wheelchair',
  healthFullTimeCareNeeded = 'health-full-time-care-needed',
  healthCerebrovascularEvent = 'health-cerebrovascular-event',
  healthCerebrovascularMoreThanOneEvent = 'health-cerebrovascular-more-than-one-event',
  healthCerebrovascularOccurredLessThan8YearsAgo = 'health-cerebrovascular-occurred-less-than-8-years-ago',
  healthCardiovascularEvent = 'health-cardiovascular-event',
  healthCardiovascularHeartMurmurSymptoms = 'health-cardiovascular-heart-murmur-symptoms',
  healthCardiovascularHeartMurmurDiagnosedInChildHood = 'health-cardiovascular-heart-murmur-diagnosed-in-childhood',
  healthCardiovascularHeartMurmurStillPresent = 'health-cardiovascular-heart-murmur-still-present',
  healthCardiovascularChestPainSymptoms = 'health-cardiovascular-chest-pain-symptoms',
  healthCardiovascularChestPainInvestigated = 'health-cardiovascular-chest-pain-investigated',
  healthCardiovascularChestPainHeartRelated = 'health-cardiovascular-chest-pain-heart-related',
  healthCerebrovascularAccident = 'health-cerebrovascular-accident',
  healthTransientIschemiaAttackDate = 'health-transientIschemia-attack-date',
  healthHeartSurgeryDate = 'health-heart-surgery-date',
  healthHeartAttackDate = 'health-heart-attack-date',
  healthInsuredAneurysmDetails = 'health-insured-aneurysm-details',
  healthInsuredAnginaDetails = 'health-insured-angina-details',
  healthInsuredPalpitationsDetails = 'health-insured-palpitations-details',
  healthThyroidCancerSymptoms = 'health-thyroid-cancer-symptoms',
  healthThyroidCancerPast10Years = 'health-thyroid-cancer-past-10-years',
  healthBreastOrOvarianCancerHasFamilyHistory = 'health-breast-or-ovarian-cancer-has-family-history',
  healthBreastOrOvarianCancerRecentTests = 'health-breast-or-ovarian-cancer-recent-tests',
  healthBreastOrOvarianCancerFollowUp = 'health-breast-or-ovarian-cancer-follow-up',
  healthBreastOrOvarianCancerTestsOnHold = 'health-breast-or-ovarian-cancer-tests-on-hold',
  healthProstateCancerFamilyHistory = 'health-prostate-cancer-family-history',
  healthProstateCancerRecentTests = 'health-prostate-cancer-recent-tests',
  healthProstateCancerFollowUp = 'health-prostate-cancer-follow-up',
  healthProstateCancerTestsOnHold = 'health-prostate-cancer-tests-on-hold',
  healthThyroidTreatments = 'health-thyroid-treatments',
  healthCancerSymptoms = 'health-cancer-symptoms',
  healthDiabetesType1Symptoms = 'health-diabetes-type-1-symptoms',
  healthDiabetesType2Symptoms = 'health-diabetes-type-2-symptoms',
  healthDiabetesDiagnosedOver15YearsAgo = 'health-diabetes-diagnosed-over-15-years-ago',
  healthDiabetesFollowupFrequency = 'health-diabetes-followup-frequency',
  healthDiabetesMedicationChangedPast6Months = 'health-diabetes-medication-changed-past-6-months',
  healthDiabetesComplications = 'health-diabetes-complications',
  healthDiabetesType2DiagnosedOver15YearsAgo = 'health-diabetes-type-2-diagnosed-over-15-years-ago',
  healthDiabetesType2FollowupFrequency = 'health-diabetes-type-2-followup-frequency',
  healthDiabetesType2MedicationChangedRecently = 'health-diabetes-type-2-medication-changed-recently',
  healthDiabetesType2Complications = 'health-diabetes-type-2-complications',
  healthHepatitisSymptoms = 'health-hepatitis-symptoms',
  healthHepatitisChronicKidneyDiseaseSymptoms = 'health-hepatitis-chronic-kidney-disease-symptoms',
  healthHepatitisInsufficiencyDate = 'health-hepatitis-insufficiency-date',
  healthHepatitisType = 'health-hepatitis-type',
  healthHepatitisPresent = 'health-hepatitis-present',
  healthHepatitisDiagnosisDate = 'health-hepatitis-diagnosis-date',
  healthHepatitisLiverFunctionTest = 'health-hepatitis-liver-function-test',
  healthMusculoskeletalSymptoms = 'health-musculoskeletal-symptoms',
  healthMusculoskeletalArthritis = 'health-musculoskeletal-arthritis',
  healthMusculoskeletalArthritisControlled = 'health-musculoskeletal-arthritis-controlled',
  healthEyeOrSkinSymptoms = 'health-eye-or-skin-symptoms',
  healthGlandSymptoms = 'health-gland-symptoms',
  healthDigestiveOrEndocrineSymptoms = 'health-digestive-or-endocrine-symptoms',
  healthDigestiveOrEndocrineStatus = 'health-digestive-or-endocrine-status',
  healthInsuredGerdIsControlled = 'health-insured-gerd-is-controlled',
  healthInsuredGerdDetails = 'health-insured-gerd-details',
  healthInsuredGerdComplicationsDetails = 'health-insured-gerd-complications-details',
  healthInsuredGerdConsulted = 'health-insured-gerd-consulted',
  healthInsuredGerdComplications = 'health-insured-gerd-complications',
  healthGastroesophagalRefluxDiseaseSymptoms = 'health-gastroesophagal-reflux-disease-symptoms',
  healthGastroesophagalRefluxDiseaseAnyComplications = 'health-gastroesophagal-reflux-disease-any-complications',
  healthInsuredGallstonesDiagnosisDate = 'health-insured-gallstones-diagnosis-date',
  healthInsuredGallstonesHadOperation = 'health-insured-gallstones-had-operation',
  healthInsuredGallstonesOperationDate = 'health-insured-gallstones-operation-date',
  healthInsuredGallstonesPastSurgeryHasRecovered = 'health-insured-gallstones-past-surgery-has-recovered',
  healthInsuredGallstonesHasPendingSurgery = 'health-insured-gallstones-has-pending-surgery',
  healthInsuredGallstonesPendingSurgeryDate = 'health-insured-gallstones-pending-surgery-date',
  healthInsuredDigestiveSystemHasCondition = 'health-insured-digestive-system-has-condition',
  healthInsuredDigestiveSystemChoices = 'health-insured-digestive-system-choices',
  healthInsuredCrohnsDiseaseDetails = 'health-insured-crohns-disease-details',
  healthInsuredUlcerativeColitisDetails = 'health-insured-ulcerative-colitis-details',
  healthInsuredCeliacDiseaseDetails = 'health-insured-celiac-disease-details',
  healthInsuredStomachPolypsDetails = 'health-insured-stomach-polyps-details',
  healthInsuredHepatitisDetails = 'health-insured-hepatitis-details',
  healthInsuredPancreatitisDetails = 'health-insured-pancreatitis-details',
  healthInsuredUlcersDetails = 'health-insured-ulcers-details',
  healthInsuredCirrhosisDetails = 'health-insured-cirrhosis-details',
  healthInsuredBleedingDetails = 'health-insured-bleeding-details',
  healthInsuredAnyOtherDisorderOfTheDigestiveSystemDetails = 'health-insured-any-other-disorder-of-the-digestive-system-details',
  healthGenitourinaryConsultedOrTreated = 'health-genitourinary-consulted-or-treated',
  healthGenitourinaryStatus = 'health-genitourinary-status',
  healthGenitourinarySymptoms = 'health-genitourinary-symptoms',
  healthGenitourinaryKidneyStones = 'health-genitourinary-kidney-stones',
  healthGenitourinaryKidneyStonesControlled = 'heath-genitourinary-kidney-stones-controlled',
  healthAnemiaSymptoms = 'health-anemia-symptoms',
  healthMentalHealthDiagnosis = 'health-mental-health-diagnosis',
  healthMentalHealthChoices = 'health-mental-health-choices',
  healthMentalHealthSymptoms = 'health-mental-health',
  healthMentalHealthStatus = 'health-mental-health-status',
  healthMentalHealthAbsentWork = 'health-mental-health-absent-work',
  healthMentalHealthAbsentWorkDepression = 'health-mental-health-absent-work-depression',
  healthMentalHealthSchizophreniaDate = 'health-mental-health-schizophrenia-date',
  healthMentalHealthPsychosisDate = 'health-mental-health-psychosis-date',
  healthMentalHealthSuicidalDate = 'health-mental-health-suicidal-date',
  healthArthritisHadSymptoms = 'health-arthritis-had-symptoms',
  healthNeurologicalSymptoms = 'health-neurological-symptoms',
  healthImmuneSystemSymptoms = 'health-immune-system-symptoms',
  healthImmuneSystemHadHivTest = 'health-immune-system-had-hiv-test',
  healthImmuneSystemHivTestNegative = 'health-immune-system-hiv-test-negative',
  healthWaitingRoutineScreeningResults = 'health-waiting-routine-screening-results',
  healthTestsForSymptomsOrFamilyHistory = 'health-tests-for-symptoms-or-family-history',
  healthFamilyHistory = 'health-family-history',
  healthHivPositiveTest = 'health-hiv-positive-test',
  healthBowelSymptoms = 'health-bowel-symptoms',
  healthBowelDiagnosedPast1Year = 'health-bowel-diagnosed-past-1-year',
  healthBowelMultipleAttacks = 'health-bowel-multiple-attacks',
  healthPulmonarySymptoms = 'health-pulmonary-symptoms',
  healthPulmonaryAsthma = 'health-pulmonary-asthma',
  healthPulmonaryAsthmaControlled = 'health-pulmonary-asthma-controlled',
  healthMedicalTestAbnormalResults = 'health-medical-test-abnormal-results',
  healthMedicalTestsUltrasound = 'health-medical-tests-ultrasound',
  healthMedicalTestsMammogram = 'health-medical-tests-mammogram',
  healthMedicalTestsElectrocardiogram = 'health-medical-tests-electrocardiogram',
  healthMedicalTestsBiopsyOrPathologyReport = 'health-medical-tests-biopsy-or-pathology-report',
  healthMedicalTestsChestXRay = 'health-medical-tests-chest-xray',
  healthMedicalTestsMRI = 'health-medical-tests-mri',
  healthMedicalTestsEchocardiogram = 'health-medical-tests-echocardiogram',
  healthMedicalTestsProstateSpecificAntigen = 'health-medical-tests-prostate-specific-antigen',
  healthConsultedPhysicianAwaitingDiagnosis = 'health-consulted-physician-awaiting-diagnosis',
  healthWaitingOnExternalDependency = 'health-waiting-on-external-dependency',
  healthAbnormalTestPhysicianNotYetConsulted = 'health-medical-tests-abnormal-test-physician-not-yet-consulted',
  healthProblemsPhysicianNotYetConsulted = 'health-problems-physician-not-yet-consulted',
  healthFamilyMedicalHistory = 'health-family-medical-history',
  healthMedicalHistoryHasSymptoms = 'health-medical-history-has-symptoms',
  healthHospitalizedMoreThan24Hours = 'health-hospitalized-more-than-24-hours',
  healthConsultedForHighBloodPressure = 'health-consulted-for-high-blood-pressure',
  healthFamilyHistoryHypercholesterolemia = 'health-family-history-hypercholesterolemia',
  healthHistoryDiabetesOrCardiovascular = 'health-history-diabetes-or-cardiovascular',
  healthHistoryBloodPressureCholesterol = 'health-history-blood-pressure-cholesterol',
  healthDisabilityCauseOtherThanDepression = 'health-disability-cause-other-than-depression',
  healthDepression30DayMissedWorkOrHospitalized = 'health-depression-30-day-missed-work-or-hospitalized',
  healthMentalHealthBipolarDate = 'health-mental-health-bipolar-date',
  healthMentalHealthHospitalizationDate = 'health-mental-health-hospitalization-date',
  healthInjuryJointDiagnosisDate = 'health-injury-joint-diagnosis-date',
  healthInjuryJointMedications = 'health-injury-joint-medications',
  healthInjuryJointAdditionalTreatment = 'health-injury-joint-additional-treatment',
  healthInjuryJointStatus = 'health-injury-joint-status',
  healthInjuryJointDateOfFullRecovery = 'health-injury-joint-date-of-full-recovery',
  healthInjuryJointLimitationDetails = 'health-injury-joint-limitation-details',
  healthInjuryJointCurrentlyExperienceSymptoms = 'health-injury-joint-currently-experience-symptoms',
  healthInjuryJointDateOfLastSymptom = 'health-injury-joint-date-of-last-symptom',
  healthInjuryJointDateOfLastOccurrence = 'health-injury-joint-date-of-last-occurrence',
  healthInjuryJointNumberOfOccurrences = 'health-injury-joint-number-of-occurrences',
  healthInjuryJointCausePhysicalLimitations = 'health-injury-joint-cause-physical-limitations',
  healthInjuryJointLimitationDetails2 = 'health-injury-joint-limitation-details-2',
  healthInjuryJointMedProv = 'health-injury-joint-med-prov',
  healthInjuryJointMedProvName = 'health-injury-joint-med-prov-name',
  healthInjuryJointMedProvAddress = 'health-injury-joint-med-prov-address',
  healthInjuryJointMedProvPhone = 'health-injury-joint-med-prov-phone',
  healthInjuryJointHospitalVisit = 'health-injury-joint-hospital-visit',
  healthInjuryJointHospitalVisitDate = 'health-injury-joint-hospital-visit-date',
  healthInjuryJointHospitalName = 'health-injury-joint-hospital-name',
  healthInjuryJointHospitalAddress = 'health-injury-joint-hospital-address',
  healthBurnoutNumberOfOccurrence = 'health-burnout-number-of-occurrence',
  healthBurnoutDateOfLastOccurrence = 'health-burnout-date-of-last-occurrence',
  healthBurnoutMedications = 'health-burnout-medications',
  healthBurnoutCausePhysicalLimitations = 'health-burnout-cause-physical-limitations',
  healthBurnoutLimitationDetails = 'health-burnout-limitation-details',
  healthInsominaMedication = 'health-insomina-medication',
  healthInsominaMedicationAmount = 'health-insomina-medication-amount',
  healthInsomniaDiagnosisDate = 'health-insomnia-diagnosis-date',
  healthInsomniaCause = 'health-insomnia-cause',
  healthInsomniaPhysician = 'health-insomnia-physician',
  healthInsomniaDateLastConsultation = 'health-insomnia-date-last-consultation',
  healthInsomniaPhysicianVisitsFrequency = 'health-insomnia-physician-visits-frequency',
  healthInsomniaHospitalVisit = 'health-insomnia-hospital-visit',
  healthInsomniaHospitalVisitDate = 'health-insomnia-hospital-visit-date',
  healthInsomniaHospitalName = 'health-insomnia-hospital-name',
  healthInsomniaHospitalAddress = 'health-insomnia-hospital-address',
  healthInsomniaTreatment = 'health-insomnia-treatment',
  healthInsomniaTreatmentDetails = 'health-insomnia-treatment-details',
  healthInsomniaPsychotherapy = 'health-insomnia-psychotherapy',
  healthInsomniaPsychotherapyDetails = 'health-insomnia-psychotherapy-details',
  healthInsomniaSymptoms = 'health-insomnia-symptoms',
  healthInsomniaOffWork = 'health-insomnia-off-work',
  healthInsomniaOffWorkDetails = 'health-insomnia-off-work-details',
  healthInsomniaDetails = 'health-insomnia-details',
  healthIsPregnant = 'health-is-pregnant',
  healthPregnancyDueDate = 'health-pregnancy-due-date',
  healthPregnancyComplications = 'health-pregnancy-complications',
  healthPregnancyComplicationsDetails = 'health-pregnancy-complications-details',
  healthMedication = 'health-medication',
  healthMedicationDetails = 'health-medication-details',
  healthPhoneInterviewPreselection = 'health-phone-interview-preselection',
  healthPhoneInterviewDay = 'health-phone-interview-day',
  healthPhoneInterviewTime = 'health-phone-interview-time',
  healthPhoneInterviewNumber = 'health-phone-interview-number',
  healthPhoneInterviewDay2 = 'health-phone-interview-day-2',
  healthPhoneInterviewTime2 = 'health-phone-interview-time-2',
  healthPhoneInterviewNumber2 = 'health-phone-interview-number-2',
  healthInjuryEyesDiagnosisDate = 'health-injury-eyes-diagnosis-date',
  healthInjuryEyesMedications = 'health-injury-eyes-medications',
  healthInjuryEyesAdditionalTreatment = 'health-injury-eyes-additional-treatment',
  healthInjuryEyesStatus = 'health-injury-eyes-status',
  healthInjuryEyesDateOfFullRecovery = 'health-injury-eyes-date-of-full-recovery',
  healthInjuryEyesLimitationDetails = 'health-injury-eyes-limitation-details',
  healthInjuryEyesCurrentlyExperienceSymptoms = 'health-injury-eyes-currently-experience-symptoms',
  healthInjuryEyesDateOfLastSymptom = 'health-injury-eyes-date-of-last-symptom',
  healthInjuryEyesDateOfLastOccurrence = 'health-injury-eyes-date-of-last-occurrence',
  healthInjuryEyesNumberOfOccurrences = 'health-injury-eyes-number-of-occurrences',
  healthInjuryEyesCausePhysicalLimitations = 'health-injury-eyes-cause-physical-limitations',
  healthInjuryEyesLimitationDetails2 = 'health-injury-eyes-limitation-details-2',
  healthInjuryEyesMedProv = 'health-injury-eyes-med-prov',
  healthInjuryEyesMedProvName = 'health-injury-eyes-med-prov-name',
  healthInjuryEyesMedProvAddress = 'health-injury-eyes-med-prov-address',
  healthInjuryEyesMedProvPhone = 'health-injury-eyes-med-prov-phone',
  healthInjuryEyesHospitalVisit = 'health-injury-eyes-hospital-visit',
  healthInjuryEyesHospitalVisitDate = 'health-injury-eyes-hospital-visit-date',
  healthInjuryEyesHospitalName = 'health-injury-eyes-hospital-name',
  healthInjuryEyesHospitalAddress = 'health-injury-eyes-hospital-address',
  healthInjurySkinDiagnosisDate = 'health-injury-skin-diagnosis-date',
  healthInjurySkinMedications = 'health-injury-skin-medications',
  healthInjurySkinAdditionalTreatment = 'health-injury-skin-additional-treatment',
  healthInjurySkinStatus = 'health-injury-skin-status',
  healthInjurySkinDateOfFullRecovery = 'health-injury-skin-date-of-full-recovery',
  healthInjurySkinLimitationDetails = 'health-injury-skin-limitation-details',
  healthInjurySkinCurrentlyExperienceSymptoms = 'health-injury-skin-currently-experience-symptoms',
  healthInjurySkinDateOfLastSymptom = 'health-injury-skin-date-of-last-symptom',
  healthInjurySkinDateOfLastOccurrence = 'health-injury-skin-date-of-last-occurrence',
  healthInjurySkinNumberOfOccurrences = 'health-injury-skin-number-of-occurrences',
  healthInjurySkinCausePhysicalLimitations = 'health-injury-skin-cause-physical-limitations',
  healthInjurySkinLimitationDetails2 = 'health-injury-skin-limitation-details-2',
  healthInjurySkinMedProv = 'health-injury-skin-med-prov',
  healthInjurySkinMedProvName = 'health-injury-skin-med-prov-name',
  healthInjurySkinMedProvAddress = 'health-injury-skin-med-prov-address',
  healthInjurySkinMedProvPhone = 'health-injury-skin-med-prov-phone',
  healthInjurySkinHospitalVisit = 'health-injury-skin-hospital-visit',
  healthInjurySkinHospitalVisitDate = 'health-injury-skin-hospital-visit-date',
  healthInjurySkinHospitalName = 'health-injury-skin-hospital-name',
  healthInjurySkinHospitalAddress = 'health-injury-skin-hospital-address',
  healthSeizureDiagnosisDate = 'health-seizure-diagnosis-date',
  healthSeizureMedications = 'health-seizure-medications',
  healthSeizureAdditionalTreatment = 'health-seizure-additional-treatment',
  healthSeizureFirstDate = 'health-seizure-first-date',
  healthSeizureOccur = 'health-seizure-occur',
  healthSeizureLastDate = 'health-seizure-last-date',
  healthSeizureType = 'health-seizure-type',
  healthSeizureOther = 'health-seizure-other',
  healthSeizureStatus = 'health-seizure-status',
  healthSeizureDateOfFullRecovery = 'health-seizure-date-of-full-recovery',
  healthSeizureLimitationDetails = 'health-seizure-limitation-details',
  healthSeizureCurrentlyExperienceSymptoms = 'health-seizure-currently-experience-symptoms',
  healthSeizureDateOfLastSymptom = 'health-seizure-date-of-last-symptom',
  healthSeizureDateOfLastOccurrence = 'health-seizure-date-of-last-occurrence',
  healthSeizureNumberOfOccurrences = 'health-seizure-number-of-occurrences',
  healthSeizureCausePhysicalLimitations = 'health-seizure-cause-physical-limitations',
  healthSeizureLimitationDetails2 = 'health-seizure-limitation-details-2',
  healthSeizureMedProv = 'health-seizure-med-prov',
  healthSeizureMedProvName = 'health-seizure-med-prov-name',
  healthSeizureMedProvAddress = 'health-seizure-med-prov-address',
  healthSeizureMedProvPhone = 'health-seizure-med-prov-phone',
  healthSeizureMedicationNumber = 'health-seizure-medication-number',
  healthSeizureMedicationName = 'health-seizure-medication-name',
  healthSeizureHospitalVisit = 'health-seizure-hospital-visit',
  healthSeizureHospitalVisitDate = 'health-seizure-hospital-visit-date',
  healthSeizureHospitalName = 'health-seizure-hospital-name',
  healthSeizureHospitalAddress = 'health-seizure-hospital-address',
  healthSeizureEmergencyRoomVisit = 'health-seizure-emergency-room-visit',
  healthSeizureEmergencyRoomDate = 'health-seizure-emergency-room-date',
  healthSeizureEmergencyRoomName = 'health-seizure-emergency-room-name',
  healthSeizureEmergencyRoomAddress = 'health-seizure-emergency-room-address',
  healthFaintingDiagnosisDate = 'health-fainting-diagnosis-date',
  healthFaintingMedications = 'health-fainting-medications',
  healthFaintingAdditionalTreatment = 'health-fainting-additional-treatment',
  healthFaintingStatus = 'health-fainting-status',
  healthFaintingDateOfFullRecovery = 'health-fainting-date-of-full-recovery',
  healthFaintingLimitationDetails = 'health-fainting-limitation-details',
  healthFaintingCurrentlyExperienceSymptoms = 'health-fainting-currently-experience-symptoms',
  healthFaintingDateOfLastSymptom = 'health-fainting-date-of-last-symptom',
  healthFaintingDateOfLastOccurrence = 'health-fainting-date-of-last-occurrence',
  healthFaintingNumberOfOccurrences = 'health-fainting-number-of-occurrences',
  healthFaintingCausePhysicalLimitations = 'health-fainting-cause-physical-limitations',
  healthFaintingLimitationDetails2 = 'health-fainting-limitation-details-2',
  healthFaintingMedProv = 'health-fainting-med-prov',
  healthFaintingMedProvName = 'health-fainting-med-prov-name',
  healthFaintingMedProvAddress = 'health-fainting-med-prov-address',
  healthFaintingMedProvPhone = 'health-fainting-med-prov-phone',
  healthFaintingHospitalVisit = 'health-fainting-hospital-visit',
  healthFaintingHospitalVisitDate = 'health-fainting-hospital-visit-date',
  healthFaintingHospitalName = 'health-fainting-hospital-name',
  healthFaintingHospitalAddress = 'health-fainting-hospital-address',
  healthTremorDiagnosisDate = 'health-tremor-diagnosis-date',
  healthTremorMedications = 'health-tremor-medications',
  healthTremorAdditionalTreatment = 'health-tremor-additional-treatment',
  healthTremorStatus = 'health-tremor-status',
  healthTremorDateOfFullRecovery = 'health-tremor-date-of-full-recovery',
  healthTremorLimitationDetails = 'health-tremor-limitation-details',
  healthTremorCurrentlyExperienceSymptoms = 'health-tremor-currently-experience-symptoms',
  healthTremorDateOfLastSymptom = 'health-tremor-date-of-last-symptom',
  healthTremorDateOfLastOccurrence = 'health-tremor-date-of-last-occurrence',
  healthTremorNumberOfOccurrences = 'health-tremor-number-of-occurrences',
  healthTremorCausePhysicalLimitations = 'health-tremor-cause-physical-limitations',
  healthTremorLimitationDetails2 = 'health-tremor-limitation-details-2',
  healthTremorMedProv = 'health-tremor-med-prov',
  healthTremorMedProvName = 'health-tremor-med-prov-name',
  healthTremorMedProvAddress = 'health-tremor-med-prov-address',
  healthTremorMedProvPhone = 'health-tremor-med-prov-phone',
  healthTremorHospitalVisit = 'health-tremor-hospital-visit',
  healthTremorHospitalVisitDate = 'health-tremor-hospital-visit-date',
  healthTremorHospitalName = 'health-tremor-hospital-name',
  healthTremorHospitalAddress = 'health-tremor-hospital-address',
  healthInsuredMultipleSclerosisDetails = 'health-insured-multiple-sclerosis-details',
  healthMultipleSclerosisDiagnosisDate = 'health-multiple-sclerosis-diagnosis-date',
  healthMultipleSclerosisMedications = 'health-multiple-sclerosis-medications',
  healthMultipleSclerosisAdditionalTreatment = 'health-multiple-sclerosis-additional-treatment',
  healthMultipleSclerosisStatus = 'health-multiple-sclerosis-status',
  healthMultipleSclerosisDateOfFullRecovery = 'health-multiple-sclerosis-date-of-full-recovery',
  healthMultipleSclerosisLimitationDetails = 'health-multiple-sclerosis-limitation-details',
  healthMultipleSclerosisCurrentlyExperienceSymptoms = 'health-multiple-sclerosis-currently-experience-symptoms',
  healthMultipleSclerosisDateOfLastSymptom = 'health-multiple-sclerosis-date-of-last-symptom',
  healthMultipleSclerosisDateOfLastOccurrence = 'health-multiple-sclerosis-date-of-last-occurrence',
  healthMultipleSclerosisNumberOfOccurrences = 'health-multiple-sclerosis-number-of-occurrences',
  healthMultipleSclerosisCausePhysicalLimitations = 'health-multiple-sclerosis-cause-physical-limitations',
  healthMultipleSclerosisLimitationDetails2 = 'health-multiple-sclerosis-limitation-details-2',
  healthMultipleSclerosisMedProv = 'health-multiple-sclerosis-med-prov',
  healthMultipleSclerosisMedProvName = 'health-multiple-sclerosis-med-prov-name',
  healthMultipleSclerosisMedProvAddress = 'health-multiple-sclerosis-med-prov-address',
  healthMultipleSclerosisMedProvPhone = 'health-multiple-sclerosis-med-prov-phone',
  healthMultipleSclerosisHospitalVisit = 'health-multiple-sclerosis-hospital-visit',
  healthMultipleSclerosisHospitalVisitDate = 'health-multiple-sclerosis-hospital-visit-date',
  healthMultipleSclerosisHospitalName = 'health-multiple-sclerosis-hospital-name',
  healthMultipleSclerosisHospitalAddress = 'health-multiple-sclerosis-hospital-address',
  healthInsuredParalysisDetails = 'health-insured-paralysis-details',
  healthParalysisDiagnosisDate = 'health-paralysis-diagnosis-date',
  healthParalysisMedications = 'health-paralysis-medications',
  healthParalysisAdditionalTreatment = 'health-paralysis-additional-treatment',
  healthParalysisStatus = 'health-paralysis-status',
  healthParalysisDateOfFullRecovery = 'health-paralysis-date-of-full-recovery',
  healthParalysisLimitationDetails = 'health-paralysis-limitation-details',
  healthParalysisCurrentlyExperienceSymptoms = 'health-paralysis-currently-experience-symptoms',
  healthParalysisDateOfLastSymptom = 'health-paralysis-date-of-last-symptom',
  healthParalysisDateOfLastOccurrence = 'health-paralysis-date-of-last-occurrence',
  healthParalysisNumberOfOccurrences = 'health-paralysis-number-of-occurrences',
  healthParalysisCausePhysicalLimitations = 'health-paralysis-cause-physical-limitations',
  healthParalysisLimitationDetails2 = 'health-paralysis-limitation-details-2',
  healthParalysisMedProv = 'health-paralysis-med-prov',
  healthParalysisMedProvName = 'health-paralysis-med-prov-name',
  healthParalysisMedProvAddress = 'health-paralysis-med-prov-address',
  healthParalysisMedProvPhone = 'health-paralysis-med-prov-phone',
  healthParalysisHospitalVisit = 'health-paralysis-hospital-visit',
  healthParalysisHospitalVisitDate = 'health-paralysis-hospital-visit-date',
  healthParalysisHospitalName = 'health-paralysis-hospital-name',
  healthParalysisHospitalAddress = 'health-paralysis-hospital-address',
  healthInsuredCerebralPalsyDetails = 'health-insured-cerebral-palsy-details',
  healthInsuredConcussionDetails = 'health-insured-concussion-details',
  healthInsuredMigrainesDetails = 'health-insured-migraines-details',
  healthInsuredAlzheimerDetails = 'health-insured-alzheimer-details',
  healthInsuredMemoryLossDetails = 'health-insured-memory-loss-details',
  healthInsuredDegenerativeDiseaseDetails = 'health-insured-degenerative-disease-details',
  healthInsuredParkinsonsDetails = 'health-insured-parkinsons-details',
  healthParkinsonDiagnosisDate = 'health-parkinson-diagnosis-date',
  healthParkinsonMedications = 'health-parkinson-medications',
  healthParkinsonAdditionalTreatment = 'health-parkinson-additional-treatment',
  healthParkinsonStatus = 'health-parkinson-status',
  healthParkinsonDateOfFullRecovery = 'health-parkinson-date-of-full-recovery',
  healthParkinsonLimitationDetails = 'health-parkinson-limitation-details',
  healthParkinsonCurrentlyExperienceSymptoms = 'health-parkinson-currently-experience-symptoms',
  healthParkinsonDateOfLastSymptom = 'health-parkinson-date-of-last-symptom',
  healthParkinsonDateOfLastOccurrence = 'health-parkinson-date-of-last-occurrence',
  healthParkinsonNumberOfOccurrences = 'health-parkinson-number-of-occurrences',
  healthParkinsonCausePhysicalLimitations = 'health-parkinson-cause-physical-limitations',
  healthParkinsonLimitationDetails2 = 'health-parkinson-limitation-details-2',
  healthParkinsonMedProv = 'health-parkinson-med-prov',
  healthParkinsonMedProvName = 'health-parkinson-med-prov-name',
  healthParkinsonMedProvAddress = 'health-parkinson-med-prov-address',
  healthParkinsonMedProvPhone = 'health-parkinson-med-prov-phone',
  healthParkinsonHospitalVisit = 'health-parkinson-hospital-visit',
  healthParkinsonHospitalVisitDate = 'health-parkinson-hospital-visit-date',
  healthParkinsonHospitalName = 'health-parkinson-hospital-name',
  healthParkinsonHospitalAddress = 'health-parkinson-hospital-address',
  healthMotorNeuronDiseaseDiagnosisDate = 'health-motor-neuron-disease-diagnosis-date',
  healthMotorNeuronDiseaseMedications = 'health-motor-neuron-disease-medications',
  healthMotorNeuronDiseaseAdditionalTreatment = 'health-motor-neuron-disease-additional-treatment',
  healthMotorNeuronDiseaseStatus = 'health-motor-neuron-disease-status',
  healthMotorNeuronDiseaseDateOfFullRecovery = 'health-motor-neuron-disease-date-of-full-recovery',
  healthMotorNeuronDiseaseLimitationDetails = 'health-motor-neuron-disease-limitation-details',
  healthMotorNeuronDiseaseCurrentlyExperienceSymptoms = 'health-motor-neuron-disease-currently-experience-symptoms',
  healthMotorNeuronDiseaseDateOfLastSymptom = 'health-motor-neuron-disease-date-of-last-symptom',
  healthMotorNeuronDiseaseDateOfLastOccurrence = 'health-motor-neuron-disease-date-of-last-occurrence',
  healthMotorNeuronDiseaseNumberOfOccurrences = 'health-motor-neuron-disease-number-of-occurrences',
  healthMotorNeuronDiseaseCausePhysicalLimitations = 'health-motor-neuron-disease-cause-physical-limitations',
  healthMotorNeuronDiseaseLimitationDetails2 = 'health-motor-neuron-disease-limitation-details-2',
  healthMotorNeuronDiseaseMedProv = 'health-motor-neuron-disease-med-prov',
  healthMotorNeuronDiseaseMedProvName = 'health-motor-neuron-disease-med-prov-name',
  healthMotorNeuronDiseaseMedProvAddress = 'health-motor-neuron-disease-med-prov-address',
  healthMotorNeuronDiseaseMedProvPhone = 'health-motor-neuron-disease-med-prov-phone',
  healthMotorNeuronDiseaseHospitalVisit = 'health-motor-neuron-disease-hospital-visit',
  healthMotorNeuronDiseaseHospitalVisitDate = 'health-motor-neuron-disease-hospital-visit-date',
  healthMotorNeuronDiseaseHospitalName = 'health-motor-neuron-disease-hospital-name',
  healthMotorNeuronDiseaseHospitalAddress = 'health-motor-neuron-disease-hospital-address',
  healthNsOtherDiseaseDiagnosisDate = 'health-ns-other-disease-diagnosis-date',
  healthNsOtherDiseaseMedications = 'health-ns-other-disease-medications',
  healthNsOtherDiseaseAdditionalTreatment = 'health-ns-other-disease-additional-treatment',
  healthNsOtherDiseaseStatus = 'health-ns-other-disease-status',
  healthNsOtherDiseaseDateOfFullRecovery = 'health-ns-other-disease-date-of-full-recovery',
  healthNsOtherDiseaseLimitationDetails = 'health-ns-other-disease-limitation-details',
  healthNsOtherDiseaseCurrentlyExperienceSymptoms = 'health-ns-other-disease-currently-experience-symptoms',
  healthNsOtherDiseaseDateOfLastSymptom = 'health-ns-other-disease-date-of-last-symptom',
  healthNsOtherDiseaseDateOfLastOccurrence = 'health-ns-other-disease-date-of-last-occurrence',
  healthNsOtherDiseaseNumberOfOccurrences = 'health-ns-other-disease-number-of-occurrences',
  healthNsOtherDiseaseCausePhysicalLimitations = 'health-ns-other-disease-cause-physical-limitations',
  healthNsOtherDiseaseLimitationDetails2 = 'health-ns-other-disease-limitation-details-2',
  healthNsOtherDiseaseMedProv = 'health-ns-other-disease-med-prov',
  healthNsOtherDiseaseMedProvName = 'health-ns-other-disease-med-prov-name',
  healthNsOtherDiseaseMedProvAddress = 'health-ns-other-disease-med-prov-address',
  healthNsOtherDiseaseMedProvPhone = 'health-ns-other-disease-med-prov-phone',
  healthNsOtherDiseaseHospitalVisit = 'health-ns-other-disease-hospital-visit',
  healthNsOtherDiseaseHospitalVisitDate = 'health-ns-other-disease-hospital-visit-date',
  healthNsOtherDiseaseHospitalName = 'health-ns-other-disease-hospital-name',
  healthNsOtherDiseaseHospitalAddress = 'health-ns-other-disease-hospital-address',
  healthHasImmuneDeficiencySymptoms = 'health-has-immune-deficiency-symptoms',
  healthHasImmuneDeficiencyChoices = 'health-has-immune-deficiency-choices',
  healthImmuneDeficiencyDiagnosisDate = 'health-immune-deficiency-diagnosis-date',
  healthImmuneDeficiencyMedications = 'health-immune-deficiency-medications',
  healthImmuneDeficiencyAdditionalTreatment = 'health-immune-deficiency-additional-treatment',
  healthImmuneDeficiencyStatus = 'health-immune-deficiency-status',
  healthImmuneDeficiencyDateOfFullRecovery = 'health-immune-deficiency-date-of-full-recovery',
  healthImmuneDeficiencyLimitationDetails = 'health-immune-deficiency-limitation-details',
  healthImmuneDeficiencyCurrentlyExperienceSymptoms = 'health-immune-deficiency-currently-experience-symptoms',
  healthImmuneDeficiencyDateOfLastSymptom = 'health-immune-deficiency-date-of-last-symptom',
  healthImmuneDeficiencyDateOfLastOccurrence = 'health-immune-deficiency-date-of-last-occurrence',
  healthImmuneDeficiencyNumberOfOccurrences = 'health-immune-deficiency-number-of-occurrences',
  healthImmuneDeficiencyCausePhysicalLimitations = 'health-immune-deficiency-cause-physical-limitations',
  healthImmuneDeficiencyLimitationDetails2 = 'health-immune-deficiency-limitation-details-2',
  healthImmuneDeficiencyMedProv = 'health-immune-deficiency-med-prov',
  healthImmuneDeficiencyMedProvName = 'health-immune-deficiency-med-prov-name',
  healthImmuneDeficiencyMedProvAddress = 'health-immune-deficiency-med-prov-address',
  healthImmuneDeficiencyMedProvPhone = 'health-immune-deficiency-med-prov-phone',
  healthImmuneDeficiencyHospitalVisit = 'health-immune-deficiency-hospital-visit',
  healthImmuneDeficiencyHospitalVisitDate = 'health-immune-deficiency-hospital-visit-date',
  healthImmuneDeficiencyHospitalName = 'health-immune-deficiency-hospital-name',
  healthImmuneDeficiencyHospitalAddress = 'health-immune-deficiency-hospital-address',
  healthAnemiaDiagnosisDate = 'health-anemia-diagnosis-date',
  healthAnemiaMedications = 'health-anemia-medications',
  healthAnemiaAdditionalTreatment = 'health-anemia-additional-treatment',
  healthAnemiaStatus = 'health-anemia-status',
  healthAnemiaDateOfFullRecovery = 'health-anemia-date-of-full-recovery',
  healthAnemiaLimitationDetails = 'health-anemia-limitation-details',
  healthAnemiaCurrentlyExperienceSymptoms = 'health-anemia-currently-experience-symptoms',
  healthAnemiaDateOfLastSymptom = 'health-anemia-date-of-last-symptom',
  healthAnemiaDateOfLastOccurrence = 'health-anemia-date-of-last-occurrence',
  healthAnemiaNumberOfOccurrences = 'health-anemia-number-of-occurrences',
  healthAnemiaCausePhysicalLimitations = 'health-anemia-cause-physical-limitations',
  healthAnemiaLimitationDetails2 = 'health-anemia-limitation-details-2',
  healthAnemiaMedProv = 'health-anemia-med-prov',
  healthAnemiaMedProvName = 'health-anemia-med-prov-name',
  healthAnemiaMedProvAddress = 'health-anemia-med-prov-address',
  healthAnemiaMedProvPhone = 'health-anemia-med-prov-phone',
  healthAnemiaHospitalVisit = 'health-anemia-hospital-visit',
  healthAnemiaHospitalVisitDate = 'health-anemia-hospital-visit-date',
  healthAnemiaHospitalName = 'health-anemia-hospital-name',
  healthAnemiaHospitalAddress = 'health-anemia-hospital-address',
  healthAnemia2DiagnosisDate = 'health-anemia-2-diagnosis-date',
  healthAnemia2Medications = 'health-anemia-2-medications',
  healthAnemia2AdditionalTreatment = 'health-anemia-2-additional-treatment',
  healthAnemia2Status = 'health-anemia-2-status',
  healthAnemia2DateOfFullRecovery = 'health-anemia-2-date-of-full-recovery',
  healthAnemia2LimitationDetails = 'health-anemia-2-limitation-details',
  healthAnemia2CurrentlyExperienceSymptoms = 'health-anemia-2-currently-experience-symptoms',
  healthAnemia2DateOfLastSymptom = 'health-anemia-2-date-of-last-symptom',
  healthAnemia2DateOfLastOccurrence = 'health-anemia-2-date-of-last-occurrence',
  healthAnemia2NumberOfOccurrences = 'health-anemia-2-number-of-occurrences',
  healthAnemia2CausePhysicalLimitations = 'health-anemia-2-cause-physical-limitations',
  healthAnemia2LimitationDetails2 = 'health-anemia-2-limitation-details-2',
  healthAnemia2MedProvider = 'health-anemia-2-med-provider',
  healthAnemia2MedProviderName = 'health-anemia-2-med-provider-name',
  healthAnemia2MedProviderAddress = 'health-anemia-2-med-provider-address',
  healthAnemia2MedProviderPhone = 'health-anemia-2-med-provider-phone',
  healthAnemia2HospitalVisit = 'health-anemia-2-hospital-visit',
  healthAnemia2HospitalVisitDate = 'health-anemia-2-hospital-visit-date',
  healthAnemia2HospitalName = 'health-anemia-2-hospital-name',
  healthAnemia2HospitalAddress = 'health-anemia-2-hospital-address',
  healthRecurrentFeverDiagnosisDate = 'health-recurrent-fever-diagnosis-date',
  healthRecurrentFeverMedications = 'health-recurrent-fever-medications',
  healthRecurrentFeverAdditionalTreatment = 'health-recurrent-fever-additional-treatment',
  healthRecurrentFeverStatus = 'health-recurrent-fever-status',
  healthRecurrentFeverDateOfFullRecovery = 'health-recurrent-fever-date-of-full-recovery',
  healthRecurrentFeverLimitationDetails = 'health-recurrent-fever-limitation-details',
  healthRecurrentFeverCurrentlyExperienceSymptoms = 'health-recurrent-fever-currently-experience-symptoms',
  healthRecurrentFeverDateOfLastSymptom = 'health-recurrent-fever-date-of-last-symptom',
  healthRecurrentFeverDateOfLastOccurrence = 'health-recurrent-fever-date-of-last-occurrence',
  healthRecurrentFeverNumberOfOccurrences = 'health-recurrent-fever-number-of-occurrences',
  healthRecurrentFeverCausePhysicalLimitations = 'health-recurrent-fever-cause-physical-limitations',
  healthRecurrentFeverLimitationDetails2 = 'health-recurrent-fever-limitation-details-2',
  healthRecurrentFeverMedProv = 'health-recurrent-fever-med-prov',
  healthRecurrentFeverMedProvName = 'health-recurrent-fever-med-prov-name',
  healthRecurrentFeverMedProvAddress = 'health-recurrent-fever-med-prov-address',
  healthRecurrentFeverMedProvPhone = 'health-recurrent-fever-med-prov-phone',
  healthRecurrentFeverHospitalVisit = 'health-recurrent-fever-hospital-visit',
  healthRecurrentFeverHospitalVisitDate = 'health-recurrent-fever-hospital-visit-date',
  healthRecurrentFeverHospitalName = 'health-recurrent-fever-hospital-name',
  healthRecurrentFeverHospitalAddress = 'health-recurrent-fever-hospital-address',
  healthFatigueDiagnosisDate = 'health-fatigue-diagnosis-date',
  healthFatigueCause = 'health-fatigue-cause',
  healthFatiguePhysician = 'health-fatigue-physician',
  healthFatigueDateLastConsultation = 'health-fatigue-date-last-consultation',
  healthFatiguePhysicianVisitsFrequency = 'health-fatigue-physician-visits-frequency',
  healthFatigueTreatment = 'health-fatigue-treatment',
  healthFatigueTreatmentDetails = 'health-fatigue-treatment-details',
  healthFatiguePsychotherapy = 'health-fatigue-psychotherapy',
  healthFatiguePsychotherapyDetails = 'health-fatigue-psychotherapy-details',
  healthFatigueSymptoms = 'health-fatigue-symptoms',
  healthFatigueOffWork = 'health-fatigue-off-work',
  healthFatigueOffWorkDetails = 'health-fatigue-off-work-details',
  healthFatigueDetails = 'health-fatigue-details',
  healthFatigueMedications = 'health-fatigue-medications',
  healthFatigueAdditionalTreatment = 'health-fatigue-additional-treatment',
  healthFatigueStatus = 'health-fatigue-status',
  healthFatigueDateOfFullRecovery = 'health-fatigue-date-of-full-recovery',
  healthFatigueLimitationDetails = 'health-fatigue-limitation-details',
  healthFatigueCurrentlyExperienceSymptoms = 'health-fatigue-currently-experience-symptoms',
  healthFatigueDateOfLastSymptom = 'health-fatigue-date-of-last-symptom',
  healthFatigueDateOfLastOccurrence = 'health-fatigue-date-of-last-occurrence',
  healthFatigueNumberOfOccurrences = 'health-fatigue-number-of-occurrences',
  healthFatigueCausePhysicalLimitations = 'health-fatigue-cause-physical-limitations',
  healthFatigueLimitationDetails2 = 'health-fatigue-limitation-details-2',
  healthFatigueMedProv = 'health-fatigue-med-prov',
  healthFatigueMedProvName = 'health-fatigue-med-prov-name',
  healthFatigueMedProvAddress = 'health-fatigue-med-prov-address',
  healthFatigueMedProvPhone = 'health-fatigue-med-prov-phone',
  healthFatigueHospitalVisit = 'health-fatigue-hospital-visit',
  healthFatigueHospitalVisitDate = 'health-fatigue-hospital-visit-date',
  healthFatigueHospitalName = 'health-fatigue-hospital-name',
  healthFatigueHospitalAddress = 'health-fatigue-hospital-address',
  healthUnexplainedWeightLossDiagnosisDate = 'health-unexplained-weight-loss-diagnosis-date',
  healthUnexplainedWeightLossMedications = 'health-unexplained-weight-loss-medications',
  healthUnexplainedWeightLossAdditionalTreatment = 'health-unexplained-weight-loss-additional-treatment',
  healthUnexplainedWeightLossStatus = 'health-unexplained-weight-loss-status',
  healthUnexplainedWeightLossDateOfFullRecovery = 'health-unexplained-weight-loss-date-of-full-recovery',
  healthUnexplainedWeightLossLimitationDetails = 'health-unexplained-weight-loss-limitation-details',
  healthUnexplainedWeightLossCurrentlyExperienceSymptoms = 'health-unexplained-weight-loss-currently-experience-symptoms',
  healthUnexplainedWeightLossDateOfLastSymptom = 'health-unexplained-weight-loss-date-of-last-symptom',
  healthUnexplainedWeightLossDateOfLastOccurrence = 'health-unexplained-weight-loss-date-of-last-occurrence',
  healthUnexplainedWeightLossNumberOfOccurrences = 'health-unexplained-weight-loss-number-of-occurrences',
  healthUnexplainedWeightLossCausePhysicalLimitations = 'health-unexplained-weight-loss-cause-physical-limitations',
  healthUnexplainedWeightLossLimitationDetails2 = 'health-unexplained-weight-loss-limitation-details-2',
  healthUnexplainedWeightLossMedProv = 'health-unexplained-weight-loss-med-prov',
  healthUnexplainedWeightLossMedProvName = 'health-unexplained-weight-loss-med-prov-name',
  healthUnexplainedWeightLossMedProvAddress = 'health-unexplained-weight-loss-med-prov-address',
  healthUnexplainedWeightLossMedProvPhone = 'health-unexplained-weight-loss-med-prov-phone',
  healthUnexplainedWeightLossHospitalVisit = 'health-unexplained-weight-loss-hospital-visit',
  healthUnexplainedWeightLossHospitalVisitDate = 'health-unexplained-weight-loss-hospital-visit-date',
  healthUnexplainedWeightLossHospitalName = 'health-unexplained-weight-loss-hospital-name',
  healthUnexplainedWeightLossHospitalAddress = 'health-unexplained-weight-loss-hospital-address',
  healthMalaiseDiagnosisDate = 'health-malaise-diagnosis-date',
  healthMalaiseMedications = 'health-malaise-medications',
  healthMalaiseAdditionalTreatment = 'health-malaise-additional-treatment',
  healthMalaiseStatus = 'health-malaise-status',
  healthMalaiseDateOfFullRecovery = 'health-malaise-date-of-full-recovery',
  healthMalaiseLimitationDetails = 'health-malaise-limitation-details',
  healthMalaiseCurrentlyExperienceSymptoms = 'health-malaise-currently-experience-symptoms',
  healthMalaiseDateOfLastSymptom = 'health-malaise-date-of-last-symptom',
  healthMalaiseDateOfLastOccurrence = 'health-malaise-date-of-last-occurrence',
  healthMalaiseNumberOfOccurrences = 'health-malaise-number-of-occurrences',
  healthMalaiseCausePhysicalLimitations = 'health-malaise-cause-physical-limitations',
  healthMalaiseLimitationDetails2 = 'health-malaise-limitation-details-2',
  healthMalaiseMedProv = 'health-malaise-med-prov',
  healthMalaiseMedProvName = 'health-malaise-med-prov-name',
  healthMalaiseMedProvAddress = 'health-malaise-med-prov-address',
  healthMalaiseMedProvPhone = 'health-malaise-med-prov-phone',
  healthMalaiseHospitalVisit = 'health-malaise-hospital-visit',
  healthMalaiseHospitalVisitDate = 'health-malaise-hospital-visit-date',
  healthMalaiseHospitalName = 'health-malaise-hospital-name',
  healthMalaiseHospitalAddress = 'health-malaise-hospital-address',
  healthLossAppetiteDiagnosisDate = 'health-loss-appetite-diagnosis-date',
  healthLossAppetiteMedications = 'health-loss-appetite-medications',
  healthLossAppetiteAdditionalTreatment = 'health-loss-appetite-additional-treatment',
  healthLossAppetiteStatus = 'health-loss-appetite-status',
  healthLossAppetiteDateOfFullRecovery = 'health-loss-appetite-date-of-full-recovery',
  healthLossAppetiteLimitationDetails = 'health-loss-appetite-limitation-details',
  healthLossAppetiteCurrentlyExperienceSymptoms = 'health-loss-appetite-currently-experience-symptoms',
  healthLossAppetiteDateOfLastSymptom = 'health-loss-appetite-date-of-last-symptom',
  healthLossAppetiteDateOfLastOccurrence = 'health-loss-appetite-date-of-last-occurrence',
  healthLossAppetiteNumberOfOccurrences = 'health-loss-appetite-number-of-occurrences',
  healthLossAppetiteCausePhysicalLimitations = 'health-loss-appetite-cause-physical-limitations',
  healthLossAppetiteLimitationDetails2 = 'health-loss-appetite-limitation-details-2',
  healthLossAppetiteMedProv = 'health-loss-appetite-med-prov',
  healthLossAppetiteMedProvName = 'health-loss-appetite-med-prov-name',
  healthLossAppetiteMedProvAddress = 'health-loss-appetite-med-prov-address',
  healthLossAppetiteMedProvPhone = 'health-loss-appetite-med-prov-phone',
  healthLossAppetiteHospitalVisit = 'health-loss-appetite-hospital-visit',
  healthLossAppetiteHospitalVisitDate = 'health-loss-appetite-hospital-visit-date',
  healthLossAppetiteHospitalName = 'health-loss-appetite-hospital-name',
  healthLossAppetiteHospitalAddress = 'health-loss-appetite-hospital-address',
  healthDiarrheaDiagnosisDate = 'health-diarrhea-diagnosis-date',
  healthDiarrheaMedications = 'health-diarrhea-medications',
  healthDiarrheaAdditionalTreatment = 'health-diarrhea-additional-treatment',
  healthDiarrheaStatus = 'health-diarrhea-status',
  healthDiarrheaDateOfFullRecovery = 'health-diarrhea-date-of-full-recovery',
  healthDiarrheaLimitationDetails = 'health-diarrhea-limitation-details',
  healthDiarrheaCurrentlyExperienceSymptoms = 'health-diarrhea-currently-experience-symptoms',
  healthDiarrheaDateOfLastSymptom = 'health-diarrhea-date-of-last-symptom',
  healthDiarrheaDateOfLastOccurrence = 'health-diarrhea-date-of-last-occurrence',
  healthDiarrheaNumberOfOccurrences = 'health-diarrhea-number-of-occurrences',
  healthDiarrheaCausePhysicalLimitations = 'health-diarrhea-cause-physical-limitations',
  healthDiarrheaLimitationDetails2 = 'health-diarrhea-limitation-details-2',
  healthDiarrheaMedProv = 'health-diarrhea-med-prov',
  healthDiarrheaMedProvName = 'health-diarrhea-med-prov-name',
  healthDiarrheaMedProvAddress = 'health-diarrhea-med-prov-address',
  healthDiarrheaMedProvPhone = 'health-diarrhea-med-prov-phone',
  healthDiarrheaHospitalVisit = 'health-diarrhea-hospital-visit',
  healthDiarrheaHospitalVisitDate = 'health-diarrhea-hospital-visit-date',
  healthDiarrheaHospitalName = 'health-diarrhea-hospital-name',
  healthDiarrheaHospitalAddress = 'health-diarrhea-hospital-address',
  healthFeverUnknownOriginDiagnosisDate = 'health-fever-unknown-origin-diagnosis-date',
  healthFeverUnknownOriginMedications = 'health-fever-unknown-origin-medications',
  healthFeverUnknownOriginAdditionalTreatment = 'health-fever-unknown-origin-additional-treatment',
  healthFeverUnknownOriginStatus = 'health-fever-unknown-origin-status',
  healthFeverUnknownOriginDateOfFullRecovery = 'health-fever-unknown-origin-date-of-full-recovery',
  healthFeverUnknownOriginLimitationDetails = 'health-fever-unknown-origin-limitation-details',
  healthFeverUnknownOriginCurrentlyExperienceSymptoms = 'health-fever-unknown-origin-currently-experience-symptoms',
  healthFeverUnknownOriginDateOfLastSymptom = 'health-fever-unknown-origin-date-of-last-symptom',
  healthFeverUnknownOriginDateOfLastOccurrence = 'health-fever-unknown-origin-date-of-last-occurrence',
  healthFeverUnknownOriginNumberOfOccurrences = 'health-fever-unknown-origin-number-of-occurrences',
  healthFeverUnknownOriginCausePhysicalLimitations = 'health-fever-unknown-origin-cause-physical-limitations',
  healthFeverUnknownOriginLimitationDetails2 = 'health-fever-unknown-origin-limitation-details-2',
  healthFeverUnknownOriginMedProv = 'health-fever-unknown-origin-med-prov',
  healthFeverUnknownOriginMedProvName = 'health-fever-unknown-origin-med-prov-name',
  healthFeverUnknownOriginMedProvAddress = 'health-fever-unknown-origin-med-prov-address',
  healthFeverUnknownOriginMedProvPhone = 'health-fever-unknown-origin-med-prov-phone',
  healthFeverUnknownOriginHospitalVisit = 'health-fever-unknown-origin-hospital-visit',
  healthFeverUnknownOriginHospitalVisitDate = 'health-fever-unknown-origin-hospital-visit-date',
  healthFeverUnknownOriginHospitalName = 'health-fever-unknown-origin-hospital-name',
  healthFeverUnknownOriginHospitalAddress = 'health-fever-unknown-origin-hospital-address',
  healthSevereNightSweatsDiagnosisDate = 'health-severe-night-sweats-diagnosis-date',
  healthSevereNightSweatsMedications = 'health-severe-night-sweats-medications',
  healthSevereNightSweatsAdditionalTreatment = 'health-severe-night-sweats-additional-treatment',
  healthSevereNightSweatsStatus = 'health-severe-night-sweats-status',
  healthSevereNightSweatsDateOfFullRecovery = 'health-severe-night-sweats-date-of-full-recovery',
  healthSevereNightSweatsLimitationDetails = 'health-severe-night-sweats-limitation-details',
  healthSevereNightSweatsCurrentlyExperienceSymptoms = 'health-severe-night-sweats-currently-experience-symptoms',
  healthSevereNightSweatsDateOfLastSymptom = 'health-severe-night-sweats-date-of-last-symptom',
  healthSevereNightSweatsDateOfLastOccurrence = 'health-severe-night-sweats-date-of-last-occurrence',
  healthSevereNightSweatsNumberOfOccurrences = 'health-severe-night-sweats-number-of-occurrences',
  healthSevereNightSweatsCausePhysicalLimitations = 'health-severe-night-sweats-cause-physical-limitations',
  healthSevereNightSweatsLimitationDetails2 = 'health-severe-night-sweats-limitation-details-2',
  healthSevereNightSweatsMedProv = 'health-severe-night-sweats-med-prov',
  healthSevereNightSweatsMedProvName = 'health-severe-night-sweats-med-prov-name',
  healthSevereNightSweatsMedProvAddress = 'health-severe-night-sweats-med-prov-address',
  healthSevereNightSweatsMedProvPhone = 'health-severe-night-sweats-med-prov-phone',
  healthSevereNightSweatsHospitalVisit = 'health-severe-night-sweats-hospital-visit',
  healthSevereNightSweatsHospitalVisitDate = 'health-severe-night-sweats-hospital-visit-date',
  healthSevereNightSweatsHospitalName = 'health-severe-night-sweats-hospital-name',
  healthSevereNightSweatsHospitalAddress = 'health-severe-night-sweats-hospital-address',
  healthUnexplainedSkinLesionsDiagnosisDate = 'health-unexplained-skin-lesions-diagnosis-date',
  healthUnexplainedSkinLesionsMedications = 'health-unexplained-skin-lesions-medications',
  healthUnexplainedSkinLesionsAdditionalTreatment = 'health-unexplained-skin-lesions-additional-treatment',
  healthUnexplainedSkinLesionsStatus = 'health-unexplained-skin-lesions-status',
  healthUnexplainedSkinLesionsDateOfFullRecovery = 'health-unexplained-skin-lesions-date-of-full-recovery',
  healthUnexplainedSkinLesionsLimitationDetails = 'health-unexplained-skin-lesions-limitation-details',
  healthUnexplainedSkinLesionsCurrentlyExperienceSymptoms = 'health-unexplained-skin-lesions-currently-experience-symptoms',
  healthUnexplainedSkinLesionsDateOfLastSymptom = 'health-unexplained-skin-lesions-date-of-last-symptom',
  healthUnexplainedSkinLesionsDateOfLastOccurrence = 'health-unexplained-skin-lesions-date-of-last-occurrence',
  healthUnexplainedSkinLesionsNumberOfOccurrences = 'health-unexplained-skin-lesions-number-of-occurrences',
  healthUnexplainedSkinLesionsCausePhysicalLimitations = 'health-unexplained-skin-lesions-cause-physical-limitations',
  healthUnexplainedSkinLesionsLimitationDetails2 = 'health-unexplained-skin-lesions-limitation-details-2',
  healthUnexplainedSkinLesionsMedProv = 'health-unexplained-skin-lesions-med-prov',
  healthUnexplainedSkinLesionsMedProvName = 'health-unexplained-skin-lesions-med-prov-name',
  healthUnexplainedSkinLesionsMedProvAddress = 'health-unexplained-skin-lesions-med-prov-address',
  healthUnexplainedSkinLesionsMedProvPhone = 'health-unexplained-skin-lesions-med-prov-phone',
  healthUnexplainedSkinLesionsHospitalVisit = 'health-unexplained-skin-lesions-hospital-visit',
  healthUnexplainedSkinLesionsHospitalVisitDate = 'health-unexplained-skin-lesions-hospital-visit-date',
  healthUnexplainedSkinLesionsHospitalName = 'health-unexplained-skin-lesions-hospital-name',
  healthUnexplainedSkinLesionsHospitalAddress = 'health-unexplained-skin-lesions-hospital-address',
  healthUnexplainedSwellingLymphGlandDiagnosisDate = 'health-unexplained-swelling-lymph-gland-diagnosis-date',
  healthUnexplainedSwellingLymphGlandMedications = 'health-unexplained-swelling-lymph-gland-medications',
  healthUnexplainedSwellingLymphGlandAdditionalTreatment = 'health-unexplained-swelling-lymph-gland-additional-treatment',
  healthUnexplainedSwellingLymphGlandStatus = 'health-unexplained-swelling-lymph-gland-status',
  healthUnexplainedSwellingLymphGlandDateOfFullRecovery = 'health-unexplained-swelling-lymph-gland-date-of-full-recovery',
  healthUnexplainedSwellingLymphGlandLimitationDetails = 'health-unexplained-swelling-lymph-gland-limitation-details',
  healthUnexplainedSwellingLymphGlandCurrentlyExperienceSymptoms = 'health-unexplained-swelling-lymph-gland-currently-experience-symptoms',
  healthUnexplainedSwellingLymphGlandDateOfLastSymptom = 'health-unexplained-swelling-lymph-gland-date-of-last-symptom',
  healthUnexplainedSwellingLymphGlandDateOfLastOccurrence = 'health-unexplained-swelling-lymph-gland-date-of-last-occurrence',
  healthUnexplainedSwellingLymphGlandNumberOfOccurrences = 'health-unexplained-swelling-lymph-gland-number-of-occurrences',
  healthUnexplainedSwellingLymphGlandCausePhysicalLimitations = 'health-unexplained-swelling-lymph-gland-cause-physical-limitations',
  healthUnexplainedSwellingLymphGlandLimitationDetails2 = 'health-unexplained-swelling-lymph-gland-limitation-details-2',
  healthUnexplainedSwellingLymphGlandMedProv = 'health-unexplained-swelling-lymph-gland-med-prov',
  healthUnexplainedSwellingLymphGlandMedProvName = 'health-unexplained-swelling-lymph-gland-med-prov-name',
  healthUnexplainedSwellingLymphGlandMedProvAddress = 'health-unexplained-swelling-lymph-gland-med-prov-address',
  healthUnexplainedSwellingLymphGlandMedProvPhone = 'health-unexplained-swelling-lymph-gland-med-prov-phone',
  healthUnexplainedSwellingLymphGlandHospitalVisit = 'health-unexplained-swelling-lymph-gland-hospital-visit',
  healthUnexplainedSwellingLymphGlandHospitalVisitDate = 'health-unexplained-swelling-lymph-gland-hospital-visit-date',
  healthUnexplainedSwellingLymphGlandHospitalName = 'health-unexplained-swelling-lymph-gland-hospital-name',
  healthUnexplainedSwellingLymphGlandHospitalAddress = 'health-unexplained-swelling-lymph-gland-hospital-address',
  healthNoseDiseaseDiagnosisDate = 'health-nose-disease-diagnosis-date',
  healthNoseDiseaseMedications = 'health-nose-disease-medications',
  healthNoseDiseaseAdditionalTreatment = 'health-nose-disease-additional-treatment',
  healthNoseDiseaseStatus = 'health-nose-disease-status',
  healthNoseDiseaseDateOfFullRecovery = 'health-nose-disease-date-of-full-recovery',
  healthNoseDiseaseLimitationDetails = 'health-nose-disease-limitation-details',
  healthNoseDiseaseCurrentlyExperienceSymptoms = 'health-nose-disease-currently-experience-symptoms',
  healthNoseDiseaseDateOfLastSymptom = 'health-nose-disease-date-of-last-symptom',
  healthNoseDiseaseDateOfLastOccurrence = 'health-nose-disease-date-of-last-occurrence',
  healthNoseDiseaseNumberOfOccurrences = 'health-nose-disease-number-of-occurrences',
  healthNoseDiseaseCausePhysicalLimitations = 'health-nose-disease-cause-physical-limitations',
  healthNoseDiseaseLimitationDetails2 = 'health-nose-disease-limitation-details-2',
  healthNoseDiseaseMedProv = 'health-nose-disease-med-prov',
  healthNoseDiseaseMedProvName = 'health-nose-disease-med-prov-name',
  healthNoseDiseaseMedProvAddress = 'health-nose-disease-med-prov-address',
  healthNoseDiseaseMedProvPhone = 'health-nose-disease-med-prov-phone',
  healthNoseDiseaseHospitalVisit = 'health-nose-disease-hospital-visit',
  healthNoseDiseaseHospitalVisitDate = 'health-nose-disease-hospital-visit-date',
  healthNoseDiseaseHospitalName = 'health-nose-disease-hospital-name',
  healthNoseDiseaseHospitalAddress = 'health-nose-disease-hospital-address',
  healthThroatDiseaseDiagnosisDate = 'health-throat-disease-diagnosis-date',
  healthThroatDiseaseMedications = 'health-throat-disease-medications',
  healthThroatDiseaseAdditionalTreatment = 'health-throat-disease-additional-treatment',
  healthThroatDiseaseStatus = 'health-throat-disease-status',
  healthThroatDiseaseDateOfFullRecovery = 'health-throat-disease-date-of-full-recovery',
  healthThroatDiseaseLimitationDetails = 'health-throat-disease-limitation-details',
  healthThroatDiseaseCurrentlyExperienceSymptoms = 'health-throat-disease-currently-experience-symptoms',
  healthThroatDiseaseDateOfLastSymptom = 'health-throat-disease-date-of-last-symptom',
  healthThroatDiseaseDateOfLastOccurrence = 'health-throat-disease-date-of-last-occurrence',
  healthThroatDiseaseNumberOfOccurrences = 'health-throat-disease-number-of-occurrences',
  healthThroatDiseaseCausePhysicalLimitations = 'health-throat-disease-cause-physical-limitations',
  healthThroatDiseaseLimitationDetails2 = 'health-throat-disease-limitation-details-2',
  healthThroatDiseaseMedProv = 'health-throat-disease-med-prov',
  healthThroatDiseaseMedProvName = 'health-throat-disease-med-prov-name',
  healthThroatDiseaseMedProvAddress = 'health-throat-disease-med-prov-address',
  healthThroatDiseaseMedProvPhone = 'health-throat-disease-med-prov-phone',
  healthThroatDiseaseHospitalVisit = 'health-throat-disease-hospital-visit',
  healthThroatDiseaseHospitalVisitDate = 'health-throat-disease-hospital-visit-date',
  healthThroatDiseaseHospitalName = 'health-throat-disease-hospital-name',
  healthThroatDiseaseHospitalAddress = 'health-throat-disease-hospital-address',
  healthLungDiseaseDiagnosisDate = 'health-lung-disease-diagnosis-date',
  healthLungDiseaseMedications = 'health-lung-disease-medications',
  healthLungDiseaseAdditionalTreatment = 'health-lung-disease-additional-treatment',
  healthLungDiseaseStatus = 'health-lung-disease-status',
  healthLungDiseaseDateOfFullRecovery = 'health-lung-disease-date-of-full-recovery',
  healthLungDiseaseLimitationDetails = 'health-lung-disease-limitation-details',
  healthLungDiseaseCurrentlyExperienceSymptoms = 'health-lung-disease-currently-experience-symptoms',
  healthLungDiseaseDateOfLastSymptom = 'health-lung-disease-date-of-last-symptom',
  healthLungDiseaseDateOfLastOccurrence = 'health-lung-disease-date-of-last-occurrence',
  healthLungDiseaseNumberOfOccurrences = 'health-lung-disease-number-of-occurrences',
  healthLungDiseaseCausePhysicalLimitations = 'health-lung-disease-cause-physical-limitations',
  healthLungDiseaseLimitationDetails2 = 'health-lung-disease-limitation-details-2',
  healthLungDiseaseMedProv = 'health-lung-disease-med-prov',
  healthLungDiseaseMedProvName = 'health-lung-disease-med-prov-name',
  healthLungDiseaseMedProvAddress = 'health-lung-disease-med-prov-address',
  healthLungDiseaseMedProvPhone = 'health-lung-disease-med-prov-phone',
  healthLungDiseaseHospitalVisit = 'health-lung-disease-hospital-visit',
  healthLungDiseaseHospitalVisitDate = 'health-lung-disease-hospital-visit-date',
  healthLungDiseaseHospitalName = 'health-lung-disease-hospital-name',
  healthLungDiseaseHospitalAddress = 'health-lung-disease-hospital-address',
  healthTuberculosisDiagnosisDate = 'health-tuberculosis-diagnosis-date',
  healthTuberculosisMedications = 'health-tuberculosis-medications',
  healthTuberculosisAdditionalTreatment = 'health-tuberculosis-additional-treatment',
  healthTuberculosisStatus = 'health-tuberculosis-status',
  healthTuberculosisDateOfFullRecovery = 'health-tuberculosis-date-of-full-recovery',
  healthTuberculosisLimitationDetails = 'health-tuberculosis-limitation-details',
  healthTuberculosisCurrentlyExperienceSymptoms = 'health-tuberculosis-currently-experience-symptoms',
  healthTuberculosisDateOfLastSymptom = 'health-tuberculosis-date-of-last-symptom',
  healthTuberculosisDateOfLastOccurrence = 'health-tuberculosis-date-of-last-occurrence',
  healthTuberculosisNumberOfOccurrences = 'health-tuberculosis-number-of-occurrences',
  healthTuberculosisCausePhysicalLimitations = 'health-tuberculosis-cause-physical-limitations',
  healthTuberculosisLimitationDetails2 = 'health-tuberculosis-limitation-details-2',
  healthTuberculosisMedProv = 'health-tuberculosis-med-prov',
  healthTuberculosisMedProvName = 'health-tuberculosis-med-prov-name',
  healthTuberculosisMedProvAddress = 'health-tuberculosis-med-prov-address',
  healthTuberculosisMedProvPhone = 'health-tuberculosis-med-prov-phone',
  healthTuberculosisHospitalVisit = 'health-tuberculosis-hospital-visit',
  healthTuberculosisHospitalVisitDate = 'health-tuberculosis-hospital-visit-date',
  healthTuberculosisHospitalName = 'health-tuberculosis-hospital-name',
  healthTuberculosisHospitalAddress = 'health-tuberculosis-hospital-address',
  healthSleepApneaDiagnosisDate = 'health-sleep-apnea-diagnosis-date',
  healthSleepApneaSleepStudy = 'health-sleep-apnea-sleep-study',
  healthSleepApneaSleepStudyDate = 'health-sleep-apnea-sleep-study-date',
  healthSleepApneaSleepStudyResults = 'health-sleep-apnea-sleep-study-results',
  healthSleepApneaIndex = 'health-sleep-apnea-index',
  healthSleepApneaTreatment = 'health-sleep-apnea-treatment',
  healthSleepApneaCPAPOften = 'health-sleep-apnea-cpap-often',
  healthSleepApneaTreatmentOther = 'health-sleep-apnea-treatment-other',
  healthSleepApneaComplaint = 'health-sleep-apnea-complaint',
  healthSleepApneaSymptoms = 'health-sleep-apnea-symptoms',
  healthSleepApneaIssues = 'health-sleep-apnea-issues',
  healthSleepApneaName = 'health-sleep-apnea-name',
  healthSleepApneaAddress = 'health-sleep-apnea-address',
  healthSleepApneaPhone = 'health-sleep-apnea-phone',

  healthOtherRespiratoryDiseaseDiagnosisDate = 'health-other-respiratory-disease-diagnosis-date',
  healthOtherRespiratoryDiseaseMedications = 'health-other-respiratory-disease-medications',
  healthOtherRespiratoryDiseaseAdditionalTreatment = 'health-other-respiratory-disease-additional-treatment',
  healthOtherRespiratoryDiseaseStatus = 'health-other-respiratory-disease-status',
  healthOtherRespiratoryDiseaseDateOfFullRecovery = 'health-other-respiratory-disease-date-of-full-recovery',
  healthOtherRespiratoryDiseaseLimitationDetails = 'health-other-respiratory-disease-limitation-details',
  healthOtherRespiratoryDiseaseCurrentlyExperienceSymptoms = 'health-other-respiratory-disease-currently-experience-symptoms',
  healthOtherRespiratoryDiseaseDateOfLastSymptom = 'health-other-respiratory-disease-date-of-last-symptom',
  healthOtherRespiratoryDiseaseDateOfLastOccurrence = 'health-other-respiratory-disease-date-of-last-occurrence',
  healthOtherRespiratoryDiseaseNumberOfOccurrences = 'health-other-respiratory-disease-number-of-occurrences',
  healthOtherRespiratoryDiseaseCausePhysicalLimitations = 'health-other-respiratory-disease-cause-physical-limitations',
  healthOtherRespiratoryDiseaseLimitationDetails2 = 'health-other-respiratory-disease-limitation-details-2',
  healthOtherRespiratoryDiseaseMedProv = 'health-other-respiratory-disease-med-prov',
  healthOtherRespiratoryDiseaseMedProvName = 'health-other-respiratory-disease-med-prov-name',
  healthOtherRespiratoryDiseaseMedProvAddress = 'health-other-respiratory-disease-med-prov-address',
  healthOtherRespiratoryDiseaseMedProvPhone = 'health-other-respiratory-disease-med-prov-phone',
  healthOtherRespiratoryDiseaseHospitalVisit = 'health-other-respiratory-disease-hospital-visit',
  healthOtherRespiratoryDiseaseHospitalVisitDate = 'health-other-respiratory-disease-hospital-visit-date',
  healthOtherRespiratoryDiseaseHospitalName = 'health-other-respiratory-disease-hospital-name',
  healthOtherRespiratoryDiseaseHospitalAddress = 'health-other-respiratory-disease-hospital-address',
  healthIntestinesDisorderDiagnosisDate = 'health-intestines-disorder-diagnosis-date',
  healthIntestinesDisorderMedications = 'health-intestines-disorder-medications',
  healthIntestinesDisorderAdditionalTreatment = 'health-intestines-disorder-additional-treatment',
  healthIntestinesDisorderStatus = 'health-intestines-disorder-status',
  healthIntestinesDisorderDateOfFullRecovery = 'health-intestines-disorder-date-of-full-recovery',
  healthIntestinesDisorderLimitationDetails = 'health-intestines-disorder-limitation-details',
  healthIntestinesDisorderCurrentlyExperienceSymptoms = 'health-intestines-disorder-currently-experience-symptoms',
  healthIntestinesDisorderDateOfLastSymptom = 'health-intestines-disorder-date-of-last-symptom',
  healthIntestinesDisorderDateOfLastOccurrence = 'health-intestines-disorder-date-of-last-occurrence',
  healthIntestinesDisorderNumberOfOccurrences = 'health-intestines-disorder-number-of-occurrences',
  healthIntestinesDisorderCausePhysicalLimitations = 'health-intestines-disorder-cause-physical-limitations',
  healthIntestinesDisorderLimitationDetails2 = 'health-intestines-disorder-limitation-details-2',
  healthIntestinesDisorderMedProv = 'health-intestines-disorder-med-prov',
  healthIntestinesDisorderMedProvName = 'health-intestines-disorder-med-prov-name',
  healthIntestinesDisorderMedProvAddress = 'health-intestines-disorder-med-prov-address',
  healthIntestinesDisorderMedProvPhone = 'health-intestines-disorder-med-prov-phone',
  healthIntestinesDisorderHospitalVisit = 'health-intestines-disorder-hospital-visit',
  healthIntestinesDisorderHospitalVisitDate = 'health-intestines-disorder-hospital-visit-date',
  healthIntestinesDisorderHospitalName = 'health-intestines-disorder-hospital-name',
  healthIntestinesDisorderHospitalAddress = 'health-intestines-disorder-hospital-address',
  healthRectumDisorderDiagnosisDate = 'health-rectum-disorder-diagnosis-date',
  healthRectumDisorderMedications = 'health-rectum-disorder-medications',
  healthRectumDisorderAdditionalTreatment = 'health-rectum-disorder-additional-treatment',
  healthRectumDisorderStatus = 'health-rectum-disorder-status',
  healthRectumDisorderDateOfFullRecovery = 'health-rectum-disorder-date-of-full-recovery',
  healthRectumDisorderLimitationDetails = 'health-rectum-disorder-limitation-details',
  healthRectumDisorderCurrentlyExperienceSymptoms = 'health-rectum-disorder-currently-experience-symptoms',
  healthRectumDisorderDateOfLastSymptom = 'health-rectum-disorder-date-of-last-symptom',
  healthRectumDisorderDateOfLastOccurrence = 'health-rectum-disorder-date-of-last-occurrence',
  healthRectumDisorderNumberOfOccurrences = 'health-rectum-disorder-number-of-occurrences',
  healthRectumDisorderCausePhysicalLimitations = 'health-rectum-disorder-cause-physical-limitations',
  healthRectumDisorderLimitationDetails2 = 'health-rectum-disorder-limitation-details-2',
  healthRectumDisorderMedProv = 'health-rectum-disorder-med-prov',
  healthRectumDisorderMedProvName = 'health-rectum-disorder-med-prov-name',
  healthRectumDisorderMedProvAddress = 'health-rectum-disorder-med-prov-address',
  healthRectumDisorderMedProvPhone = 'health-rectum-disorder-med-prov-phone',
  healthRectumDisorderHospitalVisit = 'health-rectum-disorder-hospital-visit',
  healthRectumDisorderHospitalVisitDate = 'health-rectum-disorder-hospital-visit-date',
  healthRectumDisorderHospitalName = 'health-rectum-disorder-hospital-name',
  healthRectumDisorderHospitalAddress = 'health-rectum-disorder-hospital-address',
  healthLiverDisorderDiagnosisDate = 'health-liver-disorder-diagnosis-date',
  healthLiverDisorderMedications = 'health-liver-disorder-medications',
  healthLiverDisorderAdditionalTreatment = 'health-liver-disorder-additional-treatment',
  healthLiverDisorderStatus = 'health-liver-disorder-status',
  healthLiverDisorderDateOfFullRecovery = 'health-liver-disorder-date-of-full-recovery',
  healthLiverDisorderLimitationDetails = 'health-liver-disorder-limitation-details',
  healthLiverDisorderCurrentlyExperienceSymptoms = 'health-liver-disorder-currently-experience-symptoms',
  healthLiverDisorderDateOfLastSymptom = 'health-liver-disorder-date-of-last-symptom',
  healthLiverDisorderDateOfLastOccurrence = 'health-liver-disorder-date-of-last-occurrence',
  healthLiverDisorderNumberOfOccurrences = 'health-liver-disorder-number-of-occurrences',
  healthLiverDisorderCausePhysicalLimitations = 'health-liver-disorder-cause-physical-limitations',
  healthLiverDisorderLimitationDetails2 = 'health-liver-disorder-limitation-details-2',
  healthLiverDisorderMedProv = 'health-liver-disorder-med-prov',
  healthLiverDisorderMedProvName = 'health-liver-disorder-med-prov-name',
  healthLiverDisorderMedProvAddress = 'health-liver-disorder-med-prov-address',
  healthLiverDisorderMedProvPhone = 'health-liver-disorder-med-prov-phone',
  healthLiverDisorderHospitalVisit = 'health-liver-disorder-hospital-visit',
  healthLiverDisorderHospitalVisitDate = 'health-liver-disorder-hospital-visit-date',
  healthLiverDisorderHospitalName = 'health-liver-disorder-hospital-name',
  healthLiverDisorderHospitalAddress = 'health-liver-disorder-hospital-address',
  healthPancreasDisorderDiagnosisDate = 'health-pancreas-disorder-diagnosis-date',
  healthPancreasDisorderMedications = 'health-pancreas-disorder-medications',
  healthPancreasDisorderAdditionalTreatment = 'health-pancreas-disorder-additional-treatment',
  healthPancreasDisorderStatus = 'health-pancreas-disorder-status',
  healthPancreasDisorderDateOfFullRecovery = 'health-pancreas-disorder-date-of-full-recovery',
  healthPancreasDisorderLimitationDetails = 'health-pancreas-disorder-limitation-details',
  healthPancreasDisorderCurrentlyExperienceSymptoms = 'health-pancreas-disorder-currently-experience-symptoms',
  healthPancreasDisorderDateOfLastSymptom = 'health-pancreas-disorder-date-of-last-symptom',
  healthPancreasDisorderDateOfLastOccurrence = 'health-pancreas-disorder-date-of-last-occurrence',
  healthPancreasDisorderNumberOfOccurrences = 'health-pancreas-disorder-number-of-occurrences',
  healthPancreasDisorderCausePhysicalLimitations = 'health-pancreas-disorder-cause-physical-limitations',
  healthPancreasDisorderLimitationDetails2 = 'health-pancreas-disorder-limitation-details-2',
  healthPancreasDisorderMedProv = 'health-pancreas-disorder-med-prov',
  healthPancreasDisorderMedProvName = 'health-pancreas-disorder-med-prov-name',
  healthPancreasDisorderMedProvAddress = 'health-pancreas-disorder-med-prov-address',
  healthPancreasDisorderMedProvPhone = 'health-pancreas-disorder-med-prov-phone',
  healthPancreasDisorderHospitalVisit = 'health-pancreas-disorder-hospital-visit',
  healthPancreasDisorderHospitalVisitDate = 'health-pancreas-disorder-hospital-visit-date',
  healthPancreasDisorderHospitalName = 'health-pancreas-disorder-hospital-name',
  healthPancreasDisorderHospitalAddress = 'health-pancreas-disorder-hospital-address',
  healthKidneyDisorderDiagnosisDate = 'health-kidney-disorder-diagnosis-date',
  healthKidneyDisorderMedications = 'health-kidney-disorder-medications',
  healthKidneyDisorderAdditionalTreatment = 'health-kidney-disorder-additional-treatment',
  healthKidneyDisorderStatus = 'health-kidney-disorder-status',
  healthInsuredUrineAbnormalitiesDetails = 'health-insured-urine-abnormalities-details',
  healthInsuredUrinaryTractDetails = 'health-insured-urinary-tract-details',
  healthKidneyDisorderDateOfFullRecovery = 'health-kidney-disorder-date-of-full-recovery',
  healthKidneyDisorderLimitationDetails = 'health-kidney-disorder-limitation-details',
  healthKidneyDisorderCurrentlyExperienceSymptoms = 'health-kidney-disorder-currently-experience-symptoms',
  healthKidneyDisorderDateOfLastSymptom = 'health-kidney-disorder-date-of-last-symptom',
  healthKidneyDisorderDateOfLastOccurrence = 'health-kidney-disorder-date-of-last-occurrence',
  healthKidneyDisorderNumberOfOccurrences = 'health-kidney-disorder-number-of-occurrences',
  healthKidneyDisorderCausePhysicalLimitations = 'health-kidney-disorder-cause-physical-limitations',
  healthKidneyDisorderLimitationDetails2 = 'health-kidney-disorder-limitation-details-2',
  healthKidneyDisorderMedProv = 'health-kidney-disorder-med-prov',
  healthKidneyDisorderMedProvName = 'health-kidney-disorder-med-prov-name',
  healthKidneyDisorderMedProvAddress = 'health-kidney-disorder-med-prov-address',
  healthKidneyDisorderMedProvPhone = 'health-kidney-disorder-med-prov-phone',
  healthKidneyDisorderHospitalVisit = 'health-kidney-disorder-hospital-visit',
  healthKidneyDisorderHospitalVisitDate = 'health-kidney-disorder-hospital-visit-date',
  healthKidneyDisorderHospitalName = 'health-kidney-disorder-hospital-name',
  healthKidneyDisorderHospitalAddress = 'health-kidney-disorder-hospital-address',
  healthBladderDisorderDiagnosisDate = 'health-bladder-disorder-diagnosis-date',
  healthBladderDisorderMedications = 'health-bladder-disorder-medications',
  healthBladderDisorderAdditionalTreatment = 'health-bladder-disorder-additional-treatment',
  healthBladderDisorderStatus = 'health-bladder-disorder-status',
  healthBladderDisorderDateOfFullRecovery = 'health-bladder-disorder-date-of-full-recovery',
  healthBladderDisorderLimitationDetails = 'health-bladder-disorder-limitation-details',
  healthBladderDisorderCurrentlyExperienceSymptoms = 'health-bladder-disorder-currently-experience-symptoms',
  healthBladderDisorderDateOfLastSymptom = 'health-bladder-disorder-date-of-last-symptom',
  healthBladderDisorderDateOfLastOccurrence = 'health-bladder-disorder-date-of-last-occurrence',
  healthBladderDisorderNumberOfOccurrences = 'health-bladder-disorder-number-of-occurrences',
  healthBladderDisorderCausePhysicalLimitations = 'health-bladder-disorder-cause-physical-limitations',
  healthBladderDisorderLimitationDetails2 = 'health-bladder-disorder-limitation-details-2',
  healthBladderDisorderMedProv = 'health-bladder-disorder-med-prov',
  healthBladderDisorderMedProvName = 'health-bladder-disorder-med-prov-name',
  healthBladderDisorderMedProvAddress = 'health-bladder-disorder-med-prov-address',
  healthBladderDisorderMedProvPhone = 'health-bladder-disorder-med-prov-phone',
  healthBladderDisorderHospitalVisit = 'health-bladder-disorder-hospital-visit',
  healthBladderDisorderHospitalVisitDate = 'health-bladder-disorder-hospital-visit-date',
  healthBladderDisorderHospitalName = 'health-bladder-disorder-hospital-name',
  healthBladderDisorderHospitalAddress = 'health-bladder-disorder-hospital-address',
  healthUlcersDiagnosisDate = 'health-ulcers-diagnosis-date',
  healthUlcersMedications = 'health-ulcers-medications',
  healthUlcersAdditionalTreatment = 'health-ulcers-additional-treatment',
  healthUlcersStatus = 'health-ulcers-status',
  healthUlcersDateOfFullRecovery = 'health-ulcers-date-of-full-recovery',
  healthUlcersLimitationDetails = 'health-ulcers-limitation-details',
  healthUlcersCurrentlyExperienceSymptoms = 'health-ulcers-currently-experience-symptoms',
  healthUlcersDateOfLastSymptom = 'health-ulcers-date-of-last-symptom',
  healthUlcersDateOfLastOccurrence = 'health-ulcers-date-of-last-occurrence',
  healthUlcersNumberOfOccurrences = 'health-ulcers-number-of-occurrences',
  healthUlcersCausePhysicalLimitations = 'health-ulcers-cause-physical-limitations',
  healthUlcersLimitationDetails2 = 'health-ulcers-limitation-details-2',
  healthUlcersMedProv = 'health-ulcers-med-prov',
  healthUlcersMedProvName = 'health-ulcers-med-prov-name',
  healthUlcersMedProvAddress = 'health-ulcers-med-prov-address',
  healthUlcersMedProvPhone = 'health-ulcers-med-prov-phone',
  healthUlcersHospitalVisit = 'health-ulcers-hospital-visit',
  healthUlcersHospitalVisitDate = 'health-ulcers-hospital-visit-date',
  healthUlcersHospitalName = 'health-ulcers-hospital-name',
  healthUlcersHospitalAddress = 'health-ulcers-hospital-address',
  healthColitisDiagnosisDate = 'health-colitis-diagnosis-date',
  healthColitisMedications = 'health-colitis-medications',
  healthColitisAdditionalTreatment = 'health-colitis-additional-treatment',
  healthColitisStatus = 'health-colitis-status',
  healthColitisDateOfFullRecovery = 'health-colitis-date-of-full-recovery',
  healthColitisLimitationDetails = 'health-colitis-limitation-details',
  healthColitisCurrentlyExperienceSymptoms = 'health-colitis-currently-experience-symptoms',
  healthColitisDateOfLastSymptom = 'health-colitis-date-of-last-symptom',
  healthColitisDateOfLastOccurrence = 'health-colitis-date-of-last-occurrence',
  healthColitisNumberOfOccurrences = 'health-colitis-number-of-occurrences',
  healthColitisCausePhysicalLimitations = 'health-colitis-cause-physical-limitations',
  healthColitisLimitationDetails2 = 'health-colitis-limitation-details-2',
  healthColitisMedProv = 'health-colitis-med-prov',
  healthColitisMedProvName = 'health-colitis-med-prov-name',
  healthColitisMedProvAddress = 'health-colitis-med-prov-address',
  healthColitisMedProvPhone = 'health-colitis-med-prov-phone',
  healthColitisHospitalVisit = 'health-colitis-hospital-visit',
  healthColitisHospitalVisitDate = 'health-colitis-hospital-visit-date',
  healthColitisHospitalName = 'health-colitis-hospital-name',
  healthColitisHospitalAddress = 'health-colitis-hospital-address',
  healthCrohnDiseaseDiagnosisDate = 'health-crohn-disease-diagnosis-date',
  healthCrohnDiseaseMedications = 'health-crohn-disease-medications',
  healthCrohnDiseaseAdditionalTreatment = 'health-crohn-disease-additional-treatment',
  healthCrohnDiseaseStatus = 'health-crohn-disease-status',
  healthCrohnDiseaseDateOfFullRecovery = 'health-crohn-disease-date-of-full-recovery',
  healthCrohnDiseaseLimitationDetails = 'health-crohn-disease-limitation-details',
  healthCrohnDiseaseCurrentlyExperienceSymptoms = 'health-crohn-disease-currently-experience-symptoms',
  healthCrohnDiseaseDateOfLastSymptom = 'health-crohn-disease-date-of-last-symptom',
  healthCrohnDiseaseDateOfLastOccurrence = 'health-crohn-disease-date-of-last-occurrence',
  healthCrohnDiseaseNumberOfOccurrences = 'health-crohn-disease-number-of-occurrences',
  healthCrohnDiseaseCausePhysicalLimitations = 'health-crohn-disease-cause-physical-limitations',
  healthCrohnDiseaseLimitationDetails2 = 'health-crohn-disease-limitation-details-2',
  healthCrohnDiseaseMedProv = 'health-crohn-disease-med-prov',
  healthCrohnDiseaseMedProvName = 'health-crohn-disease-med-prov-name',
  healthCrohnDiseaseMedProvAddress = 'health-crohn-disease-med-prov-address',
  healthCrohnDiseaseMedProvPhone = 'health-crohn-disease-med-prov-phone',
  healthCrohnDiseaseHospitalVisit = 'health-crohn-disease-hospital-visit',
  healthCrohnDiseaseHospitalVisitDate = 'health-crohn-disease-hospital-visit-date',
  healthCrohnDiseaseHospitalName = 'health-crohn-disease-hospital-name',
  healthCrohnDiseaseHospitalAddress = 'health-crohn-disease-hospital-address',
  healthCeliacDiseaseDiagnosisDate = 'health-celiac-disease-diagnosis-date',
  healthCeliacDiseaseMedications = 'health-celiac-disease-medications',
  healthCeliacDiseaseAdditionalTreatment = 'health-celiac-disease-additional-treatment',
  healthCeliacDiseaseStatus = 'health-celiac-disease-status',
  healthCeliacDiseaseDateOfFullRecovery = 'health-celiac-disease-date-of-full-recovery',
  healthCeliacDiseaseLimitationDetails = 'health-celiac-disease-limitation-details',
  healthCeliacDiseaseCurrentlyExperienceSymptoms = 'health-celiac-disease-currently-experience-symptoms',
  healthCeliacDiseaseDateOfLastSymptom = 'health-celiac-disease-date-of-last-symptom',
  healthCeliacDiseaseDateOfLastOccurrence = 'health-celiac-disease-date-of-last-occurrence',
  healthCeliacDiseaseNumberOfOccurrences = 'health-celiac-disease-number-of-occurrences',
  healthCeliacDiseaseCausePhysicalLimitations = 'health-celiac-disease-cause-physical-limitations',
  healthCeliacDiseaseLimitationDetails2 = 'health-celiac-disease-limitation-details-2',
  healthCeliacDiseaseMedProv = 'health-celiac-disease-med-prov',
  healthCeliacDiseaseMedProvName = 'health-celiac-disease-med-prov-name',
  healthCeliacDiseaseMedProvAddress = 'health-celiac-disease-med-prov-address',
  healthCeliacDiseaseMedProvPhone = 'health-celiac-disease-med-prov-phone',
  healthCeliacDiseaseHospitalVisit = 'health-celiac-disease-hospital-visit',
  healthCeliacDiseaseHospitalVisitDate = 'health-celiac-disease-hospital-visit-date',
  healthCeliacDiseaseHospitalName = 'health-celiac-disease-hospital-name',
  healthCeliacDiseaseHospitalAddress = 'health-celiac-disease-hospital-address',
  healthDiverticulitisDiagnosisDate = 'health-diverticulitis-diagnosis-date',
  healthDiverticulitisMedications = 'health-diverticulitis-medications',
  healthDiverticulitisAdditionalTreatment = 'health-diverticulitis-additional-treatment',
  healthDiverticulitisStatus = 'health-diverticulitis-status',
  healthDiverticulitisDateOfFullRecovery = 'health-diverticulitis-date-of-full-recovery',
  healthDiverticulitisLimitationDetails = 'health-diverticulitis-limitation-details',
  healthDiverticulitisCurrentlyExperienceSymptoms = 'health-diverticulitis-currently-experience-symptoms',
  healthDiverticulitisDateOfLastSymptom = 'health-diverticulitis-date-of-last-symptom',
  healthDiverticulitisDateOfLastOccurrence = 'health-diverticulitis-date-of-last-occurrence',
  healthDiverticulitisNumberOfOccurrences = 'health-diverticulitis-number-of-occurrences',
  healthDiverticulitisCausePhysicalLimitations = 'health-diverticulitis-cause-physical-limitations',
  healthDiverticulitisLimitationDetails2 = 'health-diverticulitis-limitation-details-2',
  healthDiverticulitisMedProv = 'health-diverticulitis-med-prov',
  healthDiverticulitisMedProvName = 'health-diverticulitis-med-prov-name',
  healthDiverticulitisMedProvAddress = 'health-diverticulitis-med-prov-address',
  healthDiverticulitisMedProvPhone = 'health-diverticulitis-med-prov-phone',
  healthDiverticulitisHospitalVisit = 'health-diverticulitis-hospital-visit',
  healthDiverticulitisHospitalVisitDate = 'health-diverticulitis-hospital-visit-date',
  healthDiverticulitisHospitalName = 'health-diverticulitis-hospital-name',
  healthDiverticulitisHospitalAddress = 'health-diverticulitis-hospital-address',
  healthHasReproductiveOrgansDisorder = 'health-has-reproductive-organs-disorder',
  healthReproductiveOrgansDisorderChoices = 'health-reproductive-organs-disorder-choices',
  healthProstateDisorderDiagnosisDate = 'health-prostate-disorder-diagnosis-date',
  healthProstateDisorderMedications = 'health-prostate-disorder-medications',
  healthProstateDisorderAdditionalTreatment = 'health-prostate-disorder-additional-treatment',
  healthProstateDisorderStatus = 'health-prostate-disorder-status',
  healthProstateDisorderDateOfFullRecovery = 'health-prostate-disorder-date-of-full-recovery',
  healthProstateDisorderLimitationDetails = 'health-prostate-disorder-limitation-details',
  healthProstateDisorderCurrentlyExperienceSymptoms = 'health-prostate-disorder-currently-experience-symptoms',
  healthProstateDisorderDateOfLastSymptom = 'health-prostate-disorder-date-of-last-symptom',
  healthProstateDisorderDateOfLastOccurrence = 'health-prostate-disorder-date-of-last-occurrence',
  healthProstateDisorderNumberOfOccurrences = 'health-prostate-disorder-number-of-occurrences',
  healthProstateDisorderCausePhysicalLimitations = 'health-prostate-disorder-cause-physical-limitations',
  healthProstateDisorderLimitationDetails2 = 'health-prostate-disorder-limitation-details-2',
  healthProstateDisorderMedProvider = 'health-prostate-disorder-med-provider',
  healthProstateDisorderMedProviderName = 'health-prostate-disorder-med-provider-name',
  healthProstateDisorderMedProviderAddress = 'health-prostate-disorder-med-provider-address',
  healthProstateDisorderMedProviderPhone = 'health-prostate-disorder-med-provider-phone',
  healthProstateDisorderHospitalVisit = 'health-prostate-disorder-hospital-visit',
  healthProstateDisorderHospitalVisitDate = 'health-prostate-disorder-hospital-visit-date',
  healthProstateDisorderHospitalName = 'health-prostate-disorder-hospital-name',
  healthProstateDisorderHospitalAddress = 'health-prostate-disorder-hospital-address',
  healthReproductiveOrgansDisorderDiagnosisDate = 'health-reproductive-organs-disorder-diagnosis-date',
  healthReproductiveOrgansDisorderMedications = 'health-reproductive-organs-disorder-medications',
  healthReproductiveOrgansDisorderAdditionalTreatment = 'health-reproductive-organs-disorder-additional-treatment',
  healthReproductiveOrgansDisorderStatus = 'health-reproductive-organs-disorder-status',
  healthReproductiveOrgansDisorderDateOfFullRecovery = 'health-reproductive-organs-disorder-date-of-full-recovery',
  healthReproductiveOrgansDisorderLimitationDetails = 'health-reproductive-organs-disorder-limitation-details',
  healthReproductiveOrgansDisorderCurrentlyExperienceSymptoms = 'health-reproductive-organs-disorder-currently-experience-symptoms',
  healthReproductiveOrgansDisorderDateOfLastSymptom = 'health-reproductive-organs-disorder-date-of-last-symptom',
  healthReproductiveOrgansDisorderDateOfLastOccurrence = 'health-reproductive-organs-disorder-date-of-last-occurrence',
  healthReproductiveOrgansDisorderNumberOfOccurrences = 'health-reproductive-organs-disorder-number-of-occurrences',
  healthReproductiveOrgansDisorderCausePhysicalLimitations = 'health-reproductive-organs-disorder-cause-physical-limitations',
  healthReproductiveOrgansDisorderLimitationDetails2 = 'health-reproductive-organs-disorder-limitation-details-2',
  healthReproductiveOrgansDisorderMedProv = 'health-reproductive-organs-disorder-med-prov',
  healthReproductiveOrgansDisorderMedProvName = 'health-reproductive-organs-disorder-med-prov-name',
  healthReproductiveOrgansDisorderMedProvAddress = 'health-reproductive-organs-disorder-med-prov-address',
  healthReproductiveOrgansDisorderMedProvPhone = 'health-reproductive-organs-disorder-med-prov-phone',
  healthReproductiveOrgansDisorderHospitalVisit = 'health-reproductive-organs-disorder-hospital-visit',
  healthReproductiveOrgansDisorderHospitalVisitDate = 'health-reproductive-organs-disorder-hospital-visit-date',
  healthReproductiveOrgansDisorderHospitalName = 'health-reproductive-organs-disorder-hospital-name',
  healthReproductiveOrgansDisorderHospitalAddress = 'health-reproductive-organs-disorder-hospital-address',
  healthBreastDisorderDiagnosisDate = 'health-breast-disorder-diagnosis-date',
  healthBreastDisorderMedications = 'health-breast-disorder-medications',
  healthBreastDisorderAdditionalTreatment = 'health-breast-disorder-additional-treatment',
  healthBreastDisorderStatus = 'health-breast-disorder-status',
  healthBreastDisorderDateOfFullRecovery = 'health-breast-disorder-date-of-full-recovery',
  healthBreastDisorderLimitationDetails = 'health-breast-disorder-limitation-details',
  healthBreastDisorderCurrentlyExperienceSymptoms = 'health-breast-disorder-currently-experience-symptoms',
  healthBreastDisorderDateOfLastSymptom = 'health-breast-disorder-date-of-last-symptom',
  healthBreastDisorderDateOfLastOccurrence = 'health-breast-disorder-date-of-last-occurrence',
  healthBreastDisorderNumberOfOccurrences = 'health-breast-disorder-number-of-occurrences',
  healthBreastDisorderCausePhysicalLimitations = 'health-breast-disorder-cause-physical-limitations',
  healthBreastDisorderLimitationDetails2 = 'health-breast-disorder-limitation-details-2',
  healthBreastDisorderMedProv = 'health-breast-disorder-med-prov',
  healthBreastDisorderMedProvName = 'health-breast-disorder-med-prov-name',
  healthBreastDisorderMedProvAddress = 'health-breast-disorder-med-prov-address',
  healthBreastDisorderMedProvPhone = 'health-breast-disorder-med-prov-phone',
  healthBreastDisorderHospitalVisit = 'health-breast-disorder-hospital-visit',
  healthBreastDisorderHospitalVisitDate = 'health-breast-disorder-hospital-visit-date',
  healthBreastDisorderHospitalName = 'health-breast-disorder-hospital-name',
  healthBreastDisorderHospitalAddress = 'health-breast-disorder-hospital-address',
  healthMenstruationDisorderDiagnosisDate = 'health-menstruation-disorder-diagnosis-date',
  healthMenstruationDisorderMedications = 'health-menstruation-disorder-medications',
  healthMenstruationDisorderAdditionalTreatment = 'health-menstruation-disorder-additional-treatment',
  healthMenstruationDisorderStatus = 'health-menstruation-disorder-status',
  healthMenstruationDisorderDateOfFullRecovery = 'health-menstruation-disorder-date-of-full-recovery',
  healthMenstruationDisorderLimitationDetails = 'health-menstruation-disorder-limitation-details',
  healthMenstruationDisorderCurrentlyExperienceSymptoms = 'health-menstruation-disorder-currently-experience-symptoms',
  healthMenstruationDisorderDateOfLastSymptom = 'health-menstruation-disorder-date-of-last-symptom',
  healthMenstruationDisorderDateOfLastOccurrence = 'health-menstruation-disorder-date-of-last-occurrence',
  healthMenstruationDisorderNumberOfOccurrences = 'health-menstruation-disorder-number-of-occurrences',
  healthMenstruationDisorderCausePhysicalLimitations = 'health-menstruation-disorder-cause-physical-limitations',
  healthMenstruationDisorderLimitationDetails2 = 'health-menstruation-disorder-limitation-details-2',
  healthMenstruationDisorderMedProv = 'health-menstruation-disorder-med-prov',
  healthMenstruationDisorderMedProvName = 'health-menstruation-disorder-med-prov-name',
  healthMenstruationDisorderMedProvAddress = 'health-menstruation-disorder-med-prov-address',
  healthMenstruationDisorderMedProvPhone = 'health-menstruation-disorder-med-prov-phone',
  healthMenstruationDisorderHospitalVisit = 'health-menstruation-disorder-hospital-visit',
  healthMenstruationDisorderHospitalVisitDate = 'health-menstruation-disorder-hospital-visit-date',
  healthMenstruationDisorderHospitalName = 'health-menstruation-disorder-hospital-name',
  healthMenstruationDisorderHospitalAddress = 'health-menstruation-disorder-hospital-address',
  healthPregnancyDeliveryDate = 'health-pregnancy-delivery-date',
  healthHasPregnancyComplications = 'health-has-pregnancy-complications',
  healthPregnancyComplicationDetails = 'health-pregnancy-complication-details',
  healthPregnancyComplicationHistory = 'health-pregnancy-complication-history',
  healthPregnancyMedProviderName = 'health-pregnancy-med-provider-name',
  healthPregnancyMedProviderAddress = 'health-pregnancy-med-provider-address',
  healthPregnancyDiagnosis = 'health-pregnancy-diagnosis',
  healthStrokeTiaDiagnosisDate = 'health-stroke-tia-diagnosis-date',
  healthStrokeTiaMedications = 'health-stroke-tia-medications',
  healthStrokeTiaAdditionalTreatment = 'health-stroke-tia-additional-treatment',
  healthStrokeTiaStatus = 'health-stroke-tia-status',
  healthStrokeTiaDateOfFullRecovery = 'health-stroke-tia-date-of-full-recovery',
  healthStrokeTiaLimitationDetails = 'health-stroke-tia-limitation-details',
  healthStrokeTiaCurrentlyExperienceSymptoms = 'health-stroke-tia-currently-experience-symptoms',
  healthStrokeTiaDateOfLastSymptom = 'health-stroke-tia-date-of-last-symptom',
  healthStrokeTiaDateOfLastOccurrence = 'health-stroke-tia-date-of-last-occurrence',
  healthStrokeTiaNumberOfOccurrences = 'health-stroke-tia-number-of-occurrences',
  healthStrokeTiaCausePhysicalLimitations = 'health-stroke-tia-cause-physical-limitations',
  healthStrokeTiaLimitationDetails2 = 'health-stroke-tia-limitation-details-2',
  healthStrokeTiaMedProv = 'health-stroke-tia-med-prov',
  healthStrokeTiaMedProvName = 'health-stroke-tia-med-prov-name',
  healthStrokeTiaMedProvAddress = 'health-stroke-tia-med-prov-address',
  healthStrokeTiaMedProvPhone = 'health-stroke-tia-med-prov-phone',
  healthStrokeTiaHospitalVisit = 'health-stroke-tia-hospital-visit',
  healthStrokeTiaHospitalVisitDate = 'health-stroke-tia-hospital-visit-date',
  healthStrokeTiaHospitalName = 'health-stroke-tia-hospital-name',
  healthStrokeTiaHospitalAddress = 'health-stroke-tia-hospital-address',
  healthChestPainDiagnosisDate = 'health-chest-pain-diagnosis-date',
  healthChestPainMedications = 'health-chest-pain-medications',
  healthChestPainAdditionalTreatment = 'health-chest-pain-additional-treatment',
  healthChestPainStatus = 'health-chest-pain-status',
  healthChestPainDateOfFullRecovery = 'health-chest-pain-date-of-full-recovery',
  healthChestPainLimitationDetails = 'health-chest-pain-limitation-details',
  healthChestPainCurrentlyExperienceSymptoms = 'health-chest-pain-currently-experience-symptoms',
  healthChestPainDateOfLastSymptom = 'health-chest-pain-date-of-last-symptom',
  healthChestPainDateOfLastOccurrence = 'health-chest-pain-date-of-last-occurrence',
  healthChestPainNumberOfOccurrences = 'health-chest-pain-number-of-occurrences',
  healthChestPainCausePhysicalLimitations = 'health-chest-pain-cause-physical-limitations',
  healthChestPainLimitationDetails2 = 'health-chest-pain-limitation-details-2',
  healthChestPainMedProv = 'health-chest-pain-med-prov',
  healthChestPainMedProvName = 'health-chest-pain-med-prov-name',
  healthChestPainMedProvAddress = 'health-chest-pain-med-prov-address',
  healthChestPainMedProvPhone = 'health-chest-pain-med-prov-phone',
  healthChestPainHospitalVisit = 'health-chest-pain-hospital-visit',
  healthChestPainHospitalVisitDate = 'health-chest-pain-hospital-visit-date',
  healthChestPainHospitalName = 'health-chest-pain-hospital-name',
  healthChestPainHospitalAddress = 'health-chest-pain-hospital-address',
  healthCoronaryArteryDiseaseDiagnosisDate = 'health-coronary-artery-disease-diagnosis-date',
  healthCoronaryArteryDiseaseMedications = 'health-coronary-artery-disease-medications',
  healthCoronaryArteryDiseaseAdditionalTreatment = 'health-coronary-artery-disease-additional-treatment',
  healthCoronaryArteryDiseaseStatus = 'health-coronary-artery-disease-status',
  healthCoronaryArteryDiseaseDateOfFullRecovery = 'health-coronary-artery-disease-date-of-full-recovery',
  healthCoronaryArteryDiseaseLimitationDetails = 'health-coronary-artery-disease-limitation-details',
  healthCoronaryArteryDiseaseCurrentlyExperienceSymptoms = 'health-coronary-artery-disease-currently-experience-symptoms',
  healthCoronaryArteryDiseaseDateOfLastSymptom = 'health-coronary-artery-disease-date-of-last-symptom',
  healthCoronaryArteryDiseaseDateOfLastOccurrence = 'health-coronary-artery-disease-date-of-last-occurrence',
  healthCoronaryArteryDiseaseNumberOfOccurrences = 'health-coronary-artery-disease-number-of-occurrences',
  healthCoronaryArteryDiseaseCausePhysicalLimitations = 'health-coronary-artery-disease-cause-physical-limitations',
  healthCoronaryArteryDiseaseLimitationDetails2 = 'health-coronary-artery-disease-limitation-details-2',
  healthCoronaryArteryDiseaseMedProv = 'health-coronary-artery-disease-med-prov',
  healthCoronaryArteryDiseaseMedProvName = 'health-coronary-artery-disease-med-prov-name',
  healthCoronaryArteryDiseaseMedProvAddress = 'health-coronary-artery-disease-med-prov-address',
  healthCoronaryArteryDiseaseMedProvPhone = 'health-coronary-artery-disease-med-prov-phone',
  healthCoronaryArteryDiseaseHospitalVisit = 'health-coronary-artery-disease-hospital-visit',
  healthCoronaryArteryDiseaseHospitalVisitDate = 'health-coronary-artery-disease-hospital-visit-date',
  healthCoronaryArteryDiseaseHospitalName = 'health-coronary-artery-disease-hospital-name',
  healthCoronaryArteryDiseaseHospitalAddress = 'health-coronary-artery-disease-hospital-address',
  healthAbnormalEkgDiagnosisDate = 'health-abnormal-ekg-diagnosis-date',
  healthAbnormalEkgMedications = 'health-abnormal-ekg-medications',
  healthAbnormalEkgAdditionalTreatment = 'health-abnormal-ekg-additional-treatment',
  healthAbnormalEkgStatus = 'health-abnormal-ekg-status',
  healthAbnormalEkgDateOfFullRecovery = 'health-abnormal-ekg-date-of-full-recovery',
  healthAbnormalEkgLimitationDetails = 'health-abnormal-ekg-limitation-details',
  healthAbnormalEkgCurrentlyExperienceSymptoms = 'health-abnormal-ekg-currently-experience-symptoms',
  healthAbnormalEkgDateOfLastSymptom = 'health-abnormal-ekg-date-of-last-symptom',
  healthAbnormalEkgDateOfLastOccurrence = 'health-abnormal-ekg-date-of-last-occurrence',
  healthAbnormalEkgNumberOfOccurrences = 'health-abnormal-ekg-number-of-occurrences',
  healthAbnormalEkgCausePhysicalLimitations = 'health-abnormal-ekg-cause-physical-limitations',
  healthAbnormalEkgLimitationDetails2 = 'health-abnormal-ekg-limitation-details-2',
  healthAbnormalEkgMedProv = 'health-abnormal-ekg-med-prov',
  healthAbnormalEkgMedProvName = 'health-abnormal-ekg-med-prov-name',
  healthAbnormalEkgMedProvAddress = 'health-abnormal-ekg-med-prov-address',
  healthAbnormalEkgMedProvPhone = 'health-abnormal-ekg-med-prov-phone',
  healthAbnormalEkgHospitalVisit = 'health-abnormal-ekg-hospital-visit',
  healthAbnormalEkgHospitalVisitDate = 'health-abnormal-ekg-hospital-visit-date',
  healthAbnormalEkgHospitalName = 'health-abnormal-ekg-hospital-name',
  healthAbnormalEkgHospitalAddress = 'health-abnormal-ekg-hospital-address',
  healthPeripheralVascularDiseaseDiagnosisDate = 'health-peripheral-vascular-disease-diagnosis-date',
  healthPeripheralVascularDiseaseMedications = 'health-peripheral-vascular-disease-medications',
  healthPeripheralVascularDiseaseAdditionalTreatment = 'health-peripheral-vascular-disease-additional-treatment',
  healthPeripheralVascularDiseaseStatus = 'health-peripheral-vascular-disease-status',
  healthPeripheralVascularDiseaseDateOfFullRecovery = 'health-peripheral-vascular-disease-date-of-full-recovery',
  healthPeripheralVascularDiseaseLimitationDetails = 'health-peripheral-vascular-disease-limitation-details',
  healthPeripheralVascularDiseaseCurrentlyExperienceSymptoms = 'health-peripheral-vascular-disease-currently-experience-symptoms',
  healthPeripheralVascularDiseaseDateOfLastSymptom = 'health-peripheral-vascular-disease-date-of-last-symptom',
  healthPeripheralVascularDiseaseDateOfLastOccurrence = 'health-peripheral-vascular-disease-date-of-last-occurrence',
  healthPeripheralVascularDiseaseNumberOfOccurrences = 'health-peripheral-vascular-disease-number-of-occurrences',
  healthPeripheralVascularDiseaseCausePhysicalLimitations = 'health-peripheral-vascular-disease-cause-physical-limitations',
  healthPeripheralVascularDiseaseLimitationDetails2 = 'health-peripheral-vascular-disease-limitation-details-2',
  healthPeripheralVascularDiseaseMedProv = 'health-peripheral-vascular-disease-med-prov',
  healthPeripheralVascularDiseaseMedProvName = 'health-peripheral-vascular-disease-med-prov-name',
  healthPeripheralVascularDiseaseMedProvAddress = 'health-peripheral-vascular-disease-med-prov-address',
  healthPeripheralVascularDiseaseMedProvPhone = 'health-peripheral-vascular-disease-med-prov-phone',
  healthPeripheralVascularDiseaseHospitalVisit = 'health-peripheral-vascular-disease-hospital-visit',
  healthPeripheralVascularDiseaseHospitalVisitDate = 'health-peripheral-vascular-disease-hospital-visit-date',
  healthPeripheralVascularDiseaseHospitalName = 'health-peripheral-vascular-disease-hospital-name',
  healthPeripheralVascularDiseaseHospitalAddress = 'health-peripheral-vascular-disease-hospital-address',
  healthCirculatorySystemOtherDisorderDiagnosisDate = 'health-circulatory-system-other-disorder-diagnosis-date',
  healthCirculatorySystemOtherDisorderMedications = 'health-circulatory-system-other-disorder-medications',
  healthCirculatorySystemOtherDisorderAdditionalTreatment = 'health-circulatory-system-other-disorder-additional-treatment',
  healthCirculatorySystemOtherDisorderStatus = 'health-circulatory-system-other-disorder-status',
  healthCirculatorySystemOtherDisorderDateOfFullRecovery = 'health-circulatory-system-other-disorder-date-of-full-recovery',
  healthCirculatorySystemOtherDisorderLimitationDetails = 'health-circulatory-system-other-disorder-limitation-details',
  healthCirculatorySystemOtherDisorderCurrentlyExperienceSymptoms = 'health-circulatory-system-other-disorder-currently-experience-symptoms',
  healthCirculatorySystemOtherDisorderDateOfLastSymptom = 'health-circulatory-system-other-disorder-date-of-last-symptom',
  healthCirculatorySystemOtherDisorderDateOfLastOccurrence = 'health-circulatory-system-other-disorder-date-of-last-occurrence',
  healthCirculatorySystemOtherDisorderNumberOfOccurrences = 'health-circulatory-system-other-disorder-number-of-occurrences',
  healthCirculatorySystemOtherDisorderCausePhysicalLimitations = 'health-circulatory-system-other-disorder-cause-physical-limitations',
  healthCirculatorySystemOtherDisorderLimitationDetails2 = 'health-circulatory-system-other-disorder-limitation-details-2',
  healthCirculatorySystemOtherDisorderMedProv = 'health-circulatory-system-other-disorder-med-prov',
  healthCirculatorySystemOtherDisorderMedProvName = 'health-circulatory-system-other-disorder-med-prov-name',
  healthCirculatorySystemOtherDisorderMedProvAddress = 'health-circulatory-system-other-disorder-med-prov-address',
  healthCirculatorySystemOtherDisorderMedProvPhone = 'health-circulatory-system-other-disorder-med-prov-phone',
  healthCirculatorySystemOtherDisorderHospitalVisit = 'health-circulatory-system-other-disorder-hospital-visit',
  healthCirculatorySystemOtherDisorderHospitalVisitDate = 'health-circulatory-system-other-disorder-hospital-visit-date',
  healthCirculatorySystemOtherDisorderHospitalName = 'health-circulatory-system-other-disorder-hospital-name',
  healthCirculatorySystemOtherDisorderHospitalAddress = 'health-circulatory-system-other-disorder-hospital-address',
  healthTumorDiagnosisDate = 'health-tumor-diagnosis-date',
  healthTumorMedications = 'health-tumor-medications',
  healthTumorAdditionalTreatment = 'health-tumor-additional-treatment',
  healthTumorStatus = 'health-tumor-status',
  healthTumorDateOfFullRecovery = 'health-tumor-date-of-full-recovery',
  healthTumorLimitationDetails = 'health-tumor-limitation-details',
  healthTumorCurrentlyExperienceSymptoms = 'health-tumor-currently-experience-symptoms',
  healthTumorDateOfLastSymptom = 'health-tumor-date-of-last-symptom',
  healthTumorDateOfLastOccurrence = 'health-tumor-date-of-last-occurrence',
  healthTumorNumberOfOccurrences = 'health-tumor-number-of-occurrences',
  healthTumorCausePhysicalLimitations = 'health-tumor-cause-physical-limitations',
  healthTumorLimitationDetails2 = 'health-tumor-limitation-details-2',
  healthTumorMedProv = 'health-tumor-med-prov',
  healthTumorMedProvName = 'health-tumor-med-prov-name',
  healthTumorMedProvAddress = 'health-tumor-med-prov-address',
  healthTumorMedProvPhone = 'health-tumor-med-prov-phone',
  healthTumorHospitalVisit = 'health-tumor-hospital-visit',
  healthTumorHospitalVisitDate = 'health-tumor-hospital-visit-date',
  healthTumorHospitalName = 'health-tumor-hospital-name',
  healthTumorHospitalAddress = 'health-tumor-hospital-address',
  healthPolyps2DiagnosisDate = 'health-polyps-2-diagnosis-date',
  healthPolyps2Medications = 'health-polyps-2-medications',
  healthPolyps2AdditionalTreatment = 'health-polyps-2-additional-treatment',
  healthPolyps2Status = 'health-polyps-2-status',
  healthPolyps2DateOfFullRecovery = 'health-polyps-2-date-of-full-recovery',
  healthPolyps2LimitationDetails = 'health-polyps-2-limitation-details',
  healthPolyps2CurrentlyExperienceSymptoms = 'health-polyps-2-currently-experience-symptoms',
  healthPolyps2DateOfLastSymptom = 'health-polyps-2-date-of-last-symptom',
  healthPolyps2DateOfLastOccurrence = 'health-polyps-2-date-of-last-occurrence',
  healthPolyps2NumberOfOccurrences = 'health-polyps-2-number-of-occurrences',
  healthPolyps2CausePhysicalLimitations = 'health-polyps-2-cause-physical-limitations',
  healthPolyps2LimitationDetails2 = 'health-polyps-2-limitation-details-2',
  healthPolyps2MedProv = 'health-polyps-2-med-prov',
  healthPolyps2MedProvName = 'health-polyps-2-med-prov-name',
  healthPolyps2MedProvAddress = 'health-polyps-2-med-prov-address',
  healthPolyps2MedProvPhone = 'health-polyps-2-med-prov-phone',
  healthPolyps2HospitalVisit = 'health-polyps-2-hospital-visit',
  healthPolyps2HospitalVisitDate = 'health-polyps-2-hospital-visit-date',
  healthPolyps2HospitalName = 'health-polyps-2-hospital-name',
  healthPolyps2HospitalAddress = 'health-polyps-2-hospital-address',
  healthBasalSquamousCellCarcinomaDiagnosisDate = 'health-basal-squamous-cell-carcinoma-diagnosis-date',
  healthBasalSquamousCellCarcinomaMedications = 'health-basal-squamous-cell-carcinoma-medications',
  healthBasalSquamousCellCarcinomaAdditionalTreatment = 'health-basal-squamous-cell-carcinoma-additional-treatment',
  healthBasalSquamousCellCarcinomaStatus = 'health-basal-squamous-cell-carcinoma-status',
  healthBasalSquamousCellCarcinomaDateOfFullRecovery = 'health-basal-squamous-cell-carcinoma-date-of-full-recovery',
  healthBasalSquamousCellCarcinomaLimitationDetails = 'health-basal-squamous-cell-carcinoma-limitation-details',
  healthBasalSquamousCellCarcinomaCurrentlyExperienceSymptoms = 'health-basal-squamous-cell-carcinoma-currently-experience-symptoms',
  healthBasalSquamousCellCarcinomaDateOfLastSymptom = 'health-basal-squamous-cell-carcinoma-date-of-last-symptom',
  healthBasalSquamousCellCarcinomaDateOfLastOccurrence = 'health-basal-squamous-cell-carcinoma-date-of-last-occurrence',
  healthBasalSquamousCellCarcinomaNumberOfOccurrences = 'health-basal-squamous-cell-carcinoma-number-of-occurrences',
  healthBasalSquamousCellCarcinomaCausePhysicalLimitations = 'health-basal-squamous-cell-carcinoma-cause-physical-limitations',
  healthBasalSquamousCellCarcinomaLimitationDetails2 = 'health-basal-squamous-cell-carcinoma-limitation-details-2',
  healthBasalSquamousCellCarcinomaMedProv = 'health-basal-squamous-cell-carcinoma-med-prov',
  healthBasalSquamousCellCarcinomaMedProvName = 'health-basal-squamous-cell-carcinoma-med-prov-name',
  healthBasalSquamousCellCarcinomaMedProvAddress = 'health-basal-squamous-cell-carcinoma-med-prov-address',
  healthBasalSquamousCellCarcinomaMedProvPhone = 'health-basal-squamous-cell-carcinoma-med-prov-phone',
  healthBasalSquamousCellCarcinomaHospitalVisit = 'health-basal-squamous-cell-carcinoma-hospital-visit',
  healthBasalSquamousCellCarcinomaHospitalVisitDate = 'health-basal-squamous-cell-carcinoma-hospital-visit-date',
  healthBasalSquamousCellCarcinomaHospitalName = 'health-basal-squamous-cell-carcinoma-hospital-name',
  healthBasalSquamousCellCarcinomaHospitalAddress = 'health-basal-squamous-cell-carcinoma-hospital-address',
  healthAbnormalMolesLesionsDiagnosisDate = 'health-abnormal-moles-lesions-diagnosis-date',
  healthAbnormalMolesLesionsMedications = 'health-abnormal-moles-lesions-medications',
  healthAbnormalMolesLesionsAdditionalTreatment = 'health-abnormal-moles-lesions-additional-treatment',
  healthAbnormalMolesLesionsStatus = 'health-abnormal-moles-lesions-status',
  healthAbnormalMolesLesionsDateOfFullRecovery = 'health-abnormal-moles-lesions-date-of-full-recovery',
  healthAbnormalMolesLesionsLimitationDetails = 'health-abnormal-moles-lesions-limitation-details',
  healthAbnormalMolesLesionsCurrentlyExperienceSymptoms = 'health-abnormal-moles-lesions-currently-experience-symptoms',
  healthAbnormalMolesLesionsDateOfLastSymptom = 'health-abnormal-moles-lesions-date-of-last-symptom',
  healthAbnormalMolesLesionsDateOfLastOccurrence = 'health-abnormal-moles-lesions-date-of-last-occurrence',
  healthAbnormalMolesLesionsNumberOfOccurrences = 'health-abnormal-moles-lesions-number-of-occurrences',
  healthAbnormalMolesLesionsCausePhysicalLimitations = 'health-abnormal-moles-lesions-cause-physical-limitations',
  healthAbnormalMolesLesionsLimitationDetails2 = 'health-abnormal-moles-lesions-limitation-details-2',
  healthAbnormalMolesLesionsMedProv = 'health-abnormal-moles-lesions-med-prov',
  healthAbnormalMolesLesionsMedProvName = 'health-abnormal-moles-lesions-med-prov-name',
  healthAbnormalMolesLesionsMedProvAddress = 'health-abnormal-moles-lesions-med-prov-address',
  healthAbnormalMolesLesionsMedProvPhone = 'health-abnormal-moles-lesions-med-prov-phone',
  healthAbnormalMolesLesionsHospitalVisit = 'health-abnormal-moles-lesions-hospital-visit',
  healthAbnormalMolesLesionsHospitalVisitDate = 'health-abnormal-moles-lesions-hospital-visit-date',
  healthAbnormalMolesLesionsHospitalName = 'health-abnormal-moles-lesions-hospital-name',
  healthAbnormalMolesLesionsHospitalAddress = 'health-abnormal-moles-lesions-hospital-address',
  healthDysplasticNeviDiagnosisDate = 'health-dysplastic-nevi-diagnosis-date',
  healthDysplasticNeviMedications = 'health-dysplastic-nevi-medications',
  healthDysplasticNeviAdditionalTreatment = 'health-dysplastic-nevi-additional-treatment',
  healthDysplasticNeviStatus = 'health-dysplastic-nevi-status',
  healthDysplasticNeviDateOfFullRecovery = 'health-dysplastic-nevi-date-of-full-recovery',
  healthDysplasticNeviLimitationDetails = 'health-dysplastic-nevi-limitation-details',
  healthDysplasticNeviCurrentlyExperienceSymptoms = 'health-dysplastic-nevi-currently-experience-symptoms',
  healthDysplasticNeviDateOfLastSymptom = 'health-dysplastic-nevi-date-of-last-symptom',
  healthDysplasticNeviDateOfLastOccurrence = 'health-dysplastic-nevi-date-of-last-occurrence',
  healthDysplasticNeviNumberOfOccurrences = 'health-dysplastic-nevi-number-of-occurrences',
  healthDysplasticNeviCausePhysicalLimitations = 'health-dysplastic-nevi-cause-physical-limitations',
  healthDysplasticNeviLimitationDetails2 = 'health-dysplastic-nevi-limitation-details-2',
  healthDysplasticNeviMedProv = 'health-dysplastic-nevi-med-prov',
  healthDysplasticNeviMedProvName = 'health-dysplastic-nevi-med-prov-name',
  healthDysplasticNeviMedProvAddress = 'health-dysplastic-nevi-med-prov-address',
  healthDysplasticNeviMedProvPhone = 'health-dysplastic-nevi-med-prov-phone',
  healthDysplasticNeviHospitalVisit = 'health-dysplastic-nevi-hospital-visit',
  healthDysplasticNeviHospitalVisitDate = 'health-dysplastic-nevi-hospital-visit-date',
  healthDysplasticNeviHospitalName = 'health-dysplastic-nevi-hospital-name',
  healthDysplasticNeviHospitalAddress = 'health-dysplastic-nevi-hospital-address',
  healthMalignantMelanomaDiagnosisDate = 'health-malignant-melanoma-diagnosis-date',
  healthMalignantMelanomaMedications = 'health-malignant-melanoma-medications',
  healthMalignantMelanomaAdditionalTreatment = 'health-malignant-melanoma-additional-treatment',
  healthMalignantMelanomaStatus = 'health-malignant-melanoma-status',
  healthMalignantMelanomaDateOfFullRecovery = 'health-malignant-melanoma-date-of-full-recovery',
  healthMalignantMelanomaLimitationDetails = 'health-malignant-melanoma-limitation-details',
  healthMalignantMelanomaCurrentlyExperienceSymptoms = 'health-malignant-melanoma-currently-experience-symptoms',
  healthMalignantMelanomaDateOfLastSymptom = 'health-malignant-melanoma-date-of-last-symptom',
  healthMalignantMelanomaDateOfLastOccurrence = 'health-malignant-melanoma-date-of-last-occurrence',
  healthMalignantMelanomaNumberOfOccurrences = 'health-malignant-melanoma-number-of-occurrences',
  healthMalignantMelanomaCausePhysicalLimitations = 'health-malignant-melanoma-cause-physical-limitations',
  healthMalignantMelanomaLimitationDetails2 = 'health-malignant-melanoma-limitation-details-2',
  healthMalignantMelanomaMedProv = 'health-malignant-melanoma-med-prov',
  healthMalignantMelanomaMedProvName = 'health-malignant-melanoma-med-prov-name',
  healthMalignantMelanomaMedProvAddress = 'health-malignant-melanoma-med-prov-address',
  healthMalignantMelanomaMedProvPhone = 'health-malignant-melanoma-med-prov-phone',
  healthMalignantMelanomaHospitalVisit = 'health-malignant-melanoma-hospital-visit',
  healthMalignantMelanomaHospitalVisitDate = 'health-malignant-melanoma-hospital-visit-date',
  healthMalignantMelanomaHospitalName = 'health-malignant-melanoma-hospital-name',
  healthMalignantMelanomaHospitalAddress = 'health-malignant-melanoma-hospital-address',
  healthGrowthLumpNotEvaluatedDiagnosisDate = 'health-growth-lump-not-evaluated-diagnosis-date',
  healthGrowthLumpNotEvaluatedMedications = 'health-growth-lump-not-evaluated-medications',
  healthGrowthLumpNotEvaluatedAdditionalTreatment = 'health-growth-lump-not-evaluated-additional-treatment',
  healthGrowthLumpNotEvaluatedStatus = 'health-growth-lump-not-evaluated-status',
  healthGrowthLumpNotEvaluatedDateOfFullRecovery = 'health-growth-lump-not-evaluated-date-of-full-recovery',
  healthGrowthLumpNotEvaluatedLimitationDetails = 'health-growth-lump-not-evaluated-limitation-details',
  healthGrowthLumpNotEvaluatedCurrentlyExperienceSymptoms = 'health-growth-lump-not-evaluated-currently-experience-symptoms',
  healthGrowthLumpNotEvaluatedDateOfLastSymptom = 'health-growth-lump-not-evaluated-date-of-last-symptom',
  healthGrowthLumpNotEvaluatedDateOfLastOccurrence = 'health-growth-lump-not-evaluated-date-of-last-occurrence',
  healthGrowthLumpNotEvaluatedNumberOfOccurrences = 'health-growth-lump-not-evaluated-number-of-occurrences',
  healthGrowthLumpNotEvaluatedCausePhysicalLimitations = 'health-growth-lump-not-evaluated-cause-physical-limitations',
  healthGrowthLumpNotEvaluatedLimitationDetails2 = 'health-growth-lump-not-evaluated-limitation-details-2',
  healthGrowthLumpNotEvaluatedMedProv = 'health-growth-lump-not-evaluated-med-prov',
  healthGrowthLumpNotEvaluatedMedProvName = 'health-growth-lump-not-evaluated-med-prov-name',
  healthGrowthLumpNotEvaluatedMedProvAddress = 'health-growth-lump-not-evaluated-med-prov-address',
  healthGrowthLumpNotEvaluatedMedProvPhone = 'health-growth-lump-not-evaluated-med-prov-phone',
  healthGrowthLumpNotEvaluatedHospitalVisit = 'health-growth-lump-not-evaluated-hospital-visit',
  healthGrowthLumpNotEvaluatedHospitalVisitDate = 'health-growth-lump-not-evaluated-hospital-visit-date',
  healthGrowthLumpNotEvaluatedHospitalName = 'health-growth-lump-not-evaluated-hospital-name',
  healthGrowthLumpNotEvaluatedHospitalAddress = 'health-growth-lump-not-evaluated-hospital-address',
  healthEndocrine = 'health-endocrine',
  healthEndocrineChoice = 'health-endocrine-choice',
  healthDiabetesType = 'health-diabetes-type',
  healthDiabetesDateHba1c = 'health-diabetes-date-HbA1C',
  healthDiabetesResultHba1c = 'health-diabetes-result-HbA1C',
  healthDiabetesComplicationsChoice = 'health-diabetes-complications-choice',
  healthDiabetesDiagnosisDate = 'health-diabetes-diagnosis-date',
  healthDiabetesMedications = 'health-diabetes-medications',
  healthDiabetesAdditionalTreatment = 'health-diabetes-additional-treatment',
  healthDiabetesStatus = 'health-diabetes-status',
  healthDiabetesDateOfFullRecovery = 'health-diabetes-date-of-full-recovery',
  healthDiabetesLimitationDetails = 'health-diabetes-limitation-details',
  healthDiabetesCurrentlyExperienceSymptoms = 'health-diabetes-currently-experience-symptoms',
  healthDiabetesDateOfLastSymptom = 'health-diabetes-date-of-last-symptom',
  healthDiabetesDateOfLastOccurrence = 'health-diabetes-date-of-last-occurrence',
  healthDiabetesNumberOfOccurrences = 'health-diabetes-number-of-occurrences',
  healthDiabetesCausePhysicalLimitations = 'health-diabetes-cause-physical-limitations',
  healthDiabetesLimitationDetails2 = 'health-diabetes-limitation-details-2',
  healthDiabetesMedProv = 'health-diabetes-med-prov',
  healthDiabetesMedProvName = 'health-diabetes-med-prov-name',
  healthDiabetesMedProvAddress = 'health-diabetes-med-prov-address',
  healthDiabetesMedProvPhone = 'health-diabetes-med-prov-phone',
  healthDiabetesHospitalVisit = 'health-diabetes-hospital-visit',
  healthDiabetesHospitalVisitDate = 'health-diabetes-hospital-visit-date',
  healthDiabetesHospitalName = 'health-diabetes-hospital-name',
  healthDiabetesHospitalAddress = 'health-diabetes-hospital-address',

  healthThyroidDiagnosisDate = 'health-thyroid-diagnosis-date',
  healthThyroidMedications = 'health-thyroid-medications',
  healthThyroidAdditionalTreatment = 'health-thyroid-additional-treatment',
  healthThyroidStatus = 'health-thyroid-status',
  healthThyroidDateOfFullRecovery = 'health-thyroid-date-of-full-recovery',
  healthThyroidLimitationDetails = 'health-thyroid-limitation-details',
  healthThyroidCurrentlyExperienceSymptoms = 'health-thyroid-currently-experience-symptoms',
  healthThyroidDateOfLastSymptom = 'health-thyroid-date-of-last-symptom',
  healthThyroidDateOfLastOccurrence = 'health-thyroid-date-of-last-occurrence',
  healthThyroidNumberOfOccurrences = 'health-thyroid-number-of-occurrences',
  healthThyroidCausePhysicalLimitations = 'health-thyroid-cause-physical-limitations',
  healthThyroidLimitationDetails2 = 'health-thyroid-limitation-details-2',
  healthThyroidMedProv = 'health-thyroid-med-prov',
  healthThyroidMedProvName = 'health-thyroid-med-prov-name',
  healthThyroidMedProvAddress = 'health-thyroid-med-prov-address',
  healthThyroidMedProvPhone = 'health-thyroid-med-prov-phone',
  healthThyroidHospitalVisit = 'health-thyroid-hospital-visit',
  healthThyroidHospitalVisitDate = 'health-thyroid-hospital-visit-date',
  healthThyroidHospitalName = 'health-thyroid-hospital-name',
  healthThyroidHospitalAddress = 'health-thyroid-hospital-address',
  healthInsuredAbnormalBloodSugarDetails = 'health-insured-abnormal-blood-sugar-details',
  healthInsuredPituitaryGlandDisorderDetails = 'health-insured-pituitary-gland-disorder-details',
  healthInsuredAdrenalGlandDisorderDetails = 'health-insured-adrenal-gland-disorder-details',
  healthOtherBloodGlandularDisorderDiagnosisDate = 'health-other-blood-glandular-disorder-diagnosis-date',
  healthOtherBloodGlandularDisorderMedications = 'health-other-blood-glandular-disorder-medications',
  healthOtherBloodGlandularDisorderAdditionalTreatment = 'health-other-blood-glandular-disorder-additional-treatment',
  healthOtherBloodGlandularDisorderStatus = 'health-other-blood-glandular-disorder-status',
  healthOtherBloodGlandularDisorderDateOfFullRecovery = 'health-other-blood-glandular-disorder-date-of-full-recovery',
  healthOtherBloodGlandularDisorderLimitationDetails = 'health-other-blood-glandular-disorder-limitation-details',
  healthOtherBloodGlandularDisorderCurrentlyExperienceSymptoms = 'health-other-blood-glandular-disorder-currently-experience-symptoms',
  healthOtherBloodGlandularDisorderDateOfLastSymptom = 'health-other-blood-glandular-disorder-date-of-last-symptom',
  healthOtherBloodGlandularDisorderDateOfLastOccurrence = 'health-other-blood-glandular-disorder-date-of-last-occurrence',
  healthOtherBloodGlandularDisorderNumberOfOccurrences = 'health-other-blood-glandular-disorder-number-of-occurrences',
  healthOtherBloodGlandularDisorderCausePhysicalLimitations = 'health-other-blood-glandular-disorder-cause-physical-limitations',
  healthOtherBloodGlandularDisorderLimitationDetails2 = 'health-other-blood-glandular-disorder-limitation-details-2',
  healthOtherBloodGlandularDisorderMedProv = 'health-other-blood-glandular-disorder-med-prov',
  healthOtherBloodGlandularDisorderMedProvName = 'health-other-blood-glandular-disorder-med-prov-name',
  healthOtherBloodGlandularDisorderMedProvAddress = 'health-other-blood-glandular-disorder-med-prov-address',
  healthOtherBloodGlandularDisorderMedProvPhone = 'health-other-blood-glandular-disorder-med-prov-phone',
  healthOtherBloodGlandularDisorderHospitalVisit = 'health-other-blood-glandular-disorder-hospital-visit',
  healthOtherBloodGlandularDisorderHospitalVisitDate = 'health-other-blood-glandular-disorder-hospital-visit-date',
  healthOtherBloodGlandularDisorderHospitalName = 'health-other-blood-glandular-disorder-hospital-name',
  healthOtherBloodGlandularDisorderHospitalAddress = 'health-other-blood-glandular-disorder-hospital-address',
  healthAnxietyDiagnosisDate = 'health-anxiety-diagnosis-date',
  healthAnxietyCause = 'health-anxiety-cause',
  healthAnxietyPhysician = 'health-anxiety-physician',
  healthAnxietyDateLastConsultation = 'health-anxiety-date-last-consultation',
  healthAnxietyPhysicianVisitsFrequency = 'health-anxiety-physician-visits-frequency',
  healthAnxietyTreatment = 'health-anxiety-treatment',
  healthAnxietyTreatmentDetails = 'health-anxiety-treatment-details',
  healthAnxietyPsychotherapy = 'health-anxiety-psychotherapy',
  healthAnxietyPsychotherapyDetails = 'health-anxiety-psychotherapy-details',
  healthAnxietySymptoms = 'health-anxiety-symptoms',
  healthAnxietyOffWork = 'health-anxiety-off-work',
  healthAnxietyOffWorkDetails = 'health-anxiety-off-work-details',
  healthAnxietyDetails = 'health-anxiety-details',
  healthAnxietyMedications = 'health-anxiety-medications',
  healthAnxietyAdditionalTreatment = 'health-anxiety-additional-treatment',
  healthAnxietyStatus = 'health-anxiety-status',
  healthAnxietyDateOfFullRecovery = 'health-anxiety-date-of-full-recovery',
  healthAnxietyLimitationDetails = 'health-anxiety-limitation-details',
  healthAnxietyCurrentlyExperienceSymptoms = 'health-anxiety-currently-experience-symptoms',
  healthAnxietyDateOfLastSymptom = 'health-anxiety-date-of-last-symptom',
  healthAnxietyDateOfLastOccurrence = 'health-anxiety-date-of-last-occurrence',
  healthAnxietyNumberOfOccurrences = 'health-anxiety-number-of-occurrences',
  healthAnxietyCausePhysicalLimitations = 'health-anxiety-cause-physical-limitations',
  healthAnxietyLimitationDetails2 = 'health-anxiety-limitation-details-2',
  healthAnxietyMedProv = 'health-anxiety-med-prov',
  healthAnxietyMedProvName = 'health-anxiety-med-prov-name',
  healthAnxietyMedProvAddress = 'health-anxiety-med-prov-address',
  healthAnxietyMedProvPhone = 'health-anxiety-med-prov-phone',
  healthAnxietyHospitalVisit = 'health-anxiety-hospital-visit',
  healthAnxietyHospitalVisitDate = 'health-anxiety-hospital-visit-date',
  healthAnxietyHospitalName = 'health-anxiety-hospital-name',
  healthAnxietyHospitalAddress = 'health-anxiety-hospital-address',
  healthAnxietyHospitalVisitDays = 'health-anxiety-hospital-visit-days',
  healthAnxietyDaysOffWork = 'health-anxiety-days-off-work',
  healthDepressionDiagnosisDate = 'health-depression-diagnosis-date',
  healthDepressionCause = 'health-depression-cause',
  healthDepressionPhysician = 'health-depression-physician',
  healthDepressionDateLastConsultation = 'health-depression-date-last-consultation',
  healthDepressionPhysicianVisitsFrequency = 'health-depression-physician-visits-frequency',
  healthDepressionTreatment = 'health-depression-treatment',
  healthDepressionTreatmentDetails = 'health-depression-treatment-details',
  healthDepressionPsychotherapy = 'health-depression-psychotherapy',
  healthDepressionPsychotherapyDetails = 'health-depression-psychotherapy-details',
  healthDepressionSymptoms = 'health-depression-symptoms',
  healthDepressionOffWork = 'health-depression-off-work',
  healthDepressionOffWorkDetails = 'health-depression-off-work-details',
  healthDepressionDetails = 'health-depression-details',
  healthDepressionMedications = 'health-depression-medications',
  healthDepressionAdditionalTreatment = 'health-depression-additional-treatment',
  healthDepressionStatus = 'health-depression-status',
  healthDepressionDateOfFullRecovery = 'health-depression-date-of-full-recovery',
  healthDepressionLimitationDetails = 'health-depression-limitation-details',
  healthDepressionCurrentlyExperienceSymptoms = 'health-depression-currently-experience-symptoms',
  healthDepressionDateOfLastSymptom = 'health-depression-date-of-last-symptom',
  healthDepressionDateOfLastOccurrence = 'health-depression-date-of-last-occurrence',
  healthDepressionNumberOfOccurrences = 'health-depression-number-of-occurrences',
  healthDepressionCausePhysicalLimitations = 'health-depression-cause-physical-limitations',
  healthDepressionLimitationDetails2 = 'health-depression-limitation-details-2',
  healthDepressionMedProv = 'health-depression-med-prov',
  healthDepressionMedProvName = 'health-depression-med-prov-name',
  healthDepressionMedProvAddress = 'health-depression-med-prov-address',
  healthDepressionMedProvPhone = 'health-depression-med-prov-phone',
  healthDepressionHospitalVisit = 'health-depression-hospital-visit',
  healthDepressionHospitalVisitDate = 'health-depression-hospital-visit-date',
  healthDepressionHospitalName = 'health-depression-hospital-name',
  healthDepressionHospitalAddress = 'health-depression-hospital-address',
  healthMentalNervousDisorderChoice = 'health-mental-nervous-disorder-choice',
  healthSuicidalThoughtsDiagnosisDate = 'health-suicidal-thoughts-diagnosis-date',
  healthSuicidalThoughtsCause = 'health-suicidal-thoughts-cause',
  healthSuicidalThoughtsPhysician = 'health-suicidal-thoughts-physician',
  healthSuicidalThoughtsDateLastConsultation = 'health-suicidal-thoughts-date-last-consultation',
  healthSuicidalThoughtsPhysicianVisitsFrequency = 'health-suicidal-thoughts-physician-visits-frequency',
  healthSuicidalThoughtsHospitalVisit = 'health-suicidal-thoughts-hospital-visit',
  healthSuicidalThoughtsHospitalVisitDate = 'health-suicidal-thoughts-hospital-visit-date',
  healthSuicidalThoughtsHospitalName = 'health-suicidal-thoughts-hospital-name',
  healthSuicidalThoughtsHospitalAddress = 'health-suicidal-thoughts-hospital-address',
  healthSuicidalThoughtsTreatment = 'health-suicidal-thoughts-treatment',
  healthSuicidalThoughtsTreatmentDetails = 'health-suicidal-thoughts-treatment-details',
  healthSuicidalThoughtsPsychotherapy = 'health-suicidal-thoughts-psychotherapy',
  healthSuicidalThoughtsPsychotherapyDetails = 'health-suicidal-thoughts-psychotherapy-details',
  healthSuicidalThoughtsSymptoms = 'health-suicidal-thoughts-symptoms',
  healthSuicidalThoughtsOffWork = 'health-suicidal-thoughts-off-work',
  healthSuicidalThoughtsOffWorkDetails = 'health-suicidal-thoughts-off-work-details',
  healthSuicidalThoughtsDetails = 'health-suicidal-thoughts-details',
  healthStressDiagnosisDate = 'health-stress-diagnosis-date',
  healthStressCause = 'health-stress-cause',
  healthStressPhysician = 'health-stress-physician',
  healthStressDateLastConsultation = 'health-stress-date-last-consultation',
  healthStressPhysicianVisitsFrequency = 'health-stress-physician-visits-frequency',
  healthStressHospitalVisit = 'health-stress-hospital-visit',
  healthStressHospitalVisitDate = 'health-stress-hospital-visit-date',
  healthStressHospitalName = 'health-stress-hospital-name',
  healthStressHospitalAddress = 'health-stress-hospital-address',
  healthStressTreatment = 'health-stress-treatment',
  healthStressTreatmentDetails = 'health-stress-treatment-details',
  healthStressPsychotherapy = 'health-stress-psychotherapy',
  healthStressPsychotherapyDetails = 'health-stress-psychotherapy-details',
  healthStressSymptoms = 'health-stress-symptoms',
  healthStressOffWork = 'health-stress-off-work',
  healthStressOffWorkDetails = 'health-stress-off-work-details',
  healthStressDetails = 'health-stress-details',
  healthNervesDiagnosisDate = 'health-nerves-diagnosis-date',
  healthNervesCause = 'health-nerves-cause',
  healthNervesPhysician = 'health-nerves-physician',
  healthNervesDateLastConsultation = 'health-nerves-date-last-consultation',
  healthNervesPhysicianVisitsFrequency = 'health-nerves-physician-visits-frequency',
  healthNervesHospitalVisit = 'health-nerves-hospital-visit',
  healthNervesHospitalVisitDate = 'health-nerves-hospital-visit-date',
  healthNervesHospitalName = 'health-nerves-hospital-name',
  healthNervesHospitalAddress = 'health-nerves-hospital-address',
  healthNervesTreatment = 'health-nerves-treatment',
  healthNervesTreatmentDetails = 'health-nerves-treatment-details',
  healthNervesPsychotherapy = 'health-nerves-psychotherapy',
  healthNervesPsychotherapyDetails = 'health-nerves-psychotherapy-details',
  healthNervesSymptoms = 'health-nerves-symptoms',
  healthNervesOffWork = 'health-nerves-off-work',
  healthNervesOffWorkDetails = 'health-nerves-off-work-details',
  healthNervesDetails = 'health-nerves-details',
  healthAddAdhdDiagnosisDate = 'health-add-adhd-diagnosis-date',
  healthAddAdhdCause = 'health-add-adhd-cause',
  healthAddAdhdPhysician = 'health-add-adhd-physician',
  healthAddAdhdDateLastConsultation = 'health-add-adhd-date-last-consultation',
  healthAddAdhdPhysicianVisitsFrequency = 'health-add-adhd-physician-visits-frequency',
  healthAddAdhdHospitalVisit = 'health-add-adhd-hospital-visit',
  healthAddAdhdHospitalVisitDate = 'health-add-adhd-hospital-visit-date',
  healthAddAdhdHospitalName = 'health-add-adhd-hospital-name',
  healthAddAdhdHospitalAddress = 'health-add-adhd-hospital-address',
  healthAddAdhdTreatment = 'health-add-adhd-treatment',
  healthAddAdhdTreatmentDetails = 'health-add-adhd-treatment-details',
  healthAddAdhdPsychotherapy = 'health-add-adhd-psychotherapy',
  healthAddAdhdPsychotherapyDetails = 'health-add-adhd-psychotherapy-details',
  healthAddAdhdSymptoms = 'health-add-adhd-symptoms',
  healthAddAdhdOffWork = 'health-add-adhd-off-work',
  healthAddAdhdOffWorkDetails = 'health-add-adhd-off-work-details',
  healthAddAdhdDetails = 'health-add-adhd-details',
  healthInsuredAddAdhdMedicationWasPrescribed = 'health-insured-add-adhd-medication-was-prescribed',
  healthInsuredAddAdhdHasConsultedDoctors = 'health-insured-add-adhd-has-consulted-doctors',
  healthInsuredAddAdhdHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-add-adhd-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredAddAdhdIsControlledDate = 'health-insured-add-adhd-is-controlled-date',
  healthInsuredAddAdhdNotControlledDetails = 'health-insured-add-adhd-not-controlled-details',

  healthNervousAlcoholType = 'health-nervous-alcohol-type',
  healthNervousAlcoholFrequency = 'health-nervous-alcohol-frequency',
  healthNervousAlocholQuantity = 'health-nervous-alochol-quantity',
  healthNervousTreatmentAlcohol = 'health-nervous-treatment-alcohol',
  healthNervousTreatmentDrug = 'health-nervous-treatment-drug',
  healthDepressionHospitalVisitDays = 'health-depression-hospital-visit-days',
  healthDepressionDaysOffWork = 'health-depression-days-off-work',
  healthBiPolarDiagnosisDate = 'health-bi-polar-diagnosis-date',
  healthBiPolarCause = 'health-bi-polar-cause',
  healthBiPolarPhysician = 'health-bi-polar-physician',
  healthBiPolarDateLastConsultation = 'health-bi-polar-date-last-consultation',
  healthBiPolarPhysicianVisitsFrequency = 'health-bi-polar-physician-visits-frequency',
  healthBiPolarTreatment = 'health-bi-polar-treatment',
  healthBiPolarTreatmentDetails = 'health-bi-polar-treatment-details',
  healthBiPolarPsychotherapy = 'health-bi-polar-psychotherapy',
  healthBiPolarPsychotherapyDetails = 'health-bi-polar-psychotherapy-details',
  healthBiPolarSymptoms = 'health-bi-polar-symptoms',
  healthBiPolarOffWork = 'health-bi-polar-off-work',
  healthBiPolarOffWorkDetails = 'health-bi-polar-off-work-details',
  healthBiPolarDetails = 'health-bi-polar-details',
  healthBiPolarMedications = 'health-bi-polar-medications',
  healthBiPolarAdditionalTreatment = 'health-bi-polar-additional-treatment',
  healthBiPolarStatus = 'health-bi-polar-status',
  healthBiPolarDateOfFullRecovery = 'health-bi-polar-date-of-full-recovery',
  healthBiPolarLimitationDetails = 'health-bi-polar-limitation-details',
  healthBiPolarCurrentlyExperienceSymptoms = 'health-bi-polar-currently-experience-symptoms',
  healthBiPolarDateOfLastSymptom = 'health-bi-polar-date-of-last-symptom',
  healthBiPolarDateOfLastOccurrence = 'health-bi-polar-date-of-last-occurrence',
  healthBiPolarNumberOfOccurrences = 'health-bi-polar-number-of-occurrences',
  healthBiPolarCausePhysicalLimitations = 'health-bi-polar-cause-physical-limitations',
  healthBiPolarLimitationDetails2 = 'health-bi-polar-limitation-details-2',
  healthBiPolarMedProv = 'health-bi-polar-med-prov',
  healthBiPolarMedProvName = 'health-bi-polar-med-prov-name',
  healthBiPolarMedProvAddress = 'health-bi-polar-med-prov-address',
  healthBiPolarMedProvPhone = 'health-bi-polar-med-prov-phone',
  healthBiPolarHospitalVisit = 'health-bi-polar-hospital-visit',
  healthBiPolarHospitalVisitDate = 'health-bi-polar-hospital-visit-date',
  healthBiPolarHospitalName = 'health-bi-polar-hospital-name',
  healthBiPolarHospitalAddress = 'health-bi-polar-hospital-address',
  healthBiPolarHospitalVisitDays = 'health-bi-polar-hospital-visit-days',
  healthBiPolarDaysOffWork = 'health-bi-polar-days-off-work',
  healthSchizophreniaDiagnosisDate = 'health-schizophrenia-diagnosis-date',
  healthSchizophreniaMedications = 'health-schizophrenia-medications',
  healthSchizophreniaAdditionalTreatment = 'health-schizophrenia-additional-treatment',
  healthSchizophreniaStatus = 'health-schizophrenia-status',
  healthSchizophreniaDateOfFullRecovery = 'health-schizophrenia-date-of-full-recovery',
  healthSchizophreniaLimitationDetails = 'health-schizophrenia-limitation-details',
  healthSchizophreniaCurrentlyExperienceSymptoms = 'health-schizophrenia-currently-experience-symptoms',
  healthSchizophreniaDateOfLastSymptom = 'health-schizophrenia-date-of-last-symptom',
  healthSchizophreniaDateOfLastOccurrence = 'health-schizophrenia-date-of-last-occurrence',
  healthSchizophreniaNumberOfOccurrences = 'health-schizophrenia-number-of-occurrences',
  healthSchizophreniaCausePhysicalLimitations = 'health-schizophrenia-cause-physical-limitations',
  healthSchizophreniaLimitationDetails2 = 'health-schizophrenia-limitation-details-2',
  healthSchizophreniaMedProv = 'health-schizophrenia-med-prov',
  healthSchizophreniaMedProvName = 'health-schizophrenia-med-prov-name',
  healthSchizophreniaMedProvAddress = 'health-schizophrenia-med-prov-address',
  healthSchizophreniaMedProvPhone = 'health-schizophrenia-med-prov-phone',
  healthSchizophreniaHospitalVisit = 'health-schizophrenia-hospital-visit',
  healthSchizophreniaHospitalVisitDate = 'health-schizophrenia-hospital-visit-date',
  healthSchizophreniaHospitalName = 'health-schizophrenia-hospital-name',
  healthSchizophreniaHospitalAddress = 'health-schizophrenia-hospital-address',
  healthSchizophreniaHospitalVisitDays = 'health-schizophrenia-hospital-visit-days',
  healthSchizophreniaDaysOffWork = 'health-schizophrenia-days-off-work',
  healthPtsdDiagnosisDate = 'health-ptsd-diagnosis-date',
  healthPtsdCause = 'health-ptsd-cause',
  healthPtsdPhysician = 'health-ptsd-physician',
  healthPtsdDateLastConsultation = 'health-ptsd-date-last-consultation',
  healthPtsdPhysicianVisitsFrequency = 'health-ptsd-physician-visits-frequency',
  healthPtsdTreatment = 'health-ptsd-treatment',
  healthPtsdTreatmentDetails = 'health-ptsd-treatment-details',
  healthPtsdPsychotherapy = 'health-ptsd-psychotherapy',
  healthPtsdPsychotherapyDetails = 'health-ptsd-psychotherapy-details',
  healthPtsdSymptoms = 'health-ptsd-symptoms',
  healthPtsdOffWork = 'health-ptsd-off-work',
  healthPtsdOffWorkDetails = 'health-ptsd-off-work-details',
  healthPtsdDetails = 'health-ptsd-details',
  healthPtsdMedications = 'health-ptsd-medications',
  healthPtsdAdditionalTreatment = 'health-ptsd-additional-treatment',
  healthPtsdStatus = 'health-ptsd-status',
  healthPtsdDateOfFullRecovery = 'health-ptsd-date-of-full-recovery',
  healthPtsdLimitationDetails = 'health-ptsd-limitation-details',
  healthPtsdCurrentlyExperienceSymptoms = 'health-ptsd-currently-experience-symptoms',
  healthPtsdDateOfLastSymptom = 'health-ptsd-date-of-last-symptom',
  healthPtsdDateOfLastOccurrence = 'health-ptsd-date-of-last-occurrence',
  healthPtsdNumberOfOccurrences = 'health-ptsd-number-of-occurrences',
  healthPtsdCausePhysicalLimitations = 'health-ptsd-cause-physical-limitations',
  healthPtsdLimitationDetails2 = 'health-ptsd-limitation-details-2',
  healthPtsdMedProv = 'health-ptsd-med-prov',
  healthPtsdMedProvName = 'health-ptsd-med-prov-name',
  healthPtsdMedProvAddress = 'health-ptsd-med-prov-address',
  healthPtsdMedProvPhone = 'health-ptsd-med-prov-phone',
  healthPtsdHospitalVisit = 'health-ptsd-hospital-visit',
  healthPtsdHospitalVisitDate = 'health-ptsd-hospital-visit-date',
  healthPtsdHospitalName = 'health-ptsd-hospital-name',
  healthPtsdHospitalAddress = 'health-ptsd-hospital-address',
  healthPtsdHospitalVisitDays = 'health-ptsd-hospital-visit-days',
  healthPtsdDaysOffWork = 'health-ptsd-days-off-work',
  healthEmotionalDisorderDiagnosisDate = 'health-emotional-disorder-diagnosis-date',
  healthEmotionalDisorderMedications = 'health-emotional-disorder-medications',
  healthEmotionalDisorderAdditionalTreatment = 'health-emotional-disorder-additional-treatment',
  healthEmotionalDisorderStatus = 'health-emotional-disorder-status',
  healthEmotionalDisorderDateOfFullRecovery = 'health-emotional-disorder-date-of-full-recovery',
  healthEmotionalDisorderLimitationDetails = 'health-emotional-disorder-limitation-details',
  healthEmotionalDisorderCurrentlyExperienceSymptoms = 'health-emotional-disorder-currently-experience-symptoms',
  healthEmotionalDisorderDateOfLastSymptom = 'health-emotional-disorder-date-of-last-symptom',
  healthEmotionalDisorderDateOfLastOccurrence = 'health-emotional-disorder-date-of-last-occurrence',
  healthEmotionalDisorderNumberOfOccurrences = 'health-emotional-disorder-number-of-occurrences',
  healthEmotionalDisorderCausePhysicalLimitations = 'health-emotional-disorder-cause-physical-limitations',
  healthEmotionalDisorderLimitationDetails2 = 'health-emotional-disorder-limitation-details-2',
  healthEmotionalDisorderMedProv = 'health-emotional-disorder-med-prov',
  healthEmotionalDisorderMedProvName = 'health-emotional-disorder-med-prov-name',
  healthEmotionalDisorderMedProvAddress = 'health-emotional-disorder-med-prov-address',
  healthEmotionalDisorderMedProvPhone = 'health-emotional-disorder-med-prov-phone',
  healthEmotionalDisorderHospitalVisit = 'health-emotional-disorder-hospital-visit',
  healthEmotionalDisorderHospitalVisitDate = 'health-emotional-disorder-hospital-visit-date',
  healthEmotionalDisorderHospitalName = 'health-emotional-disorder-hospital-name',
  healthEmotionalDisorderHospitalAddress = 'health-emotional-disorder-hospital-address',
  healthEmotionalDisorderHospitalVisitDays = 'health-emotional-disorder-hospital-visit-days',
  healthEmotionalDisorderDaysOffWork = 'health-emotional-disorder-days-off-work',
  healthBehavioralDisorderDiagnosisDate = 'health-behavioral-disorder-diagnosis-date',
  healthBehavioralDisorderMedications = 'health-behavioral-disorder-medications',
  healthBehavioralDisorderAdditionalTreatment = 'health-behavioral-disorder-additional-treatment',
  healthBehavioralDisorderStatus = 'health-behavioral-disorder-status',
  healthBehavioralDisorderDateOfFullRecovery = 'health-behavioral-disorder-date-of-full-recovery',
  healthBehavioralDisorderLimitationDetails = 'health-behavioral-disorder-limitation-details',
  healthBehavioralDisorderCurrentlyExperienceSymptoms = 'health-behavioral-disorder-currently-experience-symptoms',
  healthBehavioralDisorderDateOfLastSymptom = 'health-behavioral-disorder-date-of-last-symptom',
  healthBehavioralDisorderDateOfLastOccurrence = 'health-behavioral-disorder-date-of-last-occurrence',
  healthBehavioralDisorderNumberOfOccurrences = 'health-behavioral-disorder-number-of-occurrences',
  healthBehavioralDisorderCausePhysicalLimitations = 'health-behavioral-disorder-cause-physical-limitations',
  healthBehavioralDisorderLimitationDetails2 = 'health-behavioral-disorder-limitation-details-2',
  healthBehavioralDisorderMedProv = 'health-behavioral-disorder-med-prov',
  healthBehavioralDisorderMedProvName = 'health-behavioral-disorder-med-prov-name',
  healthBehavioralDisorderMedProvAddress = 'health-behavioral-disorder-med-prov-address',
  healthBehavioralDisorderMedProvPhone = 'health-behavioral-disorder-med-prov-phone',
  healthBehavioralDisorderHospitalVisit = 'health-behavioral-disorder-hospital-visit',
  healthBehavioralDisorderHospitalVisitDate = 'health-behavioral-disorder-hospital-visit-date',
  healthBehavioralDisorderHospitalName = 'health-behavioral-disorder-hospital-name',
  healthBehavioralDisorderHospitalAddress = 'health-behavioral-disorder-hospital-address',
  healthBehavioralDisorderHospitalVisitDays = 'health-behavioral-disorder-hospital-visit-days',
  healthBehavioralDisorderDaysOffWork = 'health-behavioral-disorder-days-off-work',
  healthMentalDisorderDiagnosisDate = 'health-mental-disorder-diagnosis-date',
  healthMentalDisorderMedications = 'health-mental-disorder-medications',
  healthMentalDisorderAdditionalTreatment = 'health-mental-disorder-additional-treatment',
  healthMentalDisorderStatus = 'health-mental-disorder-status',
  healthMentalDisorderDateOfFullRecovery = 'health-mental-disorder-date-of-full-recovery',
  healthMentalDisorderLimitationDetails = 'health-mental-disorder-limitation-details',
  healthMentalDisorderCurrentlyExperienceSymptoms = 'health-mental-disorder-currently-experience-symptoms',
  healthMentalDisorderDateOfLastSymptom = 'health-mental-disorder-date-of-last-symptom',
  healthMentalDisorderDateOfLastOccurrence = 'health-mental-disorder-date-of-last-occurrence',
  healthMentalDisorderNumberOfOccurrences = 'health-mental-disorder-number-of-occurrences',
  healthMentalDisorderCausePhysicalLimitations = 'health-mental-disorder-cause-physical-limitations',
  healthMentalDisorderLimitationDetails2 = 'health-mental-disorder-limitation-details-2',
  healthMentalDisorderMedProv = 'health-mental-disorder-med-prov',
  healthMentalDisorderMedProvName = 'health-mental-disorder-med-prov-name',
  healthMentalDisorderMedProvAddress = 'health-mental-disorder-med-prov-address',
  healthMentalDisorderMedProvPhone = 'health-mental-disorder-med-prov-phone',
  healthMentalDisorderHospitalVisit = 'health-mental-disorder-hospital-visit',
  healthMentalDisorderHospitalVisitDate = 'health-mental-disorder-hospital-visit-date',
  healthMentalDisorderHospitalName = 'health-mental-disorder-hospital-name',
  healthMentalDisorderHospitalAddress = 'health-mental-disorder-hospital-address',
  healthMentalDisorderHospitalVisitDays = 'health-mental-disorder-hospital-visit-days',
  healthMentalDisorderDaysOffWork = 'health-mental-disorder-days-off-work',
  healthNervousDisorderDiagnosisDate = 'health-nervous-disorder-diagnosis-date',
  healthNervousDisorderMedications = 'health-nervous-disorder-medications',
  healthNervousDisorderAdditionalTreatment = 'health-nervous-disorder-additional-treatment',
  healthNervousDisorderStatus = 'health-nervous-disorder-status',
  healthNervousDisorderDateOfFullRecovery = 'health-nervous-disorder-date-of-full-recovery',
  healthNervousDisorderLimitationDetails = 'health-nervous-disorder-limitation-details',
  healthNervousDisorderCurrentlyExperienceSymptoms = 'health-nervous-disorder-currently-experience-symptoms',
  healthNervousDisorderDateOfLastSymptom = 'health-nervous-disorder-date-of-last-symptom',
  healthNervousDisorderDateOfLastOccurrence = 'health-nervous-disorder-date-of-last-occurrence',
  healthNervousDisorderNumberOfOccurrences = 'health-nervous-disorder-number-of-occurrences',
  healthNervousDisorderCausePhysicalLimitations = 'health-nervous-disorder-cause-physical-limitations',
  healthNervousDisorderLimitationDetails2 = 'health-nervous-disorder-limitation-details-2',
  healthNervousDisorderMedProv = 'health-nervous-disorder-med-prov',
  healthNervousDisorderMedProvName = 'health-nervous-disorder-med-prov-name',
  healthNervousDisorderMedProvAddress = 'health-nervous-disorder-med-prov-address',
  healthNervousDisorderMedProvPhone = 'health-nervous-disorder-med-prov-phone',
  healthNervousDisorderHospitalVisit = 'health-nervous-disorder-hospital-visit',
  healthNervousDisorderHospitalVisitDate = 'health-nervous-disorder-hospital-visit-date',
  healthNervousDisorderHospitalName = 'health-nervous-disorder-hospital-name',
  healthNervousDisorderHospitalAddress = 'health-nervous-disorder-hospital-address',
  healthNervousDisorderHospitalVisitDays = 'health-nervous-disorder-hospital-visit-days',
  healthNervousDisorderDaysOffWork = 'health-nervous-disorder-days-off-work',
  healthAidsRisk = 'health-aids-risk',
  healthAutoImmuneDiagnosis = 'health-auto-immune-diagnosis',
  healthAutoImmuneChoices = 'health-auto-immune-choices',
  healthAutoImmuneDiagnosisDate = 'health-auto-immune-diagnosis-date',
  healthAutoImmuneMedications = 'health-auto-immune-medications',
  healthAutoImmuneAdditionalTreatment = 'health-auto-immune-additional-treatment',
  healthAutoImmuneStatus = 'health-auto-immune-status',
  healthAutoImmuneDateOfFullRecovery = 'health-auto-immune-date-of-full-recovery',
  healthAutoImmuneLimitationDetails = 'health-auto-immune-limitation-details',
  healthAutoImmuneCurrentlyExperienceSymptoms = 'health-auto-immune-currently-experience-symptoms',
  healthAutoImmuneDateOfLastSymptom = 'health-auto-immune-date-of-last-symptom',
  healthAutoImmuneDateOfLastOccurrence = 'health-auto-immune-date-of-last-occurrence',
  healthAutoImmuneNumberOfOccurrences = 'health-auto-immune-number-of-occurrences',
  healthAutoImmuneCausePhysicalLimitations = 'health-auto-immune-cause-physical-limitations',
  healthAutoImmuneLimitationDetails2 = 'health-auto-immune-limitation-details-2',
  healthAutoImmuneMedProv = 'health-auto-immune-med-prov',
  healthAutoImmuneMedProvName = 'health-auto-immune-med-prov-name',
  healthAutoImmuneMedProvAddress = 'health-auto-immune-med-prov-address',
  healthAutoImmuneMedProvPhone = 'health-auto-immune-med-prov-phone',
  healthAutoImmuneHospitalVisit = 'health-auto-immune-hospital-visit',
  healthAutoImmuneHospitalVisitDate = 'health-auto-immune-hospital-visit-date',
  healthAutoImmuneHospitalName = 'health-auto-immune-hospital-name',
  healthAutoImmuneHospitalAddress = 'health-auto-immune-hospital-address',
  healthArthritisDiagnosisDate = 'health-arthritis-diagnosis-date',
  healthArthritisMedications = 'health-arthritis-medications',
  healthArthritisAdditionalTreatment = 'health-arthritis-additional-treatment',
  healthArthritisStatus = 'health-arthritis-status',
  healthArthritisDateOfFullRecovery = 'health-arthritis-date-of-full-recovery',
  healthArthritisLimitationDetails = 'health-arthritis-limitation-details',
  healthArthritisCurrentlyExperienceSymptoms = 'health-arthritis-currently-experience-symptoms',
  healthArthritisDateOfLastSymptom = 'health-arthritis-date-of-last-symptom',
  healthArthritisDateOfLastOccurrence = 'health-arthritis-date-of-last-occurrence',
  healthArthritisNumberOfOccurrences = 'health-arthritis-number-of-occurrences',
  healthArthritisCausePhysicalLimitations = 'health-arthritis-cause-physical-limitations',
  healthArthritisLimitationDetails2 = 'health-arthritis-limitation-details-2',
  healthArthritisMedProv = 'health-arthritis-med-prov',
  healthArthritisMedProvName = 'health-arthritis-med-prov-name',
  healthArthritisMedProvAddress = 'health-arthritis-med-prov-address',
  healthArthritisMedProvPhone = 'health-arthritis-med-prov-phone',
  healthArthritisHospitalVisit = 'health-arthritis-hospital-visit',
  healthArthritisHospitalVisitDate = 'health-arthritis-hospital-visit-date',
  healthArthritisHospitalName = 'health-arthritis-hospital-name',
  healthArthritisHospitalAddress = 'health-arthritis-hospital-address',
  healthLupusDiagnosisDate = 'health-lupus-diagnosis-date',
  healthLupusMedications = 'health-lupus-medications',
  healthLupusAdditionalTreatment = 'health-lupus-additional-treatment',
  healthLupusStatus = 'health-lupus-status',
  healthLupusDateOfFullRecovery = 'health-lupus-date-of-full-recovery',
  healthLupusLimitationDetails = 'health-lupus-limitation-details',
  healthLupusCurrentlyExperienceSymptoms = 'health-lupus-currently-experience-symptoms',
  healthLupusDateOfLastSymptom = 'health-lupus-date-of-last-symptom',
  healthLupusDateOfLastOccurrence = 'health-lupus-date-of-last-occurrence',
  healthLupusNumberOfOccurrences = 'health-lupus-number-of-occurrences',
  healthLupusCausePhysicalLimitations = 'health-lupus-cause-physical-limitations',
  healthLupusLimitationDetails2 = 'health-lupus-limitation-details-2',
  healthLupusMedProv = 'health-lupus-med-prov',
  healthLupusMedProvName = 'health-lupus-med-prov-name',
  healthLupusMedProvAddress = 'health-lupus-med-prov-address',
  healthLupusMedProvPhone = 'health-lupus-med-prov-phone',
  healthLupusHospitalVisit = 'health-lupus-hospital-visit',
  healthLupusHospitalVisitDate = 'health-lupus-hospital-visit-date',
  healthLupusHospitalName = 'health-lupus-hospital-name',
  healthLupusHospitalAddress = 'health-lupus-hospital-address',
  healthInsuredSclerodermaDetails = 'health-insured-scleroderma-details',
  healthInsuredAidsDetails = 'health-insured-aids-details',
  healthInsuredHivDetails = 'health-insured-hiv-details',
  healthInsuredLupusDetails = 'health-insured-lupus-details',
  healthInsuredOtherAutoImmuneSystemDisorderDetails = 'health-insured-other-auto-immune-system-disorder-details',
  healthInsuredAutoImmuneSystemHasCondition = 'health-insured-auto-immune-system-has-condition',
  healthInsuredAutoImmuneSystemChoices = 'health-insured-auto-immune-system-choices',
  healthConnectiveTissueDiseaseDiagnosisDate = 'health-connective-tissue-disease-diagnosis-date',
  healthConnectiveTissueDiseaseMedications = 'health-connective-tissue-disease-medications',
  healthConnectiveTissueDiseaseAdditionalTreatment = 'health-connective-tissue-disease-additional-treatment',
  healthConnectiveTissueDiseaseStatus = 'health-connective-tissue-disease-status',
  healthConnectiveTissueDiseaseDateOfFullRecovery = 'health-connective-tissue-disease-date-of-full-recovery',
  healthConnectiveTissueDiseaseLimitationDetails = 'health-connective-tissue-disease-limitation-details',
  healthConnectiveTissueDiseaseCurrentlyExperienceSymptoms = 'health-connective-tissue-disease-currently-experience-symptoms',
  healthConnectiveTissueDiseaseDateOfLastSymptom = 'health-connective-tissue-disease-date-of-last-symptom',
  healthConnectiveTissueDiseaseDateOfLastOccurrence = 'health-connective-tissue-disease-date-of-last-occurrence',
  healthConnectiveTissueDiseaseNumberOfOccurrences = 'health-connective-tissue-disease-number-of-occurrences',
  healthConnectiveTissueDiseaseCausePhysicalLimitations = 'health-connective-tissue-disease-cause-physical-limitations',
  healthConnectiveTissueDiseaseLimitationDetails2 = 'health-connective-tissue-disease-limitation-details-2',
  healthConnectiveTissueDiseaseMedProv = 'health-connective-tissue-disease-med-prov',
  healthConnectiveTissueDiseaseMedProvName = 'health-connective-tissue-disease-med-prov-name',
  healthConnectiveTissueDiseaseMedProvAddress = 'health-connective-tissue-disease-med-prov-address',
  healthConnectiveTissueDiseaseMedProvPhone = 'health-connective-tissue-disease-med-prov-phone',
  healthConnectiveTissueDiseaseHospitalVisit = 'health-connective-tissue-disease-hospital-visit',
  healthConnectiveTissueDiseaseHospitalVisitDate = 'health-connective-tissue-disease-hospital-visit-date',
  healthConnectiveTissueDiseaseHospitalName = 'health-connective-tissue-disease-hospital-name',
  healthConnectiveTissueDiseaseHospitalAddress = 'health-connective-tissue-disease-hospital-address',
  healthInjuryBonesDiagnosisDate = 'health-injury-bones-diagnosis-date',
  healthInjuryBonesMedications = 'health-injury-bones-medications',
  healthInjuryBonesAdditionalTreatment = 'health-injury-bones-additional-treatment',
  healthInjuryBonesStatus = 'health-injury-bones-status',
  healthInjuryBonesDateOfFullRecovery = 'health-injury-bones-date-of-full-recovery',
  healthInjuryBonesLimitationDetails = 'health-injury-bones-limitation-details',
  healthInjuryBonesCurrentlyExperienceSymptoms = 'health-injury-bones-currently-experience-symptoms',
  healthInjuryBonesDateOfLastSymptom = 'health-injury-bones-date-of-last-symptom',
  healthInjuryBonesDateOfLastOccurrence = 'health-injury-bones-date-of-last-occurrence',
  healthInjuryBonesNumberOfOccurrences = 'health-injury-bones-number-of-occurrences',
  healthInjuryBonesCausePhysicalLimitations = 'health-injury-bones-cause-physical-limitations',
  healthInjuryBonesLimitationDetails2 = 'health-injury-bones-limitation-details-2',
  healthInjuryBonesMedProv = 'health-injury-bones-med-prov',
  healthInjuryBonesMedProvName = 'health-injury-bones-med-prov-name',
  healthInjuryBonesMedProvAddress = 'health-injury-bones-med-prov-address',
  healthInjuryBonesMedProvPhone = 'health-injury-bones-med-prov-phone',
  healthInjuryBonesHospitalVisit = 'health-injury-bones-hospital-visit',
  healthInjuryBonesHospitalVisitDate = 'health-injury-bones-hospital-visit-date',
  healthInjuryBonesHospitalName = 'health-injury-bones-hospital-name',
  healthInjuryBonesHospitalAddress = 'health-injury-bones-hospital-address',
  healthInjuryMusclesDiagnosisDate = 'health-injury-muscles-diagnosis-date',
  healthInjuryMusclesMedications = 'health-injury-muscles-medications',
  healthInjuryMusclesAdditionalTreatment = 'health-injury-muscles-additional-treatment',
  healthInjuryMusclesStatus = 'health-injury-muscles-status',
  healthInjuryMusclesDateOfFullRecovery = 'health-injury-muscles-date-of-full-recovery',
  healthInjuryMusclesLimitationDetails = 'health-injury-muscles-limitation-details',
  healthInjuryMusclesCurrentlyExperienceSymptoms = 'health-injury-muscles-currently-experience-symptoms',
  healthInjuryMusclesDateOfLastSymptom = 'health-injury-muscles-date-of-last-symptom',
  healthInjuryMusclesDateOfLastOccurrence = 'health-injury-muscles-date-of-last-occurrence',
  healthInjuryMusclesNumberOfOccurrences = 'health-injury-muscles-number-of-occurrences',
  healthInjuryMusclesCausePhysicalLimitations = 'health-injury-muscles-cause-physical-limitations',
  healthInjuryMusclesLimitationDetails2 = 'health-injury-muscles-limitation-details-2',
  healthInjuryMusclesMedProv = 'health-injury-muscles-med-prov',
  healthInjuryMusclesMedProvName = 'health-injury-muscles-med-prov-name',
  healthInjuryMusclesMedProvAddress = 'health-injury-muscles-med-prov-address',
  healthInjuryMusclesMedProvPhone = 'health-injury-muscles-med-prov-phone',
  healthInjuryMusclesHospitalVisit = 'health-injury-muscles-hospital-visit',
  healthInjuryMusclesHospitalVisitDate = 'health-injury-muscles-hospital-visit-date',
  healthInjuryMusclesHospitalName = 'health-injury-muscles-hospital-name',
  healthInjuryMusclesHospitalAddress = 'health-injury-muscles-hospital-address',
  healthHasNervousSystemIssuesSymptoms = 'health-has-nervous-system-issues-symptoms',
  healthHasNervousSystemIssuesChoices = 'health-has-nervous-system-issues-choices',
  healthInsuredEpilepsyHasCondition = 'health-insured-epilepsy-has-condition',
  healthInsuredEpilepsyDetails = 'health-insured-epilepsy-details',
  healthEpilepsyFirstDate = 'health-epilepsy-first-date',
  healthEpilepsyOccur = 'health-epilepsy-occur',
  healthEpilepsyLastDate = 'health-epilepsy-last-date',
  healthEpilepsyType = 'health-epilepsy-type',
  healthEpilepsyOther = 'health-epilepsy-other',
  healthEpilepsyDiagnosisDate = 'health-epilepsy-diagnosis-date',
  healthEpilepsyMedications = 'health-epilepsy-medications',
  healthEpilepsyAdditionalTreatment = 'health-epilepsy-additional-treatment',
  healthEpilepsyStatus = 'health-epilepsy-status',
  healthEpilepsyDateOfFullRecovery = 'health-epilepsy-date-of-full-recovery',
  healthEpilepsyLimitationDetails = 'health-epilepsy-limitation-details',
  healthEpilepsyCurrentlyExperienceSymptoms = 'health-epilepsy-currently-experience-symptoms',
  healthEpilepsyDateOfLastSymptom = 'health-epilepsy-date-of-last-symptom',
  healthEpilepsyDateOfLastOccurrence = 'health-epilepsy-date-of-last-occurrence',
  healthEpilepsyNumberOfOccurrences = 'health-epilepsy-number-of-occurrences',
  healthEpilepsyCausePhysicalLimitations = 'health-epilepsy-cause-physical-limitations',
  healthEpilepsyLimitationDetails2 = 'health-epilepsy-limitation-details-2',
  healthEpilepsyMedProv = 'health-epilepsy-med-prov',
  healthEpilepsyMedProvName = 'health-epilepsy-med-prov-name',
  healthEpilepsyMedProvAddress = 'health-epilepsy-med-prov-address',
  healthEpilepsyMedProvPhone = 'health-epilepsy-med-prov-phone',
  healthEpilepsyMedicationNumber = 'health-epilepsy-medication-number',
  healthEpilepsyMedicationName = 'health-epilepsy-medication-name',
  healthEpilepsyHospitalVisit = 'health-epilepsy-hospital-visit',
  healthEpilepsyHospitalVisitDate = 'health-epilepsy-hospital-visit-date',
  healthEpilepsyHospitalName = 'health-epilepsy-hospital-name',
  healthEpilepsyHospitalAddress = 'health-epilepsy-hospital-address',
  healthEpilepsyEmergencyRoomVisit = 'health-epilepsy-emergency-room-visit',
  healthEpilepsyEmergencyRoomDate = 'health-epilepsy-emergency-room-date',
  healthEpilepsyEmergencyRoomName = 'health-epilepsy-emergency-room-name',
  healthEpilepsyEmergencyRoomAddress = 'health-epilepsy-emergency-room-address',
  healthDizzinessDiagnosisDate = 'health-dizziness-diagnosis-date',
  healthDizzinessMedications = 'health-dizziness-medications',
  healthDizzinessAdditionalTreatment = 'health-dizziness-additional-treatment',
  healthDizzinessStatus = 'health-dizziness-status',
  healthDizzinessDateOfFullRecovery = 'health-dizziness-date-of-full-recovery',
  healthDizzinessLimitationDetails = 'health-dizziness-limitation-details',
  healthDizzinessCurrentlyExperienceSymptoms = 'health-dizziness-currently-experience-symptoms',
  healthDizzinessDateOfLastSymptom = 'health-dizziness-date-of-last-symptom',
  healthDizzinessDateOfLastOccurrence = 'health-dizziness-date-of-last-occurrence',
  healthDizzinessNumberOfOccurrences = 'health-dizziness-number-of-occurrences',
  healthDizzinessCausePhysicalLimitations = 'health-dizziness-cause-physical-limitations',
  healthDizzinessLimitationDetails2 = 'health-dizziness-limitation-details-2',
  healthDizzinessMedProv = 'health-dizziness-med-prov',
  healthDizzinessMedProvName = 'health-dizziness-med-prov-name',
  healthDizzinessMedProvAddress = 'health-dizziness-med-prov-address',
  healthDizzinessMedProvPhone = 'health-dizziness-med-prov-phone',
  healthDizzinessHospitalVisit = 'health-dizziness-hospital-visit',
  healthDizzinessHospitalVisitDate = 'health-dizziness-hospital-visit-date',
  healthDizzinessHospitalName = 'health-dizziness-hospital-name',
  healthDizzinessHospitalAddress = 'health-dizziness-hospital-address',

  healthEarDiseaseDiagnosisDate = 'health-ear-disease-diagnosis-date',
  healthEarDiseaseMedications = 'health-ear-disease-medications',
  healthEarDiseaseAdditionalTreatment = 'health-ear-disease-additional-treatment',
  healthEarDiseaseStatus = 'health-ear-disease-status',
  healthEarDiseaseDateOfFullRecovery = 'health-ear-disease-date-of-full-recovery',
  healthEarDiseaseLimitationDetails = 'health-ear-disease-limitation-details',
  healthEarDiseaseCurrentlyExperienceSymptoms = 'health-ear-disease-currently-experience-symptoms',
  healthEarDiseaseDateOfLastSymptom = 'health-ear-disease-date-of-last-symptom',
  healthEarDiseaseDateOfLastOccurrence = 'health-ear-disease-date-of-last-occurrence',
  healthEarDiseaseNumberOfOccurrences = 'health-ear-disease-number-of-occurrences',
  healthEarDiseaseCausePhysicalLimitations = 'health-ear-disease-cause-physical-limitations',
  healthEarDiseaseLimitationDetails2 = 'health-ear-disease-limitation-details-2',
  healthEarDiseaseMedProv = 'health-ear-disease-med-prov',
  healthEarDiseaseMedProvName = 'health-ear-disease-med-prov-name',
  healthEarDiseaseMedProvAddress = 'health-ear-disease-med-prov-address',
  healthEarDiseaseMedProvPhone = 'health-ear-disease-med-prov-phone',
  healthEarDiseaseHospitalVisit = 'health-ear-disease-hospital-visit',
  healthEarDiseaseHospitalVisitDate = 'health-ear-disease-hospital-visit-date',
  healthEarDiseaseHospitalName = 'health-ear-disease-hospital-name',
  healthEarDiseaseHospitalAddress = 'health-ear-disease-hospital-address',
  healthHasGastrointestinalDisease = 'health-gastrointestinal-disease',
  healthGastrointestinalDiseaseChoices = 'health-gastrointestinal-disease-choices',
  healthStomachDisorderDiagnosisDate = 'health-stomach-disorder-diagnosis-date',
  healthStomachDisorderMedications = 'health-stomach-disorder-medications',
  healthStomachDisorderAdditionalTreatment = 'health-stomach-disorder-additional-treatment',
  healthStomachDisorderStatus = 'health-stomach-disorder-status',
  healthStomachDisorderDateOfFullRecovery = 'health-stomach-disorder-date-of-full-recovery',
  healthStomachDisorderLimitationDetails = 'health-stomach-disorder-limitation-details',
  healthStomachDisorderCurrentlyExperienceSymptoms = 'health-stomach-disorder-currently-experience-symptoms',
  healthStomachDisorderDateOfLastSymptom = 'health-stomach-disorder-date-of-last-symptom',
  healthStomachDisorderDateOfLastOccurrence = 'health-stomach-disorder-date-of-last-occurrence',
  healthStomachDisorderNumberOfOccurrences = 'health-stomach-disorder-number-of-occurrences',
  healthStomachDisorderCausePhysicalLimitations = 'health-stomach-disorder-cause-physical-limitations',
  healthStomachDisorderLimitationDetails2 = 'health-stomach-disorder-limitation-details-2',
  healthStomachDisorderMedProv = 'health-stomach-disorder-med-prov',
  healthStomachDisorderMedProvName = 'health-stomach-disorder-med-prov-name',
  healthStomachDisorderMedProvAddress = 'health-stomach-disorder-med-prov-address',
  healthStomachDisorderMedProvPhone = 'health-stomach-disorder-med-prov-phone',
  healthStomachDisorderHospitalVisit = 'health-stomach-disorder-hospital-visit',
  healthStomachDisorderHospitalVisitDate = 'health-stomach-disorder-hospital-visit-date',
  healthStomachDisorderHospitalName = 'health-stomach-disorder-hospital-name',
  healthStomachDisorderHospitalAddress = 'health-stomach-disorder-hospital-address',

  healthOtherCancerType = 'health-other-cancer-type',
  healthOtherCancerDiagnosisDate = 'health-other-cancer-diagnosis-date',
  healthOtherCancerStage = 'health-other-cancer-stage',
  healthOtherCancerMedications = 'health-other-cancer-medications',
  healthOtherCancerTreatment = 'health-other-cancer-treatment',
  healthOtherCancerTreatmentDate = 'health-other-cancer-treatment-date',
  healthOtherCancerAdditionalTreatment = 'health-other-cancer-additional-treatment',
  healthOtherCancerStatus = 'health-other-cancer-status',
  healthOtherCancerFullRecoveryDate = 'health-other-cancer-full-recovery-date',
  healthOtherCancerLimitationDetails = 'health-other-cancer-limitation-details',
  healthOtherCancerCurrentSymptoms = 'health-other-cancer-current-symptoms',
  healthOtherCancerLastSymptomDate = 'health-other-cancer-last-symptom-date',
  healthOtherCancerLastOccurrenceDate = 'health-other-cancer-last-occurrence-date',
  healthOtherCancerNumberOfOccurrences = 'health-other-cancer-number-of-occurrences',
  healthOtherCancerPhysicalLimitations = 'health-other-cancer-physical-limitations',
  healthOtherCancerPhysicalLimitationsDetails = 'health-other-cancer-physical-limitations-details',
  healthOtherCancerMedProvider = 'health-other-cancer-med-provider',
  healthOtherCancerMedProviderName = 'health-other-cancer-med-provider-name',
  healthOtherCancerMedProviderAddress = 'health-other-cancer-med-provider-address',
  healthOtherCancerMedProviderPhone = 'health-other-cancer-med-provider-phone',
  healthOtherCancerHospitalVisit = 'health-other-cancer-hospital-visit',
  healthOtherCancerHospitalVisitDate = 'health-other-cancer-hospital-visit-date',
  healthOtherCancerHospitalName = 'health-other-cancer-hospital-name',
  healthOtherCancerHospitalAddress = 'health-other-cancer-hospital-address',

  healthHeartCirculatory = 'health-heart-circulatory',
  healthHeartCirculatorySystemChoices = 'health-heart-circulatory-system-choices',
  healthAbnormalBloodPressureDiagnosisDate = 'health-abnormal-blood-pressure-diagnosis-date',
  healthBloodPressureLastSystolicReading = 'health-blood-pressure-last-systolic-reading',
  healthBloodPressureLastDiastolicReading = 'health-blood-pressure-last-diastolic-reading',
  healthBloodPressureAbnormalReadingLast03Years = 'health-blood-pressure-abnormal-reading-last-03-years',
  healthBloodPressureMedicationCount = 'health-blood-pressure-medication-count',
  healthBloodPressureMedicationList = 'health-blood-pressure-medication-list',
  healthBloodPressureControlled = 'health-blood-pressure-controlled',
  healthBloodPressureHospitalVisit = 'health-blood-pressure-hospital-visit',
  healthBloodPressureHospitalName = 'health-blood-pressure-hospital-name',
  healthBloodPressureHospitalAddress = 'health-blood-pressure-hospital-address',
  healthBloodPressureHospitalizationDate = 'health-blood-pressure-hospitalization-date',
  healthStrokeTiaEpisodes = 'health-stroke-tia-episodes',
  healthStrokeTiaDateOne = 'health-stroke-tia-date-one',
  healthStrokeTiaDateTwo = 'health-stroke-tia-date-two',
  healthStrokeTiaDateThree = 'health-stroke-tia-date-three',
  healthStrokeTiaDateFour = 'health-stroke-tia-date-four',
  healthStrokeTiaDateFive = 'health-stroke-tia-date-five',
  healthStrokeTiaDateSix = 'health-stroke-tia-date-six',
  healthStrokeTiaDateSeven = 'health-stroke-tia-date-seven',
  healthStrokeTiaDateEight = 'health-stroke-tia-date-eight',
  healthStrokeTiaDateNine = 'health-stroke-tia-date-nine',
  healthStrokeTiaDateTen = 'health-stroke-tia-date-ten',
  healthCirculatorySystemTotalCholesterol = 'health-circulatory-system-total-cholesterol',
  healthTotalCholesterolReducedInPastTwelveMonths = 'health-total-cholesterol-reduced-in-past-twelve-months',
  healthCirculatorySystemCurrentCholesterol = 'health-circulatory-system-current-cholesterol',
  healthCirculatorySystemCholesterolMedications = 'health-circulatory-system-cholesterol-medications',
  healthCirculatorySystemCholesterolHospitalVisit = 'health-circulatory-system-cholesterol-hospital-visit',
  healthCirculatorySystemCholesterolHospitalName = 'health-circulatory-system-cholesterol-hospital-name',
  healthCirculatorySystemCholesterolHospitalAddress = 'health-circulatory-system-cholesterol-hospital-address',
  healthCirculatorySystemCholesterolHospitalVisitDate = 'health-circulatory-system-cholesterol-hospital-visit-date',

  healthOwnerCirculatorySystemHasCondition = 'health-owner-circulatory-system-has-condition',
  healthOwnerCirculatorySystemChoices = 'health-owner-circulatory-system-choices',
  healthOwnerHighBloodPressureMedicationWasPrescribed = 'health-owner-high-blood-pressure-medication-was-prescribed',
  healthOwnerHighBloodPressureMedicationDetails = 'health-owner-high-blood-pressure-medication-details',
  healthOwnerHighBloodPressureNoMedicationReason = 'health-owner-high-blood-pressure-no-medication-reason',
  healthOwnerHighBloodPressureDiagnosisDate = 'health-owner-high-blood-pressure-diagnosis-date',
  healthOwnerHighBloodPressureIsControlled = 'health-owner-high-blood-pressure-is-controlled',
  healthOwnerAbnormalBloodPressureDetails = 'health-owner-abnormal-blood-pressure-details',
  healthOwnerHighCholesterolMedicationWasPrescribed = 'health-owner-high-cholesterol-medication-was-prescribed',
  healthOwnerHighCholesterolMedicationDetails = 'health-owner-high-cholesterol-medication-details',
  healthOwnerHighCholesterolNoMedicationReason = 'health-owner-high-cholesterol-no-medication-reason',
  healthOwnerHighCholesterolDiagnosisDate = 'health-owner-high-cholesterol-diagnosis-date',
  healthOwnerHighCholesterolIsControlled = 'health-owner-high-cholesterol-is-controlled',
  healthOwnerCirculatorySystemCholesterolDetails = 'health-owner-circulatory-system-cholesterol-details',
  healthOwnerAneurysmDetails = 'health-owner-aneurysm-details',
  healthOwnerHeartAttackDetails = 'health-owner-heart-attack-details',
  healthOwnerAnginaDetails = 'health-owner-angina-details',
  healthOwnerTiaDetails = 'health-owner-tia-details',
  healthOwnerCvaDetails = 'health-owner-cva-details',
  healthOwnerHeartMurmurDetails = 'health-owner-heart-murmur-details',
  healthOwnerChestPainDetails = 'health-owner-chest-pain-details',
  healthOwnerPalpitationsDetails = 'health-owner-palpitations-details',
  healthOwnerAnyOtherHeartDisorderDetails = 'health-owner-any-other-heart-disorder-details',
  healthOwnerRespiratoryHasCondition = 'health-owner-respiratory-has-condition',
  healthOwnerRespiratoryChoices = 'health-owner-respiratory-choices',
  healthOwnerObstructiveSleepApneaDiagnosisDate = 'health-owner-obstructive-sleep-apnea-diagnosis-date',
  healthOwnerCpapTreatment = 'health-owner-cpap-treatment',
  healthOwnerCpapTreatmentUsageHours = 'health-owner-cpap-treatment-usage-hours',
  healthOwnerCpapTreatmentUsageWeek = 'health-owner-cpap-treatment-usage-week',
  healthOwnerCpapTreatmentIsControlled = 'health-owner-cpap-treatment-is-controlled',
  healthOwnerNoCpapTreatmentReason = 'health-owner-no-cpap-treatment-reason',
  healthOwnerNoCpapTreatmentReasonOtherTreatment = 'health-owner-no-cpap-treatment-reason-other-treatment',
  healthOwnerNoCpapTreatmentReasonOther = 'health-owner-no-cpap-treatment-reason-other',
  healthOwnerSleepApneaSymptomsSeverity = 'health-owner-sleep-apnea-symptoms-severity',
  healthOwnerAsthmaDetails = 'health-owner-asthma-details',
  healthOwnerChronicBronchitisDetails = 'health-owner-chronic-bronchitis-details',
  healthOwnerEmphysemaDetails = 'health-owner-emphysema-details',
  healthOwnerCopdDetails = 'health-owner-copd-details',
  healthOwnerCysticFibrosisDetails = 'health-owner-cystic-fibrosis-details',
  healthOwnerSarcoidosisDetails = 'health-owner-sarcoidosis-details',
  healthOwnerCoughingUpBloodDetails = 'health-owner-coughing-up-blood-details',
  healthOwnerShortnessOfBreathDetails = 'health-owner-shortness-of-breath-details',
  healthOwnerAnyOtherRespiratoryDisorderDetails = 'health-owner-any-other-respiratory-disorder-details',
  healthOwnerDigestiveSystemHasCondition = 'health-owner-digestive-system-has-condition',
  healthOwnerDigestiveSystemChoices = 'health-owner-digestive-system-choices',
  healthOwnerGerdIsControlled = 'health-owner-gerd-is-controlled',
  healthOwnerGerdConsulted = 'health-owner-gerd-consulted',
  healthOwnerGerdDetails = 'health-owner-gerd-details',
  healthOwnerGerdComplications = 'health-owner-gerd-complications',
  healthOwnerGerdComplicationsDetails = 'health-owner-gerd-complications-details',
  healthOwnerGallstonesDiagnosisDate = 'health-owner-gallstones-diagnosis-date',
  healthOwnerGallstonesHadOperation = 'health-owner-gallstones-had-operation',
  healthOwnerGallstonesOperationDate = 'health-owner-gallstones-operation-date',
  healthOwnerGallstonesPastSurgeryRecovery = 'health-owner-gallstones-past-surgery-recovery',
  healthOwnerGallstonesPendingSurgery = 'health-owner-gallstones-pending-surgery',
  healthOwnerGallstonesPendingSurgeryDate = 'health-owner-gallstones-pending-surgery-date',
  healthOwnerGallstonesPendingSurgeryUnknownDate = 'health-owner-gallstones-pending-surgery-unknown-date',
  healthOwnerCrohnsDiseaseDetails = 'health-owner-crohns-disease-details',
  healthOwnerUlcerativeColitisDetails = 'health-owner-ulcerative-colitis-details',
  healthOwnerCeliacDiseaseDetails = 'health-owner-celiac-disease-details',
  healthOwnerPolypsDetails = 'health-owner-polyps-details',
  healthOwnerCancerPolypsDetails = 'health-owner-cancer-polyps-details',
  healthOwnerHepatitisDetails = 'health-owner-hepatitis-details',
  healthOwnerCirrhosisDetails = 'health-owner-cirrhosis-details',
  healthOwnerPancreatitisDetails = 'health-owner-pancreatitis-details',
  healthOwnerUlcersDetails = 'health-owner-ulcers-details',
  healthOwnerGallbladderDisorderDetails = 'health-owner-gallbladder-disorder-details',
  healthOwnerBleedingDetails = 'health-owner-bleeding-details',
  healthOwnerAnyOtherDisorderOfTheDigestiveSystemDetails = 'health-owner-any-other-disorder-of-the-digestive-system-details',
  healthOwnerGenitourinaryHasCondition = 'health-owner-genitourinary-has-condition',
  healthOwnerGenitourinaryChoices = 'health-owner-genitourinary-choices',
  healthOwnerGenitourinaryOtherDetails = 'health-owner-genitourinary-other-details',
  healthOwnerKidneyStoneLastEpisode = 'health-owner-kidney-stone-last-episode',
  healthOwnerKidneyStoneEpisodesCount = 'health-owner-kidney-stone-episodes-count',
  healthOwnerKidneyStoneHasRecovered = 'health-owner-kidney-stone-has-recovered',
  healthOwnerUrineAbnormalitiesDetails = 'health-owner-urine-abnormalities-details',
  healthOwnerDisordersOfTheKidneyDetails = 'health-owner-disorders-of-the-kidney-details',
  healthOwnerUrinaryTractDetails = 'health-owner-urinary-tract-details',
  healthOwnerBladderDetails = 'health-owner-bladder-details',
  healthOwnerProstateDetails = 'health-owner-prostate-details',
  healthOwnerGenitalOrgansTestsDetails = 'health-owner-genital-organs-tests-details',
  healthOwnerEndocrineSystemHasCondition = 'health-owner-endocrine-system-has-condition',
  healthOwnerEndocrineChoices = 'health-owner-endocrine-choices',
  healthOwnerDiabetesType = 'health-owner-diabetes-type',
  healthOwnerDiabetesDetails = 'health-owner-diabetes-details',
  healthOwnerThyroidDisorderIsHypothyroidism = 'health-owner-thyroid-disorder-is-hypothyroidism',
  healthOwnerThyroidDisorderDiagnosisDate = 'health-owner-thyroid-disorder-diagnosis-date',
  healthOwnerThyroidDisorderHadSurgery = 'health-owner-thyroid-disorder-had-surgery',
  healthOwnerThyroidDisorderMedicationWasPrescribed = 'health-owner-thyroid-disorder-medication-was-prescribed',
  healthOwnerThyroidDisorderSynthroid = 'health-owner-thyroid-disorder-synthroid',
  healthOwnerThyroidDisorderIsControlled = 'health-owner-thyroid-disorder-is-controlled',
  healthOwnerThyroidDisorderRecoveredDetails = 'health-owner-thyroid-disorder-recovered-details',
  healthOwnerAbnormalBloodSugarDetails = 'health-owner-abnormal-blood-sugar-details',
  healthOwnerPituitaryGlandDisorderDetails = 'health-owner-pituitary-gland-disorder-details',
  healthOwnerAdrenalGlandDisorderDetails = 'health-owner-adrenal-gland-disorder-details',
  healthOwnerAnyOtherGlandularOrHormonalDisorderDetails = 'health-owner-any-other-glandular-or-hormonal-disorder-details',
  healthOwnerBackNeckHasCondition = 'health-owner-back-neck-has-condition',
  healthOwnerBackNeckConditions = 'health-owner-back-neck-conditions',
  healthOwnerBackNeckConditionsOther = 'health-owner-back-neck-conditions-other',
  healthOwnerOsteoarthritisIsSingleEpisode = 'health-owner-osteoarthritis-is-single-episode',
  healthOwnerOsteoarthritisPreviousEpisodesCount = 'health-owner-osteoarthritis-previous-episodes-count',
  healthOwnerOsteoarthritisPainLocation = 'health-owner-osteoarthritis-pain-location',
  healthOwnerOsteoarthritisDateSymptoms = 'health-owner-osteoarthritis-date-symptoms',
  healthOwnerOsteoarthritisPainCause = 'health-owner-osteoarthritis-pain-cause',
  healthOwnerOsteoarthritisPainCauseDetails = 'health-owner-osteoarthritis-pain-cause-details',
  healthOwnerOsteoarthritisHasConsultedDoctors = 'health-owner-osteoarthritis-consulted-doctors',
  healthOwnerOsteoarthritisHasTakenTestsOrExams = 'health-owner-osteoarthritis-has-taken-tests-or-exams',
  healthOwnerOsteoarthritisMedicationWasPrescribed = 'health-owner-osteoarthritis-medication-was-prescribed',
  healthOwnerOsteoarthritisHadOperation = 'health-owner-osteoarthritis-had-operation',
  healthOwnerOsteoarthritisOperationPerformedOrPlanned = 'health-owner-osteoarthritis-operation-performed-or-planned',
  healthOwnerOsteoarthritisTypeOfOperationPerformed = 'health-owner-osteoarthritis-type-of-operation-performed',
  healthOwnerOsteoarthritisOperationPerformedDate = 'health-owner-osteoarthritis-operation-performed-date',
  healthOwnerOsteoarthritisTypeOfOperationPlanned = 'health-owner-osteoarthritis-type-of-operation-planned',
  healthOwnerOsteoarthritisOperationPlannedDate = 'health-owner-osteoarthritis-operation-planned-date',
  healthOwnerOsteoarthritisOperationUnknownDate = 'health-owner-osteoarthritis-operation-unknown-date',
  healthOwnerOsteoarthritisHadAbsence = 'health-owner-osteoarthritis-had-absence',
  healthOwnerOsteoarthritisAbsenceStartDate = 'health-owner-osteoarthritis-absence-start-date',
  healthOwnerOsteoarthritisAbsenceFrequencyNumber = 'health-owner-osteoarthritis-absence-frequency-number',
  healthOwnerOsteoarthritisAbsenceFrequency = 'health-owner-osteoarthritis-absence-frequency',
  healthOwnerOsteoarthritisAbsenceHasRecovered = 'health-owner-osteoarthritis-absence-has-recovered',
  healthOwnerOsteoarthritisAbsenceRecoveryDate = 'health-owner-osteoarthritis-absence-recovery-date',
  healthOwnerOsteoarthritisAbsenceNotRecoveredDetails = 'health-owner-osteoarthritis-absence-not-recovered-details',

  healthOwnerOsteoarthritisHasConsultedDoctorsDetails = 'health-owner-osteoarthritis-has-consulted-doctors-details',
  healthOwnerOsteoarthritisHasConsultedDoctorsProfession = 'health-owner-osteoarthritis-has-consulted-doctors-profession',
  healthOwnerOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-owner-osteoarthritis-has-consulted-doctors-profession-details',
  healthOwnerOsteoarthritisHasConsultedDoctorsName = 'health-owner-osteoarthritis-has-consulted-doctors-name',
  healthOwnerOsteoarthritisHasConsultedDoctorsAddress = 'health-owner-osteoarthritis-has-consulted-doctors-address',
  healthOwnerOsteoarthritisHasConsultedDoctorsFirstDate = 'health-owner-osteoarthritis-has-consulted-doctors-first-date',
  healthOwnerOsteoarthritisHasConsultedDoctorsLastDate = 'health-owner-osteoarthritis-has-consulted-doctors-last-date',
  healthOwnerOsteoarthritisHasConsultedDoctorsResult = 'health-owner-osteoarthritis-has-consulted-doctors-result',
  healthOwnerOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-owner-osteoarthritis-has-consulted-doctors-frequency-number',
  healthOwnerOsteoarthritisHasConsultedDoctorsFrequency = 'health-owner-osteoarthritis-has-consulted-doctors-frequency',
  healthOwnerOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-owner-osteoarthritis-has-consulted-doctor-further-consultation',
  healthOwnerOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-owner-osteoarthritis-has-consulted-doctors-next-consultation-date',
  healthOwnerOsteoarthritisHasTakenTestsOrExamsDetails = 'health-owner-osteoarthritis-has-taken-tests-or-exams-details',
  healthOwnerOsteoarthritisHasTakenTestsOrExamsType = 'health-owner-osteoarthritis-has-taken-tests-or-exams-type',
  healthOwnerOsteoarthritisHasTakenTestsOrExamsDate = 'health-owner-osteoarthritis-has-taken-tests-or-exams-date',
  healthOwnerOsteoarthritisHasTakenTestsOrExamsResult = 'health-owner-osteoarthritis-has-taken-tests-or-exams-result',
  healthOwnerOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerOsteoarthritisMedicationDetails = 'health-owner-osteoarthritis-medication-details',
  healthOwnerOsteoarthritisMedicationName = 'health-owner-osteoarthritis-medication-name',
  healthOwnerOsteoarthritisMedicationDosage = 'health-owner-osteoarthritis-medication-dosage',
  healthOwnerOsteoarthritisMedicationStartDate = 'health-owner-osteoarthritis-medication-start-date',
  healthOwnerOsteoarthritisMedicationEndDate = 'health-owner-osteoarthritis-medication-end-date',

  healthOwnerSpondylitisAnkylosingIsSingleEpisode = 'health-owner-spondylitis-ankylosing-is-single-episode',
  healthOwnerSpondylitisAnkylosingPreviousEpisodesCount = 'health-owner-spondylitis-ankylosing-previous-episodes-count',
  healthOwnerSpondylitisAnkylosingPainLocation = 'health-owner-spondylitis-ankylosing-pain-location',
  healthOwnerSpondylitisAnkylosingDateSymptoms = 'health-owner-spondylitis-ankylosing-date-symptoms',
  healthOwnerSpondylitisAnkylosingPainCause = 'health-owner-spondylitis-ankylosing-pain-cause',
  healthOwnerSpondylitisAnkylosingPainCauseDetails = 'health-owner-spondylitis-ankylosing-pain-cause-details',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctors = 'health-owner-spondylitis-ankylosing-consulted-doctors',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExams = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams',
  healthOwnerSpondylitisAnkylosingMedicationWasPrescribed = 'health-owner-spondylitis-ankylosing-medication-was-prescribed',
  healthOwnerSpondylitisAnkylosingHadOperation = 'health-owner-spondylitis-ankylosing-had-operation',
  healthOwnerSpondylitisAnkylosingOperationPerformedOrPlanned = 'health-owner-spondylitis-ankylosing-operation-performed-or-planned',
  healthOwnerSpondylitisAnkylosingTypeOfOperationPerformed = 'health-owner-spondylitis-ankylosing-type-of-operation-performed',
  healthOwnerSpondylitisAnkylosingOperationPerformedDate = 'health-owner-spondylitis-ankylosing-operation-performed-date',
  healthOwnerSpondylitisAnkylosingTypeOfOperationPlanned = 'health-owner-spondylitis-ankylosing-type-of-operation-planned',
  healthOwnerSpondylitisAnkylosingOperationPlannedDate = 'health-owner-spondylitis-ankylosing-operation-planned-date',
  healthOwnerSpondylitisAnkylosingOperationUnknownDate = 'health-owner-spondylitis-ankylosing-operation-unknown-date',
  healthOwnerSpondylitisAnkylosingHadAbsence = 'health-owner-spondylitis-ankylosing-had-absence',
  healthOwnerSpondylitisAnkylosingAbsenceStartDate = 'health-owner-spondylitis-ankylosing-absence-start-date',
  healthOwnerSpondylitisAnkylosingAbsenceFrequencyNumber = 'health-owner-spondylitis-ankylosing-absence-frequency-number',
  healthOwnerSpondylitisAnkylosingAbsenceFrequency = 'health-owner-spondylitis-ankylosing-absence-frequency',
  healthOwnerSpondylitisAnkylosingAbsenceHasRecovered = 'health-owner-spondylitis-ankylosing-absence-has-recovered',
  healthOwnerSpondylitisAnkylosingAbsenceRecoveryDate = 'health-owner-spondylitis-ankylosing-absence-recovery-date',
  healthOwnerSpondylitisAnkylosingAbsenceNotRecoveredDetails = 'health-owner-spondylitis-ankylosing-absence-not-recovered-details',

  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsDetails = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-details',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsProfession = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-profession',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-profession-details',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsName = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-name',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsAddress = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-address',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFirstDate = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-first-date',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsLastDate = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-last-date',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsResult = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-result',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-frequency-number',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequency = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-frequency',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation = 'health-owner-spondylitis-ankylosing-has-consulted-doctor-further-consultation',
  healthOwnerSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate = 'health-owner-spondylitis-ankylosing-has-consulted-doctors-next-consultation-date',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetails = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams-details',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsType = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams-type',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDate = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams-date',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsResult = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams-result',
  healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-spondylitis-ankylosing-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerSpondylitisAnkylosingMedicationDetails = 'health-owner-spondylitis-ankylosing-medication-details',
  healthOwnerSpondylitisAnkylosingMedicationName = 'health-owner-spondylitis-ankylosing-medication-name',
  healthOwnerSpondylitisAnkylosingMedicationDosage = 'health-owner-spondylitis-ankylosing-medication-dosage',
  healthOwnerSpondylitisAnkylosingMedicationStartDate = 'health-owner-spondylitis-ankylosing-medication-start-date',
  healthOwnerSpondylitisAnkylosingMedicationEndDate = 'health-owner-spondylitis-ankylosing-medication-end-date',

  healthOwnerOsteoporosisIsSingleEpisode = 'health-owner-osteoporosis-is-single-episode',
  healthOwnerOsteoporosisPreviousEpisodesCount = 'health-owner-osteoporosis-previous-episodes-count',
  healthOwnerOsteoporosisPainLocation = 'health-owner-osteoporosis-pain-location',
  healthOwnerOsteoporosisDateSymptoms = 'health-owner-osteoporosis-date-symptoms',
  healthOwnerOsteoporosisPainCause = 'health-owner-osteoporosis-pain-cause',
  healthOwnerOsteoporosisPainCauseDetails = 'health-owner-osteoporosis-pain-cause-details',
  healthOwnerOsteoporosisHasConsultedDoctors = 'health-owner-osteoporosis-consulted-doctors',
  healthOwnerOsteoporosisHasTakenTestsOrExams = 'health-owner-osteoporosis-has-taken-tests-or-exams',
  healthOwnerOsteoporosisMedicationWasPrescribed = 'health-owner-osteoporosis-medication-was-prescribed',
  healthOwnerOsteoporosisHadOperation = 'health-owner-osteoporosis-had-operation',
  healthOwnerOsteoporosisOperationPerformedOrPlanned = 'health-owner-osteoporosis-operation-performed-or-planned',
  healthOwnerOsteoporosisTypeOfOperationPerformed = 'health-owner-osteoporosis-type-of-operation-performed',
  healthOwnerOsteoporosisOperationPerformedDate = 'health-owner-osteoporosis-operation-performed-date',
  healthOwnerOsteoporosisTypeOfOperationPlanned = 'health-owner-osteoporosis-type-of-operation-planned',
  healthOwnerOsteoporosisOperationPlannedDate = 'health-owner-osteoporosis-operation-planned-date',
  healthOwnerOsteoporosisOperationUnknownDate = 'health-owner-osteoporosis-operation-unknown-date',
  healthOwnerOsteoporosisHadAbsence = 'health-owner-osteoporosis-had-absence',
  healthOwnerOsteoporosisAbsenceStartDate = 'health-owner-osteoporosis-absence-start-date',
  healthOwnerOsteoporosisAbsenceFrequencyNumber = 'health-owner-osteoporosis-absence-frequency-number',
  healthOwnerOsteoporosisAbsenceFrequency = 'health-owner-osteoporosis-absence-frequency',
  healthOwnerOsteoporosisAbsenceHasRecovered = 'health-owner-osteoporosis-absence-has-recovered',
  healthOwnerOsteoporosisAbsenceRecoveryDate = 'health-owner-osteoporosis-absence-recovery-date',
  healthOwnerOsteoporosisAbsenceNotRecoveredDetails = 'health-owner-osteoporosis-absence-not-recovered-details',

  healthOwnerOsteoporosisHasConsultedDoctorsDetails = 'health-owner-osteoporosis-has-consulted-doctors-details',
  healthOwnerOsteoporosisHasConsultedDoctorsProfession = 'health-owner-osteoporosis-has-consulted-doctors-profession',
  healthOwnerOsteoporosisHasConsultedDoctorsProfessionDetails = 'health-owner-osteoporosis-has-consulted-doctors-profession-details',
  healthOwnerOsteoporosisHasConsultedDoctorsName = 'health-owner-osteoporosis-has-consulted-doctors-name',
  healthOwnerOsteoporosisHasConsultedDoctorsAddress = 'health-owner-osteoporosis-has-consulted-doctors-address',
  healthOwnerOsteoporosisHasConsultedDoctorsFirstDate = 'health-owner-osteoporosis-has-consulted-doctors-first-date',
  healthOwnerOsteoporosisHasConsultedDoctorsLastDate = 'health-owner-osteoporosis-has-consulted-doctors-last-date',
  healthOwnerOsteoporosisHasConsultedDoctorsResult = 'health-owner-osteoporosis-has-consulted-doctors-result',
  healthOwnerOsteoporosisHasConsultedDoctorsFrequencyNumber = 'health-owner-osteoporosis-has-consulted-doctors-frequency-number',
  healthOwnerOsteoporosisHasConsultedDoctorsFrequency = 'health-owner-osteoporosis-has-consulted-doctors-frequency',
  healthOwnerOsteoporosisHasConsultedDoctorFurtherConsultation = 'health-owner-osteoporosis-has-consulted-doctor-further-consultation',
  healthOwnerOsteoporosisHasConsultedDoctorsNextConsultationDate = 'health-owner-osteoporosis-has-consulted-doctors-next-consultation-date',
  healthOwnerOsteoporosisHasTakenTestsOrExamsDetails = 'health-owner-osteoporosis-has-taken-tests-or-exams-details',
  healthOwnerOsteoporosisHasTakenTestsOrExamsType = 'health-owner-osteoporosis-has-taken-tests-or-exams-type',
  healthOwnerOsteoporosisHasTakenTestsOrExamsDate = 'health-owner-osteoporosis-has-taken-tests-or-exams-date',
  healthOwnerOsteoporosisHasTakenTestsOrExamsResult = 'health-owner-osteoporosis-has-taken-tests-or-exams-result',
  healthOwnerOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-osteoporosis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerOsteoporosisMedicationDetails = 'health-owner-osteoporosis-medication-details',
  healthOwnerOsteoporosisMedicationName = 'health-owner-osteoporosis-medication-name',
  healthOwnerOsteoporosisMedicationDosage = 'health-owner-osteoporosis-medication-dosage',
  healthOwnerOsteoporosisMedicationStartDate = 'health-owner-osteoporosis-medication-start-date',
  healthOwnerOsteoporosisMedicationEndDate = 'health-owner-osteoporosis-medication-end-date',

  healthOwnerWhiplashIsSingleEpisode = 'health-owner-whiplash-is-single-episode',
  healthOwnerWhiplashPreviousEpisodesCount = 'health-owner-whiplash-previous-episodes-count',
  healthOwnerWhiplashPainLocation = 'health-owner-whiplash-pain-location',
  healthOwnerWhiplashDateSymptoms = 'health-owner-whiplash-date-symptoms',
  healthOwnerWhiplashPainCause = 'health-owner-whiplash-pain-cause',
  healthOwnerWhiplashPainCauseDetails = 'health-owner-whiplash-pain-cause-details',
  healthOwnerWhiplashHasConsultedDoctors = 'health-owner-whiplash-consulted-doctors',
  healthOwnerWhiplashHasTakenTestsOrExams = 'health-owner-whiplash-has-taken-tests-or-exams',
  healthOwnerWhiplashMedicationWasPrescribed = 'health-owner-whiplash-medication-was-prescribed',
  healthOwnerWhiplashHadOperation = 'health-owner-whiplash-had-operation',
  healthOwnerWhiplashOperationPerformedOrPlanned = 'health-owner-whiplash-operation-performed-or-planned',
  healthOwnerWhiplashTypeOfOperationPerformed = 'health-owner-whiplash-type-of-operation-performed',
  healthOwnerWhiplashOperationPerformedDate = 'health-owner-whiplash-operation-performed-date',
  healthOwnerWhiplashTypeOfOperationPlanned = 'health-owner-whiplash-type-of-operation-planned',
  healthOwnerWhiplashOperationPlannedDate = 'health-owner-whiplash-operation-planned-date',
  healthOwnerWhiplashOperationUnknownDate = 'health-owner-whiplash-operation-unknown-date',
  healthOwnerWhiplashHadAbsence = 'health-owner-whiplash-had-absence',
  healthOwnerWhiplashAbsenceStartDate = 'health-owner-whiplash-absence-start-date',
  healthOwnerWhiplashAbsenceFrequencyNumber = 'health-owner-whiplash-absence-frequency-number',
  healthOwnerWhiplashAbsenceFrequency = 'health-owner-whiplash-absence-frequency',
  healthOwnerWhiplashAbsenceHasRecovered = 'health-owner-whiplash-absence-has-recovered',
  healthOwnerWhiplashAbsenceRecoveryDate = 'health-owner-whiplash-absence-recovery-date',
  healthOwnerWhiplashAbsenceNotRecoveredDetails = 'health-owner-whiplash-absence-not-recovered-details',

  healthOwnerWhiplashHasConsultedDoctorsDetails = 'health-owner-whiplash-has-consulted-doctors-details',
  healthOwnerWhiplashHasConsultedDoctorsProfession = 'health-owner-whiplash-has-consulted-doctors-profession',
  healthOwnerWhiplashHasConsultedDoctorsProfessionDetails = 'health-owner-whiplash-has-consulted-doctors-profession-details',
  healthOwnerWhiplashHasConsultedDoctorsName = 'health-owner-whiplash-has-consulted-doctors-name',
  healthOwnerWhiplashHasConsultedDoctorsAddress = 'health-owner-whiplash-has-consulted-doctors-address',
  healthOwnerWhiplashHasConsultedDoctorsFirstDate = 'health-owner-whiplash-has-consulted-doctors-first-date',
  healthOwnerWhiplashHasConsultedDoctorsLastDate = 'health-owner-whiplash-has-consulted-doctors-last-date',
  healthOwnerWhiplashHasConsultedDoctorsResult = 'health-owner-whiplash-has-consulted-doctors-result',
  healthOwnerWhiplashHasConsultedDoctorsFrequencyNumber = 'health-owner-whiplash-has-consulted-doctors-frequency-number',
  healthOwnerWhiplashHasConsultedDoctorsFrequency = 'health-owner-whiplash-has-consulted-doctors-frequency',
  healthOwnerWhiplashHasConsultedDoctorFurtherConsultation = 'health-owner-whiplash-has-consulted-doctor-further-consultation',
  healthOwnerWhiplashHasConsultedDoctorsNextConsultationDate = 'health-owner-whiplash-has-consulted-doctors-next-consultation-date',
  healthOwnerWhiplashHasTakenTestsOrExamsDetails = 'health-owner-whiplash-has-taken-tests-or-exams-details',
  healthOwnerWhiplashHasTakenTestsOrExamsType = 'health-owner-whiplash-has-taken-tests-or-exams-type',
  healthOwnerWhiplashHasTakenTestsOrExamsDate = 'health-owner-whiplash-has-taken-tests-or-exams-date',
  healthOwnerWhiplashHasTakenTestsOrExamsResult = 'health-owner-whiplash-has-taken-tests-or-exams-result',
  healthOwnerWhiplashHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-whiplash-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerWhiplashMedicationDetails = 'health-owner-whiplash-medication-details',
  healthOwnerWhiplashMedicationName = 'health-owner-whiplash-medication-name',
  healthOwnerWhiplashMedicationDosage = 'health-owner-whiplash-medication-dosage',
  healthOwnerWhiplashMedicationStartDate = 'health-owner-whiplash-medication-start-date',
  healthOwnerWhiplashMedicationEndDate = 'health-owner-whiplash-medication-end-date',

  healthOwnerTorticollisIsSingleEpisode = 'health-owner-torticollis-is-single-episode',
  healthOwnerTorticollisPreviousEpisodesCount = 'health-owner-torticollis-previous-episodes-count',
  healthOwnerTorticollisPainLocation = 'health-owner-torticollis-pain-location',
  healthOwnerTorticollisDateSymptoms = 'health-owner-torticollis-date-symptoms',
  healthOwnerTorticollisPainCause = 'health-owner-torticollis-pain-cause',
  healthOwnerTorticollisPainCauseDetails = 'health-owner-torticollis-pain-cause-details',
  healthOwnerTorticollisHasConsultedDoctors = 'health-owner-torticollis-consulted-doctors',
  healthOwnerTorticollisHasTakenTestsOrExams = 'health-owner-torticollis-has-taken-tests-or-exams',
  healthOwnerTorticollisMedicationWasPrescribed = 'health-owner-torticollis-medication-was-prescribed',
  healthOwnerTorticollisHadOperation = 'health-owner-torticollis-had-operation',
  healthOwnerTorticollisOperationPerformedOrPlanned = 'health-owner-torticollis-operation-performed-or-planned',
  healthOwnerTorticollisTypeOfOperationPerformed = 'health-owner-torticollis-type-of-operation-performed',
  healthOwnerTorticollisOperationPerformedDate = 'health-owner-torticollis-operation-performed-date',
  healthOwnerTorticollisTypeOfOperationPlanned = 'health-owner-torticollis-type-of-operation-planned',
  healthOwnerTorticollisOperationPlannedDate = 'health-owner-torticollis-operation-planned-date',
  healthOwnerTorticollisOperationUnknownDate = 'health-owner-torticollis-operation-unknown-date',
  healthOwnerTorticollisHadAbsence = 'health-owner-torticollis-had-absence',
  healthOwnerTorticollisAbsenceStartDate = 'health-owner-torticollis-absence-start-date',
  healthOwnerTorticollisAbsenceFrequencyNumber = 'health-owner-torticollis-absence-frequency-number',
  healthOwnerTorticollisAbsenceFrequency = 'health-owner-torticollis-absence-frequency',
  healthOwnerTorticollisAbsenceHasRecovered = 'health-owner-torticollis-absence-has-recovered',
  healthOwnerTorticollisAbsenceRecoveryDate = 'health-owner-torticollis-absence-recovery-date',
  healthOwnerTorticollisAbsenceNotRecoveredDetails = 'health-owner-torticollis-absence-not-recovered-details',

  healthOwnerTorticollisHasConsultedDoctorsDetails = 'health-owner-torticollis-has-consulted-doctors-details',
  healthOwnerTorticollisHasConsultedDoctorsProfession = 'health-owner-torticollis-has-consulted-doctors-profession',
  healthOwnerTorticollisHasConsultedDoctorsProfessionDetails = 'health-owner-torticollis-has-consulted-doctors-profession-details',
  healthOwnerTorticollisHasConsultedDoctorsName = 'health-owner-torticollis-has-consulted-doctors-name',
  healthOwnerTorticollisHasConsultedDoctorsAddress = 'health-owner-torticollis-has-consulted-doctors-address',
  healthOwnerTorticollisHasConsultedDoctorsFirstDate = 'health-owner-torticollis-has-consulted-doctors-first-date',
  healthOwnerTorticollisHasConsultedDoctorsLastDate = 'health-owner-torticollis-has-consulted-doctors-last-date',
  healthOwnerTorticollisHasConsultedDoctorsResult = 'health-owner-torticollis-has-consulted-doctors-result',
  healthOwnerTorticollisHasConsultedDoctorsFrequencyNumber = 'health-owner-torticollis-has-consulted-doctors-frequency-number',
  healthOwnerTorticollisHasConsultedDoctorsFrequency = 'health-owner-torticollis-has-consulted-doctors-frequency',
  healthOwnerTorticollisHasConsultedDoctorFurtherConsultation = 'health-owner-torticollis-has-consulted-doctor-further-consultation',
  healthOwnerTorticollisHasConsultedDoctorsNextConsultationDate = 'health-owner-torticollis-has-consulted-doctors-next-consultation-date',
  healthOwnerTorticollisHasTakenTestsOrExamsDetails = 'health-owner-torticollis-has-taken-tests-or-exams-details',
  healthOwnerTorticollisHasTakenTestsOrExamsType = 'health-owner-torticollis-has-taken-tests-or-exams-type',
  healthOwnerTorticollisHasTakenTestsOrExamsDate = 'health-owner-torticollis-has-taken-tests-or-exams-date',
  healthOwnerTorticollisHasTakenTestsOrExamsResult = 'health-owner-torticollis-has-taken-tests-or-exams-result',
  healthOwnerTorticollisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-torticollis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerTorticollisMedicationDetails = 'health-owner-torticollis-medication-details',
  healthOwnerTorticollisMedicationName = 'health-owner-torticollis-medication-name',
  healthOwnerTorticollisMedicationDosage = 'health-owner-torticollis-medication-dosage',
  healthOwnerTorticollisMedicationStartDate = 'health-owner-torticollis-medication-start-date',
  healthOwnerTorticollisMedicationEndDate = 'health-owner-torticollis-medication-end-date',

  healthOwnerArnoldsNeuralgiaIsSingleEpisode = 'health-owner-arnolds-neuralgia-is-single-episode',
  healthOwnerArnoldsNeuralgiaPreviousEpisodesCount = 'health-owner-arnolds-neuralgia-previous-episodes-count',
  healthOwnerArnoldsNeuralgiaPainLocation = 'health-owner-arnolds-neuralgia-pain-location',
  healthOwnerArnoldsNeuralgiaDateSymptoms = 'health-owner-arnolds-neuralgia-date-symptoms',
  healthOwnerArnoldsNeuralgiaPainCause = 'health-owner-arnolds-neuralgia-pain-cause',
  healthOwnerArnoldsNeuralgiaPainCauseDetails = 'health-owner-arnolds-neuralgia-pain-cause-details',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctors = 'health-owner-arnolds-neuralgia-consulted-doctors',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExams = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams',
  healthOwnerArnoldsNeuralgiaMedicationWasPrescribed = 'health-owner-arnolds-neuralgia-medication-was-prescribed',
  healthOwnerArnoldsNeuralgiaHadOperation = 'health-owner-arnolds-neuralgia-had-operation',
  healthOwnerArnoldsNeuralgiaOperationPerformedOrPlanned = 'health-owner-arnolds-neuralgia-operation-performed-or-planned',
  healthOwnerArnoldsNeuralgiaTypeOfOperationPerformed = 'health-owner-arnolds-neuralgia-type-of-operation-performed',
  healthOwnerArnoldsNeuralgiaOperationPerformedDate = 'health-owner-arnolds-neuralgia-operation-performed-date',
  healthOwnerArnoldsNeuralgiaTypeOfOperationPlanned = 'health-owner-arnolds-neuralgia-type-of-operation-planned',
  healthOwnerArnoldsNeuralgiaOperationPlannedDate = 'health-owner-arnolds-neuralgia-operation-planned-date',
  healthOwnerArnoldsNeuralgiaOperationUnknownDate = 'health-owner-arnolds-neuralgia-operation-unknown-date',
  healthOwnerArnoldsNeuralgiaHadAbsence = 'health-owner-arnolds-neuralgia-had-absence',
  healthOwnerArnoldsNeuralgiaAbsenceStartDate = 'health-owner-arnolds-neuralgia-absence-start-date',
  healthOwnerArnoldsNeuralgiaAbsenceFrequencyNumber = 'health-owner-arnolds-neuralgia-absence-frequency-number',
  healthOwnerArnoldsNeuralgiaAbsenceFrequency = 'health-owner-arnolds-neuralgia-absence-frequency',
  healthOwnerArnoldsNeuralgiaAbsenceHasRecovered = 'health-owner-arnolds-neuralgia-absence-has-recovered',
  healthOwnerArnoldsNeuralgiaAbsenceRecoveryDate = 'health-owner-arnolds-neuralgia-absence-recovery-date',
  healthOwnerArnoldsNeuralgiaAbsenceNotRecoveredDetails = 'health-owner-arnolds-neuralgia-absence-not-recovered-details',

  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsDetails = 'health-owner-arnolds-neuralgia-has-consulted-doctors-details',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsProfession = 'health-owner-arnolds-neuralgia-has-consulted-doctors-profession',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails = 'health-owner-arnolds-neuralgia-has-consulted-doctors-profession-details',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsName = 'health-owner-arnolds-neuralgia-has-consulted-doctors-name',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsAddress = 'health-owner-arnolds-neuralgia-has-consulted-doctors-address',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFirstDate = 'health-owner-arnolds-neuralgia-has-consulted-doctors-first-date',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsLastDate = 'health-owner-arnolds-neuralgia-has-consulted-doctors-last-date',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsResult = 'health-owner-arnolds-neuralgia-has-consulted-doctors-result',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber = 'health-owner-arnolds-neuralgia-has-consulted-doctors-frequency-number',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequency = 'health-owner-arnolds-neuralgia-has-consulted-doctors-frequency',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation = 'health-owner-arnolds-neuralgia-has-consulted-doctor-further-consultation',
  healthOwnerArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate = 'health-owner-arnolds-neuralgia-has-consulted-doctors-next-consultation-date',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetails = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams-details',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsType = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams-type',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDate = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams-date',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsResult = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams-result',
  healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-arnolds-neuralgia-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerArnoldsNeuralgiaMedicationDetails = 'health-owner-arnolds-neuralgia-medication-details',
  healthOwnerArnoldsNeuralgiaMedicationName = 'health-owner-arnolds-neuralgia-medication-name',
  healthOwnerArnoldsNeuralgiaMedicationDosage = 'health-owner-arnolds-neuralgia-medication-dosage',
  healthOwnerArnoldsNeuralgiaMedicationStartDate = 'health-owner-arnolds-neuralgia-medication-start-date',
  healthOwnerArnoldsNeuralgiaMedicationEndDate = 'health-owner-arnolds-neuralgia-medication-end-date',

  healthOwnerSprainIsSingleEpisode = 'health-owner-sprain-is-single-episode',
  healthOwnerSprainPreviousEpisodesCount = 'health-owner-sprain-previous-episodes-count',
  healthOwnerSprainPainLocation = 'health-owner-sprain-pain-location',
  healthOwnerSprainDateSymptoms = 'health-owner-sprain-date-symptoms',
  healthOwnerSprainPainCause = 'health-owner-sprain-pain-cause',
  healthOwnerSprainPainCauseDetails = 'health-owner-sprain-pain-cause-details',
  healthOwnerSprainHasConsultedDoctors = 'health-owner-sprain-consulted-doctors',
  healthOwnerSprainHasTakenTestsOrExams = 'health-owner-sprain-has-taken-tests-or-exams',
  healthOwnerSprainMedicationWasPrescribed = 'health-owner-sprain-medication-was-prescribed',
  healthOwnerSprainHadOperation = 'health-owner-sprain-had-operation',
  healthOwnerSprainOperationPerformedOrPlanned = 'health-owner-sprain-operation-performed-or-planned',
  healthOwnerSprainTypeOfOperationPerformed = 'health-owner-sprain-type-of-operation-performed',
  healthOwnerSprainOperationPerformedDate = 'health-owner-sprain-operation-performed-date',
  healthOwnerSprainTypeOfOperationPlanned = 'health-owner-sprain-type-of-operation-planned',
  healthOwnerSprainOperationPlannedDate = 'health-owner-sprain-operation-planned-date',
  healthOwnerSprainOperationUnknownDate = 'health-owner-sprain-operation-unknown-date',
  healthOwnerSprainHadAbsence = 'health-owner-sprain-had-absence',
  healthOwnerSprainAbsenceStartDate = 'health-owner-sprain-absence-start-date',
  healthOwnerSprainAbsenceFrequencyNumber = 'health-owner-sprain-absence-frequency-number',
  healthOwnerSprainAbsenceFrequency = 'health-owner-sprain-absence-frequency',
  healthOwnerSprainAbsenceHasRecovered = 'health-owner-sprain-absence-has-recovered',
  healthOwnerSprainAbsenceRecoveryDate = 'health-owner-sprain-absence-recovery-date',
  healthOwnerSprainAbsenceNotRecoveredDetails = 'health-owner-sprain-absence-not-recovered-details',

  healthOwnerSprainHasConsultedDoctorsDetails = 'health-owner-sprain-has-consulted-doctors-details',
  healthOwnerSprainHasConsultedDoctorsProfession = 'health-owner-sprain-has-consulted-doctors-profession',
  healthOwnerSprainHasConsultedDoctorsProfessionDetails = 'health-owner-sprain-has-consulted-doctors-profession-details',
  healthOwnerSprainHasConsultedDoctorsName = 'health-owner-sprain-has-consulted-doctors-name',
  healthOwnerSprainHasConsultedDoctorsAddress = 'health-owner-sprain-has-consulted-doctors-address',
  healthOwnerSprainHasConsultedDoctorsFirstDate = 'health-owner-sprain-has-consulted-doctors-first-date',
  healthOwnerSprainHasConsultedDoctorsLastDate = 'health-owner-sprain-has-consulted-doctors-last-date',
  healthOwnerSprainHasConsultedDoctorsResult = 'health-owner-sprain-has-consulted-doctors-result',
  healthOwnerSprainHasConsultedDoctorsFrequencyNumber = 'health-owner-sprain-has-consulted-doctors-frequency-number',
  healthOwnerSprainHasConsultedDoctorsFrequency = 'health-owner-sprain-has-consulted-doctors-frequency',
  healthOwnerSprainHasConsultedDoctorFurtherConsultation = 'health-owner-sprain-has-consulted-doctor-further-consultation',
  healthOwnerSprainHasConsultedDoctorsNextConsultationDate = 'health-owner-sprain-has-consulted-doctors-next-consultation-date',
  healthOwnerSprainHasTakenTestsOrExamsDetails = 'health-owner-sprain-has-taken-tests-or-exams-details',
  healthOwnerSprainHasTakenTestsOrExamsType = 'health-owner-sprain-has-taken-tests-or-exams-type',
  healthOwnerSprainHasTakenTestsOrExamsDate = 'health-owner-sprain-has-taken-tests-or-exams-date',
  healthOwnerSprainHasTakenTestsOrExamsResult = 'health-owner-sprain-has-taken-tests-or-exams-result',
  healthOwnerSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerSprainMedicationDetails = 'health-owner-sprain-medication-details',
  healthOwnerSprainMedicationName = 'health-owner-sprain-medication-name',
  healthOwnerSprainMedicationDosage = 'health-owner-sprain-medication-dosage',
  healthOwnerSprainMedicationStartDate = 'health-owner-sprain-medication-start-date',
  healthOwnerSprainMedicationEndDate = 'health-owner-sprain-medication-end-date',

  healthOwnerLumbagoIsSingleEpisode = 'health-owner-lumbago-is-single-episode',
  healthOwnerLumbagoPreviousEpisodesCount = 'health-owner-lumbago-previous-episodes-count',
  healthOwnerLumbagoPainLocation = 'health-owner-lumbago-pain-location',
  healthOwnerLumbagoDateSymptoms = 'health-owner-lumbago-date-symptoms',
  healthOwnerLumbagoPainCause = 'health-owner-lumbago-pain-cause',
  healthOwnerLumbagoPainCauseDetails = 'health-owner-lumbago-pain-cause-details',
  healthOwnerLumbagoHasConsultedDoctors = 'health-owner-lumbago-consulted-doctors',
  healthOwnerLumbagoHasTakenTestsOrExams = 'health-owner-lumbago-has-taken-tests-or-exams',
  healthOwnerLumbagoMedicationWasPrescribed = 'health-owner-lumbago-medication-was-prescribed',
  healthOwnerLumbagoHadOperation = 'health-owner-lumbago-had-operation',
  healthOwnerLumbagoOperationPerformedOrPlanned = 'health-owner-lumbago-operation-performed-or-planned',
  healthOwnerLumbagoTypeOfOperationPerformed = 'health-owner-lumbago-type-of-operation-performed',
  healthOwnerLumbagoOperationPerformedDate = 'health-owner-lumbago-operation-performed-date',
  healthOwnerLumbagoTypeOfOperationPlanned = 'health-owner-lumbago-type-of-operation-planned',
  healthOwnerLumbagoOperationPlannedDate = 'health-owner-lumbago-operation-planned-date',
  healthOwnerLumbagoOperationUnknownDate = 'health-owner-lumbago-operation-unknown-date',
  healthOwnerLumbagoHadAbsence = 'health-owner-lumbago-had-absence',
  healthOwnerLumbagoAbsenceStartDate = 'health-owner-lumbago-absence-start-date',
  healthOwnerLumbagoAbsenceFrequencyNumber = 'health-owner-lumbago-absence-frequency-number',
  healthOwnerLumbagoAbsenceFrequency = 'health-owner-lumbago-absence-frequency',
  healthOwnerLumbagoAbsenceHasRecovered = 'health-owner-lumbago-absence-has-recovered',
  healthOwnerLumbagoAbsenceRecoveryDate = 'health-owner-lumbago-absence-recovery-date',
  healthOwnerLumbagoAbsenceNotRecoveredDetails = 'health-owner-lumbago-absence-not-recovered-details',

  healthOwnerLumbagoHasConsultedDoctorsDetails = 'health-owner-lumbago-has-consulted-doctors-details',
  healthOwnerLumbagoHasConsultedDoctorsProfession = 'health-owner-lumbago-has-consulted-doctors-profession',
  healthOwnerLumbagoHasConsultedDoctorsProfessionDetails = 'health-owner-lumbago-has-consulted-doctors-profession-details',
  healthOwnerLumbagoHasConsultedDoctorsName = 'health-owner-lumbago-has-consulted-doctors-name',
  healthOwnerLumbagoHasConsultedDoctorsAddress = 'health-owner-lumbago-has-consulted-doctors-address',
  healthOwnerLumbagoHasConsultedDoctorsFirstDate = 'health-owner-lumbago-has-consulted-doctors-first-date',
  healthOwnerLumbagoHasConsultedDoctorsLastDate = 'health-owner-lumbago-has-consulted-doctors-last-date',
  healthOwnerLumbagoHasConsultedDoctorsResult = 'health-owner-lumbago-has-consulted-doctors-result',
  healthOwnerLumbagoHasConsultedDoctorsFrequencyNumber = 'health-owner-lumbago-has-consulted-doctors-frequency-number',
  healthOwnerLumbagoHasConsultedDoctorsFrequency = 'health-owner-lumbago-has-consulted-doctors-frequency',
  healthOwnerLumbagoHasConsultedDoctorFurtherConsultation = 'health-owner-lumbago-has-consulted-doctor-further-consultation',
  healthOwnerLumbagoHasConsultedDoctorsNextConsultationDate = 'health-owner-lumbago-has-consulted-doctors-next-consultation-date',
  healthOwnerLumbagoHasTakenTestsOrExamsDetails = 'health-owner-lumbago-has-taken-tests-or-exams-details',
  healthOwnerLumbagoHasTakenTestsOrExamsType = 'health-owner-lumbago-has-taken-tests-or-exams-type',
  healthOwnerLumbagoHasTakenTestsOrExamsDate = 'health-owner-lumbago-has-taken-tests-or-exams-date',
  healthOwnerLumbagoHasTakenTestsOrExamsResult = 'health-owner-lumbago-has-taken-tests-or-exams-result',
  healthOwnerLumbagoHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-lumbago-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerLumbagoMedicationDetails = 'health-owner-lumbago-medication-details',
  healthOwnerLumbagoMedicationName = 'health-owner-lumbago-medication-name',
  healthOwnerLumbagoMedicationDosage = 'health-owner-lumbago-medication-dosage',
  healthOwnerLumbagoMedicationStartDate = 'health-owner-lumbago-medication-start-date',
  healthOwnerLumbagoMedicationEndDate = 'health-owner-lumbago-medication-end-date',

  healthOwnerSciaticNerveIsSingleEpisode = 'health-owner-sciatic-nerve-is-single-episode',
  healthOwnerSciaticNervePreviousEpisodesCount = 'health-owner-sciatic-nerve-previous-episodes-count',
  healthOwnerSciaticNervePainLocation = 'health-owner-sciatic-nerve-pain-location',
  healthOwnerSciaticNerveDateSymptoms = 'health-owner-sciatic-nerve-date-symptoms',
  healthOwnerSciaticNervePainCause = 'health-owner-sciatic-nerve-pain-cause',
  healthOwnerSciaticNervePainCauseDetails = 'health-owner-sciatic-nerve-pain-cause-details',
  healthOwnerSciaticNerveHasConsultedDoctors = 'health-owner-sciatic-nerve-consulted-doctors',
  healthOwnerSciaticNerveHasTakenTestsOrExams = 'health-owner-sciatic-nerve-has-taken-tests-or-exams',
  healthOwnerSciaticNerveMedicationWasPrescribed = 'health-owner-sciatic-nerve-medication-was-prescribed',
  healthOwnerSciaticNerveHadOperation = 'health-owner-sciatic-nerve-had-operation',
  healthOwnerSciaticNerveOperationPerformedOrPlanned = 'health-owner-sciatic-nerve-operation-performed-or-planned',
  healthOwnerSciaticNerveTypeOfOperationPerformed = 'health-owner-sciatic-nerve-type-of-operation-performed',
  healthOwnerSciaticNerveOperationPerformedDate = 'health-owner-sciatic-nerve-operation-performed-date',
  healthOwnerSciaticNerveTypeOfOperationPlanned = 'health-owner-sciatic-nerve-type-of-operation-planned',
  healthOwnerSciaticNerveOperationPlannedDate = 'health-owner-sciatic-nerve-operation-planned-date',
  healthOwnerSciaticNerveOperationUnknownDate = 'health-owner-sciatic-nerve-operation-unknown-date',
  healthOwnerSciaticNerveHadAbsence = 'health-owner-sciatic-nerve-had-absence',
  healthOwnerSciaticNerveAbsenceStartDate = 'health-owner-sciatic-nerve-absence-start-date',
  healthOwnerSciaticNerveAbsenceFrequencyNumber = 'health-owner-sciatic-nerve-absence-frequency-number',
  healthOwnerSciaticNerveAbsenceFrequency = 'health-owner-sciatic-nerve-absence-frequency',
  healthOwnerSciaticNerveAbsenceHasRecovered = 'health-owner-sciatic-nerve-absence-has-recovered',
  healthOwnerSciaticNerveAbsenceRecoveryDate = 'health-owner-sciatic-nerve-absence-recovery-date',
  healthOwnerSciaticNerveAbsenceNotRecoveredDetails = 'health-owner-sciatic-nerve-absence-not-recovered-details',

  healthOwnerSciaticNerveHasConsultedDoctorsDetails = 'health-owner-sciatic-nerve-has-consulted-doctors-details',
  healthOwnerSciaticNerveHasConsultedDoctorsProfession = 'health-owner-sciatic-nerve-has-consulted-doctors-profession',
  healthOwnerSciaticNerveHasConsultedDoctorsProfessionDetails = 'health-owner-sciatic-nerve-has-consulted-doctors-profession-details',
  healthOwnerSciaticNerveHasConsultedDoctorsName = 'health-owner-sciatic-nerve-has-consulted-doctors-name',
  healthOwnerSciaticNerveHasConsultedDoctorsAddress = 'health-owner-sciatic-nerve-has-consulted-doctors-address',
  healthOwnerSciaticNerveHasConsultedDoctorsFirstDate = 'health-owner-sciatic-nerve-has-consulted-doctors-first-date',
  healthOwnerSciaticNerveHasConsultedDoctorsLastDate = 'health-owner-sciatic-nerve-has-consulted-doctors-last-date',
  healthOwnerSciaticNerveHasConsultedDoctorsResult = 'health-owner-sciatic-nerve-has-consulted-doctors-result',
  healthOwnerSciaticNerveHasConsultedDoctorsFrequencyNumber = 'health-owner-sciatic-nerve-has-consulted-doctors-frequency-number',
  healthOwnerSciaticNerveHasConsultedDoctorsFrequency = 'health-owner-sciatic-nerve-has-consulted-doctors-frequency',
  healthOwnerSciaticNerveHasConsultedDoctorFurtherConsultation = 'health-owner-sciatic-nerve-has-consulted-doctor-further-consultation',
  healthOwnerSciaticNerveHasConsultedDoctorsNextConsultationDate = 'health-owner-sciatic-nerve-has-consulted-doctors-next-consultation-date',
  healthOwnerSciaticNerveHasTakenTestsOrExamsDetails = 'health-owner-sciatic-nerve-has-taken-tests-or-exams-details',
  healthOwnerSciaticNerveHasTakenTestsOrExamsType = 'health-owner-sciatic-nerve-has-taken-tests-or-exams-type',
  healthOwnerSciaticNerveHasTakenTestsOrExamsDate = 'health-owner-sciatic-nerve-has-taken-tests-or-exams-date',
  healthOwnerSciaticNerveHasTakenTestsOrExamsResult = 'health-owner-sciatic-nerve-has-taken-tests-or-exams-result',
  healthOwnerSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-sciatic-nerve-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerSciaticNerveMedicationDetails = 'health-owner-sciatic-nerve-medication-details',
  healthOwnerSciaticNerveMedicationName = 'health-owner-sciatic-nerve-medication-name',
  healthOwnerSciaticNerveMedicationDosage = 'health-owner-sciatic-nerve-medication-dosage',
  healthOwnerSciaticNerveMedicationStartDate = 'health-owner-sciatic-nerve-medication-start-date',
  healthOwnerSciaticNerveMedicationEndDate = 'health-owner-sciatic-nerve-medication-end-date',

  healthOwnerDiscDegenerationIsSingleEpisode = 'health-owner-disc-degeneration-is-single-episode',
  healthOwnerDiscDegenerationPreviousEpisodesCount = 'health-owner-disc-degeneration-previous-episodes-count',
  healthOwnerDiscDegenerationPainLocation = 'health-owner-disc-degeneration-pain-location',
  healthOwnerDiscDegenerationDateSymptoms = 'health-owner-disc-degeneration-date-symptoms',
  healthOwnerDiscDegenerationPainCause = 'health-owner-disc-degeneration-pain-cause',
  healthOwnerDiscDegenerationPainCauseDetails = 'health-owner-disc-degeneration-pain-cause-details',
  healthOwnerDiscDegenerationHasConsultedDoctors = 'health-owner-disc-degeneration-consulted-doctors',
  healthOwnerDiscDegenerationHasTakenTestsOrExams = 'health-owner-disc-degeneration-has-taken-tests-or-exams',
  healthOwnerDiscDegenerationMedicationWasPrescribed = 'health-owner-disc-degeneration-medication-was-prescribed',
  healthOwnerDiscDegenerationHadOperation = 'health-owner-disc-degeneration-had-operation',
  healthOwnerDiscDegenerationOperationPerformedOrPlanned = 'health-owner-disc-degeneration-operation-performed-or-planned',
  healthOwnerDiscDegenerationTypeOfOperationPerformed = 'health-owner-disc-degeneration-type-of-operation-performed',
  healthOwnerDiscDegenerationOperationPerformedDate = 'health-owner-disc-degeneration-operation-performed-date',
  healthOwnerDiscDegenerationTypeOfOperationPlanned = 'health-owner-disc-degeneration-type-of-operation-planned',
  healthOwnerDiscDegenerationOperationPlannedDate = 'health-owner-disc-degeneration-operation-planned-date',
  healthOwnerDiscDegenerationOperationUnknownDate = 'health-owner-disc-degeneration-operation-unknown-date',
  healthOwnerDiscDegenerationHadAbsence = 'health-owner-disc-degeneration-had-absence',
  healthOwnerDiscDegenerationAbsenceStartDate = 'health-owner-disc-degeneration-absence-start-date',
  healthOwnerDiscDegenerationAbsenceFrequencyNumber = 'health-owner-disc-degeneration-absence-frequency-number',
  healthOwnerDiscDegenerationAbsenceFrequency = 'health-owner-disc-degeneration-absence-frequency',
  healthOwnerDiscDegenerationAbsenceHasRecovered = 'health-owner-disc-degeneration-absence-has-recovered',
  healthOwnerDiscDegenerationAbsenceRecoveryDate = 'health-owner-disc-degeneration-absence-recovery-date',
  healthOwnerDiscDegenerationAbsenceNotRecoveredDetails = 'health-owner-disc-degeneration-absence-not-recovered-details',

  healthOwnerDiscDegenerationHasConsultedDoctorsDetails = 'health-owner-disc-degeneration-has-consulted-doctors-details',
  healthOwnerDiscDegenerationHasConsultedDoctorsProfession = 'health-owner-disc-degeneration-has-consulted-doctors-profession',
  healthOwnerDiscDegenerationHasConsultedDoctorsProfessionDetails = 'health-owner-disc-degeneration-has-consulted-doctors-profession-details',
  healthOwnerDiscDegenerationHasConsultedDoctorsName = 'health-owner-disc-degeneration-has-consulted-doctors-name',
  healthOwnerDiscDegenerationHasConsultedDoctorsAddress = 'health-owner-disc-degeneration-has-consulted-doctors-address',
  healthOwnerDiscDegenerationHasConsultedDoctorsFirstDate = 'health-owner-disc-degeneration-has-consulted-doctors-first-date',
  healthOwnerDiscDegenerationHasConsultedDoctorsLasttDate = 'health-owner-disc-degeneration-has-consulted-doctors-last-date',
  healthOwnerDiscDegenerationHasConsultedDoctorsResult = 'health-owner-disc-degeneration-has-consulted-doctors-result',
  healthOwnerDiscDegenerationHasConsultedDoctorsFrequencyNumber = 'health-owner-disc-degeneration-has-consulted-doctors-frequency-number',
  healthOwnerDiscDegenerationHasConsultedDoctorsFrequency = 'health-owner-disc-degeneration-has-consulted-doctors-frequency',
  healthOwnerDiscDegenerationHasConsultedDoctorFurtherConsultation = 'health-owner-disc-degeneration-has-consulted-doctor-further-consultation',
  healthOwnerDiscDegenerationHasConsultedDoctorsNextConsultationDate = 'health-owner-disc-degeneration-has-consulted-doctors-next-consultation-date',
  healthOwnerDiscDegenerationHasTakenTestsOrExamsDetails = 'health-owner-disc-degeneration-has-taken-tests-or-exams-details',
  healthOwnerDiscDegenerationHasTakenTestsOrExamsType = 'health-owner-disc-degeneration-has-taken-tests-or-exams-type',
  healthOwnerDiscDegenerationHasTakenTestsOrExamsDate = 'health-owner-disc-degeneration-has-taken-tests-or-exams-date',
  healthOwnerDiscDegenerationHasTakenTestsOrExamsResult = 'health-owner-disc-degeneration-has-taken-tests-or-exams-result',
  healthOwnerDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-disc-degeneration-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerDiscDegenerationMedicationDetails = 'health-owner-disc-degeneration-medication-details',
  healthOwnerDiscDegenerationMedicationName = 'health-owner-disc-degeneration-medication-name',
  healthOwnerDiscDegenerationMedicationDosage = 'health-owner-disc-degeneration-medication-dosage',
  healthOwnerDiscDegenerationMedicationStartDate = 'health-owner-disc-degeneration-medication-start-date',
  healthOwnerDiscDegenerationMedicationEndDate = 'health-owner-disc-degeneration-medication-end-date',

  healthOwnerFractureIsSingleEpisode = 'health-owner-fracture-is-single-episode',
  healthOwnerFracturePreviousEpisodesCount = 'health-owner-fracture-previous-episodes-count',
  healthOwnerFracturePainLocation = 'health-owner-fracture-pain-location',
  healthOwnerFractureDateSymptoms = 'health-owner-fracture-date-symptoms',
  healthOwnerFracturePainCause = 'health-owner-fracture-pain-cause',
  healthOwnerFracturePainCauseDetails = 'health-owner-fracture-pain-cause-details',
  healthOwnerFractureHasConsultedDoctors = 'health-owner-fracture-consulted-doctors',
  healthOwnerFractureHasTakenTestsOrExams = 'health-owner-fracture-has-taken-tests-or-exams',
  healthOwnerFractureMedicationWasPrescribed = 'health-owner-fracture-medication-was-prescribed',
  healthOwnerFractureHadOperation = 'health-owner-fracture-had-operation',
  healthOwnerFractureOperationPerformedOrPlanned = 'health-owner-fracture-operation-performed-or-planned',
  healthOwnerFractureTypeOfOperationPerformed = 'health-owner-fracture-type-of-operation-performed',
  healthOwnerFractureOperationPerformedDate = 'health-owner-fracture-operation-performed-date',
  healthOwnerFractureTypeOfOperationPlanned = 'health-owner-fracture-type-of-operation-planned',
  healthOwnerFractureOperationPlannedDate = 'health-owner-fracture-operation-planned-date',
  healthOwnerFractureOperationUnknownDate = 'health-owner-fracture-operation-unknown-date',
  healthOwnerFractureHadAbsence = 'health-owner-fracture-had-absence',
  healthOwnerFractureAbsenceStartDate = 'health-owner-fracture-absence-start-date',
  healthOwnerFractureAbsenceFrequencyNumber = 'health-owner-fracture-absence-frequency-number',
  healthOwnerFractureAbsenceFrequency = 'health-owner-fracture-absence-frequency',
  healthOwnerFractureAbsenceHasRecovered = 'health-owner-fracture-absence-has-recovered',
  healthOwnerFractureAbsenceRecoveryDate = 'health-owner-fracture-absence-recovery-date',
  healthOwnerFractureAbsenceNotRecoveredDetails = 'health-owner-fracture-absence-not-recovered-details',

  healthOwnerFractureHasConsultedDoctorsDetails = 'health-owner-fracture-has-consulted-doctors-details',
  healthOwnerFractureHasConsultedDoctorsProfession = 'health-owner-fracture-has-consulted-doctors-profession',
  healthOwnerFractureHasConsultedDoctorsProfessionDetails = 'health-owner-fracture-has-consulted-doctors-profession-details',
  healthOwnerFractureHasConsultedDoctorsName = 'health-owner-fracture-has-consulted-doctors-name',
  healthOwnerFractureHasConsultedDoctorsAddress = 'health-owner-fracture-has-consulted-doctors-address',
  healthOwnerFractureHasConsultedDoctorsFirstDate = 'health-owner-fracture-has-consulted-doctors-first-date',
  healthOwnerFractureHasConsultedDoctorsLastDate = 'health-owner-fracture-has-consulted-doctors-last-date',
  healthOwnerFractureHasConsultedDoctorsResult = 'health-owner-fracture-has-consulted-doctors-result',
  healthOwnerFractureHasConsultedDoctorsFrequencyNumber = 'health-owner-fracture-has-consulted-doctors-frequency-number',
  healthOwnerFractureHasConsultedDoctorsFrequency = 'health-owner-fracture-has-consulted-doctors-frequency',
  healthOwnerFractureHasConsultedDoctorFurtherConsultation = 'health-owner-fracture-has-consulted-doctor-further-consultation',
  healthOwnerFractureHasConsultedDoctorsNextConsultationDate = 'health-owner-fracture-has-consulted-doctors-next-consultation-date',
  healthOwnerFractureHasTakenTestsOrExamsDetails = 'health-owner-fracture-has-taken-tests-or-exams-details',
  healthOwnerFractureHasTakenTestsOrExamsType = 'health-owner-fracture-has-taken-tests-or-exams-type',
  healthOwnerFractureHasTakenTestsOrExamsDate = 'health-owner-fracture-has-taken-tests-or-exams-date',
  healthOwnerFractureHasTakenTestsOrExamsResult = 'health-owner-fracture-has-taken-tests-or-exams-result',
  healthOwnerFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerFractureMedicationDetails = 'health-owner-fracture-medication-details',
  healthOwnerFractureMedicationName = 'health-owner-fracture-medication-name',
  healthOwnerFractureMedicationDosage = 'health-owner-fracture-medication-dosage',
  healthOwnerFractureMedicationStartDate = 'health-owner-fracture-medication-start-date',
  healthOwnerFractureMedicationEndDate = 'health-owner-fracture-medication-end-date',

  healthOwnerHerniatedDiscIsSingleEpisode = 'health-owner-herniated-disc-is-single-episode',
  healthOwnerHerniatedDiscPreviousEpisodesCount = 'health-owner-herniated-disc-previous-episodes-count',
  healthOwnerHerniatedDiscPainLocation = 'health-owner-herniated-disc-pain-location',
  healthOwnerHerniatedDiscDateSymptoms = 'health-owner-herniated-disc-date-symptoms',
  healthOwnerHerniatedDiscPainCause = 'health-owner-herniated-disc-pain-cause',
  healthOwnerHerniatedDiscPainCauseDetails = 'health-owner-herniated-disc-pain-cause-details',
  healthOwnerHerniatedDiscHasConsultedDoctors = 'health-owner-herniated-disc-consulted-doctors',
  healthOwnerHerniatedDiscHasTakenTestsOrExams = 'health-owner-herniated-disc-has-taken-tests-or-exams',
  healthOwnerHerniatedDiscMedicationWasPrescribed = 'health-owner-herniated-disc-medication-was-prescribed',
  healthOwnerHerniatedDiscHadOperation = 'health-owner-herniated-disc-had-operation',
  healthOwnerHerniatedDiscOperationPerformedOrPlanned = 'health-owner-herniated-disc-operation-performed-or-planned',
  healthOwnerHerniatedDiscTypeOfOperationPerformed = 'health-owner-herniated-disc-type-of-operation-performed',
  healthOwnerHerniatedDiscOperationPerformedDate = 'health-owner-herniated-disc-operation-performed-date',
  healthOwnerHerniatedDiscTypeOfOperationPlanned = 'health-owner-herniated-disc-type-of-operation-planned',
  healthOwnerHerniatedDiscOperationPlannedDate = 'health-owner-herniated-disc-operation-planned-date',
  healthOwnerHerniatedDiscOperationUnknownDate = 'health-owner-herniated-disc-operation-unknown-date',
  healthOwnerHerniatedDiscHadAbsence = 'health-owner-herniated-disc-had-absence',
  healthOwnerHerniatedDiscAbsenceStartDate = 'health-owner-herniated-disc-absence-start-date',
  healthOwnerHerniatedDiscAbsenceFrequencyNumber = 'health-owner-herniated-disc-absence-frequency-number',
  healthOwnerHerniatedDiscAbsenceFrequency = 'health-owner-herniated-disc-absence-frequency',
  healthOwnerHerniatedDiscAbsenceHasRecovered = 'health-owner-herniated-disc-absence-has-recovered',
  healthOwnerHerniatedDiscAbsenceRecoveryDate = 'health-owner-herniated-disc-absence-recovery-date',
  healthOwnerHerniatedDiscAbsenceNotRecoveredDetails = 'health-owner-herniated-disc-absence-not-recovered-details',

  healthOwnerHerniatedDiscHasConsultedDoctorsDetails = 'health-owner-herniated-disc-has-consulted-doctors-details',
  healthOwnerHerniatedDiscHasConsultedDoctorsProfession = 'health-owner-herniated-disc-has-consulted-doctors-profession',
  healthOwnerHerniatedDiscHasConsultedDoctorsProfessionDetails = 'health-owner-herniated-disc-has-consulted-doctors-profession-details',
  healthOwnerHerniatedDiscHasConsultedDoctorsName = 'health-owner-herniated-disc-has-consulted-doctors-name',
  healthOwnerHerniatedDiscHasConsultedDoctorsAddress = 'health-owner-herniated-disc-has-consulted-doctors-address',
  healthOwnerHerniatedDiscHasConsultedDoctorsFirstDate = 'health-owner-herniated-disc-has-consulted-doctors-first-date',
  healthOwnerHerniatedDiscHasConsultedDoctorsLastDate = 'health-owner-herniated-disc-has-consulted-doctors-last-date',
  healthOwnerHerniatedDiscHasConsultedDoctorsResult = 'health-owner-herniated-disc-has-consulted-doctors-result',
  healthOwnerHerniatedDiscHasConsultedDoctorsFrequencyNumber = 'health-owner-herniated-disc-has-consulted-doctors-frequency-number',
  healthOwnerHerniatedDiscHasConsultedDoctorsFrequency = 'health-owner-herniated-disc-has-consulted-doctors-frequency',
  healthOwnerHerniatedDiscHasConsultedDoctorFurtherConsultation = 'health-owner-herniated-disc-has-consulted-doctor-further-consultation',
  healthOwnerHerniatedDiscHasConsultedDoctorsNextConsultationDate = 'health-owner-herniated-disc-has-consulted-doctors-next-consultation-date',
  healthOwnerHerniatedDiscHasTakenTestsOrExamsDetails = 'health-owner-herniated-disc-has-taken-tests-or-exams-details',
  healthOwnerHerniatedDiscHasTakenTestsOrExamsType = 'health-owner-herniated-disc-has-taken-tests-or-exams-type',
  healthOwnerHerniatedDiscHasTakenTestsOrExamsDate = 'health-owner-herniated-disc-has-taken-tests-or-exams-date',
  healthOwnerHerniatedDiscHasTakenTestsOrExamsResult = 'health-owner-herniated-disc-has-taken-tests-or-exams-result',
  healthOwnerHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-herniated-disc-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerHerniatedDiscMedicationDetails = 'health-owner-herniated-disc-medication-details',
  healthOwnerHerniatedDiscMedicationName = 'health-owner-herniated-disc-medication-name',
  healthOwnerHerniatedDiscMedicationDosage = 'health-owner-herniated-disc-medication-dosage',
  healthOwnerHerniatedDiscMedicationStartDate = 'health-owner-herniated-disc-medication-start-date',
  healthOwnerHerniatedDiscMedicationEndDate = 'health-owner-herniated-disc-medication-end-date',

  healthOwnerScoliosisLordosisIsSingleEpisode = 'health-owner-scoliosis-lordosis-is-single-episode',
  healthOwnerScoliosisLordosisPreviousEpisodesCount = 'health-owner-scoliosis-lordosis-previous-episodes-count',
  healthOwnerScoliosisLordosisPainLocation = 'health-owner-scoliosis-lordosis-pain-location',
  healthOwnerScoliosisLordosisDateSymptoms = 'health-owner-scoliosis-lordosis-date-symptoms',
  healthOwnerScoliosisLordosisPainCause = 'health-owner-scoliosis-lordosis-pain-cause',
  healthOwnerScoliosisLordosisPainCauseDetails = 'health-owner-scoliosis-lordosis-pain-cause-details',
  healthOwnerScoliosisLordosisHasConsultedDoctors = 'health-owner-scoliosis-lordosis-consulted-doctors',
  healthOwnerScoliosisLordosisHasTakenTestsOrExams = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams',
  healthOwnerScoliosisLordosisMedicationWasPrescribed = 'health-owner-scoliosis-lordosis-medication-was-prescribed',
  healthOwnerScoliosisLordosisHadOperation = 'health-owner-scoliosis-lordosis-had-operation',
  healthOwnerScoliosisLordosisOperationPerformedOrPlanned = 'health-owner-scoliosis-lordosis-operation-performed-or-planned',
  healthOwnerScoliosisLordosisTypeOfOperationPerformed = 'health-owner-scoliosis-lordosis-type-of-operation-performed',
  healthOwnerScoliosisLordosisOperationPerformedDate = 'health-owner-scoliosis-lordosis-operation-performed-date',
  healthOwnerScoliosisLordosisTypeOfOperationPlanned = 'health-owner-scoliosis-lordosis-type-of-operation-planned',
  healthOwnerScoliosisLordosisOperationPlannedDate = 'health-owner-scoliosis-lordosis-operation-planned-date',
  healthOwnerScoliosisLordosisOperationUnknownDate = 'health-owner-scoliosis-lordosis-operation-unknown-date',
  healthOwnerScoliosisLordosisHadAbsence = 'health-owner-scoliosis-lordosis-had-absence',
  healthOwnerScoliosisLordosisAbsenceStartDate = 'health-owner-scoliosis-lordosis-absence-start-date',
  healthOwnerScoliosisLordosisAbsenceFrequencyNumber = 'health-owner-scoliosis-lordosis-absence-frequency-number',
  healthOwnerScoliosisLordosisAbsenceFrequency = 'health-owner-scoliosis-lordosis-absence-frequency',
  healthOwnerScoliosisLordosisAbsenceHasRecovered = 'health-owner-scoliosis-lordosis-absence-has-recovered',
  healthOwnerScoliosisLordosisAbsenceRecoveryDate = 'health-owner-scoliosis-lordosis-absence-recovery-date',
  healthOwnerScoliosisLordosisAbsenceNotRecoveredDetails = 'health-owner-scoliosis-lordosis-absence-not-recovered-details',

  healthOwnerScoliosisLordosisHasConsultedDoctorsDetails = 'health-owner-scoliosis-lordosis-has-consulted-doctors-details',
  healthOwnerScoliosisLordosisHasConsultedDoctorsProfession = 'health-owner-scoliosis-lordosis-has-consulted-doctors-profession',
  healthOwnerScoliosisLordosisHasConsultedDoctorsProfessionDetails = 'health-owner-scoliosis-lordosis-has-consulted-doctors-profession-details',
  healthOwnerScoliosisLordosisHasConsultedDoctorsName = 'health-owner-scoliosis-lordosis-has-consulted-doctors-name',
  healthOwnerScoliosisLordosisHasConsultedDoctorsAddress = 'health-owner-scoliosis-lordosis-has-consulted-doctors-address',
  healthOwnerScoliosisLordosisHasConsultedDoctorsFirstDate = 'health-owner-scoliosis-lordosis-has-consulted-doctors-first-date',
  healthOwnerScoliosisLordosisHasConsultedDoctorsLastDate = 'health-owner-scoliosis-lordosis-has-consulted-doctors-last-date',
  healthOwnerScoliosisLordosisHasConsultedDoctorsResult = 'health-owner-scoliosis-lordosis-has-consulted-doctors-result',
  healthOwnerScoliosisLordosisHasConsultedDoctorsFrequencyNumber = 'health-owner-scoliosis-lordosis-has-consulted-doctors-frequency-number',
  healthOwnerScoliosisLordosisHasConsultedDoctorsFrequency = 'health-owner-scoliosis-lordosis-has-consulted-doctors-frequency',
  healthOwnerScoliosisLordosisHasConsultedDoctorFurtherConsultation = 'health-owner-scoliosis-lordosis-has-consulted-doctor-further-consultation',
  healthOwnerScoliosisLordosisHasConsultedDoctorsNextConsultationDate = 'health-owner-scoliosis-lordosis-has-consulted-doctors-next-consultation-date',
  healthOwnerScoliosisLordosisHasTakenTestsOrExamsDetails = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams-details',
  healthOwnerScoliosisLordosisHasTakenTestsOrExamsType = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams-type',
  healthOwnerScoliosisLordosisHasTakenTestsOrExamsDate = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams-date',
  healthOwnerScoliosisLordosisHasTakenTestsOrExamsResult = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams-result',
  healthOwnerScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-scoliosis-lordosis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerScoliosisLordosisMedicationDetails = 'health-owner-scoliosis-lordosis-medication-details',
  healthOwnerScoliosisLordosisMedicationName = 'health-owner-scoliosis-lordosis-medication-name',
  healthOwnerScoliosisLordosisMedicationDosage = 'health-owner-scoliosis-lordosis-medication-dosage',
  healthOwnerScoliosisLordosisMedicationStartDate = 'health-owner-scoliosis-lordosis-medication-start-date',
  healthOwnerScoliosisLordosisMedicationEndDate = 'health-owner-scoliosis-lordosis-medication-end-date',

  healthOwnerBackNeckOtherIsSingleEpisode = 'health-owner-back-neck-other-is-single-episode',
  healthOwnerBackNeckOtherPreviousEpisodesCount = 'health-owner-back-neck-other-previous-episodes-count',
  healthOwnerBackNeckOtherPainLocation = 'health-owner-back-neck-other-pain-location',
  healthOwnerBackNeckOtherDateSymptoms = 'health-owner-back-neck-other-date-symptoms',
  healthOwnerBackNeckOtherPainCause = 'health-owner-back-neck-other-pain-cause',
  healthOwnerBackNeckOtherPainCauseDetails = 'health-owner-back-neck-other-pain-cause-details',
  healthOwnerBackNeckOtherHasConsultedDoctors = 'health-owner-back-neck-other-consulted-doctors',
  healthOwnerBackNeckOtherHasTakenTestsOrExams = 'health-owner-back-neck-other-has-taken-tests-or-exams',
  healthOwnerBackNeckOtherMedicationWasPrescribed = 'health-owner-back-neck-other-medication-was-prescribed',
  healthOwnerBackNeckOtherHadOperation = 'health-owner-back-neck-other-had-operation',
  healthOwnerBackNeckOtherOperationPerformedOrPlanned = 'health-owner-back-neck-other-operation-performed-or-planned',
  healthOwnerBackNeckOtherTypeOfOperationPerformed = 'health-owner-back-neck-other-type-of-operation-performed',
  healthOwnerBackNeckOtherOperationPerformedDate = 'health-owner-back-neck-other-operation-performed-date',
  healthOwnerBackNeckOtherTypeOfOperationPlanned = 'health-owner-back-neck-other-type-of-operation-planned',
  healthOwnerBackNeckOtherOperationPlannedDate = 'health-owner-back-neck-other-operation-planned-date',
  healthOwnerBackNeckOtherOperationUnknownDate = 'health-owner-back-neck-other-operation-unknown-date',
  healthOwnerBackNeckOtherHadAbsence = 'health-owner-back-neck-other-had-absence',
  healthOwnerBackNeckOtherAbsenceStartDate = 'health-owner-back-neck-other-absence-start-date',
  healthOwnerBackNeckOtherAbsenceFrequencyNumber = 'health-owner-back-neck-other-absence-frequency-number',
  healthOwnerBackNeckOtherAbsenceFrequency = 'health-owner-back-neck-other-absence-frequency',
  healthOwnerBackNeckOtherAbsenceHasRecovered = 'health-owner-back-neck-other-absence-has-recovered',
  healthOwnerBackNeckOtherAbsenceRecoveryDate = 'health-owner-back-neck-other-absence-recovery-date',
  healthOwnerBackNeckOtherAbsenceNotRecoveredDetails = 'health-owner-back-neck-other-absence-not-recovered-details',

  healthOwnerBackNeckOtherHasConsultedDoctorsDetails = 'health-owner-back-neck-other-has-consulted-doctors-details',
  healthOwnerBackNeckOtherHasConsultedDoctorsProfession = 'health-owner-back-neck-other-has-consulted-doctors-profession',
  healthOwnerBackNeckOtherHasConsultedDoctorsProfessionDetails = 'health-owner-back-neck-other-has-consulted-doctors-profession-details',
  healthOwnerBackNeckOtherHasConsultedDoctorsName = 'health-owner-back-neck-other-has-consulted-doctors-name',
  healthOwnerBackNeckOtherHasConsultedDoctorsAddress = 'health-owner-back-neck-other-has-consulted-doctors-address',
  healthOwnerBackNeckOtherHasConsultedDoctorsFirstDate = 'health-owner-back-neck-other-has-consulted-doctors-first-date',
  healthOwnerBackNeckOtherHasConsultedDoctorsLastDate = 'health-owner-back-neck-other-has-consulted-doctors-last-date',
  healthOwnerBackNeckOtherHasConsultedDoctorsResult = 'health-owner-back-neck-other-has-consulted-doctors-result',
  healthOwnerBackNeckOtherHasConsultedDoctorsFrequencyNumber = 'health-owner-back-neck-other-has-consulted-doctors-frequency-number',
  healthOwnerBackNeckOtherHasConsultedDoctorsFrequency = 'health-owner-back-neck-other-has-consulted-doctors-frequency',
  healthOwnerBackNeckOtherHasConsultedDoctorFurtherConsultation = 'health-owner-back-neck-other-has-consulted-doctor-further-consultation',
  healthOwnerBackNeckOtherHasConsultedDoctorsNextConsultationDate = 'health-owner-back-neck-other-has-consulted-doctors-next-consultation-date',
  healthOwnerBackNeckOtherHasTakenTestsOrExamsDetails = 'health-owner-back-neck-other-has-taken-tests-or-exams-details',
  healthOwnerBackNeckOtherHasTakenTestsOrExamsType = 'health-owner-back-neck-other-has-taken-tests-or-exams-type',
  healthOwnerBackNeckOtherHasTakenTestsOrExamsDate = 'health-owner-back-neck-other-has-taken-tests-or-exams-date',
  healthOwnerBackNeckOtherHasTakenTestsOrExamsResult = 'health-owner-back-neck-other-has-taken-tests-or-exams-result',
  healthOwnerBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-back-neck-other-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerBackNeckOtherMedicationDetails = 'health-owner-back-neck-other-medication-details',
  healthOwnerBackNeckOtherMedicationName = 'health-owner-back-neck-other-medication-name',
  healthOwnerBackNeckOtherMedicationDosage = 'health-owner-back-neck-other-medication-dosage',
  healthOwnerBackNeckOtherMedicationStartDate = 'health-owner-back-neck-other-medication-start-date',
  healthOwnerBackNeckOtherMedicationEndDate = 'health-owner-back-neck-other-medication-end-date',

  healthOwnerMusculoskeletalHasCondition = 'health-owner-musculoskeletal-has-condition',
  healthOwnerMusculoskeletalConditions = 'health-owner-musculoskeletal-conditions',
  healthOwnerMusculoskeletalConditionsOther = 'health-owner-musculoskeletal-conditions-other',
  healthOwnerArthritisIsSingleEpisode = 'health-owner-arthritis-is-single-episode',
  healthOwnerArthritisPreviousEpisodesCount = 'health-owner-arthritis-previous-episodes-count',
  healthOwnerArthritisDateSymptoms = 'health-owner-arthritis-date-symptoms',
  healthOwnerArthritisPainLocation = 'health-owner-arthritis-pain-location',
  healthOwnerArthritisPainLocationLeftRight = 'health-owner-arthritis-pain-location-left-right',
  healthOwnerArthritisPainLocationOther = 'health-owner-arthritis-pain-location-other',
  healthOwnerArthritisPainCause = 'health-owner-arthritis-pain-cause',
  healthOwnerArthritisPainCauseDetails = 'health-owner-arthritis-pain-cause-details',
  healthOwnerArthritisHasConsultedDoctors = 'health-owner-arthritis-consulted-doctors',
  healthOwnerArthritisHasTakenTestsOrExams = 'health-owner-arthritis-has-taken-tests-or-exams',
  healthOwnerArthritisMedicationWasPrescribed = 'health-owner-arthritis-medication-was-prescribed',
  healthOwnerArthritisHadOperation = 'health-owner-arthritis-had-operation',
  healthOwnerArthritisOperationPerformedOrPlanned = 'health-owner-arthritis-operation-performed-or-planned',
  healthOwnerArthritisTypeOfOperationPerformed = 'health-owner-arthritis-type-of-operation-performed',
  healthOwnerArthritisOperationPerformedDate = 'health-owner-arthritis-operation-performed-date',
  healthOwnerArthritisTypeOfOperationPlanned = 'health-owner-arthritis-type-of-operation-planned',
  healthOwnerArthritisOperationPlannedDate = 'health-owner-arthritis-operation-planned-date',
  healthOwnerArthritisOperationUnknownDate = 'health-owner-arthritis-operation-unknown-date',
  healthOwnerArthritisHadAbsence = 'health-owner-arthritis-had-absence',
  healthOwnerArthritisAbsenceStartDate = 'health-owner-arthritis-absence-start-date',
  healthOwnerArthritisAbsenceFrequencyNumber = 'health-owner-arthritis-absence-frequency-number',
  healthOwnerArthritisAbsenceFrequency = 'health-owner-arthritis-absence-frequency',
  healthOwnerArthritisAbsenceHasRecovered = 'health-owner-arthritis-absence-has-recovered',
  healthOwnerArthritisAbsenceRecoveryDate = 'health-owner-arthritis-absence-recovery-date',
  healthOwnerArthritisAbsenceNotRecoveredDetails = 'health-owner-arthritis-absence-not-recovered-details',

  healthOwnerArthritisHasConsultedDoctorsDetails = 'health-owner-arthritis-has-consulted-doctors-details',
  healthOwnerArthritisHasConsultedDoctorsProfession = 'health-owner-arthritis-has-consulted-doctors-profession',
  healthOwnerArthritisHasConsultedDoctorsProfessionDetails = 'health-owner-arthritis-has-consulted-doctors-profession-details',
  healthOwnerArthritisHasConsultedDoctorsName = 'health-owner-arthritis-has-consulted-doctors-name',
  healthOwnerArthritisHasConsultedDoctorsAddress = 'health-owner-arthritis-has-consulted-doctors-address',
  healthOwnerArthritisHasConsultedDoctorsFirstDate = 'health-owner-arthritis-has-consulted-doctors-first-date',
  healthOwnerArthritisHasConsultedDoctorsLastDate = 'health-owner-arthritis-has-consulted-doctors-last-date',
  healthOwnerArthritisHasConsultedDoctorsResult = 'health-owner-arthritis-has-consulted-doctors-result',
  healthOwnerArthritisHasConsultedDoctorsFrequencyNumber = 'health-owner-arthritis-has-consulted-doctors-frequency-number',
  healthOwnerArthritisHasConsultedDoctorsFrequency = 'health-owner-arthritis-has-consulted-doctors-frequency',
  healthOwnerArthritisHasConsultedDoctorFurtherConsultation = 'health-owner-arthritis-has-consulted-doctor-further-consultation',
  healthOwnerArthritisHasConsultedDoctorsNextConsultationDate = 'health-owner-arthritis-has-consulted-doctors-next-consultation-date',
  healthOwnerArthritisHasTakenTestsOrExamsDetails = 'health-owner-arthritis-has-taken-tests-or-exams-details',
  healthOwnerArthritisHasTakenTestsOrExamsType = 'health-owner-arthritis-has-taken-tests-or-exams-type',
  healthOwnerArthritisHasTakenTestsOrExamsDate = 'health-owner-arthritis-has-taken-tests-or-exams-date',
  healthOwnerArthritisHasTakenTestsOrExamsResult = 'health-owner-arthritis-has-taken-tests-or-exams-result',
  healthOwnerArthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-arthritis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerArthritisMedicationDetails = 'health-owner-arthritis-medication-details',
  healthOwnerArthritisMedicationName = 'health-owner-arthritis-medication-name',
  healthOwnerArthritisMedicationDosage = 'health-owner-arthritis-medication-dosage',
  healthOwnerArthritisMedicationStartDate = 'health-owner-arthritis-medication-start-date',
  healthOwnerArthritisMedicationEndDate = 'health-owner-arthritis-medication-end-date',

  healthOwnerMusculoskeletalSprainIsSingleEpisode = 'health-owner-musculoskeletal-sprain-is-single-episode',
  healthOwnerMusculoskeletalSprainPreviousEpisodesCount = 'health-owner-musculoskeletal-sprain-previous-episodes-count',
  healthOwnerMusculoskeletalSprainDateSymptoms = 'health-owner-musculoskeletal-sprain-date-symptoms',
  healthOwnerMusculoskeletalSprainPainLocation = 'health-owner-musculoskeletal-sprain-pain-location',
  healthOwnerMusculoskeletalSprainPainLocationLeftRight = 'health-owner-musculoskeletal-sprain-pain-location-left-right',
  healthOwnerMusculoskeletalSprainPainLocationOther = 'health-owner-musculoskeletal-sprain-pain-location-other',
  healthOwnerMusculoskeletalSprainPainCause = 'health-owner-musculoskeletal-sprain-pain-cause',
  healthOwnerMusculoskeletalSprainPainCauseDetails = 'health-owner-musculoskeletal-sprain-pain-cause-details',
  healthOwnerMusculoskeletalSprainHasConsultedDoctors = 'health-owner-musculoskeletal-sprain-consulted-doctors',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExams = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams',
  healthOwnerMusculoskeletalSprainMedicationWasPrescribed = 'health-owner-musculoskeletal-sprain-medication-was-prescribed',
  healthOwnerMusculoskeletalSprainHadOperation = 'health-owner-musculoskeletal-sprain-had-operation',
  healthOwnerMusculoskeletalSprainOperationPerformedOrPlanned = 'health-owner-musculoskeletal-sprain-operation-performed-or-planned',
  healthOwnerMusculoskeletalSprainTypeOfOperationPerformed = 'health-owner-musculoskeletal-sprain-type-of-operation-performed',
  healthOwnerMusculoskeletalSprainOperationPerformedDate = 'health-owner-musculoskeletal-sprain-operation-performed-date',
  healthOwnerMusculoskeletalSprainTypeOfOperationPlanned = 'health-owner-musculoskeletal-sprain-type-of-operation-planned',
  healthOwnerMusculoskeletalSprainOperationPlannedDate = 'health-owner-musculoskeletal-sprain-operation-planned-date',
  healthOwnerMusculoskeletalSprainOperationUnknownDate = 'health-owner-musculoskeletal-sprain-operation-unknown-date',
  healthOwnerMusculoskeletalSprainHadAbsence = 'health-owner-musculoskeletal-sprain-had-absence',
  healthOwnerMusculoskeletalSprainAbsenceStartDate = 'health-owner-musculoskeletal-sprain-absence-start-date',
  healthOwnerMusculoskeletalSprainAbsenceFrequencyNumber = 'health-owner-musculoskeletal-sprain-absence-frequency-number',
  healthOwnerMusculoskeletalSprainAbsenceFrequency = 'health-owner-musculoskeletal-sprain-absence-frequency',
  healthOwnerMusculoskeletalSprainAbsenceHasRecovered = 'health-owner-musculoskeletal-sprain-absence-has-recovered',
  healthOwnerMusculoskeletalSprainAbsenceRecoveryDate = 'health-owner-musculoskeletal-sprain-absence-recovery-date',
  healthOwnerMusculoskeletalSprainAbsenceNotRecoveredDetails = 'health-owner-musculoskeletal-sprain-absence-not-recovered-details',

  healthOwnerMusculoskeletalSprainHasConsultedDoctorsDetails = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-details',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsProfession = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-profession',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsProfessionDetails = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-profession-details',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsName = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-name',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsAddress = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-address',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsFirstDate = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-first-date',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsLastDate = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-last-date',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsResult = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-result',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-frequency-number',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsFrequency = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-frequency',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorFurtherConsultation = 'health-owner-musculoskeletal-sprain-has-consulted-doctor-further-consultation',
  healthOwnerMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate = 'health-owner-musculoskeletal-sprain-has-consulted-doctors-next-consultation-date',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetails = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams-details',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsType = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams-type',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDate = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams-date',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsResult = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams-result',
  healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-musculoskeletal-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMusculoskeletalSprainMedicationDetails = 'health-owner-musculoskeletal-sprain-medication-details',
  healthOwnerMusculoskeletalSprainMedicationName = 'health-owner-musculoskeletal-sprain-medication-name',
  healthOwnerMusculoskeletalSprainMedicationDosage = 'health-owner-musculoskeletal-sprain-medication-dosage',
  healthOwnerMusculoskeletalSprainMedicationStartDate = 'health-owner-musculoskeletal-sprain-medication-start-date',
  healthOwnerMusculoskeletalSprainMedicationEndDate = 'health-owner-musculoskeletal-sprain-medication-end-date',

  healthOwnerMusculoskeletalFractureIsSingleEpisode = 'health-owner-musculoskeletal-fracture-is-single-episode',
  healthOwnerMusculoskeletalFracturePreviousEpisodesCount = 'health-owner-musculoskeletal-fracture-previous-episodes-count',
  healthOwnerMusculoskeletalFractureDateSymptoms = 'health-owner-musculoskeletal-fracture-date-symptoms',
  healthOwnerMusculoskeletalFracturePainLocation = 'health-owner-musculoskeletal-fracture-pain-location',
  healthOwnerMusculoskeletalFracturePainLocationLeftRight = 'health-owner-musculoskeletal-fracture-pain-location-left-right',
  healthOwnerMusculoskeletalFracturePainLocationOther = 'health-owner-musculoskeletal-fracture-pain-location-other',
  healthOwnerMusculoskeletalFracturePainCause = 'health-owner-musculoskeletal-fracture-pain-cause',
  healthOwnerMusculoskeletalFracturePainCauseDetails = 'health-owner-musculoskeletal-fracture-pain-cause-details',
  healthOwnerMusculoskeletalFractureHasConsultedDoctors = 'health-owner-musculoskeletal-fracture-consulted-doctors',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExams = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams',
  healthOwnerMusculoskeletalFractureMedicationWasPrescribed = 'health-owner-musculoskeletal-fracture-medication-was-prescribed',
  healthOwnerMusculoskeletalFractureHadOperation = 'health-owner-musculoskeletal-fracture-had-operation',
  healthOwnerMusculoskeletalFractureOperationPerformedOrPlanned = 'health-owner-musculoskeletal-fracture-operation-performed-or-planned',
  healthOwnerMusculoskeletalFractureTypeOfOperationPerformed = 'health-owner-musculoskeletal-fracture-type-of-operation-performed',
  healthOwnerMusculoskeletalFractureOperationPerformedDate = 'health-owner-musculoskeletal-fracture-operation-performed-date',
  healthOwnerMusculoskeletalFractureTypeOfOperationPlanned = 'health-owner-musculoskeletal-fracture-type-of-operation-planned',
  healthOwnerMusculoskeletalFractureOperationPlannedDate = 'health-owner-musculoskeletal-fracture-operation-planned-date',
  healthOwnerMusculoskeletalFractureOperationUnknownDate = 'health-owner-musculoskeletal-fracture-operation-unknown-date',
  healthOwnerMusculoskeletalFractureHadAbsence = 'health-owner-musculoskeletal-fracture-had-absence',
  healthOwnerMusculoskeletalFractureAbsenceStartDate = 'health-owner-musculoskeletal-fracture-absence-start-date',
  healthOwnerMusculoskeletalFractureAbsenceFrequencyNumber = 'health-owner-musculoskeletal-fracture-absence-frequency-number',
  healthOwnerMusculoskeletalFractureAbsenceFrequency = 'health-owner-musculoskeletal-fracture-absence-frequency',
  healthOwnerMusculoskeletalFractureAbsenceHasRecovered = 'health-owner-musculoskeletal-fracture-absence-has-recovered',
  healthOwnerMusculoskeletalFractureAbsenceRecoveryDate = 'health-owner-musculoskeletal-fracture-absence-recovery-date',
  healthOwnerMusculoskeletalFractureAbsenceNotRecoveredDetails = 'health-owner-musculoskeletal-fracture-absence-not-recovered-details',

  healthOwnerMusculoskeletalFractureHasConsultedDoctorsDetails = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-details',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsProfession = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-profession',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsProfessionDetails = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-profession-details',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsName = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-name',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsAddress = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-address',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsFirstDate = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-first-date',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsLastDate = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-last-date',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsResult = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-result',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-frequency-number',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsFrequency = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-frequency',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorFurtherConsultation = 'health-owner-musculoskeletal-fracture-has-consulted-doctor-further-consultation',
  healthOwnerMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate = 'health-owner-musculoskeletal-fracture-has-consulted-doctors-next-consultation-date',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetails = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams-details',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsType = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams-type',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDate = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams-date',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsResult = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams-result',
  healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-musculoskeletal-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMusculoskeletalFractureMedicationDetails = 'health-owner-musculoskeletal-fracture-medication-details',
  healthOwnerMusculoskeletalFractureMedicationName = 'health-owner-musculoskeletal-fracture-medication-name',
  healthOwnerMusculoskeletalFractureMedicationDosage = 'health-owner-musculoskeletal-fracture-medication-dosage',
  healthOwnerMusculoskeletalFractureMedicationStartDate = 'health-owner-musculoskeletal-fracture-medication-start-date',
  healthOwnerMusculoskeletalFractureMedicationEndDate = 'health-owner-musculoskeletal-fracture-medication-end-date',

  healthOwnerBursitisIsSingleEpisode = 'health-owner-bursitis-is-single-episode',
  healthOwnerBursitisPreviousEpisodesCount = 'health-owner-bursitis-previous-episodes-count',
  healthOwnerBursitisDateSymptoms = 'health-owner-bursitis-date-symptoms',
  healthOwnerBursitisPainLocation = 'health-owner-bursitis-pain-location',
  healthOwnerBursitisPainLocationLeftRight = 'health-owner-bursitis-pain-location-left-right',
  healthOwnerBursitisPainLocationOther = 'health-owner-bursitis-pain-location-other',
  healthOwnerBursitisPainCause = 'health-owner-bursitis-pain-cause',
  healthOwnerBursitisPainCauseDetails = 'health-owner-bursitis-pain-cause-details',
  healthOwnerBursitisHasConsultedDoctors = 'health-owner-bursitis-consulted-doctors',
  healthOwnerBursitisHasTakenTestsOrExams = 'health-owner-bursitis-has-taken-tests-or-exams',
  healthOwnerBursitisMedicationWasPrescribed = 'health-owner-bursitis-medication-was-prescribed',
  healthOwnerBursitisHadOperation = 'health-owner-bursitis-had-operation',
  healthOwnerBursitisOperationPerformedOrPlanned = 'health-owner-bursitis-operation-performed-or-planned',
  healthOwnerBursitisTypeOfOperationPerformed = 'health-owner-bursitis-type-of-operation-performed',
  healthOwnerBursitisOperationPerformedDate = 'health-owner-bursitis-operation-performed-date',
  healthOwnerBursitisTypeOfOperationPlanned = 'health-owner-bursitis-type-of-operation-planned',
  healthOwnerBursitisOperationPlannedDate = 'health-owner-bursitis-operation-planned-date',
  healthOwnerBursitisOperationUnknownDate = 'health-owner-bursitis-operation-unknown-date',
  healthOwnerBursitisHadAbsence = 'health-owner-bursitis-had-absence',
  healthOwnerBursitisAbsenceStartDate = 'health-owner-bursitis-absence-start-date',
  healthOwnerBursitisAbsenceFrequencyNumber = 'health-owner-bursitis-absence-frequency-number',
  healthOwnerBursitisAbsenceFrequency = 'health-owner-bursitis-absence-frequency',
  healthOwnerBursitisAbsenceHasRecovered = 'health-owner-bursitis-absence-has-recovered',
  healthOwnerBursitisAbsenceRecoveryDate = 'health-owner-bursitis-absence-recovery-date',
  healthOwnerBursitisAbsenceNotRecoveredDetails = 'health-owner-bursitis-absence-not-recovered-details',

  healthOwnerBursitisHasConsultedDoctorsDetails = 'health-owner-bursitis-has-consulted-doctors-details',
  healthOwnerBursitisHasConsultedDoctorsProfession = 'health-owner-bursitis-has-consulted-doctors-profession',
  healthOwnerBursitisHasConsultedDoctorsProfessionDetails = 'health-owner-bursitis-has-consulted-doctors-profession-details',
  healthOwnerBursitisHasConsultedDoctorsName = 'health-owner-bursitis-has-consulted-doctors-name',
  healthOwnerBursitisHasConsultedDoctorsAddress = 'health-owner-bursitis-has-consulted-doctors-address',
  healthOwnerBursitisHasConsultedDoctorsFirstDate = 'health-owner-bursitis-has-consulted-doctors-first-date',
  healthOwnerBursitisHasConsultedDoctorsLastDate = 'health-owner-bursitis-has-consulted-doctors-last-date',
  healthOwnerBursitisHasConsultedDoctorsResult = 'health-owner-bursitis-has-consulted-doctors-result',
  healthOwnerBursitisHasConsultedDoctorsFrequencyNumber = 'health-owner-bursitis-has-consulted-doctors-frequency-number',
  healthOwnerBursitisHasConsultedDoctorsFrequency = 'health-owner-bursitis-has-consulted-doctors-frequency',
  healthOwnerBursitisHasConsultedDoctorFurtherConsultation = 'health-owner-bursitis-has-consulted-doctor-further-consultation',
  healthOwnerBursitisHasConsultedDoctorsNextConsultationDate = 'health-owner-bursitis-has-consulted-doctors-next-consultation-date',
  healthOwnerBursitisHasTakenTestsOrExamsDetails = 'health-owner-bursitis-has-taken-tests-or-exams-details',
  healthOwnerBursitisHasTakenTestsOrExamsType = 'health-owner-bursitis-has-taken-tests-or-exams-type',
  healthOwnerBursitisHasTakenTestsOrExamsDate = 'health-owner-bursitis-has-taken-tests-or-exams-date',
  healthOwnerBursitisHasTakenTestsOrExamsResult = 'health-owner-bursitis-has-taken-tests-or-exams-result',
  healthOwnerBursitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-bursitis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerBursitisMedicationDetails = 'health-owner-bursitis-medication-details',
  healthOwnerBursitisMedicationName = 'health-owner-bursitis-medication-name',
  healthOwnerBursitisMedicationDosage = 'health-owner-bursitis-medication-dosage',
  healthOwnerBursitisMedicationStartDate = 'health-owner-bursitis-medication-start-date',
  healthOwnerBursitisMedicationEndDate = 'health-owner-bursitis-medication-end-date',

  healthOwnerTendonitisIsSingleEpisode = 'health-owner-tendonitis-is-single-episode',
  healthOwnerTendonitisPreviousEpisodesCount = 'health-owner-tendonitis-previous-episodes-count',
  healthOwnerTendonitisDateSymptoms = 'health-owner-tendonitis-date-symptoms',
  healthOwnerTendonitisPainLocation = 'health-owner-tendonitis-pain-location',
  healthOwnerTendonitisPainLocationLeftRight = 'health-owner-tendonitis-pain-location-left-right',
  healthOwnerTendonitisPainLocationOther = 'health-owner-tendonitis-pain-location-other',
  healthOwnerTendonitisPainCause = 'health-owner-tendonitis-pain-cause',
  healthOwnerTendonitisPainCauseDetails = 'health-owner-tendonitis-pain-cause-details',
  healthOwnerTendonitisHasConsultedDoctors = 'health-owner-tendonitis-consulted-doctors',
  healthOwnerTendonitisHasTakenTestsOrExams = 'health-owner-tendonitis-has-taken-tests-or-exams',
  healthOwnerTendonitisMedicationWasPrescribed = 'health-owner-tendonitis-medication-was-prescribed',
  healthOwnerTendonitisHadOperation = 'health-owner-tendonitis-had-operation',
  healthOwnerTendonitisOperationPerformedOrPlanned = 'health-owner-tendonitis-operation-performed-or-planned',
  healthOwnerTendonitisTypeOfOperationPerformed = 'health-owner-tendonitis-type-of-operation-performed',
  healthOwnerTendonitisOperationPerformedDate = 'health-owner-tendonitis-operation-performed-date',
  healthOwnerTendonitisTypeOfOperationPlanned = 'health-owner-tendonitis-type-of-operation-planned',
  healthOwnerTendonitisOperationPlannedDate = 'health-owner-tendonitis-operation-planned-date',
  healthOwnerTendonitisOperationUnknownDate = 'health-owner-tendonitis-operation-unknown-date',
  healthOwnerTendonitisHadAbsence = 'health-owner-tendonitis-had-absence',
  healthOwnerTendonitisAbsenceStartDate = 'health-owner-tendonitis-absence-start-date',
  healthOwnerTendonitisAbsenceFrequencyNumber = 'health-owner-tendonitis-absence-frequency-number',
  healthOwnerTendonitisAbsenceFrequency = 'health-owner-tendonitis-absence-frequency',
  healthOwnerTendonitisAbsenceHasRecovered = 'health-owner-tendonitis-absence-has-recovered',
  healthOwnerTendonitisAbsenceRecoveryDate = 'health-owner-tendonitis-absence-recovery-date',
  healthOwnerTendonitisAbsenceNotRecoveredDetails = 'health-owner-tendonitis-absence-not-recovered-details',

  healthOwnerTendonitisHasConsultedDoctorsDetails = 'health-owner-tendonitis-has-consulted-doctors-details',
  healthOwnerTendonitisHasConsultedDoctorsProfession = 'health-owner-tendonitis-has-consulted-doctors-profession',
  healthOwnerTendonitisHasConsultedDoctorsProfessionDetails = 'health-owner-tendonitis-has-consulted-doctors-profession-details',
  healthOwnerTendonitisHasConsultedDoctorsName = 'health-owner-tendonitis-has-consulted-doctors-name',
  healthOwnerTendonitisHasConsultedDoctorsAddress = 'health-owner-tendonitis-has-consulted-doctors-address',
  healthOwnerTendonitisHasConsultedDoctorsFirstDate = 'health-owner-tendonitis-has-consulted-doctors-first-date',
  healthOwnerTendonitisHasConsultedDoctorsLastDate = 'health-owner-tendonitis-has-consulted-doctors-last-date',
  healthOwnerTendonitisHasConsultedDoctorsResult = 'health-owner-tendonitis-has-consulted-doctors-result',
  healthOwnerTendonitisHasConsultedDoctorsFrequencyNumber = 'health-owner-tendonitis-has-consulted-doctors-frequency-number',
  healthOwnerTendonitisHasConsultedDoctorsFrequency = 'health-owner-tendonitis-has-consulted-doctors-frequency',
  healthOwnerTendonitisHasConsultedDoctorFurtherConsultation = 'health-owner-tendonitis-has-consulted-doctor-further-consultation',
  healthOwnerTendonitisHasConsultedDoctorsNextConsultationDate = 'health-owner-tendonitis-has-consulted-doctors-next-consultation-date',
  healthOwnerTendonitisHasTakenTestsOrExamsDetails = 'health-owner-tendonitis-has-taken-tests-or-exams-details',
  healthOwnerTendonitisHasTakenTestsOrExamsType = 'health-owner-tendonitis-has-taken-tests-or-exams-type',
  healthOwnerTendonitisHasTakenTestsOrExamsDate = 'health-owner-tendonitis-has-taken-tests-or-exams-date',
  healthOwnerTendonitisHasTakenTestsOrExamsResult = 'health-owner-tendonitis-has-taken-tests-or-exams-result',
  healthOwnerTendonitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-tendonitis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerTendonitisMedicationDetails = 'health-owner-tendonitis-medication-details',
  healthOwnerTendonitisMedicationName = 'health-owner-tendonitis-medication-name',
  healthOwnerTendonitisMedicationDosage = 'health-owner-tendonitis-medication-dosage',
  healthOwnerTendonitisMedicationStartDate = 'health-owner-tendonitis-medication-start-date',
  healthOwnerTendonitisMedicationEndDate = 'health-owner-tendonitis-medication-end-date',

  healthOwnerEpicondylitisIsSingleEpisode = 'health-owner-epicondylitis-is-single-episode',
  healthOwnerEpicondylitisPreviousEpisodesCount = 'health-owner-epicondylitis-previous-episodes-count',
  healthOwnerEpicondylitisDateSymptoms = 'health-owner-epicondylitis-date-symptoms',
  healthOwnerEpicondylitisPainLocation = 'health-owner-epicondylitis-pain-location',
  healthOwnerEpicondylitisPainLocationLeftRight = 'health-owner-epicondylitis-pain-location-left-right',
  healthOwnerEpicondylitisPainLocationOther = 'health-owner-epicondylitis-pain-location-other',
  healthOwnerEpicondylitisPainCause = 'health-owner-epicondylitis-pain-cause',
  healthOwnerEpicondylitisPainCauseDetails = 'health-owner-epicondylitis-pain-cause-details',
  healthOwnerEpicondylitisHasConsultedDoctors = 'health-owner-epicondylitis-consulted-doctors',
  healthOwnerEpicondylitisHasTakenTestsOrExams = 'health-owner-epicondylitis-has-taken-tests-or-exams',
  healthOwnerEpicondylitisMedicationWasPrescribed = 'health-owner-epicondylitis-medication-was-prescribed',
  healthOwnerEpicondylitisHadOperation = 'health-owner-epicondylitis-had-operation',
  healthOwnerEpicondylitisOperationPerformedOrPlanned = 'health-owner-epicondylitis-operation-performed-or-planned',
  healthOwnerEpicondylitisTypeOfOperationPerformed = 'health-owner-epicondylitis-type-of-operation-performed',
  healthOwnerEpicondylitisOperationPerformedDate = 'health-owner-epicondylitis-operation-performed-date',
  healthOwnerEpicondylitisTypeOfOperationPlanned = 'health-owner-epicondylitis-type-of-operation-planned',
  healthOwnerEpicondylitisOperationPlannedDate = 'health-owner-epicondylitis-operation-planned-date',
  healthOwnerEpicondylitisOperationUnknownDate = 'health-owner-epicondylitis-operation-unknown-date',
  healthOwnerEpicondylitisHadAbsence = 'health-owner-epicondylitis-had-absence',
  healthOwnerEpicondylitisAbsenceStartDate = 'health-owner-epicondylitis-absence-start-date',
  healthOwnerEpicondylitisAbsenceFrequencyNumber = 'health-owner-epicondylitis-absence-frequency-number',
  healthOwnerEpicondylitisAbsenceFrequency = 'health-owner-epicondylitis-absence-frequency',
  healthOwnerEpicondylitisAbsenceHasRecovered = 'health-owner-epicondylitis-absence-has-recovered',
  healthOwnerEpicondylitisAbsenceRecoveryDate = 'health-owner-epicondylitis-absence-recovery-date',
  healthOwnerEpicondylitisAbsenceNotRecoveredDetails = 'health-owner-epicondylitis-absence-not-recovered-details',

  healthOwnerEpicondylitisHasConsultedDoctorsDetails = 'health-owner-epicondylitis-has-consulted-doctors-details',
  healthOwnerEpicondylitisHasConsultedDoctorsProfession = 'health-owner-epicondylitis-has-consulted-doctors-profession',
  healthOwnerEpicondylitisHasConsultedDoctorsProfessionDetails = 'health-owner-epicondylitis-has-consulted-doctors-profession-details',
  healthOwnerEpicondylitisHasConsultedDoctorsName = 'health-owner-epicondylitis-has-consulted-doctors-name',
  healthOwnerEpicondylitisHasConsultedDoctorsAddress = 'health-owner-epicondylitis-has-consulted-doctors-address',
  healthOwnerEpicondylitisHasConsultedDoctorsFirstDate = 'health-owner-epicondylitis-has-consulted-doctors-first-date',
  healthOwnerEpicondylitisHasConsultedDoctorsLastDate = 'health-owner-epicondylitis-has-consulted-doctors-last-date',
  healthOwnerEpicondylitisHasConsultedDoctorsResult = 'health-owner-epicondylitis-has-consulted-doctors-result',
  healthOwnerEpicondylitisHasConsultedDoctorsFrequencyNumber = 'health-owner-epicondylitis-has-consulted-doctors-frequency-number',
  healthOwnerEpicondylitisHasConsultedDoctorsFrequency = 'health-owner-epicondylitis-has-consulted-doctors-frequency',
  healthOwnerEpicondylitisHasConsultedDoctorFurtherConsultation = 'health-owner-epicondylitis-has-consulted-doctor-further-consultation',
  healthOwnerEpicondylitisHasConsultedDoctorsNextConsultationDate = 'health-owner-epicondylitis-has-consulted-doctors-next-consultation-date',
  healthOwnerEpicondylitisHasTakenTestsOrExamsDetails = 'health-owner-epicondylitis-has-taken-tests-or-exams-details',
  healthOwnerEpicondylitisHasTakenTestsOrExamsType = 'health-owner-epicondylitis-has-taken-tests-or-exams-type',
  healthOwnerEpicondylitisHasTakenTestsOrExamsDate = 'health-owner-epicondylitis-has-taken-tests-or-exams-date',
  healthOwnerEpicondylitisHasTakenTestsOrExamsResult = 'health-owner-epicondylitis-has-taken-tests-or-exams-result',
  healthOwnerEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-epicondylitis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerEpicondylitisMedicationDetails = 'health-owner-epicondylitis-medication-details',
  healthOwnerEpicondylitisMedicationName = 'health-owner-epicondylitis-medication-name',
  healthOwnerEpicondylitisMedicationDosage = 'health-owner-epicondylitis-medication-dosage',
  healthOwnerEpicondylitisMedicationStartDate = 'health-owner-epicondylitis-medication-start-date',
  healthOwnerEpicondylitisMedicationEndDate = 'health-owner-epicondylitis-medication-end-date',

  healthOwnerLigamentTearMeniscusIsSingleEpisode = 'health-owner-ligament-tear-meniscus-is-single-episode',
  healthOwnerLigamentTearMeniscusPreviousEpisodesCount = 'health-owner-ligament-tear-meniscus-previous-episodes-count',
  healthOwnerLigamentTearMeniscusDateSymptoms = 'health-owner-ligament-tear-meniscus-date-symptoms',
  healthOwnerLigamentTearMeniscusPainLocation = 'health-owner-ligament-tear-meniscus-pain-location',
  healthOwnerLigamentTearMeniscusPainLocationLeftRight = 'health-owner-ligament-tear-meniscus-pain-location-left-right',
  healthOwnerLigamentTearMeniscusPainLocationOther = 'health-owner-ligament-tear-meniscus-pain-location-other',
  healthOwnerLigamentTearMeniscusPainCause = 'health-owner-ligament-tear-meniscus-pain-cause',
  healthOwnerLigamentTearMeniscusPainCauseDetails = 'health-owner-ligament-tear-meniscus-pain-cause-details',
  healthOwnerLigamentTearMeniscusHasConsultedDoctors = 'health-owner-ligament-tear-meniscus-consulted-doctors',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExams = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams',
  healthOwnerLigamentTearMeniscusMedicationWasPrescribed = 'health-owner-ligament-tear-meniscus-medication-was-prescribed',
  healthOwnerLigamentTearMeniscusHadOperation = 'health-owner-ligament-tear-meniscus-had-operation',
  healthOwnerLigamentTearMeniscusOperationPerformedOrPlanned = 'health-owner-ligament-tear-meniscus-operation-performed-or-planned',
  healthOwnerLigamentTearMeniscusTypeOfOperationPerformed = 'health-owner-ligament-tear-meniscus-type-of-operation-performed',
  healthOwnerLigamentTearMeniscusOperationPerformedDate = 'health-owner-ligament-tear-meniscus-operation-performed-date',
  healthOwnerLigamentTearMeniscusTypeOfOperationPlanned = 'health-owner-ligament-tear-meniscus-type-of-operation-planned',
  healthOwnerLigamentTearMeniscusOperationPlannedDate = 'health-owner-ligament-tear-meniscus-operation-planned-date',
  healthOwnerLigamentTearMeniscusOperationUnknownDate = 'health-owner-ligament-tear-meniscus-operation-unknown-date',
  healthOwnerLigamentTearMeniscusHadAbsence = 'health-owner-ligament-tear-meniscus-had-absence',
  healthOwnerLigamentTearMeniscusAbsenceStartDate = 'health-owner-ligament-tear-meniscus-absence-start-date',
  healthOwnerLigamentTearMeniscusAbsenceFrequencyNumber = 'health-owner-ligament-tear-meniscus-absence-frequency-number',
  healthOwnerLigamentTearMeniscusAbsenceFrequency = 'health-owner-ligament-tear-meniscus-absence-frequency',
  healthOwnerLigamentTearMeniscusAbsenceHasRecovered = 'health-owner-ligament-tear-meniscus-absence-has-recovered',
  healthOwnerLigamentTearMeniscusAbsenceRecoveryDate = 'health-owner-ligament-tear-meniscus-absence-recovery-date',
  healthOwnerLigamentTearMeniscusAbsenceNotRecoveredDetails = 'health-owner-ligament-tear-meniscus-absence-not-recovered-details',

  healthOwnerLigamentTearMeniscusHasConsultedDoctorsDetails = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-details',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsProfession = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-profession',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsProfessionDetails = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-profession-details',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsName = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-name',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsAddress = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-address',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsFirstDate = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-first-date',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsLastDate = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-last-date',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsResult = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-result',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-frequency-number',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsFrequency = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-frequency',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorFurtherConsultation = 'health-owner-ligament-tear-meniscus-has-consulted-doctor-further-consultation',
  healthOwnerLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate = 'health-owner-ligament-tear-meniscus-has-consulted-doctors-next-consultation-date',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetails = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams-details',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsType = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams-type',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDate = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams-date',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsResult = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams-result',
  healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-ligament-tear-meniscus-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerLigamentTearMeniscusMedicationDetails = 'health-owner-ligament-tear-meniscus-medication-details',
  healthOwnerLigamentTearMeniscusMedicationName = 'health-owner-ligament-tear-meniscus-medication-name',
  healthOwnerLigamentTearMeniscusMedicationDosage = 'health-owner-ligament-tear-meniscus-medication-dosage',
  healthOwnerLigamentTearMeniscusMedicationStartDate = 'health-owner-ligament-tear-meniscus-medication-start-date',
  healthOwnerLigamentTearMeniscusMedicationEndDate = 'health-owner-ligament-tear-meniscus-medication-end-date',

  healthOwnerCarpalTunnelIsSingleEpisode = 'health-owner-carpal-tunnel-is-single-episode',
  healthOwnerCarpalTunnelPreviousEpisodesCount = 'health-owner-carpal-tunnel-previous-episodes-count',
  healthOwnerCarpalTunnelDateSymptoms = 'health-owner-carpal-tunnel-date-symptoms',
  healthOwnerCarpalTunnelPainLocation = 'health-owner-carpal-tunnel-pain-location',
  healthOwnerCarpalTunnelPainLocationLeftRight = 'health-owner-carpal-tunnel-pain-location-left-right',
  healthOwnerCarpalTunnelPainLocationOther = 'health-owner-carpal-tunnel-pain-location-other',
  healthOwnerCarpalTunnelPainCause = 'health-owner-carpal-tunnel-pain-cause',
  healthOwnerCarpalTunnelPainCauseDetails = 'health-owner-carpal-tunnel-pain-cause-details',
  healthOwnerCarpalTunnelHasConsultedDoctors = 'health-owner-carpal-tunnel-consulted-doctors',
  healthOwnerCarpalTunnelHasTakenTestsOrExams = 'health-owner-carpal-tunnel-has-taken-tests-or-exams',
  healthOwnerCarpalTunnelMedicationWasPrescribed = 'health-owner-carpal-tunnel-medication-was-prescribed',
  healthOwnerCarpalTunnelHadOperation = 'health-owner-carpal-tunnel-had-operation',
  healthOwnerCarpalTunnelOperationPerformedOrPlanned = 'health-owner-carpal-tunnel-operation-performed-or-planned',
  healthOwnerCarpalTunnelTypeOfOperationPerformed = 'health-owner-carpal-tunnel-type-of-operation-performed',
  healthOwnerCarpalTunnelOperationPerformedDate = 'health-owner-carpal-tunnel-operation-performed-date',
  healthOwnerCarpalTunnelTypeOfOperationPlanned = 'health-owner-carpal-tunnel-type-of-operation-planned',
  healthOwnerCarpalTunnelOperationPlannedDate = 'health-owner-carpal-tunnel-operation-planned-date',
  healthOwnerCarpalTunnelOperationUnknownDate = 'health-owner-carpal-tunnel-operation-unknown-date',
  healthOwnerCarpalTunnelHadAbsence = 'health-owner-carpal-tunnel-had-absence',
  healthOwnerCarpalTunnelAbsenceStartDate = 'health-owner-carpal-tunnel-absence-start-date',
  healthOwnerCarpalTunnelAbsenceFrequencyNumber = 'health-owner-carpal-tunnel-absence-frequency-number',
  healthOwnerCarpalTunnelAbsenceFrequency = 'health-owner-carpal-tunnel-absence-frequency',
  healthOwnerCarpalTunnelAbsenceHasRecovered = 'health-owner-carpal-tunnel-absence-has-recovered',
  healthOwnerCarpalTunnelAbsenceRecoveryDate = 'health-owner-carpal-tunnel-absence-recovery-date',
  healthOwnerCarpalTunnelAbsenceNotRecoveredDetails = 'health-owner-carpal-tunnel-absence-not-recovered-details',

  healthOwnerCarpalTunnelHasConsultedDoctorsDetails = 'health-owner-carpal-tunnel-has-consulted-doctors-details',
  healthOwnerCarpalTunnelHasConsultedDoctorsProfession = 'health-owner-carpal-tunnel-has-consulted-doctors-profession',
  healthOwnerCarpalTunnelHasConsultedDoctorsProfessionDetails = 'health-owner-carpal-tunnel-has-consulted-doctors-profession-details',
  healthOwnerCarpalTunnelHasConsultedDoctorsName = 'health-owner-carpal-tunnel-has-consulted-doctors-name',
  healthOwnerCarpalTunnelHasConsultedDoctorsAddress = 'health-owner-carpal-tunnel-has-consulted-doctors-address',
  healthOwnerCarpalTunnelHasConsultedDoctorsFirstDate = 'health-owner-carpal-tunnel-has-consulted-doctors-first-date',
  healthOwnerCarpalTunnelHasConsultedDoctorsLastDate = 'health-owner-carpal-tunnel-has-consulted-doctors-last-date',
  healthOwnerCarpalTunnelHasConsultedDoctorsResult = 'health-owner-carpal-tunnel-has-consulted-doctors-result',
  healthOwnerCarpalTunnelHasConsultedDoctorsFrequencyNumber = 'health-owner-carpal-tunnel-has-consulted-doctors-frequency-number',
  healthOwnerCarpalTunnelHasConsultedDoctorsFrequency = 'health-owner-carpal-tunnel-has-consulted-doctors-frequency',
  healthOwnerCarpalTunnelHasConsultedDoctorFurtherConsultation = 'health-owner-carpal-tunnel-has-consulted-doctor-further-consultation',
  healthOwnerCarpalTunnelHasConsultedDoctorsNextConsultationDate = 'health-owner-carpal-tunnel-has-consulted-doctors-next-consultation-date',
  healthOwnerCarpalTunnelHasTakenTestsOrExamsDetails = 'health-owner-carpal-tunnel-has-taken-tests-or-exams-details',
  healthOwnerCarpalTunnelHasTakenTestsOrExamsType = 'health-owner-carpal-tunnel-has-taken-tests-or-exams-type',
  healthOwnerCarpalTunnelHasTakenTestsOrExamsDate = 'health-owner-carpal-tunnel-has-taken-tests-or-exams-date',
  healthOwnerCarpalTunnelHasTakenTestsOrExamsResult = 'health-owner-carpal-tunnel-has-taken-tests-or-exams-result',
  healthOwnerCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-carpal-tunnel-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerCarpalTunnelMedicationDetails = 'health-owner-carpal-tunnel-medication-details',
  healthOwnerCarpalTunnelMedicationName = 'health-owner-carpal-tunnel-medication-name',
  healthOwnerCarpalTunnelMedicationDosage = 'health-owner-carpal-tunnel-medication-dosage',
  healthOwnerCarpalTunnelMedicationStartDate = 'health-owner-carpal-tunnel-medication-start-date',
  healthOwnerCarpalTunnelMedicationEndDate = 'health-owner-carpal-tunnel-medication-end-date',

  healthOwnerMuscularDystrophyType = 'health-owner-muscular-dystrophy-type',
  healthOwnerMuscularDystrophyDiagnosisDate = 'health-owner-muscular-dystrophy-diagnosis-date',
  healthOwnerMuscularDystrophyIsSingleEpisode = 'health-owner-muscular-dystrophy-is-single-episode',
  healthOwnerMuscularDystrophyPreviousEpisodesCount = 'health-owner-muscular-dystrophy-previous-episodes-count',
  healthOwnerMuscularDystrophyDateSymptoms = 'health-owner-muscular-dystrophy-date-symptoms',
  healthOwnerMuscularDystrophyPainLocation = 'health-owner-muscular-dystrophy-pain-location',
  healthOwnerMuscularDystrophyPainLocationLeftRight = 'health-owner-muscular-dystrophy-pain-location-left-right',
  healthOwnerMuscularDystrophyPainLocationOther = 'health-owner-muscular-dystrophy-pain-location-other',
  healthOwnerMuscularDystrophyPainCause = 'health-owner-muscular-dystrophy-pain-cause',
  healthOwnerMuscularDystrophyPainCauseDetails = 'health-owner-muscular-dystrophy-pain-cause-details',
  healthOwnerMuscularDystrophyHasConsultedDoctors = 'health-owner-muscular-dystrophy-consulted-doctors',
  healthOwnerMuscularDystrophyHasTakenTestsOrExams = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams',
  healthOwnerMuscularDystrophyMedicationWasPrescribed = 'health-owner-muscular-dystrophy-medication-was-prescribed',
  healthOwnerMuscularDystrophyHadOperation = 'health-owner-muscular-dystrophy-had-operation',
  healthOwnerMuscularDystrophyOperationPerformedOrPlanned = 'health-owner-muscular-dystrophy-operation-performed-or-planned',
  healthOwnerMuscularDystrophyTypeOfOperationPerformed = 'health-owner-muscular-dystrophy-type-of-operation-performed',
  healthOwnerMuscularDystrophyOperationPerformedDate = 'health-owner-muscular-dystrophy-operation-performed-date',
  healthOwnerMuscularDystrophyTypeOfOperationPlanned = 'health-owner-muscular-dystrophy-type-of-operation-planned',
  healthOwnerMuscularDystrophyOperationPlannedDate = 'health-owner-muscular-dystrophy-operation-planned-date',
  healthOwnerMuscularDystrophyOperationUnknownDate = 'health-owner-muscular-dystrophy-operation-unknown-date',
  healthOwnerMuscularDystrophyHadAbsence = 'health-owner-muscular-dystrophy-had-absence',
  healthOwnerMuscularDystrophyAbsenceStartDate = 'health-owner-muscular-dystrophy-absence-start-date',
  healthOwnerMuscularDystrophyAbsenceFrequencyNumber = 'health-owner-muscular-dystrophy-absence-frequency-number',
  healthOwnerMuscularDystrophyAbsenceFrequency = 'health-owner-muscular-dystrophy-absence-frequency',
  healthOwnerMuscularDystrophyAbsenceHasRecovered = 'health-owner-muscular-dystrophy-absence-has-recovered',
  healthOwnerMuscularDystrophyAbsenceRecoveryDate = 'health-owner-muscular-dystrophy-absence-recovery-date',
  healthOwnerMuscularDystrophyAbsenceNotRecoveredDetails = 'health-owner-muscular-dystrophy-absence-not-recovered-details',

  healthOwnerMuscularDystrophyHasConsultedDoctorsDetails = 'health-owner-muscular-dystrophy-has-consulted-doctors-details',
  healthOwnerMuscularDystrophyHasConsultedDoctorsProfession = 'health-owner-muscular-dystrophy-has-consulted-doctors-profession',
  healthOwnerMuscularDystrophyHasConsultedDoctorsProfessionDetails = 'health-owner-muscular-dystrophy-has-consulted-doctors-profession-details',
  healthOwnerMuscularDystrophyHasConsultedDoctorsName = 'health-owner-muscular-dystrophy-has-consulted-doctors-name',
  healthOwnerMuscularDystrophyHasConsultedDoctorsAddress = 'health-owner-muscular-dystrophy-has-consulted-doctors-address',
  healthOwnerMuscularDystrophyHasConsultedDoctorsFirstDate = 'health-owner-muscular-dystrophy-has-consulted-doctors-first-date',
  healthOwnerMuscularDystrophyHasConsultedDoctorsLastDate = 'health-owner-muscular-dystrophy-has-consulted-doctors-last-date',
  healthOwnerMuscularDystrophyHasConsultedDoctorsResult = 'health-owner-muscular-dystrophy-has-consulted-doctors-result',
  healthOwnerMuscularDystrophyHasConsultedDoctorsFrequencyNumber = 'health-owner-muscular-dystrophy-has-consulted-doctors-frequency-number',
  healthOwnerMuscularDystrophyHasConsultedDoctorsFrequency = 'health-owner-muscular-dystrophy-has-consulted-doctors-frequency',
  healthOwnerMuscularDystrophyHasConsultedDoctorFurtherConsultation = 'health-owner-muscular-dystrophy-has-consulted-doctor-further-consultation',
  healthOwnerMuscularDystrophyHasConsultedDoctorsNextConsultationDate = 'health-owner-muscular-dystrophy-has-consulted-doctors-next-consultation-date',
  healthOwnerMuscularDystrophyHasTakenTestsOrExamsDetails = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams-details',
  healthOwnerMuscularDystrophyHasTakenTestsOrExamsType = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams-type',
  healthOwnerMuscularDystrophyHasTakenTestsOrExamsDate = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams-date',
  healthOwnerMuscularDystrophyHasTakenTestsOrExamsResult = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams-result',
  healthOwnerMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-muscular-dystrophy-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMuscularDystrophyMedicationDetails = 'health-owner-muscular-dystrophy-medication-details',
  healthOwnerMuscularDystrophyMedicationName = 'health-owner-muscular-dystrophy-medication-name',
  healthOwnerMuscularDystrophyMedicationDosage = 'health-owner-muscular-dystrophy-medication-dosage',
  healthOwnerMuscularDystrophyMedicationStartDate = 'health-owner-muscular-dystrophy-medication-start-date',
  healthOwnerMuscularDystrophyMedicationEndDate = 'health-owner-muscular-dystrophy-medication-end-date',

  healthOwnerInsomniaMedicationWasPrescribed = 'health-owner-insomnia-medication-was-prescribed',
  healthOwnerInsomniaHasConsultedDoctors = 'health-owner-insomnia-consulted-doctors',
  healthOwnerInsomniaHasConsultedOtherDoctorsOrTherapists = 'health-owner-insomnia-has-consulted-other-doctors-or-therapists',
  healthOwnerInsomniaHadAbsence = 'health-owner-insomnia-had-absence',
  healthOwnerInsomniaAbsenceHasRecovered = 'health-owner-insomnia-absence-has-recovered',
  healthOwnerInsomniaAbsenceRecoveryDate = 'health-owner-insomnia-absence-recovery-date',
  healthOwnerInsomniaAbsenceNotRecoveredDetails = 'health-owner-insomnia-absence-not-recovered-details',
  healthOwnerInsomniaMedicationDetails = 'health-owner-insomnia-medication-details',
  healthOwnerInsomniaMedicationName = 'health-owner-insomnia-medication-name',
  healthOwnerInsomniaMedicationDosage = 'health-owner-insomnia-medication-dosage',
  healthOwnerInsomniaMedicationStartDate = 'health-owner-insomnia-medication-start-date',
  healthOwnerInsomniaMedicationEndDate = 'health-owner-insomnia-medication-end-date',
  healthOwnerInsomniaMedicationStillUses = 'health-owner-insomnia-medication-still-uses',
  healthOwnerInsomniaMedicationHasChanged = 'health-owner-insomnia-medication-has-changed',
  healthOwnerInsomniaMedicationHasChangedDetails = 'health-owner-insomnia-medication-has-changed-details',
  healthOwnerInsomniaHasOrWillConsultDoctorsDetails = 'health-owner-insomnia-has-or-will-consult-doctors-details',
  healthOwnerInsomniaHasOrWillConsultDoctorsProfession = 'health-owner-insomnia-has-or-will-consult-doctors-profession',
  healthOwnerInsomniaHasOrWillConsultDoctorsProfessionOther = 'health-owner-insomnia-has-or-will-consult-doctors-profession-other',
  healthOwnerInsomniaHasOrWillConsultDoctorsName = 'health-owner-insomnia-has-or-will-consult-doctors-name',
  healthOwnerInsomniaHasOrWillConsultDoctorsAddress = 'health-owner-insomnia-has-or-will-consult-doctors-address',
  healthOwnerInsomniaHasOrWillConsultDoctorsFirstDate = 'health-owner-insomnia-has-or-will-consult-doctors-first-date',
  healthOwnerInsomniaHasOrWillConsultDoctorsLastDate = 'health-owner-insomnia-has-or-will-consult-doctors-last-date',
  healthOwnerInsomniaHasOrWillConsultDoctorsResult = 'health-owner-insomnia-has-or-will-consult-doctors-result',
  healthOwnerInsomniaHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-insomnia-has-or-will-consult-doctors-frequency-number',
  healthOwnerInsomniaHasOrWillConsultDoctorsFrequency = 'health-owner-insomnia-has-or-will-consult-doctors-frequency',
  healthOwnerInsomniaHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-insomnia-has-or-will-consult-doctors-further-consultation',
  healthOwnerInsomniaHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-insomnia-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerInsomniaHadAbsenceDetails = 'health-owner-insomnia-had-absence-details',
  healthOwnerInsomniaAbsenceStartDate = 'health-owner-insomnia-absence-start-date',
  healthOwnerInsomniaAbsenceFrequencyNumber = 'health-owner-insomnia-absence-frequency-number',
  healthOwnerInsomniaAbsenceFrequency = 'health-owner-insomnia-absence-frequency',

  healthOwnerBipolarDisorderIsSingleEpisode = 'health-owner-bipolar-disorder-is-single-episode',
  healthOwnerBipolarDisorderDiagnosticDate = 'health-owner-bipolar-disorder-diagnostic-date',
  healthOwnerBipolarDisorderMedicationWasPrescribed = 'health-owner-bipolar-disorder-medication-was-prescribed',
  healthOwnerBipolarDisorderHasConsultedDoctors = 'health-owner-bipolar-disorder-consulted-doctors',
  healthOwnerBipolarDisorderHasConsultedOtherDoctorsOrTherapists = 'health-owner-bipolar-disorder-has-consulted-other-doctors-or-therapists',
  healthOwnerBipolarDisorderWentToEmergencyDepartment = 'health-owner-bipolar-disorder-went-to-emergency-department',
  healthOwnerBipolarDisorderWasHospitalized = 'health-owner-bipolar-disorder-was-hospitalized',
  healthOwnerBipolarDisorderHadSuicidalThoughtsAndAttempt = 'health-owner-bipolar-disorder-had-suicidal-thoughts-and-attempt',
  healthOwnerBipolarDisorderSuicidalThoughtsAndAttemptChoices = 'health-owner-bipolar-disorder-suicidal-thoughts-and-attempt-choices',
  healthOwnerBipolarDisorderSuicidalThoughtsFrequencyNumber = 'health-owner-bipolar-disorder-suicidal-thoughts-frequency-number',
  healthOwnerBipolarDisorderSuicidalThoughtsFrequency = 'health-owner-bipolar-disorder-suicidal-thoughts-frequency',
  healthOwnerBipolarDisorderSuicideAttemptsNumber = 'health-owner-bipolar-disorder-suicide-attempts-number',
  healthOwnerBipolarDisorderHadAbsence = 'health-owner-bipolar-disorder-had-absence',
  healthOwnerBipolarDisorderAbsenceHasRecovered = 'health-owner-bipolar-disorder-absence-has-recovered',
  healthOwnerBipolarDisorderAbsenceRecoveryDate = 'health-owner-bipolar-disorder-absence-recovery-date',
  healthOwnerBipolarDisorderAbsenceNotRecoveredDetails = 'health-owner-bipolar-disorder-absence-not-recovered-details',

  healthOwnerBipolarDisorderMedicationDetails = 'health-owner-bipolar-disorder-medication-details',
  healthOwnerBipolarDisorderMedicationName = 'health-owner-bipolar-disorder-medication-name',
  healthOwnerBipolarDisorderMedicationDosage = 'health-owner-bipolar-disorder-medication-dosage',
  healthOwnerBipolarDisorderMedicationStartDate = 'health-owner-bipolar-disorder-medication-start-date',
  healthOwnerBipolarDisorderMedicationEndDate = 'health-owner-bipolar-disorder-medication-end-date',
  healthOwnerBipolarDisorderMedicationStillUses = 'health-owner-bipolar-disorder-medication-still-uses',
  healthOwnerBipolarDisorderMedicationHasChanged = 'health-owner-bipolar-disorder-medication-has-changed',
  healthOwnerBipolarDisorderMedicationHasChangedDetails = 'health-owner-bipolar-disorder-medication-has-changed-details',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsDetails = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-details',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsProfession = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-profession',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsProfessionOther = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-profession-other',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsName = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-name',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsAddress = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-address',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsFirstDate = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-first-date',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsLastDate = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-last-date',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsResult = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-result',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-frequency-number',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsFrequency = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-frequency',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-further-consultation',
  healthOwnerBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-bipolar-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerBipolarDisorderWentToEmergencyDepartmentDetails = 'health-owner-bipolar-disorder-went-to-emergency-department-details',
  healthOwnerBipolarDisorderWentToEmergencyDepartmentDate = 'health-owner-bipolar-disorder-went-to-emergency-department-date',
  healthOwnerBipolarDisorderWentToEmergencyDepartmentLocation = 'health-owner-bipolar-disorder-went-to-emergency-department-location',
  healthOwnerBipolarDisorderWasHospitalizedDetails = 'health-owner-bipolar-disorder-was-hospitalized-details',
  healthOwnerBipolarDisorderWasHospitalizedDate = 'health-owner-bipolar-disorder-was-hospitalized-date',
  healthOwnerBipolarDisorderWasHospitalizedFrequencyNumber = 'health-owner-bipolar-disorder-was-hospitalized-frequency-number',
  healthOwnerBipolarDisorderWasHospitalizedFrequency = 'health-owner-bipolar-disorder-was-hospitalized-frequency',
  healthOwnerBipolarDisorderWasHospitalizedLocation = 'health-owner-bipolar-disorder-was-hospitalized-location',
  healthOwnerBipolarDisorderSuicidalThoughtsDetails = 'health-owner-bipolar-disorder-suicidal-thoughts-details',
  healthOwnerBipolarDisorderSuicidalThoughtsDate = 'health-owner-bipolar-disorder-suicidal-thoughts-date',
  healthOwnerBipolarDisorderSuicideAttemptsDetails = 'health-owner-bipolar-disorder-suicide-attempts-details',
  healthOwnerBipolarDisorderSuicideAttemptsDate = 'health-owner-bipolar-disorder-suicide-attempts-date',
  healthOwnerBipolarDisorderHadAbsenceDetails = 'health-owner-bipolar-disorder-had-absence-details',
  healthOwnerBipolarDisorderAbsenceStartDate = 'health-owner-bipolar-disorder-absence-start-date',
  healthOwnerBipolarDisorderAbsenceFrequencyNumber = 'health-owner-bipolar-disorder-absence-frequency-number',
  healthOwnerBipolarDisorderAbsenceFrequency = 'health-owner-bipolar-disorder-absence-frequency',

  healthOwnerPsychosisIsSingleEpisode = 'health-owner-psychosis-is-single-episode',
  healthOwnerPsychosisDiagnosticDate = 'health-owner-psychosis-diagnostic-date',
  healthOwnerPsychosisMedicationWasPrescribed = 'health-owner-psychosis-medication-was-prescribed',
  healthOwnerPsychosisHasConsultedDoctors = 'health-owner-psychosis-consulted-doctors',
  healthOwnerPsychosisHasConsultedOtherDoctorsOrTherapists = 'health-owner-psychosis-has-consulted-other-doctors-or-therapists',
  healthOwnerPsychosisWentToEmergencyDepartment = 'health-owner-psychosis-went-to-emergency-department',
  healthOwnerPsychosisWasHospitalized = 'health-owner-psychosis-was-hospitalized',
  healthOwnerPsychosisHadSuicidalThoughtsAndAttempt = 'health-owner-psychosis-had-suicidal-thoughts-and-attempt',
  healthOwnerPsychosisSuicidalThoughtsAndAttemptChoices = 'health-owner-psychosis-suicidal-thoughts-and-attempt-choices',
  healthOwnerPsychosisSuicidalThoughtsFrequencyNumber = 'health-owner-psychosis-suicidal-thoughts-frequency-number',
  healthOwnerPsychosisSuicidalThoughtsFrequency = 'health-owner-psychosis-suicidal-thoughts-frequency',
  healthOwnerPsychosisSuicideAttemptsNumber = 'health-owner-psychosis-suicide-attempts-number',
  healthOwnerPsychosisHadAbsence = 'health-owner-psychosis-had-absence',
  healthOwnerPsychosisAbsenceHasRecovered = 'health-owner-psychosis-absence-has-recovered',
  healthOwnerPsychosisAbsenceRecoveryDate = 'health-owner-psychosis-absence-recovery-date',
  healthOwnerPsychosisAbsenceNotRecoveredDetails = 'health-owner-psychosis-absence-not-recovered-details',

  healthOwnerPsychosisMedicationDetails = 'health-owner-psychosis-medication-details',
  healthOwnerPsychosisMedicationName = 'health-owner-psychosis-medication-name',
  healthOwnerPsychosisMedicationDosage = 'health-owner-psychosis-medication-dosage',
  healthOwnerPsychosisMedicationStartDate = 'health-owner-psychosis-medication-start-date',
  healthOwnerPsychosisMedicationEndDate = 'health-owner-psychosis-medication-end-date',
  healthOwnerPsychosisMedicationStillUses = 'health-owner-psychosis-medication-still-uses',
  healthOwnerPsychosisMedicationHasChanged = 'health-owner-psychosis-medication-has-changed',
  healthOwnerPsychosisMedicationHasChangedDetails = 'health-owner-psychosis-medication-has-changed-details',
  healthOwnerPsychosisHasOrWillConsultDoctorsDetails = 'health-owner-psychosis-has-or-will-consult-doctors-details',
  healthOwnerPsychosisHasOrWillConsultDoctorsProfession = 'health-owner-psychosis-has-or-will-consult-doctors-profession',
  healthOwnerPsychosisHasOrWillConsultDoctorsProfessionOther = 'health-owner-psychosis-has-or-will-consult-doctors-profession-other',
  healthOwnerPsychosisHasOrWillConsultDoctorsName = 'health-owner-psychosis-has-or-will-consult-doctors-name',
  healthOwnerPsychosisHasOrWillConsultDoctorsAddress = 'health-owner-psychosis-has-or-will-consult-doctors-address',
  healthOwnerPsychosisHasOrWillConsultDoctorsFirstDate = 'health-owner-psychosis-has-or-will-consult-doctors-first-date',
  healthOwnerPsychosisHasOrWillConsultDoctorsLastDate = 'health-owner-psychosis-has-or-will-consult-doctors-last-date',
  healthOwnerPsychosisHasOrWillConsultDoctorsResult = 'health-owner-psychosis-has-or-will-consult-doctors-result',
  healthOwnerPsychosisHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-psychosis-has-or-will-consult-doctors-frequency-number',
  healthOwnerPsychosisHasOrWillConsultDoctorsFrequency = 'health-owner-psychosis-has-or-will-consult-doctors-frequency',
  healthOwnerPsychosisHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-psychosis-has-or-will-consult-doctors-further-consultation',
  healthOwnerPsychosisHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-psychosis-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerPsychosisWentToEmergencyDepartmentDetails = 'health-owner-psychosis-went-to-emergency-department-details',
  healthOwnerPsychosisWentToEmergencyDepartmentDate = 'health-owner-psychosis-went-to-emergency-department-date',
  healthOwnerPsychosisWentToEmergencyDepartmentLocation = 'health-owner-psychosis-went-to-emergency-department-location',
  healthOwnerPsychosisWasHospitalizedDetails = 'health-owner-psychosis-was-hospitalized-details',
  healthOwnerPsychosisWasHospitalizedDate = 'health-owner-psychosis-was-hospitalized-date',
  healthOwnerPsychosisWasHospitalizedFrequencyNumber = 'health-owner-psychosis-was-hospitalized-frequency-number',
  healthOwnerPsychosisWasHospitalizedFrequency = 'health-owner-psychosis-was-hospitalized-frequency',
  healthOwnerPsychosisWasHospitalizedLocation = 'health-owner-psychosis-was-hospitalized-location',
  healthOwnerPsychosisSuicidalThoughtsDetails = 'health-owner-psychosis-suicidal-thoughts-details',
  healthOwnerPsychosisSuicidalThoughtsDate = 'health-owner-psychosis-suicidal-thoughts-date',
  healthOwnerPsychosisSuicideAttemptsDetails = 'health-owner-psychosis-suicide-attempts-details',
  healthOwnerPsychosisSuicideAttemptsDate = 'health-owner-psychosis-suicide-attempts-date',
  healthOwnerPsychosisHadAbsenceDetails = 'health-owner-psychosis-had-absence-details',
  healthOwnerPsychosisAbsenceStartDate = 'health-owner-psychosis-absence-start-date',
  healthOwnerPsychosisAbsenceFrequencyNumber = 'health-owner-psychosis-absence-frequency-number',
  healthOwnerPsychosisAbsenceFrequency = 'health-owner-psychosis-absence-frequency',

  healthOwnerSuicideAttemptMedicationWasPrescribed = 'health-owner-suicide-attempt-medication-was-prescribed',
  healthOwnerSuicideAttemptHasConsultedDoctors = 'health-owner-suicide-attempt-consulted-doctors',
  healthOwnerSuicideAttemptHasConsultedOtherDoctorsOrTherapists = 'health-owner-suicide-attempt-has-consulted-other-doctors-or-therapists',
  healthOwnerSuicideAttemptWasHospitalized = 'health-owner-suicide-attempt-was-hospitalized',
  healthOwnerSuicideAttemptDate = 'health-owner-suicide-attempt-date',
  healthOwnerSuicideAttemptReason = 'health-owner-suicide-attempt-reason',
  healthOwnerSuicideAttemptHasMoreThanOneAttempt = 'health-owner-suicide-attempt-has-more-than-one-attempt',
  healthOwnerSuicideAttemptHowManyAttempts = 'health-owner-suicide-attempt-how-many-attempts',

  healthOwnerSuicideAttemptMedicationDetails = 'health-owner-suicide-attempt-medication-details',
  healthOwnerSuicideAttemptMedicationName = 'health-owner-suicide-attempt-medication-name',
  healthOwnerSuicideAttemptMedicationDosage = 'health-owner-suicide-attempt-medication-dosage',
  healthOwnerSuicideAttemptMedicationStartDate = 'health-owner-suicide-attempt-medication-start-date',
  healthOwnerSuicideAttemptMedicationEndDate = 'health-owner-suicide-attempt-medication-end-date',
  healthOwnerSuicideAttemptMedicationStillUses = 'health-owner-suicide-attempt-medication-still-uses',
  healthOwnerSuicideAttemptMedicationHasChanged = 'health-owner-suicide-attempt-medication-has-changed',
  healthOwnerSuicideAttemptMedicationHasChangedDetails = 'health-owner-suicide-attempt-medication-has-changed-details',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsDetails = 'health-owner-suicide-attempt-has-or-will-consult-doctors-details',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsProfession = 'health-owner-suicide-attempt-has-or-will-consult-doctors-profession',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsProfessionOther = 'health-owner-suicide-attempt-has-or-will-consult-doctors-profession-other',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsName = 'health-owner-suicide-attempt-has-or-will-consult-doctors-name',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsAddress = 'health-owner-suicide-attempt-has-or-will-consult-doctors-address',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsFirstDate = 'health-owner-suicide-attempt-has-or-will-consult-doctors-first-date',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsLastDate = 'health-owner-suicide-attempt-has-or-will-consult-doctors-last-date',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsResult = 'health-owner-suicide-attempt-has-or-will-consult-doctors-result',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-suicide-attempt-has-or-will-consult-doctors-frequency-number',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsFrequency = 'health-owner-suicide-attempt-has-or-will-consult-doctors-frequency',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-suicide-attempt-has-or-will-consult-doctors-further-consultation',
  healthOwnerSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-suicide-attempt-next-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerSuicideAttemptWasHospitalizedDetails = 'health-owner-suicide-attempt-was-hospitalized-details',
  healthOwnerSuicideAttemptWasHospitalizedDate = 'health-owner-suicide-attempt-was-hospitalized-date',
  healthOwnerSuicideAttemptWasHospitalizedFrequencyNumber = 'health-owner-suicide-attempt-was-hospitalized-frequency-number',
  healthOwnerSuicideAttemptWasHospitalizedFrequency = 'health-owner-suicide-attempt-was-hospitalized-frequency',
  healthOwnerSuicideAttemptWasHospitalizedLocation = 'health-owner-suicide-attempt-was-hospitalized-location',
  healthOwnerSuicideAttemptMultipleAttemptsDetails = 'health-owner-suicide-attempt-multiple-attempts-details',
  healthOwnerSuicideAttemptMultipleAttemptsDate = 'health-owner-suicide-attempt-multiple-attempts-date',
  healthOwnerSuicideAttemptMultipleAttemptsReason = 'health-owner-suicide-attempt-multiple-attempts-reason',

  healthOwnerEatingDisorderDiagnosticDate = 'health-owner-eating-disorder-diagnostic-date',
  healthOwnerEatingDisorderMedicationWasPrescribed = 'health-owner-eating-disorder-medication-was-prescribed',
  healthOwnerEatingDisorderHasConsultedDoctors = 'health-owner-eating-disorder-consulted-doctors',
  healthOwnerEatingDisorderHasConsultedOtherDoctorsOrTherapists = 'health-owner-eating-disorder-has-consulted-other-doctors-or-therapists',
  healthOwnerEatingDisorderWasHospitalized = 'health-owner-eating-disorder-was-hospitalized',
  healthOwnerEatingDisorderIsControlled = 'health-owner-eating-disorder-is-controlled',
  healthOwnerEatingDisorderNotControlledDetails = 'health-owner-eating-disorder-not-controlled-details',
  healthOwnerEatingDisorderHasStableWeightDate = 'health-owner-eating-disorder-has-stable-weight-date',

  healthOwnerEatingDisorderMedicationDetails = 'health-owner-eating-disorder-medication-details',
  healthOwnerEatingDisorderMedicationName = 'health-owner-eating-disorder-medication-name',
  healthOwnerEatingDisorderMedicationDosage = 'health-owner-eating-disorder-medication-dosage',
  healthOwnerEatingDisorderMedicationStartDate = 'health-owner-eating-disorder-medication-start-date',
  healthOwnerEatingDisorderMedicationEndDate = 'health-owner-eating-disorder-medication-end-date',
  healthOwnerEatingDisorderMedicationStillUses = 'health-owner-eating-disorder-medication-still-uses',
  healthOwnerEatingDisorderMedicationHasChanged = 'health-owner-eating-disorder-medication-has-changed',
  healthOwnerEatingDisorderMedicationHasChangedDetails = 'health-owner-eating-disorder-medication-has-changed-details',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsDetails = 'health-owner-eating-disorder-has-or-will-consult-doctors-details',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsProfession = 'health-owner-eating-disorder-has-or-will-consult-doctors-profession',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsProfessionOther = 'health-owner-eating-disorder-has-or-will-consult-doctors-profession-other',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsName = 'health-owner-eating-disorder-has-or-will-consult-doctors-name',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsAddress = 'health-owner-eating-disorder-has-or-will-consult-doctors-address',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsFirstDate = 'health-owner-eating-disorder-has-or-will-consult-doctors-first-date',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsLastDate = 'health-owner-eating-disorder-has-or-will-consult-doctors-last-date',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsResult = 'health-owner-eating-disorder-has-or-will-consult-doctors-result',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-eating-disorder-has-or-will-consult-doctors-frequency-number',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsFrequency = 'health-owner-eating-disorder-has-or-will-consult-doctors-frequency',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-eating-disorder-has-or-will-consult-doctors-further-consultation',
  healthOwnerEatingDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-eating-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerEatingDisorderWasHospitalizedDetails = 'health-owner-eating-disorder-was-hospitalized-details',
  healthOwnerEatingDisorderWasHospitalizedDate = 'health-owner-eating-disorder-was-hospitalized-date',
  healthOwnerEatingDisorderWasHospitalizedFrequencyNumber = 'health-owner-eating-disorder-was-hospitalized-frequency-number',
  healthOwnerEatingDisorderWasHospitalizedFrequency = 'health-owner-eating-disorder-was-hospitalized-frequency',
  healthOwnerEatingDisorderWasHospitalizedLocation = 'health-owner-eating-disorder-was-hospitalized-location',

  healthOwnerAddAdhdMedicationWasPrescribed = 'health-owner-add-adhd-medication-was-prescribed',
  healthOwnerAddAdhdHasConsultedDoctors = 'health-owner-add-adhd-consulted-doctors',
  healthOwnerAddAdhdHasConsultedOtherDoctorsOrTherapists = 'health-owner-add-adhd-has-consulted-other-doctors-or-therapists',
  healthOwnerAddAdhdIsControlled = 'health-owner-add-adhd-is-controlled',
  healthOwnerAddAdhdHasStableWeightDate = 'health-owner-add-adhd-has-stable-weight-date',
  healthOwnerAddAdhdNotControlledDetails = 'health-owner-add-adhd-not-controlled-details',

  healthOwnerAddAdhdMedicationDetails = 'health-owner-add-adhd-medication-details',
  healthOwnerAddAdhdMedicationName = 'health-owner-add-adhd-medication-name',
  healthOwnerAddAdhdMedicationDosage = 'health-owner-add-adhd-medication-dosage',
  healthOwnerAddAdhdMedicationStartDate = 'health-owner-add-adhd-medication-start-date',
  healthOwnerAddAdhdMedicationEndDate = 'health-owner-add-adhd-medication-end-date',
  healthOwnerAddAdhdMedicationStillUses = 'health-owner-add-adhd-medication-still-uses',
  healthOwnerAddAdhdMedicationHasChanged = 'health-owner-add-adhd-medication-has-changed',
  healthOwnerAddAdhdMedicationHasChangedDetails = 'health-owner-add-adhd-medication-has-changed-details',
  healthOwnerAddAdhdHasOrWillConsultDoctorsDetails = 'health-owner-add-adhd-has-or-will-consult-doctors-details',
  healthOwnerAddAdhdHasOrWillConsultDoctorsProfession = 'health-owner-add-adhd-has-or-will-consult-doctors-profession',
  healthOwnerAddAdhdHasOrWillConsultDoctorsProfessionOther = 'health-owner-add-adhd-has-or-will-consult-doctors-profession-other',
  healthOwnerAddAdhdHasOrWillConsultDoctorsName = 'health-owner-add-adhd-has-or-will-consult-doctors-name',
  healthOwnerAddAdhdHasOrWillConsultDoctorsAddress = 'health-owner-add-adhd-has-or-will-consult-doctors-address',
  healthOwnerAddAdhdHasOrWillConsultDoctorsFirstDate = 'health-owner-add-adhd-has-or-will-consult-doctors-first-date',
  healthOwnerAddAdhdHasOrWillConsultDoctorsLastDate = 'health-owner-add-adhd-has-or-will-consult-doctors-last-date',
  healthOwnerAddAdhdHasOrWillConsultDoctorsResult = 'health-owner-add-adhd-has-or-will-consult-doctors-result',
  healthOwnerAddAdhdHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-add-adhd-has-or-will-consult-doctors-frequency-number',
  healthOwnerAddAdhdHasOrWillConsultDoctorsFrequency = 'health-owner-add-adhd-has-or-will-consult-doctors-frequency',
  healthOwnerAddAdhdHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-add-adhd-has-or-will-consult-doctors-further-consultation',
  healthOwnerAddAdhdHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-add-adhd-has-or-will-consult-doctors-next-consultation-date',

  healthOwnerAutismMedicationWasPrescribed = 'health-owner-autism-medication-was-prescribed',
  healthOwnerAutismHasConsultedDoctors = 'health-owner-autism-consulted-doctors',
  healthOwnerAutismHasConsultedOtherDoctorsOrTherapists = 'health-owner-autism-has-consulted-other-doctors-or-therapists',
  healthOwnerAutismPerformsDailyActivitiesWithoutHelp = 'health-owner-autism-performs-daily-activities-without-help',
  healthOwnerAutismPerformsDailyActivitiesWithoutHelpDetails = 'health-owner-autism-performs-daily-activities-without-help-details',
  healthOwnerAutismNeedsAccommodation = 'health-owner-autism-needs-accommodation',
  healthOwnerAutismAccommodationDetails = 'health-owner-autism-accommodation-details',

  healthOwnerAutismMedicationDetails = 'health-owner-autism-medication-details',
  healthOwnerAutismMedicationName = 'health-owner-autism-medication-name',
  healthOwnerAutismMedicationDosage = 'health-owner-autism-medication-dosage',
  healthOwnerAutismMedicationStartDate = 'health-owner-autism-medication-start-date',
  healthOwnerAutismMedicationEndDate = 'health-owner-autism-medication-end-date',
  healthOwnerAutismMedicationStillUses = 'health-owner-autism-medication-still-uses',
  healthOwnerAutismMedicationHasChanged = 'health-owner-autism-medication-has-changed',
  healthOwnerAutismMedicationHasChangedDetails = 'health-owner-autism-medication-has-changed-details',
  healthOwnerAutismHasOrWillConsultDoctorsDetails = 'health-owner-autism-has-or-will-consult-doctors-details',
  healthOwnerAutismHasOrWillConsultDoctorsProfession = 'health-owner-autism-has-or-will-consult-doctors-profession',
  healthOwnerAutismHasOrWillConsultDoctorsProfessionOther = 'health-owner-autism-has-or-will-consult-doctors-profession-other',
  healthOwnerAutismHasOrWillConsultDoctorsName = 'health-owner-autism-has-or-will-consult-doctors-name',
  healthOwnerAutismHasOrWillConsultDoctorsAddress = 'health-owner-autism-has-or-will-consult-doctors-address',
  healthOwnerAutismHasOrWillConsultDoctorsFirstDate = 'health-owner-autism-has-or-will-consult-doctors-first-date',
  healthOwnerAutismHasOrWillConsultDoctorsLastDate = 'health-owner-autism-has-or-will-consult-doctors-last-date',
  healthOwnerAutismHasOrWillConsultDoctorsResult = 'health-owner-autism-has-or-will-consult-doctors-result',
  healthOwnerAutismHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-autism-has-or-will-consult-doctors-frequency-number',
  healthOwnerAutismHasOrWillConsultDoctorsFrequency = 'health-owner-autism-has-or-will-consult-doctors-frequency',
  healthOwnerAutismHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-autism-has-or-will-consult-doctors-further-consultation',
  healthOwnerAutismHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-autism-has-or-will-consult-doctors-next-consultation-date',

  healthOwnerIntellectualImpairmentMedicationWasPrescribed = 'health-owner-intellectual-impairment-medication-was-prescribed',
  healthOwnerIntellectualImpairmentHasConsultedDoctors = 'health-owner-intellectual-impairment-consulted-doctors',
  healthOwnerIntellectualImpairmentHasConsultedOtherDoctorsOrTherapists = 'health-owner-intellectual-impairment-has-consulted-other-doctors-or-therapists',
  healthOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelp = 'health-owner-intellectual-impairment-performs-daily-activities-without-help',
  healthOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelpDetails = 'health-owner-intellectual-impairment-performs-daily-activities-without-help-details',
  healthOwnerIntellectualImpairmentNeedsAccommodation = 'health-owner-intellectual-impairment-neds-accommodation',
  healthOwnerIntellectualImpairmentAccommodationDetails = 'health-owner-intellectual-impairment-accommodation-details',

  healthOwnerIntellectualImpairmentMedicationDetails = 'health-owner-intellectual-impairment-medication-details',
  healthOwnerIntellectualImpairmentMedicationName = 'health-owner-intellectual-impairment-medication-name',
  healthOwnerIntellectualImpairmentMedicationDosage = 'health-owner-intellectual-impairment-medication-dosage',
  healthOwnerIntellectualImpairmentMedicationStartDate = 'health-owner-intellectual-impairment-medication-start-date',
  healthOwnerIntellectualImpairmentMedicationEndDate = 'health-owner-intellectual-impairment-medication-end-date',
  healthOwnerIntellectualImpairmentMedicationStillUses = 'health-owner-intellectual-impairment-medication-still-uses',
  healthOwnerIntellectualImpairmentMedicationHasChanged = 'health-owner-intellectual-impairment-medication-has-changed',
  healthOwnerIntellectualImpairmentMedicationHasChangedDetails = 'health-owner-intellectual-impairment-medication-has-changed-details',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsDetails = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-details',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfession = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-profession',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-profession-other',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsName = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-name',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsAddress = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-address',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFirstDate = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-first-date',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsLastDate = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-last-date',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsResult = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-result',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-frequency-number',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequency = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-frequency',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-further-consultation',
  healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-intellectual-impairment-has-or-will-consult-doctors-next-consultation-date',

  healthOwnerDownSyndromeMedicationWasPrescribed = 'health-owner-down-syndrome-medication-was-prescribed',
  healthOwnerDownSyndromeHasConsultedDoctors = 'health-owner-down-syndrome-consulted-doctors',
  healthOwnerDownSyndromeHasConsultedOtherDoctorsOrTherapists = 'health-owner-down-syndrome-has-consulted-other-doctors-or-therapists',
  healthOwnerDownSyndromePerformsDailyActivitiesWithoutHelp = 'health-owner-down-syndrome-performs-daily-activities-without-help',
  healthOwnerDownSyndromePerformsDailyActivitiesWithoutHelpDetails = 'health-owner-down-syndrome-performs-daily-activities-without-help-details',
  healthOwnerDownSyndromeNeedsAccommodation = 'health-owner-down-syndrome-needs-accommodation',
  healthOwnerDownSyndromeAccommodationDetails = 'health-owner-down-syndrome-accommodation-details',

  healthOwnerDownSyndromeMedicationDetails = 'health-owner-down-syndrome-medication-details',
  healthOwnerDownSyndromeMedicationName = 'health-owner-down-syndrome-medication-name',
  healthOwnerDownSyndromeMedicationDosage = 'health-owner-down-syndrome-medication-dosage',
  healthOwnerDownSyndromeMedicationStartDate = 'health-owner-down-syndrome-medication-start-date',
  healthOwnerDownSyndromeMedicationEndDate = 'health-owner-down-syndrome-medication-end-date',
  healthOwnerDownSyndromeMedicationStillUses = 'health-owner-down-syndrome-medication-still-uses',
  healthOwnerDownSyndromeMedicationHasChanged = 'health-owner-down-syndrome-medication-has-changed',
  healthOwnerDownSyndromeMedicationHasChangedDetails = 'health-owner-down-syndrome-medication-has-changed-details',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsDetails = 'health-owner-down-syndrome-has-or-will-consult-doctors-details',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsProfession = 'health-owner-down-syndrome-has-or-will-consult-doctors-profession',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsProfessionOther = 'health-owner-down-syndrome-has-or-will-consult-doctors-profession-other',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsName = 'health-owner-down-syndrome-has-or-will-consult-doctors-name',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsAddress = 'health-owner-down-syndrome-has-or-will-consult-doctors-address',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsFirstDate = 'health-owner-down-syndrome-has-or-will-consult-doctors-first-date',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsLastDate = 'health-owner-down-syndrome-has-or-will-consult-doctors-last-date',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsResult = 'health-owner-down-syndrome-has-or-will-consult-doctors-result',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-down-syndrome-has-or-will-consult-doctors-frequency-number',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsFrequency = 'health-owner-down-syndrome-has-or-will-consult-doctors-frequency',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-down-syndrome-has-or-will-consult-doctors-further-consultation',
  healthOwnerDownSyndromeHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-down-syndrome-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerMentalHealthHasCondition = 'health-owner-mental-health-has-condition',
  healthOwnerMentalHealthApplicableConditions = 'health-owner-mental-health-applicable-conditions',

  healthInsuredMentalHealthOtherDiagnosticResult = 'health-insured-mental-health-other-diagnostic-result',
  healthInsuredMentalHealthOtherDiagnosticDate = 'health-insured-mental-health-other-diagnostic-date',
  healthInsuredMentalHealthOtherHasTakenTestsOrExams = 'health-insured-mental-health-other-has-taken-tests-or-exams',
  healthInsuredMentalHealthOtherMedicationWasPrescribed = 'health-insured-mental-health-other-medication-was-prescribed',
  healthInsuredMentalHealthOtherHasConsultedDoctors = 'health-insured-mental-health-other-has-consulted-doctors',
  healthInsuredMentalHealthOtherWasHospitalized = 'health-insured-mental-health-other-was-hospitalized',
  healthInsuredMentalHealthOtherHadAbsence = 'health-insured-mental-health-other-had-absence',
  healthInsuredMentalHealthOtherAbsenceHasRecovered = 'health-insured-mental-health-other-absence-has-recovered',
  healthInsuredMentalHealthOtherAbsenceRecoveryDate = 'health-insured-mental-health-other-absence-recovery-date',
  healthInsuredMentalHealthOtherAbsenceNotRecoveredDetails = 'health-insured-mental-health-other-absence-not-recovered-details',

  healthOwnerMentalHealthOtherDisorderType = 'health-owner-mental-health-other-disorder-type',
  healthOwnerMentalHealthOtherDate = 'health-owner-mental-health-other-date',
  healthOwnerMentalHealthOtherHasDiagnostic = 'health-owner-mental-health-other-has-diagnostic',
  healthOwnerMentalHealthOtherDiagnosticResult = 'health-owner-mental-health-other-diagnostic-result',
  healthOwnerMentalHealthOtherDiagnosticDate = 'health-owner-mental-health-other-diagnostic-date',
  healthOwnerMentalHealthOtherHasTakenTestsOrExams = 'health-owner-mental-health-other-has-taken-tests-or-exams',
  healthOwnerMentalHealthOtherMedicationWasPrescribed = 'health-owner-mental-health-other-medication-was-prescribed',
  healthOwnerMentalHealthOtherHasConsultedDoctors = 'health-owner-mental-health-other-consulted-doctors',
  healthOwnerMentalHealthOtherHasConsultedOtherDoctorsOrTherapists = 'health-owner-mental-health-other-has-consulted-other-doctors-or-therapists',
  healthOwnerMentalHealthOtherWentToEmergencyDepartment = 'health-owner-mental-health-other-went-to-emergency-department',
  healthOwnerMentalHealthOtherWasHospitalized = 'health-owner-mental-health-other-was-hospitalized',
  healthOwnerMentalHealthOtherHadAbsence = 'health-owner-mental-health-other-had-absence',
  healthOwnerMentalHealthOtherAbsenceHasRecovered = 'health-owner-mental-health-other-absence-has-recovered',
  healthOwnerMentalHealthOtherAbsenceRecoveryDate = 'health-owner-mental-health-other-absence-recovery-date',
  healthOwnerMentalHealthOtherAbsenceNotRecoveredDetails = 'health-owner-mental-health-other-absence-not-recovered-details',

  healthOwnerMentalHealthOtherHasTakenTestsOrExamsDetails = 'health-owner-mental-health-other-has-taken-tests-or-exams-details',
  healthOwnerMentalHealthOtherTestsOrExamsDetails = 'health-owner-mental-health-other-tests-or-exams-details',
  healthOwnerMentalHealthOtherOtherTestsOrExamsDetailsDate = 'health-owner-mental-health-other-other-tests-or-exams-details-date',
  healthOwnerMentalHealthOtherOtherTestsOrExamsDetailsResult = 'health-owner-mental-health-other-other-tests-or-exams-details-result',
  healthOwnerMentalHealthOtherMedicationDetails = 'health-owner-mental-health-other-medication-details',
  healthOwnerMentalHealthOtherMedicationName = 'health-owner-mental-health-other-medication-name',
  healthOwnerMentalHealthOtherMedicationDosage = 'health-owner-mental-health-other-medication-dosage',
  healthOwnerMentalHealthOtherMedicationStartDate = 'health-owner-mental-health-other-medication-start-date',
  healthOwnerMentalHealthOtherMedicationEndDate = 'health-owner-mental-health-other-medication-end-date',
  healthOwnerMentalHealthOtherMedicationStillUses = 'health-owner-mental-health-other-medication-still-uses',
  healthOwnerMentalHealthOtherMedicationHasChanged = 'health-owner-mental-health-other-medication-has-changed',
  healthOwnerMentalHealthOtherMedicationHasChangedDetails = 'health-owner-mental-health-other-medication-has-changed-details',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsDetails = 'health-owner-mental-health-other-has-or-will-consult-doctors-details',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsProfession = 'health-owner-mental-health-other-has-or-will-consult-doctors-profession',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsProfessionOther = 'health-owner-mental-health-other-has-or-will-consult-doctors-profession-other',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsName = 'health-owner-mental-health-other-has-or-will-consult-doctors-name',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsAddress = 'health-owner-mental-health-other-has-or-will-consult-doctors-address',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFirstDate = 'health-owner-mental-health-other-has-or-will-consult-doctors-first-date',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsLastDate = 'health-owner-mental-health-other-has-or-will-consult-doctors-last-date',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsResult = 'health-owner-mental-health-other-has-or-will-consult-doctors-result',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-mental-health-other-has-or-will-consult-doctors-frequency-number',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequency = 'health-owner-mental-health-other-has-or-will-consult-doctors-frequency',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-mental-health-other-has-or-will-consult-doctors-further-consultation',
  healthOwnerMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-mental-health-other-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerMentalHealthOtherWentToEmergencyDepartmentDetails = 'health-owner-mental-health-other-went-to-emergency-department-details',
  healthOwnerMentalHealthOtherWentToEmergencyDepartmentDate = 'health-owner-mental-health-other-went-to-emergency-department-date',
  healthOwnerMentalHealthOtherWentToEmergencyDepartmentLocation = 'health-owner-mental-health-other-went-to-emergency-department-location',
  healthOwnerMentalHealthOtherWasHospitalizedDetails = 'health-owner-mental-health-other-was-hospitalized-details',
  healthOwnerMentalHealthOtherWasHospitalizedDate = 'health-owner-mental-health-other-was-hospitalized-date',
  healthOwnerMentalHealthOtherWasHospitalizedFrequencyNumber = 'health-owner-mental-health-other-was-hospitalized-frequency-number',
  healthOwnerMentalHealthOtherWasHospitalizedFrequency = 'health-owner-mental-health-other-was-hospitalized-frequency',
  healthOwnerMentalHealthOtherWasHospitalizedLocation = 'health-owner-mental-health-other-was-hospitalized-location',
  healthOwnerMentalHealthOtherhadAbsenceDetails = 'health-owner-mental-health-other-had-absence-details',
  healthOwnerMentalHealthOtherAbsenceStartDate = 'health-owner-mental-health-other-absence-start-date',
  healthOwnerMentalHealthOtherAbsenceFrequencyNumber = 'health-owner-mental-health-other-absence-frequency-number',
  healthOwnerMentalHealthOtherAbsenceFrequency = 'health-owner-mental-health-other-absence-frequency',

  healthOwnerMusculoskeletalOtherIsSingleEpisode = 'health-owner-musculoskeletal-other-is-single-episode',
  healthOwnerMusculoskeletalOtherPreviousEpisodesCount = 'health-owner-musculoskeletal-other-previous-episodes-count',
  healthOwnerMusculoskeletalOtherDateSymptoms = 'health-owner-musculoskeletal-other-date-symptoms',
  healthOwnerMusculoskeletalOtherPainLocation = 'health-owner-musculoskeletal-other-pain-location',
  healthOwnerMusculoskeletalOtherPainLocationLeftRight = 'health-owner-musculoskeletal-other-pain-location-left-right',
  healthOwnerMusculoskeletalOtherPainLocationOther = 'health-owner-musculoskeletal-other-pain-location-other',
  healthOwnerMusculoskeletalOtherPainCause = 'health-owner-musculoskeletal-other-pain-cause',
  healthOwnerMusculoskeletalOtherPainCauseDetails = 'health-owner-musculoskeletal-other-pain-cause-details',
  healthOwnerMusculoskeletalOtherHasConsultedDoctors = 'health-owner-musculoskeletal-other-consulted-doctors',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExams = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams',
  healthOwnerMusculoskeletalOtherMedicationWasPrescribed = 'health-owner-musculoskeletal-other-medication-was-prescribed',
  healthOwnerMusculoskeletalOtherHadOperation = 'health-owner-musculoskeletal-other-had-operation',
  healthOwnerMusculoskeletalOtherOperationPerformedOrPlanned = 'health-owner-musculoskeletal-other-operation-performed-or-planned',
  healthOwnerMusculoskeletalOtherTypeOfOperationPerformed = 'health-owner-musculoskeletal-other-type-of-operation-performed',
  healthOwnerMusculoskeletalOtherOperationPerformedDate = 'health-owner-musculoskeletal-other-operation-performed-date',
  healthOwnerMusculoskeletalOtherTypeOfOperationPlanned = 'health-owner-musculoskeletal-other-type-of-operation-planned',
  healthOwnerMusculoskeletalOtherOperationPlannedDate = 'health-owner-musculoskeletal-other-operation-planned-date',
  healthOwnerMusculoskeletalOtherOperationUnknownDate = 'health-owner-musculoskeletal-other-operation-unknown-date',
  healthOwnerMusculoskeletalOtherHadAbsence = 'health-owner-musculoskeletal-other-had-absence',
  healthOwnerMusculoskeletalOtherAbsenceStartDate = 'health-owner-musculoskeletal-other-absence-start-date',
  healthOwnerMusculoskeletalOtherAbsenceFrequencyNumber = 'health-owner-musculoskeletal-other-absence-frequency-number',
  healthOwnerMusculoskeletalOtherAbsenceFrequency = 'health-owner-musculoskeletal-other-absence-frequency',
  healthOwnerMusculoskeletalOtherAbsenceHasRecovered = 'health-owner-musculoskeletal-other-absence-has-recovered',
  healthOwnerMusculoskeletalOtherAbsenceRecoveryDate = 'health-owner-musculoskeletal-other-absence-recovery-date',
  healthOwnerMusculoskeletalOtherAbsenceNotRecoveredDetails = 'health-owner-musculoskeletal-other-absence-not-recovered-details',

  healthOwnerMusculoskeletalOtherHasConsultedDoctorsDetails = 'health-owner-musculoskeletal-other-has-consulted-doctors-details',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsProfession = 'health-owner-musculoskeletal-other-has-consulted-doctors-profession',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsProfessionDetails = 'health-owner-musculoskeletal-other-has-consulted-doctors-profession-details',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsName = 'health-owner-musculoskeletal-other-has-consulted-doctors-name',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsAddress = 'health-owner-musculoskeletal-other-has-consulted-doctors-address',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsFirstDate = 'health-owner-musculoskeletal-other-has-consulted-doctors-first-date',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsLastDate = 'health-owner-musculoskeletal-other-has-consulted-doctors-last-date',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsResult = 'health-owner-musculoskeletal-other-has-consulted-doctors-result',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber = 'health-owner-musculoskeletal-other-has-consulted-doctors-frequency-number',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsFrequency = 'health-owner-musculoskeletal-other-has-consulted-doctors-frequency',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorFurtherConsultation = 'health-owner-musculoskeletal-other-has-consulted-doctor-further-consultation',
  healthOwnerMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate = 'health-owner-musculoskeletal-other-has-consulted-doctors-next-consultation-date',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetails = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams-details',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsType = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams-type',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDate = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams-date',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsResult = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams-result',
  healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-musculoskeletal-other-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMusculoskeletalOtherMedicationDetails = 'health-owner-musculoskeletal-other-medication-details',
  healthOwnerMusculoskeletalOtherMedicationName = 'health-owner-musculoskeletal-other-medication-name',
  healthOwnerMusculoskeletalOtherMedicationDosage = 'health-owner-musculoskeletal-other-medication-dosage',
  healthOwnerMusculoskeletalOtherMedicationStartDate = 'health-owner-musculoskeletal-other-medication-start-date',
  healthOwnerMusculoskeletalOtherMedicationEndDate = 'health-owner-musculoskeletal-other-medication-end-date',

  healthOwnerEpilepsyHasCondition = 'health-owner-epilepsy-has-condition',
  healthOwnerEpilepsyDetails = 'health-owner-epilepsy-details',
  healthInsuredLossOfConsciousnessBalanceDizzinessDetails = 'health-insured-loss-of-consciousness-balance-dizziness-details',
  healthInsuredNumbnessTremorsWeaknessInExtremitiesLossOfSensationDetails = 'health-insured-numbness-tremors-weakness-in-extremities-loss-of-sensation-details',
  healthOwnerNeurologicalDisordersHasCondition = 'health-owner-neurological-disorders-has-condition',
  healthOwnerNeurologicalConditions = 'health-owner-neurological-conditions',
  healthOwnerCerebralPalsyDetails = 'health-owner-cerebral-palsy-details',
  healthOwnerParalysisDetails = 'health-owner-paralysis-details',
  healthOwnerConcussionDetails = 'health-owner-concussion-details',
  healthOwnerMigrainesDetails = 'health-owner-migraines-details',
  healthOwnerBlurredVisionOrOpticNeuritisDetails = 'health-owner-blurred-vision-or-optic-neuritis-details',
  healthOwnerMultipleSclerosisDetails = 'health-owner-multiple-sclerosis-details',
  healthOwnerAlsDetails = 'health-owner-als-details',
  healthOwnerParkinsonsDetails = 'health-owner-parkinsons-details',
  healthOwnerAlzheimerDetails = 'health-owner-alzheimer-details',
  healthOwnerLossOfConsciousnessDetails = 'health-owner-loss-of-consciousness-details',
  healthOwnerNumbnessDetails = 'health-owner-numbness-details',
  healthOwnerMemoryLossDetails = 'health-owner-memory-loss-details',
  healthOwnerDegenerativeDiseaseDetails = 'health-owner-degenerative-disease-details',
  healthOwnerAnyOtherCognitiveImpairmentDetails = 'health-owner-any-other-cognitive-impairment-details',
  healthOwnerDepressionIsSingleEpisode = 'health-owner-depression-is-single-episode',
  healthOwnerDepressionDiagnosticDate = 'health-owner-depression-diagnostic-date',
  healthOwnerDepressionMedicationWasPrescribed = 'health-owner-depression-medication-was-prescribed',
  healthOwnerDepressionHasConsultedDoctors = 'health-owner-depression-consulted-doctors',
  healthOwnerDepressionHasConsultedOtherDoctorsOrTherapists = 'health-owner-depression-has-consulted-other-doctors-or-therapists',
  healthOwnerDepressionWentToEmergencyDepartment = 'health-owner-depression-went-to-emergency-department',
  healthOwnerDepressionWasHospitalized = 'health-owner-depression-was-hospitalized',
  healthOwnerDepressionHadSuicidalThoughtsAndAttempt = 'health-owner-depression-had-suicidal-thoughts-and-attempt',
  healthOwnerDepressionSuicidalThoughtsAndAttemptChoices = 'health-owner-depression-suicidal-thoughts-and-attempt-choices',
  healthOwnerDepressionSuicidalThoughtsFrequencyNumber = 'health-owner-depression-suicidal-thoughts-frequency-number',
  healthOwnerDepressionSuicidalThoughtsFrequency = 'health-owner-depression-suicidal-thoughts-frequency',
  healthOwnerDepressionSuicideAttemptsNumber = 'health-owner-depression-suicide-attempts-number',
  healthOwnerDepressionHadAbsence = 'health-owner-depression-had-absence',
  healthOwnerDepressionAbsenceHasRecovered = 'health-owner-depression-absence-has-recovered',
  healthOwnerDepressionAbsenceRecoveryDate = 'health-owner-depression-absence-recovery-date',
  healthOwnerDepressionAbsenceNotRecoveredDetails = 'health-owner-depression-absence-not-recovered-details',
  healthOwnerDepressionMedicationDetails = 'health-owner-depression-medication-details',
  healthOwnerDepressionMedicationName = 'health-owner-depression-medication-name',
  healthOwnerDepressionMedicationDosage = 'health-owner-depression-medication-dosage',
  healthOwnerDepressionMedicationStartDate = 'health-owner-depression-medication-start-date',
  healthOwnerDepressionMedicationEndDate = 'health-owner-depression-medication-end-date',
  healthOwnerDepressionMedicationStillUses = 'health-owner-depression-medication-still-uses',
  healthOwnerDepressionMedicationHasChanged = 'health-owner-depression-medication-has-changed',
  healthOwnerDepressionMedicationHasChangedDetail = 'health-owner-depression-medication-has-changed-details',
  healthOwnerDepressionHasOrWillConsultDoctorsDetails = 'health-owner-depression-has-or-will-consult-doctors-details',
  healthOwnerDepressionHasOrWillConsultDoctorsProfession = 'health-owner-depression-has-or-will-consult-doctors-profession',
  healthOwnerDepressionHasOrWillConsultDoctorsProfessionOther = 'health-owner-depression-has-or-will-consult-doctors-profession-other',
  healthOwnerDepressionHasOrWillConsultDoctorsName = 'health-owner-depression-has-or-will-consult-doctors-name',
  healthOwnerDepressionHasOrWillConsultDoctorsAddress = 'health-owner-depression-has-or-will-consult-doctors-address',
  healthOwnerDepressionHasOrWillConsultDoctorsFirstDate = 'health-owner-depression-has-or-will-consult-doctors-first-date',
  healthOwnerDepressionHasOrWillConsultDoctorsLastDate = 'health-owner-depression-has-or-will-consult-doctors-last-date',
  healthOwnerDepressionHasOrWillConsultDoctorsResult = 'health-owner-depression-has-or-will-consult-doctors-result',
  healthOwnerDepressionHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-depression-has-or-will-consult-doctors-frequency-number',
  healthOwnerDepressionHasOrWillConsultDoctorsFrequency = 'health-owner-depression-has-or-will-consult-doctors-frequency',
  healthOwnerDepressionHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-depression-has-or-will-consult-doctors-further-consultation',
  healthOwnerDepressionHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-depression-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerDepressionWentToEmergencyDepartmentDetails = 'health-owner-depression-went-to-emergency-department-details',
  healthOwnerDepressionWentToEmergencyDepartmentDate = 'health-owner-depression-went-to-emergency-department-date',
  healthOwnerDepressionWentToEmergencyDepartmentLocation = 'health-owner-depression-went-to-emergency-department-location',
  healthOwnerDepressionWasHospitalizedDetails = 'health-owner-depression-was-hospitalized-details',
  healthOwnerDepressionWasHospitalizedDate = 'health-owner-depression-was-hospitalized-date',
  healthOwnerDepressionWasHospitalizedFrequencyNumber = 'health-owner-depression-was-hospitalized-frequency-number',
  healthOwnerDepressionWasHospitalizedFrequency = 'health-owner-depression-was-hospitalized-frequency',
  healthOwnerDepressionWasHospitalizedLocation = 'health-owner-depression-was-hospitalized-location',
  healthOwnerDepressionSuicidalThoughtsDetails = 'health-owner-depression-suicidal-thoughts-details',
  healthOwnerDepressionSuicidalThoughtsDate = 'health-owner-depression-suicidal-thoughts-date',
  healthOwnerDepressionSuicideAttemptsDetails = 'health-owner-depression-suicide-attempts-details',
  healthOwnerDepressionSuicideAttemptsDate = 'health-owner-depression-suicide-attempts-date',
  healthOwnerDepressionHadAbsenceDetails = 'health-owner-depression-had-absence-details',
  healthOwnerDepressionAbsenceStartDate = 'health-owner-depression-absence-start-date',
  healthOwnerDepressionAbsenceFrequencyNumber = 'health-owner-depression-absence-frequency-number',
  healthOwnerDepressionAbsenceFrequency = 'health-owner-depression-absence-frequency',

  healthOwnerAnxietyIsSingleEpisode = 'health-owner-anxiety-is-single-episode',
  healthOwnerAnxietyDiagnosticDate = 'health-owner-anxiety-diagnostic-date',
  healthOwnerAnxietyMedicationWasPrescribed = 'health-owner-anxiety-medication-was-prescribed',
  healthOwnerAnxietyHasConsultedDoctors = 'health-owner-anxiety-consulted-doctors',
  healthOwnerAnxietyHasConsultedOtherDoctorsOrTherapists = 'health-owner-anxiety-has-consulted-other-doctors-or-therapists',
  healthOwnerAnxietyWentToEmergencyDepartment = 'health-owner-anxiety-went-to-emergency-department',
  healthOwnerAnxietyWasHospitalized = 'health-owner-anxiety-was-hospitalized',
  healthOwnerAnxietyHadSuicidalThoughtsAndAttempt = 'health-owner-anxiety-had-suicidal-thoughts-and-attempt',
  healthOwnerAnxietySuicidalThoughtsAndAttemptChoices = 'health-owner-anxiety-suicidal-thoughts-and-attempt-choices',
  healthOwnerAnxietySuicidalThoughtsFrequencyNumber = 'health-owner-anxiety-suicidal-thoughts-frequency-number',
  healthOwnerAnxietySuicidalThoughtsFrequency = 'health-owner-anxiety-suicidal-thoughts-frequency',
  healthOwnerAnxietySuicideAttemptsNumber = 'health-owner-anxiety-suicide-attempts-number',
  healthOwnerAnxietyHadAbsence = 'health-owner-anxiety-had-absence',
  healthOwnerAnxietyAbsenceHasRecovered = 'health-owner-anxiety-absence-has-recovered',
  healthOwnerAnxietyAbsenceRecoveryDate = 'health-owner-anxiety-absence-recovery-date',
  healthOwnerAnxietyAbsenceNotRecoveredDetails = 'health-owner-anxiety-absence-not-recovered-details',
  healthOwnerAnxietyMedicationDetails = 'health-owner-anxiety-medication-details',
  healthOwnerAnxietyMedicationName = 'health-owner-anxiety-medication-name',
  healthOwnerAnxietyMedicationDosage = 'health-owner-anxiety-medication-dosage',
  healthOwnerAnxietyMedicationStartDate = 'health-owner-anxiety-medication-start-date',
  healthOwnerAnxietyMedicationEndDate = 'health-owner-anxiety-medication-end-date',
  healthOwnerAnxietyMedicationStillUses = 'health-owner-anxiety-medication-still-uses',
  healthOwnerAnxietyMedicationHasChanged = 'health-owner-anxiety-medication-has-changed',
  healthOwnerAnxietyMedicationHasChangedDetails = 'health-owner-anxiety-medication-has-changed-details',
  healthOwnerAnxietyHasOrWillConsultDoctorsDetails = 'health-owner-anxiety-has-or-will-consult-doctors-details',
  healthOwnerAnxietyHasOrWillConsultDoctorsProfession = 'health-owner-anxiety-has-or-will-consult-doctors-profession',
  healthOwnerAnxietyHasOrWillConsultDoctorsProfessionOther = 'health-owner-anxiety-has-or-will-consult-doctors-profession-other',
  healthOwnerAnxietyHasOrWillConsultDoctorsName = 'health-owner-anxiety-has-or-will-consult-doctors-name',
  healthOwnerAnxietyHasOrWillConsultDoctorsAddress = 'health-owner-anxiety-has-or-will-consult-doctors-address',
  healthOwnerAnxietyHasOrWillConsultDoctorsFirstDate = 'health-owner-anxiety-has-or-will-consult-doctors-first-date',
  healthOwnerAnxietyHasOrWillConsultDoctorsLastDate = 'health-owner-anxiety-has-or-will-consult-doctors-last-date',
  healthOwnerAnxietyHasOrWillConsultDoctorsResult = 'health-owner-anxiety-has-or-will-consult-doctors-result',
  healthOwnerAnxietyHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-anxiety-has-or-will-consult-doctors-frequency-number',
  healthOwnerAnxietyHasOrWillConsultDoctorsFrequency = 'health-owner-anxiety-has-or-will-consult-doctors-frequency',
  healthOwnerAnxietyHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-anxiety-has-or-will-consult-doctors-further-consultation',
  healthOwnerAnxietyHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-anxiety-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerAnxietyWentToEmergencyDepartmentDetails = 'health-owner-anxiety-went-to-emergency-department-details',
  healthOwnerAnxietyWentToEmergencyDepartmentDate = 'health-owner-anxiety-went-to-emergency-department-date',
  healthOwnerAnxietyWentToEmergencyDepartmentLocation = 'health-owner-anxiety-went-to-emergency-department-location',
  healthOwnerAnxietyWasHospitalizedDetails = 'health-owner-anxiety-was-hospitalized-details',
  healthOwnerAnxietyWasHospitalizedDate = 'health-owner-anxiety-was-hospitalized-date',
  healthOwnerAnxietyWasHospitalizedFrequencyNumber = 'health-owner-anxiety-was-hospitalized-frequency-number',
  healthOwnerAnxietyWasHospitalizedFrequency = 'health-owner-anxiety-was-hospitalized-frequency',
  healthOwnerAnxietyWasHospitalizedLocation = 'health-owner-anxiety-was-hospitalized-location',
  healthOwnerAnxietySuicidalThoughtsDetails = 'health-owner-anxiety-suicidal-thoughts-details',
  healthOwnerAnxietySuicidalThoughtsDate = 'health-owner-anxiety-suicidal-thoughts-date',
  healthOwnerAnxietySuicideAttemptsDetails = 'health-owner-anxiety-suicide-attempts-details',
  healthOwnerAnxietySuicideAttemptsDate = 'health-owner-anxiety-suicide-attempts-date',
  healthOwnerAnxietyHadAbsenceDetails = 'health-owner-anxiety-had-absence-details',
  healthOwnerAnxietyAbsenceStartDate = 'health-owner-anxiety-absence-start-date',
  healthOwnerAnxietyAbsenceFrequencyNumber = 'health-owner-anxiety-absence-frequency-number',
  healthOwnerAnxietyAbsenceFrequency = 'health-owner-anxiety-absence-frequency',

  healthOwnerPanicAttacksIsSingleEpisode = 'health-owner-panic-attacks-is-single-episode',
  healthOwnerPanicAttacksDiagnosticDate = 'health-owner-panic-attacks-diagnostic-date',
  healthOwnerPanicAttacksMedicationWasPrescribed = 'health-owner-panic-attacks-medication-was-prescribed',
  healthOwnerPanicAttacksHasConsultedDoctors = 'health-owner-panic-attacks-consulted-doctors',
  healthOwnerPanicAttacksHasConsultedOtherDoctorsOrTherapists = 'health-owner-panic-attacks-has-consulted-other-doctors-or-therapists',
  healthOwnerPanicAttacksWentToEmergencyDepartment = 'health-owner-panic-attacks-went-to-emergency-department',
  healthOwnerPanicAttacksWasHospitalized = 'health-owner-panic-attacks-was-hospitalized',
  healthOwnerPanicAttacksHadSuicidalThoughtsAndAttempt = 'health-owner-panic-attacks-had-suicidal-thoughts-and-attempt',
  healthOwnerPanicAttacksSuicidalThoughtsAndAttemptChoices = 'health-owner-panic-attacks-suicidal-thoughts-and-attempt-choices',
  healthOwnerPanicAttacksSuicidalThoughtsFrequencyNumber = 'health-owner-panic-attacks-suicidal-thoughts-frequency-number',
  healthOwnerPanicAttacksSuicidalThoughtsFrequency = 'health-owner-panic-attacks-suicidal-thoughts-frequency',
  healthOwnerPanicAttacksSuicideAttemptsNumber = 'health-owner-panic-attacks-suicide-attempts-number',
  healthOwnerPanicAttacksHadAbsence = 'health-owner-panic-attacks-had-absence',
  healthOwnerPanicAttacksAbsenceHasRecovered = 'health-owner-panic-attacks-absence-has-recovered',
  healthOwnerPanicAttacksAbsenceRecoveryDate = 'health-owner-panic-attacks-absence-recovery-date',
  healthOwnerPanicAttacksAbsenceNotRecoveredDetails = 'health-owner-panic-attacks-absence-not-recovered-details',
  healthOwnerPanicAttacksMedicationDetails = 'health-owner-panic-attacks-medication-details',
  healthOwnerPanicAttacksMedicationName = 'health-owner-panic-attacks-medication-name',
  healthOwnerPanicAttacksMedicationDosage = 'health-owner-panic-attacks-medication-dosage',
  healthOwnerPanicAttacksMedicationStartDate = 'health-owner-panic-attacks-medication-start-date',
  healthOwnerPanicAttacksMedicationEndDate = 'health-owner-panic-attacks-medication-end-date',
  healthOwnerPanicAttacksMedicationStillUses = 'health-owner-panic-attacks-medication-still-uses',
  healthOwnerPanicAttacksMedicationHasChanged = 'health-owner-panic-attacks-medication-has-changed',
  healthOwnerPanicAttacksMedicationHasChangedDetails = 'health-owner-panic-attacks-medication-has-changed-details',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsDetails = 'health-owner-panic-attacks-has-or-will-consult-doctors-details',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsProfession = 'health-owner-panic-attacks-has-or-will-consult-doctors-profession',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsProfessionOther = 'health-owner-panic-attacks-has-or-will-consult-doctors-profession-other',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsName = 'health-owner-panic-attacks-has-or-will-consult-doctors-name',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsAddress = 'health-owner-panic-attacks-has-or-will-consult-doctors-address',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsFirstDate = 'health-owner-panic-attacks-has-or-will-consult-doctors-first-date',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsLastDate = 'health-owner-panic-attacks-has-or-will-consult-doctors-last-date',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsResult = 'health-owner-panic-attacks-has-or-will-consult-doctors-result',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-panic-attacks-has-or-will-consult-doctors-frequency-number',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsFrequency = 'health-owner-panic-attacks-has-or-will-consult-doctors-frequency',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-panic-attacks-has-or-will-consult-doctors-further-consultation',
  healthOwnerPanicAttacksHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-panic-attacks-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerPanicAttacksWentToEmergencyDepartmentDetails = 'health-owner-panic-attacks-went-to-emergency-department-details',
  healthOwnerPanicAttacksWentToEmergencyDepartmentDate = 'health-owner-panic-attacks-went-to-emergency-department-date',
  healthOwnerPanicAttacksWentToEmergencyDepartmentLocation = 'health-owner-panic-attacks-went-to-emergency-department-location',
  healthOwnerPanicAttacksWasHospitalizedDetails = 'health-owner-panic-attacks-was-hospitalized-details',
  healthOwnerPanicAttacksWasHospitalizedDate = 'health-owner-panic-attacks-was-hospitalized-date',
  healthOwnerPanicAttacksWasHospitalizedFrequencyNumber = 'health-owner-panic-attacks-was-hospitalized-frequency-number',
  healthOwnerPanicAttacksWasHospitalizedFrequency = 'health-owner-panic-attacks-was-hospitalized-frequency',
  healthOwnerPanicAttacksWasHospitalizedLocation = 'health-owner-panic-attacks-was-hospitalized-location',
  healthOwnerPanicAttacksSuicidalThoughtsDetails = 'health-owner-panic-attacks-suicidal-thoughts-details',
  healthOwnerPanicAttacksSuicidalThoughtsDate = 'health-owner-panic-attacks-suicidal-thoughts-date',
  healthOwnerPanicAttacksSuicideAttemptsDetails = 'health-owner-panic-attacks-suicide-attempts-details',
  healthOwnerPanicAttacksSuicideAttemptsDate = 'health-owner-panic-attacks-suicide-attempts-date',
  healthOwnerPanicAttacksHadAbsenceDetails = 'health-owner-panic-attacks-had-absence-details',
  healthOwnerPanicAttacksAbsenceStartDate = 'health-owner-panic-attacks-absence-start-date',
  healthOwnerPanicAttacksAbsenceFrequencyNumber = 'health-owner-panic-attacks-absence-frequency-number',
  healthOwnerPanicAttacksAbsenceFrequency = 'health-owner-panic-attacks-absence-frequency',

  healthOwnerBurnoutIsSingleEpisode = 'health-owner-burnout-is-single-episode',
  healthOwnerBurnoutDiagnosticDate = 'health-owner-burnout-diagnostic-date',
  healthOwnerBurnoutMedicationWasPrescribed = 'health-owner-burnout-medication-was-prescribed',
  healthOwnerBurnoutHasConsultedDoctors = 'health-owner-burnout-consulted-doctors',
  healthOwnerBurnoutHasConsultedOtherDoctorsOrTherapists = 'health-owner-burnout-has-consulted-other-doctors-or-therapists',
  healthOwnerBurnoutWentToEmergencyDepartment = 'health-owner-burnout-went-to-emergency-department',
  healthOwnerBurnoutWasHospitalized = 'health-owner-burnout-was-hospitalized',
  healthOwnerBurnoutHadSuicidalThoughtsAndAttempt = 'health-owner-burnout-had-suicidal-thoughts-and-attempt',
  healthOwnerBurnoutSuicidalThoughtsAndAttemptChoices = 'health-owner-burnout-suicidal-thoughts-and-attempt-choices',
  healthOwnerBurnoutSuicidalThoughtsFrequencyNumber = 'health-owner-burnout-suicidal-thoughts-frequency-number',
  healthOwnerBurnoutSuicidalThoughtsFrequency = 'health-owner-burnout-suicidal-thoughts-frequency',
  healthOwnerBurnoutSuicideAttemptsNumber = 'health-owner-burnout-suicide-attempts-number',
  healthOwnerBurnoutHadAbsence = 'health-owner-burnout-had-absence',
  healthOwnerBurnoutAbsenceHasRecovered = 'health-owner-burnout-absence-has-recovered',
  healthOwnerBurnoutAbsenceRecoveryDate = 'health-owner-burnout-absence-recovery-date',
  healthOwnerBurnoutAbsenceNotRecoveredDetails = 'health-owner-burnout-absence-not-recovered-details',

  healthOwnerBurnoutMedicationDetails = 'health-owner-burnout-medication-details',
  healthOwnerBurnoutMedicationName = 'health-owner-burnout-medication-name',
  healthOwnerBurnoutMedicationDosage = 'health-owner-burnout-medication-dosage',
  healthOwnerBurnoutMedicationStartDate = 'health-owner-burnout-medication-start-date',
  healthOwnerBurnoutMedicationEndDate = 'health-owner-burnout-medication-end-date',
  healthOwnerBurnoutMedicationStillUses = 'health-owner-burnout-medication-still-uses',
  healthOwnerBurnoutMedicationHasChanged = 'health-owner-burnout-medication-has-changed',
  healthOwnerBurnoutMedicationHasChangedDetails = 'health-owner-burnout-medication-has-changed-details',
  healthOwnerBurnoutHasOrWillConsultDoctorsDetails = 'health-owner-burnout-has-or-will-consult-doctors-details',
  healthOwnerBurnoutHasOrWillConsultDoctorsProfession = 'health-owner-burnout-has-or-will-consult-doctors-profession',
  healthOwnerBurnoutHasOrWillConsultDoctorsProfessionOther = 'health-owner-burnout-has-or-will-consult-doctors-profession-other',
  healthOwnerBurnoutHasOrWillConsultDoctorsName = 'health-owner-burnout-has-or-will-consult-doctors-name',
  healthOwnerBurnoutHasOrWillConsultDoctorsAddress = 'health-owner-burnout-has-or-will-consult-doctors-address',
  healthOwnerBurnoutHasOrWillConsultDoctorsFirstDate = 'health-owner-burnout-has-or-will-consult-doctors-first-date',
  healthOwnerBurnoutHasOrWillConsultDoctorsLastDate = 'health-owner-burnout-has-or-will-consult-doctors-last-date',
  healthOwnerBurnoutHasOrWillConsultDoctorsResult = 'health-owner-burnout-has-or-will-consult-doctors-result',
  healthOwnerBurnoutHasOrWillConsultDoctorsFrequencyNumber = 'health-owner-burnout-has-or-will-consult-doctors-frequency-number',
  healthOwnerBurnoutHasOrWillConsultDoctorsFrequency = 'health-owner-burnout-has-or-will-consult-doctors-frequency',
  healthOwnerBurnoutHasOrWillConsultDoctorsFurtherConsultation = 'health-owner-burnout-has-or-will-consult-doctors-further-consultation',
  healthOwnerBurnoutHasOrWillConsultDoctorsNextConsultationDate = 'health-owner-burnout-has-or-will-consult-doctors-next-consultation-date',
  healthOwnerBurnoutWentToEmergencyDepartmentDetails = 'health-owner-burnout-went-to-emergency-department-details',
  healthOwnerBurnoutWentToEmergencyDepartmentDate = 'health-owner-burnout-went-to-emergency-department-date',
  healthOwnerBurnoutWentToEmergencyDepartmentLocation = 'health-owner-burnout-went-to-emergency-department-location',
  healthOwnerBurnoutWasHospitalizedDetails = 'health-owner-burnout-was-hospitalized-details',
  healthOwnerBurnoutWasHospitalizedDate = 'health-owner-burnout-was-hospitalized-date',
  healthOwnerBurnoutWasHospitalizedFrequencyNumber = 'health-owner-burnout-was-hospitalized-frequency-number',
  healthOwnerBurnoutWasHospitalizedFrequency = 'health-owner-burnout-was-hospitalized-frequency',
  healthOwnerBurnoutWasHospitalizedLocation = 'health-owner-burnout-was-hospitalized-location',
  healthOwnerBurnoutSuicidalThoughtsDetails = 'health-owner-burnout-suicidal-thoughts-details',
  healthOwnerBurnoutSuicidalThoughtsDate = 'health-owner-burnout-suicidal-thoughts-date',
  healthOwnerBurnoutSuicideAttemptsDetails = 'health-owner-burnout-suicide-attempts-details',
  healthOwnerBurnoutSuicideAttemptsDate = 'health-owner-burnout-suicide-attempts-date',
  healthOwnerBurnoutHadAbsenceDetails = 'health-owner-burnout-had-absence-details',
  healthOwnerBurnoutAbsenceStartDate = 'health-owner-burnout-absence-start-date',
  healthOwnerBurnoutAbsenceFrequencyNumber = 'health-owner-burnout-absence-frequency-number',
  healthOwnerBurnoutAbsenceFrequency = 'health-owner-burnout-absence-frequency',

  healthOwnerAutoImmuneSystemHasCondition = 'health-owner-auto-immune-system-has-condition',
  healthOwnerAutoImmuneSystemChoices = 'health-owner-auto-immune-system-choices',
  healthOwnerAidsDetails = 'health-owner-aids-details',
  healthOwnerHivDetails = 'health-owner-hiv-details',
  healthOwnerLupusDetails = 'health-owner-lupus-details',
  healthOwnerSclerodermaDetails = 'health-owner-scleroderma-details',
  healthOwnerOtherAutoImmuneSystemDisorderDetails = 'health-owner-other-auto-immune-system-disorder-details',
  healthOwnerTumorBodyPart = 'health-owner-tumor-body-part',
  healthOwnerTumorSeverity = 'health-owner-tumor-severity',
  healthOwnerLeukemiaDetails = 'health-owner-leukemia-details',
  healthOwnerCancerDetails = 'health-owner-cancer-details',
  healthOwnerCancerHasCondition = 'health-owner-cancer-has-condition',
  healthOwnerCancerApplicableConditions = 'health-owner-cancer-applicable-conditions',
  healthOwnerNoduleDetails = 'health-owner-nodule-details',
  healthOwnerLymphNodeDisorderDetails = 'health-owner-lymph-node-disorder-details',
  healthOwnerCancerOtherDetails = 'health-owner-cancer-other-details',
  healthOwnerBreastDisorderHasCondition = 'health-owner-breast-disorder-has-condition',
  healthOwnerBreastDisorderApplicableConditions = 'health-owner-breast-disorder-applicable-conditions',
  healthOwnerBreastDisorderLumpDetails = 'health-owner-breast-disorder-lump-details',
  healthOwnerBreastDisorderBumpDetails = 'health-owner-breast-disorder-bump-details',
  healthOwnerBreastDisorderCystDetails = 'health-owner-breast-disorder-cyst-details',
  healthOwnerBreastDisorderOtherDetails = 'health-owner-breast-disorder-other-details',

  healthOwnerPartialOrTotalBlindnessDetails = 'health-owner-partial-or-total-blindness-details',
  healthOwnerMacularDegenerationDetails = 'health-owner-macular-degeneration-details',
  healthOwnerGlaucomaDetails = 'health-owner-glaucoma-details',
  healthOwnerPartialOrTotalDeafnessDetails = 'health-owner-partial-or-total-deafness-details',
  healthOwnerTinnitusDetails = 'health-owner-tinnitus-details',
  healthOwnerMeniereDiseaseDetails = 'health-owner-meniere-disease-details',
  healthOwnerLabyrinthitisDetails = 'health-owner-labyrinthitis-details',
  healthOwnerOtherEyeEarNoseOrThroatDisorderDetails = 'health-owner-other-eye-ear-nose-or-throat-disorder-details',
  healthOwnerLast5YearsTestsDetails = 'health-owner-last-5-years-tests-details',
  healthOwnerLast5YearsTestsAnyOtherDetails = 'health-owner-last-5-years-tests-any-other-details',
  healthOwnerOtherProfessionals = 'health-owner-other-professionals',
  healthOwnerLast5YearsHospitalizedDetails = 'health-owner-last-5-years-hospitalized-details',
  healthOwnerMoreMedicalActionPending = 'health-owner-more-medical-action-pending',
  healthOwnerMoreMedicalActionChoices = 'health-owner-more-medical-action-choices',
  healthOwnerMoreMedicalActionTreatmentsDetails = 'health-owner-more-medical-action-treatments-details',
  healthOwnerMoreMedicalActionSurgeryDetails = 'health-owner-more-medical-action-surgery-details',
  healthOwnerMoreMedicalActionAwaitingResultsDetails = 'health-owner-more-medical-action-awaiting-results-details',
  healthOwnerDiagnosticExamsOrTestsDetails = 'health-owner-diagnostic-exams-or-tests-details',
  healthOwnerHasFamilyHistory2 = 'health-owner-has-family-history-2',
  healthOwnerFamilyMedicalHistory2 = 'health-owner-family-medical-history-2',

  healthOwnerBreastCystDetails = 'health-owner-breast-cyst-details',
  healthOwnerOvarianCystDetails = 'health-owner-ovarian-cyst-details',
  healthOwnerEyeEarNoseThroatDisorderHasCondition = 'health-owner-eye-ear-nose-throat-disorder-has-condition',
  healthOwnerEyeEarNoseThroatDisorderApplicableConditions = 'health-owner-eye-ear-nose-throat-disorder-applicable-conditions',
  healthOwnerEyeEarNoseThroatOtherDisorderDetails = 'health-owner-eye-ear-nose-throat-other-disorder-details',
  healthOwnerHadConsultationTreatmentForBloodOrSkinDisease = 'health-owner-had-consultation-treatment-for-blood-or-skin-disease',
  healthOwnerHadConsultationTreatmentForBloodOrSkinDiseaseDetails = 'health-owner-had-consultation-treatment-for-blood-or-skin-disease-details',
  healthOwnerHadConsultationTreatmentForBloodDiseaseDetails = 'health-owner-had-consultation-treatment-for-blood-disease-details',
  healthOwnerHadConsultationTreatmentForSkinDiseaseDetails = 'health-owner-had-consultation-treatment-for-skin-disease-details',
  healthOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorder = 'health-owner-had-consultation-treatment-for-other-physical-or-mental-disorder',
  healthOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorderDetails = 'health-owner-had-consultation-treatment-for-other-physical-or-mental-disorder-details',
  healthOwnerHadConsultationTreatmentForOtherPhysicalDisorderDetails = 'health-owner-had-consultation-treatment-for-other-physical-disorder-details',
  healthOwnerHadConsultationTreatmentForOtherMentalDisorderDetails = 'health-owner-had-consultation-treatment-for-other-mental-disorder-details',

  healthOwnerIsPregnant = 'health-owner-is-pregnant',
  healthOwnerPregnancyDueDate = 'health-owner-pregnancy-due-date',
  healthOwnerHadPregnancyOrChildbirthComplications = 'health-owner-had-pregnancy-or-childbirth-complications',
  healthOwnerHadPregnancyOrChildbirthComplicationsDetails = 'health-owner-had-pregnancy-or-childbirth-complications-details',
  healthOwnerHadOtherMedicalTestsLast5Years = 'health-owner-had-other-medical-tests-last-5-years',
  healthOwnerHadOtherMedicalTestsLast5YearsTests = 'health-owner-had-other-medical-tests-last-5-years-tests',
  healthOwnerHadOtherMedicalTestsLast5YearsBloodTestDetails = 'health-owner-had-other-medical-tests-last-5-years-blood-test-details',
  healthOwnerHadOtherMedicalTestsLast5YearsRestingElectrocardiogramDetails = 'health-owner-had-other-medical-tests-last-5-years-resting-electrocardiogram-details',
  healthOwnerHadOtherMedicalTestsLast5YearsStressElectrocardiogramDetails = 'health-owner-had-other-medical-tests-last-5-years-stress-electrocardiogram-details',
  healthOwnerHadOtherMedicalTestsLast5YearsEchocardiogramDetails = 'health-owner-had-other-medical-tests-last-5-years-echocardiogram-details',
  healthOwnerHadOtherMedicalTestsLast5YearsColonoscopyDetails = 'health-owner-had-other-medical-tests-last-5-years-colonoscopy-details',
  healthOwnerHadOtherMedicalTestsLast5YearsXRayDetails = 'health-owner-had-other-medical-tests-last-5-years-x-ray-details',
  healthOwnerHadOtherMedicalTestsLast5YearsMammographyDetails = 'health-owner-had-other-medical-tests-last-5-years-mammography-details',
  healthOwnerHadOtherMedicalTestsLast5YearsUltrasoundDetails = 'health-owner-had-other-medical-tests-last-5-years-ultrasound-details',
  healthOwnerHadOtherMedicalTestsLast5YearsCtScanDetails = 'health-owner-had-other-medical-tests-last-5-years-ct-scan-details',
  healthOwnerHadOtherMedicalTestsLast5YearsMriDetails = 'health-owner-had-other-medical-tests-last-5-years-mri-details',

  healthOwnerHasConsultedForAbnormalProblemsLast5Years = 'health-owner-has-consulted-for-abnormal-problems-last-5-years',
  healthOwnerAbnormalProblemsLast5YearsDetails = 'health-owner-abnormal-problems-last-5-years-details',
  healthOwnerWasHospitalizedInLast5Years = 'health-owner-was-hospitalized-in-last-5-years',
  healthOwnerHospitalizedInLast5YearsDetails = 'health-owner-hospitalized-in-last-5-years-details',
  healthOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsulted = 'health-owner-medical-tests-abnormal-test-physician-has-not-yet-consulted',
  healthOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsultedDetails = 'health-owner-medical-tests-abnormal-test-physician-has-not-yet-consulted-details',
  healthOwnerOtherProfessionalsDetails = 'health-owner-other-professionals-details',
  healthOwnerDisabilityHadAbsence = 'health-owner-disability-had-absence',
  healthOwnerDisabilityAbsenceDetails = 'health-owner-disability-absence-details',
  healthOwnerHasOrHadMedication = 'health-owner-has-or-had-medication',
  healthOwnerHasOrHadMedicationDetails = 'health-owner-has-or-had-medication-details',
  healthOwnerHeight = 'health-owner-height',
  healthOwnerWeight = 'health-owner-weight',
  healthOwnerHadUnexplainedWeightLoss = 'health-owner-had-unexplained-weight-loss',
  healthOwnerWeightLossIsIntentional = 'health-owner-weight-loss-is-intentional',
  healthOwnerWeightLoss = 'health-owner-weight-loss',
  healthOwnerUnexplainedWeightLossDetails = 'health-owner-unexplained-weight-loss-details',
  healthOwnerHasFamilyMedicalHistory = 'health-owner-has-family-medical-history',
  healthOwnerHasFamilyMedicalHistoryDetails = 'health-owner-has-family-medical-history-details',
  healthOwnerPhoneInterviewIsPrescreening = 'health-owner-phone-interview-is-prescreening',
  healthOwnerPhoneInterviewDay = 'health-owner-phone-interview-day',
  healthOwnerPhoneInterviewTime = 'health-owner-phone-interview-time',
  healthOwnerPhoneInterviewNumber = 'health-owner-phone-interview-number',
  healthOwnerPhoneInterviewDay2 = 'health-owner-phone-interview-day-2',
  healthOwnerPhoneInterviewTime2 = 'health-owner-phone-interview-time-2',
  healthOwnerPhoneInterviewNumber2 = 'health-owner-phone-interview-number-2',

  healthOwnerHasFamilyDoctorOrClinic = 'health-owner-has-family-doctor-or-clinic',
  healthOwnerFamilyDoctorOrClinicName = 'health-owner-family-doctor-or-clinic-name',
  healthOwnerFamilyDoctorOrClinicAddressStreet = 'health-owner-family-doctor-or-clinic-address-street',
  healthOwnerFamilyDoctorOrClinicAddressApartment = 'health-owner-family-doctor-or-clinic-address-apartment',
  healthOwnerFamilyDoctorOrClinicAddressCity = 'health-owner-family-doctor-or-clinic-address-city',
  healthOwnerFamilyDoctorOrClinicAddressCountry = 'health-owner-family-doctor-or-clinic-address-country',
  healthOwnerFamilyDoctorOrClinicAddressProvince = 'health-owner-family-doctor-or-clinic-address-province',
  healthOwnerFamilyDoctorOrClinicAddressPostalCode = 'health-owner-family-doctor-or-clinic-address-postal-code',

  healthOwnerMusculoskeletalOsteoarthritisIsSingleEpisode = 'health-owner-musculoskeletal-osteoarthritis-is-single-episode',
  healthOwnerMusculoskeletalOsteoarthritisPreviousEpisodesCount = 'health-owner-musculoskeletal-osteoarthritis-previous-episodes-count',
  healthOwnerMusculoskeletalOsteoarthritisDateSymptoms = 'health-owner-musculoskeletal-osteoarthritis-date-symptoms',
  healthOwnerMusculoskeletalOsteoarthritisPainLocation = 'health-owner-musculoskeletal-osteoarthritis-pain-location',
  healthOwnerMusculoskeletalOsteoarthritisPainLocationLeftRight = 'health-owner-musculoskeletal-osteoarthritis-pain-location-left-right',
  healthOwnerMusculoskeletalOsteoarthritisPainLocationOther = 'health-owner-musculoskeletal-osteoarthritis-pain-location-other',
  healthOwnerMusculoskeletalOsteoarthritisPainCause = 'health-owner-musculoskeletal-osteoarthritis-pain-cause',
  healthOwnerMusculoskeletalOsteoarthritisPainCauseDetails = 'health-owner-musculoskeletal-osteoarthritis-pain-cause-details',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctors = 'health-owner-musculoskeletal-osteoarthritis-consulted-doctors',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExams = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams',
  healthOwnerMusculoskeletalOsteoarthritisMedicationWasPrescribed = 'health-owner-musculoskeletal-osteoarthritis-medication-was-prescribed',
  healthOwnerMusculoskeletalOsteoarthritisHadOperation = 'health-owner-musculoskeletal-osteoarthritis-had-operation',
  healthOwnerMusculoskeletalOsteoarthritisOperationPerformedOrPlanned = 'health-owner-musculoskeletal-osteoarthritis-operation-performed-or-planned',
  healthOwnerMusculoskeletalOsteoarthritisTypeOfOperationPerformed = 'health-owner-musculoskeletal-osteoarthritis-type-of-operation-performed',
  healthOwnerMusculoskeletalOsteoarthritisOperationPerformedDate = 'health-owner-musculoskeletal-osteoarthritis-operation-performed-date',
  healthOwnerMusculoskeletalOsteoarthritisTypeOfOperationPlanned = 'health-owner-musculoskeletal-osteoarthritis-type-of-operation-planned',
  healthOwnerMusculoskeletalOsteoarthritisOperationPlannedDate = 'health-owner-musculoskeletal-osteoarthritis-operation-planned-date',
  healthOwnerMusculoskeletalOsteoarthritisOperationUnknownDate = 'health-owner-musculoskeletal-osteoarthritis-operation-unknown-date',
  healthOwnerMusculoskeletalOsteoarthritisHadAbsence = 'health-owner-musculoskeletal-osteoarthritis-had-absence',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceStartDate = 'health-owner-musculoskeletal-osteoarthritis-absence-start-date',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceFrequencyNumber = 'health-owner-musculoskeletal-osteoarthritis-absence-frequency-number',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceFrequency = 'health-owner-musculoskeletal-osteoarthritis-absence-frequency',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceHasRecovered = 'health-owner-musculoskeletal-osteoarthritis-absence-has-recovered',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceRecoveryDate = 'health-owner-musculoskeletal-osteoarthritis-absence-recovery-date',
  healthOwnerMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails = 'health-owner-musculoskeletal-osteoarthritis-absence-not-recovered-details',

  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-details',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-profession',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-profession-details',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsName = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-name',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-address',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-first-date',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-last-date',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsResult = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-result',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency-number',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctor-further-consultation',
  healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-next-consultation-date',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-type',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-date',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-result',
  healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMusculoskeletalOsteoarthritisMedicationDetails = 'health-owner-musculoskeletal-osteoarthritis-medication-details',
  healthOwnerMusculoskeletalOsteoarthritisMedicationName = 'health-owner-musculoskeletal-osteoarthritis-medication-name',
  healthOwnerMusculoskeletalOsteoarthritisMedicationDosage = 'health-owner-musculoskeletal-osteoarthritis-medication-dosage',
  healthOwnerMusculoskeletalOsteoarthritisMedicationStartDate = 'health-owner-musculoskeletal-osteoarthritis-medication-start-date',
  healthOwnerMusculoskeletalOsteoarthritisMedicationEndDate = 'health-owner-musculoskeletal-osteoarthritis-medication-end-date',

  healthOwnerMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode = 'health-owner-musculoskeletal-osteoporosis-osteopenia-is-single-episode',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount = 'health-owner-musculoskeletal-osteoporosis-osteopenia-previous-episodes-count',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaDateSymptoms = 'health-owner-musculoskeletal-osteoporosis-osteopenia-date-symptoms',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocation = 'health-owner-musculoskeletal-osteoporosis-osteopenia-pain-location',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight = 'health-owner-musculoskeletal-osteoporosis-osteopenia-pain-location-left-right',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationOther = 'health-owner-musculoskeletal-osteoporosis-osteopenia-pain-location-other',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainCause = 'health-owner-musculoskeletal-osteoporosis-osteopenia-pain-cause',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-pain-cause-details',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors = 'health-owner-musculoskeletal-osteoporosis-osteopenia-consulted-doctors',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-was-prescribed',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHadOperation = 'health-owner-musculoskeletal-osteoporosis-osteopenia-had-operation',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned = 'health-owner-musculoskeletal-osteoporosis-osteopenia-operation-performed-or-planned',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed = 'health-owner-musculoskeletal-osteoporosis-osteopenia-type-of-operation-performed',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-operation-performed-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned = 'health-owner-musculoskeletal-osteoporosis-osteopenia-type-of-operation-planned',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-operation-planned-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-operation-unknown-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHadAbsence = 'health-owner-musculoskeletal-osteoporosis-osteopenia-had-absence',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceStartDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-start-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-frequency-number',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-frequency',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-has-recovered',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-recovery-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-absence-not-recovered-details',

  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-details',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession-details',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-name',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-address',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-first-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-last-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-result',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency-number',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctor-further-consultation',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-next-consultation-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-type',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-result',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDetails = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-details',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationName = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-name',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDosage = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-dosage',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-start-date',
  healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate = 'health-owner-musculoskeletal-osteoporosis-osteopenia-medication-end-date',

  healthOwnerFibromyalgiIsSingleEpisode = 'health-owner-fibromyalgia-is-single-episode',
  healthOwnerFibromyalgiaPreviousEpisodesCount = 'health-owner-fibromyalgia-previous-episodes-count',
  healthOwnerFibromyalgiaDateSymptoms = 'health-owner-fibromyalgia-date-symptoms',
  healthOwnerFibromyalgiaPainLocation = 'health-owner-fibromyalgia-pain-location',
  healthOwnerFibromyalgiaPainLocationLeftRight = 'health-owner-fibromyalgia-pain-location-left-right',
  healthOwnerFibromyalgiaPainLocationOther = 'health-owner-fibromyalgia-pain-location-other',
  healthOwnerFibromyalgiaPainCause = 'health-owner-fibromyalgia-pain-cause',
  healthOwnerFibromyalgiaPainCauseDetails = 'health-owner-fibromyalgia-pain-cause-details',
  healthOwnerFibromyalgiaHasConsultedDoctors = 'health-owner-fibromyalgia-consulted-doctors',
  healthOwnerFibromyalgiaHasTakenTestsOrExams = 'health-owner-fibromyalgia-has-taken-tests-or-exams',
  healthOwnerFibromyalgiaMedicationWasPrescribed = 'health-owner-fibromyalgia-medication-was-prescribed',
  healthOwnerFibromyalgiaHadOperation = 'health-owner-fibromyalgia-had-operation',
  healthOwnerFibromyalgiaOperationPerformedOrPlanned = 'health-owner-fibromyalgia-operation-performed-or-planned',
  healthOwnerFibromyalgiaTypeOfOperationPerformed = 'health-owner-fibromyalgia-type-of-operation-performed',
  healthOwnerFibromyalgiaOperationPerformedDate = 'health-owner-fibromyalgia-operation-performed-date',
  healthOwnerFibromyalgiaTypeOfOperationPlanned = 'health-owner-fibromyalgia-type-of-operation-planned',
  healthOwnerFibromyalgiaOperationPlannedDate = 'health-owner-fibromyalgia-operation-planned-date',
  healthOwnerFibromyalgiaOperationUnknownDate = 'health-owner-fibromyalgia-operation-unknown-date',
  healthOwnerFibromyalgiaHadAbsence = 'health-owner-fibromyalgia-has-had-absence',
  healthOwnerFibromyalgiaAbsenceStartDate = 'health-owner-fibromyalgia-absence-start-date',
  healthOwnerFibromyalgiaAbsenceFrequencyNumber = 'health-owner-fibromyalgia-absence-frequency-number',
  healthOwnerFibromyalgiaAbsenceFrequency = 'health-owner-fibromyalgia-absence-frequency',
  healthOwnerFibromyalgiaAbsenceHasRecovered = 'health-owner-fibromyalgia-absence-has-recovered',
  healthOwnerFibromyalgiaAbsenceRecoveryDate = 'health-owner-fibromyalgia-absence-recovery-date',
  healthOwnerFibromyalgiaAbsenceNotRecoveredDetails = 'health-owner-fibromyalgia-absence-not-recovered-details',

  healthOwnerFibromyalgiaHasConsultedDoctorsDetails = 'health-owner-fibromyalgia-has-consulted-doctors-details',
  healthOwnerFibromyalgiaHasConsultedDoctorsProfession = 'health-owner-fibromyalgia-has-consulted-doctors-profession',
  healthOwnerFibromyalgiaHasConsultedDoctorsProfessionDetails = 'health-owner-fibromyalgia-has-consulted-doctors-profession-details',
  healthOwnerFibromyalgiaHasConsultedDoctorsName = 'health-owner-fibromyalgia-has-consulted-doctors-name',
  healthOwnerFibromyalgiaHasConsultedDoctorsAddress = 'health-owner-fibromyalgia-has-consulted-doctors-address',
  healthOwnerFibromyalgiaHasConsultedDoctorsFirstDate = 'health-owner-fibromyalgia-has-consulted-doctors-first-date',
  healthOwnerFibromyalgiaHasConsultedDoctorsLastDate = 'health-owner-fibromyalgia-has-consulted-doctors-last-date',
  healthOwnerFibromyalgiaHasConsultedDoctorsResult = 'health-owner-fibromyalgia-has-consulted-doctors-result',
  healthOwnerFibromyalgiaHasConsultedDoctorsFrequencyNumber = 'health-owner-fibromyalgia-has-consulted-doctors-frequency-number',
  healthOwnerFibromyalgiaHasConsultedDoctorsFrequency = 'health-owner-fibromyalgia-has-consulted-doctors-frequency',
  healthOwnerFibromyalgiaHasConsultedDoctorFurtherConsultation = 'health-owner-fibromyalgia-has-consulted-doctor-further-consultation',
  healthOwnerFibromyalgiaHasConsultedDoctorsNextConsultationDate = 'health-owner-fibromyalgia-has-consulted-doctors-next-consultation-date',
  healthOwnerFibromyalgiaHasTakenTestsOrExamsDetails = 'health-owner-fibromyalgia-has-taken-tests-or-exams-details',
  healthOwnerFibromyalgiaHasTakenTestsOrExamsType = 'health-owner-fibromyalgia-has-taken-tests-or-exams-type',
  healthOwnerFibromyalgiaHasTakenTestsOrExamsDate = 'health-owner-fibromyalgia-has-taken-tests-or-exams-date',
  healthOwnerFibromyalgiaHasTakenTestsOrExamsResult = 'health-owner-fibromyalgia-has-taken-tests-or-exams-result',
  healthOwnerFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-fibromyalgia-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerFibromyalgiaMedicationDetails = 'health-owner-fibromyalgia-medication-details',
  healthOwnerFibromyalgiaMedicationName = 'health-owner-fibromyalgia-medication-name',
  healthOwnerFibromyalgiaMedicationDosage = 'health-owner-fibromyalgia-medication-dosage',
  healthOwnerFibromyalgiaMedicationStartDate = 'health-owner-fibromyalgia-medication-start-date',
  healthOwnerFibromyalgiaMedicationEndDate = 'health-owner-fibromyalgia-medication-end-date',

  healthOwnerReplacementJointIsSingleEpisode = 'health-owner-replacement-joint-is-single-episode',
  healthOwnerReplacementJointPreviousEpisodesCount = 'health-owner-replacement-joint-previous-episodes-count',
  healthOwnerReplacementJointDateSymptoms = 'health-owner-replacement-joint-date-symptoms',
  healthOwnerReplacementJointPainLocation = 'health-owner-replacement-joint-pain-location',
  healthOwnerReplacementJointPainLocationLeftRight = 'health-owner-replacement-joint-pain-location-left-right',
  healthOwnerReplacementJointPainLocationOther = 'health-owner-replacement-joint-pain-location-other',
  healthOwnerReplacementJointPainCause = 'health-owner-replacement-joint-pain-cause',
  healthOwnerReplacementJointPainCauseDetails = 'health-owner-replacement-joint-pain-cause-details',
  healthOwnerReplacementJointHasConsultedDoctors = 'health-owner-replacement-joint-consulted-doctors',
  healthOwnerReplacementJointHasTakenTestsOrExams = 'health-owner-replacement-joint-has-taken-tests-or-exams',
  healthOwnerReplacementJointMedicationWasPrescribed = 'health-owner-replacement-joint-medication-was-prescribed',
  healthOwnerReplacementJointHadOperation = 'health-owner-replacement-joint-had-operation',
  healthOwnerReplacementJointOperationPerformedOrPlanned = 'health-owner-replacement-joint-operation-performed-or-planned',
  healthOwnerReplacementJointTypeOfOperationPerformed = 'health-owner-replacement-joint-type-of-operation-performed',
  healthOwnerReplacementJointOperationPerformedDate = 'health-owner-replacement-joint-operation-performed-date',
  healthOwnerReplacementJointTypeOfOperationPlanned = 'health-owner-replacement-joint-type-of-operation-planned',
  healthOwnerReplacementJointOperationPlannedDate = 'health-owner-replacement-joint-operation-planned-date',
  healthOwnerReplacementJointOperationUnknownDate = 'health-owner-replacement-joint-operation-unknown-date',
  healthOwnerReplacementJointHadAbsence = 'health-owner-replacement-joint-has-had-absence',
  healthOwnerReplacementJointAbsenceStartDate = 'health-owner-replacement-joint-absence-start-date',
  healthOwnerReplacementJointAbsenceFrequencyNumber = 'health-owner-replacement-joint-absence-frequency-number',
  healthOwnerReplacementJointAbsenceFrequency = 'health-owner-replacement-joint-absence-frequency',
  healthOwnerReplacementJointAbsenceHasRecovered = 'health-owner-replacement-joint-absence-has-recovered',
  healthOwnerReplacementJointAbsenceRecoveryDate = 'health-owner-replacement-joint-absence-recovery-date',
  healthOwnerReplacementJointAbsenceNotRecoveredDetails = 'health-owner-replacement-joint-absence-not-recovered-details',

  healthOwnerReplacementJointHasConsultedDoctorsDetails = 'health-owner-replacement-joint-has-consulted-doctors-details',
  healthOwnerReplacementJointHasConsultedDoctorsProfession = 'health-owner-replacement-joint-has-consulted-doctors-profession',
  healthOwnerReplacementJointHasConsultedDoctorsProfessionDetails = 'health-owner-replacement-joint-has-consulted-doctors-profession-details',
  healthOwnerReplacementJointHasConsultedDoctorsName = 'health-owner-replacement-joint-has-consulted-doctors-name',
  healthOwnerReplacementJointHasConsultedDoctorsAddress = 'health-owner-replacement-joint-has-consulted-doctors-address',
  healthOwnerReplacementJointHasConsultedDoctorsFirstDate = 'health-owner-replacement-joint-has-consulted-doctors-first-date',
  healthOwnerReplacementJointHasConsultedDoctorsLastDate = 'health-owner-replacement-joint-has-consulted-doctors-last-date',
  healthOwnerReplacementJointHasConsultedDoctorsResult = 'health-owner-replacement-joint-has-consulted-doctors-result',
  healthOwnerReplacementJointHasConsultedDoctorsFrequencyNumber = 'health-owner-replacement-joint-has-consulted-doctors-frequency-number',
  healthOwnerReplacementJointHasConsultedDoctorsFrequency = 'health-owner-replacement-joint-has-consulted-doctors-frequency',
  healthOwnerReplacementJointHasConsultedDoctorFurtherConsultation = 'health-owner-replacement-joint-has-consulted-doctor-further-consultation',
  healthOwnerReplacementJointHasConsultedDoctorsNextConsultationDate = 'health-owner-replacement-joint-has-consulted-doctors-next-consultation-date',
  healthOwnerReplacementJointHasTakenTestsOrExamsDetails = 'health-owner-replacement-joint-has-taken-tests-or-exams-details',
  healthOwnerReplacementJointHasTakenTestsOrExamsType = 'health-owner-replacement-joint-has-taken-tests-or-exams-type',
  healthOwnerReplacementJointHasTakenTestsOrExamsDate = 'health-owner-replacement-joint-has-taken-tests-or-exams-date',
  healthOwnerReplacementJointHasTakenTestsOrExamsResult = 'health-owner-replacement-joint-has-taken-tests-or-exams-result',
  healthOwnerReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult = 'health-owner-replacement-joint-has-taken-tests-or-exams-details-abnormal-result',
  healthOwnerReplacementJointMedicationDetails = 'health-owner-replacement-joint-medication-details',
  healthOwnerReplacementJointMedicationName = 'health-owner-replacement-joint-medication-name',
  healthOwnerReplacementJointMedicationDosage = 'health-owner-replacement-joint-medication-dosage',
  healthOwnerReplacementJointMedicationStartDate = 'health-owner-replacement-joint-medication-start-date',
  healthOwnerReplacementJointMedicationEndDate = 'health-owner-replacement-joint-medication-end-date',

  healthSecondOwnerCirculatorySystemHasCondition = 'health-second-owner-circulatory-system-has-condition',
  healthSecondOwnerCirculatorySystemChoices = 'health-second-owner-circulatory-system-choices',
  healthSecondOwnerHighBloodPressureMedicationWasPrescribed = 'health-second-owner-high-blood-pressure-medication-was-prescribed',
  healthSecondOwnerHighBloodPressureDiagnosisDate = 'health-second-owner-high-blood-pressure-diagnosis-date',
  healthSecondOwnerHighBloodPressureIsControlled = 'health-second-owner-high-blood-pressure-is-controlled',
  healthSecondOwnerHighBloodPressureMedicationDetails = 'health-second-owner-high-blood-pressure-medication-details',
  healthSecondOwnerHighBloodPressureNoMedicationReason = 'health-second-owner-high-blood-pressure-no-medication-reason',

  healthSecondOwnerAbnormalBloodPressureDetails = 'health-second-owner-abnormal-blood-pressure-details',
  healthSecondOwnerHighCholesterolMedicationWasPrescribed = 'health-second-owner-high-cholesterol-medication-was-prescribed',
  healthSecondOwnerHighCholesterolDiagnosisDate = 'health-second-owner-high-cholesterol-diagnosis-date',
  healthSecondOwnerHighCholesterolIsControlled = 'health-second-owner-high-cholesterol-is-controlled',
  healthSecondOwnerHighCholesterolMedicationDetails = 'health-second-owner-high-cholesterol-medication-details',
  healthSecondOwnerHighCholesterolNoMedicationReason = 'health-second-owner-high-cholesterol-no-medication-reason',

  healthSecondOwnerCirculatorySystemCholesterolDetails = 'health-second-owner-circulatory-system-cholesterol-details',
  healthSecondOwnerAneurysmDetails = 'health-second-owner-aneurysm-details',
  healthSecondOwnerHeartAttackDetails = 'health-second-owner-heart-attack-details',
  healthSecondOwnerAnginaDetails = 'health-second-owner-angina-details',
  healthSecondOwnerTiaDetails = 'health-second-owner-tia-details',
  healthSecondOwnerCvaDetails = 'health-second-owner-cva-details',
  healthSecondOwnerHeartMurmurDetails = 'health-second-owner-heart-murmur-details',
  healthSecondOwnerChestPainDetails = 'health-second-owner-chest-pain-details',
  healthSecondOwnerPalpitationsDetails = 'health-second-owner-palpitations-details',
  healthSecondOwnerAnyOtherHeartDisorderDetails = 'health-second-owner-any-other-heart-disorder-details',
  healthSecondOwnerRespiratoryHasCondition = 'health-second-owner-respiratory-has-condition',
  healthSecondOwnerRespiratoryChoices = 'health-second-owner-respiratory-choices',
  healthSecondOwnerObstructiveSleepApneaDiagnosisDate = 'health-second-owner-obstructive-sleep-apnea-diagnosis-date',
  healthSecondOwnerCpapTreatment = 'health-second-owner-cpap-treatment',
  healthSecondOwnerCpapTreatmentUsageHours = 'health-second-owner-cpap-treatment-usage-hours',
  healthSecondOwnerCpapTreatmentUsageWeek = 'health-second-owner-cpap-treatment-usage-week',
  healthSecondOwnerCpapTreatmentIsControlled = 'health-second-owner-cpap-treatment-is-controlled',
  healthSecondOwnerNoCpapTreatmentReason = 'health-second-owner-no-cpap-treatment-reason',
  healthSecondOwnerNoCpapTreatmentReasonOtherTreatment = 'health-second-owner-no-cpap-treatment-reason-other-treatment',
  healthSecondOwnerNoCpapTreatmentReasonOther = 'health-second-owner-no-cpap-treatment-reason-other',

  healthSecondOwnerSleepApneaSymptomsSeverity = 'health-second-owner-sleep-apnea-symptoms-severity',
  healthSecondOwnerAsthmaDetails = 'health-second-owner-asthma-details',
  healthSecondOwnerChronicBronchitisDetails = 'health-second-owner-chronic-bronchitis-details',
  healthSecondOwnerEmphysemaDetails = 'health-second-owner-emphysema-details',
  healthSecondOwnerCopdDetails = 'health-second-owner-copd-details',
  healthSecondOwnerCysticFibrosisDetails = 'health-second-owner-cystic-fibrosis-details',
  healthSecondOwnerSarcoidosisDetails = 'health-second-owner-sarcoidosis-details',
  healthSecondOwnerCoughingUpBloodDetails = 'health-second-owner-coughing-up-blood-details',
  healthSecondOwnerShortnessOfBreathDetails = 'health-second-owner-shortness-of-breath-details',
  healthSecondOwnerAnyOtherRespiratoryDisorderDetails = 'health-second-owner-any-other-respiratory-disorder-details',
  healthSecondOwnerDigestiveSystemHasCondition = 'health-second-owner-digestive-system-has-condition',
  healthSecondOwnerDigestiveSystemChoices = 'health-second-owner-digestive-system-choices',
  healthSecondOwnerGerdIsControlled = 'health-second-owner-gerd-is-controlled',
  healthSecondOwnerGerdConsulted = 'health-second-owner-gerd-consulted',
  healthSecondOwnerGerdDetails = 'health-second-owner-gerd-details',
  healthSecondOwnerGerdComplications = 'health-second-owner-gerd-complications',
  healthSecondOwnerGerdComplicationsDetails = 'health-second-owner-gerd-complications-details',
  healthSecondOwnerGallstonesDiagnosisDate = 'health-second-owner-gallstones-diagnosis-date',
  healthSecondOwnerGallstonesHadOperation = 'health-second-owner-gallstones-had-operation',
  healthSecondOwnerGallstonesOperationDate = 'health-second-owner-gallstones-operation-date',
  healthSecondOwnerGallstonesPastSurgeryRecovery = 'health-second-owner-gallstones-past-surgery-recovery',
  healthSecondOwnerGallstonesPendingSurgery = 'health-second-owner-gallstones-pending-surgery',
  healthSecondOwnerGallstonesPendingSurgeryDate = 'health-second-owner-gallstones-pending-surgery-date',
  healthSecondOwnerGallstonesPendingSurgeryUnknownDate = 'health-second-owner-gallstones-pending-surgery-unknown-date',
  healthSecondOwnerCrohnsDiseaseDetails = 'health-second-owner-crohns-disease-details',
  healthSecondOwnerUlcerativeColitisDetails = 'health-second-owner-ulcerative-colitis-details',
  healthSecondOwnerCeliacDiseaseDetails = 'health-second-owner-celiac-disease-details',
  healthSecondOwnerPolypsDetails = 'health-second-owner-polyps-details',
  healthSecondOwnerHepatitisDetails = 'health-second-owner-hepatitis-details',
  healthSecondOwnerCirrhosisDetails = 'health-second-owner-cirrhosis-details',
  healthSecondOwnerPancreatitisDetails = 'health-second-owner-pancreatitis-details',
  healthSecondOwnerUlcersDetails = 'health-second-owner-ulcers-details',
  healthSecondOwnerGallbladderDisorderDetails = 'health-second-owner-gallbladder-disorder-details',
  healthSecondOwnerBleedingDetails = 'health-second-owner-bleeding-details',
  healthSecondOwnerAnyOtherDisorderOfTheDigestiveSystemDetails = 'health-second-owner-any-other-disorder-of-the-digestive-system-details',
  healthSecondOwnerGenitourinaryHasCondition = 'health-second-owner-genitourinary-has-condition',
  healthSecondOwnerGenitourinaryChoices = 'health-second-owner-genitourinary-choices',
  healthSecondOwnerKidneyStoneLastEpisode = 'health-second-owner-kidney-stone-last-episode',
  healthSecondOwnerKidneyStoneEpisodesCount = 'health-second-owner-kidney-stone-episodes-count',
  healthSecondOwnerKidneyStoneHasRecovered = 'health-second-owner-kidney-stone-has-recovered',
  healthSecondOwnerUrineAbnormalitiesDetails = 'health-second-owner-urine-abnormalities-details',
  healthSecondOwnerDisordersOfTheKidneyDetails = 'health-second-owner-disorders-of-the-kidney-details',
  healthSecondOwnerUrinaryTractDetails = 'health-second-owner-urinary-tract-details',
  healthSecondOwnerBladderDetails = 'health-second-owner-bladder-details',
  healthSecondOwnerProstateDetails = 'health-second-owner-prostate-details',
  healthSecondOwnerGenitalOrgansTestsDetails = 'health-second-owner-genital-organs-tests-details',
  healthSecondOwnerEndocrineSystemHasCondition = 'health-second-owner-endocrine-system-has-condition',
  healthSecondOwnerEndocrineChoices = 'health-second-owner-endocrine-choices',
  healthSecondOwnerDiabetesType = 'health-second-owner-diabetes-type',
  healthSecondOwnerDiabetesDetails = 'health-second-owner-diabetes-details',
  healthSecondOwnerThyroidDisorderIsHypothyroidism = 'health-second-owner-thyroid-disorder-is-hypothyroidism',
  healthSecondOwnerThyroidDisorderDiagnosisDate = 'health-second-owner-thyroid-disorder-diagnosis-date',
  healthSecondOwnerThyroidDisorderHadSurgery = 'health-second-owner-thyroid-disorder-had-surgery',
  healthSecondOwnerThyroidDisorderMedicationWasPrescribed = 'health-second-owner-thyroid-disorder-medication-was-prescribed',
  healthSecondOwnerThyroidDisorderSynthroid = 'health-second-owner-thyroid-disorder-synthroid',
  healthSecondOwnerThyroidDisorderIsControlled = 'health-second-owner-thyroid-disorder-is-controlled',
  healthSecondOwnerThyroidDisorderRecoveredDetails = 'health-second-owner-thyroid-disorder-recovered-details',
  healthSecondOwnerAbnormalBloodSugarDetails = 'health-second-owner-abnormal-blood-sugar-details',
  healthSecondOwnerPituitaryGlandDisorderDetails = 'health-second-owner-pituitary-gland-disorder-details',
  healthSecondOwnerAdrenalGlandDisorderDetails = 'health-second-owner-adrenal-gland-disorder-details',
  healthSecondOwnerAnyOtherGlandularOrHormonalDisorderDetails = 'health-second-owner-any-other-glandular-or-hormonal-disorder-details',
  healthSecondOwnerBackNeckHasCondition = 'health-second-owner-back-neck-has-condition',
  healthSecondOwnerBackNeckConditions = 'health-second-owner-back-neck-conditions',
  healthSecondOwnerBackNeckConditionsOther = 'health-second-owner-back-neck-conditions-other',
  healthSecondOwnerOsteoarthritisIsSingleEpisode = 'health-second-owner-osteoarthritis-is-single-episode',
  healthSecondOwnerOsteoarthritisPreviousEpisodesCount = 'health-second-owner-osteoarthritis-previous-episodes-count',
  healthSecondOwnerOsteoarthritisPainLocation = 'health-second-owner-osteoarthritis-pain-location',
  healthSecondOwnerOsteoarthritisDateSymptoms = 'health-second-owner-osteoarthritis-date-symptoms',
  healthSecondOwnerOsteoarthritisPainCause = 'health-second-owner-osteoarthritis-pain-cause',
  healthSecondOwnerOsteoarthritisPainCauseDetails = 'health-second-owner-osteoarthritis-pain-cause-details',
  healthSecondOwnerOsteoarthritisHasConsultedDoctors = 'health-second-owner-osteoarthritis-consulted-doctors',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExams = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams',
  healthSecondOwnerOsteoarthritisMedicationWasPrescribed = 'health-second-owner-osteoarthritis-medication-was-prescribed',
  healthSecondOwnerOsteoarthritisHadOperation = 'health-second-owner-osteoarthritis-had-operation',
  healthSecondOwnerOsteoarthritisOperationPerformedOrPlanned = 'health-second-owner-osteoarthritis-operation-performed-or-planned',
  healthSecondOwnerOsteoarthritisTypeOfOperationPerformed = 'health-second-owner-osteoarthritis-type-of-operation-performed',
  healthSecondOwnerOsteoarthritisOperationPerformedDate = 'health-second-owner-osteoarthritis-operation-performed-date',
  healthSecondOwnerOsteoarthritisTypeOfOperationPlanned = 'health-second-owner-osteoarthritis-type-of-operation-planned',
  healthSecondOwnerOsteoarthritisOperationPlannedDate = 'health-second-owner-osteoarthritis-operation-planned-date',
  healthSecondOwnerOsteoarthritisOperationUnknownDate = 'health-second-owner-osteoarthritis-operation-unknown-date',
  healthSecondOwnerOsteoarthritisHadAbsence = 'health-second-owner-osteoarthritis-had-absence',
  healthSecondOwnerOsteoarthritisAbsenceStartDate = 'health-second-owner-osteoarthritis-absence-start-date',
  healthSecondOwnerOsteoarthritisAbsenceFrequencyNumber = 'health-second-owner-osteoarthritis-absence-frequency-number',
  healthSecondOwnerOsteoarthritisAbsenceFrequency = 'health-second-owner-osteoarthritis-absence-frequency',
  healthSecondOwnerOsteoarthritisAbsenceHasRecovered = 'health-second-owner-osteoarthritis-absence-has-recovered',
  healthSecondOwnerOsteoarthritisAbsenceRecoveryDate = 'health-second-owner-osteoarthritis-absence-recovery-date',
  healthSecondOwnerOsteoarthritisAbsenceNotRecoveredDetails = 'health-second-owner-osteoarthritis-absence-not-recovered-details',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsDetails = 'health-second-owner-osteoarthritis-has-consulted-doctors-details',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsProfession = 'health-second-owner-osteoarthritis-has-consulted-doctors-profession',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-second-owner-osteoarthritis-has-consulted-doctors-profession-details',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsName = 'health-second-owner-osteoarthritis-has-consulted-doctors-name',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsAddress = 'health-second-owner-osteoarthritis-has-consulted-doctors-address',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsFirstDate = 'health-second-owner-osteoarthritis-has-consulted-doctors-first-date',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsLastDate = 'health-second-owner-osteoarthritis-has-consulted-doctors-last-date',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsResult = 'health-second-owner-osteoarthritis-has-consulted-doctors-result',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-osteoarthritis-has-consulted-doctors-frequency-number',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsFrequency = 'health-second-owner-osteoarthritis-has-consulted-doctors-frequency',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-second-owner-osteoarthritis-has-consulted-doctor-further-consultation',
  healthSecondOwnerOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-osteoarthritis-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDetails = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams-details',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsType = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams-type',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDate = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams-date',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsResult = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams-result',
  healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerOsteoarthritisMedicationDetails = 'health-second-owner-osteoarthritis-medication-details',
  healthSecondOwnerOsteoarthritisMedicationName = 'health-second-owner-osteoarthritis-medication-name',
  healthSecondOwnerOsteoarthritisMedicationDosage = 'health-second-owner-osteoarthritis-medication-dosage',
  healthSecondOwnerOsteoarthritisMedicationStartDate = 'health-second-owner-osteoarthritis-medication-start-date',
  healthSecondOwnerOsteoarthritisMedicationEndDate = 'health-second-owner-osteoarthritis-medication-end-date',

  healthSecondOwnerSpondylitisAnkylosingIsSingleEpisode = 'health-second-owner-spondylitis-ankylosing-is-single-episode',
  healthSecondOwnerSpondylitisAnkylosingPreviousEpisodesCount = 'health-second-owner-spondylitis-ankylosing-previous-episodes-count',
  healthSecondOwnerSpondylitisAnkylosingPainLocation = 'health-second-owner-spondylitis-ankylosing-pain-location',
  healthSecondOwnerSpondylitisAnkylosingDateSymptoms = 'health-second-owner-spondylitis-ankylosing-date-symptoms',
  healthSecondOwnerSpondylitisAnkylosingPainCause = 'health-second-owner-spondylitis-ankylosing-pain-cause',
  healthSecondOwnerSpondylitisAnkylosingPainCauseDetails = 'health-second-owner-spondylitis-ankylosing-pain-cause-details',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctors = 'health-second-owner-spondylitis-ankylosing-consulted-doctors',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExams = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams',
  healthSecondOwnerSpondylitisAnkylosingMedicationWasPrescribed = 'health-second-owner-spondylitis-ankylosing-medication-was-prescribed',
  healthSecondOwnerSpondylitisAnkylosingHadOperation = 'health-second-owner-spondylitis-ankylosing-had-operation',
  healthSecondOwnerSpondylitisAnkylosingOperationPerformedOrPlanned = 'health-second-owner-spondylitis-ankylosing-operation-performed-or-planned',
  healthSecondOwnerSpondylitisAnkylosingTypeOfOperationPerformed = 'health-second-owner-spondylitis-ankylosing-type-of-operation-performed',
  healthSecondOwnerSpondylitisAnkylosingOperationPerformedDate = 'health-second-owner-spondylitis-ankylosing-operation-performed-date',
  healthSecondOwnerSpondylitisAnkylosingTypeOfOperationPlanned = 'health-second-owner-spondylitis-ankylosing-type-of-operation-planned',
  healthSecondOwnerSpondylitisAnkylosingOperationPlannedDate = 'health-second-owner-spondylitis-ankylosing-operation-planned-date',
  healthSecondOwnerSpondylitisAnkylosingOperationUnknownDate = 'health-second-owner-spondylitis-ankylosing-operation-unknown-date',
  healthSecondOwnerSpondylitisAnkylosingHadAbsence = 'health-second-owner-spondylitis-ankylosing-had-absence',
  healthSecondOwnerSpondylitisAnkylosingAbsenceStartDate = 'health-second-owner-spondylitis-ankylosing-absence-start-date',
  healthSecondOwnerSpondylitisAnkylosingAbsenceFrequencyNumber = 'health-second-owner-spondylitis-ankylosing-absence-frequency-number',
  healthSecondOwnerSpondylitisAnkylosingAbsenceFrequency = 'health-second-owner-spondylitis-ankylosing-absence-frequency',
  healthSecondOwnerSpondylitisAnkylosingAbsenceHasRecovered = 'health-second-owner-spondylitis-ankylosing-absence-has-recovered',
  healthSecondOwnerSpondylitisAnkylosingAbsenceRecoveryDate = 'health-second-owner-spondylitis-ankylosing-absence-recovery-date',
  healthSecondOwnerSpondylitisAnkylosingAbsenceNotRecoveredDetails = 'health-second-owner-spondylitis-ankylosing-absence-not-recovered-details',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsDetails = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-details',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsProfession = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-profession',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-profession-details',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsName = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-name',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsAddress = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-address',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFirstDate = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-first-date',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsLastDate = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-last-date',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsResult = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-result',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-frequency-number',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequency = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-frequency',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctor-further-consultation',
  healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate = 'health-second-owner-spondylitis-ankylosing-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetails = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams-details',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsType = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams-type',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDate = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams-date',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsResult = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams-result',
  healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-spondylitis-ankylosing-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerSpondylitisAnkylosingMedicationDetails = 'health-second-owner-spondylitis-ankylosing-medication-details',
  healthSecondOwnerSpondylitisAnkylosingMedicationName = 'health-second-owner-spondylitis-ankylosing-medication-name',
  healthSecondOwnerSpondylitisAnkylosingMedicationDosage = 'health-second-owner-spondylitis-ankylosing-medication-dosage',
  healthSecondOwnerSpondylitisAnkylosingMedicationStartDate = 'health-second-owner-spondylitis-ankylosing-medication-start-date',
  healthSecondOwnerSpondylitisAnkylosingMedicationEndDate = 'health-second-owner-spondylitis-ankylosing-medication-end-date',

  healthSecondOwnerOsteoporosisIsSingleEpisode = 'health-second-owner-osteoporosis-is-single-episode',
  healthSecondOwnerOsteoporosisPreviousEpisodesCount = 'health-second-owner-osteoporosis-previous-episodes-count',
  healthSecondOwnerOsteoporosisPainLocation = 'health-second-owner-osteoporosis-pain-location',
  healthSecondOwnerOsteoporosisDateSymptoms = 'health-second-owner-osteoporosis-date-symptoms',
  healthSecondOwnerOsteoporosisPainCause = 'health-second-owner-osteoporosis-pain-cause',
  healthSecondOwnerOsteoporosisPainCauseDetails = 'health-second-owner-osteoporosis-pain-cause-details',
  healthSecondOwnerOsteoporosisHasConsultedDoctors = 'health-second-owner-osteoporosis-consulted-doctors',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExams = 'health-second-owner-osteoporosis-has-taken-tests-or-exams',
  healthSecondOwnerOsteoporosisMedicationWasPrescribed = 'health-second-owner-osteoporosis-medication-was-prescribed',
  healthSecondOwnerOsteoporosisHadOperation = 'health-second-owner-osteoporosis-had-operation',
  healthSecondOwnerOsteoporosisOperationPerformedOrPlanned = 'health-second-owner-osteoporosis-operation-performed-or-planned',
  healthSecondOwnerOsteoporosisTypeOfOperationPerformed = 'health-second-owner-osteoporosis-type-of-operation-performed',
  healthSecondOwnerOsteoporosisOperationPerformedDate = 'health-second-owner-osteoporosis-operation-performed-date',
  healthSecondOwnerOsteoporosisTypeOfOperationPlanned = 'health-second-owner-osteoporosis-type-of-operation-planned',
  healthSecondOwnerOsteoporosisOperationPlannedDate = 'health-second-owner-osteoporosis-operation-planned-date',
  healthSecondOwnerOsteoporosisOperationUnknownDate = 'health-second-owner-osteoporosis-operation-unknown-date',
  healthSecondOwnerOsteoporosisHadAbsence = 'health-second-owner-osteoporosis-had-absence',
  healthSecondOwnerOsteoporosisAbsenceStartDate = 'health-second-owner-osteoporosis-absence-start-date',
  healthSecondOwnerOsteoporosisAbsenceFrequencyNumber = 'health-second-owner-osteoporosis-absence-frequency-number',
  healthSecondOwnerOsteoporosisAbsenceFrequency = 'health-second-owner-osteoporosis-absence-frequency',
  healthSecondOwnerOsteoporosisAbsenceHasRecovered = 'health-second-owner-osteoporosis-absence-has-recovered',
  healthSecondOwnerOsteoporosisAbsenceRecoveryDate = 'health-second-owner-osteoporosis-absence-recovery-date',
  healthSecondOwnerOsteoporosisAbsenceNotRecoveredDetails = 'health-second-owner-osteoporosis-absence-not-recovered-details',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsDetails = 'health-second-owner-osteoporosis-has-consulted-doctors-details',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsProfession = 'health-second-owner-osteoporosis-has-consulted-doctors-profession',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsProfessionDetails = 'health-second-owner-osteoporosis-has-consulted-doctors-profession-details',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsName = 'health-second-owner-osteoporosis-has-consulted-doctors-name',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsAddress = 'health-second-owner-osteoporosis-has-consulted-doctors-address',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsFirstDate = 'health-second-owner-osteoporosis-has-consulted-doctors-first-date',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsLastDate = 'health-second-owner-osteoporosis-has-consulted-doctors-last-date',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsResult = 'health-second-owner-osteoporosis-has-consulted-doctors-result',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-osteoporosis-has-consulted-doctors-frequency-number',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsFrequency = 'health-second-owner-osteoporosis-has-consulted-doctors-frequency',
  healthSecondOwnerOsteoporosisHasConsultedDoctorFurtherConsultation = 'health-second-owner-osteoporosis-has-consulted-doctor-further-consultation',
  healthSecondOwnerOsteoporosisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-osteoporosis-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDetails = 'health-second-owner-osteoporosis-has-taken-tests-or-exams-details',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExamsType = 'health-second-owner-osteoporosis-has-taken-tests-or-exams-type',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDate = 'health-second-owner-osteoporosis-has-taken-tests-or-exams-date',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExamsResult = 'health-second-owner-osteoporosis-has-taken-tests-or-exams-result',
  healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-osteoporosis-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerOsteoporosisMedicationDetails = 'health-second-owner-osteoporosis-medication-details',
  healthSecondOwnerOsteoporosisMedicationName = 'health-second-owner-osteoporosis-medication-name',
  healthSecondOwnerOsteoporosisMedicationDosage = 'health-second-owner-osteoporosis-medication-dosage',
  healthSecondOwnerOsteoporosisMedicationStartDate = 'health-second-owner-osteoporosis-medication-start-date',
  healthSecondOwnerOsteoporosisMedicationEndDate = 'health-second-owner-osteoporosis-medication-end-date',

  healthSecondOwnerWhiplashIsSingleEpisode = 'health-second-owner-whiplash-is-single-episode',
  healthSecondOwnerWhiplashPreviousEpisodesCount = 'health-second-owner-whiplash-previous-episodes-count',
  healthSecondOwnerWhiplashPainLocation = 'health-second-owner-whiplash-pain-location',
  healthSecondOwnerWhiplashDateSymptoms = 'health-second-owner-whiplash-date-symptoms',
  healthSecondOwnerWhiplashPainCause = 'health-second-owner-whiplash-pain-cause',
  healthSecondOwnerWhiplashPainCauseDetails = 'health-second-owner-whiplash-pain-cause-details',
  healthSecondOwnerWhiplashHasConsultedDoctors = 'health-second-owner-whiplash-consulted-doctors',
  healthSecondOwnerWhiplashHasTakenTestsOrExams = 'health-second-owner-whiplash-has-taken-tests-or-exams',
  healthSecondOwnerWhiplashMedicationWasPrescribed = 'health-second-owner-whiplash-medication-was-prescribed',
  healthSecondOwnerWhiplashHadOperation = 'health-second-owner-whiplash-had-operation',
  healthSecondOwnerWhiplashOperationPerformedOrPlanned = 'health-second-owner-whiplash-operation-performed-or-planned',
  healthSecondOwnerWhiplashTypeOfOperationPerformed = 'health-second-owner-whiplash-type-of-operation-performed',
  healthSecondOwnerWhiplashOperationPerformedDate = 'health-second-owner-whiplash-operation-performed-date',
  healthSecondOwnerWhiplashTypeOfOperationPlanned = 'health-second-owner-whiplash-type-of-operation-planned',
  healthSecondOwnerWhiplashOperationPlannedDate = 'health-second-owner-whiplash-operation-planned-date',
  healthSecondOwnerWhiplashOperationUnknownDate = 'health-second-owner-whiplash-operation-unknown-date',
  healthSecondOwnerWhiplashHadAbsence = 'health-second-owner-whiplash-had-absence',
  healthSecondOwnerWhiplashAbsenceStartDate = 'health-second-owner-whiplash-absence-start-date',
  healthSecondOwnerWhiplashAbsenceFrequencyNumber = 'health-second-owner-whiplash-absence-frequency-number',
  healthSecondOwnerWhiplashAbsenceFrequency = 'health-second-owner-whiplash-absence-frequency',
  healthSecondOwnerWhiplashAbsenceHasRecovered = 'health-second-owner-whiplash-absence-has-recovered',
  healthSecondOwnerWhiplashAbsenceRecoveryDate = 'health-second-owner-whiplash-absence-recovery-date',
  healthSecondOwnerWhiplashAbsenceNotRecoveredDetails = 'health-second-owner-whiplash-absence-not-recovered-details',
  healthSecondOwnerWhiplashHasConsultedDoctorsDetails = 'health-second-owner-whiplash-has-consulted-doctors-details',
  healthSecondOwnerWhiplashHasConsultedDoctorsProfession = 'health-second-owner-whiplash-has-consulted-doctors-profession',
  healthSecondOwnerWhiplashHasConsultedDoctorsProfessionDetails = 'health-second-owner-whiplash-has-consulted-doctors-profession-details',
  healthSecondOwnerWhiplashHasConsultedDoctorsName = 'health-second-owner-whiplash-has-consulted-doctors-name',
  healthSecondOwnerWhiplashHasConsultedDoctorsAddress = 'health-second-owner-whiplash-has-consulted-doctors-address',
  healthSecondOwnerWhiplashHasConsultedDoctorsFirstDate = 'health-second-owner-whiplash-has-consulted-doctors-first-date',
  healthSecondOwnerWhiplashHasConsultedDoctorsLastDate = 'health-second-owner-whiplash-has-consulted-doctors-last-date',
  healthSecondOwnerWhiplashHasConsultedDoctorsResult = 'health-second-owner-whiplash-has-consulted-doctors-result',
  healthSecondOwnerWhiplashHasConsultedDoctorsFrequencyNumber = 'health-second-owner-whiplash-has-consulted-doctors-frequency-number',
  healthSecondOwnerWhiplashHasConsultedDoctorsFrequency = 'health-second-owner-whiplash-has-consulted-doctors-frequency',
  healthSecondOwnerWhiplashHasConsultedDoctorFurtherConsultation = 'health-second-owner-whiplash-has-consulted-doctor-further-consultation',
  healthSecondOwnerWhiplashHasConsultedDoctorsNextConsultationDate = 'health-second-owner-whiplash-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerWhiplashHasTakenTestsOrExamsDetails = 'health-second-owner-whiplash-has-taken-tests-or-exams-details',
  healthSecondOwnerWhiplashHasTakenTestsOrExamsType = 'health-second-owner-whiplash-has-taken-tests-or-exams-type',
  healthSecondOwnerWhiplashHasTakenTestsOrExamsDate = 'health-second-owner-whiplash-has-taken-tests-or-exams-date',
  healthSecondOwnerWhiplashHasTakenTestsOrExamsResult = 'health-second-owner-whiplash-has-taken-tests-or-exams-result',
  healthSecondOwnerWhiplashHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-whiplash-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerWhiplashMedicationDetails = 'health-second-owner-whiplash-medication-details',
  healthSecondOwnerWhiplashMedicationName = 'health-second-owner-whiplash-medication-name',
  healthSecondOwnerWhiplashMedicationDosage = 'health-second-owner-whiplash-medication-dosage',
  healthSecondOwnerWhiplashMedicationStartDate = 'health-second-owner-whiplash-medication-start-date',
  healthSecondOwnerWhiplashMedicationEndDate = 'health-second-owner-whiplash-medication-end-date',

  healthSecondOwnerTorticollisIsSingleEpisode = 'health-second-owner-torticollis-is-single-episode',
  healthSecondOwnerTorticollisPreviousEpisodesCount = 'health-second-owner-torticollis-previous-episodes-count',
  healthSecondOwnerTorticollisPainLocation = 'health-second-owner-torticollis-pain-location',
  healthSecondOwnerTorticollisDateSymptoms = 'health-second-owner-torticollis-date-symptoms',
  healthSecondOwnerTorticollisPainCause = 'health-second-owner-torticollis-pain-cause',
  healthSecondOwnerTorticollisPainCauseDetails = 'health-second-owner-torticollis-pain-cause-details',
  healthSecondOwnerTorticollisHasConsultedDoctors = 'health-second-owner-torticollis-consulted-doctors',
  healthSecondOwnerTorticollisHasTakenTestsOrExams = 'health-second-owner-torticollis-has-taken-tests-or-exams',
  healthSecondOwnerTorticollisMedicationWasPrescribed = 'health-second-owner-torticollis-medication-was-prescribed',
  healthSecondOwnerTorticollisHadOperation = 'health-second-owner-torticollis-had-operation',
  healthSecondOwnerTorticollisOperationPerformedOrPlanned = 'health-second-owner-torticollis-operation-performed-or-planned',
  healthSecondOwnerTorticollisTypeOfOperationPerformed = 'health-second-owner-torticollis-type-of-operation-performed',
  healthSecondOwnerTorticollisOperationPerformedDate = 'health-second-owner-torticollis-operation-performed-date',
  healthSecondOwnerTorticollisTypeOfOperationPlanned = 'health-second-owner-torticollis-type-of-operation-planned',
  healthSecondOwnerTorticollisOperationPlannedDate = 'health-second-owner-torticollis-operation-planned-date',
  healthSecondOwnerTorticollisOperationUnknownDate = 'health-second-owner-torticollis-operation-unknown-date',
  healthSecondOwnerTorticollisHadAbsence = 'health-second-owner-torticollis-had-absence',
  healthSecondOwnerTorticollisAbsenceStartDate = 'health-second-owner-torticollis-absence-start-date',
  healthSecondOwnerTorticollisAbsenceFrequencyNumber = 'health-second-owner-torticollis-absence-frequency-number',
  healthSecondOwnerTorticollisAbsenceFrequency = 'health-second-owner-torticollis-absence-frequency',
  healthSecondOwnerTorticollisAbsenceHasRecovered = 'health-second-owner-torticollis-absence-has-recovered',
  healthSecondOwnerTorticollisAbsenceRecoveryDate = 'health-second-owner-torticollis-absence-recovery-date',
  healthSecondOwnerTorticollisAbsenceNotRecoveredDetails = 'health-second-owner-torticollis-absence-not-recovered-details',
  healthSecondOwnerTorticollisHasConsultedDoctorsDetails = 'health-second-owner-torticollis-has-consulted-doctors-details',
  healthSecondOwnerTorticollisHasConsultedDoctorsProfession = 'health-second-owner-torticollis-has-consulted-doctors-profession',
  healthSecondOwnerTorticollisHasConsultedDoctorsProfessionDetails = 'health-second-owner-torticollis-has-consulted-doctors-profession-details',
  healthSecondOwnerTorticollisHasConsultedDoctorsName = 'health-second-owner-torticollis-has-consulted-doctors-name',
  healthSecondOwnerTorticollisHasConsultedDoctorsAddress = 'health-second-owner-torticollis-has-consulted-doctors-address',
  healthSecondOwnerTorticollisHasConsultedDoctorsFirstDate = 'health-second-owner-torticollis-has-consulted-doctors-first-date',
  healthSecondOwnerTorticollisHasConsultedDoctorsLastDate = 'health-second-owner-torticollis-has-consulted-doctors-last-date',
  healthSecondOwnerTorticollisHasConsultedDoctorsResult = 'health-second-owner-torticollis-has-consulted-doctors-result',
  healthSecondOwnerTorticollisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-torticollis-has-consulted-doctors-frequency-number',
  healthSecondOwnerTorticollisHasConsultedDoctorsFrequency = 'health-second-owner-torticollis-has-consulted-doctors-frequency',
  healthSecondOwnerTorticollisHasConsultedDoctorFurtherConsultation = 'health-second-owner-torticollis-has-consulted-doctor-further-consultation',
  healthSecondOwnerTorticollisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-torticollis-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerTorticollisHasTakenTestsOrExamsDetails = 'health-second-owner-torticollis-has-taken-tests-or-exams-details',
  healthSecondOwnerTorticollisHasTakenTestsOrExamsType = 'health-second-owner-torticollis-has-taken-tests-or-exams-type',
  healthSecondOwnerTorticollisHasTakenTestsOrExamsDate = 'health-second-owner-torticollis-has-taken-tests-or-exams-date',
  healthSecondOwnerTorticollisHasTakenTestsOrExamsResult = 'health-second-owner-torticollis-has-taken-tests-or-exams-result',
  healthSecondOwnerTorticollisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-torticollis-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerTorticollisMedicationDetails = 'health-second-owner-torticollis-medication-details',
  healthSecondOwnerTorticollisMedicationName = 'health-second-owner-torticollis-medication-name',
  healthSecondOwnerTorticollisMedicationDosage = 'health-second-owner-torticollis-medication-dosage',
  healthSecondOwnerTorticollisMedicationStartDate = 'health-second-owner-torticollis-medication-start-date',
  healthSecondOwnerTorticollisMedicationEndDate = 'health-second-owner-torticollis-medication-end-date',

  healthSecondOwnerArnoldsNeuralgiaIsSingleEpisode = 'health-second-owner-arnolds-neuralgia-is-single-episode',
  healthSecondOwnerArnoldsNeuralgiaPreviousEpisodesCount = 'health-second-owner-arnolds-neuralgia-previous-episodes-count',
  healthSecondOwnerArnoldsNeuralgiaPainLocation = 'health-second-owner-arnolds-neuralgia-pain-location',
  healthSecondOwnerArnoldsNeuralgiaDateSymptoms = 'health-second-owner-arnolds-neuralgia-date-symptoms',
  healthSecondOwnerArnoldsNeuralgiaPainCause = 'health-second-owner-arnolds-neuralgia-pain-cause',
  healthSecondOwnerArnoldsNeuralgiaPainCauseDetails = 'health-second-owner-arnolds-neuralgia-pain-cause-details',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctors = 'health-second-owner-arnolds-neuralgia-consulted-doctors',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExams = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams',
  healthSecondOwnerArnoldsNeuralgiaMedicationWasPrescribed = 'health-second-owner-arnolds-neuralgia-medication-was-prescribed',
  healthSecondOwnerArnoldsNeuralgiaHadOperation = 'health-second-owner-arnolds-neuralgia-had-operation',
  healthSecondOwnerArnoldsNeuralgiaOperationPerformedOrPlanned = 'health-second-owner-arnolds-neuralgia-operation-performed-or-planned',
  healthSecondOwnerArnoldsNeuralgiaTypeOfOperationPerformed = 'health-second-owner-arnolds-neuralgia-type-of-operation-performed',
  healthSecondOwnerArnoldsNeuralgiaOperationPerformedDate = 'health-second-owner-arnolds-neuralgia-operation-performed-date',
  healthSecondOwnerArnoldsNeuralgiaTypeOfOperationPlanned = 'health-second-owner-arnolds-neuralgia-type-of-operation-planned',
  healthSecondOwnerArnoldsNeuralgiaOperationPlannedDate = 'health-second-owner-arnolds-neuralgia-operation-planned-date',
  healthSecondOwnerArnoldsNeuralgiaOperationUnknownDate = 'health-second-owner-arnolds-neuralgia-operation-unknown-date',
  healthSecondOwnerArnoldsNeuralgiaHadAbsence = 'health-second-owner-arnolds-neuralgia-had-absence',
  healthSecondOwnerArnoldsNeuralgiaAbsenceStartDate = 'health-second-owner-arnolds-neuralgia-absence-start-date',
  healthSecondOwnerArnoldsNeuralgiaAbsenceFrequencyNumber = 'health-second-owner-arnolds-neuralgia-absence-frequency-number',
  healthSecondOwnerArnoldsNeuralgiaAbsenceFrequency = 'health-second-owner-arnolds-neuralgia-absence-frequency',
  healthSecondOwnerArnoldsNeuralgiaAbsenceHasRecovered = 'health-second-owner-arnolds-neuralgia-absence-has-recovered',
  healthSecondOwnerArnoldsNeuralgiaAbsenceRecoveryDate = 'health-second-owner-arnolds-neuralgia-absence-recovery-date',
  healthSecondOwnerArnoldsNeuralgiaAbsenceNotRecoveredDetails = 'health-second-owner-arnolds-neuralgia-absence-not-recovered-details',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsDetails = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-details',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsProfession = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-profession',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-profession-details',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsName = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-name',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsAddress = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-address',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFirstDate = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-first-date',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsLastDate = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-last-date',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsResult = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-result',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-frequency-number',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequency = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-frequency',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation = 'health-second-owner-arnolds-neuralgia-has-consulted-doctor-further-consultation',
  healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate = 'health-second-owner-arnolds-neuralgia-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetails = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams-details',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsType = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams-type',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDate = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams-date',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsResult = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams-result',
  healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-arnolds-neuralgia-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerArnoldsNeuralgiaMedicationDetails = 'health-second-owner-arnolds-neuralgia-medication-details',
  healthSecondOwnerArnoldsNeuralgiaMedicationName = 'health-second-owner-arnolds-neuralgia-medication-name',
  healthSecondOwnerArnoldsNeuralgiaMedicationDosage = 'health-second-owner-arnolds-neuralgia-medication-dosage',
  healthSecondOwnerArnoldsNeuralgiaMedicationStartDate = 'health-second-owner-arnolds-neuralgia-medication-start-date',
  healthSecondOwnerArnoldsNeuralgiaMedicationEndDate = 'health-second-owner-arnolds-neuralgia-medication-end-date',

  healthSecondOwnerSprainIsSingleEpisode = 'health-second-owner-sprain-is-single-episode',
  healthSecondOwnerSprainPreviousEpisodesCount = 'health-second-owner-sprain-previous-episodes-count',
  healthSecondOwnerSprainPainLocation = 'health-second-owner-sprain-pain-location',
  healthSecondOwnerSprainDateSymptoms = 'health-second-owner-sprain-date-symptoms',
  healthSecondOwnerSprainPainCause = 'health-second-owner-sprain-pain-cause',
  healthSecondOwnerSprainPainCauseDetails = 'health-second-owner-sprain-pain-cause-details',
  healthSecondOwnerSprainHasConsultedDoctors = 'health-second-owner-sprain-consulted-doctors',
  healthSecondOwnerSprainHasTakenTestsOrExams = 'health-second-owner-sprain-has-taken-tests-or-exams',
  healthSecondOwnerSprainMedicationWasPrescribed = 'health-second-owner-sprain-medication-was-prescribed',
  healthSecondOwnerSprainHadOperation = 'health-second-owner-sprain-had-operation',
  healthSecondOwnerSprainOperationPerformedOrPlanned = 'health-second-owner-sprain-operation-performed-or-planned',
  healthSecondOwnerSprainTypeOfOperationPerformed = 'health-second-owner-sprain-type-of-operation-performed',
  healthSecondOwnerSprainOperationPerformedDate = 'health-second-owner-sprain-operation-performed-date',
  healthSecondOwnerSprainTypeOfOperationPlanned = 'health-second-owner-sprain-type-of-operation-planned',
  healthSecondOwnerSprainOperationPlannedDate = 'health-second-owner-sprain-operation-planned-date',
  healthSecondOwnerSprainOperationUnknownDate = 'health-second-owner-sprain-operation-unknown-date',
  healthSecondOwnerSprainHadAbsence = 'health-second-owner-sprain-had-absence',
  healthSecondOwnerSprainAbsenceStartDate = 'health-second-owner-sprain-absence-start-date',
  healthSecondOwnerSprainAbsenceFrequencyNumber = 'health-second-owner-sprain-absence-frequency-number',
  healthSecondOwnerSprainAbsenceFrequency = 'health-second-owner-sprain-absence-frequency',
  healthSecondOwnerSprainAbsenceHasRecovered = 'health-second-owner-sprain-absence-has-recovered',
  healthSecondOwnerSprainAbsenceRecoveryDate = 'health-second-owner-sprain-absence-recovery-date',
  healthSecondOwnerSprainAbsenceNotRecoveredDetails = 'health-second-owner-sprain-absence-not-recovered-details',
  healthSecondOwnerSprainHasConsultedDoctorsDetails = 'health-second-owner-sprain-has-consulted-doctors-details',
  healthSecondOwnerSprainHasConsultedDoctorsProfession = 'health-second-owner-sprain-has-consulted-doctors-profession',
  healthSecondOwnerSprainHasConsultedDoctorsProfessionDetails = 'health-second-owner-sprain-has-consulted-doctors-profession-details',
  healthSecondOwnerSprainHasConsultedDoctorsName = 'health-second-owner-sprain-has-consulted-doctors-name',
  healthSecondOwnerSprainHasConsultedDoctorsAddress = 'health-second-owner-sprain-has-consulted-doctors-address',
  healthSecondOwnerSprainHasConsultedDoctorsFirstDate = 'health-second-owner-sprain-has-consulted-doctors-first-date',
  healthSecondOwnerSprainHasConsultedDoctorsLastDate = 'health-second-owner-sprain-has-consulted-doctors-last-date',
  healthSecondOwnerSprainHasConsultedDoctorsResult = 'health-second-owner-sprain-has-consulted-doctors-result',
  healthSecondOwnerSprainHasConsultedDoctorsFrequencyNumber = 'health-second-owner-sprain-has-consulted-doctors-frequency-number',
  healthSecondOwnerSprainHasConsultedDoctorsFrequency = 'health-second-owner-sprain-has-consulted-doctors-frequency',
  healthSecondOwnerSprainHasConsultedDoctorFurtherConsultation = 'health-second-owner-sprain-has-consulted-doctor-further-consultation',
  healthSecondOwnerSprainHasConsultedDoctorsNextConsultationDate = 'health-second-owner-sprain-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerSprainHasTakenTestsOrExamsDetails = 'health-second-owner-sprain-has-taken-tests-or-exams-details',
  healthSecondOwnerSprainHasTakenTestsOrExamsType = 'health-second-owner-sprain-has-taken-tests-or-exams-type',
  healthSecondOwnerSprainHasTakenTestsOrExamsDate = 'health-second-owner-sprain-has-taken-tests-or-exams-date',
  healthSecondOwnerSprainHasTakenTestsOrExamsResult = 'health-second-owner-sprain-has-taken-tests-or-exams-result',
  healthSecondOwnerSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerSprainMedicationDetails = 'health-second-owner-sprain-medication-details',
  healthSecondOwnerSprainMedicationName = 'health-second-owner-sprain-medication-name',
  healthSecondOwnerSprainMedicationDosage = 'health-second-owner-sprain-medication-dosage',
  healthSecondOwnerSprainMedicationStartDate = 'health-second-owner-sprain-medication-start-date',
  healthSecondOwnerSprainMedicationEndDate = 'health-second-owner-sprain-medication-end-date',

  healthSecondOwnerLumbagoIsSingleEpisode = 'health-second-owner-lumbago-is-single-episode',
  healthSecondOwnerLumbagoPreviousEpisodesCount = 'health-second-owner-lumbago-previous-episodes-count',
  healthSecondOwnerLumbagoPainLocation = 'health-second-owner-lumbago-pain-location',
  healthSecondOwnerLumbagoDateSymptoms = 'health-second-owner-lumbago-date-symptoms',
  healthSecondOwnerLumbagoPainCause = 'health-second-owner-lumbago-pain-cause',
  healthSecondOwnerLumbagoPainCauseDetails = 'health-second-owner-lumbago-pain-cause-details',
  healthSecondOwnerLumbagoHasConsultedDoctors = 'health-second-owner-lumbago-consulted-doctors',
  healthSecondOwnerLumbagoHasTakenTestsOrExams = 'health-second-owner-lumbago-has-taken-tests-or-exams',
  healthSecondOwnerLumbagoMedicationWasPrescribed = 'health-second-owner-lumbago-medication-was-prescribed',
  healthSecondOwnerLumbagoHadOperation = 'health-second-owner-lumbago-had-operation',
  healthSecondOwnerLumbagoOperationPerformedOrPlanned = 'health-second-owner-lumbago-operation-performed-or-planned',
  healthSecondOwnerLumbagoTypeOfOperationPerformed = 'health-second-owner-lumbago-type-of-operation-performed',
  healthSecondOwnerLumbagoOperationPerformedDate = 'health-second-owner-lumbago-operation-performed-date',
  healthSecondOwnerLumbagoTypeOfOperationPlanned = 'health-second-owner-lumbago-type-of-operation-planned',
  healthSecondOwnerLumbagoOperationPlannedDate = 'health-second-owner-lumbago-operation-planned-date',
  healthSecondOwnerLumbagoOperationUnknownDate = 'health-second-owner-lumbago-operation-unknown-date',
  healthSecondOwnerLumbagoHadAbsence = 'health-second-owner-lumbago-had-absence',
  healthSecondOwnerLumbagoAbsenceStartDate = 'health-second-owner-lumbago-absence-start-date',
  healthSecondOwnerLumbagoAbsenceFrequencyNumber = 'health-second-owner-lumbago-absence-frequency-number',
  healthSecondOwnerLumbagoAbsenceFrequency = 'health-second-owner-lumbago-absence-frequency',
  healthSecondOwnerLumbagoAbsenceHasRecovered = 'health-second-owner-lumbago-absence-has-recovered',
  healthSecondOwnerLumbagoAbsenceRecoveryDate = 'health-second-owner-lumbago-absence-recovery-date',
  healthSecondOwnerLumbagoAbsenceNotRecoveredDetails = 'health-second-owner-lumbago-absence-not-recovered-details',
  healthSecondOwnerLumbagoHasConsultedDoctorsDetails = 'health-second-owner-lumbago-has-consulted-doctors-details',
  healthSecondOwnerLumbagoHasConsultedDoctorsProfession = 'health-second-owner-lumbago-has-consulted-doctors-profession',
  healthSecondOwnerLumbagoHasConsultedDoctorsProfessionDetails = 'health-second-owner-lumbago-has-consulted-doctors-profession-details',
  healthSecondOwnerLumbagoHasConsultedDoctorsName = 'health-second-owner-lumbago-has-consulted-doctors-name',
  healthSecondOwnerLumbagoHasConsultedDoctorsAddress = 'health-second-owner-lumbago-has-consulted-doctors-address',
  healthSecondOwnerLumbagoHasConsultedDoctorsFirstDate = 'health-second-owner-lumbago-has-consulted-doctors-first-date',
  healthSecondOwnerLumbagoHasConsultedDoctorsLastDate = 'health-second-owner-lumbago-has-consulted-doctors-last-date',
  healthSecondOwnerLumbagoHasConsultedDoctorsResult = 'health-second-owner-lumbago-has-consulted-doctors-result',
  healthSecondOwnerLumbagoHasConsultedDoctorsFrequencyNumber = 'health-second-owner-lumbago-has-consulted-doctors-frequency-number',
  healthSecondOwnerLumbagoHasConsultedDoctorsFrequency = 'health-second-owner-lumbago-has-consulted-doctors-frequency',
  healthSecondOwnerLumbagoHasConsultedDoctorFurtherConsultation = 'health-second-owner-lumbago-has-consulted-doctor-further-consultation',
  healthSecondOwnerLumbagoHasConsultedDoctorsNextConsultationDate = 'health-second-owner-lumbago-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerLumbagoHasTakenTestsOrExamsDetails = 'health-second-owner-lumbago-has-taken-tests-or-exams-details',
  healthSecondOwnerLumbagoHasTakenTestsOrExamsType = 'health-second-owner-lumbago-has-taken-tests-or-exams-type',
  healthSecondOwnerLumbagoHasTakenTestsOrExamsDate = 'health-second-owner-lumbago-has-taken-tests-or-exams-date',
  healthSecondOwnerLumbagoHasTakenTestsOrExamsResult = 'health-second-owner-lumbago-has-taken-tests-or-exams-result',
  healthSecondOwnerLumbagoHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-lumbago-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerLumbagoMedicationDetails = 'health-second-owner-lumbago-medication-details',
  healthSecondOwnerLumbagoMedicationName = 'health-second-owner-lumbago-medication-name',
  healthSecondOwnerLumbagoMedicationDosage = 'health-second-owner-lumbago-medication-dosage',
  healthSecondOwnerLumbagoMedicationStartDate = 'health-second-owner-lumbago-medication-start-date',
  healthSecondOwnerLumbagoMedicationEndDate = 'health-second-owner-lumbago-medication-end-date',

  healthSecondOwnerSciaticNerveIsSingleEpisode = 'health-second-owner-sciatic-nerve-is-single-episode',
  healthSecondOwnerSciaticNervePreviousEpisodesCount = 'health-second-owner-sciatic-nerve-previous-episodes-count',
  healthSecondOwnerSciaticNervePainLocation = 'health-second-owner-sciatic-nerve-pain-location',
  healthSecondOwnerSciaticNerveDateSymptoms = 'health-second-owner-sciatic-nerve-date-symptoms',
  healthSecondOwnerSciaticNervePainCause = 'health-second-owner-sciatic-nerve-pain-cause',
  healthSecondOwnerSciaticNervePainCauseDetails = 'health-second-owner-sciatic-nerve-pain-cause-details',
  healthSecondOwnerSciaticNerveHasConsultedDoctors = 'health-second-owner-sciatic-nerve-consulted-doctors',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExams = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams',
  healthSecondOwnerSciaticNerveMedicationWasPrescribed = 'health-second-owner-sciatic-nerve-medication-was-prescribed',
  healthSecondOwnerSciaticNerveHadOperation = 'health-second-owner-sciatic-nerve-had-operation',
  healthSecondOwnerSciaticNerveOperationPerformedOrPlanned = 'health-second-owner-sciatic-nerve-operation-performed-or-planned',
  healthSecondOwnerSciaticNerveTypeOfOperationPerformed = 'health-second-owner-sciatic-nerve-type-of-operation-performed',
  healthSecondOwnerSciaticNerveOperationPerformedDate = 'health-second-owner-sciatic-nerve-operation-performed-date',
  healthSecondOwnerSciaticNerveTypeOfOperationPlanned = 'health-second-owner-sciatic-nerve-type-of-operation-planned',
  healthSecondOwnerSciaticNerveOperationPlannedDate = 'health-second-owner-sciatic-nerve-operation-planned-date',
  healthSecondOwnerSciaticNerveOperationUnknownDate = 'health-second-owner-sciatic-nerve-operation-unknown-date',
  healthSecondOwnerSciaticNerveHadAbsence = 'health-second-owner-sciatic-nerve-had-absence',
  healthSecondOwnerSciaticNerveAbsenceStartDate = 'health-second-owner-sciatic-nerve-absence-start-date',
  healthSecondOwnerSciaticNerveAbsenceFrequencyNumber = 'health-second-owner-sciatic-nerve-absence-frequency-number',
  healthSecondOwnerSciaticNerveAbsenceFrequency = 'health-second-owner-sciatic-nerve-absence-frequency',
  healthSecondOwnerSciaticNerveAbsenceHasRecovered = 'health-second-owner-sciatic-nerve-absence-has-recovered',
  healthSecondOwnerSciaticNerveAbsenceRecoveryDate = 'health-second-owner-sciatic-nerve-absence-recovery-date',
  healthSecondOwnerSciaticNerveAbsenceNotRecoveredDetails = 'health-second-owner-sciatic-nerve-absence-not-recovered-details',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsDetails = 'health-second-owner-sciatic-nerve-has-consulted-doctors-details',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsProfession = 'health-second-owner-sciatic-nerve-has-consulted-doctors-profession',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsProfessionDetails = 'health-second-owner-sciatic-nerve-has-consulted-doctors-profession-details',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsName = 'health-second-owner-sciatic-nerve-has-consulted-doctors-name',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsAddress = 'health-second-owner-sciatic-nerve-has-consulted-doctors-address',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsFirstDate = 'health-second-owner-sciatic-nerve-has-consulted-doctors-first-date',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsLastDate = 'health-second-owner-sciatic-nerve-has-consulted-doctors-last-date',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsResult = 'health-second-owner-sciatic-nerve-has-consulted-doctors-result',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsFrequencyNumber = 'health-second-owner-sciatic-nerve-has-consulted-doctors-frequency-number',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsFrequency = 'health-second-owner-sciatic-nerve-has-consulted-doctors-frequency',
  healthSecondOwnerSciaticNerveHasConsultedDoctorFurtherConsultation = 'health-second-owner-sciatic-nerve-has-consulted-doctor-further-consultation',
  healthSecondOwnerSciaticNerveHasConsultedDoctorsNextConsultationDate = 'health-second-owner-sciatic-nerve-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDetails = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams-details',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExamsType = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams-type',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDate = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams-date',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExamsResult = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams-result',
  healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-sciatic-nerve-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerSciaticNerveMedicationDetails = 'health-second-owner-sciatic-nerve-medication-details',
  healthSecondOwnerSciaticNerveMedicationName = 'health-second-owner-sciatic-nerve-medication-name',
  healthSecondOwnerSciaticNerveMedicationDosage = 'health-second-owner-sciatic-nerve-medication-dosage',
  healthSecondOwnerSciaticNerveMedicationStartDate = 'health-second-owner-sciatic-nerve-medication-start-date',
  healthSecondOwnerSciaticNerveMedicationEndDate = 'health-second-owner-sciatic-nerve-medication-end-date',

  healthSecondOwnerDiscDegenerationIsSingleEpisode = 'health-second-owner-disc-degeneration-is-single-episode',
  healthSecondOwnerDiscDegenerationPreviousEpisodesCount = 'health-second-owner-disc-degeneration-previous-episodes-count',
  healthSecondOwnerDiscDegenerationPainLocation = 'health-second-owner-disc-degeneration-pain-location',
  healthSecondOwnerDiscDegenerationDateSymptoms = 'health-second-owner-disc-degeneration-date-symptoms',
  healthSecondOwnerDiscDegenerationPainCause = 'health-second-owner-disc-degeneration-pain-cause',
  healthSecondOwnerDiscDegenerationPainCauseDetails = 'health-second-owner-disc-degeneration-pain-cause-details',
  healthSecondOwnerDiscDegenerationHasConsultedDoctors = 'health-second-owner-disc-degeneration-consulted-doctors',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExams = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams',
  healthSecondOwnerDiscDegenerationMedicationWasPrescribed = 'health-second-owner-disc-degeneration-medication-was-prescribed',
  healthSecondOwnerDiscDegenerationHadOperation = 'health-second-owner-disc-degeneration-had-operation',
  healthSecondOwnerDiscDegenerationOperationPerformedOrPlanned = 'health-second-owner-disc-degeneration-operation-performed-or-planned',
  healthSecondOwnerDiscDegenerationTypeOfOperationPerformed = 'health-second-owner-disc-degeneration-type-of-operation-performed',
  healthSecondOwnerDiscDegenerationOperationPerformedDate = 'health-second-owner-disc-degeneration-operation-performed-date',
  healthSecondOwnerDiscDegenerationTypeOfOperationPlanned = 'health-second-owner-disc-degeneration-type-of-operation-planned',
  healthSecondOwnerDiscDegenerationOperationPlannedDate = 'health-second-owner-disc-degeneration-operation-planned-date',
  healthSecondOwnerDiscDegenerationOperationUnknownDate = 'health-second-owner-disc-degeneration-operation-unknown-date',
  healthSecondOwnerDiscDegenerationHadAbsence = 'health-second-owner-disc-degeneration-had-absence',
  healthSecondOwnerDiscDegenerationAbsenceStartDate = 'health-second-owner-disc-degeneration-absence-start-date',
  healthSecondOwnerDiscDegenerationAbsenceFrequencyNumber = 'health-second-owner-disc-degeneration-absence-frequency-number',
  healthSecondOwnerDiscDegenerationAbsenceFrequency = 'health-second-owner-disc-degeneration-absence-frequency',
  healthSecondOwnerDiscDegenerationAbsenceHasRecovered = 'health-second-owner-disc-degeneration-absence-has-recovered',
  healthSecondOwnerDiscDegenerationAbsenceRecoveryDate = 'health-second-owner-disc-degeneration-absence-recovery-date',
  healthSecondOwnerDiscDegenerationAbsenceNotRecoveredDetails = 'health-second-owner-disc-degeneration-absence-not-recovered-details',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsDetails = 'health-second-owner-disc-degeneration-has-consulted-doctors-details',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsProfession = 'health-second-owner-disc-degeneration-has-consulted-doctors-profession',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsProfessionDetails = 'health-second-owner-disc-degeneration-has-consulted-doctors-profession-details',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsName = 'health-second-owner-disc-degeneration-has-consulted-doctors-name',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsAddress = 'health-second-owner-disc-degeneration-has-consulted-doctors-address',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsFirstDate = 'health-second-owner-disc-degeneration-has-consulted-doctors-first-date',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsLasttDate = 'health-second-owner-disc-degeneration-has-consulted-doctors-last-date',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsResult = 'health-second-owner-disc-degeneration-has-consulted-doctors-result',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsFrequencyNumber = 'health-second-owner-disc-degeneration-has-consulted-doctors-frequency-number',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsFrequency = 'health-second-owner-disc-degeneration-has-consulted-doctors-frequency',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorFurtherConsultation = 'health-second-owner-disc-degeneration-has-consulted-doctor-further-consultation',
  healthSecondOwnerDiscDegenerationHasConsultedDoctorsNextConsultationDate = 'health-second-owner-disc-degeneration-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDetails = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams-details',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsType = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams-type',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDate = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams-date',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsResult = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams-result',
  healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-disc-degeneration-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerDiscDegenerationMedicationDetails = 'health-second-owner-disc-degeneration-medication-details',
  healthSecondOwnerDiscDegenerationMedicationName = 'health-second-owner-disc-degeneration-medication-name',
  healthSecondOwnerDiscDegenerationMedicationDosage = 'health-second-owner-disc-degeneration-medication-dosage',
  healthSecondOwnerDiscDegenerationMedicationStartDate = 'health-second-owner-disc-degeneration-medication-start-date',
  healthSecondOwnerDiscDegenerationMedicationEndDate = 'health-second-owner-disc-degeneration-medication-end-date',

  healthSecondOwnerFractureIsSingleEpisode = 'health-second-owner-fracture-is-single-episode',
  healthSecondOwnerFracturePreviousEpisodesCount = 'health-second-owner-fracture-previous-episodes-count',
  healthSecondOwnerFracturePainLocation = 'health-second-owner-fracture-pain-location',
  healthSecondOwnerFractureDateSymptoms = 'health-second-owner-fracture-date-symptoms',
  healthSecondOwnerFracturePainCause = 'health-second-owner-fracture-pain-cause',
  healthSecondOwnerFracturePainCauseDetails = 'health-second-owner-fracture-pain-cause-details',
  healthSecondOwnerFractureHasConsultedDoctors = 'health-second-owner-fracture-consulted-doctors',
  healthSecondOwnerFractureHasTakenTestsOrExams = 'health-second-owner-fracture-has-taken-tests-or-exams',
  healthSecondOwnerFractureMedicationWasPrescribed = 'health-second-owner-fracture-medication-was-prescribed',
  healthSecondOwnerFractureHadOperation = 'health-second-owner-fracture-had-operation',
  healthSecondOwnerFractureOperationPerformedOrPlanned = 'health-second-owner-fracture-operation-performed-or-planned',
  healthSecondOwnerFractureTypeOfOperationPerformed = 'health-second-owner-fracture-type-of-operation-performed',
  healthSecondOwnerFractureOperationPerformedDate = 'health-second-owner-fracture-operation-performed-date',
  healthSecondOwnerFractureTypeOfOperationPlanned = 'health-second-owner-fracture-type-of-operation-planned',
  healthSecondOwnerFractureOperationPlannedDate = 'health-second-owner-fracture-operation-planned-date',
  healthSecondOwnerFractureOperationUnknownDate = 'health-second-owner-fracture-operation-unknown-date',
  healthSecondOwnerFractureHadAbsence = 'health-second-owner-fracture-had-absence',
  healthSecondOwnerFractureAbsenceStartDate = 'health-second-owner-fracture-absence-start-date',
  healthSecondOwnerFractureAbsenceFrequencyNumber = 'health-second-owner-fracture-absence-frequency-number',
  healthSecondOwnerFractureAbsenceFrequency = 'health-second-owner-fracture-absence-frequency',
  healthSecondOwnerFractureAbsenceHasRecovered = 'health-second-owner-fracture-absence-has-recovered',
  healthSecondOwnerFractureAbsenceRecoveryDate = 'health-second-owner-fracture-absence-recovery-date',
  healthSecondOwnerFractureAbsenceNotRecoveredDetails = 'health-second-owner-fracture-absence-not-recovered-details',
  healthSecondOwnerFractureHasConsultedDoctorsDetails = 'health-second-owner-fracture-has-consulted-doctors-details',
  healthSecondOwnerFractureHasConsultedDoctorsProfession = 'health-second-owner-fracture-has-consulted-doctors-profession',
  healthSecondOwnerFractureHasConsultedDoctorsProfessionDetails = 'health-second-owner-fracture-has-consulted-doctors-profession-details',
  healthSecondOwnerFractureHasConsultedDoctorsName = 'health-second-owner-fracture-has-consulted-doctors-name',
  healthSecondOwnerFractureHasConsultedDoctorsAddress = 'health-second-owner-fracture-has-consulted-doctors-address',
  healthSecondOwnerFractureHasConsultedDoctorsFirstDate = 'health-second-owner-fracture-has-consulted-doctors-first-date',
  healthSecondOwnerFractureHasConsultedDoctorsLastDate = 'health-second-owner-fracture-has-consulted-doctors-last-date',
  healthSecondOwnerFractureHasConsultedDoctorsResult = 'health-second-owner-fracture-has-consulted-doctors-result',
  healthSecondOwnerFractureHasConsultedDoctorsFrequencyNumber = 'health-second-owner-fracture-has-consulted-doctors-frequency-number',
  healthSecondOwnerFractureHasConsultedDoctorsFrequency = 'health-second-owner-fracture-has-consulted-doctors-frequency',
  healthSecondOwnerFractureHasConsultedDoctorFurtherConsultation = 'health-second-owner-fracture-has-consulted-doctor-further-consultation',
  healthSecondOwnerFractureHasConsultedDoctorsNextConsultationDate = 'health-second-owner-fracture-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerFractureHasTakenTestsOrExamsDetails = 'health-second-owner-fracture-has-taken-tests-or-exams-details',
  healthSecondOwnerFractureHasTakenTestsOrExamsType = 'health-second-owner-fracture-has-taken-tests-or-exams-type',
  healthSecondOwnerFractureHasTakenTestsOrExamsDate = 'health-second-owner-fracture-has-taken-tests-or-exams-date',
  healthSecondOwnerFractureHasTakenTestsOrExamsResult = 'health-second-owner-fracture-has-taken-tests-or-exams-result',
  healthSecondOwnerFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerFractureMedicationDetails = 'health-second-owner-fracture-medication-details',
  healthSecondOwnerFractureMedicationName = 'health-second-owner-fracture-medication-name',
  healthSecondOwnerFractureMedicationDosage = 'health-second-owner-fracture-medication-dosage',
  healthSecondOwnerFractureMedicationStartDate = 'health-second-owner-fracture-medication-start-date',
  healthSecondOwnerFractureMedicationEndDate = 'health-second-owner-fracture-medication-end-date',

  healthSecondOwnerHerniatedDiscIsSingleEpisode = 'health-second-owner-herniated-disc-is-single-episode',
  healthSecondOwnerHerniatedDiscPreviousEpisodesCount = 'health-second-owner-herniated-disc-previous-episodes-count',
  healthSecondOwnerHerniatedDiscPainLocation = 'health-second-owner-herniated-disc-pain-location',
  healthSecondOwnerHerniatedDiscDateSymptoms = 'health-second-owner-herniated-disc-date-symptoms',
  healthSecondOwnerHerniatedDiscPainCause = 'health-second-owner-herniated-disc-pain-cause',
  healthSecondOwnerHerniatedDiscPainCauseDetails = 'health-second-owner-herniated-disc-pain-cause-details',
  healthSecondOwnerHerniatedDiscHasConsultedDoctors = 'health-second-owner-herniated-disc-consulted-doctors',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExams = 'health-second-owner-herniated-disc-has-taken-tests-or-exams',
  healthSecondOwnerHerniatedDiscMedicationWasPrescribed = 'health-second-owner-herniated-disc-medication-was-prescribed',
  healthSecondOwnerHerniatedDiscHadOperation = 'health-second-owner-herniated-disc-had-operation',
  healthSecondOwnerHerniatedDiscOperationPerformedOrPlanned = 'health-second-owner-herniated-disc-operation-performed-or-planned',
  healthSecondOwnerHerniatedDiscTypeOfOperationPerformed = 'health-second-owner-herniated-disc-type-of-operation-performed',
  healthSecondOwnerHerniatedDiscOperationPerformedDate = 'health-second-owner-herniated-disc-operation-performed-date',
  healthSecondOwnerHerniatedDiscTypeOfOperationPlanned = 'health-second-owner-herniated-disc-type-of-operation-planned',
  healthSecondOwnerHerniatedDiscOperationPlannedDate = 'health-second-owner-herniated-disc-operation-planned-date',
  healthSecondOwnerHerniatedDiscOperationUnknownDate = 'health-second-owner-herniated-disc-operation-unknown-date',
  healthSecondOwnerHerniatedDiscHadAbsence = 'health-second-owner-herniated-disc-had-absence',
  healthSecondOwnerHerniatedDiscAbsenceStartDate = 'health-second-owner-herniated-disc-absence-start-date',
  healthSecondOwnerHerniatedDiscAbsenceFrequencyNumber = 'health-second-owner-herniated-disc-absence-frequency-number',
  healthSecondOwnerHerniatedDiscAbsenceFrequency = 'health-second-owner-herniated-disc-absence-frequency',
  healthSecondOwnerHerniatedDiscAbsenceHasRecovered = 'health-second-owner-herniated-disc-absence-has-recovered',
  healthSecondOwnerHerniatedDiscAbsenceRecoveryDate = 'health-second-owner-herniated-disc-absence-recovery-date',
  healthSecondOwnerHerniatedDiscAbsenceNotRecoveredDetails = 'health-second-owner-herniated-disc-absence-not-recovered-details',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsDetails = 'health-second-owner-herniated-disc-has-consulted-doctors-details',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsProfession = 'health-second-owner-herniated-disc-has-consulted-doctors-profession',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsProfessionDetails = 'health-second-owner-herniated-disc-has-consulted-doctors-profession-details',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsName = 'health-second-owner-herniated-disc-has-consulted-doctors-name',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsAddress = 'health-second-owner-herniated-disc-has-consulted-doctors-address',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsFirstDate = 'health-second-owner-herniated-disc-has-consulted-doctors-first-date',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsLastDate = 'health-second-owner-herniated-disc-has-consulted-doctors-last-date',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsResult = 'health-second-owner-herniated-disc-has-consulted-doctors-result',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsFrequencyNumber = 'health-second-owner-herniated-disc-has-consulted-doctors-frequency-number',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsFrequency = 'health-second-owner-herniated-disc-has-consulted-doctors-frequency',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorFurtherConsultation = 'health-second-owner-herniated-disc-has-consulted-doctor-further-consultation',
  healthSecondOwnerHerniatedDiscHasConsultedDoctorsNextConsultationDate = 'health-second-owner-herniated-disc-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDetails = 'health-second-owner-herniated-disc-has-taken-tests-or-exams-details',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsType = 'health-second-owner-herniated-disc-has-taken-tests-or-exams-type',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDate = 'health-second-owner-herniated-disc-has-taken-tests-or-exams-date',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsResult = 'health-second-owner-herniated-disc-has-taken-tests-or-exams-result',
  healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-herniated-disc-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerHerniatedDiscMedicationDetails = 'health-second-owner-herniated-disc-medication-details',
  healthSecondOwnerHerniatedDiscMedicationName = 'health-second-owner-herniated-disc-medication-name',
  healthSecondOwnerHerniatedDiscMedicationDosage = 'health-second-owner-herniated-disc-medication-dosage',
  healthSecondOwnerHerniatedDiscMedicationStartDate = 'health-second-owner-herniated-disc-medication-start-date',
  healthSecondOwnerHerniatedDiscMedicationEndDate = 'health-second-owner-herniated-disc-medication-end-date',

  healthSecondOwnerScoliosisLordosisIsSingleEpisode = 'health-second-owner-scoliosis-lordosis-is-single-episode',
  healthSecondOwnerScoliosisLordosisPreviousEpisodesCount = 'health-second-owner-scoliosis-lordosis-previous-episodes-count',
  healthSecondOwnerScoliosisLordosisPainLocation = 'health-second-owner-scoliosis-lordosis-pain-location',
  healthSecondOwnerScoliosisLordosisDateSymptoms = 'health-second-owner-scoliosis-lordosis-date-symptoms',
  healthSecondOwnerScoliosisLordosisPainCause = 'health-second-owner-scoliosis-lordosis-pain-cause',
  healthSecondOwnerScoliosisLordosisPainCauseDetails = 'health-second-owner-scoliosis-lordosis-pain-cause-details',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctors = 'health-second-owner-scoliosis-lordosis-consulted-doctors',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExams = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams',
  healthSecondOwnerScoliosisLordosisMedicationWasPrescribed = 'health-second-owner-scoliosis-lordosis-medication-was-prescribed',
  healthSecondOwnerScoliosisLordosisHadOperation = 'health-second-owner-scoliosis-lordosis-had-operation',
  healthSecondOwnerScoliosisLordosisOperationPerformedOrPlanned = 'health-second-owner-scoliosis-lordosis-operation-performed-or-planned',
  healthSecondOwnerScoliosisLordosisTypeOfOperationPerformed = 'health-second-owner-scoliosis-lordosis-type-of-operation-performed',
  healthSecondOwnerScoliosisLordosisOperationPerformedDate = 'health-second-owner-scoliosis-lordosis-operation-performed-date',
  healthSecondOwnerScoliosisLordosisTypeOfOperationPlanned = 'health-second-owner-scoliosis-lordosis-type-of-operation-planned',
  healthSecondOwnerScoliosisLordosisOperationPlannedDate = 'health-second-owner-scoliosis-lordosis-operation-planned-date',
  healthSecondOwnerScoliosisLordosisOperationUnknownDate = 'health-second-owner-scoliosis-lordosis-operation-unknown-date',
  healthSecondOwnerScoliosisLordosisHadAbsence = 'health-second-owner-scoliosis-lordosis-had-absence',
  healthSecondOwnerScoliosisLordosisAbsenceStartDate = 'health-second-owner-scoliosis-lordosis-absence-start-date',
  healthSecondOwnerScoliosisLordosisAbsenceFrequencyNumber = 'health-second-owner-scoliosis-lordosis-absence-frequency-number',
  healthSecondOwnerScoliosisLordosisAbsenceFrequency = 'health-second-owner-scoliosis-lordosis-absence-frequency',
  healthSecondOwnerScoliosisLordosisAbsenceHasRecovered = 'health-second-owner-scoliosis-lordosis-absence-has-recovered',
  healthSecondOwnerScoliosisLordosisAbsenceRecoveryDate = 'health-second-owner-scoliosis-lordosis-absence-recovery-date',
  healthSecondOwnerScoliosisLordosisAbsenceNotRecoveredDetails = 'health-second-owner-scoliosis-lordosis-absence-not-recovered-details',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsDetails = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-details',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsProfession = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-profession',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsProfessionDetails = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-profession-details',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsName = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-name',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsAddress = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-address',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFirstDate = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-first-date',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsLastDate = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-last-date',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsResult = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-result',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-frequency-number',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFrequency = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-frequency',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorFurtherConsultation = 'health-second-owner-scoliosis-lordosis-has-consulted-doctor-further-consultation',
  healthSecondOwnerScoliosisLordosisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-scoliosis-lordosis-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDetails = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams-details',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsType = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams-type',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDate = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams-date',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsResult = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams-result',
  healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-scoliosis-lordosis-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerScoliosisLordosisMedicationDetails = 'health-second-owner-scoliosis-lordosis-medication-details',
  healthSecondOwnerScoliosisLordosisMedicationName = 'health-second-owner-scoliosis-lordosis-medication-name',
  healthSecondOwnerScoliosisLordosisMedicationDosage = 'health-second-owner-scoliosis-lordosis-medication-dosage',
  healthSecondOwnerScoliosisLordosisMedicationStartDate = 'health-second-owner-scoliosis-lordosis-medication-start-date',
  healthSecondOwnerScoliosisLordosisMedicationEndDate = 'health-second-owner-scoliosis-lordosis-medication-end-date',
  healthSecondOwnerBackNeckOtherIsSingleEpisode = 'health-second-owner-back-neck-other-is-single-episode',
  healthSecondOwnerBackNeckOtherPreviousEpisodesCount = 'health-second-owner-back-neck-other-previous-episodes-count',
  healthSecondOwnerBackNeckOtherPainLocation = 'health-second-owner-back-neck-other-pain-location',
  healthSecondOwnerBackNeckOtherDateSymptoms = 'health-second-owner-back-neck-other-date-symptoms',
  healthSecondOwnerBackNeckOtherPainCause = 'health-second-owner-back-neck-other-pain-cause',
  healthSecondOwnerBackNeckOtherPainCauseDetails = 'health-second-owner-back-neck-other-pain-cause-details',
  healthSecondOwnerBackNeckOtherHasConsultedDoctors = 'health-second-owner-back-neck-other-consulted-doctors',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExams = 'health-second-owner-back-neck-other-has-taken-tests-or-exams',
  healthSecondOwnerBackNeckOtherMedicationWasPrescribed = 'health-second-owner-back-neck-other-medication-was-prescribed',
  healthSecondOwnerBackNeckOtherHadOperation = 'health-second-owner-back-neck-other-had-operation',
  healthSecondOwnerBackNeckOtherOperationPerformedOrPlanned = 'health-second-owner-back-neck-other-operation-performed-or-planned',
  healthSecondOwnerBackNeckOtherTypeOfOperationPerformed = 'health-second-owner-back-neck-other-type-of-operation-performed',
  healthSecondOwnerBackNeckOtherOperationPerformedDate = 'health-second-owner-back-neck-other-operation-performed-date',
  healthSecondOwnerBackNeckOtherTypeOfOperationPlanned = 'health-second-owner-back-neck-other-type-of-operation-planned',
  healthSecondOwnerBackNeckOtherOperationPlannedDate = 'health-second-owner-back-neck-other-operation-planned-date',
  healthSecondOwnerBackNeckOtherOperationUnknownDate = 'health-second-owner-back-neck-other-operation-unknown-date',
  healthSecondOwnerBackNeckOtherHadAbsence = 'health-second-owner-back-neck-other-had-absence',
  healthSecondOwnerBackNeckOtherAbsenceStartDate = 'health-second-owner-back-neck-other-absence-start-date',
  healthSecondOwnerBackNeckOtherAbsenceFrequencyNumber = 'health-second-owner-back-neck-other-absence-frequency-number',
  healthSecondOwnerBackNeckOtherAbsenceFrequency = 'health-second-owner-back-neck-other-absence-frequency',
  healthSecondOwnerBackNeckOtherAbsenceHasRecovered = 'health-second-owner-back-neck-other-absence-has-recovered',
  healthSecondOwnerBackNeckOtherAbsenceRecoveryDate = 'health-second-owner-back-neck-other-absence-recovery-date',
  healthSecondOwnerBackNeckOtherAbsenceNotRecoveredDetails = 'health-second-owner-back-neck-other-absence-not-recovered-details',

  healthSecondOwnerBackNeckOtherHasConsultedDoctorsDetails = 'health-second-owner-back-neck-other-has-consulted-doctors-details',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsProfession = 'health-second-owner-back-neck-other-has-consulted-doctors-profession',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsProfessionDetails = 'health-second-owner-back-neck-other-has-consulted-doctors-profession-details',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsName = 'health-second-owner-back-neck-other-has-consulted-doctors-name',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsAddress = 'health-second-owner-back-neck-other-has-consulted-doctors-address',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsFirstDate = 'health-second-owner-back-neck-other-has-consulted-doctors-first-date',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsLastDate = 'health-second-owner-back-neck-other-has-consulted-doctors-last-date',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsResult = 'health-second-owner-back-neck-other-has-consulted-doctors-result',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsFrequencyNumber = 'health-second-owner-back-neck-other-has-consulted-doctors-frequency-number',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsFrequency = 'health-second-owner-back-neck-other-has-consulted-doctors-frequency',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorFurtherConsultation = 'health-second-owner-back-neck-other-has-consulted-doctor-further-consultation',
  healthSecondOwnerBackNeckOtherHasConsultedDoctorsNextConsultationDate = 'health-second-owner-back-neck-other-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDetails = 'health-second-owner-back-neck-other-has-taken-tests-or-exams-details',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsType = 'health-second-owner-back-neck-other-has-taken-tests-or-exams-type',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDate = 'health-second-owner-back-neck-other-has-taken-tests-or-exams-date',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsResult = 'health-second-owner-back-neck-other-has-taken-tests-or-exams-result',
  healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-back-neck-other-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerBackNeckOtherMedicationDetails = 'health-second-owner-back-neck-other-medication-details',
  healthSecondOwnerBackNeckOtherMedicationName = 'health-second-owner-back-neck-other-medication-name',
  healthSecondOwnerBackNeckOtherMedicationDosage = 'health-second-owner-back-neck-other-medication-dosage',
  healthSecondOwnerBackNeckOtherMedicationStartDate = 'health-second-owner-back-neck-other-medication-start-date',
  healthSecondOwnerBackNeckOtherMedicationEndDate = 'health-second-owner-back-neck-other-medication-end-date',

  healthSecondOwnerMusculoskeletalHasCondition = 'health-second-owner-musculoskeletal-has-condition',
  healthSecondOwnerMusculoskeletalConditions = 'health-second-owner-musculoskeletal-conditions',
  healthSecondOwnerMusculoskeletalConditionsOther = 'health-second-owner-musculoskeletal-conditions-other',
  healthSecondOwnerArthritisIsSingleEpisode = 'health-second-owner-arthritis-is-single-episode',
  healthSecondOwnerArthritisPreviousEpisodesCount = 'health-second-owner-arthritis-previous-episodes-count',
  healthSecondOwnerArthritisDateSymptoms = 'health-second-owner-arthritis-date-symptoms',
  healthSecondOwnerArthritisPainLocation = 'health-second-owner-arthritis-pain-location',
  healthSecondOwnerArthritisPainLocationLeftRight = 'health-second-owner-arthritis-pain-location-left-right',
  healthSecondOwnerArthritisPainLocationOther = 'health-second-owner-arthritis-pain-location-other',
  healthSecondOwnerArthritisPainCause = 'health-second-owner-arthritis-pain-cause',
  healthSecondOwnerArthritisPainCauseDetails = 'health-second-owner-arthritis-pain-cause-details',
  healthSecondOwnerArthritisHasConsultedDoctors = 'health-second-owner-arthritis-consulted-doctors',
  healthSecondOwnerArthritisHasTakenTestsOrExams = 'health-second-owner-arthritis-has-taken-tests-or-exams',
  healthSecondOwnerArthritisMedicationWasPrescribed = 'health-second-owner-arthritis-medication-was-prescribed',
  healthSecondOwnerArthritisHadOperation = 'health-second-owner-arthritis-had-operation',
  healthSecondOwnerArthritisOperationPerformedOrPlanned = 'health-second-owner-arthritis-operation-performed-or-planned',
  healthSecondOwnerArthritisTypeOfOperationPerformed = 'health-second-owner-arthritis-type-of-operation-performed',
  healthSecondOwnerArthritisOperationPerformedDate = 'health-second-owner-arthritis-operation-performed-date',
  healthSecondOwnerArthritisTypeOfOperationPlanned = 'health-second-owner-arthritis-type-of-operation-planned',
  healthSecondOwnerArthritisOperationPlannedDate = 'health-second-owner-arthritis-operation-planned-date',
  healthSecondOwnerArthritisOperationUnknownDate = 'health-second-owner-arthritis-operation-unknown-date',
  healthSecondOwnerArthritisHadAbsence = 'health-second-owner-arthritis-had-absence',
  healthSecondOwnerArthritisAbsenceStartDate = 'health-second-owner-arthritis-absence-start-date',
  healthSecondOwnerArthritisAbsenceFrequencyNumber = 'health-second-owner-arthritis-absence-frequency-number',
  healthSecondOwnerArthritisAbsenceFrequency = 'health-second-owner-arthritis-absence-frequency',
  healthSecondOwnerArthritisAbsenceHasRecovered = 'health-second-owner-arthritis-absence-has-recovered',
  healthSecondOwnerArthritisAbsenceRecoveryDate = 'health-second-owner-arthritis-absence-recovery-date',
  healthSecondOwnerArthritisAbsenceNotRecoveredDetails = 'health-second-owner-arthritis-absence-not-recovered-details',
  healthSecondOwnerArthritisHasConsultedDoctorsDetails = 'health-second-owner-arthritis-has-consulted-doctors-details',
  healthSecondOwnerArthritisHasConsultedDoctorsProfession = 'health-second-owner-arthritis-has-consulted-doctors-profession',
  healthSecondOwnerArthritisHasConsultedDoctorsProfessionDetails = 'health-second-owner-arthritis-has-consulted-doctors-profession-details',
  healthSecondOwnerArthritisHasConsultedDoctorsName = 'health-second-owner-arthritis-has-consulted-doctors-name',
  healthSecondOwnerArthritisHasConsultedDoctorsAddress = 'health-second-owner-arthritis-has-consulted-doctors-address',
  healthSecondOwnerArthritisHasConsultedDoctorsFirstDate = 'health-second-owner-arthritis-has-consulted-doctors-first-date',
  healthSecondOwnerArthritisHasConsultedDoctorsLastDate = 'health-second-owner-arthritis-has-consulted-doctors-last-date',
  healthSecondOwnerArthritisHasConsultedDoctorsResult = 'health-second-owner-arthritis-has-consulted-doctors-result',
  healthSecondOwnerArthritisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-arthritis-has-consulted-doctors-frequency-number',
  healthSecondOwnerArthritisHasConsultedDoctorsFrequency = 'health-second-owner-arthritis-has-consulted-doctors-frequency',
  healthSecondOwnerArthritisHasConsultedDoctorFurtherConsultation = 'health-second-owner-arthritis-has-consulted-doctor-further-consultation',
  healthSecondOwnerArthritisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-arthritis-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerArthritisHasTakenTestsOrExamsDetails = 'health-second-owner-arthritis-has-taken-tests-or-exams-details',
  healthSecondOwnerArthritisHasTakenTestsOrExamsType = 'health-second-owner-arthritis-has-taken-tests-or-exams-type',
  healthSecondOwnerArthritisHasTakenTestsOrExamsDate = 'health-second-owner-arthritis-has-taken-tests-or-exams-date',
  healthSecondOwnerArthritisHasTakenTestsOrExamsResult = 'health-second-owner-arthritis-has-taken-tests-or-exams-result',
  healthSecondOwnerArthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-arthritis-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerArthritisMedicationDetails = 'health-second-owner-arthritis-medication-details',
  healthSecondOwnerArthritisMedicationName = 'health-second-owner-arthritis-medication-name',
  healthSecondOwnerArthritisMedicationDosage = 'health-second-owner-arthritis-medication-dosage',
  healthSecondOwnerArthritisMedicationStartDate = 'health-second-owner-arthritis-medication-start-date',
  healthSecondOwnerArthritisMedicationEndDate = 'health-second-owner-arthritis-medication-end-date',

  healthSecondOwnerMusculoskeletalSprainIsSingleEpisode = 'health-second-owner-musculoskeletal-sprain-is-single-episode',
  healthSecondOwnerMusculoskeletalSprainPreviousEpisodesCount = 'health-second-owner-musculoskeletal-sprain-previous-episodes-count',
  healthSecondOwnerMusculoskeletalSprainDateSymptoms = 'health-second-owner-musculoskeletal-sprain-date-symptoms',
  healthSecondOwnerMusculoskeletalSprainPainLocation = 'health-second-owner-musculoskeletal-sprain-pain-location',
  healthSecondOwnerMusculoskeletalSprainPainLocationLeftRight = 'health-second-owner-musculoskeletal-sprain-pain-location-left-right',
  healthSecondOwnerMusculoskeletalSprainPainLocationOther = 'health-second-owner-musculoskeletal-sprain-pain-location-other',
  healthSecondOwnerMusculoskeletalSprainPainCause = 'health-second-owner-musculoskeletal-sprain-pain-cause',
  healthSecondOwnerMusculoskeletalSprainPainCauseDetails = 'health-second-owner-musculoskeletal-sprain-pain-cause-details',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctors = 'health-second-owner-musculoskeletal-sprain-consulted-doctors',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExams = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams',
  healthSecondOwnerMusculoskeletalSprainMedicationWasPrescribed = 'health-second-owner-musculoskeletal-sprain-medication-was-prescribed',
  healthSecondOwnerMusculoskeletalSprainHadOperation = 'health-second-owner-musculoskeletal-sprain-had-operation',
  healthSecondOwnerMusculoskeletalSprainOperationPerformedOrPlanned = 'health-second-owner-musculoskeletal-sprain-operation-performed-or-planned',
  healthSecondOwnerMusculoskeletalSprainTypeOfOperationPerformed = 'health-second-owner-musculoskeletal-sprain-type-of-operation-performed',
  healthSecondOwnerMusculoskeletalSprainOperationPerformedDate = 'health-second-owner-musculoskeletal-sprain-operation-performed-date',
  healthSecondOwnerMusculoskeletalSprainTypeOfOperationPlanned = 'health-second-owner-musculoskeletal-sprain-type-of-operation-planned',
  healthSecondOwnerMusculoskeletalSprainOperationPlannedDate = 'health-second-owner-musculoskeletal-sprain-operation-planned-date',
  healthSecondOwnerMusculoskeletalSprainOperationUnknownDate = 'health-second-owner-musculoskeletal-sprain-operation-unknown-date',
  healthSecondOwnerMusculoskeletalSprainHadAbsence = 'health-second-owner-musculoskeletal-sprain-had-absence',
  healthSecondOwnerMusculoskeletalSprainAbsenceStartDate = 'health-second-owner-musculoskeletal-sprain-absence-start-date',
  healthSecondOwnerMusculoskeletalSprainAbsenceFrequencyNumber = 'health-second-owner-musculoskeletal-sprain-absence-frequency-number',
  healthSecondOwnerMusculoskeletalSprainAbsenceFrequency = 'health-second-owner-musculoskeletal-sprain-absence-frequency',
  healthSecondOwnerMusculoskeletalSprainAbsenceHasRecovered = 'health-second-owner-musculoskeletal-sprain-absence-has-recovered',
  healthSecondOwnerMusculoskeletalSprainAbsenceRecoveryDate = 'health-second-owner-musculoskeletal-sprain-absence-recovery-date',
  healthSecondOwnerMusculoskeletalSprainAbsenceNotRecoveredDetails = 'health-second-owner-musculoskeletal-sprain-absence-not-recovered-details',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsDetails = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-details',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsProfession = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-profession',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsProfessionDetails = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-profession-details',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsName = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-name',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsAddress = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-address',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFirstDate = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-first-date',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsLastDate = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-last-date',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsResult = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-result',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-frequency-number',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFrequency = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-frequency',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorFurtherConsultation = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctor-further-consultation',
  healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate = 'health-second-owner-musculoskeletal-sprain-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetails = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams-details',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsType = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams-type',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDate = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams-date',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsResult = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams-result',
  healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-musculoskeletal-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerMusculoskeletalSprainMedicationDetails = 'health-second-owner-musculoskeletal-sprain-medication-details',
  healthSecondOwnerMusculoskeletalSprainMedicationName = 'health-second-owner-musculoskeletal-sprain-medication-name',
  healthSecondOwnerMusculoskeletalSprainMedicationDosage = 'health-second-owner-musculoskeletal-sprain-medication-dosage',
  healthSecondOwnerMusculoskeletalSprainMedicationStartDate = 'health-second-owner-musculoskeletal-sprain-medication-start-date',
  healthSecondOwnerMusculoskeletalSprainMedicationEndDate = 'health-second-owner-musculoskeletal-sprain-medication-end-date',

  healthSecondOwnerMusculoskeletalFractureIsSingleEpisode = 'health-second-owner-musculoskeletal-fracture-is-single-episode',
  healthSecondOwnerMusculoskeletalFracturePreviousEpisodesCount = 'health-second-owner-musculoskeletal-fracture-previous-episodes-count',
  healthSecondOwnerMusculoskeletalFractureDateSymptoms = 'health-second-owner-musculoskeletal-fracture-date-symptoms',
  healthSecondOwnerMusculoskeletalFracturePainLocation = 'health-second-owner-musculoskeletal-fracture-pain-location',
  healthSecondOwnerMusculoskeletalFracturePainLocationLeftRight = 'health-second-owner-musculoskeletal-fracture-pain-location-left-right',
  healthSecondOwnerMusculoskeletalFracturePainLocationOther = 'health-second-owner-musculoskeletal-fracture-pain-location-other',
  healthSecondOwnerMusculoskeletalFracturePainCause = 'health-second-owner-musculoskeletal-fracture-pain-cause',
  healthSecondOwnerMusculoskeletalFracturePainCauseDetails = 'health-second-owner-musculoskeletal-fracture-pain-cause-details',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctors = 'health-second-owner-musculoskeletal-fracture-consulted-doctors',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExams = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams',
  healthSecondOwnerMusculoskeletalFractureMedicationWasPrescribed = 'health-second-owner-musculoskeletal-fracture-medication-was-prescribed',
  healthSecondOwnerMusculoskeletalFractureHadOperation = 'health-second-owner-musculoskeletal-fracture-had-operation',
  healthSecondOwnerMusculoskeletalFractureOperationPerformedOrPlanned = 'health-second-owner-musculoskeletal-fracture-operation-performed-or-planned',
  healthSecondOwnerMusculoskeletalFractureTypeOfOperationPerformed = 'health-second-owner-musculoskeletal-fracture-type-of-operation-performed',
  healthSecondOwnerMusculoskeletalFractureOperationPerformedDate = 'health-second-owner-musculoskeletal-fracture-operation-performed-date',
  healthSecondOwnerMusculoskeletalFractureTypeOfOperationPlanned = 'health-second-owner-musculoskeletal-fracture-type-of-operation-planned',
  healthSecondOwnerMusculoskeletalFractureOperationPlannedDate = 'health-second-owner-musculoskeletal-fracture-operation-planned-date',
  healthSecondOwnerMusculoskeletalFractureOperationUnknownDate = 'health-second-owner-musculoskeletal-fracture-operation-unknown-date',
  healthSecondOwnerMusculoskeletalFractureHadAbsence = 'health-second-owner-musculoskeletal-fracture-had-absence',
  healthSecondOwnerMusculoskeletalFractureAbsenceStartDate = 'health-second-owner-musculoskeletal-fracture-absence-start-date',
  healthSecondOwnerMusculoskeletalFractureAbsenceFrequencyNumber = 'health-second-owner-musculoskeletal-fracture-absence-frequency-number',
  healthSecondOwnerMusculoskeletalFractureAbsenceFrequency = 'health-second-owner-musculoskeletal-fracture-absence-frequency',
  healthSecondOwnerMusculoskeletalFractureAbsenceHasRecovered = 'health-second-owner-musculoskeletal-fracture-absence-has-recovered',
  healthSecondOwnerMusculoskeletalFractureAbsenceRecoveryDate = 'health-second-owner-musculoskeletal-fracture-absence-recovery-date',
  healthSecondOwnerMusculoskeletalFractureAbsenceNotRecoveredDetails = 'health-second-owner-musculoskeletal-fracture-absence-not-recovered-details',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsDetails = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-details',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsProfession = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-profession',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsProfessionDetails = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-profession-details',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsName = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-name',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsAddress = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-address',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFirstDate = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-first-date',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsLastDate = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-last-date',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsResult = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-result',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-frequency-number',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFrequency = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-frequency',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorFurtherConsultation = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctor-further-consultation',
  healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate = 'health-second-owner-musculoskeletal-fracture-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetails = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams-details',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsType = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams-type',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDate = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams-date',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsResult = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams-result',
  healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-musculoskeletal-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerMusculoskeletalFractureMedicationDetails = 'health-second-owner-musculoskeletal-fracture-medication-details',
  healthSecondOwnerMusculoskeletalFractureMedicationName = 'health-second-owner-musculoskeletal-fracture-medication-name',
  healthSecondOwnerMusculoskeletalFractureMedicationDosage = 'health-second-owner-musculoskeletal-fracture-medication-dosage',
  healthSecondOwnerMusculoskeletalFractureMedicationStartDate = 'health-second-owner-musculoskeletal-fracture-medication-start-date',
  healthSecondOwnerMusculoskeletalFractureMedicationEndDate = 'health-second-owner-musculoskeletal-fracture-medication-end-date',

  healthSecondOwnerBursitisIsSingleEpisode = 'health-second-owner-bursitis-is-single-episode',
  healthSecondOwnerBursitisPreviousEpisodesCount = 'health-second-owner-bursitis-previous-episodes-count',
  healthSecondOwnerBursitisDateSymptoms = 'health-second-owner-bursitis-date-symptoms',
  healthSecondOwnerBursitisPainLocation = 'health-second-owner-bursitis-pain-location',
  healthSecondOwnerBursitisPainLocationLeftRight = 'health-second-owner-bursitis-pain-location-left-right',
  healthSecondOwnerBursitisPainLocationOther = 'health-second-owner-bursitis-pain-location-other',
  healthSecondOwnerBursitisPainCause = 'health-second-owner-bursitis-pain-cause',
  healthSecondOwnerBursitisPainCauseDetails = 'health-second-owner-bursitis-pain-cause-details',
  healthSecondOwnerBursitisHasConsultedDoctors = 'health-second-owner-bursitis-consulted-doctors',
  healthSecondOwnerBursitisHasTakenTestsOrExams = 'health-second-owner-bursitis-has-taken-tests-or-exams',
  healthSecondOwnerBursitisMedicationWasPrescribed = 'health-second-owner-bursitis-medication-was-prescribed',
  healthSecondOwnerBursitisHadOperation = 'health-second-owner-bursitis-had-operation',
  healthSecondOwnerBursitisOperationPerformedOrPlanned = 'health-second-owner-bursitis-operation-performed-or-planned',
  healthSecondOwnerBursitisTypeOfOperationPerformed = 'health-second-owner-bursitis-type-of-operation-performed',
  healthSecondOwnerBursitisOperationPerformedDate = 'health-second-owner-bursitis-operation-performed-date',
  healthSecondOwnerBursitisTypeOfOperationPlanned = 'health-second-owner-bursitis-type-of-operation-planned',
  healthSecondOwnerBursitisOperationPlannedDate = 'health-second-owner-bursitis-operation-planned-date',
  healthSecondOwnerBursitisOperationUnknownDate = 'health-second-owner-bursitis-operation-unknown-date',
  healthSecondOwnerBursitisHadAbsence = 'health-second-owner-bursitis-had-absence',
  healthSecondOwnerBursitisAbsenceStartDate = 'health-second-owner-bursitis-absence-start-date',
  healthSecondOwnerBursitisAbsenceFrequencyNumber = 'health-second-owner-bursitis-absence-frequency-number',
  healthSecondOwnerBursitisAbsenceFrequency = 'health-second-owner-bursitis-absence-frequency',
  healthSecondOwnerBursitisAbsenceHasRecovered = 'health-second-owner-bursitis-absence-has-recovered',
  healthSecondOwnerBursitisAbsenceRecoveryDate = 'health-second-owner-bursitis-absence-recovery-date',
  healthSecondOwnerBursitisAbsenceNotRecoveredDetails = 'health-second-owner-bursitis-absence-not-recovered-details',
  healthSecondOwnerBursitisHasConsultedDoctorsDetails = 'health-second-owner-bursitis-has-consulted-doctors-details',
  healthSecondOwnerBursitisHasConsultedDoctorsProfession = 'health-second-owner-bursitis-has-consulted-doctors-profession',
  healthSecondOwnerBursitisHasConsultedDoctorsProfessionDetails = 'health-second-owner-bursitis-has-consulted-doctors-profession-details',
  healthSecondOwnerBursitisHasConsultedDoctorsName = 'health-second-owner-bursitis-has-consulted-doctors-name',
  healthSecondOwnerBursitisHasConsultedDoctorsAddress = 'health-second-owner-bursitis-has-consulted-doctors-address',
  healthSecondOwnerBursitisHasConsultedDoctorsFirstDate = 'health-second-owner-bursitis-has-consulted-doctors-first-date',
  healthSecondOwnerBursitisHasConsultedDoctorsLastDate = 'health-second-owner-bursitis-has-consulted-doctors-last-date',
  healthSecondOwnerBursitisHasConsultedDoctorsResult = 'health-second-owner-bursitis-has-consulted-doctors-result',
  healthSecondOwnerBursitisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-bursitis-has-consulted-doctors-frequency-number',
  healthSecondOwnerBursitisHasConsultedDoctorsFrequency = 'health-second-owner-bursitis-has-consulted-doctors-frequency',
  healthSecondOwnerBursitisHasConsultedDoctorFurtherConsultation = 'health-second-owner-bursitis-has-consulted-doctor-further-consultation',
  healthSecondOwnerBursitisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-bursitis-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerBursitisHasTakenTestsOrExamsDetails = 'health-second-owner-bursitis-has-taken-tests-or-exams-details',
  healthSecondOwnerBursitisHasTakenTestsOrExamsType = 'health-second-owner-bursitis-has-taken-tests-or-exams-type',
  healthSecondOwnerBursitisHasTakenTestsOrExamsDate = 'health-second-owner-bursitis-has-taken-tests-or-exams-date',
  healthSecondOwnerBursitisHasTakenTestsOrExamsResult = 'health-second-owner-bursitis-has-taken-tests-or-exams-result',
  healthSecondOwnerBursitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-bursitis-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerBursitisMedicationDetails = 'health-second-owner-bursitis-medication-details',
  healthSecondOwnerBursitisMedicationName = 'health-second-owner-bursitis-medication-name',
  healthSecondOwnerBursitisMedicationDosage = 'health-second-owner-bursitis-medication-dosage',
  healthSecondOwnerBursitisMedicationStartDate = 'health-second-owner-bursitis-medication-start-date',
  healthSecondOwnerBursitisMedicationEndDate = 'health-second-owner-bursitis-medication-end-date',

  healthSecondOwnerTendonitisIsSingleEpisode = 'health-second-owner-tendonitis-is-single-episode',
  healthSecondOwnerTendonitisPreviousEpisodesCount = 'health-second-owner-tendonitis-previous-episodes-count',
  healthSecondOwnerTendonitisDateSymptoms = 'health-second-owner-tendonitis-date-symptoms',
  healthSecondOwnerTendonitisPainLocation = 'health-second-owner-tendonitis-pain-location',
  healthSecondOwnerTendonitisPainLocationLeftRight = 'health-second-owner-tendonitis-pain-location-left-right',
  healthSecondOwnerTendonitisPainLocationOther = 'health-second-owner-tendonitis-pain-location-other',
  healthSecondOwnerTendonitisPainCause = 'health-second-owner-tendonitis-pain-cause',
  healthSecondOwnerTendonitisPainCauseDetails = 'health-second-owner-tendonitis-pain-cause-details',
  healthSecondOwnerTendonitisHasConsultedDoctors = 'health-second-owner-tendonitis-consulted-doctors',
  healthSecondOwnerTendonitisHasTakenTestsOrExams = 'health-second-owner-tendonitis-has-taken-tests-or-exams',
  healthSecondOwnerTendonitisMedicationWasPrescribed = 'health-second-owner-tendonitis-medication-was-prescribed',
  healthSecondOwnerTendonitisHadOperation = 'health-second-owner-tendonitis-had-operation',
  healthSecondOwnerTendonitisOperationPerformedOrPlanned = 'health-second-owner-tendonitis-operation-performed-or-planned',
  healthSecondOwnerTendonitisTypeOfOperationPerformed = 'health-second-owner-tendonitis-type-of-operation-performed',
  healthSecondOwnerTendonitisOperationPerformedDate = 'health-second-owner-tendonitis-operation-performed-date',
  healthSecondOwnerTendonitisTypeOfOperationPlanned = 'health-second-owner-tendonitis-type-of-operation-planned',
  healthSecondOwnerTendonitisOperationPlannedDate = 'health-second-owner-tendonitis-operation-planned-date',
  healthSecondOwnerTendonitisOperationUnknownDate = 'health-second-owner-tendonitis-operation-unknown-date',
  healthSecondOwnerTendonitisHadAbsence = 'health-second-owner-tendonitis-had-absence',
  healthSecondOwnerTendonitisAbsenceStartDate = 'health-second-owner-tendonitis-absence-start-date',
  healthSecondOwnerTendonitisAbsenceFrequencyNumber = 'health-second-owner-tendonitis-absence-frequency-number',
  healthSecondOwnerTendonitisAbsenceFrequency = 'health-second-owner-tendonitis-absence-frequency',
  healthSecondOwnerTendonitisAbsenceHasRecovered = 'health-second-owner-tendonitis-absence-has-recovered',
  healthSecondOwnerTendonitisAbsenceRecoveryDate = 'health-second-owner-tendonitis-absence-recovery-date',
  healthSecondOwnerTendonitisAbsenceNotRecoveredDetails = 'health-second-owner-tendonitis-absence-not-recovered-details',
  healthSecondOwnerTendonitisHasConsultedDoctorsDetails = 'health-second-owner-tendonitis-has-consulted-doctors-details',
  healthSecondOwnerTendonitisHasConsultedDoctorsProfession = 'health-second-owner-tendonitis-has-consulted-doctors-profession',
  healthSecondOwnerTendonitisHasConsultedDoctorsProfessionDetails = 'health-second-owner-tendonitis-has-consulted-doctors-profession-details',
  healthSecondOwnerTendonitisHasConsultedDoctorsName = 'health-second-owner-tendonitis-has-consulted-doctors-name',
  healthSecondOwnerTendonitisHasConsultedDoctorsAddress = 'health-second-owner-tendonitis-has-consulted-doctors-address',
  healthSecondOwnerTendonitisHasConsultedDoctorsFirstDate = 'health-second-owner-tendonitis-has-consulted-doctors-first-date',
  healthSecondOwnerTendonitisHasConsultedDoctorsLastDate = 'health-second-owner-tendonitis-has-consulted-doctors-last-date',
  healthSecondOwnerTendonitisHasConsultedDoctorsResult = 'health-second-owner-tendonitis-has-consulted-doctors-result',
  healthSecondOwnerTendonitisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-tendonitis-has-consulted-doctors-frequency-number',
  healthSecondOwnerTendonitisHasConsultedDoctorsFrequency = 'health-second-owner-tendonitis-has-consulted-doctors-frequency',
  healthSecondOwnerTendonitisHasConsultedDoctorFurtherConsultation = 'health-second-owner-tendonitis-has-consulted-doctor-further-consultation',
  healthSecondOwnerTendonitisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-tendonitis-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerTendonitisHasTakenTestsOrExamsDetails = 'health-second-owner-tendonitis-has-taken-tests-or-exams-details',
  healthSecondOwnerTendonitisHasTakenTestsOrExamsType = 'health-second-owner-tendonitis-has-taken-tests-or-exams-type',
  healthSecondOwnerTendonitisHasTakenTestsOrExamsDate = 'health-second-owner-tendonitis-has-taken-tests-or-exams-date',
  healthSecondOwnerTendonitisHasTakenTestsOrExamsResult = 'health-second-owner-tendonitis-has-taken-tests-or-exams-result',
  healthSecondOwnerTendonitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-tendonitis-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerTendonitisMedicationDetails = 'health-second-owner-tendonitis-medication-details',
  healthSecondOwnerTendonitisMedicationName = 'health-second-owner-tendonitis-medication-name',
  healthSecondOwnerTendonitisMedicationDosage = 'health-second-owner-tendonitis-medication-dosage',
  healthSecondOwnerTendonitisMedicationStartDate = 'health-second-owner-tendonitis-medication-start-date',
  healthSecondOwnerTendonitisMedicationEndDate = 'health-second-owner-tendonitis-medication-end-date',

  healthSecondOwnerEpicondylitisIsSingleEpisode = 'health-second-owner-epicondylitis-is-single-episode',
  healthSecondOwnerEpicondylitisPreviousEpisodesCount = 'health-second-owner-epicondylitis-previous-episodes-count',
  healthSecondOwnerEpicondylitisDateSymptoms = 'health-second-owner-epicondylitis-date-symptoms',
  healthSecondOwnerEpicondylitisPainLocation = 'health-second-owner-epicondylitis-pain-location',
  healthSecondOwnerEpicondylitisPainLocationLeftRight = 'health-second-owner-epicondylitis-pain-location-left-right',
  healthSecondOwnerEpicondylitisPainLocationOther = 'health-second-owner-epicondylitis-pain-location-other',
  healthSecondOwnerEpicondylitisPainCause = 'health-second-owner-epicondylitis-pain-cause',
  healthSecondOwnerEpicondylitisPainCauseDetails = 'health-second-owner-epicondylitis-pain-cause-details',
  healthSecondOwnerEpicondylitisHasConsultedDoctors = 'health-second-owner-epicondylitis-consulted-doctors',
  healthSecondOwnerEpicondylitisHasTakenTestsOrExams = 'health-second-owner-epicondylitis-has-taken-tests-or-exams',
  healthSecondOwnerEpicondylitisMedicationWasPrescribed = 'health-second-owner-epicondylitis-medication-was-prescribed',
  healthSecondOwnerEpicondylitisHadOperation = 'health-second-owner-epicondylitis-had-operation',
  healthSecondOwnerEpicondylitisOperationPerformedOrPlanned = 'health-second-owner-epicondylitis-operation-performed-or-planned',
  healthSecondOwnerEpicondylitisTypeOfOperationPerformed = 'health-second-owner-epicondylitis-type-of-operation-performed',
  healthSecondOwnerEpicondylitisOperationPerformedDate = 'health-second-owner-epicondylitis-operation-performed-date',
  healthSecondOwnerEpicondylitisTypeOfOperationPlanned = 'health-second-owner-epicondylitis-type-of-operation-planned',
  healthSecondOwnerEpicondylitisOperationPlannedDate = 'health-second-owner-epicondylitis-operation-planned-date',
  healthSecondOwnerEpicondylitisOperationUnknownDate = 'health-second-owner-epicondylitis-operation-unknown-date',
  healthSecondOwnerEpicondylitisHadAbsence = 'health-second-owner-epicondylitis-had-absence',
  healthSecondOwnerEpicondylitisAbsenceStartDate = 'health-second-owner-epicondylitis-absence-start-date',
  healthSecondOwnerEpicondylitisAbsenceFrequencyNumber = 'health-second-owner-epicondylitis-absence-frequency-number',
  healthSecondOwnerEpicondylitisAbsenceFrequency = 'health-second-owner-epicondylitis-absence-frequency',
  healthSecondOwnerEpicondylitisAbsenceHasRecovered = 'health-second-owner-epicondylitis-absence-has-recovered',
  healthSecondOwnerEpicondylitisAbsenceRecoveryDate = 'health-second-owner-epicondylitis-absence-recovery-date',
  healthSecondOwnerEpicondylitisAbsenceNotRecoveredDetails = 'health-second-owner-epicondylitis-absence-not-recovered-details',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsDetails = 'health-second-owner-epicondylitis-has-consulted-doctors-details',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsProfession = 'health-second-owner-epicondylitis-has-consulted-doctors-profession',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsProfessionDetails = 'health-second-owner-epicondylitis-has-consulted-doctors-profession-details',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsName = 'health-second-owner-epicondylitis-has-consulted-doctors-name',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsAddress = 'health-second-owner-epicondylitis-has-consulted-doctors-address',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsFirstDate = 'health-second-owner-epicondylitis-has-consulted-doctors-first-date',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsLastDate = 'health-second-owner-epicondylitis-has-consulted-doctors-last-date',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsResult = 'health-second-owner-epicondylitis-has-consulted-doctors-result',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-epicondylitis-has-consulted-doctors-frequency-number',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsFrequency = 'health-second-owner-epicondylitis-has-consulted-doctors-frequency',
  healthSecondOwnerEpicondylitisHasConsultedDoctorFurtherConsultation = 'health-second-owner-epicondylitis-has-consulted-doctor-further-consultation',
  healthSecondOwnerEpicondylitisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-epicondylitis-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDetails = 'health-second-owner-epicondylitis-has-taken-tests-or-exams-details',
  healthSecondOwnerEpicondylitisHasTakenTestsOrExamsType = 'health-second-owner-epicondylitis-has-taken-tests-or-exams-type',
  healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDate = 'health-second-owner-epicondylitis-has-taken-tests-or-exams-date',
  healthSecondOwnerEpicondylitisHasTakenTestsOrExamsResult = 'health-second-owner-epicondylitis-has-taken-tests-or-exams-result',
  healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-epicondylitis-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerEpicondylitisMedicationDetails = 'health-second-owner-epicondylitis-medication-details',
  healthSecondOwnerEpicondylitisMedicationName = 'health-second-owner-epicondylitis-medication-name',
  healthSecondOwnerEpicondylitisMedicationDosage = 'health-second-owner-epicondylitis-medication-dosage',
  healthSecondOwnerEpicondylitisMedicationStartDate = 'health-second-owner-epicondylitis-medication-start-date',
  healthSecondOwnerEpicondylitisMedicationEndDate = 'health-second-owner-epicondylitis-medication-end-date',

  healthSecondOwnerLigamentTearMeniscusIsSingleEpisode = 'health-second-owner-ligament-tear-meniscus-is-single-episode',
  healthSecondOwnerLigamentTearMeniscusPreviousEpisodesCount = 'health-second-owner-ligament-tear-meniscus-previous-episodes-count',
  healthSecondOwnerLigamentTearMeniscusDateSymptoms = 'health-second-owner-ligament-tear-meniscus-date-symptoms',
  healthSecondOwnerLigamentTearMeniscusPainLocation = 'health-second-owner-ligament-tear-meniscus-pain-location',
  healthSecondOwnerLigamentTearMeniscusPainLocationLeftRight = 'health-second-owner-ligament-tear-meniscus-pain-location-left-right',
  healthSecondOwnerLigamentTearMeniscusPainLocationOther = 'health-second-owner-ligament-tear-meniscus-pain-location-other',
  healthSecondOwnerLigamentTearMeniscusPainCause = 'health-second-owner-ligament-tear-meniscus-pain-cause',
  healthSecondOwnerLigamentTearMeniscusPainCauseDetails = 'health-second-owner-ligament-tear-meniscus-pain-cause-details',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctors = 'health-second-owner-ligament-tear-meniscus-consulted-doctors',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExams = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams',
  healthSecondOwnerLigamentTearMeniscusMedicationWasPrescribed = 'health-second-owner-ligament-tear-meniscus-medication-was-prescribed',
  healthSecondOwnerLigamentTearMeniscusHadOperation = 'health-second-owner-ligament-tear-meniscus-had-operation',
  healthSecondOwnerLigamentTearMeniscusOperationPerformedOrPlanned = 'health-second-owner-ligament-tear-meniscus-operation-performed-or-planned',
  healthSecondOwnerLigamentTearMeniscusTypeOfOperationPerformed = 'health-second-owner-ligament-tear-meniscus-type-of-operation-performed',
  healthSecondOwnerLigamentTearMeniscusOperationPerformedDate = 'health-second-owner-ligament-tear-meniscus-operation-performed-date',
  healthSecondOwnerLigamentTearMeniscusTypeOfOperationPlanned = 'health-second-owner-ligament-tear-meniscus-type-of-operation-planned',
  healthSecondOwnerLigamentTearMeniscusOperationPlannedDate = 'health-second-owner-ligament-tear-meniscus-operation-planned-date',
  healthSecondOwnerLigamentTearMeniscusOperationUnknownDate = 'health-second-owner-ligament-tear-meniscus-operation-unknown-date',
  healthSecondOwnerLigamentTearMeniscusHadAbsence = 'health-second-owner-ligament-tear-meniscus-had-absence',
  healthSecondOwnerLigamentTearMeniscusAbsenceStartDate = 'health-second-owner-ligament-tear-meniscus-absence-start-date',
  healthSecondOwnerLigamentTearMeniscusAbsenceFrequencyNumber = 'health-second-owner-ligament-tear-meniscus-absence-frequency-number',
  healthSecondOwnerLigamentTearMeniscusAbsenceFrequency = 'health-second-owner-ligament-tear-meniscus-absence-frequency',
  healthSecondOwnerLigamentTearMeniscusAbsenceHasRecovered = 'health-second-owner-ligament-tear-meniscus-absence-has-recovered',
  healthSecondOwnerLigamentTearMeniscusAbsenceRecoveryDate = 'health-second-owner-ligament-tear-meniscus-absence-recovery-date',
  healthSecondOwnerLigamentTearMeniscusAbsenceNotRecoveredDetails = 'health-second-owner-ligament-tear-meniscus-absence-not-recovered-details',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsDetails = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-details',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsProfession = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-profession',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsProfessionDetails = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-profession-details',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsName = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-name',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsAddress = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-address',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFirstDate = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-first-date',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsLastDate = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-last-date',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsResult = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-result',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-frequency-number',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFrequency = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-frequency',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorFurtherConsultation = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctor-further-consultation',
  healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate = 'health-second-owner-ligament-tear-meniscus-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetails = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams-details',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsType = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams-type',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDate = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams-date',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsResult = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams-result',
  healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-ligament-tear-meniscus-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerLigamentTearMeniscusMedicationDetails = 'health-second-owner-ligament-tear-meniscus-medication-details',
  healthSecondOwnerLigamentTearMeniscusMedicationName = 'health-second-owner-ligament-tear-meniscus-medication-name',
  healthSecondOwnerLigamentTearMeniscusMedicationDosage = 'health-second-owner-ligament-tear-meniscus-medication-dosage',
  healthSecondOwnerLigamentTearMeniscusMedicationStartDate = 'health-second-owner-ligament-tear-meniscus-medication-start-date',
  healthSecondOwnerLigamentTearMeniscusMedicationEndDate = 'health-second-owner-ligament-tear-meniscus-medication-end-date',

  healthSecondOwnerCarpalTunnelIsSingleEpisode = 'health-second-owner-carpal-tunnel-is-single-episode',
  healthSecondOwnerCarpalTunnelPreviousEpisodesCount = 'health-second-owner-carpal-tunnel-previous-episodes-count',
  healthSecondOwnerCarpalTunnelDateSymptoms = 'health-second-owner-carpal-tunnel-date-symptoms',
  healthSecondOwnerCarpalTunnelPainLocation = 'health-second-owner-carpal-tunnel-pain-location',
  healthSecondOwnerCarpalTunnelPainLocationLeftRight = 'health-second-owner-carpal-tunnel-pain-location-left-right',
  healthSecondOwnerCarpalTunnelPainLocationOther = 'health-second-owner-carpal-tunnel-pain-location-other',
  healthSecondOwnerCarpalTunnelPainCause = 'health-second-owner-carpal-tunnel-pain-cause',
  healthSecondOwnerCarpalTunnelPainCauseDetails = 'health-second-owner-carpal-tunnel-pain-cause-details',
  healthSecondOwnerCarpalTunnelHasConsultedDoctors = 'health-second-owner-carpal-tunnel-consulted-doctors',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExams = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams',
  healthSecondOwnerCarpalTunnelMedicationWasPrescribed = 'health-second-owner-carpal-tunnel-medication-was-prescribed',
  healthSecondOwnerCarpalTunnelHadOperation = 'health-second-owner-carpal-tunnel-had-operation',
  healthSecondOwnerCarpalTunnelOperationPerformedOrPlanned = 'health-second-owner-carpal-tunnel-operation-performed-or-planned',
  healthSecondOwnerCarpalTunnelTypeOfOperationPerformed = 'health-second-owner-carpal-tunnel-type-of-operation-performed',
  healthSecondOwnerCarpalTunnelOperationPerformedDate = 'health-second-owner-carpal-tunnel-operation-performed-date',
  healthSecondOwnerCarpalTunnelTypeOfOperationPlanned = 'health-second-owner-carpal-tunnel-type-of-operation-planned',
  healthSecondOwnerCarpalTunnelOperationPlannedDate = 'health-second-owner-carpal-tunnel-operation-planned-date',
  healthSecondOwnerCarpalTunnelOperationUnknownDate = 'health-second-owner-carpal-tunnel-operation-unknown-date',
  healthSecondOwnerCarpalTunnelHadAbsence = 'health-second-owner-carpal-tunnel-had-absence',
  healthSecondOwnerCarpalTunnelAbsenceStartDate = 'health-second-owner-carpal-tunnel-absence-start-date',
  healthSecondOwnerCarpalTunnelAbsenceFrequencyNumber = 'health-second-owner-carpal-tunnel-absence-frequency-number',
  healthSecondOwnerCarpalTunnelAbsenceFrequency = 'health-second-owner-carpal-tunnel-absence-frequency',
  healthSecondOwnerCarpalTunnelAbsenceHasRecovered = 'health-second-owner-carpal-tunnel-absence-has-recovered',
  healthSecondOwnerCarpalTunnelAbsenceRecoveryDate = 'health-second-owner-carpal-tunnel-absence-recovery-date',
  healthSecondOwnerCarpalTunnelAbsenceNotRecoveredDetails = 'health-second-owner-carpal-tunnel-absence-not-recovered-details',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsDetails = 'health-second-owner-carpal-tunnel-has-consulted-doctors-details',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsProfession = 'health-second-owner-carpal-tunnel-has-consulted-doctors-profession',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsProfessionDetails = 'health-second-owner-carpal-tunnel-has-consulted-doctors-profession-details',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsName = 'health-second-owner-carpal-tunnel-has-consulted-doctors-name',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsAddress = 'health-second-owner-carpal-tunnel-has-consulted-doctors-address',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsFirstDate = 'health-second-owner-carpal-tunnel-has-consulted-doctors-first-date',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsLastDate = 'health-second-owner-carpal-tunnel-has-consulted-doctors-last-date',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsResult = 'health-second-owner-carpal-tunnel-has-consulted-doctors-result',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsFrequencyNumber = 'health-second-owner-carpal-tunnel-has-consulted-doctors-frequency-number',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsFrequency = 'health-second-owner-carpal-tunnel-has-consulted-doctors-frequency',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorFurtherConsultation = 'health-second-owner-carpal-tunnel-has-consulted-doctor-further-consultation',
  healthSecondOwnerCarpalTunnelHasConsultedDoctorsNextConsultationDate = 'health-second-owner-carpal-tunnel-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDetails = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams-details',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsType = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams-type',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDate = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams-date',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsResult = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams-result',
  healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-carpal-tunnel-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerCarpalTunnelMedicationDetails = 'health-second-owner-carpal-tunnel-medication-details',
  healthSecondOwnerCarpalTunnelMedicationName = 'health-second-owner-carpal-tunnel-medication-name',
  healthSecondOwnerCarpalTunnelMedicationDosage = 'health-second-owner-carpal-tunnel-medication-dosage',
  healthSecondOwnerCarpalTunnelMedicationStartDate = 'health-second-owner-carpal-tunnel-medication-start-date',
  healthSecondOwnerCarpalTunnelMedicationEndDate = 'health-second-owner-carpal-tunnel-medication-end-date',

  healthSecondOwnerMuscularDystrophyType = 'health-second-owner-muscular-dystrophy-type',
  healthSecondOwnerMuscularDystrophyDiagnosisDate = 'health-second-owner-muscular-dystrophy-diagnosis-date',
  healthSecondOwnerMuscularDystrophyIsSingleEpisode = 'health-second-owner-muscular-dystrophy-is-single-episode',
  healthSecondOwnerMuscularDystrophyPreviousEpisodesCount = 'health-second-owner-muscular-dystrophy-previous-episodes-count',
  healthSecondOwnerMuscularDystrophyDateSymptoms = 'health-second-owner-muscular-dystrophy-date-symptoms',
  healthSecondOwnerMuscularDystrophyPainLocation = 'health-second-owner-muscular-dystrophy-pain-location',
  healthSecondOwnerMuscularDystrophyPainLocationLeftRight = 'health-second-owner-muscular-dystrophy-pain-location-left-right',
  healthSecondOwnerMuscularDystrophyPainLocationOther = 'health-second-owner-muscular-dystrophy-pain-location-other',
  healthSecondOwnerMuscularDystrophyPainCause = 'health-second-owner-muscular-dystrophy-pain-cause',
  healthSecondOwnerMuscularDystrophyPainCauseDetails = 'health-second-owner-muscular-dystrophy-pain-cause-details',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctors = 'health-second-owner-muscular-dystrophy-consulted-doctors',
  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExams = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams',
  healthSecondOwnerMuscularDystrophyMedicationWasPrescribed = 'health-second-owner-muscular-dystrophy-medication-was-prescribed',
  healthSecondOwnerMuscularDystrophyHadOperation = 'health-second-owner-muscular-dystrophy-had-operation',
  healthSecondOwnerMuscularDystrophyOperationPerformedOrPlanned = 'health-second-owner-muscular-dystrophy-operation-performed-or-planned',
  healthSecondOwnerMuscularDystrophyTypeOfOperationPerformed = 'health-second-owner-muscular-dystrophy-type-of-operation-performed',
  healthSecondOwnerMuscularDystrophyOperationPerformedDate = 'health-second-owner-muscular-dystrophy-operation-performed-date',
  healthSecondOwnerMuscularDystrophyTypeOfOperationPlanned = 'health-second-owner-muscular-dystrophy-type-of-operation-planned',
  healthSecondOwnerMuscularDystrophyOperationPlannedDate = 'health-second-owner-muscular-dystrophy-operation-planned-date',
  healthSecondOwnerMuscularDystrophyOperationUnknownDate = 'health-second-owner-muscular-dystrophy-operation-unknown-date',
  healthSecondOwnerMuscularDystrophyHadAbsence = 'health-second-owner-muscular-dystrophy-had-absence',
  healthSecondOwnerMuscularDystrophyAbsenceStartDate = 'health-second-owner-muscular-dystrophy-absence-start-date',
  healthSecondOwnerMuscularDystrophyAbsenceFrequencyNumber = 'health-second-owner-muscular-dystrophy-absence-frequency-number',
  healthSecondOwnerMuscularDystrophyAbsenceFrequency = 'health-second-owner-muscular-dystrophy-absence-frequency',
  healthSecondOwnerMuscularDystrophyAbsenceHasRecovered = 'health-second-owner-muscular-dystrophy-absence-has-recovered',
  healthSecondOwnerMuscularDystrophyAbsenceRecoveryDate = 'health-second-owner-muscular-dystrophy-absence-recovery-date',
  healthSecondOwnerMuscularDystrophyAbsenceNotRecoveredDetails = 'health-second-owner-muscular-dystrophy-absence-not-recovered-details',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsDetails = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-details',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsProfession = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-profession',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsProfessionDetails = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-profession-details',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsName = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-name',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsAddress = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-address',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFirstDate = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-first-date',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsLastDate = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-last-date',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsResult = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-result',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFrequencyNumber = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-frequency-number',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFrequency = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-frequency',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorFurtherConsultation = 'health-second-owner-muscular-dystrophy-has-consulted-doctor-further-consultation',
  healthSecondOwnerMuscularDystrophyHasConsultedDoctorsNextConsultationDate = 'health-second-owner-muscular-dystrophy-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDetails = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams-details',
  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsType = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams-type',
  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDate = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams-date',
  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsResult = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams-result',
  healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-muscular-dystrophy-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerMuscularDystrophyMedicationDetails = 'health-second-owner-muscular-dystrophy-medication-details',
  healthSecondOwnerMuscularDystrophyMedicationName = 'health-second-owner-muscular-dystrophy-medication-name',
  healthSecondOwnerMuscularDystrophyMedicationDosage = 'health-second-owner-muscular-dystrophy-medication-dosage',
  healthSecondOwnerMuscularDystrophyMedicationStartDate = 'health-second-owner-muscular-dystrophy-medication-start-date',
  healthSecondOwnerMuscularDystrophyMedicationEndDate = 'health-second-owner-muscular-dystrophy-medication-end-date',

  healthSecondOwnerInsomniaMedicationWasPrescribed = 'health-second-owner-insomnia-medication-was-prescribed',
  healthSecondOwnerInsomniaHasConsultedDoctors = 'health-second-owner-insomnia-consulted-doctors',
  healthSecondOwnerInsomniaHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-insomnia-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerInsomniaHadAbsence = 'health-second-owner-insomnia-had-absence',
  healthSecondOwnerInsomniaAbsenceHasRecovered = 'health-second-owner-insomnia-absence-has-recovered',
  healthSecondOwnerInsomniaAbsenceRecoveryDate = 'health-second-owner-insomnia-absence-recovery-date',
  healthSecondOwnerInsomniaAbsenceNotRecoveredDetails = 'health-second-owner-insomnia-absence-not-recovered-details',
  healthSecondOwnerInsomniaMedicationDetails = 'health-second-owner-insomnia-medication-details',
  healthSecondOwnerInsomniaMedicationName = 'health-second-owner-insomnia-medication-name',
  healthSecondOwnerInsomniaMedicationDosage = 'health-second-owner-insomnia-medication-dosage',
  healthSecondOwnerInsomniaMedicationStartDate = 'health-second-owner-insomnia-medication-start-date',
  healthSecondOwnerInsomniaMedicationEndDate = 'health-second-owner-insomnia-medication-end-date',
  healthSecondOwnerInsomniaMedicationStillUses = 'health-second-owner-insomnia-medication-still-uses',
  healthSecondOwnerInsomniaMedicationHasChanged = 'health-second-owner-insomnia-medication-has-changed',
  healthSecondOwnerInsomniaMedicationHasChangedDetails = 'health-second-owner-insomnia-medication-has-changed-details',

  healthSecondOwnerInsomniaHasOrWillConsultDoctorsDetails = 'health-second-owner-insomnia-has-or-will-consult-doctors-details',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsProfession = 'health-second-owner-insomnia-has-or-will-consult-doctors-profession',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-insomnia-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsName = 'health-second-owner-insomnia-has-or-will-consult-doctors-name',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsAddress = 'health-second-owner-insomnia-has-or-will-consult-doctors-address',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsFirstDate = 'health-second-owner-insomnia-has-or-will-consult-doctors-first-date',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsLastDate = 'health-second-owner-insomnia-has-or-will-consult-doctors-last-date',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsResult = 'health-second-owner-insomnia-has-or-will-consult-doctors-result',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-insomnia-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsFrequency = 'health-second-owner-insomnia-has-or-will-consult-doctors-frequency',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-insomnia-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerInsomniaHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-insomnia-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerInsomniaHadAbsenceDetails = 'health-second-owner-insomnia-had-absence-details',
  healthSecondOwnerInsomniaAbsenceStartDate = 'health-second-owner-insomnia-absence-start-date',
  healthSecondOwnerInsomniaAbsenceFrequencyNumber = 'health-second-owner-insomnia-absence-frequency-number',
  healthSecondOwnerInsomniaAbsenceFrequency = 'health-second-owner-insomnia-absence-frequency',

  healthSecondOwnerBipolarDisorderIsSingleEpisode = 'health-second-owner-bipolar-disorder-is-single-episode',
  healthSecondOwnerBipolarDisorderDiagnosticDate = 'health-second-owner-bipolar-disorder-diagnostic-date',
  healthSecondOwnerBipolarDisorderMedicationWasPrescribed = 'health-second-owner-bipolar-disorder-medication-was-prescribed',
  healthSecondOwnerBipolarDisorderHasConsultedDoctors = 'health-second-owner-bipolar-disorder-consulted-doctors',
  healthSecondOwnerBipolarDisorderHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-bipolar-disorder-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerBipolarDisorderWentToEmergencyDepartment = 'health-second-owner-bipolar-disorder-went-to-emergency-department',
  healthSecondOwnerBipolarDisorderWasHospitalized = 'health-second-owner-bipolar-disorder-was-hospitalized',
  healthSecondOwnerBipolarDisorderHadSuicidalThoughtsAndAttempt = 'health-second-owner-bipolar-disorder-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerBipolarDisorderSuicidalThoughtsAndAttemptChoices = 'health-second-owner-bipolar-disorder-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerBipolarDisorderSuicidalThoughtsFrequencyNumber = 'health-second-owner-bipolar-disorder-suicidal-thoughts-frequency-number',
  healthSecondOwnerBipolarDisorderSuicidalThoughtsFrequency = 'health-second-owner-bipolar-disorder-suicidal-thoughts-frequency',
  healthSecondOwnerBipolarDisorderSuicideAttemptsNumber = 'health-second-owner-bipolar-disorder-suicide-attempts-number',
  healthSecondOwnerBipolarDisorderHadAbsence = 'health-second-owner-bipolar-disorder-had-absence',
  healthSecondOwnerBipolarDisorderAbsenceHasRecovered = 'health-second-owner-bipolar-disorder-absence-has-recovered',
  healthSecondOwnerBipolarDisorderAbsenceRecoveryDate = 'health-second-owner-bipolar-disorder-absence-recovery-date',
  healthSecondOwnerBipolarDisorderAbsenceNotRecoveredDetails = 'health-second-owner-bipolar-disorder-absence-not-recovered-details',
  healthSecondOwnerBipolarDisorderMedicationDetails = 'health-second-owner-bipolar-disorder-medication-details',
  healthSecondOwnerBipolarDisorderMedicationName = 'health-second-owner-bipolar-disorder-medication-name',
  healthSecondOwnerBipolarDisorderMedicationDosage = 'health-second-owner-bipolar-disorder-medication-dosage',
  healthSecondOwnerBipolarDisorderMedicationStartDate = 'health-second-owner-bipolar-disorder-medication-start-date',
  healthSecondOwnerBipolarDisorderMedicationEndDate = 'health-second-owner-bipolar-disorder-medication-end-date',
  healthSecondOwnerBipolarDisorderMedicationStillUses = 'health-second-owner-bipolar-disorder-medication-still-uses',
  healthSecondOwnerBipolarDisorderMedicationHasChanged = 'health-second-owner-bipolar-disorder-medication-has-changed',
  healthSecondOwnerBipolarDisorderMedicationHasChangedDetails = 'health-second-owner-bipolar-disorder-medication-has-changed-details',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsDetails = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-details',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsProfession = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-profession',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsName = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-name',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsAddress = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-address',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFirstDate = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-first-date',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsLastDate = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-last-date',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsResult = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-result',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFrequency = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-frequency',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-bipolar-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentDetails = 'health-second-owner-bipolar-disorder-went-to-emergency-department-details',
  healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentDate = 'health-second-owner-bipolar-disorder-went-to-emergency-department-date',
  healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentLocation = 'health-second-owner-bipolar-disorder-went-to-emergency-department-location',
  healthSecondOwnerBipolarDisorderWasHospitalizedDetails = 'health-second-owner-bipolar-disorder-was-hospitalized-details',
  healthSecondOwnerBipolarDisorderWasHospitalizedDate = 'health-second-owner-bipolar-disorder-was-hospitalized-date',
  healthSecondOwnerBipolarDisorderWasHospitalizedFrequencyNumber = 'health-second-owner-bipolar-disorder-was-hospitalized-frequency-number',
  healthSecondOwnerBipolarDisorderWasHospitalizedFrequency = 'health-second-owner-bipolar-disorder-was-hospitalized-frequency',
  healthSecondOwnerBipolarDisorderWasHospitalizedLocation = 'health-second-owner-bipolar-disorder-was-hospitalized-location',
  healthSecondOwnerBipolarDisorderSuicidalThoughtsDetails = 'health-second-owner-bipolar-disorder-suicidal-thoughts-details',
  healthSecondOwnerBipolarDisorderSuicidalThoughtsDate = 'health-second-owner-bipolar-disorder-suicidal-thoughts-date',
  healthSecondOwnerBipolarDisorderSuicideAttemptsDetails = 'health-second-owner-bipolar-disorder-suicide-attempts-details',
  healthSecondOwnerBipolarDisorderSuicideAttemptsDate = 'health-second-owner-bipolar-disorder-suicide-attempts-date',
  healthSecondOwnerBipolarDisorderHadAbsenceDetails = 'health-second-owner-bipolar-disorder-had-absence-details',
  healthSecondOwnerBipolarDisorderAbsenceStartDate = 'health-second-owner-bipolar-disorder-absence-start-date',
  healthSecondOwnerBipolarDisorderAbsenceFrequencyNumber = 'health-second-owner-bipolar-disorder-absence-frequency-number',
  healthSecondOwnerBipolarDisorderAbsenceFrequency = 'health-second-owner-bipolar-disorder-absence-frequency',

  healthSecondOwnerPsychosisIsSingleEpisode = 'health-second-owner-psychosis-is-single-episode',
  healthSecondOwnerPsychosisDiagnosticDate = 'health-second-owner-psychosis-diagnostic-date',
  healthSecondOwnerPsychosisMedicationWasPrescribed = 'health-second-owner-psychosis-medication-was-prescribed',
  healthSecondOwnerPsychosisHasConsultedDoctors = 'health-second-owner-psychosis-consulted-doctors',
  healthSecondOwnerPsychosisHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-psychosis-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerPsychosisWentToEmergencyDepartment = 'health-second-owner-psychosis-went-to-emergency-department',
  healthSecondOwnerPsychosisWasHospitalized = 'health-second-owner-psychosis-was-hospitalized',
  healthSecondOwnerPsychosisHadSuicidalThoughtsAndAttempt = 'health-second-owner-psychosis-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerPsychosisSuicidalThoughtsAndAttemptChoices = 'health-second-owner-psychosis-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerPsychosisSuicidalThoughtsFrequencyNumber = 'health-second-owner-psychosis-suicidal-thoughts-frequency-number',
  healthSecondOwnerPsychosisSuicidalThoughtsFrequency = 'health-second-owner-psychosis-suicidal-thoughts-frequency',
  healthSecondOwnerPsychosisSuicideAttemptsNumber = 'health-second-owner-psychosis-suicide-attempts-number',
  healthSecondOwnerPsychosisHadAbsence = 'health-second-owner-psychosis-had-absence',
  healthSecondOwnerPsychosisAbsenceHasRecovered = 'health-second-owner-psychosis-absence-has-recovered',
  healthSecondOwnerPsychosisAbsenceRecoveryDate = 'health-second-owner-psychosis-absence-recovery-date',
  healthSecondOwnerPsychosisAbsenceNotRecoveredDetails = 'health-second-owner-psychosis-absence-not-recovered-details',
  healthSecondOwnerPsychosisMedicationDetails = 'health-second-owner-psychosis-medication-details',
  healthSecondOwnerPsychosisMedicationName = 'health-second-owner-psychosis-medication-name',
  healthSecondOwnerPsychosisMedicationDosage = 'health-second-owner-psychosis-medication-dosage',
  healthSecondOwnerPsychosisMedicationStartDate = 'health-second-owner-psychosis-medication-start-date',
  healthSecondOwnerPsychosisMedicationEndDate = 'health-second-owner-psychosis-medication-end-date',
  healthSecondOwnerPsychosisMedicationStillUses = 'health-second-owner-psychosis-medication-still-uses',
  healthSecondOwnerPsychosisMedicationHasChanged = 'health-second-owner-psychosis-medication-has-changed',
  healthSecondOwnerPsychosisMedicationHasChangedDetails = 'health-second-owner-psychosis-medication-has-changed-details',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsDetails = 'health-second-owner-psychosis-has-or-will-consult-doctors-details',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsProfession = 'health-second-owner-psychosis-has-or-will-consult-doctors-profession',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-psychosis-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsName = 'health-second-owner-psychosis-has-or-will-consult-doctors-name',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsAddress = 'health-second-owner-psychosis-has-or-will-consult-doctors-address',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsFirstDate = 'health-second-owner-psychosis-has-or-will-consult-doctors-first-date',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsLastDate = 'health-second-owner-psychosis-has-or-will-consult-doctors-last-date',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsResult = 'health-second-owner-psychosis-has-or-will-consult-doctors-result',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-psychosis-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsFrequency = 'health-second-owner-psychosis-has-or-will-consult-doctors-frequency',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-psychosis-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerPsychosisHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-psychosis-has-or-will-consult-doctors-next-consultation-date',
  healthSecondOwnerPsychosisWentToEmergencyDepartmentDetails = 'health-second-owner-psychosis-went-to-emergency-department-details',
  healthSecondOwnerPsychosisWentToEmergencyDepartmentDate = 'health-second-owner-psychosis-went-to-emergency-department-date',
  healthSecondOwnerPsychosisWentToEmergencyDepartmentLocation = 'health-second-owner-psychosis-went-to-emergency-department-location',
  healthSecondOwnerPsychosisWasHospitalizedDetails = 'health-second-owner-psychosis-was-hospitalized-details',
  healthSecondOwnerPsychosisWasHospitalizedDate = 'health-second-owner-psychosis-was-hospitalized-date',
  healthSecondOwnerPsychosisWasHospitalizedFrequencyNumber = 'health-second-owner-psychosis-was-hospitalized-frequency-number',
  healthSecondOwnerPsychosisWasHospitalizedFrequency = 'health-second-owner-psychosis-was-hospitalized-frequency',
  healthSecondOwnerPsychosisWasHospitalizedLocation = 'health-second-owner-psychosis-was-hospitalized-location',
  healthSecondOwnerPsychosisSuicidalThoughtsDetails = 'health-second-owner-psychosis-suicidal-thoughts-details',
  healthSecondOwnerPsychosisSuicidalThoughtsDate = 'health-second-owner-psychosis-suicidal-thoughts-date',
  healthSecondOwnerPsychosisSuicideAttemptsDetails = 'health-second-owner-psychosis-suicide-attempts-details',
  healthSecondOwnerPsychosisSuicideAttemptsDate = 'health-second-owner-psychosis-suicide-attempts-date',
  healthSecondOwnerPsychosisHadAbsenceDetails = 'health-second-owner-psychosis-had-absence-details',
  healthSecondOwnerPsychosisAbsenceStartDate = 'health-second-owner-psychosis-absence-start-date',
  healthSecondOwnerPsychosisAbsenceFrequencyNumber = 'health-second-owner-psychosis-absence-frequency-number',
  healthSecondOwnerPsychosisAbsenceFrequency = 'health-second-owner-psychosis-absence-frequency',

  healthSecondOwnerSuicideAttemptMedicationWasPrescribed = 'health-second-owner-suicide-attempt-medication-was-prescribed',
  healthSecondOwnerSuicideAttemptHasConsultedDoctors = 'health-second-owner-suicide-attempt-consulted-doctors',
  healthSecondOwnerSuicideAttemptHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-suicide-attempt-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerSuicideAttemptWasHospitalized = 'health-second-owner-suicide-attempt-was-hospitalized',
  healthSecondOwnerSuicideAttemptDate = 'health-second-owner-suicide-attempt-date',
  healthSecondOwnerSuicideAttemptReason = 'health-second-owner-suicide-attempt-reason',
  healthSecondOwnerSuicideAttemptHasMoreThanOneAttempt = 'health-second-owner-suicide-attempt-has-more-than-one-attempt',
  healthSecondOwnerSuicideAttemptHowManyAttempts = 'health-second-owner-suicide-attempt-how-many-attempts',
  healthSecondOwnerSuicideAttemptMedicationDetails = 'health-second-owner-suicide-attempt-medication-details',
  healthSecondOwnerSuicideAttemptMedicationName = 'health-second-owner-suicide-attempt-medication-name',
  healthSecondOwnerSuicideAttemptMedicationDosage = 'health-second-owner-suicide-attempt-medication-dosage',
  healthSecondOwnerSuicideAttemptMedicationStartDate = 'health-second-owner-suicide-attempt-medication-start-date',
  healthSecondOwnerSuicideAttemptMedicationEndDate = 'health-second-owner-suicide-attempt-medication-end-date',
  healthSecondOwnerSuicideAttemptMedicationStillUses = 'health-second-owner-suicide-attempt-medication-still-uses',
  healthSecondOwnerSuicideAttemptMedicationHasChanged = 'health-second-owner-suicide-attempt-medication-has-changed',
  healthSecondOwnerSuicideAttemptMedicationHasChangedDetails = 'health-second-owner-suicide-attempt-medication-has-changed-details',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsDetails = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-details',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsProfession = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-profession',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsName = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-name',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsAddress = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-address',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFirstDate = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-first-date',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsLastDate = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-last-date',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsResult = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-result',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFrequency = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-frequency',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-suicide-attempt-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-suicide-attempt-next-has-or-will-consult-doctors-next-consultation-date',
  healthSecondOwnerSuicideAttemptWasHospitalizedDetails = 'health-second-owner-suicide-attempt-was-hospitalized-details',
  healthSecondOwnerSuicideAttemptWasHospitalizedDate = 'health-second-owner-suicide-attempt-was-hospitalized-date',
  healthSecondOwnerSuicideAttemptWasHospitalizedFrequencyNumber = 'health-second-owner-suicide-attempt-was-hospitalized-frequency-number',
  healthSecondOwnerSuicideAttemptWasHospitalizedFrequency = 'health-second-owner-suicide-attempt-was-hospitalized-frequency',
  healthSecondOwnerSuicideAttemptWasHospitalizedLocation = 'health-second-owner-suicide-attempt-was-hospitalized-location',
  healthSecondOwnerSuicideAttemptMultipleAttemptsDetails = 'health-second-owner-suicide-attempt-multiple-attempts-details',
  healthSecondOwnerSuicideAttemptMultipleAttemptsDate = 'health-second-owner-suicide-attempt-multiple-attempts-date',
  healthSecondOwnerSuicideAttemptMultipleAttemptsReason = 'health-second-owner-suicide-attempt-multiple-attempts-reason',

  healthSecondOwnerEatingDisorderDiagnosticDate = 'health-second-owner-eating-disorder-diagnostic-date',
  healthSecondOwnerEatingDisorderMedicationWasPrescribed = 'health-second-owner-eating-disorder-medication-was-prescribed',
  healthSecondOwnerEatingDisorderHasConsultedDoctors = 'health-second-owner-eating-disorder-consulted-doctors',
  healthSecondOwnerEatingDisorderHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-eating-disorder-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerEatingDisorderWasHospitalized = 'health-second-owner-eating-disorder-was-hospitalized',
  healthSecondOwnerEatingDisorderIsControlled = 'health-second-owner-eating-disorder-is-controlled',
  healthSecondOwnerEatingDisorderNotControlledDetails = 'health-second-owner-eating-disorder-not-controlled-details',
  healthSecondOwnerEatingDisorderHasStableWeightDate = 'health-second-owner-eating-disorder-has-stable-weight-date',
  healthSecondOwnerEatingDisorderMedicationDetails = 'health-second-owner-eating-disorder-medication-details',
  healthSecondOwnerEatingDisorderMedicationName = 'health-second-owner-eating-disorder-medication-name',
  healthSecondOwnerEatingDisorderMedicationDosage = 'health-second-owner-eating-disorder-medication-dosage',
  healthSecondOwnerEatingDisorderMedicationStartDate = 'health-second-owner-eating-disorder-medication-start-date',
  healthSecondOwnerEatingDisorderMedicationEndDate = 'health-second-owner-eating-disorder-medication-end-date',
  healthSecondOwnerEatingDisorderMedicationStillUses = 'health-second-owner-eating-disorder-medication-still-uses',
  healthSecondOwnerEatingDisorderMedicationHasChanged = 'health-second-owner-eating-disorder-medication-has-changed',
  healthSecondOwnerEatingDisorderMedicationHasChangedDetails = 'health-second-owner-eating-disorder-medication-has-changed-details',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsDetails = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-details',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsProfession = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-profession',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsName = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-name',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsAddress = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-address',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFirstDate = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-first-date',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsLastDate = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-last-date',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsResult = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-result',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFrequency = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-frequency',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-eating-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthSecondOwnerEatingDisorderWasHospitalizedDetails = 'health-second-owner-eating-disorder-was-hospitalized-details',
  healthSecondOwnerEatingDisorderWasHospitalizedDate = 'health-second-owner-eating-disorder-was-hospitalized-date',
  healthSecondOwnerEatingDisorderWasHospitalizedFrequencyNumber = 'health-second-owner-eating-disorder-was-hospitalized-frequency-number',
  healthSecondOwnerEatingDisorderWasHospitalizedFrequency = 'health-second-owner-eating-disorder-was-hospitalized-frequency',
  healthSecondOwnerEatingDisorderWasHospitalizedLocation = 'health-second-owner-eating-disorder-was-hospitalized-location',

  healthSecondOwnerAddAdhdMedicationWasPrescribed = 'health-second-owner-add-adhd-medication-was-prescribed',
  healthSecondOwnerAddAdhdHasConsultedDoctors = 'health-second-owner-add-adhd-consulted-doctors',
  healthSecondOwnerAddAdhdHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-add-adhd-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerAddAdhdIsControlled = 'health-second-owner-add-adhd-is-controlled',
  healthSecondOwnerAddAdhdHasStableWeightDate = 'health-second-owner-add-adhd-has-stable-weight-date',
  healthSecondOwnerAddAdhdNotControlledDetails = 'health-second-owner-add-adhd-not-controlled-details',
  healthSecondOwnerAddAdhdMedicationDetails = 'health-second-owner-add-adhd-medication-details',
  healthSecondOwnerAddAdhdMedicationName = 'health-second-owner-add-adhd-medication-name',
  healthSecondOwnerAddAdhdMedicationDosage = 'health-second-owner-add-adhd-medication-dosage',
  healthSecondOwnerAddAdhdMedicationStartDate = 'health-second-owner-add-adhd-medication-start-date',
  healthSecondOwnerAddAdhdMedicationEndDate = 'health-second-owner-add-adhd-medication-end-date',
  healthSecondOwnerAddAdhdMedicationStillUses = 'health-second-owner-add-adhd-medication-still-uses',
  healthSecondOwnerAddAdhdMedicationHasChanged = 'health-second-owner-add-adhd-medication-has-changed',
  healthSecondOwnerAddAdhdMedicationHasChangedDetails = 'health-second-owner-add-adhd-medication-has-changed-details',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsDetails = 'health-second-owner-add-adhd-has-or-will-consult-doctors-details',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsProfession = 'health-second-owner-add-adhd-has-or-will-consult-doctors-profession',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-add-adhd-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsName = 'health-second-owner-add-adhd-has-or-will-consult-doctors-name',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsAddress = 'health-second-owner-add-adhd-has-or-will-consult-doctors-address',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFirstDate = 'health-second-owner-add-adhd-has-or-will-consult-doctors-first-date',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsLastDate = 'health-second-owner-add-adhd-has-or-will-consult-doctors-last-date',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsResult = 'health-second-owner-add-adhd-has-or-will-consult-doctors-result',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-add-adhd-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFrequency = 'health-second-owner-add-adhd-has-or-will-consult-doctors-frequency',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-add-adhd-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerAddAdhdHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-add-adhd-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerAutismMedicationWasPrescribed = 'health-second-owner-autism-medication-was-prescribed',
  healthSecondOwnerAutismHasConsultedDoctors = 'health-second-owner-autism-consulted-doctors',
  healthSecondOwnerAutismHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-autism-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerAutismPerformsDailyActivitiesWithoutHelp = 'health-second-owner-autism-performs-daily-activities-without-help',
  healthSecondOwnerAutismPerformsDailyActivitiesWithoutHelpDetails = 'health-second-owner-autism-performs-daily-activities-without-help-details',
  healthSecondOwnerAutismNeedsAccommodation = 'health-second-owner-autism-needs-accommodation',
  healthSecondOwnerAutismAccommodationDetails = 'health-second-owner-autism-accommodation-details',
  healthSecondOwnerAutismMedicationDetails = 'health-second-owner-autism-medication-details',
  healthSecondOwnerAutismMedicationName = 'health-second-owner-autism-medication-name',
  healthSecondOwnerAutismMedicationDosage = 'health-second-owner-autism-medication-dosage',
  healthSecondOwnerAutismMedicationStartDate = 'health-second-owner-autism-medication-start-date',
  healthSecondOwnerAutismMedicationEndDate = 'health-second-owner-autism-medication-end-date',
  healthSecondOwnerAutismMedicationStillUses = 'health-second-owner-autism-medication-still-uses',
  healthSecondOwnerAutismMedicationHasChanged = 'health-second-owner-autism-medication-has-changed',
  healthSecondOwnerAutismMedicationHasChangedDetails = 'health-second-owner-autism-medication-has-changed-details',
  healthSecondOwnerAutismHasOrWillConsultDoctorsDetails = 'health-second-owner-autism-has-or-will-consult-doctors-details',
  healthSecondOwnerAutismHasOrWillConsultDoctorsProfession = 'health-second-owner-autism-has-or-will-consult-doctors-profession',
  healthSecondOwnerAutismHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-autism-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerAutismHasOrWillConsultDoctorsName = 'health-second-owner-autism-has-or-will-consult-doctors-name',
  healthSecondOwnerAutismHasOrWillConsultDoctorsAddress = 'health-second-owner-autism-has-or-will-consult-doctors-address',
  healthSecondOwnerAutismHasOrWillConsultDoctorsFirstDate = 'health-second-owner-autism-has-or-will-consult-doctors-first-date',
  healthSecondOwnerAutismHasOrWillConsultDoctorsLastDate = 'health-second-owner-autism-has-or-will-consult-doctors-last-date',
  healthSecondOwnerAutismHasOrWillConsultDoctorsResult = 'health-second-owner-autism-has-or-will-consult-doctors-result',
  healthSecondOwnerAutismHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-autism-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerAutismHasOrWillConsultDoctorsFrequency = 'health-second-owner-autism-has-or-will-consult-doctors-frequency',
  healthSecondOwnerAutismHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-autism-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerAutismHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-autism-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerIntellectualImpairmentMedicationWasPrescribed = 'health-second-owner-intellectual-impairment-medication-was-prescribed',
  healthSecondOwnerIntellectualImpairmentHasConsultedDoctors = 'health-second-owner-intellectual-impairment-consulted-doctors',
  healthSecondOwnerIntellectualImpairmentHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-intellectual-impairment-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelp = 'health-second-owner-intellectual-impairment-performs-daily-activities-without-help',
  healthSecondOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelpDetails = 'health-second-owner-intellectual-impairment-performs-daily-activities-without-help-details',
  healthSecondOwnerIntellectualImpairmentNeedsAccommodation = 'health-second-owner-intellectual-impairment-neds-accommodation',
  healthSecondOwnerIntellectualImpairmentAccommodationDetails = 'health-second-owner-intellectual-impairment-accommodation-details',
  healthSecondOwnerIntellectualImpairmentMedicationDetails = 'health-second-owner-intellectual-impairment-medication-details',
  healthSecondOwnerIntellectualImpairmentMedicationName = 'health-second-owner-intellectual-impairment-medication-name',
  healthSecondOwnerIntellectualImpairmentMedicationDosage = 'health-second-owner-intellectual-impairment-medication-dosage',
  healthSecondOwnerIntellectualImpairmentMedicationStartDate = 'health-second-owner-intellectual-impairment-medication-start-date',
  healthSecondOwnerIntellectualImpairmentMedicationEndDate = 'health-second-owner-intellectual-impairment-medication-end-date',
  healthSecondOwnerIntellectualImpairmentMedicationStillUses = 'health-second-owner-intellectual-impairment-medication-still-uses',
  healthSecondOwnerIntellectualImpairmentMedicationHasChanged = 'health-second-owner-intellectual-impairment-medication-has-changed',
  healthSecondOwnerIntellectualImpairmentMedicationHasChangedDetails = 'health-second-owner-intellectual-impairment-medication-has-changed-details',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsDetails = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-details',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfession = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-profession',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsName = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-name',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsAddress = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-address',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFirstDate = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-first-date',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsLastDate = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-last-date',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsResult = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-result',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequency = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-frequency',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-intellectual-impairment-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerDownSyndromeMedicationWasPrescribed = 'health-second-owner-down-syndrome-medication-was-prescribed',
  healthSecondOwnerDownSyndromeHasConsultedDoctors = 'health-second-owner-down-syndrome-consulted-doctors',
  healthSecondOwnerDownSyndromeHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-down-syndrome-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerDownSyndromePerformsDailyActivitiesWithoutHelp = 'health-second-owner-down-syndrome-performs-daily-activities-without-help',
  healthSecondOwnerDownSyndromePerformsDailyActivitiesWithoutHelpDetails = 'health-second-owner-down-syndrome-performs-daily-activities-without-help-details',
  healthSecondOwnerDownSyndromeNeedsAccommodation = 'health-second-owner-down-syndrome-needs-accommodation',
  healthSecondOwnerDownSyndromeAccommodationDetails = 'health-second-owner-down-syndrome-accommodation-details',
  healthSecondOwnerDownSyndromeMedicationDetails = 'health-second-owner-down-syndrome-medication-details',
  healthSecondOwnerDownSyndromeMedicationName = 'health-second-owner-down-syndrome-medication-name',
  healthSecondOwnerDownSyndromeMedicationDosage = 'health-second-owner-down-syndrome-medication-dosage',
  healthSecondOwnerDownSyndromeMedicationStartDate = 'health-second-owner-down-syndrome-medication-start-date',
  healthSecondOwnerDownSyndromeMedicationEndDate = 'health-second-owner-down-syndrome-medication-end-date',
  healthSecondOwnerDownSyndromeMedicationStillUses = 'health-second-owner-down-syndrome-medication-still-uses',
  healthSecondOwnerDownSyndromeMedicationHasChanged = 'health-second-owner-down-syndrome-medication-has-changed',
  healthSecondOwnerDownSyndromeMedicationHasChangedDetails = 'health-second-owner-down-syndrome-medication-has-changed-details',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsDetails = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-details',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsProfession = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-profession',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsName = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-name',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsAddress = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-address',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFirstDate = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-first-date',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsLastDate = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-last-date',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsResult = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-result',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFrequency = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-frequency',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-down-syndrome-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerMentalHealthOtherDisorderType = 'health-second-owner-mental-health-other-disorder-type',
  healthSecondOwnerMentalHealthOtherDate = 'health-second-owner-mental-health-other-date',
  healthSecondOwnerMentalHealthOtherHasDiagnostic = 'health-second-owner-mental-health-other-has-diagnostic',
  healthSecondOwnerMentalHealthOtherDiagnosticResult = 'health-second-owner-mental-health-other-diagnostic-result',
  healthSecondOwnerMentalHealthOtherDiagnosticDate = 'health-second-owner-mental-health-other-diagnostic-date',
  healthSecondOwnerMentalHealthOtherHasTakenTestsOrExams = 'health-second-owner-mental-health-other-has-taken-tests-or-exams',
  healthSecondOwnerMentalHealthOtherMedicationWasPrescribed = 'health-second-owner-mental-health-other-medication-was-prescribed',
  healthSecondOwnerMentalHealthOtherHasConsultedDoctors = 'health-second-owner-mental-health-other-consulted-doctors',
  healthSecondOwnerMentalHealthOtherHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-mental-health-other-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerMentalHealthOtherWentToEmergencyDepartment = 'health-second-owner-mental-health-other-went-to-emergency-department',
  healthSecondOwnerMentalHealthOtherWasHospitalized = 'health-second-owner-mental-health-other-was-hospitalized',
  healthSecondOwnerMentalHealthOtherHadAbsence = 'health-second-owner-mental-health-other-had-absence',
  healthSecondOwnerMentalHealthOtherAbsenceHasRecovered = 'health-second-owner-mental-health-other-absence-has-recovered',
  healthSecondOwnerMentalHealthOtherAbsenceRecoveryDate = 'health-second-owner-mental-health-other-absence-recovery-date',
  healthSecondOwnerMentalHealthOtherAbsenceNotRecoveredDetails = 'health-second-owner-mental-health-other-absence-not-recovered-details',
  healthSecondOwnerMentalHealthOtherHasTakenTestsOrExamsDetails = 'health-second-owner-mental-health-other-has-taken-tests-or-exams-details',
  healthSecondOwnerMentalHealthOtherTestsOrExamsDetails = 'health-second-owner-mental-health-other-tests-or-exams-details',
  healthSecondOwnerMentalHealthOtherOtherTestsOrExamsDetailsDate = 'health-second-owner-mental-health-other-other-tests-or-exams-details-date',
  healthSecondOwnerMentalHealthOtherOtherTestsOrExamsDetailsResult = 'health-second-owner-mental-health-other-other-tests-or-exams-details-result',
  healthSecondOwnerMentalHealthOtherMedicationDetails = 'health-second-owner-mental-health-other-medication-details',
  healthSecondOwnerMentalHealthOtherMedicationName = 'health-second-owner-mental-health-other-medication-name',
  healthSecondOwnerMentalHealthOtherMedicationDosage = 'health-second-owner-mental-health-other-medication-dosage',
  healthSecondOwnerMentalHealthOtherMedicationStartDate = 'health-second-owner-mental-health-other-medication-start-date',
  healthSecondOwnerMentalHealthOtherMedicationEndDate = 'health-second-owner-mental-health-other-medication-end-date',
  healthSecondOwnerMentalHealthOtherMedicationStillUses = 'health-second-owner-mental-health-other-medication-still-uses',
  healthSecondOwnerMentalHealthOtherMedicationHasChanged = 'health-second-owner-mental-health-other-medication-has-changed',
  healthSecondOwnerMentalHealthOtherMedicationHasChangedDetails = 'health-second-owner-mental-health-other-medication-has-changed-details',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsDetails = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-details',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsProfession = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-profession',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsName = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-name',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsAddress = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-address',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFirstDate = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-first-date',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsLastDate = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-last-date',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsResult = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-result',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequency = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-frequency',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-mental-health-other-has-or-will-consult-doctors-next-consultation-date',
  healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentDetails = 'health-second-owner-mental-health-other-went-to-emergency-department-details',
  healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentDate = 'health-second-owner-mental-health-other-went-to-emergency-department-date',
  healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentLocation = 'health-second-owner-mental-health-other-went-to-emergency-department-location',
  healthSecondOwnerMentalHealthOtherWasHospitalizedDetails = 'health-second-owner-mental-health-other-was-hospitalized-details',
  healthSecondOwnerMentalHealthOtherWasHospitalizedDate = 'health-second-owner-mental-health-other-was-hospitalized-date',
  healthSecondOwnerMentalHealthOtherWasHospitalizedFrequencyNumber = 'health-second-owner-mental-health-other-was-hospitalized-frequency-number',
  healthSecondOwnerMentalHealthOtherWasHospitalizedFrequency = 'health-second-owner-mental-health-other-was-hospitalized-frequency',
  healthSecondOwnerMentalHealthOtherWasHospitalizedLocation = 'health-second-owner-mental-health-other-was-hospitalized-location',
  healthSecondOwnerMentalHealthOtherhadAbsenceDetails = 'health-second-owner-mental-health-other-had-absence-details',
  healthSecondOwnerMentalHealthOtherAbsenceStartDate = 'health-second-owner-mental-health-other-absence-start-date',
  healthSecondOwnerMentalHealthOtherAbsenceFrequencyNumber = 'health-second-owner-mental-health-other-absence-frequency-number',
  healthSecondOwnerMentalHealthOtherAbsenceFrequency = 'health-second-owner-mental-health-other-absence-frequency',

  healthSecondOwnerMusculoskeletalOtherIsSingleEpisode = 'health-second-owner-musculoskeletal-other-is-single-episode',
  healthSecondOwnerMusculoskeletalOtherPreviousEpisodesCount = 'health-second-owner-musculoskeletal-other-previous-episodes-count',
  healthSecondOwnerMusculoskeletalOtherDateSymptoms = 'health-second-owner-musculoskeletal-other-date-symptoms',
  healthSecondOwnerMusculoskeletalOtherPainLocation = 'health-second-owner-musculoskeletal-other-pain-location',
  healthSecondOwnerMusculoskeletalOtherPainLocationLeftRight = 'health-second-owner-musculoskeletal-other-pain-location-left-right',
  healthSecondOwnerMusculoskeletalOtherPainLocationOther = 'health-second-owner-musculoskeletal-other-pain-location-other',
  healthSecondOwnerMusculoskeletalOtherPainCause = 'health-second-owner-musculoskeletal-other-pain-cause',
  healthSecondOwnerMusculoskeletalOtherPainCauseDetails = 'health-second-owner-musculoskeletal-other-pain-cause-details',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctors = 'health-second-owner-musculoskeletal-other-consulted-doctors',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExams = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams',
  healthSecondOwnerMusculoskeletalOtherMedicationWasPrescribed = 'health-second-owner-musculoskeletal-other-medication-was-prescribed',
  healthSecondOwnerMusculoskeletalOtherHadOperation = 'health-second-owner-musculoskeletal-other-had-operation',
  healthSecondOwnerMusculoskeletalOtherOperationPerformedOrPlanned = 'health-second-owner-musculoskeletal-other-operation-performed-or-planned',
  healthSecondOwnerMusculoskeletalOtherTypeOfOperationPerformed = 'health-second-owner-musculoskeletal-other-type-of-operation-performed',
  healthSecondOwnerMusculoskeletalOtherOperationPerformedDate = 'health-second-owner-musculoskeletal-other-operation-performed-date',
  healthSecondOwnerMusculoskeletalOtherTypeOfOperationPlanned = 'health-second-owner-musculoskeletal-other-type-of-operation-planned',
  healthSecondOwnerMusculoskeletalOtherOperationPlannedDate = 'health-second-owner-musculoskeletal-other-operation-planned-date',
  healthSecondOwnerMusculoskeletalOtherOperationUnknownDate = 'health-second-owner-musculoskeletal-other-operation-unknown-date',
  healthSecondOwnerMusculoskeletalOtherHadAbsence = 'health-second-owner-musculoskeletal-other-had-absence',
  healthSecondOwnerMusculoskeletalOtherAbsenceStartDate = 'health-second-owner-musculoskeletal-other-absence-start-date',
  healthSecondOwnerMusculoskeletalOtherAbsenceFrequencyNumber = 'health-second-owner-musculoskeletal-other-absence-frequency-number',
  healthSecondOwnerMusculoskeletalOtherAbsenceFrequency = 'health-second-owner-musculoskeletal-other-absence-frequency',
  healthSecondOwnerMusculoskeletalOtherAbsenceHasRecovered = 'health-second-owner-musculoskeletal-other-absence-has-recovered',
  healthSecondOwnerMusculoskeletalOtherAbsenceRecoveryDate = 'health-second-owner-musculoskeletal-other-absence-recovery-date',
  healthSecondOwnerMusculoskeletalOtherAbsenceNotRecoveredDetails = 'health-second-owner-musculoskeletal-other-absence-not-recovered-details',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsDetails = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-details',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsProfession = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-profession',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsProfessionDetails = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-profession-details',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsName = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-name',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsAddress = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-address',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFirstDate = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-first-date',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsLastDate = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-last-date',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsResult = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-result',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-frequency-number',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFrequency = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-frequency',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorFurtherConsultation = 'health-second-owner-musculoskeletal-other-has-consulted-doctor-further-consultation',
  healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate = 'health-second-owner-musculoskeletal-other-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetails = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams-details',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsType = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams-type',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDate = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams-date',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsResult = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams-result',
  healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-musculoskeletal-other-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerMusculoskeletalOtherMedicationDetails = 'health-second-owner-musculoskeletal-other-medication-details',
  healthSecondOwnerMusculoskeletalOtherMedicationName = 'health-second-owner-musculoskeletal-other-medication-name',
  healthSecondOwnerMusculoskeletalOtherMedicationDosage = 'health-second-owner-musculoskeletal-other-medication-dosage',
  healthSecondOwnerMusculoskeletalOtherMedicationStartDate = 'health-second-owner-musculoskeletal-other-medication-start-date',
  healthSecondOwnerMusculoskeletalOtherMedicationEndDate = 'health-second-owner-musculoskeletal-other-medication-end-date',

  healthSecondOwnerEpilepsyHasCondition = 'health-second-owner-epilepsy-has-condition',
  healthSecondOwnerEpilepsyDetails = 'health-second-owner-epilepsy-details',
  healthInsuredBlurredVisionOrOpticNeuritisDetails = 'health-insured-blurred-vision-or-optic-neuritis-details',
  healthSecondOwnerNeurologicalDisordersHasCondition = 'health-second-owner-neurological-disorders-has-condition',
  healthSecondOwnerNeurologicalConditions = 'health-second-owner-neurological-conditions',
  healthSecondOwnerCerebralPalsyDetails = 'health-second-owner-cerebral-palsy-details',
  healthSecondOwnerParalysisDetails = 'health-second-owner-paralysis-details',
  healthSecondOwnerConcussionDetails = 'health-second-owner-concussion-details',
  healthSecondOwnerMigrainesDetails = 'health-second-owner-migraines-details',
  healthSecondOwnerBlurredVisionOrOpticNeuritisDetails = 'health-second-owner-blurred-vision-or-optic-neuritis-details',
  healthSecondOwnerMultipleSclerosisDetails = 'health-second-owner-multiple-sclerosis-details',
  healthSecondOwnerAlsDetails = 'health-second-owner-als-details',
  healthSecondOwnerParkinsonsDetails = 'health-second-owner-parkinsons-details',
  healthSecondOwnerAlzheimerDetails = 'health-second-owner-alzheimer-details',
  healthSecondOwnerLossOfConsciousnessDetails = 'health-second-owner-loss-of-consciousness-details',
  healthSecondOwnerNumbnessDetails = 'health-second-owner-numbness-details',
  healthSecondOwnerMemoryLossDetails = 'health-second-owner-memory-loss-details',
  healthSecondOwnerDegenerativeDiseaseDetails = 'health-second-owner-degenerative-disease-details',
  healthSecondOwnerAnyOtherCognitiveImpairmentDetails = 'health-second-owner-any-other-cognitive-impairment-details',
  healthSecondOwnerDepressionIsSingleEpisode = 'health-second-owner-depression-is-single-episode',
  healthSecondOwnerDepressionDiagnosticDate = 'health-second-owner-depression-diagnostic-date',
  healthSecondOwnerDepressionMedicationWasPrescribed = 'health-second-owner-depression-medication-was-prescribed',
  healthSecondOwnerDepressionHasConsultedDoctors = 'health-second-owner-depression-consulted-doctors',
  healthSecondOwnerDepressionHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-depression-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerDepressionWentToEmergencyDepartment = 'health-second-owner-depression-went-to-emergency-department',
  healthSecondOwnerDepressionWasHospitalized = 'health-second-owner-depression-was-hospitalized',
  healthSecondOwnerDepressionHadSuicidalThoughtsAndAttempt = 'health-second-owner-depression-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerDepressionSuicidalThoughtsAndAttemptChoices = 'health-second-owner-depression-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerDepressionSuicidalThoughtsFrequencyNumber = 'health-second-owner-depression-suicidal-thoughts-frequency-number',
  healthSecondOwnerDepressionSuicidalThoughtsFrequency = 'health-second-owner-depression-suicidal-thoughts-frequency',
  healthSecondOwnerDepressionSuicideAttemptsNumber = 'health-second-owner-depression-suicide-attempts-number',
  healthSecondOwnerDepressionHadAbsence = 'health-second-owner-depression-had-absence',
  healthSecondOwnerDepressionAbsenceHasRecovered = 'health-second-owner-depression-absence-has-recovered',
  healthSecondOwnerDepressionAbsenceRecoveryDate = 'health-second-owner-depression-absence-recovery-date',
  healthSecondOwnerDepressionAbsenceNotRecoveredDetails = 'health-second-owner-depression-absence-not-recovered-details',
  healthSecondOwnerDepressionMedicationDetails = 'health-second-owner-depression-medication-details',
  healthSecondOwnerDepressionMedicationName = 'health-second-owner-depression-medication-name',
  healthSecondOwnerDepressionMedicationDosage = 'health-second-owner-depression-medication-dosage',
  healthSecondOwnerDepressionMedicationStartDate = 'health-second-owner-depression-medication-start-date',
  healthSecondOwnerDepressionMedicationEndDate = 'health-second-owner-depression-medication-end-date',
  healthSecondOwnerDepressionMedicationStillUses = 'health-second-owner-depression-medication-still-uses',
  healthSecondOwnerDepressionMedicationHasChanged = 'health-second-owner-depression-medication-has-changed',
  healthSecondOwnerDepressionMedicationHasChangedDetails = 'health-second-owner-depression-medication-has-changed-details',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsDetails = 'health-second-owner-depression-has-or-will-consult-doctors-details',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsProfession = 'health-second-owner-depression-has-or-will-consult-doctors-profession',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-depression-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsName = 'health-second-owner-depression-has-or-will-consult-doctors-name',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsAddress = 'health-second-owner-depression-has-or-will-consult-doctors-address',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsFirstDate = 'health-second-owner-depression-has-or-will-consult-doctors-first-date',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsLastDate = 'health-second-owner-depression-has-or-will-consult-doctors-last-date',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsResult = 'health-second-owner-depression-has-or-will-consult-doctors-result',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-depression-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsFrequency = 'health-second-owner-depression-has-or-will-consult-doctors-frequency',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-depression-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerDepressionHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-depression-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerDepressionWentToEmergencyDepartmentDetails = 'health-second-owner-depression-went-to-emergency-department-details',
  healthSecondOwnerDepressionWentToEmergencyDepartmentDate = 'health-second-owner-depression-went-to-emergency-department-date',
  healthSecondOwnerDepressionWentToEmergencyDepartmentLocation = 'health-second-owner-depression-went-to-emergency-department-location',

  healthSecondOwnerDepressionWasHospitalizedDetails = 'health-second-owner-depression-was-hospitalized-details',
  healthSecondOwnerDepressionWasHospitalizedDate = 'health-second-owner-depression-was-hospitalized-date',
  healthSecondOwnerDepressionWasHospitalizedFrequencyNumber = 'health-second-owner-depression-was-hospitalized-frequency-number',
  healthSecondOwnerDepressionWasHospitalizedFrequency = 'health-second-owner-depression-was-hospitalized-frequency',
  healthSecondOwnerDepressionWasHospitalizedLocation = 'health-second-owner-depression-was-hospitalized-location',

  healthSecondOwnerDepressionSuicidalThoughtsDetails = 'health-second-owner-depression-suicidal-thoughts-details',
  healthSecondOwnerDepressionSuicidalThoughtsDate = 'health-second-owner-depression-suicidal-thoughts-date',
  healthSecondOwnerDepressionSuicideAttemptsDetails = 'health-second-owner-depression-suicide-attempts-details',
  healthSecondOwnerDepressionSuicideAttemptsDate = 'health-second-owner-depression-suicide-attempts-date',
  healthSecondOwnerDepressionHadAbsenceDetails = 'health-second-owner-depression-had-absence-details',
  healthSecondOwnerDepressionAbsenceStartDate = 'health-second-owner-depression-absence-start-date',
  healthSecondOwnerDepressionAbsenceFrequencyNumber = 'health-second-owner-depression-absence-frequency-number',
  healthSecondOwnerDepressionAbsenceFrequency = 'health-second-owner-depression-absence-frequency',

  healthSecondOwnerAnxietyIsSingleEpisode = 'health-second-owner-anxiety-is-single-episode',
  healthSecondOwnerAnxietyDiagnosticDate = 'health-second-owner-anxiety-diagnostic-date',
  healthSecondOwnerAnxietyMedicationWasPrescribed = 'health-second-owner-anxiety-medication-was-prescribed',
  healthSecondOwnerAnxietyHasConsultedDoctors = 'health-second-owner-anxiety-consulted-doctors',
  healthSecondOwnerAnxietyHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-anxiety-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerAnxietyWentToEmergencyDepartment = 'health-second-owner-anxiety-went-to-emergency-department',
  healthSecondOwnerAnxietyWasHospitalized = 'health-second-owner-anxiety-was-hospitalized',
  healthSecondOwnerAnxietyHadSuicidalThoughtsAndAttempt = 'health-second-owner-anxiety-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerAnxietySuicidalThoughtsAndAttemptChoices = 'health-second-owner-anxiety-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerAnxietySuicidalThoughtsFrequencyNumber = 'health-second-owner-anxiety-suicidal-thoughts-frequency-number',
  healthSecondOwnerAnxietySuicidalThoughtsFrequency = 'health-second-owner-anxiety-suicidal-thoughts-frequency',
  healthSecondOwnerAnxietySuicideAttemptsNumber = 'health-second-owner-anxiety-suicide-attempts-number',
  healthSecondOwnerAnxietyHadAbsence = 'health-second-owner-anxiety-had-absence',
  healthSecondOwnerAnxietyAbsenceHasRecovered = 'health-second-owner-anxiety-absence-has-recovered',
  healthSecondOwnerAnxietyAbsenceRecoveryDate = 'health-second-owner-anxiety-absence-recovery-date',
  healthSecondOwnerAnxietyAbsenceNotRecoveredDetails = 'health-second-owner-anxiety-absence-not-recovered-details',
  healthSecondOwnerAnxietyMedicationDetails = 'health-second-owner-anxiety-medication-details',
  healthSecondOwnerAnxietyMedicationName = 'health-second-owner-anxiety-medication-name',
  healthSecondOwnerAnxietyMedicationDosage = 'health-second-owner-anxiety-medication-dosage',
  healthSecondOwnerAnxietyMedicationStartDate = 'health-second-owner-anxiety-medication-start-date',
  healthSecondOwnerAnxietyMedicationEndDate = 'health-second-owner-anxiety-medication-end-date',
  healthSecondOwnerAnxietyMedicationStillUses = 'health-second-owner-anxiety-medication-still-uses',
  healthSecondOwnerAnxietyMedicationHasChanged = 'health-second-owner-anxiety-medication-has-changed',
  healthSecondOwnerAnxietyMedicationHasChangedDetails = 'health-second-owner-anxiety-medication-has-changed-details',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsDetails = 'health-second-owner-anxiety-has-or-will-consult-doctors-details',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsProfession = 'health-second-owner-anxiety-has-or-will-consult-doctors-profession',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-anxiety-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsName = 'health-second-owner-anxiety-has-or-will-consult-doctors-name',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsAddress = 'health-second-owner-anxiety-has-or-will-consult-doctors-address',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsFirstDate = 'health-second-owner-anxiety-has-or-will-consult-doctors-first-date',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsLastDate = 'health-second-owner-anxiety-has-or-will-consult-doctors-last-date',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsResult = 'health-second-owner-anxiety-has-or-will-consult-doctors-result',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-anxiety-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsFrequency = 'health-second-owner-anxiety-has-or-will-consult-doctors-frequency',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-anxiety-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerAnxietyHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-anxiety-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerAnxietyWentToEmergencyDepartmentDetails = 'health-second-owner-anxiety-went-to-emergency-department-details',
  healthSecondOwnerAnxietyWentToEmergencyDepartmentDate = 'health-second-owner-anxiety-went-to-emergency-department-date',
  healthSecondOwnerAnxietyWentToEmergencyDepartmentLocation = 'health-second-owner-anxiety-went-to-emergency-department-location',

  healthSecondOwnerAnxietyWasHospitalizedDetails = 'health-second-owner-anxiety-was-hospitalized-details',
  healthSecondOwnerAnxietyWasHospitalizedDate = 'health-second-owner-anxiety-was-hospitalized-date',
  healthSecondOwnerAnxietyWasHospitalizedFrequencyNumber = 'health-second-owner-anxiety-was-hospitalized-frequency-number',
  healthSecondOwnerAnxietyWasHospitalizedFrequency = 'health-second-owner-anxiety-was-hospitalized-frequency',
  healthSecondOwnerAnxietyWasHospitalizedLocation = 'health-second-owner-anxiety-was-hospitalized-location',

  healthSecondOwnerAnxietySuicidalThoughtsDetails = 'health-second-owner-anxiety-suicidal-thoughts-details',
  healthSecondOwnerAnxietySuicidalThoughtsDate = 'health-second-owner-anxiety-suicidal-thoughts-date',
  healthSecondOwnerAnxietySuicideAttemptsDetails = 'health-second-owner-anxiety-suicide-attempts-details',
  healthSecondOwnerAnxietySuicideAttemptsDate = 'health-second-owner-anxiety-suicide-attempts-date',
  healthSecondOwnerAnxietyHadAbsenceDetails = 'health-second-owner-anxiety-had-absence-details',
  healthSecondOwnerAnxietyAbsenceStartDate = 'health-second-owner-anxiety-absence-start-date',
  healthSecondOwnerAnxietyAbsenceFrequencyNumber = 'health-second-owner-anxiety-absence-frequency-number',
  healthSecondOwnerAnxietyAbsenceFrequency = 'health-second-owner-anxiety-absence-frequency',

  healthSecondOwnerPanicAttacksIsSingleEpisode = 'health-second-owner-panic-attacks-is-single-episode',
  healthSecondOwnerPanicAttacksDiagnosticDate = 'health-second-owner-panic-attacks-diagnostic-date',
  healthSecondOwnerPanicAttacksMedicationWasPrescribed = 'health-second-owner-panic-attacks-medication-was-prescribed',
  healthSecondOwnerPanicAttacksHasConsultedDoctors = 'health-second-owner-panic-attacks-consulted-doctors',
  healthSecondOwnerPanicAttacksHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-panic-attacks-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerPanicAttacksWentToEmergencyDepartment = 'health-second-owner-panic-attacks-went-to-emergency-department',
  healthSecondOwnerPanicAttacksWasHospitalized = 'health-second-owner-panic-attacks-was-hospitalized',
  healthSecondOwnerPanicAttacksHadSuicidalThoughtsAndAttempt = 'health-second-owner-panic-attacks-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerPanicAttacksSuicidalThoughtsAndAttemptChoices = 'health-second-owner-panic-attacks-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerPanicAttacksSuicidalThoughtsFrequencyNumber = 'health-second-owner-panic-attacks-suicidal-thoughts-frequency-number',
  healthSecondOwnerPanicAttacksSuicidalThoughtsFrequency = 'health-second-owner-panic-attacks-suicidal-thoughts-frequency',
  healthSecondOwnerPanicAttacksSuicideAttemptsNumber = 'health-second-owner-panic-attacks-suicide-attempts-number',
  healthSecondOwnerPanicAttacksHadAbsence = 'health-second-owner-panic-attacks-had-absence',
  healthSecondOwnerPanicAttacksAbsenceHasRecovered = 'health-second-owner-panic-attacks-absence-has-recovered',
  healthSecondOwnerPanicAttacksAbsenceRecoveryDate = 'health-second-owner-panic-attacks-absence-recovery-date',
  healthSecondOwnerPanicAttacksAbsenceNotRecoveredDetails = 'health-second-owner-panic-attacks-absence-not-recovered-details',

  healthSecondOwnerPanicAttacksMedicationDetails = 'health-second-owner-panic-attacks-medication-details',
  healthSecondOwnerPanicAttacksMedicationName = 'health-second-owner-panic-attacks-medication-name',
  healthSecondOwnerPanicAttacksMedicationDosage = 'health-second-owner-panic-attacks-medication-dosage',
  healthSecondOwnerPanicAttacksMedicationStartDate = 'health-second-owner-panic-attacks-medication-start-date',
  healthSecondOwnerPanicAttacksMedicationEndDate = 'health-second-owner-panic-attacks-medication-end-date',
  healthSecondOwnerPanicAttacksMedicationStillUses = 'health-second-owner-panic-attacks-medication-still-uses',
  healthSecondOwnerPanicAttacksMedicationHasChanged = 'health-second-owner-panic-attacks-medication-has-changed',
  healthSecondOwnerPanicAttacksMedicationHasChangedDetails = 'health-second-owner-panic-attacks-medication-has-changed-details',

  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsDetails = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-details',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsProfession = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-profession',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsName = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-name',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsAddress = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-address',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFirstDate = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-first-date',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsLastDate = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-last-date',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsResult = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-result',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFrequency = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-frequency',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-panic-attacks-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerPanicAttacksWentToEmergencyDepartmentDetails = 'health-second-owner-panic-attacks-went-to-emergency-department-details',
  healthSecondOwnerPanicAttacksWentToEmergencyDepartmentDate = 'health-second-owner-panic-attacks-went-to-emergency-department-date',
  healthSecondOwnerPanicAttacksWentToEmergencyDepartmentLocation = 'health-second-owner-panic-attacks-went-to-emergency-department-location',

  healthSecondOwnerPanicAttacksWasHospitalizedDetails = 'health-second-owner-panic-attacks-was-hospitalized-details',
  healthSecondOwnerPanicAttacksWasHospitalizedDate = 'health-second-owner-panic-attacks-was-hospitalized-date',
  healthSecondOwnerPanicAttacksWasHospitalizedFrequencyNumber = 'health-second-owner-panic-attacks-was-hospitalized-frequency-number',
  healthSecondOwnerPanicAttacksWasHospitalizedFrequency = 'health-second-owner-panic-attacks-was-hospitalized-frequency',
  healthSecondOwnerPanicAttacksWasHospitalizedLocation = 'health-second-owner-panic-attacks-was-hospitalized-location',

  healthSecondOwnerPanicAttacksSuicidalThoughtsDetails = 'health-second-owner-panic-attacks-suicidal-thoughts-details',
  healthSecondOwnerPanicAttacksSuicidalThoughtsDate = 'health-second-owner-panic-attacks-suicidal-thoughts-date',
  healthSecondOwnerPanicAttacksSuicideAttemptsDetails = 'health-second-owner-panic-attacks-suicide-attempts-details',
  healthSecondOwnerPanicAttacksSuicideAttemptsDate = 'health-second-owner-panic-attacks-suicide-attempts-date',
  healthSecondOwnerPanicAttacksHadAbsenceDetails = 'health-second-owner-panic-attacks-had-absence-details',
  healthSecondOwnerPanicAttacksAbsenceStartDate = 'health-second-owner-panic-attacks-absence-start-date',
  healthSecondOwnerPanicAttacksAbsenceFrequencyNumber = 'health-second-owner-panic-attacks-absence-frequency-number',
  healthSecondOwnerPanicAttacksAbsenceFrequency = 'health-second-owner-panic-attacks-absence-frequency',

  healthSecondOwnerBurnoutIsSingleEpisode = 'health-second-owner-burnout-is-single-episode',
  healthSecondOwnerBurnoutDiagnosticDate = 'health-second-owner-burnout-diagnostic-date',
  healthSecondOwnerBurnoutMedicationWasPrescribed = 'health-second-owner-burnout-medication-was-prescribed',
  healthSecondOwnerBurnoutHasConsultedDoctors = 'health-second-owner-burnout-consulted-doctors',
  healthSecondOwnerBurnoutHasConsultedOtherDoctorsOrTherapists = 'health-second-owner-burnout-has-consulted-other-doctors-or-therapists',
  healthSecondOwnerBurnoutWentToEmergencyDepartment = 'health-second-owner-burnout-went-to-emergency-department',
  healthSecondOwnerBurnoutWasHospitalized = 'health-second-owner-burnout-was-hospitalized',
  healthSecondOwnerBurnoutHadSuicidalThoughtsAndAttempt = 'health-second-owner-burnout-had-suicidal-thoughts-and-attempt',
  healthSecondOwnerBurnoutSuicidalThoughtsAndAttemptChoices = 'health-second-owner-burnout-suicidal-thoughts-and-attempt-choices',
  healthSecondOwnerBurnoutSuicidalThoughtsFrequencyNumber = 'health-second-owner-burnout-suicidal-thoughts-frequency-number',
  healthSecondOwnerBurnoutSuicidalThoughtsFrequency = 'health-second-owner-burnout-suicidal-thoughts-frequency',
  healthSecondOwnerBurnoutSuicideAttemptsNumber = 'health-second-owner-burnout-suicide-attempts-number',
  healthSecondOwnerBurnoutHadAbsence = 'health-second-owner-burnout-had-absence',
  healthSecondOwnerBurnoutAbsenceHasRecovered = 'health-second-owner-burnout-absence-has-recovered',
  healthSecondOwnerBurnoutAbsenceRecoveryDate = 'health-second-owner-burnout-absence-recovery-date',
  healthSecondOwnerBurnoutAbsenceNotRecoveredDetails = 'health-second-owner-burnout-absence-not-recovered-details',
  healthSecondOwnerBurnoutMedicationDetails = 'health-second-owner-burnout-medication-details',
  healthSecondOwnerBurnoutMedicationName = 'health-second-owner-burnout-medication-name',
  healthSecondOwnerBurnoutMedicationDosage = 'health-second-owner-burnout-medication-dosage',
  healthSecondOwnerBurnoutMedicationStartDate = 'health-second-owner-burnout-medication-start-date',
  healthSecondOwnerBurnoutMedicationEndDate = 'health-second-owner-burnout-medication-end-date',
  healthSecondOwnerBurnoutMedicationStillUses = 'health-second-owner-burnout-medication-still-uses',
  healthSecondOwnerBurnoutMedicationHasChanged = 'health-second-owner-burnout-medication-has-changed',
  healthSecondOwnerBurnoutMedicationHasChangedDetails = 'health-second-owner-burnout-medication-has-changed-details',

  healthSecondOwnerBurnoutHasOrWillConsultDoctorsDetails = 'health-second-owner-burnout-has-or-will-consult-doctors-details',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsProfession = 'health-second-owner-burnout-has-or-will-consult-doctors-profession',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsProfessionOther = 'health-second-owner-burnout-has-or-will-consult-doctors-profession-other',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsName = 'health-second-owner-burnout-has-or-will-consult-doctors-name',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsAddress = 'health-second-owner-burnout-has-or-will-consult-doctors-address',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsFirstDate = 'health-second-owner-burnout-has-or-will-consult-doctors-first-date',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsLastDate = 'health-second-owner-burnout-has-or-will-consult-doctors-last-date',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsResult = 'health-second-owner-burnout-has-or-will-consult-doctors-result',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsFrequencyNumber = 'health-second-owner-burnout-has-or-will-consult-doctors-frequency-number',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsFrequency = 'health-second-owner-burnout-has-or-will-consult-doctors-frequency',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsFurtherConsultation = 'health-second-owner-burnout-has-or-will-consult-doctors-further-consultation',
  healthSecondOwnerBurnoutHasOrWillConsultDoctorsNextConsultationDate = 'health-second-owner-burnout-has-or-will-consult-doctors-next-consultation-date',

  healthSecondOwnerBurnoutWentToEmergencyDepartmentDetails = 'health-second-owner-burnout-went-to-emergency-department-details',
  healthSecondOwnerBurnoutWentToEmergencyDepartmentDate = 'health-second-owner-burnout-went-to-emergency-department-date',
  healthSecondOwnerBurnoutWentToEmergencyDepartmentLocation = 'health-second-owner-burnout-went-to-emergency-department-location',

  healthSecondOwnerBurnoutWasHospitalizedDetails = 'health-second-owner-burnout-was-hospitalized-details',
  healthSecondOwnerBurnoutWasHospitalizedDate = 'health-second-owner-burnout-was-hospitalized-date',
  healthSecondOwnerBurnoutWasHospitalizedFrequencyNumber = 'health-second-owner-burnout-was-hospitalized-frequency-number',
  healthSecondOwnerBurnoutWasHospitalizedFrequency = 'health-second-owner-burnout-was-hospitalized-frequency',
  healthSecondOwnerBurnoutWasHospitalizedLocation = 'health-second-owner-burnout-was-hospitalized-location',

  healthSecondOwnerBurnoutSuicidalThoughtsDetails = 'health-second-owner-burnout-suicidal-thoughts-details',
  healthSecondOwnerBurnoutSuicidalThoughtsDate = 'health-second-owner-burnout-suicidal-thoughts-date',
  healthSecondOwnerBurnoutSuicideAttemptsDetails = 'health-second-owner-burnout-suicide-attempts-details',
  healthSecondOwnerBurnoutSuicideAttemptsDate = 'health-second-owner-burnout-suicide-attempts-date',
  healthSecondOwnerBurnoutHadAbsenceDetails = 'health-second-owner-burnout-had-absence-details',
  healthSecondOwnerBurnoutAbsenceStartDate = 'health-second-owner-burnout-absence-start-date',
  healthSecondOwnerBurnoutAbsenceFrequencyNumber = 'health-second-owner-burnout-absence-frequency-number',
  healthSecondOwnerBurnoutAbsenceFrequency = 'health-second-owner-burnout-absence-frequency',

  healthSecondOwnerAutoImmuneSystemHasCondition = 'health-second-owner-auto-immune-system-has-condition',
  healthSecondOwnerAutoImmuneSystemChoices = 'health-second-owner-auto-immune-system-choices',
  healthSecondOwnerAidsDetails = 'health-second-owner-aids-details',
  healthSecondOwnerHivDetails = 'health-second-owner-hiv-details',
  healthSecondOwnerLupusDetails = 'health-second-owner-lupus-details',
  healthSecondOwnerSclerodermaDetails = 'health-second-owner-scleroderma-details',
  healthSecondOwnerOtherAutoImmuneSystemDisorderDetails = 'health-second-owner-other-auto-immune-system-disorder-details',
  healthSecondOwnerTumorBodyPart = 'health-second-owner-tumor-body-part',
  healthSecondOwnerTumorSeverity = 'health-second-owner-tumor-severity',
  healthSecondOwnerLeukemiaDetails = 'health-second-owner-leukemia-details',
  healthSecondOwnerCancerDetails = 'health-second-owner-cancer-details',
  healthSecondOwnerCancerPolypsDetails = 'health-second-owner-cancer-polyps-details',
  healthSecondOwnerNoduleDetails = 'health-second-owner-nodule-details',
  healthSecondOwnerLymphNodeDisorderDetails = 'health-second-owner-lymph-node-disorder-details',
  healthSecondOwnerCancerOtherDetails = 'health-second-owner-cancer-other-details',
  healthSecondOwnerBreastDisorderHasCondition = 'health-second-owner-breast-disorder-has-condition',
  healthSecondOwnerBreastDisorderApplicableConditions = 'health-second-owner-breast-disorder-applicable-conditions',
  healthSecondOwnerBreastDisorderLumpDetails = 'health-second-owner-breast-disorder-lump-details',
  healthSecondOwnerBreastDisorderBumpDetails = 'health-second-owner-breast-disorder-bump-details',
  healthSecondOwnerBreastDisorderCystDetails = 'health-second-owner-breast-disorder-cyst-details',
  healthSecondOwnerBreastDisorderOtherDetails = 'health-second-owner-breast-disorder-other-details',

  healthSecondOwnerPartialOrTotalBlindnessDetails = 'health-second-owner-partial-or-total-blindness-details',
  healthSecondOwnerMacularDegenerationDetails = 'health-second-owner-macular-degeneration-details',
  healthSecondOwnerGlaucomaDetails = 'health-second-owner-glaucoma-details',
  healthSecondOwnerPartialOrTotalDeafnessDetails = 'health-second-owner-partial-or-total-deafness-details',
  healthSecondOwnerTinnitusDetails = 'health-second-owner-tinnitus-details',
  healthSecondOwnerMeniereDiseaseDetails = 'health-second-owner-meniere-disease-details',
  healthSecondOwnerLabyrinthitisDetails = 'health-second-owner-labyrinthitis-details',
  healthSecondOwnerOtherEyeEarNoseOrThroatDisorderDetails = 'health-second-owner-other-eye-ear-nose-or-throat-disorder-details',
  healthSecondOwnerLast5YearsTestsDetails = 'health-second-owner-last-5-years-tests-details',
  healthSecondOwnerLast5YearsTestsAnyOtherDetails = 'health-second-owner-last-5-years-tests-any-other-details',
  healthSecondOwnerOtherProfessionals = 'health-second-owner-other-professionals',
  healthSecondOwnerLast5YearsHospitalizedDetails = 'health-second-owner-last-5-years-hospitalized-details',
  healthSecondOwnerMoreMedicalActionPending = 'health-second-owner-more-medical-action-pending',
  healthSecondOwnerMoreMedicalActionChoices = 'health-second-owner-more-medical-action-choices',
  healthSecondOwnerMoreMedicalActionTreatmentsDetails = 'health-second-owner-more-medical-action-treatments-details',
  healthSecondOwnerMoreMedicalActionSurgeryDetails = 'health-second-owner-more-medical-action-surgery-details',
  healthSecondOwnerMoreMedicalActionAwaitingResultsDetails = 'health-second-owner-more-medical-action-awaiting-results-details',
  healthSecondOwnerDiagnosticExamsOrTestsDetails = 'health-second-owner-diagnostic-exams-or-tests-details',
  healthSecondOwnerHasFamilyHistory2 = 'health-second-owner-has-family-history-2',
  healthSecondOwnerFamilyMedicalHistory2 = 'health-second-owner-family-medical-history-2',
  healthSecondOwnerHasFamilyDoctorOrClinic = 'health-second-owner-has-family-doctor-or-clinic',
  healthSecondOwnerFamilyDoctorOrClinicName = 'health-second-owner-family-doctor-or-clinic-name',
  healthSecondOwnerFamilyDoctorOrClinicAddressStreet = 'health-second-owner-family-doctor-or-clinic-address-street',
  healthSecondOwnerFamilyDoctorOrClinicAddressApartment = 'health-second-owner-family-doctor-or-clinic-address-apartment',
  healthSecondOwnerFamilyDoctorOrClinicAddressCity = 'health-second-owner-family-doctor-or-clinic-address-city',
  healthSecondOwnerFamilyDoctorOrClinicAddressCountry = 'health-second-owner-family-doctor-or-clinic-address-country',
  healthSecondOwnerFamilyDoctorOrClinicAddressProvince = 'health-second-owner-family-doctor-or-clinic-address-province',
  healthSecondOwnerFamilyDoctorOrClinicAddressPostalCode = 'health-second-owner-family-doctor-or-clinic-address-postal-code',
  healthSecondOwnerMusculoskeletalOsteoarthritisIsSingleEpisode = 'health-second-owner-musculoskeletal-osteoarthritis-is-single-episode',
  healthSecondOwnerMusculoskeletalOsteoarthritisPreviousEpisodesCount = 'health-second-owner-musculoskeletal-osteoarthritis-previous-episodes-count',
  healthSecondOwnerMusculoskeletalOsteoarthritisDateSymptoms = 'health-second-owner-musculoskeletal-osteoarthritis-date-symptoms',
  healthSecondOwnerMusculoskeletalOsteoarthritisPainLocation = 'health-second-owner-musculoskeletal-osteoarthritis-pain-location',
  healthSecondOwnerMusculoskeletalOsteoarthritisPainLocationLeftRight = 'health-second-owner-musculoskeletal-osteoarthritis-pain-location-left-right',
  healthSecondOwnerMusculoskeletalOsteoarthritisPainLocationOther = 'health-second-owner-musculoskeletal-osteoarthritis-pain-location-other',
  healthSecondOwnerMusculoskeletalOsteoarthritisPainCause = 'health-second-owner-musculoskeletal-osteoarthritis-pain-cause',
  healthSecondOwnerMusculoskeletalOsteoarthritisPainCauseDetails = 'health-second-owner-musculoskeletal-osteoarthritis-pain-cause-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctors = 'health-second-owner-musculoskeletal-osteoarthritis-consulted-doctors',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExams = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams',
  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationWasPrescribed = 'health-second-owner-musculoskeletal-osteoarthritis-medication-was-prescribed',
  healthSecondOwnerMusculoskeletalOsteoarthritisHadOperation = 'health-second-owner-musculoskeletal-osteoarthritis-had-operation',
  healthSecondOwnerMusculoskeletalOsteoarthritisOperationPerformedOrPlanned = 'health-second-owner-musculoskeletal-osteoarthritis-operation-performed-or-planned',
  healthSecondOwnerMusculoskeletalOsteoarthritisTypeOfOperationPerformed = 'health-second-owner-musculoskeletal-osteoarthritis-type-of-operation-performed',
  healthSecondOwnerMusculoskeletalOsteoarthritisOperationPerformedDate = 'health-second-owner-musculoskeletal-osteoarthritis-operation-performed-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisTypeOfOperationPlanned = 'health-second-owner-musculoskeletal-osteoarthritis-type-of-operation-planned',
  healthSecondOwnerMusculoskeletalOsteoarthritisOperationPlannedDate = 'health-second-owner-musculoskeletal-osteoarthritis-operation-planned-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisOperationUnknownDate = 'health-second-owner-musculoskeletal-osteoarthritis-operation-unknown-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisHadAbsence = 'health-second-owner-musculoskeletal-osteoarthritis-had-absence',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceStartDate = 'health-second-owner-musculoskeletal-osteoarthritis-absence-start-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceFrequencyNumber = 'health-second-owner-musculoskeletal-osteoarthritis-absence-frequency-number',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceFrequency = 'health-second-owner-musculoskeletal-osteoarthritis-absence-frequency',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceHasRecovered = 'health-second-owner-musculoskeletal-osteoarthritis-absence-has-recovered',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceRecoveryDate = 'health-second-owner-musculoskeletal-osteoarthritis-absence-recovery-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails = 'health-second-owner-musculoskeletal-osteoarthritis-absence-not-recovered-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-profession',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-profession-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsName = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-name',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-address',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-first-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-last-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsResult = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-result',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency-number',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-frequency',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctor-further-consultation',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-second-owner-musculoskeletal-osteoarthritis-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-type',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-result',
  healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-musculoskeletal-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationDetails = 'health-second-owner-musculoskeletal-osteoarthritis-medication-details',
  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationName = 'health-second-owner-musculoskeletal-osteoarthritis-medication-name',
  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationDosage = 'health-second-owner-musculoskeletal-osteoarthritis-medication-dosage',
  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationStartDate = 'health-second-owner-musculoskeletal-osteoarthritis-medication-start-date',
  healthSecondOwnerMusculoskeletalOsteoarthritisMedicationEndDate = 'health-second-owner-musculoskeletal-osteoarthritis-medication-end-date',

  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-is-single-episode',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-previous-episodes-count',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaDateSymptoms = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-date-symptoms',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocation = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-pain-location',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-pain-location-left-right',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationOther = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-pain-location-other',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainCause = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-pain-cause',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-pain-cause-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-consulted-doctors',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-was-prescribed',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHadOperation = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-had-operation',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-operation-performed-or-planned',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-type-of-operation-performed',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-operation-performed-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-type-of-operation-planned',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-operation-planned-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-operation-unknown-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHadAbsence = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-had-absence',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceStartDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-start-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-frequency-number',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-frequency',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-has-recovered',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-recovery-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-absence-not-recovered-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-profession-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-name',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-address',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-first-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-last-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-result',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency-number',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-frequency',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctor-further-consultation',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-consulted-doctors-next-consultation-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-type',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-result',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-has-taken-tests-or-exams-details-abnormal-result',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDetails = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-details',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationName = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-name',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDosage = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-dosage',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-start-date',
  healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate = 'health-second-owner-musculoskeletal-osteoporosis-osteopenia-medication-end-date',

  healthSecondOwnerFibromyalgiaIsSingleEpisode = 'health-second-owner-fibromyalgia-is-single-episode',
  healthSecondOwnerFibromyalgiaPreviousEpisodesCount = 'health-second-owner-fibromyalgia-previous-episodes-count',
  healthSecondOwnerFibromyalgiaDateSymptoms = 'health-second-owner-fibromyalgia-date-symptoms',
  healthSecondOwnerFibromyalgiaPainLocation = 'health-second-owner-fibromyalgia-pain-location',
  healthSecondOwnerFibromyalgiaPainLocationLeftRight = 'health-second-owner-fibromyalgia-pain-location-left-right',
  healthSecondOwnerFibromyalgiaPainLocationOther = 'health-second-owner-fibromyalgia-pain-location-other',
  healthSecondOwnerFibromyalgiaPainCause = 'health-second-owner-fibromyalgia-pain-cause',
  healthSecondOwnerFibromyalgiaPainCauseDetails = 'health-second-owner-fibromyalgia-pain-cause-details',
  healthSecondOwnerFibromyalgiaHasConsultedDoctors = 'health-second-owner-fibromyalgia-consulted-doctors',
  healthSecondOwnerFibromyalgiaHasTakenTestsOrExams = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams',
  healthSecondOwnerFibromyalgiaMedicationWasPrescribed = 'health-second-owner-fibromyalgia-medication-was-prescribed',
  healthSecondOwnerFibromyalgiaHadOperation = 'health-second-owner-fibromyalgia-had-operation',
  healthSecondOwnerFibromyalgiaOperationPerformedOrPlanned = 'health-second-owner-fibromyalgia-operation-performed-or-planned',
  healthSecondOwnerFibromyalgiaTypeOfOperationPerformed = 'health-second-owner-fibromyalgia-type-of-operation-performed',
  healthSecondOwnerFibromyalgiaOperationPerformedDate = 'health-second-owner-fibromyalgia-operation-performed-date',
  healthSecondOwnerFibromyalgiaTypeOfOperationPlanned = 'health-second-owner-fibromyalgia-type-of-operation-planned',
  healthSecondOwnerFibromyalgiaOperationPlannedDate = 'health-second-owner-fibromyalgia-operation-planned-date',
  healthSecondOwnerFibromyalgiaOperationUnknownDate = 'health-second-owner-fibromyalgia-operation-unknown-date',
  healthSecondOwnerFibromyalgiaHadAbsence = 'health-second-owner-fibromyalgia-has-had-absence',
  healthSecondOwnerFibromyalgiaAbsenceStartDate = 'health-second-owner-fibromyalgia-absence-start-date',
  healthSecondOwnerFibromyalgiaAbsenceFrequencyNumber = 'health-second-owner-fibromyalgia-absence-frequency-number',
  healthSecondOwnerFibromyalgiaAbsenceFrequency = 'health-second-owner-fibromyalgia-absence-frequency',
  healthSecondOwnerFibromyalgiaAbsenceHasRecovered = 'health-second-owner-fibromyalgia-absence-has-recovered',
  healthSecondOwnerFibromyalgiaAbsenceRecoveryDate = 'health-second-owner-fibromyalgia-absence-recovery-date',
  healthSecondOwnerFibromyalgiaAbsenceNotRecoveredDetails = 'health-second-owner-fibromyalgia-absence-not-recovered-details',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsDetails = 'health-second-owner-fibromyalgia-has-consulted-doctors-details',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsProfession = 'health-second-owner-fibromyalgia-has-consulted-doctors-profession',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsProfessionDetails = 'health-second-owner-fibromyalgia-has-consulted-doctors-profession-details',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsName = 'health-second-owner-fibromyalgia-has-consulted-doctors-name',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsAddress = 'health-second-owner-fibromyalgia-has-consulted-doctors-address',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsFirstDate = 'health-second-owner-fibromyalgia-has-consulted-doctors-first-date',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsLastDate = 'health-second-owner-fibromyalgia-has-consulted-doctors-last-date',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsResult = 'health-second-owner-fibromyalgia-has-consulted-doctors-result',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsFrequencyNumber = 'health-second-owner-fibromyalgia-has-consulted-doctors-frequency-number',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsFrequency = 'health-second-owner-fibromyalgia-has-consulted-doctors-frequency',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorFurtherConsultation = 'health-second-owner-fibromyalgia-has-consulted-doctor-further-consultation',
  healthSecondOwnerFibromyalgiaHasConsultedDoctorsNextConsultationDate = 'health-second-owner-fibromyalgia-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDetails = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams-details',
  healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsType = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams-type',
  healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDate = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams-date',
  healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsResult = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams-result',
  healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-fibromyalgia-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerFibromyalgiaMedicationDetails = 'health-second-owner-fibromyalgia-medication-details',
  healthSecondOwnerFibromyalgiaMedicationName = 'health-second-owner-fibromyalgia-medication-name',
  healthSecondOwnerFibromyalgiaMedicationDosage = 'health-second-owner-fibromyalgia-medication-dosage',
  healthSecondOwnerFibromyalgiaMedicationStartDate = 'health-second-owner-fibromyalgia-medication-start-date',
  healthSecondOwnerFibromyalgiaMedicationEndDate = 'health-second-owner-fibromyalgia-medication-end-date',

  healthSecondOwnerReplacementJointIsSingleEpisode = 'health-second-owner-replacement-joint-is-single-episode',
  healthSecondOwnerReplacementJointPreviousEpisodesCount = 'health-second-owner-replacement-joint-previous-episodes-count',
  healthSecondOwnerReplacementJointDateSymptoms = 'health-second-owner-replacement-joint-date-symptoms',
  healthSecondOwnerReplacementJointPainLocation = 'health-second-owner-replacement-joint-pain-location',
  healthSecondOwnerReplacementJointPainLocationLeftRight = 'health-second-owner-replacement-joint-pain-location-left-right',
  healthSecondOwnerReplacementJointPainLocationOther = 'health-second-owner-replacement-joint-pain-location-other',
  healthSecondOwnerReplacementJointPainCause = 'health-second-owner-replacement-joint-pain-cause',
  healthSecondOwnerReplacementJointPainCauseDetails = 'health-second-owner-replacement-joint-pain-cause-details',
  healthSecondOwnerReplacementJointHasConsultedDoctors = 'health-second-owner-replacement-joint-consulted-doctors',
  healthSecondOwnerReplacementJointHasTakenTestsOrExams = 'health-second-owner-replacement-joint-has-taken-tests-or-exams',
  healthSecondOwnerReplacementJointMedicationWasPrescribed = 'health-second-owner-replacement-joint-medication-was-prescribed',
  healthSecondOwnerReplacementJointHadOperation = 'health-second-owner-replacement-joint-had-operation',
  healthSecondOwnerReplacementJointOperationPerformedOrPlanned = 'health-second-owner-replacement-joint-operation-performed-or-planned',
  healthSecondOwnerReplacementJointTypeOfOperationPerformed = 'health-second-owner-replacement-joint-type-of-operation-performed',
  healthSecondOwnerReplacementJointOperationPerformedDate = 'health-second-owner-replacement-joint-operation-performed-date',
  healthSecondOwnerReplacementJointTypeOfOperationPlanned = 'health-second-owner-replacement-joint-type-of-operation-planned',
  healthSecondOwnerReplacementJointOperationPlannedDate = 'health-second-owner-replacement-joint-operation-planned-date',
  healthSecondOwnerReplacementJointOperationUnknownDate = 'health-second-owner-replacement-joint-operation-unknown-date',
  healthSecondOwnerReplacementJointHadAbsence = 'health-second-owner-replacement-joint-has-had-absence',
  healthSecondOwnerReplacementJointAbsenceStartDate = 'health-second-owner-replacement-joint-absence-start-date',
  healthSecondOwnerReplacementJointAbsenceFrequencyNumber = 'health-second-owner-replacement-joint-absence-frequency-number',
  healthSecondOwnerReplacementJointAbsenceFrequency = 'health-second-owner-replacement-joint-absence-frequency',
  healthSecondOwnerReplacementJointAbsenceHasRecovered = 'health-second-owner-replacement-joint-absence-has-recovered',
  healthSecondOwnerReplacementJointAbsenceRecoveryDate = 'health-second-owner-replacement-joint-absence-recovery-date',
  healthSecondOwnerReplacementJointAbsenceNotRecoveredDetails = 'health-second-owner-replacement-joint-absence-not-recovered-details',
  healthSecondOwnerReplacementJointHasConsultedDoctorsDetails = 'health-second-owner-replacement-joint-has-consulted-doctors-details',
  healthSecondOwnerReplacementJointHasConsultedDoctorsProfession = 'health-second-owner-replacement-joint-has-consulted-doctors-profession',
  healthSecondOwnerReplacementJointHasConsultedDoctorsProfessionDetails = 'health-second-owner-replacement-joint-has-consulted-doctors-profession-details',
  healthSecondOwnerReplacementJointHasConsultedDoctorsName = 'health-second-owner-replacement-joint-has-consulted-doctors-name',
  healthSecondOwnerReplacementJointHasConsultedDoctorsAddress = 'health-second-owner-replacement-joint-has-consulted-doctors-address',
  healthSecondOwnerReplacementJointHasConsultedDoctorsFirstDate = 'health-second-owner-replacement-joint-has-consulted-doctors-first-date',
  healthSecondOwnerReplacementJointHasConsultedDoctorsLastDate = 'health-second-owner-replacement-joint-has-consulted-doctors-last-date',
  healthSecondOwnerReplacementJointHasConsultedDoctorsResult = 'health-second-owner-replacement-joint-has-consulted-doctors-result',
  healthSecondOwnerReplacementJointHasConsultedDoctorsFrequencyNumber = 'health-second-owner-replacement-joint-has-consulted-doctors-frequency-number',
  healthSecondOwnerReplacementJointHasConsultedDoctorsFrequency = 'health-second-owner-replacement-joint-has-consulted-doctors-frequency',
  healthSecondOwnerReplacementJointHasConsultedDoctorFurtherConsultation = 'health-second-owner-replacement-joint-has-consulted-doctor-further-consultation',
  healthSecondOwnerReplacementJointHasConsultedDoctorsNextConsultationDate = 'health-second-owner-replacement-joint-has-consulted-doctors-next-consultation-date',

  healthSecondOwnerReplacementJointHasTakenTestsOrExamsDetails = 'health-second-owner-replacement-joint-has-taken-tests-or-exams-details',
  healthSecondOwnerReplacementJointHasTakenTestsOrExamsType = 'health-second-owner-replacement-joint-has-taken-tests-or-exams-type',
  healthSecondOwnerReplacementJointHasTakenTestsOrExamsDate = 'health-second-owner-replacement-joint-has-taken-tests-or-exams-date',
  healthSecondOwnerReplacementJointHasTakenTestsOrExamsResult = 'health-second-owner-replacement-joint-has-taken-tests-or-exams-result',
  healthSecondOwnerReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult = 'health-second-owner-replacement-joint-has-taken-tests-or-exams-details-abnormal-result',

  healthSecondOwnerReplacementJointMedicationDetails = 'health-second-owner-replacement-joint-medication-details',
  healthSecondOwnerReplacementJointMedicationName = 'health-second-owner-replacement-joint-medication-name',
  healthSecondOwnerReplacementJointMedicationDosage = 'health-second-owner-replacement-joint-medication-dosage',
  healthSecondOwnerReplacementJointMedicationStartDate = 'health-second-owner-replacement-joint-medication-start-date',
  healthSecondOwnerReplacementJointMedicationEndDate = 'health-second-owner-replacement-joint-medication-end-date',

  healthSecondOwnerGenitourinaryOtherDetails = 'health-second-owner-genitourinary-other-details',
  healthSecondOwnerMentalHealthHasCondition = 'health-second-owner-mental-health-has-condition',
  healthSecondOwnerMentalHealthApplicableConditions = 'health-second-owner-mental-health-applicable-conditions',
  healthSecondOwnerCancerHasCondition = 'health-second-owner-cancer-has-condition',
  healthSecondOwnerCancerApplicableConditions = 'health-second-owner-cancer-applicable-conditions',
  healthSecondOwnerBreastCystDetails = 'health-second-owner-breast-cyst-details',
  healthSecondOwnerOvarianCystDetails = 'health-second-owner-ovarian-cyst-details',
  healthSecondOwnerEyeEarNoseThroatDisorderHasCondition = 'health-second-owner-eye-ear-nose-throat-disorder-has-condition',
  healthSecondOwnerEyeEarNoseThroatDisorderApplicableConditions = 'health-second-owner-eye-ear-nose-throat-disorder-applicable-conditions',
  healthSecondOwnerEyeEarNoseThroatOtherDisorderDetails = 'health-second-owner-eye-ear-nose-throat-other-disorder-details',
  healthSecondOwnerHadConsultationTreatmentForBloodOrSkinDisease = 'health-second-owner-had-consultation-treatment-for-blood-or-skin-disease',
  healthsecondOwnerHadConsultationTreatmentForBloodOrSkinDiseaseDetails = 'health-second-owner-had-consultation-treatment-for-blood-or-skin-disease-details',
  healthSecondOwnerHadConsultationTreatmentForBloodDiseaseDetails = 'health-second-owner-had-consultation-treatment-for-blood-disease-details',
  healthSecondOwnerHadConsultationTreatmentForSkinDiseaseDetails = 'health-second-owner-had-consultation-treatment-for-skin-disease-details',

  healthSecondOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorder = 'health-second-owner-had-consultation-treatment-for-other-physical-or-mental-disorder',
  healthSecondOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorderDetails = 'health-second-owner-had-consultation-treatment-for-other-physical-or-mental-disorder-details',
  healthSecondOwnerHadConsultationTreatmentForOtherPhysicalDisorderDetails = 'health-second-owner-had-consultation-treatment-for-other-physical-disorder-details',
  healthSecondOwnerHadConsultationTreatmentForOtherMentalDisorderDetails = 'health-second-owner-had-consultation-treatment-for-other-mental-disorder-details',

  healthSecondOwnerIsPregnant = 'health-second-owner-is-pregnant',
  healthSecondOwnerPregnancyDueDate = 'health-second-owner-pregnancy-due-date',
  healthSecondOwnerHadPregnancyOrChildbirthComplications = 'health-second-owner-had-pregnancy-or-childbirth-complications',
  healthSecondOwnerHadPregnancyOrChildbirthComplicationsDetails = 'health-second-owner-had-pregnancy-or-childbirth-complications-details',
  healthSecondOwnerHadOtherMedicalTestsLast5Years = 'health-second-owner-had-other-medical-tests-last-5-years',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsTests = 'health-second-owner-had-other-medical-tests-last-5-years-tests',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsBloodTestDetails = 'health-second-owner-had-other-medical-tests-last-5-years-blood-test-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsRestingElectrocardiogramDetails = 'health-second-owner-had-other-medical-tests-last-5-years-resting-electrocardiogram-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsStressElectrocardiogramDetails = 'health-second-owner-had-other-medical-tests-last-5-years-stress-electrocardiogram-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsEchocardiogramDetails = 'health-second-owner-had-other-medical-tests-last-5-years-echocardiogram-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsColonoscopyDetails = 'health-second-owner-had-other-medical-tests-last-5-years-colonoscopy-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsXRayDetails = 'health-second-owner-had-other-medical-tests-last-5-years-x-ray-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsMammographyDetails = 'health-second-owner-had-other-medical-tests-last-5-years-mammography-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsUltrasoundDetails = 'health-second-owner-had-other-medical-tests-last-5-years-ultrasound-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsCtScanDetails = 'health-second-owner-had-other-medical-tests-last-5-years-ct-scan-details',
  healthSecondOwnerHadOtherMedicalTestsLast5YearsMriDetails = 'health-second-owner-had-other-medical-tests-last-5-years-mri-details',

  healthSecondOwnerHasConsultedForAbnormalProblemsLast5Years = 'health-second-owner-has-consulted-for-abnormal-problems-last-5-years',
  healthSecondOwnerAbnormalProblemsLast5YearsDetails = 'health-second-owner-abnormal-problems-last-5-years-details',
  healthSecondOwnerWasHospitalizedInLast5Years = 'health-second-owner-was-hospitalized-in-last-5-years',
  healthSecondOwnerHospitalizedInLast5YearsDetails = 'health-second-owner-hospitalized-in-last-5-years-details',
  healthSecondOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsulted = 'health-second-owner-medical-tests-abnormal-test-physician-has-not-yet-consulted',
  healthSecondOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsultedDetails = 'health-second-owner-medical-tests-abnormal-test-physician-has-not-yet-consulted-details',
  healthSecondOwnerOtherProfessionalsDetails = 'health-second-owner-other-professionals-details',
  healthSecondOwnerDisabilityHadAbsence = 'health-second-owner-disability-had-absence',
  healthSecondOwnerDisabilityAbsenceDetails = 'health-second-owner-disability-absence-details',
  healthSecondOwnerHasOrHadMedication = 'health-second-owner-has-or-had-medication',
  healthSecondOwnerHasOrHadMedicationDetails = 'health-second-owner-has-or-had-medication-details',
  healthSecondOwnerHeight = 'health-second-owner-height',
  healthSecondOwnerWeight = 'health-second-owner-weight',
  healthSecondOwnerHadUnexplainedWeightLoss = 'health-second-owner-had-unexplained-weight-loss',
  healthSecondOwnerWeightLossIsIntentional = 'health-second-owner-weight-loss-is-intentional',
  healthSecondOwnerWeightLoss = 'health-second-owner-weight-loss',
  healthSecondOwnerUnexplainedWeightLossDetails = 'health-second-owner-unexplained-weight-loss-details',
  healthSecondOwnerHasFamilyMedicalHistory = 'health-second-owner-has-family-medical-history',
  healthSecondOwnerHasFamilyMedicalHistoryDetails = 'health-second-owner-has-family-medical-history-details',
  healthSecondOwnerPhoneInterviewIsPrescreening = 'health-second-owner-phone-interview-is-prescreening',
  healthSecondOwnerPhoneInterviewDay = 'health-second-owner-phone-interview-day',
  healthSecondOwnerPhoneInterviewTime = 'health-second-owner-phone-interview-time',
  healthSecondOwnerPhoneInterviewNumber = 'health-second-owner-phone-interview-number',
  healthSecondOwnerPhoneInterviewDay2 = 'health-second-owner-phone-interview-day-2',
  healthSecondOwnerPhoneInterviewTime2 = 'health-second-owner-phone-interview-time-2',
  healthSecondOwnerPhoneInterviewNumber2 = 'health-second-owner-phone-interview-number-2',

  temporaryInsuranceAgreementLegal = 'temporary-insurance-agreement-legal',
  temporaryInsuranceAgreementAge = 'temporary-insurance-agreement-age',
  temporaryInsuranceAgreementPastApplications = 'temporary-insurance-agreement-past-applications',
  temporaryInsuranceAgreementCardiovascular = 'temporary-insurance-agreement-cardiovascular',
  temporaryInsuranceAgreementHospital = 'temporary-insurance-agreement-hospital',
  temporaryInsuranceAgreementDrugAlcohol = 'temporary-insurance-agreement-drug-alcohol',
  temporaryInsuranceAgreement = 'temporary-insurance-agreement',

  otherDiagnosis = 'other-diagnosis',
  hasOtherDiagnosis = 'has-other-diagnosis',
  otherDiagnosisInfo = 'other-diagnosis-info',
  otherDiagnosisChoices = 'other-diagnosis-choices',
  otherDiagnosisDetails = 'other-diagnosis-details',
  otherDiagnosisMedProvName = 'other-diagnosis-med-prov-name',
  otherDiagnosisMedProvAddress = 'other-diagnosis-med-prov-address',
  otherDiagnosisMedProvPhone = 'other-diagnosis-med-prov-phone',
  otherDiagnosisAddressAutoComplete = 'other-diagnosis-address-auto-complete',
  pendingTreatments = 'pending-treatments',
  hasPendingTreatments = 'has-pending-treatments',
  pendingTreatmentsInfo = 'pending-treatments-info',
  pendingTreatmentChoices = 'pending-treatment-choices',
  pendingTreatmentDetails = 'pending-treatment-details',
  pendingTreatmentMedProvName = 'pending-treatment-med-prov-name',
  pendingTreatmentMedProvAddress = 'pending-treatment-med-prov-address',
  pendingTreatmentMedProvPhone = 'pending-treatment-med-prov-phone',
  pendingTreatmentAddressAutoComplete = 'pending-treatment-address-auto-complete',
  pendingMedicalTests = 'pending-medical-tests',
  hasPendingMedicalTests = 'has-pending-medical-tests',
  pendingMedicalTestsInfo = 'pending-medical-tests-info',
  pendingMedicalTestsType = 'pending-medical-tests-type',
  pendingMedicalTestsResults = 'pending-medical-tests-results',
  pendingMedicalTestsMedProvName = 'pending-medical-tests-med-prov-name',
  pendingMedicalTestsMedProvAddress = 'pending-medical-tests-med-prov-address',
  pendingMedicalTestsMedProvPhone = 'pending-medical-tests-med-prov-phone',
  pendingMedicalTestsAddressAutoComplete = 'pending-medical-tests-address-auto-complete',
  lifestyleConvictionOtherThanForDrunkDriving = 'lifestyle-conviction-other-than-for-drunk-driving',
  lifestyleConvictionDrunkDrivingMoreThanOnce = 'lifestyle-conviction-drunk-driving-more-than-once',
  lifestyleOtherMovingViolations = 'lifestyle-other-moving-violations',
  lifestyleMovingViolationsConvictions = 'lifestyle-moving-violations-convictions',
  lifestyleOtherMovingViolationsConvictions = 'lifestyle-other-moving-violations-convictions',
  lifestyleMovingAccidents = 'lifestyle-moving-accidents',
  lifestyleOtherMovingAccidents = 'lifestyle-other-moving-accidents',
  lifestyleMovingViolationDetails = 'lifestyle-moving-violation-details',
  lifestyleInsured1stViolationDate = 'lifestyle-insured-1st-violation-date',
  lifestyleInsured2ndViolationDate = 'lifestyle-insured-2nd-violation-date',
  lifestyle1stSpeedingLimitDetails = 'lifestyle-1st-speeding-limit-details',
  lifestyle1stDrivingViolationsOthersDetails = 'lifestyle-1st-driving-violations-others-details',
  lifestyle2ndDrivingViolationsChoices = 'lifestyle-2nd-driving-violations-choices',
  lifestyle2ndSpeedingLimitDetails = 'lifestyle-2nd-speeding-limit-details',
  lifestyle2ndDrivingViolationsOthersDetails = 'lifestyle-2nd-driving-violations-others-details',
  lifestyleAdditionalDrivingViolations = 'lifestyle-additional-driving-violations',
  lifestyleInsuredDrivingHasViolationsInPast3Years = 'lifestyle-insured-driving-has-violations-in-past-3-years',
  lifestyleMovingViolationDetailsLast05Years = 'lifestyle-moving-violation-details-last-05years',
  lifestyleMovingViolationNumber = 'lifestyle-moving-violation-number',
  lifestyleMovingViolationDate = 'lifestyle-moving-violation-date',
  lifestyleMovingViolationType = 'lifestyle-moving-violation-type',
  lifestyleAwaitingSentenceDrunkDriving = 'lifestyle-awaiting-sentence-drunk-driving',
  lifestyleDriverLicenseStillSuspended = 'lifestyle-driver-license-still-suspended',
  lifestyleDriverProfileOtherViolation = 'lifestyle-driver-profile-other-violation',
  lifestyleActivelyWorking = 'lifestyle-actively-working',
  lifestyleStatusNeitherStudentStayingHomeOrRetired = 'lifestyle-status-neither-student-staying-home-or-retired',
  lifestyleMarijuanaPrescription = 'lifestyle-marijuana-prescription',
  lifestyleReceivedDrugOrAlcoholCounselling = 'lifestyle-received-drug-or-alcohol-counselling',
  lifestyleChargedOrConvicted = 'lifestyle-charged-or-convicted',
  lifestyleUnderInvestigationsCircumstances = 'lifestyle-under-investigations-circumstances',
  lifestyleUnderInvestigationsDate = 'lifestyle-under-investigations-date',
  lifestyleUnderInvestigationsCharges = 'lifestyle-under-investigations-charges',
  lifestyleTrialCircumstances = 'lifestyle-trial-circumstances',
  lifestyleTrialDate = 'lifestyle-trial-date',
  lifestyleTrialCharges = 'lifestyle-trial-charges',
  lifestyleProbationStartDate = 'lifestyle-probation-start-date',
  lifestyleProbationEndDate = 'lifestyle-probation-end-date',
  lifestyleProbationCircumstances = 'lifestyle-probation-circumstances',
  lifestyleProbationDate = 'lifestyle-probation-date',
  lifestyleProbationCharges = 'lifestyle-probation-charges',
  lifestyleProbationSentenceDetails = 'lifestyle-probation-sentence-details',
  lifestyleAnyOtherOffences = 'lifestyle-any-other-offences',
  lifestyleOtherOffencesCircumstances = 'lifestyle-other-offences-circumstances',
  lifestyleOtherOffencesDate = 'lifestyle-other-offences-date',
  lifestyleOtherOffencesCharges = 'lifestyle-other-offences-charges',
  lifeStyleInsuredChargedOrConvictedCircumstances = 'lifestyle-insured-charged-or-convicted-circumstances',
  lifeStyleInsuredChargedOrConvictedDate = 'lifestyle-insured-charged-or-convicted-date',
  lifeStyleInsuredChargedOrConvictedCharges = 'lifestyle-insured-charged-or-convicted-charges',
  lifeStyleInsuredChargedOrConvictedSentenceDetails = 'lifestyle-insured-charged-or-convicted-sentence-details',
  lifeStyleInsuredHasAdditionalOffence = 'lifestyle-insured-has-additional-offence',
  lifeStyleInsuredChargedOrConvictedAdditionalOffenceCircumstances = 'lifestyle-insured-charged-or-convicted-additional-offence-circumstances',
  lifeStyleInsuredChargedOrConvictedAdditionalOffencesDate = 'lifestyle-insured-charged-or-convicted-additional-offences-date',
  lifeStyleInsuredChargedOrConvictedAdditionalOffencesCharges = 'lifestyle-insured-charged-or-convicted-additional-offences-charges',
  lifestyleDriversLicenceSuspended = 'lifestyle-drivers-licence-suspended',
  lifestyleDriversLicenceSuspensionDetails = 'lifestyle-drivers-licence-suspension-details',
  lifestyleDriversLicenceSuspensionReason = 'lifestyle-drivers-licence-suspension-reason',
  lifestyleDriversLicenceSuspensionReinstated = 'lifestyle-drivers-licence-suspension-reinstated',
  lifestyleImpairedDriving = 'lifestyle-impaired-driving',
  lifestyleIndictableOffenceOtherThanImpairedDriving = 'lifestyle-indictable-offence-other-than-impaired-driving',
  lifestyleDrivingOffenses = 'lifestyle-impaired-offenses',
  lifestyleOccupationInCategory = 'lifestyle-occupation-in-category',
  lifestyleFlownAsPrivatePilot = 'lifestyle-flown-as-private-pilot',
  lifestyleExtremeSport = 'lifestyle-extreme-sport',
  lifestyleExtremeSportChoices = 'lifestyle-extreme-sport-choices',
  lifestyleTravelMoreThan6Months = 'lifestyle-travel-more-than-6-months',
  lifestyleTravelOtherThanApprovedCountries = 'lifestyle-travel-other-than-countries',
  lifestyleTravelPastDetails = 'lifestyle-travel-past-details',
  lifestyleTravelPastDetailsWithin02Years = 'lifestyle-travel-past-details-within-02years',
  lifestyleTravelPastDetailsCountriesVisited = 'lifestyle-travel-past-details-countries-visited',
  lifestyleTravelPastDetailsDateOfTravel = 'lifestyle-travel-past-details-date-of-travel',
  lifestyleTravelPastDetailsLengthOfStayInDays = 'lifestyle-travel-past-details-length-of-stay-in-days',
  lifestyleTravelPastDetailsPurpose = 'lifestyle-travel-past-details-purpose',
  lifestyleTravelPastDetailsPurposeOther = 'lifestyle-travel-past-details-purpose-other',
  lifestyleTravelFutureDetails = 'lifestyle-travel-future-details',
  lifestyleTravelFutureDetailsWithin02Years = 'lifestyle-travel-future-details-within-02years',
  lifestyleTravelFutureDetailsCountriesVisited = 'lifestyle-travel-future-details-countries-visited',
  lifestyleTravelFutureDetailsDateOfTravel = 'lifestyle-travel-future-details-date-of-travel',
  lifestyleTravelFutureDetailsLengthOfStayInDays = 'lifestyle-travel-future-details-length-of-stay-in-days',
  lifestyleTravelFutureDetailsPurpose = 'lifestyle-travel-future-details-purpose',
  lifestyleTravelFutureDetailsPurposeOther = 'lifestyle-travel-future-details-purpose-other',
  lifestyleInsuredTravelLast12MonthsDestination = 'lifestyle-insured-travel-last-12-months-destination',
  lifestyleInsuredTravelLast12MonthsDestinationOther = 'lifestyle-insured-travel-last-12-months-destination-other',
  lifestyleInsuredTravelLast12MonthsReason = 'lifestyle-insured-travel-last-12-months-reason',
  lifestyleInsuredTravelLast12MonthsReasonOther = 'lifestyle-insured-travel-last-12-months-reason-other',
  lifestyleInsuredTravelLast12MonthsDepartureDate = 'lifestyle-insured-travel-last-12-months-departure-date',
  lifestyleInsuredTravelLast12MonthsReturnDate = 'lifestyle-insured-travel-last-12-months-return-date',
  lifestyleInsuredTravelNext12MonthsDestination = 'lifestyle-insured-travel-next-12-months-destination',
  lifestyleInsuredTravelNext12MonthsDestinationOther = 'lifestyle-insured-travel-next-12-months-destination-other',
  lifestyleInsuredTravelNext12MonthsReason = 'lifestyle-insured-travel-next-12-months-reason',
  lifestyleInsuredTravelNext12MonthsReasonOther = 'lifestyle-insured-travel-next-12-months-reason-other',
  lifestyleInsuredTravelNext12MonthsDepartureDate = 'lifestyle-insured-travel-next-12-months-departure-date',
  lifestyleInsuredTravelNext12MonthsReturnDate = 'lifestyle-insured-travel-next-12-months-return-date',
  lifestyleInsuredTravelNext12MonthsUnknownDate = 'lifestyle-insured-travel-next-12-months-unknown-date',

  lifestyleImpairedDrivingOffense = 'lifestyle-impaired-driving-offense',
  lifestyleImpairedDrivingOffenseDate = 'lifestyle-impaired-driving-offense-date',
  lifestyleImpairedDrivingLicenseSuspensionDate = 'lifestyle-impaired-driving-license-suspension-date',
  lifestyleImpairedDrivingLicenseRecoveryDate = 'lifestyle-impaired-driving-license-recovery-date',
  lifestyleImpairedDrivingIgnitionInterlockDevice = 'lifestyle-impaired-driving-ignition-interlock-device',
  lifestyleImpairedDrivingWithSuspendedLicense = 'lifestyle-impaired-driving-with-suspended-license',
  lifestyleImpairedDrivingAnyOtherImpairedDrivingDetails = 'lifestyle-impaired-driving-any-other-impaired-driving-details',
  lifestyleImpairedDrivingAnyOtherImpairedDrivingDetailsDate = 'lifestyle-impaired-driving-any-other-impaired-driving-details-date',
  lifestyleImpairedDrivingIgnitionInterlockDeviceStartDate = 'lifestyle-impaired-driving-ignition-interlock-device-start-date',
  lifestyleImpairedDrivingIgnitionInterlockDeviceEndDate = 'lifestyle-impaired-driving-ignition-interlock-device-end-date',

  lifestyleLicenseSuspendOtherReason = 'lifestyle-license-suspend-other-reason',
  lifestyleLicenseSuspendOtherReasonType = 'lifestyle-license-suspend-other-reason-type',
  lifestyleLicenseSuspendOtherReasonMedicalReasonDetails = 'lifestyle-license-suspend-other-reason-medical-reason-details',
  lifestyleLicenseSuspendOtherReasonOtherDetails = 'lifestyle-license-suspend-other-reason-other-details',
  lifestyleLicenseSuspendOtherReasonSuspensionDate = 'lifestyle-license-suspend-other-reason-suspension-date',
  lifestyleLicenseSuspendOtherReasonRecoveryDate = 'lifestyle-license-suspend-other-reason-recovery-date',
  lifestyleLicenseSuspendOtherReasonWithSuspendedLicense = 'lifestyle-license-suspend-other-reason-with-suspended-license',

  additionalDrivingDetails = 'additional-driving-details',
  additionalDrivingViolationsChoices = 'additional-driving-violations-choices',
  additionalSpeedingLimitDetails = 'additional-speeding-limit-details',
  additionalDrivingViolationsOtherDetails = 'additional-driving-violations-other-details',
  additionalDrivingViolationsDate = 'additional-driving-violations-date',

  lifestyleInsuredDrivingHasViolations = 'lifestyle-insured-driving-has-violations',
  lifestyleInsuredDrivingViolationsReasons = 'lifestyle-insured-driving-violations-reasons',
  lifestyleInsuredDrivingViolations = 'lifestyle-insured-driving-violations',
  lifestyleInsured1stViolationDetails = 'lifestyle-insured-1st-violation-details',

  lifestyleTobaccoMostRecentUsage = 'lifestyle-tobacco-most-recent-usage',
  lifestyleTobaccoChoices = 'lifestyle-tobacco-choices',
  lifestyleTobaccoLastDateTobaccoUse = 'lifestyle-tobacco-last-date-tobacco-use',
  lifestyleTobaccoTypicalUsage = 'lifestyle-tobacco-typical-usage',
  lifestyleTobaccoLastDateBigCigars = 'lifestyle-tobacco-last-date-big-cigars',
  lifestyleTobaccoAnswerInconsistent = 'lifestyle-tobacco-answer-inconsistent',
  lifestyleInsuredTobaccoCigarettesDailyQuantity = 'lifestyle-insured-tobacco-cigarettes-daily-quantity',
  lifestyleInsuredTobaccoChoicesOtherIs = 'lifestyle-insured-tobacco-choices-other-is',

  lifestyleMarijuanaMostRecentUsage = 'lifestyle-marijuana-most-recent-usage',
  lifestyleMarijuanaTypicalUsage = 'lifestyle-marijuana-typical-usage',
  lifestyleHasUsedDrugs = 'lifestyle-has-used-drugs',
  lifestyleDrugType = 'lifestyle-drug-type',
  lifestyleMarijuanaUsageDetails = 'lifestyle-marijuana-usage-details',
  lifestyleInsuredMarijuanaUseFormDetails = 'lifestyle-insured-marijuana-use-form-details',
  lifestyleOtherDrugDetails = 'lifestyle-other-drug-details',
  lifestyleHasEngagedInHazardousActivities = 'lifestyle-has-engaged-in-hazardous-activities',
  lifestyleMarijuanaUsageReason = 'lifestyle-marijuana-usage-reason',
  lifestyleMarijuanaMedProviderName = 'lifestyle-marijuana-med-provider-name',
  lifestyleMarijuanaMedProviderAddress = 'lifestyle-marijuana-med-provider-address',
  lifestyleMarijuanaMedProviderPhone = 'lifestyle-marijuana-med-provider-phone',

  lifestyleInsuredAlcoholUse = 'lifestyle-insured-alcohol-use',
  lifestyleInsuredAlcoholUseFrequency = 'lifestyle-insured-alcohol-use-frequency',
  lifestyleInsuredAlcoholUseQuantity = 'lifestyle-insured-alcohol-use-quantity',
  lifestyleInsuredAlcoholUseHistory = 'lifestyle-insured-alcohol-use-history',
  lifestyleInsuredAlcoholUseHistoryFrequency = 'lifestyle-insured-alcohol-use-history-frequency',
  lifestyleInsuredAlcoholUseHistoryQuantity = 'lifestyle-insured-alcohol-use-history-quantity',

  lifestyleInsuredAlcoholHasTherapy = 'lifestyle-insured-alcohol-has-therapy',
  lifestyleInsuredAlcoholTherapyOrTreatmentStartDate = 'lifestyle-insured-alcohol-therapy-or-treatment-start-date',
  lifestyleInsuredAlcoholTherapyOrTreatmentEndDate = 'lifestyle-insured-alcohol-therapy-or-treatment-end-date',
  lifestyleInsuredAlcoholTherapyOrTreatmentType = 'lifestyle-insured-alcohol-therapy-or-treatment-type',
  lifestyleInsuredAlcoholTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-insured-alcohol-therapy-or-treatment-professional-contact-information',
  lifestyleInsuredAlcoholTherapyOrTreatmentAdditionnalPeriod = 'lifestyle-insured-alcohol-therapy-or-treatment-additionnal-period',
  lifestyleInsuredAlcoholTherapyOrTreatmentAdditionnalPeriodDetails = 'lifestyle-insured-alcohol-therapy-or-treatment-additionnal-period-details',
  lifestyleInsuredAlcoholSupportGroupMeetings = 'lifestyle-insured-alcohol-support-group-meetings',
  lifestyleInsuredAlcoholSupportGroupStillAttending = 'lifestyle-insured-alcohol-support-group-still-attending',
  lifestyleInsuredAlcoholSupportGroupStartDate = 'lifestyle-insured-alcohol-support-group-start-date',
  lifestyleInsuredAlcoholSupportGroupEndDate = 'lifestyle-insured-alcohol-support-group-end-date',

  lifestyleInsuredAlcoholChangeHabitConsumptionDate = 'lifestyle-insured-alcohol-change-habit-consumption-date',
  lifestyleInsuredAlcoholChangeHabitConsumptionReason = 'lifestyle-insured-alcohol-change-habit-consumption-reason',

  lifestyleAlcoholHabit = 'lifestyle-alcohol-habit',
  lifestyleAlcoholHabitChoice = 'lifestyle-alcohol-habit-choice',
  insuredHasPendingFelonies = 'insured-has-pending-felonies',
  hasInsuredBeenOnProbation = 'has-insured-been-on-probation',
  lifeStyleQuantityBigCigars = 'lifestyle-tobacco-quantity-big-cigars',
  hasInsuredUsedTobaccoWithinAYear = 'lifestyle-tobacco-used-within-12-months',
  lifestyleDrugMostRecentUsage = 'lifestyle-drug-most-recent-usage',
  lifestyleAlcoholBeerTypicalUsage = 'lifestyle-alcohol-beer-typical-usage',
  lifestyleAlcoholWineTypicalUsage = 'lifestyle-alcohol-wine-typical-usage',
  lifestyleAlcoholSpiritsTypicalUsage = 'lifestyle-alcohol-spirits-typical-usage',
  lifestyleAlcoholCocktailsTypicalUsage = 'lifestyle-alcohol-cocktails-typical-usage',
  lifestyleIllegalOrWithoutPrescriptionDrugUsage = 'lifestyle-illegal-or-without-prescription-drug-usage',
  lifestyleDUIChoices = 'lifestyle-dui-choices',
  lifestyleDUINumber = 'lifestyle-dui-number',
  lifestyleDUIDate = 'lifestyle-dui-date',
  lifestyleDUIType = 'lifestyle-dui-type',

  lifestyleOwnerAlcoholUse = 'lifestyle-owner-alcohol-use',
  lifestyleOwnerAlcoholUseFrequency = 'lifestyle-owner-alcohol-use-frequency',
  lifestyleOwnerAlcoholUseQuantity = 'lifestyle-owner-alcohol-use-quantity',
  lifestyleOwnerAlcoholUseHistory = 'lifestyle-owner-alcohol-use-history',
  lifestyleOwnerAlcoholUseHistoryFrequency = 'lifestyle-owner-alcohol-use-history-frequency',
  lifestyleOwnerAlcoholUseHistoryQuantity = 'lifestyle-owner-alcohol-use-history-quantity',
  lifestyleOwnerAlcoholAdvisedOrCeaseConsumption = 'lifestyle-owner-alcohol-advised-or-cease-consumption',
  lifestyleOwnerAlcoholHasTherapy = 'lifestyle-owner-alcohol-therapy',
  lifestyleOwnerAlcoholSupportGroupMeetings = 'lifestyle-owner-alcohol-support-group-meetings',
  lifestyleOwnerAlcoholAdvisedOrCeaseConsumptionDate = 'lifestyle-owner-alcohol-advised-or-cease-consumption-date',
  lifestyleOwnerAlcoholAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-owner-alcohol-advised-or-cease-consumption-professional-contact-information',
  lifestyleOwnerAlcoholTherapyOrTreatmentStartDate = 'lifestyle-owner-alcohol-therapy-or-treatment-start-date',
  lifestyleOwnerAlcoholTherapyOrTreatmentEndDate = 'lifestyle-owner-alcohol-therapy-or-treatment-end-date',
  lifestyleOwnerAlcoholTherapyOrTreatmentType = 'lifestyle-owner-alcohol-therapy-or-treatment-type',
  lifestyleOwnerAlcoholTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-owner-alcohol-therapy-or-treatment-professional-contact-information',
  lifestyleOwnerAlcoholTherapyOrTreatmentAdditionalPeriod = 'lifestyle-owner-alcohol-therapy-or-treatment-additional-period',
  lifestyleOwnerAlcoholLastDateOfTheLastTreatment = 'lifestyle-owner-alcohol-last-date-of-the-last-treatment',
  lifestyleOwnerAlcoholSupportGroupStillAttending = 'lifestyle-owner-alcohol-support-group-still-attending',
  lifestyleOwnerAlcoholSupportGroupStartDate = 'lifestyle-owner-alcohol-support-group-start-date',
  lifestyleOwnerAlcoholSupportGroupEndDate = 'lifestyle-owner-alcohol-support-group-end-date',
  lifestyleOwnerAlcoholChangeHabitConsumptionDate = 'lifestyle-owner-alcohol-change-habit-consumption-date',
  lifestyleOwnerAlcoholChangeHabitConsumptionReason = 'lifestyle-owner-alcohol-change-habit-consumption-reason',

  lifestyleOwnerMarijuanaUse = 'lifestyle-owner-marijuana-use',
  lifestyleOwnerMarijuanaUseForm = 'lifestyle-owner-marijuana-use-form',
  lifestyleOwnerMarijuanaUseFormDetails = 'lifestyle-owner-marijuana-use-form-details',
  lifestyleOwnerMarijuanaJointConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-joint-consumption-habits-quantity',
  lifestyleOwnerMarijuanaJointConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-joint-consumption-habits-frequency',
  lifestyleOwnerMarijuanaJointConsumptionHabitsUsageDate = 'lifestyle-owner-marijuana-joint-consumption-habits-usage-date',
  lifestyleOwnerMarijuanaJointConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-joint-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaJointConsumptionMedicalCondition = 'lifestyle-owner-marijuana-joint-consumption-medical-condition',
  lifestyleOwnerMarijuanaJointConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-joint-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaJointConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-joint-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaJointPastUse = 'lifestyle-owner-marijuana-joint-past-use',
  lifestyleOwnerMarijuanaJointPastConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-joint-past-consumption-habits-quantity',
  lifestyleOwnerMarijuanaJointPastConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-joint-past-consumption-habits-frequency',
  lifestyleOwnerMarijuanaJointPastConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-joint-past-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaJointPastConsumptionMedicalCondition = 'lifestyle-owner-marijuana-joint-past-consumption-medical-condition',
  lifestyleOwnerMarijuanaJointPastConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-joint-past-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaJointPastConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-joint-past-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaJointChangeHabitConsumptionDate = 'lifestyle-owner-marijuana-joint-change-habit-consumption-date',
  lifestyleOwnerMarijuanaJointChangeHabitConsumptionReason = 'lifestyle-owner-marijuana-joint-change-habit-consumption-reason',
  lifestyleOwnerMarijuanaEdibleConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-edible-consumption-habits-quantity',
  lifestyleOwnerMarijuanaEdibleConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-edible-consumption-habits-frequency',
  lifestyleOwnerMarijuanaEdibleConsumptionHabitsUsageDate = 'lifestyle-owner-marijuana-edible-consumption-habits-usage-date',
  lifestyleOwnerMarijuanaEdibleConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-edible-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaEdibleConsumptionMedicalCondition = 'lifestyle-owner-marijuana-edible-consumption-medical-condition',
  lifestyleOwnerMarijuanaEdibleConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-edible-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaEdibleConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-edible-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaEdiblePastUse = 'lifestyle-owner-marijuana-edible-past-use',
  lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-edible-past-consumption-habits-quantity',
  lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-edible-past-consumption-habits-frequency',
  lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-edible-past-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalCondition = 'lifestyle-owner-marijuana-edible-past-consumption-medical-condition',
  lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-edible-past-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-edible-past-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaEdibleChangeHabitConsumptionDate = 'lifestyle-owner-marijuana-edible-change-habit-consumption-date',
  lifestyleOwnerMarijuanaEdibleChangeHabitConsumptionReason = 'lifestyle-owner-marijuana-edible-change-habit-consumption-reason',
  lifestyleOwnerMarijuanaOilConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-oil-consumption-habits-quantity',
  lifestyleOwnerMarijuanaOilConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-oil-consumption-habits-frequency',
  lifestyleOwnerMarijuanaOilConsumptionHabitsUsageDate = 'lifestyle-owner-marijuana-oil-consumption-habits-usage-date',
  lifestyleOwnerMarijuanaOilConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-oil-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaOilConsumptionMedicalCondition = 'lifestyle-owner-marijuana-oil-consumption-medical-condition',
  lifestyleOwnerMarijuanaOilConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-oil-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaOilConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-oil-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaOilPastUse = 'lifestyle-owner-marijuana-oil-past-use',
  lifestyleOwnerMarijuanaOilPastConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-oil-past-consumption-habits-quantity',
  lifestyleOwnerMarijuanaOilPastConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-oil-past-consumption-habits-frequency',
  lifestyleOwnerMarijuanaOilPastConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-oil-past-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaOilPastConsumptionMedicalCondition = 'lifestyle-owner-marijuana-oil-past-consumption-medical-condition',
  lifestyleOwnerMarijuanaOilPastConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-oil-past-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaOilPastConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-oil-past-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaOilChangeHabitConsumptionDate = 'lifestyle-owner-marijuana-oil-change-habit-consumption-date',
  lifestyleOwnerMarijuanaOilChangeHabitConsumptionReason = 'lifestyle-owner-marijuana-oil-change-habit-consumption-reason',
  lifestyleOwnerMarijuanaOtherConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-other-consumption-habits-quantity',
  lifestyleOwnerMarijuanaOtherConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-other-consumption-habits-frequency',
  lifestyleOwnerMarijuanaOtherConsumptionHabitsUsageDate = 'lifestyle-owner-marijuana-other-consumption-habits-usage-date',
  lifestyleOwnerMarijuanaOtherConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-other-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaOtherConsumptionMedicalCondition = 'lifestyle-owner-marijuana-other-consumption-medical-condition',
  lifestyleOwnerMarijuanaOtherConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-other-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaOtherConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-other-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaOtherPastUse = 'lifestyle-owner-marijuana-other-past-use',
  lifestyleOwnerMarijuanaOtherPastConsumptionHabitsQuantity = 'lifestyle-owner-marijuana-other-past-consumption-habits-quantity',
  lifestyleOwnerMarijuanaOtherPastConsumptionHabitsFrequency = 'lifestyle-owner-marijuana-other-past-consumption-habits-frequency',
  lifestyleOwnerMarijuanaOtherPastConsumptionHabitsUsageType = 'lifestyle-owner-marijuana-other-past-consumption-habits-usage-type',
  lifestyleOwnerMarijuanaOtherPastConsumptionMedicalCondition = 'lifestyle-owner-marijuana-other-past-consumption-medical-condition',
  lifestyleOwnerMarijuanaOtherPastConsumptionMedicalPrescribed = 'lifestyle-owner-marijuana-other-past-consumption-medical-prescribed',
  lifestyleOwnerMarijuanaOtherPastConsumptionMedicalPrescribingPhysician = 'lifestyle-owner-marijuana-other-past-consumption-medical-prescribing-physician',
  lifestyleOwnerMarijuanaOtherChangeHabitConsumptionDate = 'lifestyle-owner-marijuana-other-change-habit-consumption-date',
  lifestyleOwnerMarijuanaOtherChangeHabitConsumptionReason = 'lifestyle-owner-marijuana-other-change-habit-consumption-reason',
  lifestyleOwnerMarijuanaAdvisedOrCeaseConsumption = 'lifestyle-owner-marijuana-advised-or-cease-consumption',
  lifestyleOwnerMarijuanaHasTherapy = 'lifestyle-owner-marijuana-therapy',
  lifestyleOwnerMarijuanaSupportGroupMeetings = 'lifestyle-owner-marijuana-support-group-meetings',
  lifestyleOwnerMarijuanaAdvisedOrCeaseConsumptionDate = 'lifestyle-owner-marijuana-advised-or-cease-consumption-date',
  lifestyleOwnerMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-owner-marijuana-advised-or-cease-consumption-professional-contact-information',
  lifestyleOwnerMarijuanaTherapyOrTreatmentStartDate = 'lifestyle-owner-marijuana-therapy-or-treatment-start-date',
  lifestyleOwnerMarijuanaTherapyOrTreatmentEndDate = 'lifestyle-owner-marijuana-therapy-or-treatment-end-date',
  lifestyleOwnerMarijuanaTherapyOrTreatmentType = 'lifestyle-owner-marijuana-therapy-or-treatment-type',
  lifestyleOwnerMarijuanaTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-owner-marijuana-therapy-or-treatment-professional-contact-information',
  lifestyleOwnerMarijuanaTherapyOrTreatmentIsOnlyPeriod = 'lifestyle-owner-marijuana-therapy-or-treatment-is-only-period',
  lifestyleOwnerMarijuanaTherapyOrTreatmentAdditionalPeriodDetails = 'lifestyle-owner-marijuana-therapy-or-treatment-additionnal-period-details',
  lifestyleOwnerMarijuanaSupportGroupStillAttending = 'lifestyle-owner-marijuana-support-group-still-attending',
  lifestyleOwnerMarijuanaSupportGroupStartDate = 'lifestyle-owner-marijuana-support-group-start-date',
  lifestyleOwnerMarijuanaSupportGroupEndDate = 'lifestyle-owner-marijuana-support-group-end-date',
  lifestyleOwnerMarijuanaPastUseType = 'lifestyle-owner-marijuana-past-use-type',
  lifestyleOwnerMarijuanaPastUseTypeOther = 'lifestyle-owner-marijuana-past-use-type-other',

  lifestyleOwnerDrugsOrNarcoticsUse = 'lifestyle-owner-drugs-or-narcotics-use',
  lifestyleOwnerDrugsOrNarcoticsUseType = 'lifestyle-owner-drugs-or-narcotics-use-type',
  lifestyleOwnerDrugsOrNarcoticsUseFormOther = 'lifestyle-owner-drugs-or-narcotics-use-form-other',
  lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumption = 'lifestyle-owner-drugs-or-narcotics-advised-or-cease-consumption',
  lifestyleOwnerDrugsOrNarcoticsHasTherapy = 'lifestyle-owner-drugs-or-narcotics-therapy',
  lifestyleOwnerDrugsOrNarcoticsSupportGroupMeetings = 'lifestyle-owner-drugs-or-narcotics-support-group-meetings',
  lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionDate = 'lifestyle-owner-drugs-or-narcotics-advised-or-cease-consumption-date',
  lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-owner-drugs-or-narcotics-advised-or-cease-consumption-professional-contact-information',
  lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentStartDate = 'lifestyle-owner-drugs-or-narcotics-therapy-or-treatment-start-date',
  lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentEndDate = 'lifestyle-owner-drugs-or-narcotics-therapy-or-treatment-end-date',
  lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentType = 'lifestyle-owner-drugs-or-narcotics-therapy-or-treatment-type',
  lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-owner-drugs-or-narcotics-therapy-or-treatment-professional-contact-information',
  lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod = 'lifestyle-owner-drugs-or-narcotics-therapy-or-treatment-additionnal-period',
  lifestyleOwnerDrugsOrNarcoticsLastDateOfTheLastTreatment = 'lifestyle-owner-drugs-or-narcotics-last-date-of-the-last-treatment',
  lifestyleOwnerDrugsOrNarcoticsSupportGroupStillAttending = 'lifestyle-owner-drugs-or-narcotics-support-group-still-attending',
  lifestyleOwnerDrugsOrNarcoticsSupportGroupStartDate = 'lifestyle-owner-drugs-or-narcotics-support-group-start-date',
  lifestyleOwnerDrugsOrNarcoticsSupportGroupEndDate = 'lifestyle-owner-drugs-or-narcotics-support-group-end-date',

  lifestyleOwnerDrugsCocaineConsumptionHabitsQuantity = 'lifestyle-owner-drugs-cocaine-consumption-habits-quantity',
  lifestyleOwnerDrugsCocaineConsumptionHabitsFrequency = 'lifestyle-owner-drugs-cocaine-consumption-habits-frequency',
  lifestyleOwnerDrugsCocaineUseStillConsume = 'lifestyle-owner-drugs-cocaine-use-still-consume',
  lifestyleOwnerDrugsCocaineUseStartDate = 'lifestyle-owner-drugs-cocaine-use-start-date',
  lifestyleOwnerDrugsCocaineUseEndDate = 'lifestyle-owner-drugs-cocaine-use-end-date',
  lifestyleOwnerDrugsCocainePastUse = 'lifestyle-owner-drugs-cocaine-past-use',
  lifestyleOwnerDrugsCocainePastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-cocaine-past-consumption-habits-quantity',
  lifestyleOwnerDrugsCocainePastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-cocaine-past-consumption-habits-frequency',
  lifestyleOwnerDrugsCocainePastUseStartDate = 'lifestyle-owner-drugs-cocaine-past-use-start-date',
  lifestyleOwnerDrugsCocainePastUseEndDate = 'lifestyle-owner-drugs-cocaine-past-use-end-date',
  lifestyleOwnerDrugsCocainePastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-cocaine-past-change-habit-consumption-date',
  lifestyleOwnerDrugsCocainePastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-cocaine-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsEcstasyConsumptionHabitsQuantity = 'lifestyle-owner-drugs-ecstasy-consumption-habits-quantity',
  lifestyleOwnerDrugsEcstasyConsumptionHabitsFrequency = 'lifestyle-owner-drugs-ecstasy-consumption-habits-frequency',
  lifestyleOwnerDrugsEcstasyUseStillConsume = 'lifestyle-owner-drugs-ecstasy-use-still-consume',
  lifestyleOwnerDrugsEcstasyUseStartDate = 'lifestyle-owner-drugs-ecstasy-use-start-date',
  lifestyleOwnerDrugsEcstasyUseEndDate = 'lifestyle-owner-drugs-ecstasy-use-end-date',
  lifestyleOwnerDrugsEcstasyPastUse = 'lifestyle-owner-drugs-ecstasy-past-use',
  lifestyleOwnerDrugsEcstasyPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-ecstasy-past-consumption-habits-quantity',
  lifestyleOwnerDrugsEcstasyPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-ecstasy-past-consumption-habits-frequency',
  lifestyleOwnerDrugsEcstasyPastUseStartDate = 'lifestyle-owner-drugs-ecstasy-past-use-start-date',
  lifestyleOwnerDrugsEcstasyPastUseEndDate = 'lifestyle-owner-drugs-ecstasy-past-use-end-date',
  lifestyleOwnerDrugsEcstasyPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-ecstasy-past-change-habit-consumption-date',
  lifestyleOwnerDrugsEcstasyPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-ecstasy-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsLsdConsumptionHabitsQuantity = 'lifestyle-owner-drugs-lsd-consumption-habits-quantity',
  lifestyleOwnerDrugsLsdConsumptionHabitsFrequency = 'lifestyle-owner-drugs-lsd-consumption-habits-frequency',
  lifestyleOwnerDrugsLsdUseStillConsume = 'lifestyle-owner-drugs-lsd-use-still-consume',
  lifestyleOwnerDrugsLsdUseStartDate = 'lifestyle-owner-drugs-lsd-use-start-date',
  lifestyleOwnerDrugsLsdUseEndDate = 'lifestyle-owner-drugs-lsd-use-end-date',
  lifestyleOwnerDrugsLsdPastUse = 'lifestyle-owner-drugs-lsd-past-use',
  lifestyleOwnerDrugsLsdPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-lsd-past-consumption-habits-quantity',
  lifestyleOwnerDrugsLsdPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-lsd-past-consumption-habits-frequency',
  lifestyleOwnerDrugsLsdPastUseStartDate = 'lifestyle-owner-drugs-lsd-past-use-start-date',
  lifestyleOwnerDrugsLsdPastUseEndDate = 'lifestyle-owner-drugs-lsd-past-use-end-date',
  lifestyleOwnerDrugsLsdPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-lsd-past-change-habit-consumption-date',
  lifestyleOwnerDrugsLsdPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-lsd-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsMagicMushroomsConsumptionHabitsQuantity = 'lifestyle-owner-drugs-magic-mushrooms-consumption-habits-quantity',
  lifestyleOwnerDrugsMagicMushroomsConsumptionHabitsFrequency = 'lifestyle-owner-drugs-magic-mushrooms-consumption-habits-frequency',
  lifestyleOwnerDrugsMagicMushroomsUseStillConsume = 'lifestyle-owner-drugs-magic-mushrooms-use-still-consume',
  lifestyleOwnerDrugsMagicMushroomsUseStartDate = 'lifestyle-owner-drugs-magic-mushrooms-use-start-date',
  lifestyleOwnerDrugsMagicMushroomsUseEndDate = 'lifestyle-owner-drugs-magic-mushrooms-use-end-date',
  lifestyleOwnerDrugsMagicMushroomsPastUse = 'lifestyle-owner-drugs-magic-mushrooms-past-use',
  lifestyleOwnerDrugsMagicMushroomsPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-magic-mushrooms-past-consumption-habits-quantity',
  lifestyleOwnerDrugsMagicMushroomsPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-magic-mushrooms-past-consumption-habits-frequency',
  lifestyleOwnerDrugsMagicMushroomsPastUseStartDate = 'lifestyle-owner-drugs-magic-mushrooms-past-use-start-date',
  lifestyleOwnerDrugsMagicMushroomsPastUseEndDate = 'lifestyle-owner-drugs-magic-mushrooms-past-use-end-date',
  lifestyleOwnerDrugsMagicMushroomsPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-magic-mushrooms-past-change-habit-consumption-date',
  lifestyleOwnerDrugsMagicMushroomsPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-magic-mushrooms-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsHeroinConsumptionHabitsQuantity = 'lifestyle-owner-drugs-heroin-consumption-habits-quantity',
  lifestyleOwnerDrugsHeroinConsumptionHabitsFrequency = 'lifestyle-owner-drugs-heroin-consumption-habits-frequency',
  lifestyleOwnerDrugsHeroinUseStillConsume = 'lifestyle-owner-drugs-heroin-use-still-consume',
  lifestyleOwnerDrugsHeroinUseStartDate = 'lifestyle-owner-drugs-heroin-use-start-date',
  lifestyleOwnerDrugsHeroinUseEndDate = 'lifestyle-owner-drugs-heroin-use-end-date',
  lifestyleOwnerDrugsHeroinPastUse = 'lifestyle-owner-drugs-heroin-past-use',
  lifestyleOwnerDrugsHeroinPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-heroin-past-consumption-habits-quantity',
  lifestyleOwnerDrugsHeroinPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-heroin-past-consumption-habits-frequency',
  lifestyleOwnerDrugsHeroinPastUseStartDate = 'lifestyle-owner-drugs-heroin-past-use-start-date',
  lifestyleOwnerDrugsHeroinPastUseEndDate = 'lifestyle-owner-drugs-heroin-past-use-end-date',
  lifestyleOwnerDrugsHeroinPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-heroin-past-change-habit-consumption-date',
  lifestyleOwnerDrugsHeroinPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-heroin-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsFentanylConsumptionHabitsQuantity = 'lifestyle-owner-drugs-fentanyl-consumption-habits-quantity',
  lifestyleOwnerDrugsFentanylConsumptionHabitsFrequency = 'lifestyle-owner-drugs-fentanyl-consumption-habits-frequency',
  lifestyleOwnerDrugsFentanylUseStillConsume = 'lifestyle-owner-drugs-fentanyl-use-still-consume',
  lifestyleOwnerDrugsFentanylUseStartDate = 'lifestyle-owner-drugs-fentanyl-use-start-date',
  lifestyleOwnerDrugsFentanylUseEndDate = 'lifestyle-owner-drugs-fentanyl-use-end-date',
  lifestyleOwnerDrugsFentanylPastUse = 'lifestyle-owner-drugs-fentanyl-past-use',
  lifestyleOwnerDrugsFentanylPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-fentanyl-past-consumption-habits-quantity',
  lifestyleOwnerDrugsFentanylPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-fentanyl-past-consumption-habits-frequency',
  lifestyleOwnerDrugsFentanylPastUseStartDate = 'lifestyle-owner-drugs-fentanyl-past-use-start-date',
  lifestyleOwnerDrugsFentanylPastUseEndDate = 'lifestyle-owner-drugs-fentanyl-past-use-end-date',
  lifestyleOwnerDrugsFentanylPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-fentanyl-past-change-habit-consumption-date',
  lifestyleOwnerDrugsFentanylPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-fentanyl-past-change-habit-consumption-reason',

  lifestyleOwnerDrugsAnabolicSteroidsConsumptionHabitsQuantity = 'lifestyle-owner-drugs-anabolic-steroids-consumption-habits-quantity',
  lifestyleOwnerDrugsAnabolicSteroidsConsumptionHabitsFrequency = 'lifestyle-owner-drugs-anabolic-steroids-consumption-habits-frequency',
  lifestyleOwnerDrugsAnabolicSteroidsUseStillConsume = 'lifestyle-owner-drugs-anabolic-steroids-use-still-consume',
  lifestyleOwnerDrugsAnabolicSteroidsUseStartDate = 'lifestyle-owner-drugs-anabolic-steroids-use-start-date',
  lifestyleOwnerDrugsAnabolicSteroidsUseEndDate = 'lifestyle-owner-drugs-anabolic-steroids-use-end-date',
  lifestyleOwnerDrugsAnabolicSteroidsPastUse = 'lifestyle-owner-drugs-anabolic-steroids-past-use',
  lifestyleOwnerDrugsAnabolicSteroidsPastConsumptionHabitsQuantity = 'lifestyle-owner-drugs-anabolic-steroids-past-consumption-habits-quantity',
  lifestyleOwnerDrugsAnabolicSteroidsPastConsumptionHabitsFrequency = 'lifestyle-owner-drugs-anabolic-steroids-past-consumption-habits-frequency',
  lifestyleOwnerDrugsAnabolicSteroidsPastUseStartDate = 'lifestyle-owner-drugs-anabolic-steroids-past-use-start-date',
  lifestyleOwnerDrugsAnabolicSteroidsPastUseEndDate = 'lifestyle-owner-drugs-anabolic-steroids-past-use-end-date',
  lifestyleOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionDate = 'lifestyle-owner-drugs-anabolic-steroids-past-change-habit-consumption-date',
  lifestyleOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionReason = 'lifestyle-owner-drugs-anabolic-steroids-past-change-habit-consumption-reason',

  lifestyleOwnerOtherDrugsOrNarcoticsConsumptionHabitsQuantity = 'lifestyle-owner-other-drugs-or-narcotics-consumption-habits-quantity',
  lifestyleOwnerOtherDrugsOrNarcoticsConsumptionHabitsFrequency = 'lifestyle-owner-other-drugs-or-narcotics-consumption-habits-frequency',
  lifestyleOwnerOtherDrugsOrNarcoticsUseStillConsume = 'lifestyle-owner-other-drugs-or-narcotics-use-still-consume',
  lifestyleOwnerOtherDrugsOrNarcoticsUseStartDate = 'lifestyle-owner-other-drugs-or-narcotics-use-start-date',
  lifestyleOwnerOtherDrugsOrNarcoticsUseEndDate = 'lifestyle-owner-other-drugs-or-narcotics-use-end-date',
  lifestyleOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionDate = 'lifestyle-owner-other-drugs-or-narcotics-change-habit-consumption-date',
  lifestyleOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionReason = 'lifestyle-owner-other-drugs-or-narcotics-change-habit-consumption-reason',
  lifestyleOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsQuantity = 'lifestyle-owner-other-drugs-or-narcotics-past-consumption-habits-quantity',
  lifestyleOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsFrequency = 'lifestyle-owner-other-drugs-or-narcotics-past-consumption-habits-frequency',
  lifestyleOwnerOtherDrugsOrNarcoticsPastUseStartDate = 'lifestyle-owner-other-drugs-or-narcotics-past-use-start-date',
  lifestyleOwnerOtherDrugsOrNarcoticsPastUseEndDate = 'lifestyle-owner-other-drugs-or-narcotics-past-use-end-date',
  lifestyleOwnerDrivingHasViolations = 'lifestyle-owner-driving-has-violations',
  lifestyleOwnerDrivingHasViolationsInPast3Years = 'lifestyle-owner-driving-has-violations-in-past-3-years',
  lifestyleOwnerDrivingViolationsReasons = 'lifestyle-owner-driving-violations-reasons',
  lifestyleOwner1stViolationDate = 'lifestyle-owner-1st-violation-date',
  lifestyleOwnerMovingViolationDetails = 'lifestyle-owner-moving-violation-details',
  lifestyleOwner1stSpeedingLimitDetails = 'lifestyle-owner-1st-speeding-limit-details',
  lifestyleOwner1stDrivingViolationsOthersDetails = 'lifestyle-owner-1st-driving-violations-others-details',
  lifestyleOwner2ndViolationDate = 'lifestyle-owner-2nd-violation-date',
  lifestyleOwner2ndDrivingViolationsChoices = 'lifestyle-owner-2nd-driving-violations-choices',
  lifestyleOwner2ndSpeedingLimitDetails = 'lifestyle-owner-2nd-speeding-limit-details',
  lifestyleOwner2ndDrivingViolationsOthersDetails = 'lifestyle-owner-2nd-driving-violations-others-details',

  lifestyleOwnerImpairedDrivingOffence = 'lifestyle-owner-impaired-driving-offence',
  lifestyleOwnerImpairedDrivingLastOffenceDate = 'lifestyle-owner-impaired-driving-last-offence-date',
  lifestyleOwnerImpairedDrivingLicenseSuspensionDate = 'lifestyle-owner-impaired-driving-license-suspension-date',
  lifestyleOwnerImpairedDrivingLicenseRecoveryDate = 'lifestyle-owner-impaired-driving-license-recovery-date',
  lifestyleOwnerImpairedDrivingHasIgnitionInterlockDevice = 'lifestyle-owner-impaired-driving-has-ignition-interlock-device',
  lifestyleOwnerImpairedDrivingWithSuspendedLicense = 'lifestyle-owner-impaired-driving-with-suspended-license',
  lifestyleOwnerImpairedDrivingAnyOtherImpairedDrivingCharges = 'lifestyle-owner-impaired-driving-any-other-impaired-driving-charges',
  lifestyleOwnerImpairedDrivingAnyOtherImpairedDrivingChargesDates = 'lifestyle-owner-impaired-driving-any-other-impaired-driving-charges-dates',
  lifestyleOwnerImpairedDrivingIgnitionInterlockDeviceStartDate = 'lifestyle-owner-impaired-driving-ignition-interlock-device-start-date',
  lifestyleOwnerImpairedDrivingIgnitionInterlockDeviceEndDate = 'lifestyle-owner-impaired-driving-ignition-interlock-device-end-date',

  lifestyleOwnerLicenseSuspendedOtherReason = 'lifestyle-owner-license-suspended-other-reason',
  lifestyleOwnerLicenseSuspendedOtherReasonReasons = 'lifestyle-owner-license-suspended-other-reason-reasons',
  lifestyleOwnerLicenseSuspendedOtherReasonMedicalReasonDetails = 'lifestyle-owner-license-suspended-other-reason-medical-reason-details',
  lifestyleOwnerLicenseSuspendedOtherReasonOtherDetails = 'lifestyle-owner-license-suspended-other-reason-other-details',
  lifestyleOwnerLicenseSuspendedOtherReasonSuspensionDate = 'lifestyle-owner-license-suspended-other-reason-suspension-date',
  lifestyleOwnerLicenseSuspendedOtherReasonRecoveryDate = 'lifestyle-owner-license-suspended-other-reason-recovery-date',
  lifestyleOwnerLicenseSuspendedOtherReasonDroveWithSuspendedLicense = 'lifestyle-owner-license-suspended-other-reason-drove-with-suspended-license',

  lifestyleOwnerAdditionalDrivingViolationDetails = 'lifestyle-owner-additional-driving-violation-details',
  lifestyleOwnerAdditionalDrivingViolationsDate = 'lifestyle-owner-additional-driving-violations-date',
  lifestyleOwnerAdditionalDrivingViolationType = 'lifestyle-owner-additional-driving-violation-type',
  lifestyleOwnerAdditionalDrivingViolationKmOverSpeedLimit = 'lifestyle-owner-additional-driving-violation-km-over-speed-limit',
  lifestleOwnerAdditionalDrivingViolationsOtherDetails = 'lifestyle-owner-additional-driving-violations-other-details',

  lifestyleOwnerAdditionalDrivingViolations = 'lifestyle-owner-additional-driving-violations',
  lifestyleOwnerHasCriminalHistory = 'lifestyle-owner-criminal-history',
  lifestyleOwnerHasCriminalHistory1 = 'lifestyle-owner-criminal-history-1',
  lifestyleOwnerCriminalHistoryChoices = 'lifestyle-owner-criminal-history-choices',
  lifestyleOwnerUnderInvestigationsCircumstances = 'lifestyle-owner-under-investigations-circumstances',
  lifestyleOwnerUnderInvestigationsDate = 'lifestyle-owner-under-investigations-date',
  lifestyleOwnerUnderInvestigationsCharges = 'lifestyle-owner-under-investigations-charges',
  lifestyleOwnerTrialCircumstances = 'lifestyle-owner-trial-circumstances',
  lifestyleOwnerTrialDate = 'lifestyle-owner-trial-date',
  lifestyleOwnerTrialCharges = 'lifestyle-owner-trial-charges',
  lifestyleOwnerProbationStartDate = 'lifestyle-owner-probation-start-date',
  lifestyleOwnerProbationEndDate = 'lifestyle-owner-probation-end-date',
  lifestyleOwnerProbationCircumstances = 'lifestyle-owner-probation-circumstances',
  lifestyleOwnerProbationDate = 'lifestyle-owner-probation-date',
  lifestyleOwnerProbationCharges = 'lifestyle-owner-probation-charges',
  lifestyleOwnerProbationSentenceDetails = 'lifestyle-owner-probation-sentence-details',
  lifestyleOwnerAnyOtherOffences = 'lifestyle-owner-any-other-offences',
  lifestyleOwnerOtherOffencesCircumstances = 'lifestyle-owner-other-offences-circumstances',
  lifestyleOwnerOtherOffencesDate = 'lifestyle-owner-other-offences-date',
  lifestyleOwnerOtherOffencesCharges = 'lifestyle-owner-other-offences-charges',
  lifestyleOwnerChargedOrConvictedCircumstances = 'lifestyle-owner-charged-or-convicted-circumstances',
  lifestyleOwnerChargedOrConvictedDate = 'lifestyle-owner-charged-or-convicted-date',
  lifestyleOwnerChargedOrConvictedCharges = 'lifestyle-owner-charged-or-convicted-charges',
  lifestyleOwnerChargedOrConvictedSentenceDetails = 'lifestyle-owner-charged-or-convicted-sentence-details',
  lifestyleOwnerChargedOrConvictedAdditionalOffence = 'lifestyle-owner-charged-or-convicted-additional-offence',
  lifestyleOwnerChargedOrConvictedAdditionalOffenceCircumstances = 'lifestyle-owner-charged-or-convicted-additional-offence-circumstances',
  lifestyleOwnerChargedOrConvictedAdditionalOffenceOtherOffencesDate = 'lifestyle-owner-charged-or-convicted-additional-offence-other-offences-date',
  lifestyleOwnerChargedOrConvictedAdditionalOffenceCharges = 'lifestyle-owner-charged-or-convicted-additional-offence-charges',
  lifestyleOwnerHasTravel = 'lifestyle-owner-travel',
  lifestyleOwnerTravel12MonthsPastOrFuture = 'lifestyle-owner-travel-12-months-past-or-future',
  lifestyleOwnerTravelLast12MonthsDestination = 'lifestyle-owner-travel-last-12-months-destination',
  lifestyleOwnerTravelLast12MonthsReason = 'lifestyle-owner-travel-last-12-months-reason',
  lifestyleOwnerTravelLast12MonthsReasonOther = 'lifestyle-owner-travel-last-12-months-reason-other',
  lifestyleOwnerTravelLast12MonthsDepartureDate = 'lifestyle-owner-travel-last-12-months-departure-date',
  lifestyleOwnerTravelLast12MonthsReturnDate = 'lifestyle-owner-travel-last-12-months-return-date',
  lifestyleOwnerTravelNext12MonthsDestination = 'lifestyle-owner-travel-next-12-months-destination',
  lifestyleOwnerTravelNext12MonthsReason = 'lifestyle-owner-travel-next-12-months-reason',
  lifestyleOwnerTravelNext12MonthsReasonOther = 'lifestyle-owner-travel-next-12-months-reason-other',
  lifestyleOwnerTravelNext12MonthsDepartureDate = 'lifestyle-owner-travel-next-12-months-departure-date',
  lifestyleOwnerTravelNext12MonthsReturnDate = 'lifestyle-owner-travel-next-12-months-return-date',
  lifestyleOwnerTravelNext12MonthsUnknownDate = 'lifestyle-owner-travel-next-12-months-unknown-date',

  lifestyleOwnerTravelLast12MonthsDetails = 'lifestyle-owner-travel-last-12-months-details',
  lifestyleOwnerTravelLast12MonthsDestinationOther = 'lifestyle-owner-travel-last-12-months-destination-other',
  lifestyleOwnerTravelNext12MonthsDetails = 'lifestyle-owner-travel-next-12-months-details',
  lifestyleOwnerTravelNext12MonthsDestinationOther = 'lifestyle-owner-travel-next-12-months-destination-other',
  lifestyleSecondOwnerTravelLast12MonthsDetails = 'lifestyle-second-owner-travel-last-12-months-details',
  lifestyleSecondOwnerTravelLast12MonthsDestinationOther = 'lifestyle-second-owner-travel-last-12-months-destination-other',
  lifestyleSecondOwnerTravelNext12MonthsDetails = 'lifestyle-second-owner-travel-next-12-months-details',
  lifestyleSecondOwnerTravelNext12MonthsDestinationOther = 'lifestyle-second-owner-travel-next-12-months-destination-other',

  lifestyleOwnerPersonalMedicalQuestionnaire = 'lifestyle-owner-personal-medical-questionnaire',
  lifestyleOwnerHasFlownAsOtherThanPassenger = 'lifestyle-owner-has-flown-as-other-than-passenger',
  lifestyleOwnerParticipationInExtremeSport = 'lifestyle-owner-participation-in-extreme-sport',
  lifestyleOwnerParticipationInExtremeSportChoices = 'lifestyle-owner-participation-in-extreme-sport-choices',
  lifestyleOwnerIsTobaccoUser = 'lifestyle-owner-is-tobacco-user',
  lifestyleOwnerTobaccoUsedWithin12Months = 'lifestyle-owner-tobacco-used-within-12-months',
  lifestyleOwnerTobaccoChoices = 'lifestyle-owner-tobacco-choices',
  lifestyleOwnerTobaccoChoicesOtherIs = 'lifestyle-owner-tobacco-choices-other-is',
  lifestyleOwnerTobaccoCigarettesDailyQuantity = 'lifestyle-owner-tobacco-cigarettes-daily-quantity',

  lifestyleOwnerTobaccoMostRecentUsage = 'lifestyle-owner-tobacco-most-recent-usage',
  lifestyleOwnerTobaccoLastDateTobaccoUse = 'lifestyle-owner-tobacco-last-date-tobacco-use',
  lifestyleOwnerTobaccoBigCigarsQuantity = 'lifestyle-owner-tobacco-big-cigars-quantity',
  lifestyleOwnerTobaccoLastDateBigCigars = 'lifestyle-owner-tobacco-last-date-big-cigars',

  lifestyleSecondOwnerAlcoholUse = 'lifestyle-second-owner-alcohol-use',
  lifestyleSecondOwnerAlcoholUseFrequency = 'lifestyle-second-owner-alcohol-use-frequency',
  lifestyleSecondOwnerAlcoholUseQuantity = 'lifestyle-second-owner-alcohol-use-quantity',
  lifestyleSecondOwnerAlcoholUseHistory = 'lifestyle-second-owner-alcohol-use-history',
  lifestyleSecondOwnerAlcoholUseHistoryFrequency = 'lifestyle-second-owner-alcohol-use-history-frequency',
  lifestyleSecondOwnerAlcoholUseHistoryQuantity = 'lifestyle-second-owner-alcohol-use-history-quantity',
  lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumption = 'lifestyle-second-owner-alcohol-advised-or-cease-consumption',
  lifestyleSecondOwnerAlcoholHasTherapy = 'lifestyle-second-owner-alcohol-therapy',
  lifestyleSecondOwnerAlcoholSupportGroupMeetings = 'lifestyle-second-owner-alcohol-support-group-meetings',
  lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumptionDate = 'lifestyle-second-owner-alcohol-advised-or-cease-consumption-date',
  lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-second-owner-alcohol-advised-or-cease-consumption-professional-contact-information',
  lifestyleSecondOwnerAlcoholTherapyOrTreatmentStartDate = 'lifestyle-second-owner-alcohol-therapy-or-treatment-start-date',
  lifestyleSecondOwnerAlcoholTherapyOrTreatmentEndDate = 'lifestyle-second-owner-alcohol-therapy-or-treatment-end-date',
  lifestyleSecondOwnerAlcoholTherapyOrTreatmentType = 'lifestyle-second-owner-alcohol-therapy-or-treatment-type',
  lifestyleSecondOwnerAlcoholTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-second-owner-alcohol-therapy-or-treatment-professional-contact-information',
  lifestyleSecondOwnerAlcoholTherapyOrTreatmentAdditionalPeriod = 'lifestyle-second-owner-alcohol-therapy-or-treatment-additional-period',
  lifestyleSecondOwnerAlcoholLastDateOfTheLastTreatment = 'lifestyle-second-owner-alcohol-last-date-of-the-last-treatment',
  lifestyleSecondOwnerAlcoholSupportGroupStillAttending = 'lifestyle-second-owner-alcohol-support-group-still-attending',
  lifestyleSecondOwnerAlcoholSupportGroupStartDate = 'lifestyle-second-owner-alcohol-support-group-start-date',
  lifestyleSecondOwnerAlcoholSupportGroupEndDate = 'lifestyle-second-owner-alcohol-support-group-end-date',
  lifestyleSecondOwnerAlcoholChangeHabitConsumptionDate = 'lifestyle-second-owner-alcohol-change-habit-consumption-date',
  lifestyleSecondOwnerAlcoholChangeHabitConsumptionReason = 'lifestyle-second-owner-alcohol-change-habit-consumption-reason',

  lifestyleSecondOwnerMarijuanaUse = 'lifestyle-second-owner-marijuana-use',
  lifestyleSecondOwnerMarijuanaUseForm = 'lifestyle-second-owner-marijuana-use-form',
  lifestyleSecondOwnerMarijuanaUseFormDetails = 'lifestyle-second-owner-marijuana-use-form-details',
  lifestyleSecondOwnerMarijuanaJointConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-joint-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaJointConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-joint-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaJointConsumptionHabitsUsageDate = 'lifestyle-second-owner-marijuana-joint-consumption-habits-usage-date',
  lifestyleSecondOwnerMarijuanaJointConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-joint-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaJointConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-joint-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaJointConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-joint-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaJointConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-joint-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaJointPastUse = 'lifestyle-second-owner-marijuana-joint-past-use',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-joint-past-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-joint-past-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-joint-past-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-joint-past-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-joint-past-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-joint-past-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaJointChangeHabitConsumptionDate = 'lifestyle-second-owner-marijuana-joint-change-habit-consumption-date',
  lifestyleSecondOwnerMarijuanaJointChangeHabitConsumptionReason = 'lifestyle-second-owner-marijuana-joint-change-habit-consumption-reason',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-edible-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-edible-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsUsageDate = 'lifestyle-second-owner-marijuana-edible-consumption-habits-usage-date',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-edible-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-edible-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-edible-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-edible-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaEdiblePastUse = 'lifestyle-second-owner-marijuana-edible-past-use',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-edible-past-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-edible-past-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-edible-past-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-edible-past-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-edible-past-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-edible-past-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaEdibleChangeHabitConsumptionDate = 'lifestyle-second-owner-marijuana-edible-change-habit-consumption-date',
  lifestyleSecondOwnerMarijuanaEdibleChangeHabitConsumptionReason = 'lifestyle-second-owner-marijuana-edible-change-habit-consumption-reason',
  lifestyleSecondOwnerMarijuanaOilConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-oil-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaOilConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-oil-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaOilConsumptionHabitsUsageDate = 'lifestyle-second-owner-marijuana-oil-consumption-habits-usage-date',
  lifestyleSecondOwnerMarijuanaOilConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-oil-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaOilConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-oil-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaOilConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-oil-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaOilConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-oil-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaOilPastUse = 'lifestyle-second-owner-marijuana-oil-past-use',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-oil-past-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-oil-past-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-oil-past-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-oil-past-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-oil-past-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-oil-past-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaOilChangeHabitConsumptionDate = 'lifestyle-second-owner-marijuana-oil-change-habit-consumption-date',
  lifestyleSecondOwnerMarijuanaOilChangeHabitConsumptionReason = 'lifestyle-second-owner-marijuana-oil-change-habit-consumption-reason',
  lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-other-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-other-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsUsageDate = 'lifestyle-second-owner-marijuana-other-consumption-habits-usage-date',
  lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-other-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-other-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-other-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-other-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaOtherPastUse = 'lifestyle-second-owner-marijuana-other-past-use',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsQuantity = 'lifestyle-second-owner-marijuana-other-past-consumption-habits-quantity',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsFrequency = 'lifestyle-second-owner-marijuana-other-past-consumption-habits-frequency',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsUsageType = 'lifestyle-second-owner-marijuana-other-past-consumption-habits-usage-type',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalCondition = 'lifestyle-second-owner-marijuana-other-past-consumption-medical-condition',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalPrescribed = 'lifestyle-second-owner-marijuana-other-past-consumption-medical-prescribed',
  lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalPrescribingPhysician = 'lifestyle-second-owner-marijuana-other-past-consumption-medical-prescribing-physician',
  lifestyleSecondOwnerMarijuanaOtherChangeHabitConsumptionDate = 'lifestyle-second-owner-marijuana-other-change-habit-consumption-date',
  lifestyleSecondOwnerMarijuanaOtherChangeHabitConsumptionReason = 'lifestyle-second-owner-marijuana-other-change-habit-consumption-reason',
  lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumption = 'lifestyle-second-owner-marijuana-advised-or-cease-consumption',
  lifestyleSecondOwnerMarijuanaHasTherapy = 'lifestyle-second-owner-marijuana-therapy',
  lifestyleSecondOwnerMarijuanaSupportGroupMeetings = 'lifestyle-second-owner-marijuana-support-group-meetings',
  lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumptionDate = 'lifestyle-second-owner-marijuana-advised-or-cease-consumption-date',
  lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-second-owner-marijuana-advised-or-cease-consumption-professional-contact-information',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentStartDate = 'lifestyle-second-owner-marijuana-therapy-or-treatment-start-date',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentEndDate = 'lifestyle-second-owner-marijuana-therapy-or-treatment-end-date',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentType = 'lifestyle-second-owner-marijuana-therapy-or-treatment-type',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-second-owner-marijuana-therapy-or-treatment-professional-contact-information',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentIsOnlyPeriod = 'lifestyle-second-owner-marijuana-therapy-or-treatment-is-only-period',
  lifestyleSecondOwnerMarijuanaTherapyOrTreatmentAdditionalPeriodDetails = 'lifestyle-second-owner-marijuana-therapy-or-treatment-additionnal-period-details',
  lifestyleSecondOwnerMarijuanaSupportGroupStillAttending = 'lifestyle-second-owner-marijuana-support-group-still-attending',
  lifestyleSecondOwnerMarijuanaSupportGroupStartDate = 'lifestyle-second-owner-marijuana-support-group-start-date',
  lifestyleSecondOwnerMarijuanaSupportGroupEndDate = 'lifestyle-second-owner-marijuana-support-group-end-date',
  lifestyleSecondOwnerMarijuanaPastUseType = 'lifestyle-second-owner-marijuana-past-use-type',
  lifestyleSecondOwnerMarijuanaPastUseTypeOther = 'lifestyle-second-owner-marijuana-past-use-type-other',

  lifestyleSecondOwnerDrugsOrNarcoticsUse = 'lifestyle-second-owner-drugs-or-narcotics-use',
  lifestyleSecondOwnerDrugsOrNarcoticsUseType = 'lifestyle-second-owner-drugs-or-narcotics-use-type',
  lifestyleSecondOwnerDrugsOrNarcoticsUseFormOther = 'lifestyle-second-owner-drugs-or-narcotics-use-form-other',
  lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumption = 'lifestyle-second-owner-drugs-or-narcotics-advised-or-cease-consumption',
  lifestyleSecondOwnerDrugsOrNarcoticsHasTherapy = 'lifestyle-second-owner-drugs-or-narcotics-therapy',
  lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupMeetings = 'lifestyle-second-owner-drugs-or-narcotics-support-group-meetings',
  lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionDate = 'lifestyle-second-owner-drugs-or-narcotics-advised-or-cease-consumption-date',
  lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-second-owner-drugs-or-narcotics-advised-or-cease-consumption-professional-contact-information',
  lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentStartDate = 'lifestyle-second-owner-drugs-or-narcotics-therapy-or-treatment-start-date',
  lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentEndDate = 'lifestyle-second-owner-drugs-or-narcotics-therapy-or-treatment-end-date',
  lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentType = 'lifestyle-second-owner-drugs-or-narcotics-therapy-or-treatment-type',
  lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-second-owner-drugs-or-narcotics-therapy-or-treatment-professional-contact-information',
  lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod = 'lifestyle-second-owner-drugs-or-narcotics-therapy-or-treatment-additionnal-period',
  lifestyleSecondOwnerDrugsOrNarcoticsLastDateOfTheLastTreatment = 'lifestyle-second-owner-drugs-or-narcotics-last-date-of-the-last-treatment',
  lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupStillAttending = 'lifestyle-second-owner-drugs-or-narcotics-support-group-still-attending',
  lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupStartDate = 'lifestyle-second-owner-drugs-or-narcotics-support-group-start-date',
  lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupEndDate = 'lifestyle-second-owner-drugs-or-narcotics-support-group-end-date',

  lifestyleSecondOwnerDrugsCocaineConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-cocaine-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsCocaineConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-cocaine-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsCocaineUseStillConsume = 'lifestyle-second-owner-drugs-cocaine-use-still-consume',
  lifestyleSecondOwnerDrugsCocaineUseStartDate = 'lifestyle-second-owner-drugs-cocaine-use-start-date',
  lifestyleSecondOwnerDrugsCocaineUseEndDate = 'lifestyle-second-owner-drugs-cocaine-use-end-date',
  lifestyleSecondOwnerDrugsCocainePastUse = 'lifestyle-second-owner-drugs-cocaine-past-use',
  lifestyleSecondOwnerDrugsCocainePastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-cocaine-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsCocainePastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-cocaine-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsCocainePastUseStartDate = 'lifestyle-second-owner-drugs-cocaine-past-use-start-date',
  lifestyleSecondOwnerDrugsCocainePastUseEndDate = 'lifestyle-second-owner-drugs-cocaine-past-use-end-date',
  lifestyleSecondOwnerDrugsCocainePastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-cocaine-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsCocainePastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-cocaine-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsEcstasyConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-ecstasy-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsEcstasyConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-ecstasy-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsEcstasyUseStillConsume = 'lifestyle-second-owner-drugs-ecstasy-use-still-consume',
  lifestyleSecondOwnerDrugsEcstasyUseStartDate = 'lifestyle-second-owner-drugs-ecstasy-use-start-date',
  lifestyleSecondOwnerDrugsEcstasyUseEndDate = 'lifestyle-second-owner-drugs-ecstasy-use-end-date',
  lifestyleSecondOwnerDrugsEcstasyPastUse = 'lifestyle-second-owner-drugs-ecstasy-past-use',
  lifestyleSecondOwnerDrugsEcstasyPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-ecstasy-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsEcstasyPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-ecstasy-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsEcstasyPastUseStartDate = 'lifestyle-second-owner-drugs-ecstasy-past-use-start-date',
  lifestyleSecondOwnerDrugsEcstasyPastUseEndDate = 'lifestyle-second-owner-drugs-ecstasy-past-use-end-date',
  lifestyleSecondOwnerDrugsEcstasyPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-ecstasy-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsEcstasyPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-ecstasy-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsLsdConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-lsd-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsLsdConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-lsd-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsLsdUseStillConsume = 'lifestyle-second-owner-drugs-lsd-use-still-consume',
  lifestyleSecondOwnerDrugsLsdUseStartDate = 'lifestyle-second-owner-drugs-lsd-use-start-date',
  lifestyleSecondOwnerDrugsLsdUseEndDate = 'lifestyle-second-owner-drugs-lsd-use-end-date',
  lifestyleSecondOwnerDrugsLsdPastUse = 'lifestyle-second-owner-drugs-lsd-past-use',
  lifestyleSecondOwnerDrugsLsdPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-lsd-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsLsdPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-lsd-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsLsdPastUseStartDate = 'lifestyle-second-owner-drugs-lsd-past-use-start-date',
  lifestyleSecondOwnerDrugsLsdPastUseEndDate = 'lifestyle-second-owner-drugs-lsd-past-use-end-date',
  lifestyleSecondOwnerDrugsLsdPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-lsd-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsLsdPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-lsd-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsMagicMushroomsConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-magic-mushrooms-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsMagicMushroomsConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-magic-mushrooms-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsMagicMushroomsUseStillConsume = 'lifestyle-second-owner-drugs-magic-mushrooms-use-still-consume',
  lifestyleSecondOwnerDrugsMagicMushroomsUseStartDate = 'lifestyle-second-owner-drugs-magic-mushrooms-use-start-date',
  lifestyleSecondOwnerDrugsMagicMushroomsUseEndDate = 'lifestyle-second-owner-drugs-magic-mushrooms-use-end-date',
  lifestyleSecondOwnerDrugsMagicMushroomsPastUse = 'lifestyle-second-owner-drugs-magic-mushrooms-past-use',
  lifestyleSecondOwnerDrugsMagicMushroomsPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-magic-mushrooms-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsMagicMushroomsPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-magic-mushrooms-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsMagicMushroomsPastUseStartDate = 'lifestyle-second-owner-drugs-magic-mushrooms-past-use-start-date',
  lifestyleSecondOwnerDrugsMagicMushroomsPastUseEndDate = 'lifestyle-second-owner-drugs-magic-mushrooms-past-use-end-date',
  lifestyleSecondOwnerDrugsMagicMushroomsPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-magic-mushrooms-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsMagicMushroomsPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-magic-mushrooms-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsHeroinConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-heroin-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsHeroinConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-heroin-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsHeroinUseStillConsume = 'lifestyle-second-owner-drugs-heroin-use-still-consume',
  lifestyleSecondOwnerDrugsHeroinUseStartDate = 'lifestyle-second-owner-drugs-heroin-use-start-date',
  lifestyleSecondOwnerDrugsHeroinUseEndDate = 'lifestyle-second-owner-drugs-heroin-use-end-date',
  lifestyleSecondOwnerDrugsHeroinPastUse = 'lifestyle-second-owner-drugs-heroin-past-use',
  lifestyleSecondOwnerDrugsHeroinPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-heroin-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsHeroinPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-heroin-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsHeroinPastUseStartDate = 'lifestyle-second-owner-drugs-heroin-past-use-start-date',
  lifestyleSecondOwnerDrugsHeroinPastUseEndDate = 'lifestyle-second-owner-drugs-heroin-past-use-end-date',
  lifestyleSecondOwnerDrugsHeroinPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-heroin-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsHeroinPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-heroin-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsFentanylConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-fentanyl-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsFentanylConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-fentanyl-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsFentanylUseStillConsume = 'lifestyle-second-owner-drugs-fentanyl-use-still-consume',
  lifestyleSecondOwnerDrugsFentanylUseStartDate = 'lifestyle-second-owner-drugs-fentanyl-use-start-date',
  lifestyleSecondOwnerDrugsFentanylUseEndDate = 'lifestyle-second-owner-drugs-fentanyl-use-end-date',
  lifestyleSecondOwnerDrugsFentanylPastUse = 'lifestyle-second-owner-drugs-fentanyl-past-use',
  lifestyleSecondOwnerDrugsFentanylPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-fentanyl-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsFentanylPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-fentanyl-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsFentanylPastUseStartDate = 'lifestyle-second-owner-drugs-fentanyl-past-use-start-date',
  lifestyleSecondOwnerDrugsFentanylPastUseEndDate = 'lifestyle-second-owner-drugs-fentanyl-past-use-end-date',
  lifestyleSecondOwnerDrugsFentanylPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-fentanyl-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsFentanylPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-fentanyl-past-change-habit-consumption-reason',

  lifestyleSecondOwnerDrugsAnabolicSteroidsConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-anabolic-steroids-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsAnabolicSteroidsConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-anabolic-steroids-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsAnabolicSteroidsUseStillConsume = 'lifestyle-second-owner-drugs-anabolic-steroids-use-still-consume',
  lifestyleSecondOwnerDrugsAnabolicSteroidsUseStartDate = 'lifestyle-second-owner-drugs-anabolic-steroids-use-start-date',
  lifestyleSecondOwnerDrugsAnabolicSteroidsUseEndDate = 'lifestyle-second-owner-drugs-anabolic-steroids-use-end-date',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastUse = 'lifestyle-second-owner-drugs-anabolic-steroids-past-use',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastConsumptionHabitsQuantity = 'lifestyle-second-owner-drugs-anabolic-steroids-past-consumption-habits-quantity',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastConsumptionHabitsFrequency = 'lifestyle-second-owner-drugs-anabolic-steroids-past-consumption-habits-frequency',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastUseStartDate = 'lifestyle-second-owner-drugs-anabolic-steroids-past-use-start-date',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastUseEndDate = 'lifestyle-second-owner-drugs-anabolic-steroids-past-use-end-date',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionDate = 'lifestyle-second-owner-drugs-anabolic-steroids-past-change-habit-consumption-date',
  lifestyleSecondOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionReason = 'lifestyle-second-owner-drugs-anabolic-steroids-past-change-habit-consumption-reason',

  lifestyleSecondOwnerOtherDrugsOrNarcoticsConsumptionHabitsQuantity = 'lifestyle-second-owner-other-drugs-or-narcotics-consumption-habits-quantity',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsConsumptionHabitsFrequency = 'lifestyle-second-owner-other-drugs-or-narcotics-consumption-habits-frequency',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsUseStillConsume = 'lifestyle-second-owner-other-drugs-or-narcotics-use-still-consume',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsUseStartDate = 'lifestyle-second-owner-other-drugs-or-narcotics-use-start-date',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsUseEndDate = 'lifestyle-second-owner-other-drugs-or-narcotics-use-end-date',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionDate = 'lifestyle-second-owner-other-drugs-or-narcotics-change-habit-consumption-date',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionReason = 'lifestyle-second-owner-other-drugs-or-narcotics-change-habit-consumption-reason',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsQuantity = 'lifestyle-second-owner-other-drugs-or-narcotics-past-consumption-habits-quantity',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsFrequency = 'lifestyle-second-owner-other-drugs-or-narcotics-past-consumption-habits-frequency',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsPastUseStartDate = 'lifestyle-second-owner-other-drugs-or-narcotics-past-use-start-date',
  lifestyleSecondOwnerOtherDrugsOrNarcoticsPastUseEndDate = 'lifestyle-second-owner-other-drugs-or-narcotics-past-use-end-date',
  lifestyleSecondOwnerDrivingHasViolations = 'lifestyle-second-owner-driving-has-violations',
  lifestyleSecondOwnerDrivingHasViolationsInPast3Years = 'lifestyle-second-owner-driving-has-violations-in-past-3-years',
  lifestyleSecondOwnerDrivingViolationsReasons = 'lifestyle-second-owner-driving-violations-reasons',
  lifestyleSecondOwner1stViolationDate = 'lifestyle-second-owner-1st-violation-date',
  lifestyleSecondOwnerMovingViolationDetails = 'lifestyle-second-owner-moving-violation-details',
  lifestyleSecondOwner1stSpeedingLimitDetails = 'lifestyle-second-owner-1st-speeding-limit-details',
  lifestyleSecondOwner1stDrivingViolationsOthersDetails = 'lifestyle-second-owner-1st-driving-violations-others-details',
  lifestyleSecondOwner2ndViolationDate = 'lifestyle-second-owner-2nd-violation-date',
  lifestyleSecondOwner2ndDrivingViolationsChoices = 'lifestyle-second-owner-2nd-driving-violations-choices',
  lifestyleSecondOwner2ndSpeedingLimitDetails = 'lifestyle-second-owner-2nd-speeding-limit-details',
  lifestyleSecondOwner2ndDrivingViolationsOthersDetails = 'lifestyle-second-owner-2nd-driving-violations-others-details',
  lifestyleSecondOwnerAdditionalDrivingViolations = 'lifestyle-second-owner-additional-driving-violations',
  lifestyleSecondOwnerHasCriminalHistory = 'lifestyle-second-owner-criminal-history',
  lifestyleSecondOwnerHasCriminalHistory1 = 'lifestyle-second-owner-criminal-history-1',
  lifestyleSecondOwnerCriminalHistoryChoices = 'lifestyle-second-owner-criminal-history-choices',
  lifestyleSecondOwnerUnderInvestigationsCircumstances = 'lifestyle-second-owner-under-investigations-circumstances',
  lifestyleSecondOwnerUnderInvestigationsDate = 'lifestyle-second-owner-under-investigations-date',
  lifestyleSecondOwnerUnderInvestigationsCharges = 'lifestyle-second-owner-under-investigations-charges',
  lifestyleSecondOwnerTrialCircumstances = 'lifestyle-second-owner-trial-circumstances',
  lifestyleSecondOwnerTrialDate = 'lifestyle-second-owner-trial-date',
  lifestyleSecondOwnerTrialCharges = 'lifestyle-second-owner-trial-charges',
  lifestyleSecondOwnerProbationStartDate = 'lifestyle-second-owner-probation-start-date',
  lifestyleSecondOwnerProbationEndDate = 'lifestyle-second-owner-probation-end-date',
  lifestyleSecondOwnerProbationCircumstances = 'lifestyle-second-owner-probation-circumstances',
  lifestyleSecondOwnerProbationDate = 'lifestyle-second-owner-probation-date',
  lifestyleSecondOwnerProbationCharges = 'lifestyle-second-owner-probation-charges',
  lifestyleSecondOwnerProbationSentenceDetails = 'lifestyle-second-owner-probation-sentence-details',
  lifestyleSecondOwnerAnyOtherOffences = 'lifestyle-second-owner-any-other-offences',
  lifestyleSecondOwnerOtherOffencesCircumstances = 'lifestyle-second-owner-other-offences-circumstances',
  lifestyleSecondOwnerOtherOffencesDate = 'lifestyle-second-owner-other-offences-date',
  lifestyleSecondOwnerOtherOffencesCharges = 'lifestyle-second-owner-other-offences-charges',
  lifestyleSecondOwnerChargedOrConvictedCircumstances = 'lifestyle-second-owner-charged-or-convicted-circumstances',
  lifestyleSecondOwnerChargedOrConvictedDate = 'lifestyle-second-owner-charged-or-convicted-date',
  lifestyleSecondOwnerChargedOrConvictedCharges = 'lifestyle-second-owner-charged-or-convicted-charges',
  lifestyleSecondOwnerChargedOrConvictedSentenceDetails = 'lifestyle-second-owner-charged-or-convicted-sentence-details',
  lifestyleSecondOwnerChargedOrConvictedAdditionalOffence = 'lifestyle-second-owner-charged-or-convicted-additional-offence',
  lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceCircumstances = 'lifestyle-second-owner-charged-or-convicted-additional-offence-circumstances',
  lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceOtherOffencesDate = 'lifestyle-second-owner-charged-or-convicted-additional-offence-other-offences-date',
  lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceCharges = 'lifestyle-second-owner-charged-or-convicted-additional-offence-charges',
  lifestyleSecondOwnerHasTravel = 'lifestyle-second-owner-travel',
  lifestyleSecondOwnerTravel12MonthsPastOrFuture = 'lifestyle-second-owner-travel-12-months-past-or-future',
  lifestyleSecondOwnerTravelLast12MonthsDestination = 'lifestyle-second-owner-travel-last-12-months-destination',
  lifestyleSecondOwnerTravelLast12MonthsReason = 'lifestyle-second-owner-travel-last-12-months-reason',
  lifestyleSecondOwnerTravelLast12MonthsReasonOther = 'lifestyle-second-owner-travel-last-12-months-reason-other',
  lifestyleSecondOwnerTravelLast12MonthsDepartureDate = 'lifestyle-second-owner-travel-last-12-months-departure-date',
  lifestyleSecondOwnerTravelLast12MonthsReturnDate = 'lifestyle-second-owner-travel-last-12-months-return-date',
  lifestyleSecondOwnerTravelNext12MonthsDestination = 'lifestyle-second-owner-travel-next-12-months-destination',
  lifestyleSecondOwnerTravelNext12MonthsReason = 'lifestyle-second-owner-travel-next-12-months-reason',
  lifestyleSecondOwnerTravelNext12MonthsReasonOther = 'lifestyle-second-owner-travel-next-12-months-reason-other',
  lifestyleSecondOwnerTravelNext12MonthsDepartureDate = 'lifestyle-second-owner-travel-next-12-months-departure-date',
  lifestyleSecondOwnerTravelNext12MonthsReturnDate = 'lifestyle-second-owner-travel-next-12-months-return-date',
  lifestyleSecondOwnerTravelNext12MonthsUnknownDate = 'lifestyle-second-owner-travel-next-12-months-unknown-date',

  lifestyleSecondOwnerPersonalMedicalQuestionnaire = 'lifestyle-second-owner-personal-medical-questionnaire',
  lifestyleSecondOwnerHasFlownAsOtherThanPassenger = 'lifestyle-second-owner-has-flown-as-other-than-passenger',
  lifestyleSecondOwnerParticipationInExtremeSport = 'lifestyle-second-owner-participation-in-extreme-sport',
  lifestyleSecondOwnerIsTobaccoUser = 'lifestyle-second-owner-is-tobacco-user',
  lifestyleSecondOwnerTobaccoUsedWithin12Months = 'lifestyle-second-owner-tobacco-used-within-12-months',
  lifestyleSecondOwnerTobaccoChoices = 'lifestyle-second-owner-tobacco-choices',
  lifestyleSecondOwnerTobaccoChoicesOtherIs = 'lifestyle-second-owner-tobacco-choices-other-is',
  lifestyleSecondOwnerTobaccoCigarettesDailyQuantity = 'lifestyle-second-owner-tobacco-cigarettes-daily-quantity',
  lifestyleSecondOwnerTobaccoMostRecentUsage = 'lifestyle-second-owner-tobacco-most-recent-usage',
  lifestyleSecondOwnerTobaccoLastDateTobaccoUse = 'lifestyle-second-owner-tobacco-last-date-tobacco-use',
  lifestyleSecondOwnerTobaccoBigCigarsQuantity = 'lifestyle-second-owner-tobacco-big-cigars-quantity',
  lifestyleSecondOwnerTobaccoLastDateBigCigars = 'lifestyle-second-owner-tobacco-last-date-big-cigars',
  lifestyleSecondOwnerParticipationInExtremeSportChoices = 'lifestyle-second-owner-participation-in-extreme-sport-choices',

  lifestyleSecondOwnerImpairedDrivingOffence = 'lifestyle-second-owner-impaired-driving-offence',
  lifestyleSecondOwnerImpairedDrivingLastOffenceDate = 'lifestyle-second-owner-impaired-driving-last-offence-date',
  lifestyleSecondOwnerImpairedDrivingLicenseSuspensionDate = 'lifestyle-second-owner-impaired-driving-license-suspension-date',
  lifestyleSecondOwnerImpairedDrivingLicenseRecoveryDate = 'lifestyle-second-owner-impaired-driving-license-recovery-date',
  lifestyleSecondOwnerImpairedDrivingHasIgnitionInterlockDevice = 'lifestyle-second-owner-impaired-driving-has-ignition-interlock-device',
  lifestyleSecondOwnerImpairedDrivingWithSuspendedLicense = 'lifestyle-second-owner-impaired-driving-with-suspended-license',
  lifestyleSecondOwnerImpairedDrivingAnyOtherImpairedDrivingCharges = 'lifestyle-second-owner-impaired-driving-any-other-impaired-driving-charges',
  lifestyleSecondOwnerImpairedDrivingAnyOtherImpairedDrivingChargesDates = 'lifestyle-second-owner-impaired-driving-any-other-impaired-driving-charges-dates',
  lifestyleSecondOwnerImpairedDrivingIgnitionInterlockDeviceStartDate = 'lifestyle-second-owner-impaired-driving-ignition-interlock-device-start-date',
  lifestyleSecondOwnerImpairedDrivingIgnitionInterlockDeviceEndDate = 'lifestyle-second-owner-impaired-driving-ignition-interlock-device-end-date',

  lifestyleSecondOwnerLicenseSuspendedOtherReason = 'lifestyle-second-owner-license-suspended-other-reason',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonReasons = 'lifestyle-second-owner-license-suspended-other-reason-reasons',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonMedicalReasonDetails = 'lifestyle-second-owner-license-suspended-other-reason-medical-reason-details',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonOtherDetails = 'lifestyle-second-owner-license-suspended-other-reason-other-details',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonSuspensionDate = 'lifestyle-second-owner-license-suspended-other-reason-suspension-date',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonRecoveryDate = 'lifestyle-second-owner-license-suspended-other-reason-recovery-date',
  lifestyleSecondOwnerLicenseSuspendedOtherReasonDroveWithSuspendedLicense = 'lifestyle-second-owner-license-suspended-other-reason-drove-with-suspended-license',

  lifestyleSecondOwnerAdditionalDrivingViolationDetails = 'lifestyle-second-owner-additional-driving-violation-details',
  lifestyleSecondOwnerAdditionalDrivingViolationsDate = 'lifestyle-second-owner-additional-driving-violations-date',
  lifestyleSecondOwnerAdditionalDrivingViolationType = 'lifestyle-second-owner-additional-driving-violation-type',
  lifestyleSecondOwnerAdditionalDrivingViolationKmOverSpeedLimit = 'lifestyle-second-owner-additional-driving-violation-km-over-speed-limit',
  lifestyleSecondOwnerAdditionalDrivingViolationsOtherDetails = 'lifestyle-second-owner-additional-driving-violations-other-details',

  consentPayment = 'consent-payment',
  consentMarketingCommunication = 'consent-marketing-communication',
  consentCreditRating = 'consent-credit-rating',
  consentGeneral = 'consent-general',
  consentMibPrenotice = 'consent-mib-prenotice',
  consentMib = 'consent-mib',
  consentMedicalInformation = 'consent-medical-information',
  consentMibDisclosure = 'consent-mib-disclosure',
  consentPersonalInfo = 'consent-personal-info',
  consentDeclarations = 'consent-declarations',
  consentPreviousInformation = 'consent-previous-information',
  consentPersonalFilesAndInformation = 'consent-personal-files-and-information',
  consentIdentityVerification = 'consent-identity-verification',

  insuredIdentityVerification = 'insured-identity-verification',
  insuredIdentityVerificationDocumentType = 'insured-identity-verification-document-type',
  insuredIdentityVerificationDocumentTypeOther = 'insured-identity-verification-document-type-other',
  insuredIdentityVerificationDocumentLastName = 'insured-identity-verification-document-last-name',
  insuredIdentityVerificationDocumentFirstName = 'insured-identity-verification-document-first-name',
  insuredIdentityVerificationDocumentNumber = 'insured-identity-verification-document-number',
  insuredIdentityVerificationDocumentJurisdiction = 'insured-identity-verification-document-jurisdiction',
  insuredIdentityVerificationDocumentExpiryDate = 'insured-identity-verification-document-expiry-date',
  insuredIdentityVerificationLastName = 'insured-identity-verification-last-name',
  insuredIdentityVerificationFirstName = 'insured-identity-verification-first-name',
  insuredIdentityVerificationDateOfBirth = 'insured-identity-verification-date-of-birth',
  insuredIdentityVerificationAddress = 'insured-identity-verification-address',
  insuredIdentityVerificationApartment = 'insured-identity-verification-apartment',
  insuredIdentityVerificationCity = 'insured-identity-verification-city',
  insuredIdentityVerificationProvince = 'insured-identity-verification-province',
  insuredIdentityVerificationPostalCode = 'insured-identity-verification-postal-code',
  insuredIdentityVerificationDualProcessReason = 'insured-identity-verification-dual-process-reason',
  insuredIdentityVerificationDualProcessReasonOther = 'insured-identity-verification-dual-process-reason-other',
  insuredIdentityVerificationDualProcessNameAddressType = 'insured-identity-verification-dual-process-name-address-type',
  insuredIdentityVerificationDualProcessNameAddressIssuingAuthority = 'insured-identity-verification-dual-process-name-address-issuing-authority',
  insuredIdentityVerificationDualProcessNameAddressIssuingJurisdiction = 'insured-identity-verification-dual-process-name-address-issuing-jurisdiction',
  insuredIdentityVerificationDualProcessNameAddressReferenceNumber = 'insured-identity-verification-dual-process-name-address-reference-number',
  insuredIdentityVerificationDualProcessNameAddressDate = 'insured-identity-verification-dual-process-name-address-date',
  insuredIdentityVerificationDualProcessNameBirthDateType = 'insured-identity-verification-dual-process-name-birth-date-type',
  insuredIdentityVerificationDualProcessNameBirthDateIssuingAuthority = 'insured-identity-verification-dual-process-name-birth-date-issuing-authority',
  insuredIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction = 'insured-identity-verification-dual-process-name-birth-date-issuing-jurisdiction',
  insuredIdentityVerificationDualProcessNameBirthDateReferenceNumber = 'insured-identity-verification-dual-process-name-birth-date-reference-number',
  insuredIdentityVerificationDualProcessNameBirthDateDate = 'insured-identity-verification-dual-process-name-birth-date-date',

  padBranchNumber = 'pad-branch-number',
  padFinancialInstitutionNumber = 'pad-financial-institution-number',
  padAccountNumber = 'pad-account-number',
  padConsentPayment = 'pad-consent-payment',
  padConsentElectronicSignature = 'pad-consent-electronic-signature',

  contractCorrespondenceLanguage = 'contract-correspondence-language',

  alcoholHabit = 'alcohol-habit',
  drinkBeer = 'drink-beer',
  beerQuantity = 'beer-quantity',
  beerDate = 'beer-date',
  beerDrinkPast = 'beer-drink-past',
  beerPastQuantity = 'beer-past-quantity',
  beerPastDate = 'beer-past-date',
  beerReducedReason = 'beer-reduced-reason',
  drinkWine = 'drink-wine',
  wineQuantity = 'wine-quantity',
  wineDate = 'wine-date',
  wineDrinkPast = 'wine-drink-past',
  winePastQuantity = 'wine-past-quantity',
  winePastDate = 'wine-past-date',
  wineReducedReason = 'wine-reduced-reason',
  drinkLiquor = 'drink-liquor',
  liquorQuantity = 'liquor-quantity',
  liquorDate = 'liquor-date',
  liquorDrinkPast = 'liquor-drink-past',
  liquorPastQuantity = 'liquor-past-quantity',
  lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumption = 'lifestyle-insured-alcohol-has-been-advised-reduce-or-cease-consumption',
  lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumptionDate = 'lifestyle-insured-alcohol-has-been-advised-reduce-or-cease-consumption-date',
  lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-insured-alcohol-has-been-advised-reduce-or-cease-consumption-professional-contact-information',
  liquorPastDate = 'liquor-past-date',
  liquorReducedReason = 'liquor-reduced-reason',
  receivedMedicalTreatmentForAlcohol = 'received-medical-treatment-for-alcohol',
  receivedMedicalTreatmentForAlcoholDetails = 'received-medical-treatment-for-alcohol-details',
  memberOfAlcoholicsAnonymous = 'member-of-alcoholics-anonymous',
  alcoholicsAnonymousMeetingDateFirstAttended = 'alcoholics-anonymous-meeting-date-first-attended',
  alcoholicsAnonymousMeetingDateLastAttended = 'alcoholics-anonymous-meeting-date-last-attended',
  alcoholicsAnonymousMeetingFrequency = 'alcoholics-anonymous-meeting-frequency',
  lostJobDueToAlcohol = 'lost-job-due-to-alcohol',
  lostJobDueToAlcoholDetails = 'lost-job-due-to-alcohol-details',
  drivingUnderInfluenceDetails = 'driving-under-influence-details',
  drugsIllicit = 'drugs-illicit',
  alcoholUseAdditionalInformation = 'alcohol-use-additional-information',

  retirementDate = 'retirement-date',
  retirementInsuredAnnualIncome = 'retirement-insured-annual-income',
  retirementCurrentFinancialHealthStatus = 'retirement-current-financial-health-status',
  retirementIsCurrentlySaving = 'retirement-is-currently-saving',
  retirementSavingsObstacles = 'retirement-savings-obstacles',
  retirementSavingsGoals = 'retirement-savings-goals',
  retirementSavingsPreference = 'retirement-savings-preference',

  insuredCurrentAge = 'insured-current-age',
  insuredMainBeneficiariesExist = 'insured-main-beneficiaries-exist',
  insuredMainBeneficiariesRecipients = 'insured-main-beneficiaries-recipients',
  insuredMainBeneficiariesRevocableWarning = 'insured-main-beneficiaries-revocable-warning',
  insuredAddBeneficiary = 'insured-add-beneficiary',
  insuredMainBeneficiaries = 'insured-main-beneficiaries',
  insuredAddMainBeneficiary = 'insured-add-main-beneficiary',
  insuredMainBeneficiariesShare = 'insured-main-beneficiaries-share',
  insuredMainBeneficiariesSurrogateId = 'insured-main-beneficiaries-surrogate-id',
  insuredMainBeneficiariesFirstName = 'insured-main-beneficiaries-first-name',
  insuredMainBeneficiariesLastName = 'insured-main-beneficiaries-last-name',
  insuredMainBeneficiariesBirthDate = 'insured-main-beneficiaries-birth-date',
  insuredMainBeneficiariesRelation = 'insured-main-beneficiaries-relation',
  insuredMainBeneficiariesRelationOther = 'insured-main-beneficiaries-relation-other',
  insuredMainBeneficiariesRevocable = 'insured-main-beneficiaries-revocable',
  insuredMainBeneficiariesAddressAutocomplete = 'insured-main-beneficiaries-address-autocomplete',
  insuredMainBeneficiariesAddress = 'insured-main-beneficiaries-address',
  insuredMainBeneficiariesAddress2 = 'insured-main-beneficiaries-address-2',
  insuredMainBeneficiariesCity = 'insured-main-beneficiaries-city',
  insuredMainBeneficiariesProvinceOrState = 'insured-main-beneficiaries-province-or-state',
  insuredMainBeneficiariesPostalCode = 'insured-main-beneficiaries-postal-code',
  insuredMainBeneficiariesEmail = 'insured-main-beneficiaries-email',
  insuredMainBeneficiariesPhone = 'insured-main-beneficiaries-phone',
  insuredMainBeneficiariesType = 'insured-main-beneficiaries-type',
  insuredMainBeneficiariesMiddleName = 'insured-main-beneficiaries-middle-name',
  insuredMainBeneficiariesGender = 'insured-main-beneficiaries-gender',
  insuredMainBeneficiariesPhoneType = 'insured-main-beneficiaries-phone-type',
  insuredMainBeneficiariesAlternatePhone = 'insured-main-beneficiaries-alternate-phone',
  insuredMainBeneficiariesAlternatePhoneType = 'insured-main-beneficiaries-alternate-phone-type',
  insuredMainBeneficiariesSSN = 'insured-main-beneficiaries-ssn',
  insuredMainBeneficiariesTIN = 'insured-main-beneficiaries-tin',
  insuredMainBeneficiariesTrustName = 'insured-main-beneficiaries-trust-name',
  insuredMainBeneficiariesTrustDate = 'insured-main-beneficiaries-trust-date',
  insuredMainBeneficiariesEntityName = 'insured-main-beneficiaries-entity-name',
  insuredMainBeneficiariesTrustee = 'insured-main-beneficiaries-trustee',
  insuredMainBeneficiariesOfficer = 'insured-main-beneficiaries-officer',
  insuredMainBeneficiariesShareCriticalIllnessInsurance = 'insured-main-beneficiaries-share-critical-illness-insurance',
  insuredMainBeneficiariesShareCriticalIllnessRider = 'insured-main-beneficiaries-share-critical-illness-rider',
  insuredMainBeneficiariesShareReturnOfPremiumAtDeath = 'insured-main-beneficiaries-share-return-of-premium-at-death',
  insuredMainBeneficiariesShareReturnOfPremiumAtExpiry = 'insured-main-beneficiaries-share-return-of-premium-at-expiry',
  insuredMainBeneficiariesShareReturnOfPremiumAtCancellation = 'insured-main-beneficiaries-share-return-of-premium-at-cancellation',
  insuredContingentBeneficiariesExist = 'insured-contingent-beneficiaries-exist',
  insuredAddContingentBeneficiary = 'insured-add-contingent-beneficiary',
  insuredContingentBeneficiaries = 'insured-contingent-beneficiaries',
  insuredContingentBeneficiariesSurrogateId = 'insured-contingent-beneficiaries-surrogate-id',
  insuredContingentBeneficiariesRevocableWarning = 'insured-contingent-beneficiaries-revocable-warning',
  insuredContingentBeneficiariesRecipients = 'insured-contingent-beneficiaries-recipients',
  insuredContingentBeneficiariesShare = 'insured-contingent-beneficiaries-share',
  insuredContingentBeneficiariesFirstName = 'insured-contingent-beneficiaries-first-name',
  insuredContingentBeneficiariesLastName = 'insured-contingent-beneficiaries-last-name',
  insuredContingentBeneficiariesBirthDate = 'insured-contingent-beneficiaries-birth-date',
  insuredContingentBeneficiariesRelation = 'insured-contingent-beneficiaries-relation',
  insuredContingentBeneficiariesRelationOther = 'insured-contingent-beneficiaries-relation-other',
  insuredContingentBeneficiariesRevocable = 'insured-contingent-beneficiaries-revocable',
  insuredContingentBeneficiariesAddressAutocomplete = 'insured-contingent-beneficiaries-address-autocomplete',
  insuredContingentBeneficiariesAddress = 'insured-contingent-beneficiaries-address',
  insuredContingentBeneficiariesAddress2 = 'insured-contingent-beneficiaries-address-2',
  insuredContingentBeneficiariesCity = 'insured-contingent-beneficiaries-city',
  insuredContingentBeneficiariesProvinceOrState = 'insured-contingent-beneficiaries-province-or-state',
  insuredContingentBeneficiariesPostalCode = 'insured-contingent-beneficiaries-postal-code',
  insuredContingentBeneficiariesEmail = 'insured-contingent-beneficiaries-email',
  insuredContingentBeneficiariesPhone = 'insured-contingent-beneficiaries-phone',
  insuredContingentBeneficiariesType = 'insured-contingent-beneficiaries-type',
  insuredContingentBeneficiariesMiddleName = 'insured-contingent-beneficiaries-middle-name',
  insuredContingentBeneficiariesGender = 'insured-contingent-beneficiaries-gender',
  insuredContingentBeneficiariesPhoneType = 'insured-contingent-beneficiaries-phone-type',
  insuredContingentBeneficiariesAlternatePhone = 'insured-contingent-beneficiaries-alternate-phone',
  insuredContingentBeneficiariesAlternatePhoneType = 'insured-contingent-beneficiaries-alternate-phone-type',
  insuredContingentBeneficiariesSSN = 'insured-contingent-beneficiaries-ssn',
  insuredContingentBeneficiariesTIN = 'insured-contingent-beneficiaries-tin',
  insuredContingentBeneficiariesTrustName = 'insured-contingent-beneficiaries-trust-name',
  insuredContingentBeneficiariesTrustDate = 'insured-contingent-beneficiaries-trust-date',
  insuredContingentBeneficiariesEntityName = 'insured-contingent-beneficiaries-entity-name',
  insuredContingentBeneficiariesTrustee = 'insured-contingent-beneficiaries-trustee',
  insuredContingentBeneficiariesOfficer = 'insured-contingent-beneficiaries-officer',
  trusteeExists = 'trustee-exists',
  trustees = 'trustees',
  trusteeFirstName = 'trustee-first-name',
  trusteeLastName = 'trustee-last-name',
  trusteeAddress = 'trustee-address',
  trusteeCity = 'trustee-city',
  trusteeProvince = 'trustee-province',
  trusteePostalCode = 'trustee-postal-code',
  trusteeEmail = 'trustee-email',
  trusteePhoneNumber = 'trustee-phone-number',
  trusteeRelationToInsured = 'trustee-relation-to-insured',
  trusteeRelationToInsuredOther = 'trustee-relation-to-insured-other',
  trusteeDateOfBirth = 'trustee-date-of-birth',
  trusteeType = 'trustee-type',
  trusteeMiddleName = 'trustee-middle-name',
  trusteeGender = 'trustee-gender',
  trusteePhoneType = 'trustee-phone-type',
  trusteeAlternatePhone = 'trustee-alternate-phone',
  trusteeAlternatePhoneType = 'trustee-alternate-phone-type',
  trusteeSSN = 'trustee-ssn',
  trusteeTIN = 'trustee-tin',
  trusteeBenefitsPayableOption = 'trustee-benefits-payable-option',
  trusteeBenefitsPayableAtAge = 'trustee-benefits-payable-at-age',

  //cancelling and replacement
  externalCancellationReplacement = 'external-cancellation-replacement',
  internalCancellationReplacementComplete = 'internal-cancellation-replacement-complete',
  internalCancellationReplacementPartial = 'internal-cancellation-replacement-partial',
  cancelledPolicyNumber = 'cancelled-policy-number',
  cancelledCoverage = 'cancelled-coverage',

  ownerIs = 'owner-is',
  ownerIsTheInsured = 'owner-is-the-insured',
  ownerIsTheLegalGuardian = 'owner-is-the-legal-guardian',
  ownerType = 'owner-type',
  ownerFirstName = 'owner-first-name',
  ownerMiddleName = 'owner-middle-name',
  ownerLastName = 'owner-last-name',
  ownerGender = 'owner-gender',
  ownerAddressStreet = 'owner-address-street',
  ownerAddressApartmentNumber = 'owner-address-apartment-number',
  ownerAddressCity = 'owner-address-city',
  ownerAddressCountry = 'owner-address-country',
  ownerAddressProvinceOrState = 'owner-address-province-or-state',
  ownerAddressPostalCode = 'owner-address-postal-code',
  ownerPrimaryPhoneNumber = 'owner-primary-phone-number',
  ownerPrimaryPhoneNumberType = 'owner-primary-phone-number-type',
  ownerPrimaryPhoneNumberExtension = 'owner-primary-phone-number-extension',
  ownerAlternatePhoneNumber = 'owner-alternate-phone-number',
  ownerAlternatePhoneNumberType = 'owner-alternate-phone-number-type',
  ownerAlternatePhoneNumberExtension = 'owner-alternate-phone-number-extension',
  ownerAlternatePhoneNumberTwo = 'owner-alternate-phone-number-two',
  ownerAlternatePhoneNumberTypeTwo = 'owner-alternate-phone-number-type-two',
  ownerAlternatePhoneNumberExtensionTwo = 'owner-alternate-phone-number-extension-two',
  ownerEmail = 'owner-email',
  ownerSSN = 'owner-ssn',
  ownerDateOfBirth = 'owner-date-of-birth',
  ownerMaritalStatus = 'owner-marital-status',
  ownerEmploymentStatus = 'owner-employment-status',
  ownerEmployerCompanyName = 'owner-employer-company-name',
  ownerOccupation = 'owner-occupation',
  ownerOccupationOtherIs = 'owner-occupation-other-is',
  ownerOccupationInCategory = 'owner-occupation-in-category',

  ownerBirthCountry = 'owner-birth-country',
  ownerBirthCountryOther = 'owner-birth-country-other',
  ownerBirthProvinceOrState = 'owner-birth-province-or-state',
  ownerBirthCity = 'owner-birth-city',
  ownerSignatureCity = 'owner-signature-city',
  ownerSignatureState = 'owner-signature-state',
  ownerSignatureDate = 'owner-signature-date',
  ownerMiddleNameInitial = 'owner-middle-name-initial',
  ownerRelationToProposedInsured = 'owner-relation-to-proposed-insured',
  ownerRelationToProposedInsuredOtherIs = 'owner-relationship-to-proposed-insured-other-is',
  ownerOtherRelationToProposedInsured = 'owner-other-relation-to-proposed-insured',

  ownerIsUnitedStatesCitizen = 'owner-is-united-states-citizen',
  ownerNumberOfYearsInUnitedStates = 'owner-number-of-years-in-united-states',
  ownerIdVerificationStatus = 'owner-id-verification-status',
  ownerHasDriversLicense = 'owner-has-drivers-license',
  ownerOtherIdentificationDocument = 'owner-other-identification-document',
  ownerIdentificationNumber = 'owner-identification-number',
  ownerIdentificationStateIssued = 'owner-identification-state-issued',
  ownerIdentificationIssueDate = 'owner-identification-issue-date',
  ownerIdentificationExpiryDate = 'owner-identification-expiry-date',
  ownerIdentificationDocumentIssuingAuthority = 'owner-identification-document-issuing-authority',
  ownerTrustName = 'owner-trust-name',
  ownerTrustDate = 'owner-trust-date',
  ownerEntityName = 'owner-entity-name',
  ownerTrustOrEntityDateOfBirth = 'owner-trust-or-entity-date-of-birth',
  ownerTrustOrEntityOccupation = 'owner-trust-or-entity-occupation',
  ownerSin = 'owner-sin',
  ownerTIN = 'owner-tin',
  ownerResidentOtherCountry = 'owner-resident-other-country',
  ownerOtherCountry = 'owner-other-country',
  ownerOtherCountryTin = 'owner-other-country-tin',
  ownerTrusteeName = 'owner-trustee-name',
  ownerOfficerName = 'owner-officer-name',
  ownerThirdPartyLastName = 'owner-third-party-last-name',
  ownerThirdPartyFirstName = 'owner-third-party-first-name',
  ownerThirdPartyDateOfBirth = 'owner-third-party-date-of-birth',
  ownerThirdPartyOccupation = 'owner-third-party-occupation',
  ownerThirdPartyOccupationOtherIs = 'owner-third-party-occupation-other-is',

  ownerThirdPartyRelationship = 'owner-third-party-relationship',
  ownerThirdPartyRelationshipOther = 'owner-third-party-relationship-other',
  ownerThirdPartyAddress = 'owner-third-party-address',
  ownerThirdPartyApartment = 'owner-third-party-apartment',
  ownerThirdPartyCity = 'owner-third-party-city',
  ownerThirdPartyCountry = 'owner-third-party-country',
  ownerThirdPartyProvince = 'owner-third-party-province',
  ownerThirdPartyPostalCode = 'owner-third-party-postal-code',
  ownerActsForThirdParty = 'owner-acts-for-third-party',
  ownerThirdPartyDetermination = 'owner-third-party-determination',
  ownerTrustOrEntityAddressStreet = 'owner-trust-or-entity-address-street',
  ownerTrustOrEntityAddressApartmentNumber = 'owner-trust-or-entity-address-apartment-number',
  ownerTrustOrEntityAddressCity = 'owner-trust-or-entity-address-city',
  ownerTrustOrEntityAddressCountry = 'owner-trust-or-entity-address-country',
  ownerTrustOrEntityAddressProvinceOrState = 'owner-trust-or-entity-address-province-or-state',
  ownerTrustOrEntityAddressPostalCode = 'owner-trust-or-entity-address-postal-code',
  ownerThirdPartyPhoneType = 'owner-third-party-phone-type',
  ownerThirdPartyPhoneNumber = 'owner-third-party-phone-number',
  ownerThirdPartyPhoneExtension = 'owner-third-party-phone-extension',
  ownerTrustOrEntityPrimaryPhoneNumber = 'owner-trust-or-entity-primary-phone-number',
  ownerTrustOrEntityPrimaryPhoneType = 'owner-trust-or-entity-primary-phone-type',
  ownerTrustOrEntityAlternatePhoneNumber = 'owner-trust-or-entity-alternate-phone-number',
  ownerTrustOrEntityAlternatePhoneType = 'owner-trust-or-entity-alternate-phone-type',
  ownerTrustOrEntityEmail = 'owner-trust-or-entity-email',
  ownerTrustOrEntityRelationToInsured = 'owner-trust-or-entity-relation-to-insured',
  ownerOtherTrustOrEntityRelationToInsured = 'owner-other-trust-or-entity-relation-to-insured',
  ownerPersonalizedOffersAndAdviceConsent = 'owner-personalized-offers-and-advice-consent',

  ownerIdentityVerification = 'owner-identity-verification',
  ownerIdentityVerificationDocumentType = 'owner-identity-verification-document-type',
  ownerIdentityVerificationDocumentTypeOther = 'owner-identity-verification-document-type-other',
  ownerIdentityVerificationDocumentLastName = 'owner-identity-verification-document-last-name',
  ownerIdentityVerificationDocumentFirstName = 'owner-identity-verification-document-first-name',
  ownerIdentityVerificationDocumentNumber = 'owner-identity-verification-document-number',
  ownerIdentityVerificationDocumentJurisdiction = 'owner-identity-verification-document-jurisdiction',
  ownerIdentityVerificationDocumentExpiryDate = 'owner-identity-verification-document-expiry-date',
  ownerIdentityVerificationDualProcessReason = 'owner-identity-verification-dual-process-reason',
  ownerIdentityVerificationDualProcessReasonOther = 'owner-identity-verification-dual-process-reason-other',
  ownerIdentityVerificationDualProcessNameAddressDocumentType = 'owner-identity-verification-dual-process-name-address-document-type',
  ownerIdentityVerificationDualProcessNameAddressDocumentIssuingAuthority = 'owner-identity-verification-dual-process-name-address-document-issuing-authority',
  ownerIdentityVerificationDualProcessNameAddressDocumentIssuingJurisdiction = 'owner-identity-verification-dual-process-name-address-document-issuing-jurisdiction',
  ownerIdentityVerificationDualProcessNameAddressDocumentReferenceNumber = 'owner-identity-verification-dual-process-name-address-document-reference-number',
  ownerIdentityVerificationDualProcessNameAddressDate = 'owner-identity-verification-dual-process-name-address-date',
  ownerIdentityVerificationDualProcessNameBirthDateDocumentType = 'owner-identity-verification-dual-process-name-birth-date-document-type',
  ownerIdentityVerificationDualProcessNameBirthDateIssuingAuthority = 'owner-identity-verification-dual-process-name-birth-date-document-issuing-authority',
  ownerIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction = 'owner-identity-verification-dual-process-name-birth-date-document-issuing-jurisdiction',
  ownerIdentityVerificationDualProcessNameBirthDateReferenceNumber = 'owner-identity-verification-dual-process-name-birth-date-document-reference-number',
  ownerIdentityVerificationDualProcessNameBirthDateDate = 'owner-identity-verification-dual-process-name-birth-date-date',

  addSubrogatedOwner = 'add-subrogated-owner',
  subrogatedOwnerType = 'subrogated-owner-type',
  subrogatedOwnerFirstName = 'subrogated-owner-first-name',
  subrogatedOwnerLastName = 'subrogated-owner-last-name',
  subrogatedOwnerSex = 'subrogated-owner-sex',
  subrogatedOwnerDateOfBirth = 'subrogated-owner-date-of-birth',
  subrogatedOwnerRelationship = 'subrogated-owner-relationship',
  subrogatedOwnerOther = 'subrogated-owner-other',

  ownerWaiverOfPremium = 'owner-waiver-of-premium',
  ownerWaiverOfPremiumTerm = 'owner-waiver-of-premium-term',
  ownerWaiverOfPremiumType = 'owner-waiver-of-premium-type',

  addSecondOwner = 'add-second-owner',
  secondOwnerIs = 'second-owner-is',
  secondOwnerIsTheInsured = 'second-owner-is-the-insured',
  secondOwnerIsTheLegalGuardian = 'second-owner-is-the-legal-guardian',
  secondOtherOwnerLastName = 'second-other-owner-last-name',
  secondOtherOwnerFirstName = 'second-other-owner-first-name',
  secondOwnerMiddleNameInitial = 'second-owner-middle-name-initial',
  secondOtherOwnerGender = 'second-other-owner-gender',
  secondOtherOwnerDateOfBirth = 'second-other-owner-date-of-birth',
  secondOtherOwnerMaritalStatus = 'second-other-owner-marital-status',
  secondOtherOwnerProfession = 'second-other-owner-profession',
  secondOtherOwnerAddress = 'second-other-owner-address',
  secondOtherOwnerApartment = 'second-other-owner-apartment',
  secondOtherOwnerCity = 'second-other-owner-city',
  secondOtherOwnerCountry = 'second-other-owner-country',
  secondOtherOwnerProvince = 'second-other-owner-province',
  secondOtherOwnerPostalCode = 'second-other-owner-postal-code',
  secondOtherOwnerSin = 'second-other-owner-sin',
  secondOtherOwnerEmail = 'second-other-owner-email',
  secondOtherOwnerPhoneExtensionOne = 'second-other-owner-phone-extension-1',
  secondOtherOwnerPhoneTypeOne = 'second-other-owner-phone-type-1',
  secondOtherOwnerPhoneOne = 'second-other-owner-phone-1',
  secondOtherOwnerPhoneExtensionTwo = 'second-other-owner-phone-extension-2',
  secondOtherOwnerPhoneTypeTwo = 'second-other-owner-phone-type-2',
  secondOtherOwnerPhoneTwo = 'second-other-owner-phone-2',
  secondOtherOwnerPhoneExtensionThree = 'second-other-owner-phone-extension-3',
  secondOtherOwnerPhoneTypeThree = 'second-other-owner-phone-type-3',
  secondOtherOwnerPhoneThree = 'second-other-owner-phone-3',
  secondOtherOwnerRelationToProposedInsured = 'second-other-owner-relation-to-proposed-insured',

  secondOwnerOccupationOtherIs = 'second-owner-occupation-other-is',
  secondOwnerOccupationInCategory = 'second-owner-occupation-in-category',
  secondOwnerIdVerificationStatus = 'second-owner-id-verification-status',
  secondOwnerIdentificationDocument = 'second-owner-identification-document',
  secondOwnerOtherIdentificationDocument = 'second-owner-other-identification-document',
  secondOwnerIdentificationDocumentNumber = 'second-owner-identification-document-number',
  secondOwnerIdentificationDocumentExpiry = 'second-owner-identification-document-expiry',
  secondOwnerIdentificationDocumentIssuingAuthority = 'second-owner-identification-document-issuing-authority',
  secondOwnerIdentificationDocumentCountryProvince = 'second-owner-identification-document-country-province',

  secondOwnerThirdPartyOccupationOtherIs = 'second-owner-third-party-occupation-other-is',
  secondOwnerThirdPartyDetermination = 'second-owner-third-party-determination',
  secondOwnerTpLastName = 'second-owner-tp-last-name',
  secondOwnerTpFirstName = 'second-owner-tp-first-name',
  secondOwnerTpDateOfBirth = 'second-owner-tp-date-of-birth',
  secondOwnerTpOccupation = 'second-owner-tp-occupation',
  secondOwnerTpRelationship = 'second-owner-tp-relationship',
  secondOwnerTpRelationshipOther = 'second-owner-tp-relationship-other',
  secondOwnerTpAddress = 'second-owner-tp-address',
  secondOwnerTpApartment = 'second-owner-tp-apartment',
  secondOwnerTpCity = 'second-owner-tp-city',
  secondOwnerTpCountry = 'second-owner-tp-country',
  secondOwnerTpProvince = 'second-owner-tp-province',
  secondOwnerThirdPartyPhoneType = 'second-owner-third-party-phone-type',
  secondOwnerThirdPartyPhoneNumber = 'second-owner-third-party-phone-number',
  secondOwnerThirdPartyPhoneExtension = 'second-owner-third-party-phone-extension',
  secondOwnerTpPostalCode = 'second-owner-tp-postal-code',
  secondOwnerSin = 'second-owner-sin',
  secondOwnerUsCitizen = 'second-owner-is-united-states-citizen',
  secondOwnerTin = 'second-owner-tin',
  secondOwnerResidentOtherCountry = 'second-owner-resident-other-country',
  secondOwnerOtherCountry = 'second-owner-other-country',
  secondOwnerOtherCountryTin = 'second-owner-other-country-tin',
  addSecondSubrogatedOwner = 'add-second-subrogated-owner',
  secondSubrogatedOwnerType = 'second-subrogated-owner-type',
  secondSubrogatedOwnerFirstName = 'second-subrogated-owner-first-name',
  secondSubrogatedOwnerLastName = 'second-subrogated-owner-last-name',
  secondSubrogatedOwnerSex = 'second-subrogated-owner-sex',
  secondSubrogatedOwnerDateOfBirth = 'second-subrogated-owner-date-of-birth',
  secondSubrogatedOwnerRelationship = 'second-subrogated-owner-relationship',
  secondSubrogatedOwnerOther = 'second-subrogated-owner-other',

  secondOwnerEmploymentStatus = 'second-owner-employment-status',
  secondOwnerEmployerCompanyName = 'second-owner-employer-company-name',
  secondOwnerBirthCountry = 'second-owner-birth-country',
  secondOwnerBirthCountryOther = 'second-owner-birth-country-other',
  secondOwnerBirthProvinceOrState = 'second-owner-birth-province-or-state',
  secondOwnerBirthCity = 'second-owner-birth-city',
  secondOwnerSignatureCity = 'second-owner-signature-city',
  secondOwnerSignatureState = 'second-owner-signature-state',
  secondOwnerSignatureDate = 'second-owner-signature-date',

  secondOwnerRelationshipToProposedInsuredOtherIs = 'second-owner-relationship-to-proposed-insured-other-is',

  secondOwnerWaiverOfPremium = 'second-owner-waiver-of-premium',
  secondOwnerWaiverOfPremiumTerm = 'second-owner-waiver-of-premium-term',
  secondOwnerWaiverOfPremiumType = 'second-owner-waiver-of-premium-type',
  secondOwnerPersonalizedOffersAndAdviceConsent = 'second-owner-personalized-offers-and-advice-consent',

  secondOwnerIdentityVerification = 'second-owner-identity-verification',
  secondOwnerIdentityVerificationDocumentType = 'second-owner-identity-verification-document-type',
  secondOwnerIdentityVerificationDocumentTypeOther = 'second-owner-identity-verification-document-type-other',
  secondOwnerIdentityVerificationDocumentLastName = 'second-owner-identity-verification-document-last-name',
  secondOwnerIdentityVerificationDocumentFirstName = 'second-owner-identity-verification-document-first-name',
  secondOwnerIdentityVerificationDocumentNumber = 'second-owner-identity-verification-document-number',
  secondOwnerIdentityVerificationDocumentJurisdiction = 'second-owner-identity-verification-document-jurisdiction',
  secondOwnerIdentityVerificationDocumentExpiryDate = 'second-owner-identity-verification-document-expiry-date',
  secondOwnerIdentityVerificationDualProcessReason = 'second-owner-identity-verification-dual-process-reason',
  secondOwnerIdentityVerificationDualProcessReasonOther = 'second-owner-identity-verification-dual-process-reason-other',
  secondOwnerIdentityVerificationDualProcessNameAddressDocumentType = 'second-owner-identity-verification-dual-process-name-address-document-type',
  secondOwnerIdentityVerificationDualProcessNameAddressDocumentIssuingAuthority = 'second-owner-identity-verification-dual-process-name-address-document-issuing-authority',
  secondOwnerIdentityVerificationDualProcessNameAddressDocumentIssuingJurisdiction = 'second-owner-identity-verification-dual-process-name-address-document-issuing-jurisdiction',
  secondOwnerIdentityVerificationDualProcessNameAddressDocumentReferenceNumber = 'second-owner-identity-verification-dual-process-name-address-document-reference-number',
  secondOwnerIdentityVerificationDualProcessNameAddressDate = 'second-owner-identity-verification-dual-process-name-address-date',
  secondOwnerIdentityVerificationDualProcessNameBirthDateDocumentType = 'second-owner-identity-verification-dual-process-name-birth-date-document-type',
  secondOwnerIdentityVerificationDualProcessNameBirthDateIssuingAuthority = 'second-owner-identity-verification-dual-process-name-birth-date-document-issuing-authority',
  secondOwnerIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction = 'second-owner-identity-verification-dual-process-name-birth-date-document-issuing-jurisdiction',
  secondOwnerIdentityVerificationDualProcessNameBirthDateReferenceNumber = 'second-owner-identity-verification-dual-process-name-birth-date-document-reference-number',
  secondOwnerIdentityVerificationDualProcessNameBirthDateDate = 'second-owner-identity-verification-dual-process-name-birth-date-date',

  informationField = 'information-field',
  otherInstructions = 'other-instructions',

  existingApplication = 'existing-application',
  pastApplicationDecision = 'past-application-decision',
  previousApplicationExists = 'previous-application-exists',
  previousApplicationChoices = 'previous-application-choices',
  previousApplicationCarrierName = 'previous-application-carrier-name',
  previousApplicationDate = 'previous-application-date',
  previousApplicationReasonForOutcome = 'previous-application-reason-for-outcome',

  membershipSurveyChoice = 'membership-survey-choice',

  producerReportSourceOfBusiness = 'producer-report-source-of-business',
  producerReportPresentInsuredTypeDetails = 'producer-report-present-insured-type-details',
  producerReportOtherSourceOfBusiness = 'producer-report-other-source-of-business',
  producerReportMarketType = 'producer-report-market-type',
  producerReportOtherMarketType = 'producer-report-other-market-type',
  producerReportDeathBenefitReason = 'producer-report-death-benefit-reason',
  producerReportOtherDeathBenefitReason = 'producer-report-other-death-benefit-reason',
  producerReportRateClassQuote = 'producer-report-rate-class-quote',
  producerReportRelatedToInsured = 'producer-report-related-to-insured',
  producerReportRelationship = 'producer-report-relationship',
  producerReportOtherRelationship = 'producer-report-other-relationship',
  producerReportApplicationTakenInPerson = 'producer-report-application-taken-in-person',
  producerReportApplicationTakenBy = 'producer-report-application-taken-by',
  policyHolderInsuredSameLanguage = 'policy-holder-insured-same-language',
  propositionExplainerFullName = 'proposition-explainer-full-name',
  explanationsComprehension = 'explanations-comprehension',
  generalInformation = 'general-information',
  producerReportInsuredPresent = 'producer-report-insured-present',
  producerReportUnderwriting = 'producer-report-underwriting',
  producerReportUnderwritingDetails = 'producer-report-underwriting-details',
  producerReportPendingCoverage = 'producer-report-pending-coverage',
  producerReportCoverageHistory = 'producer-report-coverage-history',
  producerReportReplacement = 'producer-report-replacement',
  producerReportQuestions = 'producer-report-questions',
  producerReportFamilyNumberBrothers = 'producer-report-family-number-brothers',
  producerReportFamilyNumberSisters = 'producer-report-family-number-sisters',
  producerReportFamilyParentsTwoTimesInsurance = 'producer-report-family-parents-two-times-insurance',
  producerReportFamilyFather = 'producer-report-family-father',
  producerReportFamilyMother = 'producer-report-family-mother',
  producerReportFamilySiblings = 'producer-report-family-siblings',
  producerReportFamilySibiling1 = 'producer-report-family-sibiling-1',
  producerReportFamilySibiling2 = 'producer-report-family-sibiling-2',
  producerReportFamilySibiling3 = 'producer-report-family-sibiling-3',
  producerReportFamilySibling4 = 'producer-report-family-sibling-4',
  producerReportFamilySibling5 = 'producer-report-family-sibling-5',
  producerReportFamilySibling6 = 'producer-report-family-sibling-6',
  producerReportFamilySibling7 = 'producer-report-family-sibling-7',
  producerReportFamilySibling8 = 'producer-report-family-sibling-8',
  producerReportFamilySibling9 = 'producer-report-family-sibling-9',
  producerReportFamilySibling10 = 'producer-report-family-sibling-10',
  producerReportDate = 'producer-report-date',
  producerReportInsuranceProducerFirstName = 'producer-report-insurance-producer-first-name',
  producerReportInsuranceProducerMiddleName = 'producer-report-insurance-producer-middle-name',
  producerReportInsuranceProducerLastName = 'producer-report-insurance-producer-last-name',
  producerReportAgentCode = 'producer-report-agent-code',
  producerReportComissionSplit = 'producer-report-comission-split',
  producerReportInsuranceProducerSplitPercentage = 'producer-report-insurance-producer-split-percentage',
  producerReportProducerUnderwritingRemarks = 'producer-report-producer-underwriting-remarks',
  producerReportAddAgent = 'producer-report-add-agent',
  producerReportKnowsTheInsured = 'producer-report-knows-the-insured',

  producerReportHasSecondInsuranceProducer = 'producer-report-has-second-insurance-producer',
  producerReportSecondInsuranceProducerFirstName = 'producer-report-second-insurance-producer-first-name',
  producerReportSecondInsuranceProducerMiddleName = 'producer-report-second-insurance-producer-middle-name',
  producerReportSecondInsuranceProducerLastName = 'producer-report-second-insurance-producer-last-name',
  producerReportSecondAgentCode = 'producer-report-second-agent-code',
  producerReportSecondInsuranceProducerSplitPercentage = 'producer-report-second-insurance-producer-split-percentage',
  producerReportSecondInsuranceProducerGeneralAgentCode = 'producer-report-second-insurance-producer-general-agent-code',

  producerReportHasThirdInsuranceProducer = 'producer-report-has-third-insurance-producer',
  producerReportThirdAgentLastName = 'producer-report-third-agent-last-name',
  producerReportThirdAgentFirstName = 'producer-report-third-agent-first-name',
  producerReportThirdAgentCode = 'producer-report-third-agent-code',
  producerReportThirdInsuranceProducerSplitPercentage = 'producer-report-third-insurance-producer-split-percentage',
  producerReportThirdInsuranceProducerGeneralAgentCode = 'producer-report-third-insurance-producer-general-agent-code',

  policyIsMec = 'policy-is-mec',
  policyHoldingCourt = 'policy-holding-court',
  policySignatureCity = 'policy-signature-city',
  policySignatureStateOrProvince = 'policy-signature-state-or-province',
  policyInsuranceProducerNPNNumber = 'policy-insurance-producer-npn-number',
  policyInsuranceProducerAgentCode = 'policy-insurance-producer-agent-code',
  policyInsuranceProducerAgentEmail = 'policy-insurance-producer-agent-email',
  policyInsuranceGeneralAgentName = 'policy-insurance-general-agent-name',
  policyInsuranceGeneralAgentEmail = 'policy-insurance-general-agent-email',
  policyInsuranceGeneralAgentCode = 'policy-insurance-general-agent-code',

  criminalOffenceDetails = 'criminal-offence-details',
  criminalOffenceCircumstances = 'criminal-offence-circumstances',
  criminalOffenceDate = 'criminal-offence-date',
  criminalOffenceCharges = 'criminal-offence-charges',
  criminalOffenceSentenceDetails = 'criminal-offence-sentence-details',

  lifestyleInsuredHasCriminalHistory = 'lifestyle-insured-has-criminal-history',
  lifestyleInsuredHasCriminalHistory1 = 'lifestyle-insured-has-criminal-history-1',
  lifestyleInsuredCriminalHistoryChoices = 'lifestyle-insured-criminal-history-choices',

  riderIds = 'rider-ids',
  accidentalDeathBenefitAnnualPremium = 'accidental-death-benefit-annual-premium',
  guaranteedInsurabilityFaceAmount = 'guaranteed-insurability-face-amount',
  guaranteedInsurabilityAnnualPremium = 'guaranteed-insurability-annual-premium',
  waiverOfPremiumAnnualPremium = 'waiver-of-premium-annual-premium',
  rider10YearLevelRiskClass = 'rider-10-year-level-risk-class',
  rider10YearLevelFaceAmount = 'rider-10-year-level-face-amount',
  rider10YearLevelAnnualPremium = 'rider-10-year-level-annual-premium',
  rider10YearLevelTableRating = 'rider-10-year-level-table-rating',
  rider10YearLevelFlatRatingAmount = 'rider-10-year-level-flat-rating-amount',
  rider10YearLevelFlatRatingEnd = 'rider-10-year-level-flat-rating-end',
  rider20YearLevelRiskClass = 'rider-20-year-level-risk-class',
  rider20YearLevelFaceAmount = 'rider-20-year-level-face-amount',
  rider20YearLevelAnnualPremium = 'rider-20-year-level-annual-premium',
  rider20YearLevelTableRating = 'rider-20-year-level-table-rating',
  rider20YearLevelFlatRatingAmount = 'rider-20-year-level-flat-rating-amount',
  rider20YearLevelFlatRatingEnd = 'rider-20-year-level-flat-rating-end',

  drugUseChoice = 'drug-use-choice',
  drugUseOther = 'drug-use-other',
  drugOpiumDerivativesQuantity = 'drug-opium-derivatives-quantity',
  drugOpiumDerivativesFrequency = 'drug-opium-derivatives-frequency',
  drugOpiumDerivativesFirstUseDate = 'drug-opium-derivatives-first-use-date',
  drugOpiumDerivativesLastUseDate = 'drug-opium-derivatives-last-use-date',
  drugMarijuanaQuantity = 'drug-marijuana-quantity',
  drugMarijuanaFrequency = 'drug-marijuana-frequency',
  drugMarijuanaFirstUseDate = 'drug-marijuana-first-use-date',
  drugMarijuanaLastUseDate = 'drug-marijuana-last-use-date',
  drugPhenobarbitalQuantity = 'drug-phenobarbital-quantity',
  drugPhenobarbitalFrequency = 'drug-phenobarbital-frequency',
  drugPhenobarbitalFirstUseDate = 'drug-phenobarbital-first-use-date',
  drugPhenobarbitalLastUseDate = 'drug-phenobarbital-last-use-date',
  drugHallucinogensQuantity = 'drug-hallucinogens-quantity',
  drugHallucinogensFrequency = 'drug-hallucinogens-frequency',
  drugHallucinogensFirstUseDate = 'drug-hallucinogens-first-use-date',
  drugHallucinogensLastUseDate = 'drug-hallucinogens-last-use-date',
  drugHeroineQuantity = 'drug-heroine-quantity',
  drugHeroineFrequency = 'drug-heroine-frequency',
  drugHeroineFirstUseDate = 'drug-heroine-first-use-date',
  drugHeroineLastUseDate = 'drug-heroine-last-use-date',
  drugHashishQuantity = 'drug-hashish-quantity',
  drugHashishFrequency = 'drug-hashish-frequency',
  drugHashishFirstUseDate = 'drug-hashish-first-use-date',
  drugHashishLastUseDate = 'drug-hashish-last-use-date',
  drugLsdQuantity = 'drug-lsd-quantity',
  drugLsdFrequency = 'drug-lsd-frequency',
  drugLsdFirstUseDate = 'drug-lsd-first-use-date',
  drugLsdLastUseDate = 'drug-lsd-last-use-date',
  drugPcpQuantity = 'drug-pcp-quantity',
  drugPcpFrequency = 'drug-pcp-frequency',
  drugPcpFirstUseDate = 'drug-pcp-first-use-date',
  drugPcpLastUseDate = 'drug-pcp-last-use-date',
  drugMorphineQuantity = 'drug-morphine-quantity',
  drugMorphineFrequency = 'drug-morphine-frequency',
  drugMorphineFirstUseDate = 'drug-morphine-first-use-date',
  drugMorphineLastUseDate = 'drug-morphine-last-use-date',
  drugAmphetaminesQuantity = 'drug-amphetamines-quantity',
  drugAmphetaminesFrequency = 'drug-amphetamines-frequency',
  drugAmphetaminesFirstUseDate = 'drug-amphetamines-first-use-date',
  drugAmphetaminesLastUseDate = 'drug-amphetamines-last-use-date',
  drugHydrocodoneQuantity = 'drug-hydrocodone-quantity',
  drugHydrocodoneFrequency = 'drug-hydrocodone-frequency',
  drugHydrocodoneFirstUseDate = 'drug-hydrocodone-first-use-date',
  drugHydrocodoneLastUseDate = 'drug-hydrocodone-last-use-date',
  drugCrystalMethQuantity = 'drug-crystal-meth-quantity',
  drugCrystalMethFrequency = 'drug-crystal-meth-frequency',
  drugCrystalMethFirstUseDate = 'drug-crystal-meth-first-use-date',
  drugCrystalMethLastUseDate = 'drug-crystal-meth-last-use-date',
  drugPercodanQuantity = 'drug-percodan-quantity',
  drugPercodanFrequency = 'drug-percodan-frequency',
  drugPercodanFirstUseDate = 'drug-percodan-first-use-date',
  drugPercodanLastUseDate = 'drug-percodan-last-use-date',
  drugCocaineQuantity = 'drug-cocaine-quantity',
  drugCocaineFrequency = 'drug-cocaine-frequency',
  drugCocaineFirstUseDate = 'drug-cocaine-first-use-date',
  drugCocaineLastUseDate = 'drug-cocaine-last-use-date',
  drugCodeineQuantity = 'drug-codeine-quantity',
  drugCodeineFrequency = 'drug-codeine-frequency',
  drugCodeineFirstUseDate = 'drug-codeine-first-use-date',
  drugCodeineLastUseDate = 'drug-codeine-last-use-date',
  drugSpeedQuantity = 'drug-speed-quantity',
  drugSpeedFrequency = 'drug-speed-frequency',
  drugSpeedFirstUseDate = 'drug-speed-first-use-date',
  drugSpeedLastUseDate = 'drug-speed-last-use-date',
  drugDemerolQuantity = 'drug-demerol-quantity',
  drugDemerolFrequency = 'drug-demerol-frequency',
  drugDemerolFirstUseDate = 'drug-demerol-first-use-date',
  drugDemerolLastUseDate = 'drug-demerol-last-use-date',
  drugCrackQuantity = 'drug-crack-quantity',
  drugCrackFrequency = 'drug-crack-frequency',
  drugCrackFirstUseDate = 'drug-crack-first-use-date',
  drugCrackLastUseDate = 'drug-crack-last-use-date',
  drugOxycodoneQuantity = 'drug-oxycodone-quantity',
  drugOxycodoneFrequency = 'drug-oxycodone-frequency',
  drugOxycodoneFirstUseDate = 'drug-oxycodone-first-use-date',
  drugOxycodoneLastUseDate = 'drug-oxycodone-last-use-date',
  drugLibriumQuantity = 'drug-librium-quantity',
  drugLibriumFrequency = 'drug-librium-frequency',
  drugLibriumFirstUseDate = 'drug-librium-first-use-date',
  drugLibriumLastUseDate = 'drug-librium-last-use-date',
  drugMethadoneQuantity = 'drug-methadone-quantity',
  drugMethadoneFrequency = 'drug-methadone-frequency',
  drugMethadoneFirstUseDate = 'drug-methadone-first-use-date',
  drugMethadoneLastUseDate = 'drug-methadone-last-use-date',
  drugBarbituratesQuantity = 'drug-barbiturates-quantity',
  drugBarbituratesFrequency = 'drug-barbiturates-frequency',
  drugBarbituratesFirstUseDate = 'drug-barbiturates-first-use-date',
  drugBarbituratesLastUseDate = 'drug-barbiturates-last-use-date',
  drugVicodinQuantity = 'drug-vicodin-quantity',
  drugVicodinFrequency = 'drug-vicodin-frequency',
  drugVicodinFirstUseDate = 'drug-vicodin-first-use-date',
  drugVicodinLastUseDate = 'drug-vicodin-last-use-date',
  drugAlcoholQuantity = 'drug-alcohol-quantity',
  drugAlcoholFrequency = 'drug-alcohol-frequency',
  drugAlcoholFirstUseDate = 'drug-alcohol-first-use-date',
  drugAlcoholLastUseDate = 'drug-alcohol-last-use-date',
  drugOtherDrugsQuantity = 'drug-other-drugs-quantity',
  drugOtherDrugsFrequency = 'drug-other-drugs-frequency',
  drugOtherDrugsFirstUseDate = 'drug-other-drugs-first-use-date',
  drugOtherDrugsLastUseDate = 'drug-other-drugs-last-use-date',
  lifestyleInsuredDrugsOtherPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-other-past-change-habit-consumption-date',
  lifestyleInsuredDrugsOtherPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-other-past-change-habit-consumption-reason',
  lifestyleInsuredDrugsOrNarcoticsHasBeenAdvisedReduceOrCeaseConsumption = 'lifestyle-insured-drugs-or-narcotics-has-been-advised-reduce-or-cease-consumption',
  lifestyleInsuredDrugsOrNarcoticsHadTherapy = 'lifestyle-insured-drugs-or-narcotics-had-therapy',
  lifestyleInsuredDrugsOrNarcoticsSupportGroupMeetings = 'lifestyle-insured-drugs-or-narcotics-support-group-meetings',

  lifestyleInsuredDrugsOtherConsumptionHabitsQuantity = 'lifestyle-insured-drugs-other-consumption-habits-quantity',
  lifestyleInsuredDrugsOtherConsumptionHabitsFrequency = 'lifestyle-insured-drugs-other-consumption-habits-frequency',
  lifestyleInsuredDrugsOtherUseStillConsume = 'lifestyle-insured-drugs-other-use-still-consume',
  lifestyleInsuredDrugsOtherUseStartDate = 'lifestyle-insured-drugs-other-use-start-date',
  lifestyleInsuredDrugsOtherUseEndDate = 'lifestyle-insured-drugs-other-use-end-date',

  lifestyleInsuredDrugsOtherPastUse = 'lifestyle-insured-drugs-other-past-use',
  lifestyleInsuredDrugsOtherPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-other-past-consumption-habits-quantity',
  lifestyleInsuredDrugsOtherPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-other-past-consumption-habits-frequency',
  lifestyleInsuredDrugsOtherPastUseStartDate = 'lifestyle-insured-drugs-other-past-use-start-date',
  lifestyleInsuredDrugsOtherPastUseEndDate = 'lifestyle-insured-drugs-other-past-use-end-date',

  lifestyleInsuredDrugsOrNarcoticsAdvisedCeaseConsumptionDate = 'lifestyle-insured-drugs-or-narcotics-advised-cease-consumption-date',
  lifestyleInsuredDrugsOrNarcoticsAdvisedCeaseConsumptionProfessionalContactInformation = 'lifestyle-insured-drugs-or-narcotics-advised-cease-consumption-professional-contact-information',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentStartDate = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-start-date',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentEndDate = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-end-date',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentType = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-type',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-professional-contact-information',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-additionnal-period',
  lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriodDetails = 'lifestyle-insured-drugs-or-narcotics-therapy-or-treatment-additionnal-period-details',
  lifestyleInsuredDrugsOrNarcoticsSupportGroupStillAttending = 'lifestyle-insured-drugs-or-narcotics-support-group-still-attending',
  lifestyleInsuredDrugsOrNarcoticsSupportGroupStartDate = 'lifestyle-insured-drugs-or-narcotics-support-group-start-date',
  lifestyleInsuredDrugsOrNarcoticsSupportGroupEndDate = 'lifestyle-insured-drugs-or-narcotics-support-group-end-date',

  drugMedProv = 'drug-med-prov',
  drugMedProvDetails = 'drug-med-prov-details',
  drugDui = 'drug-dui',
  drugMissedWork = 'drug-missed-work',
  drugGuilty = 'drug-guilty',
  drugFamily = 'drug-family',
  drugTreatment = 'drug-treatment',
  drugSupportGroup = 'drug-support-group',
  drugSupportGroupName = 'drug-support-group-name',
  drugSupportGroupDateFirstAttended = 'drug-support-group-date-first-attended',
  drugSupportGroupDateLastAttended = 'drug-support-group-date-last-attended',
  drugSupportGroupFrequency = 'drug-support-group-frequency',
  drugUseInsuredPastWeight = 'drug-use-insured-past-weight',
  avocationSportChoice = 'avocation-sport-choice',

  autoRacingAssociations = 'auto-racing-associations',
  autoRacingLicensesAndTitles = 'auto-racing-licenses-and-titles',
  autoRacingParticipationMonetary = 'auto-racing-participation-monetary',
  autoRacingGains = 'auto-racing-gains',
  autoRacingLocation = 'auto-racing-location',
  autoRacingExperimentalForm = 'auto-racing-experimental-form',
  autoRacingExperimentalFormDetails = 'auto-racing-experimental-form-details',
  autoRacingSportDuration = 'auto-racing-sport-duration',
  autoRacingParticipationFrequency = 'auto-racing-participation-frequency',
  autoRacingGreatestSpeed = 'auto-racing-greatest-speed',
  autoRacingGreatestSpeedCount = 'auto-racing-greatest-speed-count',
  autoRacingGreatestSpeedCountYear = 'auto-racing-greatest-speed-count-year',
  autoRacingAverageSpeed = 'auto-racing-average-speed',
  autoRacingParticipationAverageTime = 'auto-racing-participation-average-time',
  autoRacingVehicleModified = 'auto-racing-vehicle-modified',
  autoRacingVehicleModel = 'auto-racing-vehicle-model',
  autoRacingVehicleClass = 'auto-racing-vehicle-class',
  autoRacingTypeOfFuel = 'auto-racing-type-of-fuel',
  autoRacingVehicleHp = 'auto-racing-vehicle-hp',
  autoRacingTopSpeed = 'auto-racing-top-speed',
  autoRacingEngineDisplacement = 'auto-racing-engine-displacement',
  autoRacingTrackType = 'auto-racing-track-type',

  boatRacingAssociations = 'boat-racing-associations',
  boatRacingLicensesAndTitles = 'boat-racing-licenses-and-titles',
  boatRacingParticipationMonetary = 'boat-racing-participation-monetary',
  boatRacingGains = 'boat-racing-gains',
  boatRacingLocation = 'boat-racing-location',
  boatRacingExperimentalForm = 'boat-racing-experimental-form',
  boatRacingExperimentalFormDetails = 'boat-racing-experimental-form-details',
  boatRacingSportDuration = 'boat-racing-sport-duration',
  boatRacingParticipationFrequency = 'boat-racing-participation-frequency',
  boatRacingGreatestSpeed = 'boat-racing-greatest-speed',
  boatRacingGreatestSpeedCount = 'boat-racing-greatest-speed-count',
  boatRacingGreatestSpeedCountYear = 'boat-racing-greatest-speed-count-year',
  boatRacingAverageSpeed = 'boat-racing-average-speed',
  boatRacingParticipationAverageTime = 'boat-racing-participation-average-time',
  boatRacingVehicleModified = 'boat-racing-vehicle-modified',
  boatRacingVehicleModel = 'boat-racing-vehicle-model',
  boatRacingVehicleClass = 'boat-racing-vehicle-class',
  boatRacingTypeOfFuel = 'boat-racing-type-of-fuel',
  boatRacingVehicleHp = 'boat-racing-vehicle-hp',
  boatRacingTopSpeed = 'boat-racing-top-speed',
  boatRacingEngineDisplacement = 'boat-racing-engine-displacement',
  boatRacingTrackType = 'boat-racing-track-type',

  boxingAssociations = 'boxing-associations',
  boxingLicensesAndTitles = 'boxing-licenses-and-titles',
  boxingParticipationMonetary = 'boxing-participation-monetary',
  boxingGains = 'boxing-gains',
  boxingLocation = 'boxing-location',
  boxingExperimentalForm = 'boxing-experimental-form',
  boxingExperimentalFormDetails = 'boxing-experimental-form-details',
  boxingSportDuration = 'boxing-sport-duration',
  boxingParticipationFrequency = 'boxing-participation-frequency',
  boxingParticipationAverageTime = 'boxing-participation-average-time',

  ballooningAssociations = 'ballooning-associations',
  ballooningLicensesAndTitles = 'ballooning-licenses-and-titles',
  ballooningParticipationMonetary = 'ballooning-participation-monetary',
  ballooningGains = 'ballooning-gains',
  ballooningLocation = 'ballooning-location',
  ballooningExperimentalForm = 'ballooning-experimental-form',
  ballooningExperimentalFormDetails = 'ballooning-experimental-form-details',
  ballooningSportDuration = 'ballooning-sport-duration',
  ballooningParticipationFrequency = 'ballooning-participation-frequency',
  ballooningGreatestHeight = 'ballooning-greatest-height',
  ballooningGreatestHeightCount = 'ballooning-greatest-height-count',
  ballooningGreatestHeightCountYear = 'ballooning-greatest-height-count-year',
  ballooningAverageHeight = 'ballooning-average-height',
  ballooningParticipationAverageTime = 'ballooning-participation-average-time',

  hangGlidingUltralightsAssociations = 'hang-gliding-ultralights-associations',
  hangGlidingUltralightsLicensesAndTitles = 'hang-gliding-ultralights-licenses-and-titles',
  hangGlidingUltralightsParticipationMonetary = 'hang-gliding-ultralights-participation-monetary',
  hangGlidingUltralightsGains = 'hang-gliding-ultralights-gains',
  hangGlidingUltralightsLocation = 'hang-gliding-ultralights-location',
  hangGlidingUltralightsExperimentalForm = 'hang-gliding-ultralights-experimental-form',
  hangGlidingUltralightsExperimentalFormDetails = 'hang-gliding-ultralights-experimental-form-details',
  hangGlidingUltralightsSportDuration = 'hang-gliding-ultralights-sport-duration',
  hangGlidingUltralightsParticipationFrequency = 'hang-gliding-ultralights-participation-frequency',
  hangGlidingUltralightsGreatestHeight = 'hang-gliding-ultralights-greatest-height',
  hangGlidingUltralightsGreatestHeightCount = 'hang-gliding-ultralights-greatest-height-count',
  hangGlidingUltralightsGreatestHeightCountYear = 'hang-gliding-ultralights-greatest-height-count-year',
  hangGlidingUltralightsAverageHeight = 'hang-gliding-ultralights-average-height',
  hangGlidingUltralightsParticipationAverageTime = 'hang-gliding-ultralights-participation-average-time',

  motorcycleRacingAssociations = 'motorcycle-racing-associations',
  motorcycleRacingLicensesAndTitles = 'motorcycle-racing-licenses-and-titles',
  motorcycleRacingParticipationMonetary = 'motorcycle-racing-participation-monetary',
  motorcycleRacingGains = 'motorcycle-racing-gains',
  motorcycleRacingLocation = 'motorcycle-racing-location',
  motorcycleRacingExperimentalForm = 'motorcycle-racing-experimental-form',
  motorcycleRacingExperimentalFormDetails = 'motorcycle-racing-experimental-form-details',
  motorcycleRacingSportDuration = 'motorcycle-racing-sport-duration',
  motorcycleRacingParticipationFrequency = 'motorcycle-racing-participation-frequency',
  motorcycleRacingGreatestSpeed = 'motorcycle-racing-greatest-speed',
  motorcycleRacingGreatestSpeedCount = 'motorcycle-racing-greatest-speed-count',
  motorcycleRacingGreatestSpeedCountYear = 'motorcycle-racing-greatest-speed-count-year',
  motorcycleRacingAverageSpeed = 'motorcycle-racing-average-speed',
  motorcycleRacingParticipationAverageTime = 'motorcycle-racing-participation-average-time',
  motorcycleRacingVehicleModified = 'motorcycle-racing-vehicle-modified',
  motorcycleRacingVehicleModel = 'motorcycle-racing-vehicle-model',
  motorcycleRacingVehicleClass = 'motorcycle-racing-vehicle-class',
  motorcycleRacingTypeOfFuel = 'motorcycle-racing-type-of-fuel',
  motorcycleRacingVehicleHp = 'motorcycle-racing-vehicle-hp',
  motorcycleRacingTopSpeed = 'motorcycle-racing-top-speed',
  motorcycleRacingEngineDisplacement = 'motorcycle-racing-engine-displacement',
  motorcycleRacingTrackType = 'motorcycle-racing-track-type',

  parachutingSkydivingAssociations = 'parachuting-skydiving-associations',
  parachutingSkydivingLicensesAndTitles = 'parachuting-skydiving-licenses-and-titles',
  parachutingSkydivingParticipationMonetary = 'parachuting-skydiving-participation-monetary',
  parachutingSkydivingGains = 'parachuting-skydiving-gains',
  parachutingSkydivingLocation = 'parachuting-skydiving-location',
  parachutingSkydivingExperimentalForm = 'parachuting-skydiving-experimental-form',
  parachutingSkydivingExperimentalFormDetails = 'parachuting-skydiving-experimental-form-details',
  parachutingSkydivingSportDuration = 'parachuting-skydiving-sport-duration',
  parachutingSkydivingParticipationFrequency = 'parachuting-skydiving-participation-frequency',
  parachutingSkydivingGreatestHeight = 'parachuting-skydiving-greatest-height',
  parachutingSkydivingGreatestHeightCount = 'parachuting-skydiving-greatest-height-count',
  parachutingSkydivingGreatestHeightCountYear = 'parachuting-skydiving-greatest-height-count-year',
  parachutingSkydivingAverageHeight = 'parachuting-skydiving-average-height',
  parachutingSkydivingParticipationAverageTime = 'parachuting-skydiving-participation-average-time',

  professionalAthleticsAssociations = 'professional-athletics-associations',
  professionalAthleticsLicensesAndTitles = 'professional-athletics-licenses-and-titles',
  professionalAthleticsParticipationMonetary = 'professional-athletics-participation-monetary',
  professionalAthleticsGains = 'professional-athletics-gains',
  professionalAthleticsLocation = 'professional-athletics-location',
  professionalAthleticsExperimentalForm = 'professional-athletics-experimental-form',
  professionalAthleticsExperimentalFormDetails = 'professional-athletics-experimental-form-details',
  professionalAthleticsSportDuration = 'professional-athletics-sport-duration',
  professionalAthleticsParticipationFrequency = 'professional-athletics-participation-frequency',
  professionalAthleticsParticipationAverageTime = 'professional-athletics-participation-average-time',

  scubaSkinDivingAssociations = 'scuba-skin-diving-associations',
  scubaSkinDivingLicensesAndTitles = 'scuba-skin-diving-licenses-and-titles',
  scubaSkinDivingParticipationMonetary = 'scuba-skin-diving-participation-monetary',
  scubaSkinDivingGains = 'scuba-skin-diving-gains',
  scubaSkinDivingLocation = 'scuba-skin-diving-location',
  scubaSkinDivingExperimentalForm = 'scuba-skin-diving-experimental-form',
  scubaSkinDivingExperimentalFormDetails = 'scuba-skin-diving-experimental-form-details',
  scubaSkinDivingSportDuration = 'scuba-skin-diving-sport-duration',
  scubaSkinDivingParticipationFrequency = 'scuba-skin-diving-participation-frequency',
  scubaSkinDivingParticipationAverageTime = 'scuba-skin-diving-participation-average-time',
  scubaSkinDivingEquipmentUsed = 'scuba-skin-diving-equipment-used',
  scubaSkinDivingOwnEquipment = 'scuba-skin-diving-own-equipment',
  scubaSkinDivingDiveAlone = 'scuba-skin-diving-dive-alone',
  scubaSkinDivingAverageDepth = 'scuba-skin-diving-average-depth',
  scubaSkinDivingMaximumDepth = 'scuba-skin-diving-maximum-depth',
  scubaSkinDivingNumberOfTimesAttained = 'scuba-skin-diving-number-of-times-attained',

  snowmobileRacingAssociations = 'snowmobile-racing-associations',
  snowmobileRacingLicensesAndTitles = 'snowmobile-racing-licenses-and-titles',
  snowmobileRacingParticipationMonetary = 'snowmobile-racing-participation-monetary',
  snowmobileRacingGains = 'snowmobile-racing-gains',
  snowmobileRacingLocation = 'snowmobile-racing-location',
  snowmobileRacingExperimentalForm = 'snowmobile-racing-experimental-form',
  snowmobileRacingExperimentalFormDetails = 'snowmobile-racing-experimental-form-details',
  snowmobileRacingSportDuration = 'snowmobile-racing-sport-duration',
  snowmobileRacingParticipationFrequency = 'snowmobile-racing-participation-frequency',
  snowmobileRacingGreatestSpeed = 'snowmobile-racing-greatest-speed',
  snowmobileRacingGreatestSpeedCount = 'snowmobile-racing-greatest-speed-count',
  snowmobileRacingGreatestSpeedCountYear = 'snowmobile-racing-greatest-speed-count-year',
  snowmobileRacingAverageSpeed = 'snowmobile-racing-average-speed',
  snowmobileRacingParticipationAverageTime = 'snowmobile-racing-participation-average-time',
  snowmobileRacingVehicleModified = 'snowmobile-racing-vehicle-modified',
  snowmobileRacingVehicleModel = 'snowmobile-racing-vehicle-model',
  snowmobileRacingVehicleClass = 'snowmobile-racing-vehicle-class',
  snowmobileRacingTypeOfFuel = 'snowmobile-racing-type-of-fuel',
  snowmobileRacingVehicleHp = 'snowmobile-racing-vehicle-hp',
  snowmobileRacingTopSpeed = 'snowmobile-racing-top-speed',
  snowmobileRacingEngineDisplacement = 'snowmobile-racing-engine-displacement',
  snowmobileRacingTrackType = 'snowmobile-racing-track-type',
  marijuanaCurrentUse = 'marijuana-current-use',
  marijuanaSmokingQuantity = 'marijuana-smoking-quantity',
  marijuanaSmokingFrequency = 'marijuana-smoking-frequency',
  marijuanaVaporizingQuantity = 'marijuana-vaporizing-quantity',
  marijuanaVaporizingFrequency = 'marijuana-vaporizing-frequency',
  marijuanaCapsuleQuantity = 'marijuana-capsule-quantity',
  marijuanaCapsuleFrequency = 'marijuana-capsule-frequency',
  marijuanaEatenQuantity = 'marijuana-eaten-quantity',
  marijuanaEatenFrequency = 'marijuana-eaten-frequency',
  marijuanaOtherQuantity = 'marijuana-other-quantity',
  marijuanaOtherFrequency = 'marijuana-other-frequency',

  lifestyleInsuredMarijuanaOtherConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-other-consumption-habits-quantity',
  lifestyleInsuredMarijuanaOtherConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-other-consumption-habits-frequency',
  lifestyleInsuredMarijuanaOtherConsumptionHabitsUsageDate = 'lifestyle-insured-marijuana-other-consumption-habits-usage-date',
  lifestyleInsuredMarijuanaOtherConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-other-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaOtherConsumptionMedicalCondition = 'lifestyle-insured-marijuana-other-consumption-medical-condition',
  lifestyleInsuredMarijuanaOtherMedicalPrescribed = 'lifestyle-insured-marijuana-other-medical-prescribed',
  lifestyleInsuredMarijuanaOtherMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-other-medical-prescribing-physician',

  lifestyleInsuredMarijuanaOtherPastConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-other-past-consumption-habits-quantity',
  lifestyleInsuredMarijuanaOtherPastConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-other-past-consumption-habits-frequency',
  lifestyleInsuredMarijuanaOtherPastConsumptionMedicalCondition = 'lifestyle-insured-marijuana-other-past-consumption-medical-condition',
  lifestyleInsuredMarijuanaOtherPastConsumptionMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-other-past-consumption-medical-prescribing-physician',

  lifestyleInsuredMarijuanaAdvisedOrCeaseConsumptionDate = 'lifestyle-insured-marijuana-advised-or-cease-consumption-date',
  lifestyleInsuredMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation = 'lifestyle-insured-marijuana-advised-or-cease-consumption-professional-contact-information',

  marijuanaDisease = 'marijuana-disease',
  marijuanaMedicinalMedProvDate = 'marijuana-medicinal-med-prov-date',
  marijuanaDiseaseOther = 'marijuana-disease-other',
  lifestyleInsuredMarijuanaOtherPastUse = 'lifestyle-insured-marijuana-other-past-use',
  lifestyleInsuredMarijuanaOtherPastConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-other-past-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaOtherPastConsumptionMedicalPrescribed = 'lifestyle-insured-marijuana-other-past-consumption-medical-prescribed',
  lifestyleInsuredMarijuanaOtherChangeHabitConsumptionDate = 'lifestyle-insured-marijuana-other-change-habit-consumption-date',
  lifestyleInsuredMarijuanaOtherChangeHabitConsumptionReason = 'lifestyle-insured-marijuana-other-change-habit-consumption-reason',
  lifestyleInsuredMarijuanaHasBeenAdvisedReduceOrCeaseConsumption = 'lifestyle-insured-marijuana-has-been-advised-reduce-or-cease-consumption',
  lifestyleInsuredMarijuanaHadTherapy = 'lifestyle-insured-marijuana-had-therapy',
  lifestyleInsuredMarijuanaSupportGroupMeetings = 'lifestyle-insured-marijuana-support-group-meetings',
  lifestyleInsuredMarijuanaTherapyOrTreatmentStartDate = 'lifestyle-insured-marijuana-therapy-or-treatment-start-date',
  lifestyleInsuredMarijuanaTherapyOrTreatmentEndDate = 'lifestyle-insured-marijuana-therapy-or-treatment-end-date',
  lifestyleInsuredMarijuanaTherapyOrTreatmentType = 'lifestyle-insured-marijuana-therapy-or-treatment-type',
  lifestyleInsuredMarijuanaTherapyOrTreatmentProfessionalContactInformation = 'lifestyle-insured-marijuana-therapy-or-treatment-professional-contact-information',
  lifestyleInsuredMarijuanaTherapyOrTreatmentIsOnlyPeriod = 'lifestyle-insured-marijuana-therapy-or-treatment-is-only-period',
  lifestyleInsuredMarijuanaTherapyOrTreatmentAdditionalPeriodDetails = 'lifestyle-insured-marijuana-therapy-or-treatment-additional-period-details',
  lifestyleInsuredMarijuanaSupportGroupStillAttending = 'lifestyle-insured-marijuana-support-group-still-attending',
  lifestyleInsuredMarijuanaSupportGroupStartDate = 'lifestyle-insured-marijuana-support-group-start-date',
  lifestyleInsuredMarijuanaSupportGroupEndDate = 'lifestyle-insured-marijuana-support-group-end-date',

  lifestyleInsuredMarijuanaPastUseType = 'lifestyle-insured-marijuana-past-use-type',
  lifestyleInsuredMarijuanaPastUseTypeOther = 'lifestyle-insured-marijuana-past-use-type-other',

  typeOfFlyingDone = 'type-of-flying-done',
  civilianAviation = 'civilian-aviation',
  civilianAviationOther = 'civilian-aviation-other',
  militaryAviation = 'military-aviation',
  militaryAviationOther = 'military-aviation-other',
  aviationDisqualification = 'aviation-disqualification',
  aviationDisqualificationDetails = 'aviation-disqualification-details',
  typeOfAircraft = 'type-of-aircraft',
  hoursFlownLast12Months = 'hours-flown-last-12-months',
  hoursWillFlyNext12Months = 'hours-will-fly-next-12-months',
  internationalAviation = 'international-aviation',
  internationalAviationDetails = 'international-aviation-details',

  healthInsuredHerniatedDiscIsSingleEpisode = 'health-insured-herniated-disc-is-single-episode',
  healthInsuredHerniatedDiscPreviousEpisodesCount = 'health-insured-herniated-disc-previous-episodes-count',
  healthInsuredHerniatedDiscPainLocation = 'health-insured-herniated-disc-pain-location',
  healthInsuredHerniatedDiscDateSymptoms = 'health-insured-herniated-disc-date-symptoms',
  healthInsuredHerniatedDiscPainCause = 'health-insured-herniated-disc-pain-cause',
  healthInsuredHerniatedDiscPainCauseDetails = 'health-insured-herniated-disc-pain-cause-details',
  healthInsuredHerniatedDiscHasConsultedDoctors = 'health-insured-herniated-disc-has-consulted-doctors',
  healthInsuredHerniatedDiscHasTakenTestsOrExams = 'health-insured-herniated-disc-has-taken-tests-or-exams',
  healthInsuredHerniatedDiscMedicationWasPrescribed = 'health-insured-herniated-disc-medication-was-prescribed',
  healthInsuredHerniatedDiscHadOperation = 'health-insured-herniated-disc-had-operation',
  healthInsuredHerniatedDiscOperationPerformedOrPlanned = 'health-insured-herniated-disc-operation-performed-or-planned',
  healthInsuredHerniatedDiscTypeOfOperationPerformed = 'health-insured-herniated-disc-type-of-operation-performed',
  healthInsuredHerniatedDiscOperationPerformedDate = 'health-insured-herniated-disc-operation-performed-date',
  healthInsuredHerniatedDiscTypeOfOperationPlanned = 'health-insured-herniated-disc-type-of-operation-planned',
  healthInsuredHerniatedDiscOperationPlannedDate = 'health-insured-herniated-disc-operation-planned-date',
  healthInsuredHerniatedDiscOperationUnknownDate = 'health-insured-herniated-disc-operation-unknown-date',
  healthInsuredHerniatedDiscHadAbsence = 'health-insured-herniated-disc-had-absence',
  healthInsuredHerniatedDiscAbsenceStartDate = 'health-insured-herniated-disc-absence-start-date',
  healthInsuredHerniatedDiscAbsenceFrequencyNumber = 'health-insured-herniated-disc-absence-frequency-number',
  healthInsuredHerniatedDiscAbsenceFrequency = 'health-insured-herniated-disc-absence-frequency',
  healthInsuredHerniatedDiscAbsenceHasRecovered = 'health-insured-herniated-disc-absence-has-recovered',
  healthInsuredHerniatedDiscAbsenceRecoveryDate = 'health-insured-herniated-disc-absence-recovery-date',
  healthInsuredHerniatedDiscAbsenceNotRecoveredDetails = 'health-insured-herniated-disc-absence-not-recovered-details',

  healthInsuredHerniatedDiscHasConsultedDoctorsDetails = 'health-insured-herniated-disc-has-consulted-doctors-details',
  healthInsuredHerniatedDiscHasConsultedDoctorsProfession = 'health-insured-herniated-disc-has-consulted-doctors-profession',
  healthInsuredHerniatedDiscHasConsultedDoctorsProfessionDetails = 'health-insured-herniated-disc-has-consulted-doctors-profession-details',
  healthInsuredHerniatedDiscHasConsultedDoctorsName = 'health-insured-herniated-disc-has-consulted-doctors-name',
  healthInsuredHerniatedDiscHasConsultedDoctorsAddress = 'health-insured-herniated-disc-has-consulted-doctors-address',
  healthInsuredHerniatedDiscHasConsultedDoctorsFirstDate = 'health-insured-herniated-disc-has-consulted-doctors-first-date',
  healthInsuredHerniatedDiscHasConsultedDoctorsLastDate = 'health-insured-herniated-disc-has-consulted-doctors-last-date',
  healthInsuredHerniatedDiscHasConsultedDoctorsResult = 'health-insured-herniated-disc-has-consulted-doctors-result',
  healthInsuredHerniatedDiscHasConsultedDoctorsFrequencyNumber = 'health-insured-herniated-disc-has-consulted-doctors-frequency-number',
  healthInsuredHerniatedDiscHasConsultedDoctorsFrequency = 'health-insured-herniated-disc-has-consulted-doctors-frequency',
  healthInsuredHerniatedDiscHasConsultedDoctorFurtherConsultation = 'health-insured-herniated-disc-has-consulted-doctor-further-consultation',
  healthInsuredHerniatedDiscHasConsultedDoctorsNextConsultationDate = 'health-insured-herniated-disc-has-consulted-doctors-next-consultation-date',
  healthInsuredHerniatedDiscHasTakenTestsOrExamsDetails = 'health-insured-herniated-disc-has-taken-tests-or-exams-details',
  healthInsuredHerniatedDiscHasTakenTestsOrExamsType = 'health-insured-herniated-disc-has-taken-tests-or-exams-type',
  healthInsuredHerniatedDiscHasTakenTestsOrExamsDate = 'health-insured-herniated-disc-has-taken-tests-or-exams-date',
  healthInsuredHerniatedDiscHasTakenTestsOrExamsResult = 'health-insured-herniated-disc-has-taken-tests-or-exams-result',
  healthInsuredHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-herniated-disc-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredHerniatedDiscMedicationDetails = 'health-insured-herniated-disc-medication-details',
  healthInsuredHerniatedDiscMedicationName = 'health-insured-herniated-disc-medication-name',
  healthInsuredHerniatedDiscMedicationDosage = 'health-insured-herniated-disc-medication-dosage',
  healthInsuredHerniatedDiscMedicationStartDate = 'health-insured-herniated-disc-medication-start-date',
  healthInsuredHerniatedDiscMedicationEndDate = 'health-insured-herniated-disc-medication-end-date',

  healthInsuredScoliosisLordosisIsSingleEpisode = 'health-insured-scoliosis-lordosis-is-single-episode',
  healthInsuredScoliosisLordosisPreviousEpisodesCount = 'health-insured-scoliosis-lordosis-previous-episodes-count',
  healthInsuredScoliosisLordosisPainLocation = 'health-insured-scoliosis-lordosis-pain-location',
  healthInsuredScoliosisLordosisDateSymptoms = 'health-insured-scoliosis-lordosis-date-symptoms',
  healthInsuredScoliosisLordosisPainCause = 'health-insured-scoliosis-lordosis-pain-cause',
  healthInsuredScoliosisLordosisPainCauseDetails = 'health-insured-scoliosis-lordosis-pain-cause-details',
  healthInsuredScoliosisLordosisHasConsultedDoctors = 'health-insured-scoliosis-lordosis-has-consulted-doctors',
  healthInsuredScoliosisLordosisHasTakenTestsOrExams = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams',
  healthInsuredScoliosisLordosisMedicationWasPrescribed = 'health-insured-scoliosis-lordosis-medication-was-prescribed',
  healthInsuredScoliosisLordosisHadOperation = 'health-insured-scoliosis-lordosis-had-operation',
  healthInsuredScoliosisLordosisOperationPerformedOrPlanned = 'health-insured-scoliosis-lordosis-operation-performed-or-planned',
  healthInsuredScoliosisLordosisTypeOfOperationPerformed = 'health-insured-scoliosis-lordosis-type-of-operation-performed',
  healthInsuredScoliosisLordosisOperationPerformedDate = 'health-insured-scoliosis-lordosis-operation-performed-date',
  healthInsuredScoliosisLordosisTypeOfOperationPlanned = 'health-insured-scoliosis-lordosis-type-of-operation-planned',
  healthInsuredScoliosisLordosisOperationPlannedDate = 'health-insured-scoliosis-lordosis-operation-planned-date',
  healthInsuredScoliosisLordosisOperationUnknownDate = 'health-insured-scoliosis-lordosis-operation-unknown-date',
  healthInsuredScoliosisLordosisHadAbsence = 'health-insured-scoliosis-lordosis-had-absence',
  healthInsuredScoliosisLordosisAbsenceStartDate = 'health-insured-scoliosis-lordosis-absence-start-date',
  healthInsuredScoliosisLordosisAbsenceFrequencyNumber = 'health-insured-scoliosis-lordosis-absence-frequency-number',
  healthInsuredScoliosisLordosisAbsenceFrequency = 'health-insured-scoliosis-lordosis-absence-frequency',
  healthInsuredScoliosisLordosisAbsenceHasRecovered = 'health-insured-scoliosis-lordosis-absence-has-recovered',
  healthInsuredScoliosisLordosisAbsenceRecoveryDate = 'health-insured-scoliosis-lordosis-absence-recovery-date',
  healthInsuredScoliosisLordosisAbsenceNotRecoveredDetails = 'health-insured-scoliosis-lordosis-absence-not-recovered-details',

  healthInsuredScoliosisLordosisHasConsultedDoctorsDetails = 'health-insured-scoliosis-lordosis-has-consulted-doctors-details',
  healthInsuredScoliosisLordosisHasConsultedDoctorsProfession = 'health-insured-scoliosis-lordosis-has-consulted-doctors-profession',
  healthInsuredScoliosisLordosisHasConsultedDoctorsProfessionDetails = 'health-insured-scoliosis-lordosis-has-consulted-doctors-profession-details',
  healthInsuredScoliosisLordosisHasConsultedDoctorsName = 'health-insured-scoliosis-lordosis-has-consulted-doctors-name',
  healthInsuredScoliosisLordosisHasConsultedDoctorsAddress = 'health-insured-scoliosis-lordosis-has-consulted-doctors-address',
  healthInsuredScoliosisLordosisHasConsultedDoctorsFirstDate = 'health-insured-scoliosis-lordosis-has-consulted-doctors-first-date',
  healthInsuredScoliosisLordosisHasConsultedDoctorsLastDate = 'health-insured-scoliosis-lordosis-has-consulted-doctors-last-date',
  healthInsuredScoliosisLordosisHasConsultedDoctorsResult = 'health-insured-scoliosis-lordosis-has-consulted-doctors-result',
  healthInsuredScoliosisLordosisHasConsultedDoctorsFrequencyNumber = 'health-insured-scoliosis-lordosis-has-consulted-doctors-frequency-number',
  healthInsuredScoliosisLordosisHasConsultedDoctorsFrequency = 'health-insured-scoliosis-lordosis-has-consulted-doctors-frequency',
  healthInsuredScoliosisLordosisHasConsultedDoctorFurtherConsultation = 'health-insured-scoliosis-lordosis-has-consulted-doctor-further-consultation',
  healthInsuredScoliosisLordosisHasConsultedDoctorsNextConsultationDate = 'health-insured-scoliosis-lordosis-has-consulted-doctors-next-consultation-date',
  healthInsuredScoliosisLordosisHasTakenTestsOrExamsDetails = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams-details',
  healthInsuredScoliosisLordosisHasTakenTestsOrExamsType = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams-type',
  healthInsuredScoliosisLordosisHasTakenTestsOrExamsDate = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams-date',
  healthInsuredScoliosisLordosisHasTakenTestsOrExamsResult = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams-result',
  healthInsuredScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-scoliosis-lordosis-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredScoliosisLordosisMedicationDetails = 'health-insured-scoliosis-lordosis-medication-details',
  healthInsuredScoliosisLordosisMedicationName = 'health-insured-scoliosis-lordosis-medication-name',
  healthInsuredScoliosisLordosisMedicationDosage = 'health-insured-scoliosis-lordosis-medication-dosage',
  healthInsuredScoliosisLordosisMedicationStartDate = 'health-insured-scoliosis-lordosis-medication-start-date',
  healthInsuredScoliosisLordosisMedicationEndDate = 'health-insured-scoliosis-lordosis-medication-end-date',

  insuredArrested = 'insured-arrested',
  insuredArrestedState = 'insured-arrested-state',
  insuredArrestedDate = 'insured-arrested-date',
  insuredArrestedDetails = 'insured-arrested-details',
  insuredAwaitingTrial = 'insured-awaiting-trial',
  insuredConvicted = 'insured-convicted',
  insuredConvictedDate = 'insured-convicted-date',
  insuredConvictedSentence = 'insured-convicted-sentence',
  insuredParole = 'insured-parole',
  insuredDateCompletedProbation = 'insured-date-completed-probation',
  arrestedDrugAlcohol = 'arrested-drug-alcohol',
  arrestedDrugAlcoholDetails = 'arrested-drug-alcohol-details',
  insuredCriminalHistoryDetails = 'insured-criminal-history-details',

  healthInsuredLigamentTearMeniscusType = 'health-insured-ligament-tear-meniscus-type',
  healthInsuredLigamentTearMeniscusDiagnosisDate = 'health-insured-ligament-tear-meniscus-diagnosis-date',
  healthInsuredLigamentTearMeniscusIsSingleEpisode = 'health-insured-ligament-tear-meniscus-is-single-episode',
  healthInsuredLigamentTearMeniscusPreviousEpisodesCount = 'health-insured-ligament-tear-meniscus-previous-episodes-count',
  healthInsuredLigamentTearMeniscusDateSymptoms = 'health-insured-ligament-tear-meniscus-date-symptoms',
  healthInsuredLigamentTearMeniscusPainLocation = 'health-insured-ligament-tear-meniscus-pain-location',
  healthInsuredLigamentTearMeniscusPainLocationLeftRight = 'health-insured-ligament-tear-meniscus-pain-location-left-right',
  healthInsuredLigamentTearMeniscusPainLocationOther = 'health-insured-ligament-tear-meniscus-pain-location-other',
  healthInsuredLigamentTearMeniscusPainCause = 'health-insured-ligament-tear-meniscus-pain-cause',
  healthInsuredLigamentTearMeniscusPainCauseDetails = 'health-insured-ligament-tear-meniscus-pain-cause-details',
  healthInsuredLigamentTearMeniscusHasConsultedDoctors = 'health-insured-ligament-tear-meniscus-has-consulted-doctors',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExams = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams',
  healthInsuredLigamentTearMeniscusMedicationWasPrescribed = 'health-insured-ligament-tear-meniscus-medication-was-prescribed',
  healthInsuredLigamentTearMeniscusHadOperation = 'health-insured-ligament-tear-meniscus-had-operation',
  healthInsuredLigamentTearMeniscusOperationPerformedOrPlanned = 'health-insured-ligament-tear-meniscus-operation-performed-or-planned',
  healthInsuredLigamentTearMeniscusTypeOfOperationPerformed = 'health-insured-ligament-tear-meniscus-type-of-operation-performed',
  healthInsuredLigamentTearMeniscusOperationPerformedDate = 'health-insured-ligament-tear-meniscus-operation-performed-date',
  healthInsuredLigamentTearMeniscusTypeOfOperationPlanned = 'health-insured-ligament-tear-meniscus-type-of-operation-planned',
  healthInsuredLigamentTearMeniscusOperationPlannedDate = 'health-insured-ligament-tear-meniscus-operation-planned-date',
  healthInsuredLigamentTearMeniscusOperationUnknownDate = 'health-insured-ligament-tear-meniscus-operation-unknown-date',
  healthInsuredLigamentTearMeniscusHadAbsence = 'health-insured-ligament-tear-meniscus-had-absence',
  healthInsuredLigamentTearMeniscusAbsenceStartDate = 'health-insured-ligament-tear-meniscus-absence-start-date',
  healthInsuredLigamentTearMeniscusAbsenceFrequencyNumber = 'health-insured-ligament-tear-meniscus-absence-frequency-number',
  healthInsuredLigamentTearMeniscusAbsenceFrequency = 'health-insured-ligament-tear-meniscus-absence-frequency',
  healthInsuredLigamentTearMeniscusAbsenceHasRecovered = 'health-insured-ligament-tear-meniscus-absence-has-recovered',
  healthInsuredLigamentTearMeniscusAbsenceRecoveryDate = 'health-insured-ligament-tear-meniscus-absence-recovery-date',
  healthInsuredLigamentTearMeniscusAbsenceNotRecoveredDetails = 'health-insured-ligament-tear-meniscus-absence-not-recovered-details',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsDetails = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-details',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsProfession = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-profession',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsProfessionDetails = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-profession-details',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsName = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-name',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsAddress = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-address',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsFirstDate = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-first-date',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsLastDate = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-last-date',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsResult = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-result',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-frequency-number',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsFrequency = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-frequency',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorFurtherConsultation = 'health-insured-ligament-tear-meniscus-has-consulted-doctor-further-consultation',
  healthInsuredLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate = 'health-insured-ligament-tear-meniscus-has-consulted-doctors-next-consultation-date',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDetails = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams-details',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsType = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams-type',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDate = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams-date',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsResult = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams-result',
  healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-ligament-tear-meniscus-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredLigamentTearMeniscusMedicationDetails = 'health-insured-ligament-tear-meniscus-medication-details',
  healthInsuredLigamentTearMeniscusMedicationName = 'health-insured-ligament-tear-meniscus-medication-name',
  healthInsuredLigamentTearMeniscusMedicationDosage = 'health-insured-ligament-tear-meniscus-medication-dosage',
  healthInsuredLigamentTearMeniscusMedicationStartDate = 'health-insured-ligament-tear-meniscus-medication-start-date',
  healthInsuredLigamentTearMeniscusMedicationEndDate = 'health-insured-ligament-tear-meniscus-medication-end-date',

  healthInsuredCarpalTunnelIsSingleEpisode = 'health-insured-carpal-tunnel-is-single-episode',
  healthInsuredCarpalTunnelPreviousEpisodesCount = 'health-insured-carpal-tunnel-previous-episodes-count',
  healthInsuredCarpalTunnelDateSymptoms = 'health-insured-carpal-tunnel-date-symptoms',
  healthInsuredCarpalTunnelPainLocation = 'health-insured-carpal-tunnel-pain-location',
  healthInsuredCarpalTunnelPainLocationLeftRight = 'health-insured-carpal-tunnel-pain-location-left-right',
  healthInsuredCarpalTunnelPainLocationOther = 'health-insured-carpal-tunnel-pain-location-other',
  healthInsuredCarpalTunnelPainCause = 'health-insured-carpal-tunnel-pain-cause',
  healthInsuredCarpalTunnelPainCauseDetails = 'health-insured-carpal-tunnel-pain-cause-details',
  healthInsuredCarpalTunnelHasConsultedDoctors = 'health-insured-carpal-tunnel-has-consulted-doctors',
  healthInsuredCarpalTunnelHasTakenTestsOrExams = 'health-insured-carpal-tunnel-has-taken-tests-or-exams',
  healthInsuredCarpalTunnelMedicationWasPrescribed = 'health-insured-carpal-tunnel-medication-was-prescribed',
  healthInsuredCarpalTunnelHadOperation = 'health-insured-carpal-tunnel-had-operation',
  healthInsuredCarpalTunnelOperationPerformedOrPlanned = 'health-insured-carpal-tunnel-operation-performed-or-planned',
  healthInsuredCarpalTunnelTypeOfOperationPerformed = 'health-insured-carpal-tunnel-type-of-operation-performed',
  healthInsuredCarpalTunnelOperationPerformedDate = 'health-insured-carpal-tunnel-operation-performed-date',
  healthInsuredCarpalTunnelTypeOfOperationPlanned = 'health-insured-carpal-tunnel-type-of-operation-planned',
  healthInsuredCarpalTunnelOperationPlannedDate = 'health-insured-carpal-tunnel-operation-planned-date',
  healthInsuredCarpalTunnelOperationUnknownDate = 'health-insured-carpal-tunnel-operation-unknown-date',
  healthInsuredCarpalTunnelHadAbsence = 'health-insured-carpal-tunnel-had-absence',
  healthInsuredCarpalTunnelAbsenceStartDate = 'health-insured-carpal-tunnel-absence-start-date',
  healthInsuredCarpalTunnelAbsenceFrequencyNumber = 'health-insured-carpal-tunnel-absence-frequency-number',
  healthInsuredCarpalTunnelAbsenceFrequency = 'health-insured-carpal-tunnel-absence-frequency',
  healthInsuredCarpalTunnelAbsenceHasRecovered = 'health-insured-carpal-tunnel-absence-has-recovered',
  healthInsuredCarpalTunnelAbsenceRecoveryDate = 'health-insured-carpal-tunnel-absence-recovery-date',
  healthInsuredCarpalTunnelAbsenceNotRecoveredDetails = 'health-insured-carpal-tunnel-absence-not-recovered-details',
  healthInsuredCarpalTunnelHasConsultedDoctorsDetails = 'health-insured-carpal-tunnel-has-consulted-doctors-details',
  healthInsuredCarpalTunnelHasConsultedDoctorsProfession = 'health-insured-carpal-tunnel-has-consulted-doctors-profession',
  healthInsuredCarpalTunnelHasConsultedDoctorsProfessionDetails = 'health-insured-carpal-tunnel-has-consulted-doctors-profession-details',
  healthInsuredCarpalTunnelHasConsultedDoctorsName = 'health-insured-carpal-tunnel-has-consulted-doctors-name',
  healthInsuredCarpalTunnelHasConsultedDoctorsAddress = 'health-insured-carpal-tunnel-has-consulted-doctors-address',
  healthInsuredCarpalTunnelHasConsultedDoctorsFirstDate = 'health-insured-carpal-tunnel-has-consulted-doctors-first-date',
  healthInsuredCarpalTunnelHasConsultedDoctorsLastDate = 'health-insured-carpal-tunnel-has-consulted-doctors-last-date',
  healthInsuredCarpalTunnelHasConsultedDoctorsResult = 'health-insured-carpal-tunnel-has-consulted-doctors-result',
  healthInsuredCarpalTunnelHasConsultedDoctorsFrequencyNumber = 'health-insured-carpal-tunnel-has-consulted-doctors-frequency-number',
  healthInsuredCarpalTunnelHasConsultedDoctorsFrequency = 'health-insured-carpal-tunnel-has-consulted-doctors-frequency',
  healthInsuredCarpalTunnelHasConsultedDoctorFurtherConsultation = 'health-insured-carpal-tunnel-has-consulted-doctor-further-consultation',
  healthInsuredCarpalTunnelHasConsultedDoctorsNextConsultationDate = 'health-insured-carpal-tunnel-has-consulted-doctors-next-consultation-date',
  healthInsuredCarpalTunnelHasTakenTestsOrExamsDetails = 'health-insured-carpal-tunnel-has-taken-tests-or-exams-details',
  healthInsuredCarpalTunnelHasTakenTestsOrExamsType = 'health-insured-carpal-tunnel-has-taken-tests-or-exams-type',
  healthInsuredCarpalTunnelHasTakenTestsOrExamsDate = 'health-insured-carpal-tunnel-has-taken-tests-or-exams-date',
  healthInsuredCarpalTunnelHasTakenTestsOrExamsResult = 'health-insured-carpal-tunnel-has-taken-tests-or-exams-result',
  healthInsuredCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-carpal-tunnel-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredCarpalTunnelMedicationDetails = 'health-insured-carpal-tunnel-medication-details',
  healthInsuredCarpalTunnelMedicationName = 'health-insured-carpal-tunnel-medication-name',
  healthInsuredCarpalTunnelMedicationDosage = 'health-insured-carpal-tunnel-medication-dosage',
  healthInsuredCarpalTunnelMedicationStartDate = 'health-insured-carpal-tunnel-medication-start-date',
  healthInsuredCarpalTunnelMedicationEndDate = 'health-insured-carpal-tunnel-medication-end-date',

  healthInsuredBackNeckOtherIsSingleEpisode = 'health-insured-back-neck-other-is-single-episode',
  healthInsuredBackNeckOtherPreviousEpisodesCount = 'health-insured-back-neck-other-previous-episodes-count',
  healthInsuredBackNeckOtherPainLocation = 'health-insured-back-neck-other-pain-location',
  healthInsuredBackNeckOtherDateSymptoms = 'health-insured-back-neck-other-date-symptoms',
  healthInsuredBackNeckOtherPainCause = 'health-insured-back-neck-other-pain-cause',
  healthInsuredBackNeckOtherPainCauseDetails = 'health-insured-back-neck-other-pain-cause-details',
  healthInsuredBackNeckOtherHasConsultedDoctors = 'health-insured-back-neck-other-has-consulted-doctors',
  healthInsuredBackNeckOtherHasTakenTestsOrExams = 'health-insured-back-neck-other-has-taken-tests-or-exams',
  healthInsuredBackNeckOtherMedicationWasPrescribed = 'health-insured-back-neck-other-medication-was-prescribed',
  healthInsuredBackNeckOtherHadOperation = 'health-insured-back-neck-other-had-operation',
  healthInsuredBackNeckOtherOperationPerformedOrPlanned = 'health-insured-back-neck-other-operation-performed-or-planned',
  healthInsuredBackNeckOtherTypeOfOperationPerformed = 'health-insured-back-neck-other-type-of-operation-performed',
  healthInsuredBackNeckOtherOperationPerformedDate = 'health-insured-back-neck-other-operation-performed-date',
  healthInsuredBackNeckOtherTypeOfOperationPlanned = 'health-insured-back-neck-other-type-of-operation-planned',
  healthInsuredBackNeckOtherOperationPlannedDate = 'health-insured-back-neck-other-operation-planned-date',
  healthInsuredBackNeckOtherOperationUnknownDate = 'health-insured-back-neck-other-operation-unknown-date',
  healthInsuredBackNeckOtherHadAbsence = 'health-insured-back-neck-other-had-absence',
  healthInsuredBackNeckOtherAbsenceStartDate = 'health-insured-back-neck-other-absence-start-date',
  healthInsuredBackNeckOtherAbsenceFrequencyNumber = 'health-insured-back-neck-other-absence-frequency-number',
  healthInsuredBackNeckOtherAbsenceFrequency = 'health-insured-back-neck-other-absence-frequency',
  healthInsuredBackNeckOtherAbsenceHasRecovered = 'health-insured-back-neck-other-absence-has-recovered',
  healthInsuredBackNeckOtherAbsenceRecoveryDate = 'health-insured-back-neck-other-absence-recovery-date',
  healthInsuredBackNeckOtherAbsenceNotRecoveredDetails = 'health-insured-back-neck-other-absence-not-recovered-details',

  healthInsuredBackNeckOtherHasConsultedDoctorsDetails = 'health-insured-back-neck-other-has-consulted-doctors-details',
  healthInsuredBackNeckOtherHasConsultedDoctorsProfession = 'health-insured-back-neck-other-has-consulted-doctors-profession',
  healthInsuredBackNeckOtherHasConsultedDoctorsProfessionDetails = 'health-insured-back-neck-other-has-consulted-doctors-profession-details',
  healthInsuredBackNeckOtherHasConsultedDoctorsName = 'health-insured-back-neck-other-has-consulted-doctors-name',
  healthInsuredBackNeckOtherHasConsultedDoctorsAddress = 'health-insured-back-neck-other-has-consulted-doctors-address',
  healthInsuredBackNeckOtherHasConsultedDoctorsFirstDate = 'health-insured-back-neck-other-has-consulted-doctors-first-date',
  healthInsuredBackNeckOtherHasConsultedDoctorsLastDate = 'health-insured-back-neck-other-has-consulted-doctors-last-date',
  healthInsuredBackNeckOtherHasConsultedDoctorsResult = 'health-insured-back-neck-other-has-consulted-doctors-result',
  healthInsuredBackNeckOtherHasConsultedDoctorsFrequencyNumber = 'health-insured-back-neck-other-has-consulted-doctors-frequency-number',
  healthInsuredBackNeckOtherHasConsultedDoctorsFrequency = 'health-insured-back-neck-other-has-consulted-doctors-frequency',
  healthInsuredBackNeckOtherHasConsultedDoctorFurtherConsultation = 'health-insured-back-neck-other-has-consulted-doctor-further-consultation',
  healthInsuredBackNeckOtherHasConsultedDoctorsNextConsultationDate = 'health-insured-back-neck-other-has-consulted-doctors-next-consultation-date',
  healthInsuredBackNeckOtherHasTakenTestsOrExamsDetails = 'health-insured-back-neck-other-has-taken-tests-or-exams-details',
  healthInsuredBackNeckOtherHasTakenTestsOrExamsType = 'health-insured-back-neck-other-has-taken-tests-or-exams-type',
  healthInsuredBackNeckOtherHasTakenTestsOrExamsDate = 'health-insured-back-neck-other-has-taken-tests-or-exams-date',
  healthInsuredBackNeckOtherHasTakenTestsOrExamsResult = 'health-insured-back-neck-other-has-taken-tests-or-exams-result',
  healthInsuredBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-back-neck-other-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredBackNeckOtherMedicationDetails = 'health-insured-back-neck-other-medication-details',
  healthInsuredBackNeckOtherMedicationName = 'health-insured-back-neck-other-medication-name',
  healthInsuredBackNeckOtherMedicationDosage = 'health-insured-back-neck-other-medication-dosage',
  healthInsuredBackNeckOtherMedicationStartDate = 'health-insured-back-neck-other-medication-start-date',
  healthInsuredBackNeckOtherMedicationEndDate = 'health-insured-back-neck-other-medication-end-date',

  healthInsuredMusculoskeletalHasCondition = 'health-insured-musculoskeletal-has-condition',
  healthInsuredMusculoskeletalConditions = 'health-insured-musculoskeletal-conditions',
  healthInsuredMusculoskeletalConditionsOther = 'health-insured-musculoskeletal-conditions-other',

  healthInsuredArthritisIsSingleEpisode = 'health-insured-arthritis-is-single-episode',
  healthInsuredArthritisPreviousEpisodesCount = 'health-insured-arthritis-previous-episodes-count',
  healthInsuredArthritisDateSymptoms = 'health-insured-arthritis-date-symptoms',
  healthInsuredArthritisPainLocation = 'health-insured-arthritis-pain-location',
  healthInsuredArthritisPainLocationLeftRight = 'health-insured-arthritis-pain-location-left-right',
  healthInsuredArthritisPainLocationOther = 'health-insured-arthritis-pain-location-other',
  healthInsuredArthritisPainCause = 'health-insured-arthritis-pain-cause',
  healthInsuredArthritisPainCauseDetails = 'health-insured-arthritis-pain-cause-details',
  healthInsuredArthritisHasConsultedDoctors = 'health-insured-arthritis-has-consulted-doctors',
  healthInsuredArthritisHasTakenTestsOrExams = 'health-insured-arthritis-has-taken-tests-or-exams',
  healthInsuredArthritisMedicationWasPrescribed = 'health-insured-arthritis-medication-was-prescribed',
  healthInsuredArthritisHadOperation = 'health-insured-arthritis-had-operation',
  healthInsuredArthritisOperationPerformedOrPlanned = 'health-insured-arthritis-operation-performed-or-planned',
  healthInsuredArthritisTypeOfOperationPerformed = 'health-insured-arthritis-type-of-operation-performed',
  healthInsuredArthritisOperationPerformedDate = 'health-insured-arthritis-operation-performed-date',
  healthInsuredArthritisTypeOfOperationPlanned = 'health-insured-arthritis-type-of-operation-planned',
  healthInsuredArthritisOperationPlannedDate = 'health-insured-arthritis-operation-planned-date',
  healthInsuredArthritisOperationUnknownDate = 'health-insured-arthritis-operation-unknown-date',
  healthInsuredArthritisHadAbsence = 'health-insured-arthritis-had-absence',
  healthInsuredArthritisAbsenceStartDate = 'health-insured-arthritis-absence-start-date',
  healthInsuredArthritisAbsenceFrequencyNumber = 'health-insured-arthritis-absence-frequency-number',
  healthInsuredArthritisAbsenceFrequency = 'health-insured-arthritis-absence-frequency',
  healthInsuredArthritisAbsenceHasRecovered = 'health-insured-arthritis-absence-has-recovered',
  healthInsuredArthritisAbsenceRecoveryDate = 'health-insured-arthritis-absence-recovery-date',
  healthInsuredArthritisAbsenceNotRecoveredDetails = 'health-insured-arthritis-absence-not-recovered-details',

  healthInsuredArthritisHasConsultedDoctorsDetails = 'health-insured-arthritis-has-consulted-doctors-details',
  healthInsuredArthritisHasConsultedDoctorsProfession = 'health-insured-arthritis-has-consulted-doctors-profession',
  healthInsuredArthritisHasConsultedDoctorsProfessionDetails = 'health-insured-arthritis-has-consulted-doctors-profession-details',
  healthInsuredArthritisHasConsultedDoctorsName = 'health-insured-arthritis-has-consulted-doctors-name',
  healthInsuredArthritisHasConsultedDoctorsAddress = 'health-insured-arthritis-has-consulted-doctors-address',
  healthInsuredArthritisHasConsultedDoctorsFirstDate = 'health-insured-arthritis-has-consulted-doctors-first-date',
  healthInsuredArthritisHasConsultedDoctorsLastDate = 'health-insured-arthritis-has-consulted-doctors-last-date',
  healthInsuredArthritisHasConsultedDoctorsResult = 'health-insured-arthritis-has-consulted-doctors-result',
  healthInsuredArthritisHasConsultedDoctorsFrequencyNumber = 'health-insured-arthritis-has-consulted-doctors-frequency-number',
  healthInsuredArthritisHasConsultedDoctorsFrequency = 'health-insured-arthritis-has-consulted-doctors-frequency',
  healthInsuredArthritisHasConsultedDoctorFurtherConsultation = 'health-insured-arthritis-has-consulted-doctor-further-consultation',
  healthInsuredArthritisHasConsultedDoctorsNextConsultationDate = 'health-insured-arthritis-has-consulted-doctors-next-consultation-date',

  healthInsuredArthritisHasTakenTestsOrExamsDetails = 'health-insured-arthritis-has-taken-tests-or-exams-details',
  healthInsuredArthritisHasTakenTestsOrExamsType = 'health-insured-arthritis-has-taken-tests-or-exams-type',
  healthInsuredArthritisHasTakenTestsOrExamsDate = 'health-insured-arthritis-has-taken-tests-or-exams-date',
  healthInsuredArthritisHasTakenTestsOrExamsResult = 'health-insured-arthritis-has-taken-tests-or-exams-result',
  healthInsuredArthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-arthritis-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredArthritisMedicationDetails = 'health-insured-arthritis-medication-details',
  healthInsuredArthritisMedicationName = 'health-insured-arthritis-medication-name',
  healthInsuredArthritisMedicationDosage = 'health-insured-arthritis-medication-dosage',
  healthInsuredArthritisMedicationStartDate = 'health-insured-arthritis-medication-start-date',
  healthInsuredArthritisMedicationEndDate = 'health-insured-arthritis-medication-end-date',

  activeMilitary = 'active-military',
  militaryMemberNationalGuard = 'military-member-national-guard',
  militaryCapacity = 'military-capacity',
  militaryCapacityOther = 'military-capacity-other',
  militaryCareer = 'military-career',
  militaryMemberReserves = 'military-member-reserves',
  militaryReservesOther = 'military-reserves-other',
  militaryRank = 'military-rank',
  militaryPayGrade = 'military-pay-grade',
  militaryUnitDesignation = 'military-unit-designation',
  militaryOccupationalSpecialty = 'military-occupational-specialty',
  militaryMosDetails = 'military-mos-details',
  militaryMosAviation = 'military-mos-aviation',
  militaryOverseasDuty = 'military-overseas-duty',
  militaryOverseasDutyDetails = 'military-overseas-duty-details',
  militaryPcsDetails = 'military-pcs-details',
  militaryPcsDate = 'military-pcs-date',

  aviationExperienceDetailsForPast05Years = 'aviation-experience-details-for-past-05years',
  aviationChoices = 'aviation-choices',
  aviationOther = 'aviation-other',
  aviationLicenceType = 'aviation-licence-type',
  aviationLicenceDate = 'aviation-licence-date',
  aviationCertificateType = 'aviation-certificate-type',
  aviationAnnualFlyingHours = 'aviation-annual-flying-hours',
  aviationSoloFlyingHours = 'aviation-solo-flying-hours',
  aviationDateOfLastFlight = 'aviation-date-of-last-flight',

  healthInsuredBackNeckHasCondition = 'health-insured-back-neck-has-condition',
  healthInsuredBackNeckConditions = 'health-insured-back-neck-conditions',
  healthInsuredBackNeckConditionsOther = 'health-insured-back-neck-conditions-other',

  healthInsuredOsteoarthritisIsSingleEpisode = 'health-insured-osteoarthritis-is-single-episode',
  healthInsuredOsteoarthritisPreviousEpisodesCount = 'health-insured-osteoarthritis-previous-episodes-count',
  healthInsuredOsteoarthritisPainLocation = 'health-insured-osteoarthritis-pain-location',
  healthInsuredOsteoarthritisDateSymptoms = 'health-insured-osteoarthritis-date-symptoms',
  healthInsuredOsteoarthritisPainCause = 'health-insured-osteoarthritis-pain-cause',
  healthInsuredOsteoarthritisPainCauseDetails = 'health-insured-osteoarthritis-pain-cause-details',
  healthInsuredOsteoarthritisHasConsultedDoctors = 'health-insured-osteoarthritis-has-consulted-doctors',
  healthInsuredOsteoarthritisHasTakenTestsOrExams = 'health-insured-osteoarthritis-has-taken-tests-or-exams',
  healthInsuredOsteoarthritisMedicationWasPrescribed = 'health-insured-osteoarthritis-medication-was-prescribed',
  healthInsuredOsteoarthritisHadOperation = 'health-insured-osteoarthritis-had-operation',
  healthInsuredOsteoarthritisOperationPerformedOrPlanned = 'health-insured-osteoarthritis-operation-performed-or-planned',
  healthInsuredOsteoarthritisTypeOfOperationPerformed = 'health-insured-osteoarthritis-type-of-operation-performed',
  healthInsuredOsteoarthritisOperationPerformedDate = 'health-insured-osteoarthritis-operation-performed-date',
  healthInsuredOsteoarthritisTypeOfOperationPlanned = 'health-insured-osteoarthritis-type-of-operation-planned',
  healthInsuredOsteoarthritisOperationPlannedDate = 'health-insured-osteoarthritis-operation-planned-date',
  healthInsuredOsteoarthritisOperationUnknownDate = 'health-insured-osteoarthritis-operation-unknown-date',
  healthInsuredOsteoarthritisHadAbsence = 'health-insured-osteoarthritis-had-absence',
  healthInsuredOsteoarthritisAbsenceStartDate = 'health-insured-osteoarthritis-absence-start-date',
  healthInsuredOsteoarthritisAbsenceFrequencyNumber = 'health-insured-osteoarthritis-absence-frequency-number',
  healthInsuredOsteoarthritisAbsenceFrequency = 'health-insured-osteoarthritis-absence-frequency',
  healthInsuredOsteoarthritisAbsenceHasRecovered = 'health-insured-osteoarthritis-absence-has-recovered',
  healthInsuredOsteoarthritisAbsenceRecoveryDate = 'health-insured-osteoarthritis-absence-recovery-date',
  healthInsuredOsteoarthritisAbsenceNotRecoveredDetails = 'health-insured-osteoarthritis-absence-not-recovered-details',

  healthInsuredOsteoarthritisHasConsultedDoctorsDetails = 'health-insured-osteoarthritis-has-consulted-doctors-details',
  healthInsuredOsteoarthritisHasConsultedDoctorsProfession = 'health-insured-osteoarthritis-has-consulted-doctors-profession',
  healthInsuredOsteoarthritisHasConsultedDoctorsProfessionDetails = 'health-insured-osteoarthritis-has-consulted-doctors-profession-details',
  healthInsuredOsteoarthritisHasConsultedDoctorsName = 'health-insured-osteoarthritis-has-consulted-doctors-name',
  healthInsuredOsteoarthritisHasConsultedDoctorsAddress = 'health-insured-osteoarthritis-has-consulted-doctors-address',
  healthInsuredOsteoarthritisHasConsultedDoctorsFirstDate = 'health-insured-osteoarthritis-has-consulted-doctors-first-date',
  healthInsuredOsteoarthritisHasConsultedDoctorsLastDate = 'health-insured-osteoarthritis-has-consulted-doctors-last-date',
  healthInsuredOsteoarthritisHasConsultedDoctorsResult = 'health-insured-osteoarthritis-has-consulted-doctors-result',
  healthInsuredOsteoarthritisHasConsultedDoctorsFrequencyNumber = 'health-insured-osteoarthritis-has-consulted-doctors-frequency-number',
  healthInsuredOsteoarthritisHasConsultedDoctorsFrequency = 'health-insured-osteoarthritis-has-consulted-doctors-frequency',
  healthInsuredOsteoarthritisHasConsultedDoctorFurtherConsultation = 'health-insured-osteoarthritis-has-consulted-doctor-further-consultation',
  healthInsuredOsteoarthritisHasConsultedDoctorsNextConsultationDate = 'health-insured-osteoarthritis-has-consulted-doctors-next-consultation-date',
  healthInsuredOsteoarthritisHasTakenTestsOrExamsDetails = 'health-insured-osteoarthritis-has-taken-tests-or-exams-details',
  healthInsuredOsteoarthritisHasTakenTestsOrExamsType = 'health-insured-osteoarthritis-has-taken-tests-or-exams-type',
  healthInsuredOsteoarthritisHasTakenTestsOrExamsDate = 'health-insured-osteoarthritis-has-taken-tests-or-exams-date',
  healthInsuredOsteoarthritisHasTakenTestsOrExamsResult = 'health-insured-osteoarthritis-has-taken-tests-or-exams-result',
  healthInsuredOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-osteoarthritis-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredOsteoarthritisMedicationDetails = 'health-insured-osteoarthritis-medication-details',
  healthInsuredOsteoarthritisMedicationName = 'health-insured-osteoarthritis-medication-name',
  healthInsuredOsteoarthritisMedicationDosage = 'health-insured-osteoarthritis-medication-dosage',
  healthInsuredOsteoarthritisMedicationStartDate = 'health-insured-osteoarthritis-medication-start-date',
  healthInsuredOsteoarthritisMedicationEndDate = 'health-insured-osteoarthritis-medication-end-date',

  healthInsuredSpondylitisAnkylosingIsSingleEpisode = 'health-insured-spondylitis-ankylosing-is-single-episode',
  healthInsuredSpondylitisAnkylosingPreviousEpisodesCount = 'health-insured-spondylitis-ankylosing-previous-episodes-count',
  healthInsuredSpondylitisAnkylosingPainLocation = 'health-insured-spondylitis-ankylosing-pain-location',
  healthInsuredSpondylitisAnkylosingDateSymptoms = 'health-insured-spondylitis-ankylosing-date-symptoms',
  healthInsuredSpondylitisAnkylosingPainCause = 'health-insured-spondylitis-ankylosing-pain-cause',
  healthInsuredSpondylitisAnkylosingPainCauseDetails = 'health-insured-spondylitis-ankylosing-pain-cause-details',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctors = 'health-insured-spondylitis-ankylosing-has-consulted-doctors',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExams = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams',
  healthInsuredSpondylitisAnkylosingMedicationWasPrescribed = 'health-insured-spondylitis-ankylosing-medication-was-prescribed',
  healthInsuredSpondylitisAnkylosingHadOperation = 'health-insured-spondylitis-ankylosing-had-operation',
  healthInsuredSpondylitisAnkylosingOperationPerformedOrPlanned = 'health-insured-spondylitis-ankylosing-operation-performed-or-planned',
  healthInsuredSpondylitisAnkylosingTypeOfOperationPerformed = 'health-insured-spondylitis-ankylosing-type-of-operation-performed',
  healthInsuredSpondylitisAnkylosingOperationPerformedDate = 'health-insured-spondylitis-ankylosing-operation-performed-date',
  healthInsuredSpondylitisAnkylosingTypeOfOperationPlanned = 'health-insured-spondylitis-ankylosing-type-of-operation-planned',
  healthInsuredSpondylitisAnkylosingOperationPlannedDate = 'health-insured-spondylitis-ankylosing-operation-planned-date',
  healthInsuredSpondylitisAnkylosingOperationUnknownDate = 'health-insured-spondylitis-ankylosing-operation-unknown-date',
  healthInsuredSpondylitisAnkylosingHadAbsence = 'health-insured-spondylitis-ankylosing-had-absence',
  healthInsuredSpondylitisAnkylosingAbsenceStartDate = 'health-insured-spondylitis-ankylosing-absence-start-date',
  healthInsuredSpondylitisAnkylosingAbsenceFrequencyNumber = 'health-insured-spondylitis-ankylosing-absence-frequency-number',
  healthInsuredSpondylitisAnkylosingAbsenceFrequency = 'health-insured-spondylitis-ankylosing-absence-frequency',
  healthInsuredSpondylitisAnkylosingAbsenceHasRecovered = 'health-insured-spondylitis-ankylosing-absence-has-recovered',
  healthInsuredSpondylitisAnkylosingAbsenceRecoveryDate = 'health-insured-spondylitis-ankylosing-absence-recovery-date',
  healthInsuredSpondylitisAnkylosingAbsenceNotRecoveredDetails = 'health-insured-spondylitis-ankylosing-absence-not-recovered-details',

  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsDetails = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-details',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsProfession = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-profession',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-profession-details',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsName = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-name',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsAddress = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-address',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFirstDate = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-first-date',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsLastDate = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-last-date',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsResult = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-result',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-frequency-number',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFrequency = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-frequency',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation = 'health-insured-spondylitis-ankylosing-has-consulted-doctor-further-consultation',
  healthInsuredSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate = 'health-insured-spondylitis-ankylosing-has-consulted-doctors-next-consultation-date',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDetails = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams-details',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsType = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams-type',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDate = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams-date',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsResult = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams-result',
  healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-spondylitis-ankylosing-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredSpondylitisAnkylosingMedicationDetails = 'health-insured-spondylitis-ankylosing-medication-details',
  healthInsuredSpondylitisAnkylosingMedicationName = 'health-insured-spondylitis-ankylosing-medication-name',
  healthInsuredSpondylitisAnkylosingMedicationDosage = 'health-insured-spondylitis-ankylosing-medication-dosage',
  healthInsuredSpondylitisAnkylosingMedicationStartDate = 'health-insured-spondylitis-ankylosing-medication-start-date',
  healthInsuredSpondylitisAnkylosingMedicationEndDate = 'health-insured-spondylitis-ankylosing-medication-end-date',

  healthInsuredDiscDegenerationIsSingleEpisode = 'health-insured-disc-degeneration-is-single-episode',
  healthInsuredDiscDegenerationPreviousEpisodesCount = 'health-insured-disc-degeneration-previous-episodes-count',
  healthInsuredDiscDegenerationPainLocation = 'health-insured-disc-degeneration-pain-location',
  healthInsuredDiscDegenerationDateSymptoms = 'health-insured-disc-degeneration-date-symptoms',
  healthInsuredDiscDegenerationPainCause = 'health-insured-disc-degeneration-pain-cause',
  healthInsuredDiscDegenerationPainCauseDetails = 'health-insured-disc-degeneration-pain-cause-details',
  healthInsuredDiscDegenerationHasConsultedDoctors = 'health-insured-disc-degeneration-has-consulted-doctors',
  healthInsuredDiscDegenerationHasTakenTestsOrExams = 'health-insured-disc-degeneration-has-taken-tests-or-exams',
  healthInsuredDiscDegenerationMedicationWasPrescribed = 'health-insured-disc-degeneration-medication-was-prescribed',
  healthInsuredDiscDegenerationHadOperation = 'health-insured-disc-degeneration-had-operation',
  healthInsuredDiscDegenerationOperationPerformedOrPlanned = 'health-insured-disc-degeneration-operation-performed-or-planned',
  healthInsuredDiscDegenerationTypeOfOperationPerformed = 'health-insured-disc-degeneration-type-of-operation-performed',
  healthInsuredDiscDegenerationOperationPerformedDate = 'health-insured-disc-degeneration-operation-performed-date',
  healthInsuredDiscDegenerationTypeOfOperationPlanned = 'health-insured-disc-degeneration-type-of-operation-planned',
  healthInsuredDiscDegenerationOperationPlannedDate = 'health-insured-disc-degeneration-operation-planned-date',
  healthInsuredDiscDegenerationOperationUnknownDate = 'health-insured-disc-degeneration-operation-unknown-date',
  healthInsuredDiscDegenerationHadAbsence = 'health-insured-disc-degeneration-had-absence',
  healthInsuredDiscDegenerationAbsenceStartDate = 'health-insured-disc-degeneration-absence-start-date',
  healthInsuredDiscDegenerationAbsenceFrequencyNumber = 'health-insured-disc-degeneration-absence-frequency-number',
  healthInsuredDiscDegenerationAbsenceFrequency = 'health-insured-disc-degeneration-absence-frequency',
  healthInsuredDiscDegenerationAbsenceHasRecovered = 'health-insured-disc-degeneration-absence-has-recovered',
  healthInsuredDiscDegenerationAbsenceRecoveryDate = 'health-insured-disc-degeneration-absence-recovery-date',
  healthInsuredDiscDegenerationAbsenceNotRecoveredDetails = 'health-insured-disc-degeneration-absence-not-recovered-details',

  healthInsuredDiscDegenerationHasConsultedDoctorsDetails = 'health-insured-disc-degeneration-has-consulted-doctors-details',
  healthInsuredDiscDegenerationHasConsultedDoctorsProfession = 'health-insured-disc-degeneration-has-consulted-doctors-profession',
  healthInsuredDiscDegenerationHasConsultedDoctorsProfessionDetails = 'health-insured-disc-degeneration-has-consulted-doctors-profession-details',
  healthInsuredDiscDegenerationHasConsultedDoctorsName = 'health-insured-disc-degeneration-has-consulted-doctors-name',
  healthInsuredDiscDegenerationHasConsultedDoctorsAddress = 'health-insured-disc-degeneration-has-consulted-doctors-address',
  healthInsuredDiscDegenerationHasConsultedDoctorsFirstDate = 'health-insured-disc-degeneration-has-consulted-doctors-first-date',
  healthInsuredDiscDegenerationHasConsultedDoctorsLasttDate = 'health-insured-disc-degeneration-has-consulted-doctors-last-date',
  healthInsuredDiscDegenerationHasConsultedDoctorsResult = 'health-insured-disc-degeneration-has-consulted-doctors-result',
  healthInsuredDiscDegenerationHasConsultedDoctorsFrequencyNumber = 'health-insured-disc-degeneration-has-consulted-doctors-frequency-number',
  healthInsuredDiscDegenerationHasConsultedDoctorsFrequency = 'health-insured-disc-degeneration-has-consulted-doctors-frequency',
  healthInsuredDiscDegenerationHasConsultedDoctorFurtherConsultation = 'health-insured-disc-degeneration-has-consulted-doctor-further-consultation',
  healthInsuredDiscDegenerationHasConsultedDoctorsNextConsultationDate = 'health-insured-disc-degeneration-has-consulted-doctors-next-consultation-date',
  healthInsuredDiscDegenerationHasTakenTestsOrExamsDetails = 'health-insured-disc-degeneration-has-taken-tests-or-exams-details',
  healthInsuredDiscDegenerationHasTakenTestsOrExamsType = 'health-insured-disc-degeneration-has-taken-tests-or-exams-type',
  healthInsuredDiscDegenerationHasTakenTestsOrExamsDate = 'health-insured-disc-degeneration-has-taken-tests-or-exams-date',
  healthInsuredDiscDegenerationHasTakenTestsOrExamsResult = 'health-insured-disc-degeneration-has-taken-tests-or-exams-result',
  healthInsuredDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-disc-degeneration-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredDiscDegenerationMedicationDetails = 'health-insured-disc-degeneration-medication-details',
  healthInsuredDiscDegenerationMedicationName = 'health-insured-disc-degeneration-medication-name',
  healthInsuredDiscDegenerationMedicationDosage = 'health-insured-disc-degeneration-medication-dosage',
  healthInsuredDiscDegenerationMedicationStartDate = 'health-insured-disc-degeneration-medication-start-date',
  healthInsuredDiscDegenerationMedicationEndDate = 'health-insured-disc-degeneration-medication-end-date',

  healthInsuredFractureIsSingleEpisode = 'health-insured-fracture-is-single-episode',
  healthInsuredFracturePreviousEpisodesCount = 'health-insured-fracture-previous-episodes-count',
  healthInsuredFracturePainLocation = 'health-insured-fracture-pain-location',
  healthInsuredFractureDateSymptoms = 'health-insured-fracture-date-symptoms',
  healthInsuredFracturePainCause = 'health-insured-fracture-pain-cause',
  healthInsuredFracturePainCauseDetails = 'health-insured-fracture-pain-cause-details',
  healthInsuredFractureHasConsultedDoctors = 'health-insured-fracture-has-consulted-doctors',
  healthInsuredFractureHasTakenTestsOrExams = 'health-insured-fracture-has-taken-tests-or-exams',
  healthInsuredFractureMedicationWasPrescribed = 'health-insured-fracture-medication-was-prescribed',
  healthInsuredFractureHadOperation = 'health-insured-fracture-had-operation',
  healthInsuredFractureOperationPerformedOrPlanned = 'health-insured-fracture-operation-performed-or-planned',
  healthInsuredFractureTypeOfOperationPerformed = 'health-insured-fracture-type-of-operation-performed',
  healthInsuredFractureOperationPerformedDate = 'health-insured-fracture-operation-performed-date',
  healthInsuredFractureTypeOfOperationPlanned = 'health-insured-fracture-type-of-operation-planned',
  healthInsuredFractureOperationPlannedDate = 'health-insured-fracture-operation-planned-date',
  healthInsuredFractureOperationUnknownDate = 'health-insured-fracture-operation-unknown-date',
  healthInsuredFractureHadAbsence = 'health-insured-fracture-had-absence',
  healthInsuredFractureAbsenceStartDate = 'health-insured-fracture-absence-start-date',
  healthInsuredFractureAbsenceFrequencyNumber = 'health-insured-fracture-absence-frequency-number',
  healthInsuredFractureAbsenceFrequency = 'health-insured-fracture-absence-frequency',
  healthInsuredFractureAbsenceHasRecovered = 'health-insured-fracture-absence-has-recovered',
  healthInsuredFractureAbsenceRecoveryDate = 'health-insured-fracture-absence-recovery-date',
  healthInsuredFractureAbsenceNotRecoveredDetails = 'health-insured-fracture-absence-not-recovered-details',

  healthInsuredBipolarDisorderIsSingleEpisode = 'health-insured-bipolar-disorder-is-single-episode',
  healthInsuredBipolarDisorderDiagnosticDate = 'health-insured-bipolar-disorder-diagnostic-date',
  healthInsuredBipolarDisorderMedicationWasPrescribed = 'health-insured-bipolar-disorder-medication-was-prescribed',
  healthInsuredBipolarDisorderHasConsultedDoctors = 'health-insured-bipolar-disorder-has-consulted-doctors',
  healthInsuredBipolarDisorderHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-bipolar-disorder-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredBipolarDisorderWentToEmergencyDepartment = 'health-insured-bipolar-disorder-went-to-emergency-department',
  healthInsuredBipolarDisorderWasHospitalized = 'health-insured-bipolar-disorder-was-hospitalized',
  healthInsuredBipolarDisorderHadSuicidalThoughtsAndAttempt = 'health-insured-bipolar-disorder-had-suicidal-thoughts-and-attempt',
  healthInsuredBipolarDisorderSuicidalThoughtsAndAttemptChoices = 'health-insured-bipolar-disorder-suicidal-thoughts-and-attempt-choices',
  healthInsuredBipolarDisorderSuicidalThoughtsFrequencyNumber = 'health-insured-bipolar-disorder-suicidal-thoughts-frequency-number',
  healthInsuredBipolarDisorderSuicidalThoughtsFrequency = 'health-insured-bipolar-disorder-suicidal-thoughts-frequency',
  healthInsuredBipolarDisorderSuicideAttemptsNumber = 'health-insured-bipolar-disorder-suicide-attempts-number',
  healthInsuredBipolarDisorderHadAbsence = 'health-insured-bipolar-disorder-had-absence',
  healthInsuredBipolarDisorderAbsenceHasRecovered = 'health-insured-bipolar-disorder-absence-has-recovered',
  healthInsuredBipolarDisorderAbsenceRecoveryDate = 'health-insured-bipolar-disorder-absence-recovery-date',
  healthInsuredBipolarDisorderAbsenceNotRecoveredDetails = 'health-insured-bipolar-disorder-absence-not-recovered-details',
  healthInsuredBipolarDisorderMedicationDetails = 'health-insured-bipolar-disorder-medication-details',
  healthInsuredBipolarDisorderMedicationName = 'health-insured-bipolar-disorder-medication-name',
  healthInsuredBipolarDisorderMedicationDosage = 'health-insured-bipolar-disorder-medication-dosage',
  healthInsuredBipolarDisorderMedicationStartDate = 'health-insured-bipolar-disorder-medication-start-date',
  healthInsuredBipolarDisorderMedicationEndDate = 'health-insured-bipolar-disorder-medication-end-date',
  healthInsuredBipolarDisorderMedicationStillUses = 'health-insured-bipolar-disorder-medication-still-uses',
  healthInsuredBipolarDisorderMedicationHasChanged = 'health-insured-bipolar-disorder-medication-has-changed',
  healthInsuredBipolarDisorderMedicationHasChangedDetails = 'health-insured-bipolar-disorder-medication-has-changed-details',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsDetails = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-details',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsProfession = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-profession',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsProfessionOther = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-profession-other',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsName = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-name',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsAddress = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-address',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsFirstDate = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-first-date',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsLastDate = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-last-date',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsResult = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-result',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-frequency-number',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsFrequency = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-frequency',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-further-consultation',
  healthInsuredBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-bipolar-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthInsuredBipolarDisorderWentToEmergencyDepartmentDetails = 'health-insured-bipolar-disorder-went-to-emergency-department-details',
  healthInsuredBipolarDisorderWentToEmergencyDepartmentDate = 'health-insured-bipolar-disorder-went-to-emergency-department-date',
  healthInsuredBipolarDisorderWentToEmergencyDepartmentLocation = 'health-insured-bipolar-disorder-went-to-emergency-department-location',
  healthInsuredBipolarDisorderWasHospitalizedDetails = 'health-insured-bipolar-disorder-was-hospitalized-details',
  healthInsuredBipolarDisorderWasHospitalizedDate = 'health-insured-bipolar-disorder-was-hospitalized-date',
  healthInsuredBipolarDisorderWasHospitalizedFrequencyNumber = 'health-insured-bipolar-disorder-was-hospitalized-frequency-number',
  healthInsuredBipolarDisorderWasHospitalizedFrequency = 'health-insured-bipolar-disorder-was-hospitalized-frequency',
  healthInsuredBipolarDisorderWasHospitalizedLocation = 'health-insured-bipolar-disorder-was-hospitalized-location',
  healthInsuredBipolarDisorderSuicidalThoughtsDetails = 'health-insured-bipolar-disorder-suicidal-thoughts-details',
  healthInsuredBipolarDisorderSuicidalThoughtsDate = 'health-insured-bipolar-disorder-suicidal-thoughts-date',
  healthInsuredBipolarDisorderSuicideAttemptsDetails = 'health-insured-bipolar-disorder-suicide-attempts-details',
  healthInsuredBipolarDisorderSuicideAttemptsDate = 'health-insured-bipolar-disorder-suicide-attempts-date',
  healthInsuredBipolarDisorderHadAbsenceDetails = 'health-insured-bipolar-disorder-had-absence-details',
  healthInsuredBipolarDisorderAbsenceStartDate = 'health-insured-bipolar-disorder-absence-start-date',
  healthInsuredBipolarDisorderAbsenceFrequencyNumber = 'health-insured-bipolar-disorder-absence-frequency-number',
  healthInsuredBipolarDisorderAbsenceFrequency = 'health-insured-bipolar-disorder-absence-frequency',

  healthInsuredPsychosisIsSingleEpisode = 'health-insured-psychosis-is-single-episode',
  healthInsuredPsychosisDiagnosticDate = 'health-insured-psychosis-diagnostic-date',
  healthInsuredPsychosisMedicationWasPrescribed = 'health-insured-psychosis-medication-was-prescribed',
  healthInsuredPsychosisHasConsultedDoctors = 'health-insured-psychosis-has-consulted-doctors',
  healthInsuredPsychosisHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-psychosis-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredPsychosisWentToEmergencyDepartment = 'health-insured-psychosis-went-to-emergency-department',
  healthInsuredPsychosisWasHospitalized = 'health-insured-psychosis-was-hospitalized',
  healthInsuredPsychosisHadSuicidalThoughtsAndAttempt = 'health-insured-psychosis-had-suicidal-thoughts-and-attempt',
  healthInsuredPsychosisSuicidalThoughtsAndAttemptChoices = 'health-insured-psychosis-suicidal-thoughts-and-attempt-choices',
  healthInsuredPsychosisSuicidalThoughtsFrequencyNumber = 'health-insured-psychosis-suicidal-thoughts-frequency-number',
  healthInsuredPsychosisSuicidalThoughtsFrequency = 'health-insured-psychosis-suicidal-thoughts-frequency',
  healthInsuredPsychosisSuicideAttemptsNumber = 'health-insured-psychosis-suicide-attempts-number',
  healthInsuredPsychosisHadAbsence = 'health-insured-psychosis-had-absence',
  healthInsuredPsychosisAbsenceHasRecovered = 'health-insured-psychosis-absence-has-recovered',
  healthInsuredPsychosisAbsenceRecoveryDate = 'health-insured-psychosis-absence-recovery-date',
  healthInsuredPsychosisAbsenceNotRecoveredDetails = 'health-insured-psychosis-absence-not-recovered-details',
  healthInsuredPsychosisMedicationDetails = 'health-insured-psychosis-medication-details',
  healthInsuredPsychosisMedicationName = 'health-insured-psychosis-medication-name',
  healthInsuredPsychosisMedicationDosage = 'health-insured-psychosis-medication-dosage',
  healthInsuredPsychosisMedicationStartDate = 'health-insured-psychosis-medication-start-date',
  healthInsuredPsychosisMedicationEndDate = 'health-insured-psychosis-medication-end-date',
  healthInsuredPsychosisMedicationStillUses = 'health-insured-psychosis-medication-still-uses',
  healthInsuredPsychosisMedicationHasChanged = 'health-insured-psychosis-medication-has-changed',
  healthInsuredPsychosisMedicationHasChangedDetails = 'health-insured-psychosis-medication-has-changed-details',
  healthInsuredPsychosisHasOrWillConsultDoctorsDetails = 'health-insured-psychosis-has-or-will-consult-doctors-details',
  healthInsuredPsychosisHasOrWillConsultDoctorsProfession = 'health-insured-psychosis-has-or-will-consult-doctors-profession',
  healthInsuredPsychosisHasOrWillConsultDoctorsProfessionOther = 'health-insured-psychosis-has-or-will-consult-doctors-profession-other',
  healthInsuredPsychosisHasOrWillConsultDoctorsName = 'health-insured-psychosis-has-or-will-consult-doctors-name',
  healthInsuredPsychosisHasOrWillConsultDoctorsAddress = 'health-insured-psychosis-has-or-will-consult-doctors-address',
  healthInsuredPsychosisHasOrWillConsultDoctorsFirstDate = 'health-insured-psychosis-has-or-will-consult-doctors-first-date',
  healthInsuredPsychosisHasOrWillConsultDoctorsLastDate = 'health-insured-psychosis-has-or-will-consult-doctors-last-date',
  healthInsuredPsychosisHasOrWillConsultDoctorsResult = 'health-insured-psychosis-has-or-will-consult-doctors-result',
  healthInsuredPsychosisHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-psychosis-has-or-will-consult-doctors-frequency-number',
  healthInsuredPsychosisHasOrWillConsultDoctorsFrequency = 'health-insured-psychosis-has-or-will-consult-doctors-frequency',
  healthInsuredPsychosisHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-psychosis-has-or-will-consult-doctors-further-consultation',
  healthInsuredPsychosisHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-psychosis-has-or-will-consult-doctors-next-consultation-date',
  healthInsuredPsychosisWentToEmergencyDepartmentDetails = 'health-insured-psychosis-went-to-emergency-department-details',
  healthInsuredPsychosisWentToEmergencyDepartmentDate = 'health-insured-psychosis-went-to-emergency-department-date',
  healthInsuredPsychosisWentToEmergencyDepartmentLocation = 'health-insured-psychosis-went-to-emergency-department-location',
  healthInsuredPsychosisWasHospitalizedDetails = 'health-insured-psychosis-was-hospitalized-details',
  healthInsuredPsychosisWasHospitalizedDate = 'health-insured-psychosis-was-hospitalized-date',
  healthInsuredPsychosisWasHospitalizedFrequencyNumber = 'health-insured-psychosis-was-hospitalized-frequency-number',
  healthInsuredPsychosisWasHospitalizedFrequency = 'health-insured-psychosis-was-hospitalized-frequency',
  healthInsuredPsychosisWasHospitalizedLocation = 'health-insured-psychosis-was-hospitalized-location',
  healthInsuredPsychosisSuicidalThoughtsDetails = 'health-insured-psychosis-suicidal-thoughts-details',
  healthInsuredPsychosisSuicidalThoughtsDate = 'health-insured-psychosis-suicidal-thoughts-date',
  healthInsuredPsychosisSuicideAttemptsDetails = 'health-insured-psychosis-suicide-attempts-details',
  healthInsuredPsychosisSuicideAttemptsDate = 'health-insured-psychosis-suicide-attempts-date',
  healthInsuredPsychosisHadAbsenceDetails = 'health-insured-psychosis-had-absence-details',
  healthInsuredPsychosisAbsenceStartDate = 'health-insured-psychosis-absence-start-date',
  healthInsuredPsychosisAbsenceFrequencyNumber = 'health-insured-psychosis-absence-frequency-number',
  healthInsuredPsychosisAbsenceFrequency = 'health-insured-psychosis-absence-frequency',

  healthInsuredSuicideAttemptHasMoreThanOneAttempt = 'health-insured-suicide-attempt-has-more-than-one-attempt',
  healthInsuredSuicideAttemptHowManyAttempts = 'health-insured-suicide-attempt-how-many-attempts',
  healthInsuredSuicideAttemptMedicationDetails = 'health-insured-suicide-attempt-medication-details',
  healthInsuredSuicideAttemptMedicationName = 'health-insured-suicide-attempt-medication-name',
  healthInsuredSuicideAttemptMedicationDosage = 'health-insured-suicide-attempt-medication-dosage',
  healthInsuredSuicideAttemptMedicationStartDate = 'health-insured-suicide-attempt-medication-start-date',
  healthInsuredSuicideAttemptMedicationEndDate = 'health-insured-suicide-attempt-medication-end-date',
  healthInsuredSuicideAttemptMedicationStillUses = 'health-insured-suicide-attempt-medication-still-uses',
  healthInsuredSuicideAttemptMedicationHasChanged = 'health-insured-suicide-attempt-medication-has-changed',
  healthInsuredSuicideAttemptMedicationHasChangedDetails = 'health-insured-suicide-attempt-medication-has-changed-details',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsDetails = 'health-insured-suicide-attempt-has-or-will-consult-doctors-details',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsProfession = 'health-insured-suicide-attempt-has-or-will-consult-doctors-profession',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsProfessionOther = 'health-insured-suicide-attempt-has-or-will-consult-doctors-profession-other',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsName = 'health-insured-suicide-attempt-has-or-will-consult-doctors-name',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsAddress = 'health-insured-suicide-attempt-has-or-will-consult-doctors-address',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsFirstDate = 'health-insured-suicide-attempt-has-or-will-consult-doctors-first-date',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsLastDate = 'health-insured-suicide-attempt-has-or-will-consult-doctors-last-date',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsResult = 'health-insured-suicide-attempt-has-or-will-consult-doctors-result',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-suicide-attempt-has-or-will-consult-doctors-frequency-number',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsFrequency = 'health-insured-suicide-attempt-has-or-will-consult-doctors-frequency',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-suicide-attempt-has-or-will-consult-doctors-further-consultation',
  healthInsuredSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-suicide-attempt-next-has-or-will-consult-doctors-next-consultation-date',
  healthInsuredSuicideAttemptWasHospitalizedDetails = 'health-insured-suicide-attempt-was-hospitalized-details',
  healthInsuredSuicideAttemptWasHospitalizedDate = 'health-insured-suicide-attempt-was-hospitalized-date',
  healthInsuredSuicideAttemptWasHospitalizedFrequencyNumber = 'health-insured-suicide-attempt-was-hospitalized-frequency-number',
  healthInsuredSuicideAttemptWasHospitalizedFrequency = 'health-insured-suicide-attempt-was-hospitalized-frequency',
  healthInsuredSuicideAttemptWasHospitalizedLocation = 'health-insured-suicide-attempt-was-hospitalized-location',
  healthInsuredSuicideAttemptMultipleAttemptsDetails = 'health-insured-suicide-attempt-multiple-attempts-details',
  healthInsuredSuicideAttemptMultipleAttemptsDate = 'health-insured-suicide-attempt-multiple-attempts-date',
  healthInsuredSuicideAttemptMultipleAttemptsReason = 'health-insured-suicide-attempt-multiple-attempts-reason',

  healthInsuredAddAdhdIsControlled = 'health-insured-add-adhd-is-controlled',
  healthInsuredAddAdhdMedicationDetails = 'health-insured-add-adhd-medication-details',
  healthInsuredAddAdhdMedicationName = 'health-insured-add-adhd-medication-name',
  healthInsuredAddAdhdMedicationDosage = 'health-insured-add-adhd-medication-dosage',
  healthInsuredAddAdhdMedicationStartDate = 'health-insured-add-adhd-medication-start-date',
  healthInsuredAddAdhdMedicationEndDate = 'health-insured-add-adhd-medication-end-date',
  healthInsuredAddAdhdMedicationStillUses = 'health-insured-add-adhd-medication-still-uses',
  healthInsuredAddAdhdMedicationHasChanged = 'health-insured-add-adhd-medication-has-changed',
  healthInsuredAddAdhdMedicationHasChangedDetails = 'health-insured-add-adhd-medication-has-changed-details',
  healthInsuredAddAdhdHasOrWillConsultDoctorsDetails = 'health-insured-add-adhd-has-or-will-consult-doctors-details',
  healthInsuredAddAdhdHasOrWillConsultDoctorsProfession = 'health-insured-add-adhd-has-or-will-consult-doctors-profession',
  healthInsuredAddAdhdHasOrWillConsultDoctorsProfessionOther = 'health-insured-add-adhd-has-or-will-consult-doctors-profession-other',
  healthInsuredAddAdhdHasOrWillConsultDoctorsName = 'health-insured-add-adhd-has-or-will-consult-doctors-name',
  healthInsuredAddAdhdHasOrWillConsultDoctorsAddress = 'health-insured-add-adhd-has-or-will-consult-doctors-address',
  healthInsuredAddAdhdHasOrWillConsultDoctorsFirstDate = 'health-insured-add-adhd-has-or-will-consult-doctors-first-date',
  healthInsuredAddAdhdHasOrWillConsultDoctorsLastDate = 'health-insured-add-adhd-has-or-will-consult-doctors-last-date',
  healthInsuredAddAdhdHasOrWillConsultDoctorsResult = 'health-insured-add-adhd-has-or-will-consult-doctors-result',
  healthInsuredAddAdhdHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-add-adhd-has-or-will-consult-doctors-frequency-number',
  healthInsuredAddAdhdHasOrWillConsultDoctorsFrequency = 'health-insured-add-adhd-has-or-will-consult-doctors-frequency',
  healthInsuredAddAdhdHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-add-adhd-has-or-will-consult-doctors-further-consultation',
  healthInsuredAddAdhdHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-add-adhd-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredAutismMedicationWasPrescribed = 'health-insured-autism-medication-was-prescribed',
  healthInsuredAutismHasConsultedDoctors = 'health-insured-autism-has-consulted-doctors',
  healthInsuredAutismHasOrWillOtherDoctorsOrTherapists = 'health-insured-autism-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredAutismPerformsDailyActivitiesWithoutHelp = 'health-insured-autism-performs-daily-activities-without-help',
  healthInsuredAutismPerformsDailyActivitiesWithoutHelpDetails = 'health-insured-autism-performs-daily-activities-without-help-details',
  healthInsuredAutismNeedsAccommodation = 'health-insured-autism-needs-accommodation',
  healthInsuredAutismAccommodationDetails = 'health-insured-autism-accommodation-details',
  healthInsuredAutismMedicationDetails = 'health-insured-autism-medication-details',
  healthInsuredAutismMedicationName = 'health-insured-autism-medication-name',
  healthInsuredAutismMedicationDosage = 'health-insured-autism-medication-dosage',
  healthInsuredAutismMedicationStartDate = 'health-insured-autism-medication-start-date',
  healthInsuredAutismMedicationEndDate = 'health-insured-autism-medication-end-date',
  healthInsuredAutismMedicationStillUses = 'health-insured-autism-medication-still-uses',
  healthInsuredAutismMedicationHasChanged = 'health-insured-autism-medication-has-changed',
  healthInsuredAutismMedicationHasChangedDetails = 'health-insured-autism-medication-has-changed-details',
  healthInsuredAutismHasOrWillConsultDoctorsDetails = 'health-insured-autism-has-or-will-consult-doctors-details',
  healthInsuredAutismHasOrWillConsultDoctorsProfession = 'health-insured-autism-has-or-will-consult-doctors-profession',
  healthInsuredAutismHasOrWillConsultDoctorsProfessionOther = 'health-insured-autism-has-or-will-consult-doctors-profession-other',
  healthInsuredAutismHasOrWillConsultDoctorsName = 'health-insured-autism-has-or-will-consult-doctors-name',
  healthInsuredAutismHasOrWillConsultDoctorsAddress = 'health-insured-autism-has-or-will-consult-doctors-address',
  healthInsuredAutismHasOrWillConsultDoctorsFirstDate = 'health-insured-autism-has-or-will-consult-doctors-first-date',
  healthInsuredAutismHasOrWillConsultDoctorsLastDate = 'health-insured-autism-has-or-will-consult-doctors-last-date',
  healthInsuredAutismHasOrWillConsultDoctorsResult = 'health-insured-autism-has-or-will-consult-doctors-result',
  healthInsuredAutismHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-autism-has-or-will-consult-doctors-frequency-number',
  healthInsuredAutismHasOrWillConsultDoctorsFrequency = 'health-insured-autism-has-or-will-consult-doctors-frequency',
  healthInsuredAutismHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-autism-has-or-will-consult-doctors-further-consultation',
  healthInsuredAutismHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-autism-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredIntellectualImpairmentMedicationWasPrescribed = 'health-insured-intellectual-impairment-medication-was-prescribed',
  healthInsuredIntellectualImpairmentHasConsultedDoctors = 'health-insured-intellectual-impairment-has-consulted-doctors',
  healthInsuredIntellectualImpairmentHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-intellectual-impairment-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredIntellectualImpairmentPerfromsDailyActivitiesWithoutHelp = 'health-insured-intellectual-impairment-performs-daily-activities-without-help',
  healthInsuredIntellectualImpairmentPerfromsDailyActivitiesWithoutHelpDetails = 'health-insured-intellectual-impairment-performs-daily-activities-without-help-details',
  healthInsuredIntellectualImpairmentNeedsAccommodation = 'health-insured-intellectual-impairment-needs-accommodation',
  healthInsuredIntellectualImpairmentAccommodationDetails = 'health-insured-intellectual-impairment-accommodation-details',
  healthInsuredIntellectualImpairmentMedicationDetails = 'health-insured-intellectual-impairment-medication-details',
  healthInsuredIntellectualImpairmentMedicationName = 'health-insured-intellectual-impairment-medication-name',
  healthInsuredIntellectualImpairmentMedicationDosage = 'health-insured-intellectual-impairment-medication-dosage',
  healthInsuredIntellectualImpairmentMedicationStartDate = 'health-insured-intellectual-impairment-medication-start-date',
  healthInsuredIntellectualImpairmentMedicationEndDate = 'health-insured-intellectual-impairment-medication-end-date',
  healthInsuredIntellectualImpairmentMedicationStillUses = 'health-insured-intellectual-impairment-medication-still-uses',
  healthInsuredIntellectualImpairmentMedicationHasChanged = 'health-insured-intellectual-impairment-medication-has-changed',
  healthInsuredIntellectualImpairmentMedicationHasChangedDetails = 'health-insured-intellectual-impairment-medication-has-changed-details',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsDetails = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-details',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsProfession = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-profession',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-profession-other',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsName = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-name',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsAddress = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-address',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFirstDate = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-first-date',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsLastDate = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-last-date',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsResult = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-result',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-frequency-number',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFrequency = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-frequency',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-further-consultation',
  healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-intellectual-impairment-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredDownSyndromeMedicationWasPrescribed = 'health-insured-down-syndrome-medication-was-prescribed',
  healthInsuredDownSyndromeHasConsultedDoctors = 'health-insured-down-syndrome-has-consulted-doctors',
  healthInsuredDownSyndromeHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-down-syndrome-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredDownSyndromePerformsDailyActivitiesWithoutHelp = 'health-insured-down-syndrome-performs-daily-activities-without-help',
  healthInsuredDownSyndromePerformsDailyActivitiesWithoutHelpDetails = 'health-insured-down-syndrome-performs-daily-activities-without-help-details',
  healthInsuredDownSyndromeNeedsAccommodation = 'health-insured-down-syndrome-needs-accommodation',
  healthInsuredDownSyndromeAccommodationDetails = 'health-insured-down-syndrome-accommodation-details',
  healthInsuredDownSyndromeMedicationDetails = 'health-insured-down-syndrome-medication-details',
  healthInsuredDownSyndromeMedicationName = 'health-insured-down-syndrome-medication-name',
  healthInsuredDownSyndromeMedicationDosage = 'health-insured-down-syndrome-medication-dosage',
  healthInsuredDownSyndromeMedicationStartDate = 'health-insured-down-syndrome-medication-start-date',
  healthInsuredDownSyndromeMedicationEndDate = 'health-insured-down-syndrome-medication-end-date',
  healthInsuredDownSyndromeMedicationStillUses = 'health-insured-down-syndrome-medication-still-uses',
  healthInsuredDownSyndromeMedicationHasChanged = 'health-insured-down-syndrome-medication-has-changed',
  healthInsuredDownSyndromeMedicationHasChangedDetails = 'health-insured-down-syndrome-medication-has-changed-details',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsDetails = 'health-insured-down-syndrome-has-or-will-consult-doctors-details',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsProfession = 'health-insured-down-syndrome-has-or-will-consult-doctors-profession',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsProfessionOther = 'health-insured-down-syndrome-has-or-will-consult-doctors-profession-other',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsName = 'health-insured-down-syndrome-has-or-will-consult-doctors-name',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsAddress = 'health-insured-down-syndrome-has-or-will-consult-doctors-address',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsFirstDate = 'health-insured-down-syndrome-has-or-will-consult-doctors-first-date',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsLastDate = 'health-insured-down-syndrome-has-or-will-consult-doctors-last-date',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsResult = 'health-insured-down-syndrome-has-or-will-consult-doctors-result',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-down-syndrome-has-or-will-consult-doctors-frequency-number',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsFrequency = 'health-insured-down-syndrome-has-or-will-consult-doctors-frequency',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-down-syndrome-has-or-will-consult-doctors-further-consultation',
  healthInsuredDownSyndromeHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-down-syndrome-has-or-will-consult-doctors-next-consultation-date',

  healthInsuredMentalHealthHasCondition = 'health-insured-mental-health-has-condition',
  healthInsuredMentalHealthChoices = 'health-insured-mental-health-choices',

  healthInsuredMentalHealthOtherDisorderType = 'health-insured-mental-health-other-disorder-type',
  healthInsuredMentalHealthOtherDate = 'health-insured-mental-health-other-date',
  healthInsuredMentalHealthOtherHasDiagnostic = 'health-insured-mental-health-other-has-diagnostic',
  healthInsuredMentalHealthOtherHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-mental-health-other-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredMentalHealthOtherWentToEmergencyDepartment = 'health-insured-mental-health-other-went-to-emergency-department',
  healthInsuredMentalHealthOtherHasTakenTestsOrExamsDetails = 'health-insured-mental-health-other-has-taken-tests-or-exams-details',
  healthInsuredMentalHealthOtherTestsOrExamsDetails = 'health-insured-mental-health-other-tests-or-exams-details',
  healthInsuredMentalHealthOtherOtherTestsOrExamsDetailsDate = 'health-insured-mental-health-other-other-tests-or-exams-details-date',
  healthInsuredMentalHealthOtherOtherTestsOrExamsDetailsResult = 'health-insured-mental-health-other-other-tests-or-exams-details-result',
  healthInsuredMentalHealthOtherMedicationDetails = 'health-insured-mental-health-other-medication-details',
  healthInsuredMentalHealthOtherMedicationName = 'health-insured-mental-health-other-medication-name',
  healthInsuredMentalHealthOtherMedicationDosage = 'health-insured-mental-health-other-medication-dosage',
  healthInsuredMentalHealthOtherMedicationStartDate = 'health-insured-mental-health-other-medication-start-date',
  healthInsuredMentalHealthOtherMedicationEndDate = 'health-insured-mental-health-other-medication-end-date',
  healthInsuredMentalHealthOtherMedicationStillUses = 'health-insured-mental-health-other-medication-still-uses',
  healthInsuredMentalHealthOtherMedicationHasChanged = 'health-insured-mental-health-other-medication-has-changed',
  healthInsuredMentalHealthOtherMedicationHasChangedDetails = 'health-insured-mental-health-other-medication-has-changed-details',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsDetails = 'health-insured-mental-health-other-has-or-will-consult-doctors-details',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsProfession = 'health-insured-mental-health-other-has-or-will-consult-doctors-profession',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsProfessionOther = 'health-insured-mental-health-other-has-or-will-consult-doctors-profession-other',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsName = 'health-insured-mental-health-other-has-or-will-consult-doctors-name',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsAddress = 'health-insured-mental-health-other-has-or-will-consult-doctors-address',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFirstDate = 'health-insured-mental-health-other-has-or-will-consult-doctors-first-date',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsLastDate = 'health-insured-mental-health-other-has-or-will-consult-doctors-last-date',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsResult = 'health-insured-mental-health-other-has-or-will-consult-doctors-result',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-mental-health-other-has-or-will-consult-doctors-frequency-number',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFrequency = 'health-insured-mental-health-other-has-or-will-consult-doctors-frequency',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-mental-health-other-has-or-will-consult-doctors-further-consultation',
  healthInsuredMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-mental-health-other-has-or-will-consult-doctors-next-consultation-date',
  healthInsuredMentalHealthOtherWentToEmergencyDepartmentDetails = 'health-insured-mental-health-other-went-to-emergency-department-details',
  healthInsuredMentalHealthOtherWentToEmergencyDepartmentDate = 'health-insured-mental-health-other-went-to-emergency-department-date',
  healthInsuredMentalHealthOtherWentToEmergencyDepartmentLocation = 'health-insured-mental-health-other-went-to-emergency-department-location',
  healthInsuredMentalHealthOtherWasHospitalizedDetails = 'health-insured-mental-health-other-was-hospitalized-details',
  healthInsuredMentalHealthOtherWasHospitalizedDate = 'health-insured-mental-health-other-was-hospitalized-date',
  healthInsuredMentalHealthOtherWasHospitalizedFrequencyNumber = 'health-insured-mental-health-other-was-hospitalized-frequency-number',
  healthInsuredMentalHealthOtherWasHospitalizedFrequency = 'health-insured-mental-health-other-was-hospitalized-frequency',
  healthInsuredMentalHealthOtherWasHospitalizedLocation = 'health-insured-mental-health-other-was-hospitalized-location',
  healthInsuredMentalHealthOtherhadAbsenceDetails = 'health-insured-mental-health-other-had-absence-details',
  healthInsuredMentalHealthOtherAbsenceStartDate = 'health-insured-mental-health-other-absence-start-date',
  healthInsuredMentalHealthOtherAbsenceFrequencyNumber = 'health-insured-mental-health-other-absence-frequency-number',
  healthInsuredMentalHealthOtherAbsenceFrequency = 'health-insured-mental-health-other-absence-frequency',

  healthInsuredAlsDetails = 'health-insured-als-details',

  healthInsuredEatingDisorderDiagnosticDate = 'health-insured-eating-disorder-diagnostic-date',
  healthInsuredEatingDisorderMedicationWasPrescribed = 'health-insured-eating-disorder-medication-was-prescribed',
  healthInsuredEatingDisorderHasConsultedDoctors = 'health-insured-eating-disorder-has-consulted-doctors',
  healthInsuredEatingDisorderHasOrWillConsultOtherDoctorsOrTherapists = 'health-insured-eating-disorder-has-or-will-consult-other-doctors-or-therapists',
  healthInsuredEatingDisorderWasHospitalized = 'health-insured-eating-disorder-was-hospitalized',
  healthInsuredEatingDisorderIsControlled = 'health-insured-eating-disorder-is-controlled',
  healthInsuredEatingDisorderNotControlledDetails = 'health-insured-eating-disorder-not-controlled-details',
  healthInsuredEatingDisorderHasStableWeightDate = 'health-insured-eating-disorder-has-stable-weight-date',
  healthInsuredEatingDisorderMedicationDetails = 'health-insured-eating-disorder-medication-details',
  healthInsuredEatingDisorderMedicationName = 'health-insured-eating-disorder-medication-name',
  healthInsuredEatingDisorderMedicationDosage = 'health-insured-eating-disorder-medication-dosage',
  healthInsuredEatingDisorderMedicationStartDate = 'health-insured-eating-disorder-medication-start-date',
  healthInsuredEatingDisorderMedicationEndDate = 'health-insured-eating-disorder-medication-end-date',
  healthInsuredEatingDisorderMedicationStillUses = 'health-insured-eating-disorder-medication-still-uses',
  healthInsuredEatingDisorderMedicationHasChanged = 'health-insured-eating-disorder-medication-has-changed',
  healthInsuredEatingDisorderMedicationHasChangedDetails = 'health-insured-eating-disorder-medication-has-changed-details',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsDetails = 'health-insured-eating-disorder-has-or-will-consult-doctors-details',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsProfession = 'health-insured-eating-disorder-has-or-will-consult-doctors-profession',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsProfessionOther = 'health-insured-eating-disorder-has-or-will-consult-doctors-profession-other',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsName = 'health-insured-eating-disorder-has-or-will-consult-doctors-name',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsAddress = 'health-insured-eating-disorder-has-or-will-consult-doctors-address',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsFirstDate = 'health-insured-eating-disorder-has-or-will-consult-doctors-first-date',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsLastDate = 'health-insured-eating-disorder-has-or-will-consult-doctors-last-date',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsResult = 'health-insured-eating-disorder-has-or-will-consult-doctors-result',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsFrequencyNumber = 'health-insured-eating-disorder-has-or-will-consult-doctors-frequency-number',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsFrequency = 'health-insured-eating-disorder-has-or-will-consult-doctors-frequency',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsFurtherConsultation = 'health-insured-eating-disorder-has-or-will-consult-doctors-further-consultation',
  healthInsuredEatingDisorderHasOrWillConsultDoctorsNextConsultationDate = 'health-insured-eating-disorder-has-or-will-consult-doctors-next-consultation-date',
  healthInsuredEatingDisorderWasHospitalizedDetails = 'health-insured-eating-disorder-was-hospitalized-details',
  healthInsuredEatingDisorderWasHospitalizedDate = 'health-insured-eating-disorder-was-hospitalized-date',
  healthInsuredEatingDisorderWasHospitalizedFrequencyNumber = 'health-insured-eating-disorder-was-hospitalized-frequency-number',
  healthInsuredEatingDisorderWasHospitalizedFrequency = 'health-insured-eating-disorder-was-hospitalized-frequency',
  healthInsuredEatingDisorderWasHospitalizedLocation = 'health-insured-eating-disorder-was-hospitalized-location',

  healthInsuredPartialOrTotalBlindnessDetails = 'health-insured-partial-or-total-blindness-details',
  healthInsuredMacularDegenerationDetails = 'health-insured-macular-degeneration-details',
  healthInsuredGlaucomaDetails = 'health-insured-glaucoma-details',
  healthInsuredGerdHasConsulted = 'health-insured-gerd-has-consulted',
  healthInsuredGenitourinaryHasCondition = 'health-insured-genitourinary-has-condition',
  healthInsuredGenitourinaryChoices = 'health-insured-genitourinary-choices',
  healthInsuredKidneyStoneLastEpisode = 'health-insured-kidney-stone-last-episode',
  healthInsuredKidneyStoneEpisodesCount = 'health-insured-kidney-stone-episodes-count',
  healthInsuredKidneyStoneHasRecovered = 'health-insured-kidney-stone-has-recovered',
  healthInsuredDisordersOfTheKidneyDetails = 'health-insured-disorders-of-the-kidney-details',
  healthInsuredBladderDetails = 'health-insured-bladder-details',
  healthInsuredProstateDetails = 'health-insured-prostate-details',
  healthInsuredGenitalOrgansTestsDetails = 'health-insured-genital-organs-tests-details',

  healthInsuredNeurologicalDisordersHasCondition = 'health-insured-neurological-disorders-has-condition',
  healthInsuredNeurologicalDisordersChoices = 'health-insured-neurological-disorders-choices',
  healthInsuredAnyOtherCognitiveImpairmentDetails = 'health-insured-any-other-cognitive-impairment-details',
  healthInsuredHadUnexplainedWeightLoss = 'health-insured-had-unexplained-weight-loss',
  healthInsuredVoluntaryWeightLossAmount = 'health-insured-voluntary-weight-loss-amount',
  healthInsuredUnexplainedWeightLossDetails = 'health-insured-unexplained-weight-loss-details',
  healthInsuredSleepApneaSymptomsSeverity = 'health-insured-sleep-apnea-symptoms-severity',
  healthInsuredObstrusiveSleepApneaDiagnosisDate = 'health-insured-obstrusive-sleep-apnea-diagnosis-date',

  healthInsuredCpapTreatmentUsageHours = 'health-insured-cpap-treatment-usage-hours',
  healthInsuredCpapTreatmentUsageWeek = 'health-insured-cpap-treatment-usage-week',
  healthInsuredCpapTreatmentIsControlled = 'health-insured-cpap-treatment-is-controlled',
  healthInsuredCpapTreatment = 'health-insured-cpap-treatment',
  healthInsuredCpapNoTreatmentReason = 'health-insured-no-cpap-treatment-reason',
  healthInsuredCpapNoTreatmentReasonOtherTreatment = 'health-insured-no-cpap-treatment-reason-other-treatment',
  healthInsuredCpapNoTreatmentReasonOther = 'health-insured-no-cpap-treatment-reason-other',

  healthInsuredRespiratoryHasCondition = 'health-insured-respiratory-has-condition',
  healthInsuredRespiratoryChoices = 'health-insured-respiratory-choices',
  healthInsuredAsthmaDetails = 'health-insured-asthma-details',
  healthInsuredChronicBronchitisDetails = 'health-insured-chronic-bronchitis-details',
  healthInsuredEmphysemaDetails = 'health-insured-emphysema-details',
  healthInsuredCopdDetails = 'health-insured-copd-details',
  healthInsuredShortnessOfBreathDetails = 'health-insured-shortness-of-breath-details',
  healthInsuredAnyOtherRespiratoryDisorderDetails = 'health-insured-any-other-respiratory-disorder-details',

  healthInsuredEndocrineSystemHasCondition = 'health-insured-endocrine-system-has-condition',
  healthInsuredEndocrineChoices = 'health-insured-endocrine-choices',
  healthInsuredDiabetesType = 'health-insured-diabetes-type',
  healthInsuredDiabetesDetails = 'health-insured-diabetes-details',
  healthInsuredThyroidDisorderIsHypothyroidism = 'health-insured-thyroid-disorder-is-hypothyroidism',
  healthInsuredThyroidDisorderDiagnosisDate = 'health-insured-thyroid-disorder-diagnosis-date',
  healthInsuredThyroidDisorderHadSurgery = 'health-insured-thyroid-disorder-had-surgery',
  healthInsuredThyroidDisorderMedication = 'health-insured-thyroid-disorder-medication',
  healthInsuredThyroidDisorderIsControlled = 'health-insured-thyroid-disorder-is-controlled',
  healthInsuredAnyOtherGlandularOrHormonalDisorderDetails = 'health-insured-any-other-glandular-or-hormonal-disorder-details',
  healthInsuredThyroidDisorderSynthroid = 'health-insured-thyroid-disorder-synthroid',
  healthInsuredThyroidDisorderRecoveredDetails = 'health-insured-thyroid-disorder-recovered-details',
  healthInsuredTinnitusDetails = 'health-insured-tinnitus-details',
  healthInsuredLabyrinthitisDetails = 'health-insured-labyrinthitis-details',
  healthInsuredPartialOrTotalDeafnessDetails = 'health-insured-partial-or-total-deafness-details',
  healthInsuredMeniereDiseaseDetails = 'health-insured-meniere-disease-details',
  healthInsuredAbnormalBloodPressureDetails = 'health-insured-abnormal-blood-pressure-details',
  healthInsuredCirculatorySystemCholesterolDetails = 'health-insured-circulatory-system-cholesterol-details',

  healthInsuredCardiovascularSystemHasCondition = 'health-insured-cardiovascular-system-has-condition',
  healthInsuredCardiovascularSystemChoices = 'health-insured-cardiovascular-system-choices',
  healthInsuredHighBloodPressureDetails = 'health-insured-high-blood-pressure-details',
  healthInsuredHighBloodPressureMedicationDetails = 'health-insured-high-blood-pressure-medication-details',
  healthInsuredHighBloodPressureNoMedicationReason = 'health-insured-high-blood-pressure-no-medication-reason',

  healthInsuredHighCholesterolDiagnosisDate = 'health-insured-high-cholesterol-diagnosis-date',
  healthInsuredHighCholesterolConditionIsControlled = 'health-insured-high-cholesterol-condition-is-controlled',
  healthInsuredHighCholesterolDetails = 'health-insured-high-cholesterol-details',
  healthInsuredHighCholesterolMedicationDetails = 'health-insured-high-cholesterol-medication-details',
  healthInsuredHighCholesterolNoMedicationReason = 'health-insured-high-cholesterol-no-medication-reason',

  healthInsuredHeartAttackDetails = 'health-insured-heart-attack-details',
  healthInsuredAnyOtherHeartDisorderDetails = 'health-insured-any-other-heart-disorder-details',

  healthInsuredFamilyHasFamilyHistory2 = 'health-insured-family-has-family-history-2',
  healthInsuredFamilyHasMedicalHistory2 = 'health-insured-family-has-medical-history-2',
  healthInsuredHasFamilyHistory2 = 'health-insured-has-family-history-2',
  healthInsuredHasFamilyMedicalHistory2 = 'health-insured-has-family-medical-history-2',

  healthInsuredHasFamilyDoctorOrClinic = 'health-insured-has-family-doctor-or-clinic',
  healthInsuredFamilyDoctorOrClinicName = 'health-insured-family-doctor-or-clinic-name',
  healthInsuredFamilyDoctorOrClinicAddressStreet = 'health-insured-family-doctor-or-clinic-address-street',
  healthInsuredFamilyDoctorOrClinicAddressApartment = 'health-insured-family-doctor-or-clinic-address-apartment',
  healthInsuredFamilyDoctorOrClinicAddressCity = 'health-insured-family-doctor-or-clinic-address-city',
  healthInsuredFamilyDoctorOrClinicAddressCountry = 'health-insured-family-doctor-or-clinic-address-country',
  healthInsuredFamilyDoctorOrClinicAddressProvince = 'health-insured-family-doctor-or-clinic-address-province',
  healthInsuredFamilyDoctorOrClinicAddressPostalCode = 'health-insured-family-doctor-or-clinic-address-postal-code',

  healthInsuredHadConsultationTreatmentForOtherPhysicalMentalDisorder = 'health-insured-had-consultation-treatment-for-other-physical-or-mental-disorder',
  healthInsuredHadConsultationTreatmentForOtherPhysicalMentalDisorderDetails = 'health-insured-had-consultation-treatment-for-other-physical-or-mental-disorder-details',
  healthInsuredHadConsultationTreatmentForOtherPhysicalDisorderDetails = 'health-insured-had-consultation-treatment-for-other-physical-disorder-details',
  healthInsuredHadConsultationTreatmentForOtherMentalDisorderDetails = 'health-insured-had-consultation-treatment-for-other-mental-disorder-details',

  healthInsuredFractureHasConsultedDoctorsDetails = 'health-insured-fracture-has-consulted-doctors-details',
  healthInsuredFractureHasConsultedDoctorsProfession = 'health-insured-fracture-has-consulted-doctors-profession',
  healthInsuredFractureHasConsultedDoctorsProfessionDetails = 'health-insured-fracture-has-consulted-doctors-profession-details',
  healthInsuredFractureHasConsultedDoctorsName = 'health-insured-fracture-has-consulted-doctors-name',
  healthInsuredFractureHasConsultedDoctorsAddress = 'health-insured-fracture-has-consulted-doctors-address',
  healthInsuredFractureHasConsultedDoctorsFirstDate = 'health-insured-fracture-has-consulted-doctors-first-date',
  healthInsuredFractureHasConsultedDoctorsLastDate = 'health-insured-fracture-has-consulted-doctors-last-date',
  healthInsuredFractureHasConsultedDoctorsResult = 'health-insured-fracture-has-consulted-doctors-result',
  healthInsuredFractureHasConsultedDoctorsFrequencyNumber = 'health-insured-fracture-has-consulted-doctors-frequency-number',
  healthInsuredFractureHasConsultedDoctorsFrequency = 'health-insured-fracture-has-consulted-doctors-frequency',
  healthInsuredFractureHasConsultedDoctorFurtherConsultation = 'health-insured-fracture-has-consulted-doctor-further-consultation',
  healthInsuredFractureHasConsultedDoctorsNextConsultationDate = 'health-insured-fracture-has-consulted-doctors-next-consultation-date',
  healthInsuredFractureHasTakenTestsOrExamsDetails = 'health-insured-fracture-has-taken-tests-or-exams-details',
  healthInsuredFractureHasTakenTestsOrExamsType = 'health-insured-fracture-has-taken-tests-or-exams-type',
  healthInsuredFractureHasTakenTestsOrExamsDate = 'health-insured-fracture-has-taken-tests-or-exams-date',
  healthInsuredFractureHasTakenTestsOrExamsResult = 'health-insured-fracture-has-taken-tests-or-exams-result',
  healthInsuredFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredFractureMedicationDetails = 'health-insured-fracture-medication-details',
  healthInsuredFractureMedicationName = 'health-insured-fracture-medication-name',
  healthInsuredFractureMedicationDosage = 'health-insured-fracture-medication-dosage',
  healthInsuredFractureMedicationStartDate = 'health-insured-fracture-medication-start-date',
  healthInsuredFractureMedicationEndDate = 'health-insured-fracture-medication-end-date',

  insuredIsCurrentlyActiveDutyMilitary = 'insured-is-currently-active-duty-military',
  healthInsuredLeukemiaDetails = 'health-insured-leukemia-details',
  healthCancerRisk = 'health-cancer-risk',
  healthCancerChoices = 'health-cancer-choices',
  healthCancerTypes = 'health-cancer-types',
  healthInsuredNoduleDetails = 'health-insured-nodule-details',
  healthSkinCancerTypes = 'health-skin-cancer-types',
  healthSkinCancerTypesOther = 'health-skin-cancer-types-other',
  healthMelanomaStage = 'health-melanoma-stage',
  healthMelanomaTreatmentTypes = 'health-melanoma-treatment-types',
  healthMelanomaTreatmentDateCompletion = 'health-melanoma-treatment-date-completion',
  healthMelanomaTreatmentHistory = 'health-melanoma-treatment-history',
  healthMelanomaTreatmentDateOfPrior = 'health-melanoma-treatment-date-of-prior',
  hasInsuredHealthMelanomaMedProvider = 'has-insured-health-melanoma-med-provider',
  healthMelanomaMedProviderName = 'health-melanoma-med-provider-name',
  healthMelanomaMedProviderAddress = 'health-melanoma-med-provider-address',
  healthMelanomaMedProviderPhone = 'health-melanoma-med-provider-phone',
  isInsuredHealthMelanomaHospitalized = 'is-insured-health-melanoma-hospitalized',
  healthMelanomaHospitalizedDate = 'health-melanoma-hospitalized-date',
  healthMelanomaHospitalName = 'health-melanoma-hospital-name',
  healthMelanomaHospitalAddress = 'health-melanoma-hospital-address',
  isHealthBasalCellRemoved = 'is-health-basal-cell-removed',
  healthBasalCellRemovedDate = 'health-basal-cell-removed-date',
  isHealthBasalCellRecurrence = 'is-health-basal-cell-recurrence',
  hasInsuredHealthBasalMedProvider = 'has-insured-health-basal-med-provider',
  healthBasalMedProviderName = 'health-basal-med-provider-name',
  healthBasalMedProviderAddress = 'health-basal-med-provider-address',
  healthBasalMedProviderPhone = 'health-basal-med-provider-phone',
  isInsuredHealthBasalHospitalized = 'is-insured-health-basal-hospitalized',
  healthBasalHospitalizedDate = 'health-basal-hospitalized-date',
  healthBasalHospitalName = 'health-basal-hospital-name',
  healthBasalHospitalAddress = 'health-basal-hospital-address',
  isHealthSquamousCellRemoved = 'is-health-squamous-cell-removed',
  healthSquamousCellRemovedDate = 'health-squamous-cell-removed-date',
  isHealthSquamousCellRecurrence = 'is-health-squamous-cell-recurrence',
  hasInsuredHealthSquamousMedProvider = 'has-insured-health-squamous-med-provider',
  healthSquamousMedProviderName = 'health-squamous-med-provider-name',
  healthSquamousMedProviderAddress = 'health-squamous-med-provider-address',
  healthSquamousMedProviderPhone = 'health-squamous-med-provider-phone',
  isInsuredHealthSquamousHospitalized = 'is-insured-health-squamous-hospitalized',
  healthSquamousHospitalizedDate = 'health-squamous-hospitalized-date',
  healthSquamousHospitalName = 'health-squamous-hospital-name',
  healthSquamousHospitalAddress = 'health-squamous-hospital-address',
  healthProstateStage = 'health-prostate-stage',
  healthProstateGleasonScore = 'health-prostate-gleason-score',
  healthProstateLastPsaNumber = 'health-prostate-last-psa-number',
  healthProstateLastPsaDate = 'health-prostate-last-psa-date',
  healthProstateTreatment = 'health-prostate-treatment',
  healthProstateTreatmentDate = 'health-prostate-treatment-date',
  hasInsuredHealthProstateMedProvider = 'has-insured-health-prostate-med-provider',
  healthProstateMedProviderName = 'health-prostate-med-provider-name',
  healthProstateMedProviderAddress = 'health-prostate-med-provider-address',
  healthProstateMedProviderPhone = 'health-prostate-med-provider-phone',
  isInsuredHealthProstateHospitalized = 'is-insured-health-prostate-hospitalized',
  healthProstateHospitalizedDate = 'health-prostate-hospitalized-date',
  healthProstateHospitalName = 'health-prostate-hospital-name',
  healthProstateHospitalAddress = 'health-prostate-hospital-address',
  healthPolypsLastDateColonoscopy = 'health-polyps-last-date-colonoscopy',
  healthPolypsColonoscopyReason = 'health-polyps-colonoscopy-reason',
  healthPolypsNumber = 'health-polyps-number',
  isHealthPolypsRemoved = 'is-health-polyps-removed',
  isHealthPolypsDiagnosedWithGsfap = 'is-health-polyps-diagnosed-with-gsfap',
  isInsuredHealthPolypsGsfapFamilyHistory = 'is-insured-health-polyps-gsfap-family-history',
  hasInsuredHealthPolypsMedProvider = 'has-insured-health-polyps-med-provider',
  healthPolypsMedProviderName = 'health-polyps-med-provider-name',
  healthPolypsMedProviderAddress = 'health-polyps-med-provider-address',
  healthPolypsMedProviderPhone = 'health-polyps-med-provider-phone',
  isInsuredHealthPolypsHospitalized = 'is-insured-health-polyps-hospitalized',
  healthPolypsHospitalizedDate = 'health-polyps-hospitalized-date',
  healthPolypsHospitalName = 'health-polyps-hospital-name',
  healthPolypsHospitalAddress = 'health-polyps-hospital-address',
  healthTcgLocation = 'health-tcg-location',
  healthTcgDiagnosisDate = 'health-tcg-diagnosis-date',
  isHealthTcgMalignant = 'is-health-tcg-malignant',
  healthTcgMalignantTreatmentChoice = 'health-tcg-malignant-treatment-choice',
  healthTcgRemovedDate = 'health-tcg-removed-date',
  healthTcgTreatmentType = 'health-tcg-treatment-type',
  healthTcgTreatmentLastDate = 'health-tcg-treatment-last-date',
  healthTcgTreatmentCurrent = 'health-tcg-treatment-current',
  hasInsuredHealthTcgMedProvider = 'has-insured-health-tcg-med-provider',
  healthTcgMedProviderName = 'health-tcg-med-provider-name',
  healthTcgMedProviderAddress = 'health-tcg-med-provider-address',
  healthTcgMedProviderPhone = 'health-tcg-med-provider-phone',
  isInsuredHealthTcgHospitalized = 'is-insured-health-tcg-hospitalized',
  healthTcgHospitalizedDate = 'health-tcg-hospitalized-date',
  healthTcgHospitalName = 'health-tcg-hospital-name',
  healthTcgHospitalAddress = 'health-tcg-hospital-address',

  drugAlcoholTreatment = 'drug-alcohol-treatment',
  drugAlcoholTreatmentChoices = 'drug-alcohol-treatment-choices',
  drugCounsellingPrescribed = 'drug-counselling-prescribed',
  drugCounsellingBeganDate = 'drug-counselling-began-date',
  drugCounsellingHealthProfessionName = 'drug-counselling-health-profession-name',
  drugCounsellingHealthProfessionAddress = 'drug-counselling-health-profession-address',
  drugCounsellingNow = 'drug-counselling-now',
  drugCounsellingEndDate = 'drug-counselling-end-date',
  alcoholCounsellingBeganDate = 'alcohol-counselling-began-date',
  alcoholCounsellingHealthProfessionName = 'alcohol-counselling-health-profession-name',
  alcoholCounsellingHealthProfessionAddress = 'alcohol-counselling-health-profession-address',
  alcoholCounsellingNow = 'alcohol-counselling-now',
  alcoholCounsellingEndDate = 'alcohol-counselling-end-date',
  drugTreatmentPrescribed = 'drug-treatment-prescribed',
  drugTreatmentBeganDate = 'drug-treatment-began-date',
  drugTreatmentHealthProfessionName = 'drug-treatment-health-profession-name',
  drugTreatmentHealthProfessionAddress = 'drug-treatment-health-profession-address',
  drugTreatmentNow = 'drug-treatment-now',
  drugTreatmentEndDate = 'drug-treatment-end-date',
  alcoholTreatmentBeganDate = 'alcohol-treatment-began-date',
  alcoholTreatmentHealthProfessionName = 'alcohol-treatment-health-profession-name',
  alcoholTreatmentHealthProfessionAddress = 'alcohol-treatment-health-profession-address',
  alcoholTreatmentNow = 'alcohol-treatment-now',
  alcoholTreatmentEndDate = 'alcohol-treatment-end-date',

  healthOtherRespiratoryDetails = 'health-other-respiratory-details',
  healthOtherRespiratoryChoices = 'health-other-respiratory-choices',
  healthOtherAsthmaFirstAttackDate = 'health-other-asthma-first-attack-date',
  healthOtherAsthmaAttacksFrequency = 'health-other-asthma-attacks-frequency',
  healthOtherAsthmaLastAttackDate = 'health-other-asthma-last-attack-date',
  healthOtherAsthmaSeverity = 'health-other-asthma-severity',
  healthOtherAsthmaPhysicianCount = 'health-other-asthma-physician-count',
  healthOtherAsthmaPhysicianName1 = 'health-other-asthma-physician-name1',
  healthOtherAsthmaPhysicianAddress1 = 'health-other-asthma-physician-address1',
  healthOtherAsthmaPhysicianName2 = 'health-other-asthma-physician-name2',
  healthOtherAsthmaPhysicianAddress2 = 'health-other-asthma-physician-address2',
  healthOtherAsthmaPhysicianName3 = 'health-other-asthma-physician-name3',
  healthOtherAsthmaPhysicianAddress3 = 'health-other-asthma-physician-address3',
  healthOtherAsthmaPhysicianName4 = 'health-other-asthma-physician-name4',
  healthOtherAsthmaPhysicianAddress4 = 'health-other-asthma-physician-address4',
  healthOtherAsthmaPhysicianName5 = 'health-other-asthma-physician-name5',
  healthOtherAsthmaPhysicianAddress5 = 'health-other-asthma-physician-address5',
  healthOtherAsthmaPhysicianName6 = 'health-other-asthma-physician-name6',
  healthOtherAsthmaPhysicianAddress6 = 'health-other-asthma-physician-address6',
  healthOtherAsthmaPhysicianName7 = 'health-other-asthma-physician-name7',
  healthOtherAsthmaPhysicianAddress7 = 'health-other-asthma-physician-address7',
  healthOtherAsthmaPhysicianName8 = 'health-other-asthma-physician-name8',
  healthOtherAsthmaPhysicianAddress8 = 'health-other-asthma-physician-address8',
  healthOtherAsthmaPhysicianName9 = 'health-other-asthma-physician-name9',
  healthOtherAsthmaPhysicianAddress9 = 'health-other-asthma-physician-address9',
  healthOtherAsthmaPhysicianName10 = 'health-other-asthma-physician-name10',
  healthOtherAsthmaPhysicianAddress10 = 'health-other-asthma-physician-address10',
  hasHealthOtherAsthmaInsuredHospitalized = 'has-health-other-asthma-insured-hospitalized',
  healthOtherAsthmaInsuredHospitalizedDate = 'health-other-asthma-insured-hospitalized-date',
  healthOtherAsthmaInsuredHospitalName = 'health-other-asthma-insured-hospital-name',
  healthOtherAsthmaInsuredHospitalAddress = 'health-other-asthma-insured-hospital-address',
  hasHealthOtherAsthmaInsuredEmergencyVisit = 'has-health-other-asthma-insured-emergency-visit',
  healthOtherAsthmaInsuredEmergencyVisitDate = 'health-other-asthma-insured-emergency-visit-date',
  healthOtherAsthmaInsuredEmergencyName = 'health-other-asthma-insured-emergency-name',
  healthOtherAsthmaInsuredEmergencyAddress = 'health-other-asthma-insured-emergency-address',
  healthOtherAsthmaInsuredNumberOfMedications = 'health-other-asthma-insured-number-of-medications',
  healthOtherAsthmaInsuredNumberOfMedicationsDetails = 'health-other-asthma-insured-number-of-medications-details',
  isHealthOtherAsthmaInsuredShortOfBreath = 'is-health-other-asthma-insured-short-of-breath',
  healthOtherAsthmaLimitations = 'health-other-asthma-limitations',
  healthOtherAsthmaLimitationsDetails = 'health-other-asthma-limitations-details',
  isHealthOtherAsthmaInsuredTobaccoUser = 'is-health-other-asthma-insured-tobacco-user',
  healthOtherCopdFirstAttackDate = 'health-other-copd-first-attack-date',
  healthOtherCopdAttacksFrequency = 'health-other-copd-attacks-frequency',
  healthOtherCopdLastAttackDate = 'health-other-copd-last-attack-date',
  healthOtherCopdSeverity = 'health-other-copd-severity',
  healthOtherCopdPhysicianCount = 'health-other-copd-physician-count',
  healthOtherCopdPhysicianName1 = 'health-other-copd-physician-name1',
  healthOtherCopdPhysicianAddress1 = 'health-other-copd-physician-address1',
  healthOtherCopdPhysicianName2 = 'health-other-copd-physician-name2',
  healthOtherCopdPhysicianAddress2 = 'health-other-copd-physician-address2',
  healthOtherCopdPhysicianName3 = 'health-other-copd-physician-name3',
  healthOtherCopdPhysicianAddress3 = 'health-other-copd-physician-address3',
  healthOtherCopdPhysicianName4 = 'health-other-copd-physician-name4',
  healthOtherCopdPhysicianAddress4 = 'health-other-copd-physician-address4',
  healthOtherCopdPhysicianName5 = 'health-other-copd-physician-name5',
  healthOtherCopdPhysicianAddress5 = 'health-other-copd-physician-address5',
  healthOtherCopdPhysicianName6 = 'health-other-copd-physician-name6',
  healthOtherCopdPhysicianAddress6 = 'health-other-copd-physician-address6',
  healthOtherCopdPhysicianName7 = 'health-other-copd-physician-name7',
  healthOtherCopdPhysicianAddress7 = 'health-other-copd-physician-address7',
  healthOtherCopdPhysicianName8 = 'health-other-copd-physician-name8',
  healthOtherCopdPhysicianAddress8 = 'health-other-copd-physician-address8',
  healthOtherCopdPhysicianName9 = 'health-other-copd-physician-name9',
  healthOtherCopdPhysicianAddress9 = 'health-other-copd-physician-address9',
  healthOtherCopdPhysicianName10 = 'health-other-copd-physician-name10',
  healthOtherCopdPhysicianAddress10 = 'health-other-copd-physician-address10',
  hasHealthOtherCopdInsuredHospitalized = 'has-health-other-copd-insured-hospitalized',
  healthOtherCopdInsuredHospitalizedDate = 'health-other-copd-insured-hospitalized-date',
  healthOtherCopdInsuredHospitalName = 'health-other-copd-insured-hospital-name',
  healthOtherCopdHospitalAddress = 'health-other-copd-hospital-address',
  hasHealthOtherCopdInsuredEmergencyVisit = 'has-health-other-copd-insured-emergency-visit',
  healthOtherCopdInsuredEmergencyVisitDate = 'health-other-copd-insured-emergency-visit-date',
  healthOtherCopdInsuredEmergencyName = 'health-other-copd-insured-emergency-name',
  healthOtherCopdInsuredEmergencyAddress = 'health-other-copd-insured-emergency-address',
  healthOtherCopdInsuredNumberOfMedications = 'health-other-copd-insured-number-of-medications',
  healthOtherCopdInsuredNumberOfMedicationsDetails = 'health-other-copd-insured-number-of-medications-details',
  isHealthOtherCopdInsuredShortOfBreath = 'is-health-other-copd-insured-short-of-breath',
  healthOtherCopdLimitations = 'health-other-copd-limitations',
  healthOtherCopdLimitationsDetails = 'health-other-copd-limitations-details',
  healthInsuredCysticFibrosisDetails = 'health-insured-cystic-fibrosis-details',
  healthInsuredSarcoidosisDetails = 'health-insured-sarcoidosis-details',
  healthInsuredCoughingUpBloodDetails = 'health-insured-coughing-up-blood-details',
  isHealthOtherCopdInsuredTobaccoUser = 'is-health-other-copd-insured-tobacco-user',
  healthOtherEmphysemaFirstAttackDate = 'health-other-emphysema-first-attack-date',
  healthOtherEmphysemaAttacksFrequency = 'health-other-emphysema-attacks-frequency',
  healthOtherEmphysemaLastAttackDate = 'health-other-emphysema-last-attack-date',
  healthOtherEmphysemaSeverity = 'health-other-emphysema-severity',
  healthOtherEmphysemaPhysicianCount = 'health-other-emphysema-physician-count',
  healthOtherEmphysemaPhysicianName1 = 'health-other-emphysema-physician-name1',
  healthOtherEmphysemaPhysicianAddress1 = 'health-other-emphysema-physician-address1',
  healthOtherEmphysemaPhysicianName2 = 'health-other-emphysema-physician-name2',
  healthOtherEmphysemaPhysicianAddress2 = 'health-other-emphysema-physician-address2',
  healthOtherEmphysemaPhysicianName3 = 'health-other-emphysema-physician-name3',
  healthOtherEmphysemaPhysicianAddress3 = 'health-other-emphysema-physician-address3',
  healthOtherEmphysemaPhysicianName4 = 'health-other-emphysema-physician-name4',
  healthOtherEmphysemaPhysicianAddress4 = 'health-other-emphysema-physician-address4',
  healthOtherEmphysemaPhysicianName5 = 'health-other-emphysema-physician-name5',
  healthOtherEmphysemaPhysicianAddress5 = 'health-other-emphysema-physician-address5',
  healthOtherEmphysemaPhysicianName6 = 'health-other-emphysema-physician-name6',
  healthOtherEmphysemaPhysicianAddress6 = 'health-other-emphysema-physician-address6',
  healthOtherEmphysemaPhysicianName7 = 'health-other-emphysema-physician-name7',
  healthOtherEmphysemaPhysicianAddress7 = 'health-other-emphysema-physician-address7',
  healthOtherEmphysemaPhysicianName8 = 'health-other-emphysema-physician-name8',
  healthOtherEmphysemaPhysicianAddress8 = 'health-other-emphysema-physician-address8',
  healthOtherEmphysemaPhysicianName9 = 'health-other-emphysema-physician-name9',
  healthOtherEmphysemaPhysicianAddress9 = 'health-other-emphysema-physician-address9',
  healthOtherEmphysemaPhysicianName10 = 'health-other-emphysema-physician-name10',
  healthOtherEmphysemaPhysicianAddress10 = 'health-other-emphysema-physician-address10',
  hasHealthOtherEmphysemaInsuredHospitalized = 'has-health-other-emphysema-insured-hospitalized',
  healthOtherEmphysemaInsuredHospitalizedDate = 'health-other-emphysema-insured-hospitalized-date',
  healthOtherEmphysemaInsuredHospitalName = 'health-other-emphysema-insured-hospital-name',
  healthOtherEmphysemaInsuredHospitalAddress = 'health-other-emphysema-insured-hospital-address',
  hasHealthOtherEmphysemaInsuredEmergencyVisit = 'has-health-other-emphysema-insured-emergency-visit',
  healthOtherEmphysemaInsuredEmergencyVisitDate = 'health-other-emphysema-insured-emergency-visit-date',
  healthOtherEmphysemaInsuredEmergencyName = 'health-other-emphysema-insured-emergency-name',
  healthOtherEmphysemaInsuredEmergencyAddress = 'health-other-emphysema-insured-emergency-address',
  healthOtherEmphysemaInsuredNumberOfMedications = 'health-other-emphysema-insured-number-of-medications',
  healthOtherEmphysemaInsuredNumberOfMedicationsDetails = 'health-other-emphysema-insured-number-of-medications-details',
  isHealthOtherEmphysemaInsuredShortOfBreath = 'is-health-other-emphysema-insured-short-of-breath',
  healthOtherEmphysemaLimitations = 'health-other-emphysema-limitations',
  healthOtherEmphysemaLimitationsDetails = 'health-other-emphysema-limitations-details',
  isHealthOtherEmphysemaInsuredTobaccoUser = 'is-health-other-emphysema-insured-tobacco-user',
  healthOtherBronchitisFirstAttackDate = 'health-other-bronchitis-first-attack-date',
  healthOtherBronchitisAttacksFrequency = 'health-other-bronchitis-attacks-frequency',
  healthOtherBronchitisLastAttackDate = 'health-other-bronchitis-last-attack-date',
  healthOtherBronchitisSeverity = 'health-other-bronchitis-severity',
  healthOtherBronchitisPhysicianCount = 'health-other-bronchitis-physician-count',
  healthOtherBronchitisPhysicianName1 = 'health-other-bronchitis-physician-name1',
  healthOtherBronchitisPhysicianAddress1 = 'health-other-bronchitis-physician-address1',
  healthOtherBronchitisPhysicianName2 = 'health-other-bronchitis-physician-name2',
  healthOtherBronchitisPhysicianAddress2 = 'health-other-bronchitis-physician-address2',
  healthOtherBronchitisPhysicianName3 = 'health-other-bronchitis-physician-name3',
  healthOtherBronchitisPhysicianAddress3 = 'health-other-bronchitis-physician-address3',
  healthOtherBronchitisPhysicianName4 = 'health-other-bronchitis-physician-name4',
  healthOtherBronchitisPhysicianAddress4 = 'health-other-bronchitis-physician-address4',
  healthOtherBronchitisPhysicianName5 = 'health-other-bronchitis-physician-name5',
  healthOtherBronchitisPhysicianAddress5 = 'health-other-bronchitis-physician-address5',
  healthOtherBronchitisPhysicianName6 = 'health-other-bronchitis-physician-name6',
  healthOtherBronchitisPhysicianAddress6 = 'health-other-bronchitis-physician-address6',
  healthOtherBronchitisPhysicianName7 = 'health-other-bronchitis-physician-name7',
  healthOtherBronchitisPhysicianAddress7 = 'health-other-bronchitis-physician-address7',
  healthOtherBronchitisPhysicianName8 = 'health-other-bronchitis-physician-name8',
  healthOtherBronchitisPhysicianAddress8 = 'health-other-bronchitis-physician-address8',
  healthOtherBronchitisPhysicianName9 = 'health-other-bronchitis-physician-name9',
  healthOtherBronchitisPhysicianAddress9 = 'health-other-bronchitis-physician-address9',
  healthOtherBronchitisPhysicianName10 = 'health-other-bronchitis-physician-name10',
  healthOtherBronchitisPhysicianAddress10 = 'health-other-bronchitis-physician-address10',
  hasHealthOtherBronchitisInsuredHospitalized = 'has-health-other-bronchitis-insured-hospitalized',
  healthOtherBronchitisInsuredHospitalizedDate = 'health-other-bronchitis-insured-hospitalized-date',
  healthOtherBronchitisInsuredHospitalName = 'health-other-bronchitis-insured-hospital-name',
  healthOtherBronchitisInsuredHospitalAddress = 'health-other-bronchitis-insured-hospital-address',
  hasHealthOtherBronchitisInsuredEmergencyVisit = 'has-health-other-bronchitis-insured-emergency-visit',
  healthOtherBronchitisInsuredEmergencyVisitDate = 'health-other-bronchitis-insured-emergency-visit-date',
  healthOtherBronchitisInsuredEmergencyName = 'health-other-bronchitis-insured-emergency-name',
  healthOtherBronchitisInsuredEmergencyAddress = 'health-other-bronchitis-insured-emergency-address',
  healthOtherBronchitisInsuredNumberOfMedications = 'health-other-bronchitis-insured-number-of-medications',
  healthOtherBronchitisNumberOfMedicationsDetails = 'health-other-bronchitis-number-of-medications-details',
  isHealthOtherBronchitisInsuredShortOfBreath = 'is-health-other-bronchitis-insured-short-of-breath',
  healthOtherBronchitisLimitations = 'health-other-bronchitis-limitations',
  healthOtherBronchitisLimitationsDetails = 'health-other-bronchitis-limitations-details',
  isHealthOtherBronchitisInsuredTobaccoUser = 'is-health-other-bronchitis-insured-tobacco-user',
  insuredSignatureCity = 'insured-signature-city',
  insuredSignatureState = 'insured-signature-state',
  insuredSignatureDate = 'insured-signature-date',

  healthInsuredMusculoskeletalFractureIsSingleEpisode = 'health-insured-musculoskeletal-fracture-is-single-episode',
  healthInsuredMusculoskeletalFracturePreviousEpisodesCount = 'health-insured-musculoskeletal-fracture-previous-episodes-count',
  healthInsuredMusculoskeletalFractureDateSymptoms = 'health-insured-musculoskeletal-fracture-date-symptoms',
  healthInsuredMusculoskeletalFracturePainLocation = 'health-insured-musculoskeletal-fracture-pain-location',
  healthInsuredMusculoskeletalFracturePainLocationLeftRight = 'health-insured-musculoskeletal-fracture-pain-location-left-right',
  healthInsuredMusculoskeletalFracturePainLocationOther = 'health-insured-musculoskeletal-fracture-pain-location-other',
  healthInsuredMusculoskeletalFracturePainCause = 'health-insured-musculoskeletal-fracture-pain-cause',
  healthInsuredMusculoskeletalFracturePainCauseDetails = 'health-insured-musculoskeletal-fracture-pain-cause-details',
  healthInsuredMusculoskeletalFractureHasConsultedDoctors = 'health-insured-musculoskeletal-fracture-has-consulted-doctors',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExams = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams',
  healthInsuredMusculoskeletalFractureMedicationWasPrescribed = 'health-insured-musculoskeletal-fracture-medication-was-prescribed',
  healthInsuredMusculoskeletalFractureHadOperation = 'health-insured-musculoskeletal-fracture-had-operation',
  healthInsuredMusculoskeletalFractureOperationPerformedOrPlanned = 'health-insured-musculoskeletal-fracture-operation-performed-or-planned',
  healthInsuredMusculoskeletalFractureTypeOfOperationPerformed = 'health-insured-musculoskeletal-fracture-type-of-operation-performed',
  healthInsuredMusculoskeletalFractureOperationPerformedDate = 'health-insured-musculoskeletal-fracture-operation-performed-date',
  healthInsuredMusculoskeletalFractureTypeOfOperationPlanned = 'health-insured-musculoskeletal-fracture-type-of-operation-planned',
  healthInsuredMusculoskeletalFractureOperationPlannedDate = 'health-insured-musculoskeletal-fracture-operation-planned-date',
  healthInsuredMusculoskeletalFractureOperationUnknownDate = 'health-insured-musculoskeletal-fracture-operation-unknown-date',
  healthInsuredMusculoskeletalFractureHadAbsence = 'health-insured-musculoskeletal-fracture-had-absence',
  healthInsuredMusculoskeletalFractureAbsenceStartDate = 'health-insured-musculoskeletal-fracture-absence-start-date',
  healthInsuredMusculoskeletalFractureAbsenceFrequencyNumber = 'health-insured-musculoskeletal-fracture-absence-frequency-number',
  healthInsuredMusculoskeletalFractureAbsenceFrequency = 'health-insured-musculoskeletal-fracture-absence-frequency',
  healthInsuredMusculoskeletalFractureAbsenceHasRecovered = 'health-insured-musculoskeletal-fracture-absence-has-recovered',
  healthInsuredMusculoskeletalFractureAbsenceRecoveryDate = 'health-insured-musculoskeletal-fracture-absence-recovery-date',
  healthInsuredMusculoskeletalFractureAbsenceNotRecoveredDetails = 'health-insured-musculoskeletal-fracture-absence-not-recovered-details',

  healthInsuredMusculoskeletalFractureHasConsultedDoctorsDetails = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-details',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsProfession = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-profession',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsProfessionDetails = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-profession-details',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsName = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-name',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsAddress = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-address',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsFirstDate = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-first-date',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsLastDate = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-last-date',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsResult = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-result',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-frequency-number',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsFrequency = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-frequency',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorFurtherConsultation = 'health-insured-musculoskeletal-fracture-has-consulted-doctor-further-consultation',
  healthInsuredMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate = 'health-insured-musculoskeletal-fracture-has-consulted-doctors-next-consultation-date',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDetails = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams-details',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsType = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams-type',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDate = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams-date',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsResult = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams-result',
  healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-musculoskeletal-fracture-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredMusculoskeletalFractureMedicationDetails = 'health-insured-musculoskeletal-fracture-medication-details',
  healthInsuredMusculoskeletalFractureMedicationName = 'health-insured-musculoskeletal-fracture-medication-name',
  healthInsuredMusculoskeletalFractureMedicationDosage = 'health-insured-musculoskeletal-fracture-medication-dosage',
  healthInsuredMusculoskeletalFractureMedicationStartDate = 'health-insured-musculoskeletal-fracture-medication-start-date',
  healthInsuredMusculoskeletalFractureMedicationEndDate = 'health-insured-musculoskeletal-fracture-medication-end-date',

  healthInsuredBursitisIsSingleEpisode = 'health-insured-bursitis-is-single-episode',
  healthInsuredBursitisPreviousEpisodesCount = 'health-insured-bursitis-previous-episodes-count',
  healthInsuredBursitisDateSymptoms = 'health-insured-bursitis-date-symptoms',
  healthInsuredBursitisPainLocation = 'health-insured-bursitis-pain-location',
  healthInsuredBursitisPainLocationLeftRight = 'health-insured-bursitis-pain-location-left-right',
  healthInsuredBursitisPainLocationOther = 'health-insured-bursitis-pain-location-other',
  healthInsuredBursitisPainCause = 'health-insured-bursitis-pain-cause',
  healthInsuredBursitisPainCauseDetails = 'health-insured-bursitis-pain-cause-details',
  healthInsuredBursitisHasConsultedDoctors = 'health-insured-bursitis-has-consulted-doctors',
  healthInsuredBursitisHasTakenTestsOrExams = 'health-insured-bursitis-has-taken-tests-or-exams',
  healthInsuredBursitisMedicationWasPrecribed = 'health-insured-bursitis-medication-was-prescribed',
  healthInsuredBursitisHadOperation = 'health-insured-bursitis-had-operation',
  healthInsuredBursitisOperationPerformedOrPlanned = 'health-insured-bursitis-operation-performed-or-planned',
  healthInsuredBursitisTypeOfOperationPerformed = 'health-insured-bursitis-type-of-operation-performed',
  healthInsuredBursitisOperationPerformedDate = 'health-insured-bursitis-operation-performed-date',
  healthInsuredBursitisTypeOfOperationPlanned = 'health-insured-bursitis-type-of-operation-planned',
  healthInsuredBursitisOperationPlannedDate = 'health-insured-bursitis-operation-planned-date',
  healthInsuredBursitisOperationUnknownDate = 'health-insured-bursitis-operation-unknown-date',
  healthInsuredBursitisHadAbsence = 'health-insured-bursitis-had-absence',
  healthInsuredBursitisAbsenceStartDate = 'health-insured-bursitis-absence-start-date',
  healthInsuredBursitisAbsenceFrequencyNumber = 'health-insured-bursitis-absence-frequency-number',
  healthInsuredBursitisAbsenceFrequency = 'health-insured-bursitis-absence-frequency',
  healthInsuredBursitisAbsenceHasRecovered = 'health-insured-bursitis-absence-has-recovered',
  healthInsuredBursitisAbsenceRecoveryDate = 'health-insured-bursitis-absence-recovery-date',
  healthInsuredBursitisAbsenceNotRecoveredDetails = 'health-insured-bursitis-absence-not-recovered-details',

  healthInsuredBursitisHasConsultedDoctorsDetails = 'health-insured-bursitis-has-consulted-doctors-details',
  healthInsuredBursitisHasConsultedDoctorsProfession = 'health-insured-bursitis-has-consulted-doctors-profession',
  healthInsuredBursitisHasConsultedDoctorsProfessionDetails = 'health-insured-bursitis-has-consulted-doctors-profession-details',
  healthInsuredBursitisHasConsultedDoctorsName = 'health-insured-bursitis-has-consulted-doctors-name',
  healthInsuredBursitisHasConsultedDoctorsAddress = 'health-insured-bursitis-has-consulted-doctors-address',
  healthInsuredBursitisHasConsultedDoctorsFirstDate = 'health-insured-bursitis-has-consulted-doctors-first-date',
  healthInsuredBursitisHasConsultedDoctorsLastDate = 'health-insured-bursitis-has-consulted-doctors-last-date',
  healthInsuredBursitisHasConsultedDoctorsResult = 'health-insured-bursitis-has-consulted-doctors-result',
  healthInsuredBursitisHasConsultedDoctorsFrequencyNumber = 'health-insured-bursitis-has-consulted-doctors-frequency-number',
  healthInsuredBursitisHasConsultedDoctorsFrequency = 'health-insured-bursitis-has-consulted-doctors-frequency',
  healthInsuredBursitisHasConsultedDoctorFurtherConsultation = 'health-insured-bursitis-has-consulted-doctor-further-consultation',
  healthInsuredBursitisHasConsultedDoctorsNextConsultationDate = 'health-insured-bursitis-has-consulted-doctors-next-consultation-date',
  healthInsuredBursitisHasTakenTestsOrExamsDetails = 'health-insured-bursitis-has-taken-tests-or-exams-details',
  healthInsuredBursitisHasTakenTestsOrExamsType = 'health-insured-bursitis-has-taken-tests-or-exams-type',
  healthInsuredBursitisHasTakenTestsOrExamsDate = 'health-insured-bursitis-has-taken-tests-or-exams-date',
  healthInsuredBursitisHasTakenTestsOrExamsResult = 'health-insured-bursitis-has-taken-tests-or-exams-result',
  healthInsuredBursitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-bursitis-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredBursitisMedicationDetails = 'health-insured-bursitis-medication-details',
  healthInsuredBursitisMedicationName = 'health-insured-bursitis-medication-name',
  healthInsuredBursitisMedicationDosage = 'health-insured-bursitis-medication-dosage',
  healthInsuredBursitisMedicationStartDate = 'health-insured-bursitis-medication-start-date',
  healthInsuredBursitisMedicationEndDate = 'health-insured-bursitis-medication-end-date',

  healthInsuredMuscularDystrophyType = 'health-insured-muscular-dystrophy-type',
  healthInsuredMuscularDystrophyDiagnosisDate = 'health-insured-muscular-dystrophy-diagnosis-date',
  healthInsuredMuscularDystrophyIsSingleEpisode = 'health-insured-muscular-dystrophy-is-single-episode',
  healthInsuredMuscularDystrophyPreviousEpisodesCount = 'health-insured-muscular-dystrophy-previous-episodes-count',
  healthInsuredMuscularDystrophyDateSymptoms = 'health-insured-muscular-dystrophy-date-symptoms',
  healthInsuredMuscularDystrophyPainLocation = 'health-insured-muscular-dystrophy-pain-location',
  healthInsuredMuscularDystrophyPainLocationLeftRight = 'health-insured-muscular-dystrophy-pain-location-left-right',
  healthInsuredMuscularDystrophyPainLocationOther = 'health-insured-muscular-dystrophy-pain-location-other',
  healthInsuredMuscularDystrophyPainCause = 'health-insured-muscular-dystrophy-pain-cause',
  healthInsuredMuscularDystrophyPainCauseDetails = 'health-insured-muscular-dystrophy-pain-cause-details',
  healthInsuredMuscularDystrophyHasConsultedDoctors = 'health-insured-muscular-dystrophy-has-consulted-doctors',
  healthInsuredMuscularDystrophyHasTakenTestsOrExams = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams',
  healthInsuredMuscularDystrophyMedicationWasPrescribed = 'health-insured-muscular-dystrophy-medication-was-prescribed',
  healthInsuredMuscularDystrophyHadOperation = 'health-insured-muscular-dystrophy-had-operation',
  healthInsuredMuscularDystrophyOperationPerformedOrPlanned = 'health-insured-muscular-dystrophy-operation-performed-or-planned',
  healthInsuredMuscularDystrophyTypeOfOperationPerformed = 'health-insured-muscular-dystrophy-type-of-operation-performed',
  healthInsuredMuscularDystrophyOperationPerformedDate = 'health-insured-muscular-dystrophy-operation-performed-date',
  healthInsuredMuscularDystrophyTypeOfOperationPlanned = 'health-insured-muscular-dystrophy-type-of-operation-planned',
  healthInsuredMuscularDystrophyOperationPlannedDate = 'health-insured-muscular-dystrophy-operation-planned-date',
  healthInsuredMuscularDystrophyOperationUnknownDate = 'health-insured-muscular-dystrophy-operation-unknown-date',
  healthInsuredMuscularDystrophyHadAbsence = 'health-insured-muscular-dystrophy-had-absence',
  healthInsuredMuscularDystrophyAbsenceStartDate = 'health-insured-muscular-dystrophy-absence-start-date',
  healthInsuredMuscularDystrophyAbsenceFrequencyNumber = 'health-insured-muscular-dystrophy-absence-frequency-number',
  healthInsuredMuscularDystrophyAbsenceFrequency = 'health-insured-muscular-dystrophy-absence-frequency',
  healthInsuredMuscularDystrophyAbsenceHasRecovered = 'health-insured-muscular-dystrophy-absence-has-recovered',
  healthInsuredMuscularDystrophyAbsenceRecoveryDate = 'health-insured-muscular-dystrophy-absence-recovery-date',
  healthInsuredMuscularDystrophyAbsenceNotRecoveredDetails = 'health-insured-muscular-dystrophy-absence-not-recovered-details',

  healthInsuredMuscularDystrophyHasConsultedDoctorsDetails = 'health-insured-muscular-dystrophy-has-consulted-doctors-details',
  healthInsuredMuscularDystrophyHasConsultedDoctorsProfession = 'health-insured-muscular-dystrophy-has-consulted-doctors-profession',
  healthInsuredMuscularDystrophyHasConsultedDoctorsProfessionDetails = 'health-insured-muscular-dystrophy-has-consulted-doctors-profession-details',
  healthInsuredMuscularDystrophyHasConsultedDoctorsName = 'health-insured-muscular-dystrophy-has-consulted-doctors-name',
  healthInsuredMuscularDystrophyHasConsultedDoctorsAddress = 'health-insured-muscular-dystrophy-has-consulted-doctors-address',
  healthInsuredMuscularDystrophyHasConsultedDoctorsFirstDate = 'health-insured-muscular-dystrophy-has-consulted-doctors-first-date',
  healthInsuredMuscularDystrophyHasConsultedDoctorsLastDate = 'health-insured-muscular-dystrophy-has-consulted-doctors-last-date',
  healthInsuredMuscularDystrophyHasConsultedDoctorsResult = 'health-insured-muscular-dystrophy-has-consulted-doctors-result',
  healthInsuredMuscularDystrophyHasConsultedDoctorsFrequencyNumber = 'health-insured-muscular-dystrophy-has-consulted-doctors-frequency-number',
  healthInsuredMuscularDystrophyHasConsultedDoctorsFrequency = 'health-insured-muscular-dystrophy-has-consulted-doctors-frequency',
  healthInsuredMuscularDystrophyHasConsultedDoctorFurtherConsultation = 'health-insured-muscular-dystrophy-has-consulted-doctor-further-consultation',
  healthInsuredMuscularDystrophyHasConsultedDoctorsNextConsultationDate = 'health-insured-muscular-dystrophy-has-consulted-doctors-next-consultation-date',

  healthInsuredMuscularDystrophyHasTakenTestsOrExamsDetails = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams-details',
  healthInsuredMuscularDystrophyHasTakenTestsOrExamsType = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams-type',
  healthInsuredMuscularDystrophyHasTakenTestsOrExamsDate = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams-date',
  healthInsuredMuscularDystrophyHasTakenTestsOrExamsResult = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams-result',
  healthInsuredMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-muscular-dystrophy-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredMuscularDystrophyMedicationDetails = 'health-insured-muscular-dystrophy-medication-details',
  healthInsuredMuscularDystrophyMedicationName = 'health-insured-muscular-dystrophy-medication-name',
  healthInsuredMuscularDystrophyMedicationDosage = 'health-insured-muscular-dystrophy-medication-dosage',
  healthInsuredMuscularDystrophyMedicationStartDate = 'health-insured-muscular-dystrophy-medication-start-date',
  healthInsuredMuscularDystrophyMedicationEndDate = 'health-insured-muscular-dystrophy-medication-end-date',

  healthInsuredMusculoskeletalOtherIsSingleEpisode = 'health-insured-musculoskeletal-other-is-single-episode',
  healthInsuredMusculoskeletalOtherPreviousEpisodesCount = 'health-insured-musculoskeletal-other-previous-episodes-count',
  healthInsuredMusculoskeletalOtherDateSymptoms = 'health-insured-musculoskeletal-other-date-symptoms',
  healthInsuredMusculoskeletalOtherPainLocation = 'health-insured-musculoskeletal-other-pain-location',
  healthInsuredMusculoskeletalOtherPainLocationLeftRight = 'health-insured-musculoskeletal-other-pain-location-left-right',
  healthInsuredMusculoskeletalOtherPainLocationOther = 'health-insured-musculoskeletal-other-pain-location-other',
  healthInsuredMusculoskeletalOtherPainCause = 'health-insured-musculoskeletal-other-pain-cause',
  healthInsuredMusculoskeletalOtherPainCauseDetails = 'health-insured-musculoskeletal-other-pain-cause-details',
  healthInsuredMusculoskeletalOtherHasConsultedDoctors = 'health-insured-musculoskeletal-other-has-consulted-doctors',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExams = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams',
  healthInsuredMusculoskeletalOtherMedicationWasPrescribed = 'health-insured-musculoskeletal-other-medication-was-prescribed',
  healthInsuredMusculoskeletalOtherHadOperation = 'health-insured-musculoskeletal-other-had-operation',
  healthInsuredMusculoskeletalOtherOperationPerformedOrPlanned = 'health-insured-musculoskeletal-other-operation-performed-or-planned',
  healthInsuredMusculoskeletalOtherTypeOfOperationPerformed = 'health-insured-musculoskeletal-other-type-of-operation-performed',
  healthInsuredMusculoskeletalOtherOperationPerformedDate = 'health-insured-musculoskeletal-other-operation-performed-date',
  healthInsuredMusculoskeletalOtherTypeOfOperationPlanned = 'health-insured-musculoskeletal-other-type-of-operation-planned',
  healthInsuredMusculoskeletalOtherOperationPlannedDate = 'health-insured-musculoskeletal-other-operation-planned-date',
  healthInsuredMusculoskeletalOtherOperationUnknownDate = 'health-insured-musculoskeletal-other-operation-unknown-date',
  healthInsuredMusculoskeletalOtherHadAbsence = 'health-insured-musculoskeletal-other-had-absence',
  healthInsuredMusculoskeletalOtherAbsenceStartDate = 'health-insured-musculoskeletal-other-absence-start-date',
  healthInsuredMusculoskeletalOtherAbsenceFrequencyNumber = 'health-insured-musculoskeletal-other-absence-frequency-number',
  healthInsuredMusculoskeletalOtherAbsenceFrequency = 'health-insured-musculoskeletal-other-absence-frequency',

  healthInsuredMusculoskeletalOtherAbsenceHasRecovered = 'health-insured-musculoskeletal-other-absence-has-recovered',
  healthInsuredMusculoskeletalOtherAbsenceRecoveryDate = 'health-insured-musculoskeletal-other-absence-recovery-date',
  healthInsuredMusculoskeletalOtherAbsenceNotRecoveredDetails = 'health-insured-musculoskeletal-other-absence-not-recovered-details',

  healthInsuredMusculoskeletalOtherHasConsultedDoctorsDetails = 'health-insured-musculoskeletal-other-has-consulted-doctors-details',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsProfession = 'health-insured-musculoskeletal-other-has-consulted-doctors-profession',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsProfessionDetails = 'health-insured-musculoskeletal-other-has-consulted-doctors-profession-details',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsName = 'health-insured-musculoskeletal-other-has-consulted-doctors-name',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsAddress = 'health-insured-musculoskeletal-other-has-consulted-doctors-address',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsFirstDate = 'health-insured-musculoskeletal-other-has-consulted-doctors-first-date',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsLastDate = 'health-insured-musculoskeletal-other-has-consulted-doctors-last-date',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsResult = 'health-insured-musculoskeletal-other-has-consulted-doctors-result',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber = 'health-insured-musculoskeletal-other-has-consulted-doctors-frequency-number',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsFrequency = 'health-insured-musculoskeletal-other-has-consulted-doctors-frequency',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorFurtherConsultation = 'health-insured-musculoskeletal-other-has-consulted-doctor-further-consultation',
  healthInsuredMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate = 'health-insured-musculoskeletal-other-has-consulted-doctors-next-consultation-date',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDetails = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams-details',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsType = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams-type',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDate = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams-date',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsResult = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams-result',
  healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-musculoskeletal-other-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredMusculoskeletalOtherMedicationDetails = 'health-insured-musculoskeletal-other-medication-details',
  healthInsuredMusculoskeletalOtherMedicationName = 'health-insured-musculoskeletal-other-medication-name',
  healthInsuredMusculoskeletalOtherMedicationDosage = 'health-insured-musculoskeletal-other-medication-dosage',
  healthInsuredMusculoskeletalOtherMedicationStartDate = 'health-insured-musculoskeletal-other-medication-start-date',
  healthInsuredMusculoskeletalOtherMedicationEndDate = 'health-insured-musculoskeletal-other-medication-end-date',

  healthInsuredTendonitisIsSingleEpisode = 'health-insured-tendonitis-is-single-episode',
  healthInsuredTendonitisPreviousEpisodesCount = 'health-insured-tendonitis-previous-episodes-count',
  healthInsuredTendonitisDateSymptoms = 'health-insured-tendonitis-date-symptoms',
  healthInsuredTendonitisPainLocation = 'health-insured-tendonitis-pain-location',
  healthInsuredTendonitisPainLocationLeftRight = 'health-insured-tendonitis-pain-location-left-right',
  healthInsuredTendonitisPainLocationOther = 'health-insured-tendonitis-pain-location-other',
  healthInsuredTendonitisPainCause = 'health-insured-tendonitis-pain-cause',
  healthInsuredTendonitisPainCauseDetails = 'health-insured-tendonitis-pain-cause-details',
  healthInsuredTendonitisHasConsultedDoctors = 'health-insured-tendonitis-has-consulted-doctors',
  healthInsuredTendonitisHasTakenTestsOrExams = 'health-insured-tendonitis-has-taken-tests-or-exams',
  healthInsuredTendonitisMedicationWasPrescribed = 'health-insured-tendonitis-medication-was-prescribed',
  healthInsuredTendonitisHadOperation = 'health-insured-tendonitis-had-operation',
  healthInsuredTendonitisOperationPerformedOrPlanned = 'health-insured-tendonitis-operation-performed-or-planned',
  healthInsuredTendonitisTypeOfOperationPerformed = 'health-insured-tendonitis-type-of-operation-performed',
  healthInsuredTendonitisOperationPerformedDate = 'health-insured-tendonitis-operation-performed-date',
  healthInsuredTendonitisTypeOfOperationPlanned = 'health-insured-tendonitis-type-of-operation-planned',
  healthInsuredTendonitisOperationPlannedDate = 'health-insured-tendonitis-operation-planned-date',
  healthInsuredTendonitisOperationUnknownDate = 'health-insured-tendonitis-operation-unknown-date',
  healthInsuredTendonitisHadAbsence = 'health-insured-tendonitis-had-absence',
  healthInsuredTendonitisAbsenceStartDate = 'health-insured-tendonitis-absence-start-date',
  healthInsuredTendonitisAbsenceFrequencyNumber = 'health-insured-tendonitis-absence-frequency-number',
  healthInsuredTendonitisAbsenceFrequency = 'health-insured-tendonitis-absence-frequency',
  healthInsuredTendonitisHasRecovered = 'health-insured-tendonitis-has-recovered',
  healthInsuredTendonitisRecoveryDate = 'health-insured-tendonitis-recovery-date',
  healthInsuredTendonitisAbsenceNotRecoveredDetails = 'health-insured-tendonitis-absence-not-recovered-details',

  healthInsuredTendonitisHasConsultedDoctorsDetails = 'health-insured-tendonitis-has-consulted-doctors-details',
  healthInsuredTendonitisHasConsultedDoctorsProfession = 'health-insured-tendonitis-has-consulted-doctors-profession',
  healthInsuredTendonitisHasConsultedDoctorsProfessionDetails = 'health-insured-tendonitis-has-consulted-doctors-profession-details',
  healthInsuredTendonitisHasConsultedDoctorsName = 'health-insured-tendonitis-has-consulted-doctors-name',
  healthInsuredTendonitisHasConsultedDoctorsAddress = 'health-insured-tendonitis-has-consulted-doctors-address',
  healthInsuredTendonitisHasConsultedDoctorsFirstDate = 'health-insured-tendonitis-has-consulted-doctors-first-date',
  healthInsuredTendonitisHasConsultedDoctorsLastDate = 'health-insured-tendonitis-has-consulted-doctors-last-date',
  healthInsuredTendonitisHasConsultedDoctorsResult = 'health-insured-tendonitis-has-consulted-doctors-result',
  healthInsuredTendonitisHasConsultedDoctorsFrequencyNumber = 'health-insured-tendonitis-has-consulted-doctors-frequency-number',
  healthInsuredTendonitisHasConsultedDoctorsFrequency = 'health-insured-tendonitis-has-consulted-doctors-frequency',
  healthInsuredTendonitisHasConsultedDoctorFurtherConsultation = 'health-insured-tendonitis-has-consulted-doctor-further-consultation',
  healthInsuredTendonitisHasConsultedDoctorsNextConsultationDate = 'health-insured-tendonitis-has-consulted-doctors-next-consultation-date',

  healthInsuredTendonitisHasTakenTestsOrExamsDetails = 'health-insured-tendonitis-has-taken-tests-or-exams-details',
  healthInsuredTendonitisHasTakenTestsOrExamsType = 'health-insured-tendonitis-has-taken-tests-or-exams-type',
  healthInsuredTendonitisHasTakenTestsOrExamsDate = 'health-insured-tendonitis-has-taken-tests-or-exams-date',
  healthInsuredTendonitisHasTakenTestsOrExamsResult = 'health-insured-tendonitis-has-taken-tests-or-exams-result',
  healthInsuredTendonitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-tendonitis-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredTendonitisMedicationDetails = 'health-insured-tendonitis-medication-details',
  healthInsuredTendonitisMedicationName = 'health-insured-tendonitis-medication-name',
  healthInsuredTendonitisMedicationDosage = 'health-insured-tendonitis-medication-dosage',
  healthInsuredTendonitisMedicationStartDate = 'health-insured-tendonitis-medication-start-date',
  healthInsuredTendonitisMedicationEndDate = 'health-insured-tendonitis-medication-end-date',

  healthInsuredEpicondylitisIsSingleEpisode = 'health-insured-epicondylitis-is-single-episode',
  healthInsuredEpicondylitisPreviousEpisodesCount = 'health-insured-epicondylitis-previous-episodes-count',
  healthInsuredEpicondylitisDateSymptoms = 'health-insured-epicondylitis-date-symptoms',
  healthInsuredEpicondylitisPainLocation = 'health-insured-epicondylitis-pain-location',
  healthInsuredEpicondylitisPainLocationLeftRight = 'health-insured-epicondylitis-pain-location-left-right',
  healthInsuredEpicondylitisPainLocationOther = 'health-insured-epicondylitis-pain-location-other',
  healthInsuredEpicondylitisPainCause = 'health-insured-epicondylitis-pain-cause',
  healthInsuredEpicondylitisPainCauseDetails = 'health-insured-epicondylitis-pain-cause-details',
  healthInsuredEpicondylitisHasConsultedDoctors = 'health-insured-epicondylitis-has-consulted-doctors',
  healthInsuredEpicondylitisHasTakenTestsOrExams = 'health-insured-epicondylitis-has-taken-tests-or-exams',
  healthInsuredEpicondylitisMedicationWasPrescribed = 'health-insured-epicondylitis-medication-was-prescribed',
  healthInsuredEpicondylitisHadOperation = 'health-insured-epicondylitis-had-operation',
  healthInsuredEpicondylitisOperationPerformedOrPlanned = 'health-insured-epicondylitis-operation-performed-or-planned',
  healthInsuredEpicondylitisTypeOfOperationPerformed = 'health-insured-epicondylitis-type-of-operation-performed',
  healthInsuredEpicondylitisOperationPerformedDate = 'health-insured-epicondylitis-operation-performed-date',
  healthInsuredEpicondylitisTypeOfOperationPlanned = 'health-insured-epicondylitis-type-of-operation-planned',
  healthInsuredEpicondylitisOperationPlannedDate = 'health-insured-epicondylitis-operation-planned-date',
  healthInsuredEpicondylitisOperationUnknownDate = 'health-insured-epicondylitis-operation-unknown-date',
  healthInsuredEpicondylitisHadAbsence = 'health-insured-epicondylitis-had-absence',
  healthInsuredEpicondylitisAbsenceStartDate = 'health-insured-epicondylitis-absence-start-date',
  healthInsuredEpicondylitisAbsenceFrequencyNumber = 'health-insured-epicondylitis-absence-frequency-number',
  healthInsuredEpicondylitisAbsenceFrequency = 'health-insured-epicondylitis-absence-frequency',
  healthInsuredEpicondylitisHasRecovered = 'health-insured-epicondylitis-has-recovered',
  healthInsuredEpicondylitisRecoveryDate = 'health-insured-epicondylitis-recovery-date',
  healthInsuredEpicondylitisAbsenceNotRecoveredDetails = 'health-insured-epicondylitis-absence-not-recovered-details',

  healthInsuredEpicondylitisHasConsultedDoctorsDetails = 'health-insured-epicondylitis-has-consulted-doctors-details',
  healthInsuredEpicondylitisHasConsultedDoctorsProfession = 'health-insured-epicondylitis-has-consulted-doctors-profession',
  healthInsuredEpicondylitisHasConsultedDoctorsProfessionDetails = 'health-insured-epicondylitis-has-consulted-doctors-profession-details',
  healthInsuredEpicondylitisHasConsultedDoctorsName = 'health-insured-epicondylitis-has-consulted-doctors-name',
  healthInsuredEpicondylitisHasConsultedDoctorsAddress = 'health-insured-epicondylitis-has-consulted-doctors-address',
  healthInsuredEpicondylitisHasConsultedDoctorsFirstDate = 'health-insured-epicondylitis-has-consulted-doctors-first-date',
  healthInsuredEpicondylitisHasConsultedDoctorsLastDate = 'health-insured-epicondylitis-has-consulted-doctors-last-date',
  healthInsuredEpicondylitisHasConsultedDoctorsResult = 'health-insured-epicondylitis-has-consulted-doctors-result',
  healthInsuredEpicondylitisHasConsultedDoctorsFrequencyNumber = 'health-insured-epicondylitis-has-consulted-doctors-frequency-number',
  healthInsuredEpicondylitisHasConsultedDoctorsFrequency = 'health-insured-epicondylitis-has-consulted-doctors-frequency',
  healthInsuredEpicondylitisHasConsultedDoctorFurtherConsultation = 'health-insured-epicondylitis-has-consulted-doctor-further-consultation',
  healthInsuredEpicondylitisHasConsultedDoctorsNextConsultationDate = 'health-insured-epicondylitis-has-consulted-doctors-next-consultation-date',

  healthInsuredEpicondylitisHasTakenTestsOrExamsDetails = 'health-insured-epicondylitis-has-taken-tests-or-exams-details',
  healthInsuredEpicondylitisHasTakenTestsOrExamsType = 'health-insured-epicondylitis-has-taken-tests-or-exams-type',
  healthInsuredEpicondylitisHasTakenTestsOrExamsDate = 'health-insured-epicondylitis-has-taken-tests-or-exams-date',
  healthInsuredEpicondylitisHasTakenTestsOrExamsResult = 'health-insured-epicondylitis-has-taken-tests-or-exams-result',
  healthInsuredEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-epicondylitis-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredEpicondylitisMedicationDetails = 'health-insured-epicondylitis-medication-details',
  healthInsuredEpicondylitisMedicationName = 'health-insured-epicondylitis-medication-name',
  healthInsuredEpicondylitisMedicationDosage = 'health-insured-epicondylitis-medication-dosage',
  healthInsuredEpicondylitisMedicationStartDate = 'health-insured-epicondylitis-medication-start-date',
  healthInsuredEpicondylitisMedicationEndDate = 'health-insured-epicondylitis-medication-end-date',

  healthInsuredFibromyalgiaHasConsultedDoctorsDetails = 'health-insured-fibromyalgia-has-consulted-doctors-details',
  healthInsuredFibromyalgiaHasConsultedDoctorsProfession = 'health-insured-fibromyalgia-has-consulted-doctors-profession',
  healthInsuredFibromyalgiaHasConsultedDoctorsProfessionDetails = 'health-insured-fibromyalgia-has-consulted-doctors-profession-details',
  healthInsuredFibromyalgiaHasConsultedDoctorsName = 'health-insured-fibromyalgia-has-consulted-doctors-name',
  healthInsuredFibromyalgiaHasConsultedDoctorsAddress = 'health-insured-fibromyalgia-has-consulted-doctors-address',
  healthInsuredFibromyalgiaHasConsultedDoctorsFirstDate = 'health-insured-fibromyalgia-has-consulted-doctors-first-date',
  healthInsuredFibromyalgiaHasConsultedDoctorsLastDate = 'health-insured-fibromyalgia-has-consulted-doctors-last-date',
  healthInsuredFibromyalgiaHasConsultedDoctorsResult = 'health-insured-fibromyalgia-has-consulted-doctors-result',
  healthInsuredFibromyalgiaHasConsultedDoctorsFrequencyNumber = 'health-insured-fibromyalgia-has-consulted-doctors-frequency-number',
  healthInsuredFibromyalgiaHasConsultedDoctorsFrequency = 'health-insured-fibromyalgia-has-consulted-doctors-frequency',
  healthInsuredFibromyalgiaHasConsultedDoctorFurtherConsultation = 'health-insured-fibromyalgia-has-consulted-doctor-further-consultation',
  healthInsuredFibromyalgiaHasConsultedDoctorsNextConsultationDate = 'health-insured-fibromyalgia-has-consulted-doctors-next-consultation-date',

  healthInsuredFibromyalgiaHasTakenTestsOrExamsDetails = 'health-insured-fibromyalgia-has-taken-tests-or-exams-details',
  healthInsuredFibromyalgiaHasTakenTestsOrExamsType = 'health-insured-fibromyalgia-has-taken-tests-or-exams-type',
  healthInsuredFibromyalgiaHasTakenTestsOrExamsDate = 'health-insured-fibromyalgia-has-taken-tests-or-exams-date',
  healthInsuredFibromyalgiaHasTakenTestsOrExamsResult = 'health-insured-fibromyalgia-has-taken-tests-or-exams-result',
  healthInsuredFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-fibromyalgia-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredFibromyalgiaMedicationDetails = 'health-insured-fibromyalgia-medication-details',
  healthInsuredFibromyalgiaMedicationName = 'health-insured-fibromyalgia-medication-name',
  healthInsuredFibromyalgiaMedicationDosage = 'health-insured-fibromyalgia-medication-dosage',
  healthInsuredFibromyalgiaMedicationStartDate = 'health-insured-fibromyalgia-medication-start-date',
  healthInsuredFibromyalgiaMedicationEndDate = 'health-insured-fibromyalgia-medication-end-date',

  healthInsuredReplacementJointHasConsultedDoctorsDetails = 'health-insured-replacement-joint-has-consulted-doctors-details',
  healthInsuredReplacementJointHasConsultedDoctorsProfession = 'health-insured-replacement-joint-has-consulted-doctors-profession',
  healthInsuredReplacementJointHasConsultedDoctorsProfessionDetails = 'health-insured-replacement-joint-has-consulted-doctors-profession-details',
  healthInsuredReplacementJointHasConsultedDoctorsName = 'health-insured-replacement-joint-has-consulted-doctors-name',
  healthInsuredReplacementJointHasConsultedDoctorsAddress = 'health-insured-replacement-joint-has-consulted-doctors-address',
  healthInsuredReplacementJointHasConsultedDoctorsFirstDate = 'health-insured-replacement-joint-has-consulted-doctors-first-date',
  healthInsuredReplacementJointHasConsultedDoctorsLastDate = 'health-insured-replacement-joint-has-consulted-doctors-last-date',
  healthInsuredReplacementJointHasConsultedDoctorsResult = 'health-insured-replacement-joint-has-consulted-doctors-result',
  healthInsuredReplacementJointHasConsultedDoctorsFrequencyNumber = 'health-insured-replacement-joint-has-consulted-doctors-frequency-number',
  healthInsuredReplacementJointHasConsultedDoctorsFrequency = 'health-insured-replacement-joint-has-consulted-doctors-frequency',
  healthInsuredReplacementJointHasConsultedDoctorFurtherConsultation = 'health-insured-replacement-joint-has-consulted-doctor-further-consultation',
  healthInsuredReplacementJointHasConsultedDoctorsNextConsultationDate = 'health-insured-replacement-joint-has-consulted-doctors-next-consultation-date',

  healthInsuredReplacementJointHasTakenTestsOrExamsDetails = 'health-insured-replacement-joint-has-taken-tests-or-exams-details',
  healthInsuredReplacementJointHasTakenTestsOrExamsType = 'health-insured-replacement-joint-has-taken-tests-or-exams-type',
  healthInsuredReplacementJointHasTakenTestsOrExamsDate = 'health-insured-replacement-joint-has-taken-tests-or-exams-date',
  healthInsuredReplacementJointHasTakenTestsOrExamsResult = 'health-insured-replacement-joint-has-taken-tests-or-exams-result',
  healthInsuredReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-replacement-joint-has-taken-tests-or-exams-details-abnormal-result',

  healthInsuredReplacementJointMedicationDetails = 'health-insured-replacement-joint-medication-details',
  healthInsuredReplacementJointMedicationName = 'health-insured-replacement-joint-medication-name',
  healthInsuredReplacementJointMedicationDosage = 'health-insured-replacement-joint-medication-dosage',
  healthInsuredReplacementJointMedicationStartDate = 'health-insured-replacement-joint-medication-start-date',
  healthInsuredReplacementJointMedicationEndDate = 'health-insured-replacement-joint-medication-end-date',

  healthInsuredMusculoskeletalSprainHasConsultedDoctorsDetails = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-details',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsProfession = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-profession',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsProfessionDetails = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-profession-details',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsName = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-name',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsAddress = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-address',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsFirstDate = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-first-date',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsLastDate = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-last-date',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsResult = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-result',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-frequency-number',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsFrequency = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-frequency',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorFurtherConsultation = 'health-insured-musculoskeletal-sprain-has-consulted-doctor-further-consultation',
  healthInsuredMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate = 'health-insured-musculoskeletal-sprain-has-consulted-doctors-next-consultation-date',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDetails = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams-details',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsType = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams-type',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDate = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams-date',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsResult = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams-result',
  healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult = 'health-insured-musculoskeletal-sprain-has-taken-tests-or-exams-details-abnormal-result',
  healthInsuredMusculoskeletalSprainMedicationDetails = 'health-insured-musculoskeletal-sprain-medication-details',
  healthInsuredMusculoskeletalSprainMedicationName = 'health-insured-musculoskeletal-sprain-medication-name',
  healthInsuredMusculoskeletalSprainMedicationDosage = 'health-insured-musculoskeletal-sprain-medication-dosage',
  healthInsuredMusculoskeletalSprainMedicationStartDate = 'health-insured-musculoskeletal-sprain-medication-start-date',
  healthInsuredMusculoskeletalSprainMedicationEndDate = 'health-insured-musculoskeletal-sprain-medication-end-date',

  healthInsuredCancerHasCondition = 'health-insured-cancer-has-condition',
  healthInsuredCancerChoices = 'health-insured-cancer-choices',
  healthInsuredCancerDetails = 'health-insured-cancer-details',
  healthInsuredCancerOtherDetails = 'health-insured-cancer-other-details',
  healthInsuredBreastCystDetails = 'health-insured-breast-cyst-details',
  healthInsuredOvarianCystDetails = 'health-insured-ovarian-cyst-details',
  healthInsuredPolypsDetails = 'health-insured-polyps-details',
  healthInsuredTumorBodyPart = 'health-insured-tumor-body-part',
  healthInsuredTumorSeverity = 'health-insured-tumor-severity',
  healthInsuredLymphNodeDisorderDetails = 'health-insured-lymph-node-disorder-details',
  healthInsuredBreastDisorderHasCondition = 'health-insured-breast-disorder-has-condition',
  healthInsuredBreastDisorderApplicableConditions = 'health-insured-breast-disorder-applicable-conditions',
  healthInsuredBreastDisorderLumpDetails = 'health-insured-breast-disorder-lump-details',
  healthInsuredBreastDisorderBumpDetails = 'health-insured-breast-disorder-bump-details',
  healthInsuredBreastDisorderCystDetails = 'health-insured-breast-disorder-cyst-details',
  healthInsuredBreastDisorderOtherDetails = 'health-insured-breast-disorder-other-details',

  healthInsuredEyeEarNoseThroatDisorderHasCondition = 'health-insured-eye-ear-nose-throat-disorder-has-condition',
  healthInsuredEyeEarNoseThroatDisorderChoices = 'health-insured-eye-ear-nose-throat-disorder-choices',
  healthInsuredEyeEarNoseThroatOtherDisorderDetails = 'health-insured-eye-ear-nose-throat-other-disorder-details',
  healthInsuredHadConsultationTreatmentForBloodOrSkinDisease = 'health-insured-had-consultation-treatment-for-blood-or-skin-disease',
  healthInsuredHadConsultationTreatmentForBloodOrSkinDiseaseDetails = 'health-insured-had-consultation-treatment-for-blood-or-skin-disease-details',
  healthInsuredHadConsultationTreatmentForBloodDiseaseDetails = 'health-insured-had-consultation-treatment-for-blood-disease-details',
  healthInsuredHadConsultationTreatmentForSkinDiseaseDetails = 'health-insured-had-consultation-treatment-for-skin-disease-details',

  healthInsuredHadDisabilityOrAbsenceFromWork = 'health-insured-had-disability-or-absence-from-work',
  healthInsuredHadDisabilityOrAbsenceFromWorkDetails = 'health-insured-had-disability-or-absence-from-work-details',
  healthInsuredWeightLossIsIntentional = 'health-insured-weight-loss-is-intentional',

  lifestyleTravelFutureDetailsWithin2Years = 'lifestyle-travel-future-details-within-2years',
  lifestyleTravelPastDetailsWithin2Years = 'lifestyle-travel-past-details-within-2years',

  lifestyleInsuredHasTraveled = 'lifestyle-insured-has-traveled',
  lifestyleInsuredTravel12MonthsPastOrFuture = 'lifestyle-insured-travel-12-months-past-or-future',

  insuredReplacementInsuranceJoined = 'insured-replacement-insurance-joined',
  insuredParentHoldInsuranceContract = 'insured-parent-hold-insurance-contract',
  insuredFatherLifeAmountInForce = 'insured-father-life-amount-in-force',
  insuredFatherCriticalIllnessAmountInForce = 'insured-father-critical-illness-amount-in-force',
  insuredMotherLifeAmountInForce = 'insured-mother-life-amount-in-force',
  insuredMotherCriticalIllnessAmountInForce = 'insured-mother-critical-illness-amount-in-force',
  insuredSiblingsHoldInsuranceContract = 'insured-siblings-hold-insurance-contract',

  lifestyleInsuredMarijuanaUse = 'lifestyle-insured-marijuana-use',
  lifestyleInsuredMarijuanaUseForm = 'lifestyle-insured-marijuana-use-form',

  lifestyleInsuredMarijuanaJointConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-joint-consumption-habits-quantity',
  lifestyleInsuredMarijuanaJointConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-joint-consumption-habits-frequency',
  lifestyleInsuredMarijuanaJointConsumptionHabitsUsageDate = 'lifestyle-insured-marijuana-joint-consumption-habits-usage-date',
  lifestyleInsuredMarijuanaJointConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-joint-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaJointConsumptionMedicalCondition = 'lifestyle-insured-marijuana-joint-consumption-medical-condition',
  lifestyleInsuredMarijuanaJointMedicalPrescribed = 'lifestyle-insured-marijuana-joint-medical-prescribed',
  lifestyleInsuredMarijuanaJointMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-joint-medical-prescribing-physician',

  lifestyleInsuredMarijuanaJointPastUse = 'lifestyle-insured-marijuana-joint-past-use',
  lifestyleInsuredMarijuanaJointPastConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-joint-past-consumption-habits-quantity',
  lifestyleInsuredMarijuanaJointPastConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-joint-past-consumption-habits-frequency',
  lifestyleInsuredMarijuanaJointPastConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-joint-past-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaJointPastConsumptionMedicalCondition = 'lifestyle-insured-marijuana-joint-past-consumption-medical-condition',
  lifestyleInsuredMarijuanaJointPastConsumptionMedicalPrescribed = 'lifestyle-insured-marijuana-joint-past-consumption-medical-prescribed',
  lifestyleInsuredMarijuanaJointPastConsumptionMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-joint-past-consumption-medical-prescribing-physician',
  lifestyleInsuredMarijuanaJointChangeHabitConsumptionDate = 'lifestyle-insured-marijuana-joint-change-habit-consumption-date',
  lifestyleInsuredMarijuanaJointChangeHabitConsumptionReason = 'lifestyle-insured-marijuana-joint-change-habit-consumption-reason',

  lifestyleInsuredMarijuanaEdibleConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-edible-consumption-habits-quantity',
  lifestyleInsuredMarijuanaEdibleConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-edible-consumption-habits-frequency',
  lifestyleInsuredMarijuanaEdibleConsumptionHabitsUsageDate = 'lifestyle-insured-marijuana-edible-consumption-habits-usage-date',
  lifestyleInsuredMarijuanaEdibleConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-edible-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaEdibleConsumptionMedicalCondition = 'lifestyle-insured-marijuana-edible-consumption-medical-condition',
  lifestyleInsuredMarijuanaEdibleMedicalPrescribed = 'lifestyle-insured-marijuana-edible-medical-prescribed',
  lifestyleInsuredMarijuanaEdibleMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-edible-medical-prescribing-physician',

  lifestyleInsuredMarijuanaEdiblePastUse = 'lifestyle-insured-marijuana-edible-past-use',
  lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-edible-past-consumption-habits-quantity',
  lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-edible-past-consumption-habits-frequency',
  lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-edible-past-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalCondition = 'lifestyle-insured-marijuana-edible-past-consumption-medical-condition',
  lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalPrescribed = 'lifestyle-insured-marijuana-edible-past-consumption-medical-prescribed',
  lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-edible-past-consumption-medical-prescribing-physician',
  lifestyleInsuredMarijuanaEdibleChangeHabitConsumptionDate = 'lifestyle-insured-marijuana-edible-change-habit-consumption-date',
  lifestyleInsuredMarijuanaEdibleChangeHabitConsumptionReason = 'lifestyle-insured-marijuana-edible-change-habit-consumption-reason',

  lifestyleInsuredMarijuanaOilConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-oil-consumption-habits-quantity',
  lifestyleInsuredMarijuanaOilConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-oil-consumption-habits-frequency',
  lifestyleInsuredMarijuanaOilConsumptionHabitsUsageDate = 'lifestyle-insured-marijuana-oil-consumption-habits-usage-date',
  lifestyleInsuredMarijuanaOilConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-oil-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaOilConsumptionMedicalCondition = 'lifestyle-insured-marijuana-oil-consumption-medical-condition',
  lifestyleInsuredMarijuanaOilMedicalPrescribed = 'lifestyle-insured-marijuana-oil-medical-prescribed',
  lifestyleInsuredMarijuanaOilMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-oil-medical-prescribing-physician',

  lifestyleInsuredMarijuanaOilPastUse = 'lifestyle-insured-marijuana-oil-past-use',
  lifestyleInsuredMarijuanaOilPastConsumptionHabitsQuantity = 'lifestyle-insured-marijuana-oil-past-consumption-habits-quantity',
  lifestyleInsuredMarijuanaOilPastConsumptionHabitsFrequency = 'lifestyle-insured-marijuana-oil-past-consumption-habits-frequency',
  lifestyleInsuredMarijuanaOilPastConsumptionHabitsUsageType = 'lifestyle-insured-marijuana-oil-past-consumption-habits-usage-type',
  lifestyleInsuredMarijuanaOilPastConsumptionMedicalCondition = 'lifestyle-insured-marijuana-oil-past-consumption-medical-condition',
  lifestyleInsuredMarijuanaOilPastConsumptionMedicalPrescribed = 'lifestyle-insured-marijuana-oil-past-consumption-medical-prescribed',
  lifestyleInsuredMarijuanaOilPastConsumptionMedicalPrescribingPhysician = 'lifestyle-insured-marijuana-oil-past-consumption-medical-prescribing-physician',
  lifestyleInsuredMarijuanaOilChangeHabitConsumptionDate = 'lifestyle-insured-marijuana-oil-change-habit-consumption-date',
  lifestyleInsuredMarijuanaOilChangeHabitConsumptionReason = 'lifestyle-insured-marijuana-oil-change-habit-consumption-reason',

  lifestyleInsuredDrugsOrNarcoticsUse = 'lifestyle-insured-drugs-or-narcotics-use',
  lifestyleInsuredDrugsOrNarcoticsUseType = 'lifestyle-insured-drugs-or-narcotics-use-type',
  lifestyleInsuredDrugsOrNarcoticsUseFormOther = 'lifestyle-insured-drugs-or-narcotics-use-form-other',

  lifestyleInsuredDrugsCocaineConsumptionHabitsQuantity = 'lifestyle-insured-drugs-cocaine-consumption-habits-quantity',
  lifestyleInsuredDrugsCocaineConsumptionHabitsFrequency = 'lifestyle-insured-drugs-cocaine-consumption-habits-frequency',
  lifestyleInsuredDrugsCocaineUseStillConsume = 'lifestyle-insured-drugs-cocaine-use-still-consume',
  lifestyleInsuredDrugsCocaineUseStartDate = 'lifestyle-insured-drugs-cocaine-use-start-date',
  lifestyleInsuredDrugsCocaineUseEndDate = 'lifestyle-insured-drugs-cocaine-use-end-date',
  lifestyleInsuredDrugsCocainePastUse = 'lifestyle-insured-drugs-cocaine-past-use',
  lifestyleInsuredDrugsCocainePastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-cocaine-past-consumption-habits-quantity',
  lifestyleInsuredDrugsCocainePastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-cocaine-past-consumption-habits-frequency',
  lifestyleInsuredDrugsCocainePastUseStartDate = 'lifestyle-insured-drugs-cocaine-past-use-start-date',
  lifestyleInsuredDrugsCocainePastUseEndDate = 'lifestyle-insured-drugs-cocaine-past-use-end-date',
  lifestyleInsuredDrugsCocainePastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-cocaine-past-change-habit-consumption-date',
  lifestyleInsuredDrugsCocainePastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-cocaine-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsEcstasyConsumptionHabitsQuantity = 'lifestyle-insured-drugs-ecstasy-consumption-habits-quantity',
  lifestyleInsuredDrugsEcstasyConsumptionHabitsFrequency = 'lifestyle-insured-drugs-ecstasy-consumption-habits-frequency',
  lifestyleInsuredDrugsEcstasyUseStillConsume = 'lifestyle-insured-drugs-ecstasy-use-still-consume',
  lifestyleInsuredDrugsEcstasyUseStartDate = 'lifestyle-insured-drugs-ecstasy-use-start-date',
  lifestyleInsuredDrugsEcstasyUseEndDate = 'lifestyle-insured-drugs-ecstasy-use-end-date',
  lifestyleInsuredDrugsEcstasyPastUse = 'lifestyle-insured-drugs-ecstasy-past-use',
  lifestyleInsuredDrugsEcstasyPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-ecstasy-past-consumption-habits-quantity',
  lifestyleInsuredDrugsEcstasyPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-ecstasy-past-consumption-habits-frequency',
  lifestyleInsuredDrugsEcstasyPastUseStartDate = 'lifestyle-insured-drugs-ecstasy-past-use-start-date',
  lifestyleInsuredDrugsEcstasyPastUseEndDate = 'lifestyle-insured-drugs-ecstasy-past-use-end-date',
  lifestyleInsuredDrugsEcstasyPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-ecstasy-past-change-habit-consumption-date',
  lifestyleInsuredDrugsEcstasyPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-ecstasy-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsLsdConsumptionHabitsQuantity = 'lifestyle-insured-drugs-lsd-consumption-habits-quantity',
  lifestyleInsuredDrugsLsdConsumptionHabitsFrequency = 'lifestyle-insured-drugs-lsd-consumption-habits-frequency',
  lifestyleInsuredDrugsLsdUseStillConsume = 'lifestyle-insured-drugs-lsd-use-still-consume',
  lifestyleInsuredDrugsLsdUseStartDate = 'lifestyle-insured-drugs-lsd-use-start-date',
  lifestyleInsuredDrugsLsdUseEndDate = 'lifestyle-insured-drugs-lsd-use-end-date',
  lifestyleInsuredDrugsLsdPastUse = 'lifestyle-insured-drugs-lsd-past-use',
  lifestyleInsuredDrugsLsdPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-lsd-past-consumption-habits-quantity',
  lifestyleInsuredDrugsLsdPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-lsd-past-consumption-habits-frequency',
  lifestyleInsuredDrugsLsdPastUseStartDate = 'lifestyle-insured-drugs-lsd-past-use-start-date',
  lifestyleInsuredDrugsLsdPastUseEndDate = 'lifestyle-insured-drugs-lsd-past-use-end-date',
  lifestyleInsuredDrugsLsdPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-lsd-past-change-habit-consumption-date',
  lifestyleInsuredDrugsLsdPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-lsd-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsMagicMushroomsConsumptionHabitsQuantity = 'lifestyle-insured-drugs-magic-mushrooms-consumption-habits-quantity',
  lifestyleInsuredDrugsMagicMushroomsConsumptionHabitsFrequency = 'lifestyle-insured-drugs-magic-mushrooms-consumption-habits-frequency',
  lifestyleInsuredDrugsMagicMushroomsUseStillConsume = 'lifestyle-insured-drugs-magic-mushrooms-use-still-consume',
  lifestyleInsuredDrugsMagicMushroomsUseStartDate = 'lifestyle-insured-drugs-magic-mushrooms-use-start-date',
  lifestyleInsuredDrugsMagicMushroomsUseEndDate = 'lifestyle-insured-drugs-magic-mushrooms-use-end-date',
  lifestyleInsuredDrugsMagicMushroomsPastUse = 'lifestyle-insured-drugs-magic-mushrooms-past-use',
  lifestyleInsuredDrugsMagicMushroomsPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-magic-mushrooms-past-consumption-habits-quantity',
  lifestyleInsuredDrugsMagicMushroomsPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-magic-mushrooms-past-consumption-habits-frequency',
  lifestyleInsuredDrugsMagicMushroomsPastUseStartDate = 'lifestyle-insured-drugs-magic-mushrooms-past-use-start-date',
  lifestyleInsuredDrugsMagicMushroomsPastUseEndDate = 'lifestyle-insured-drugs-magic-mushrooms-past-use-end-date',
  lifestyleInsuredDrugsMagicMushroomsPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-magic-mushrooms-past-change-habit-consumption-date',
  lifestyleInsuredDrugsMagicMushroomsPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-magic-mushrooms-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsHeroinConsumptionHabitsQuantity = 'lifestyle-insured-drugs-heroin-consumption-habits-quantity',
  lifestyleInsuredDrugsHeroinConsumptionHabitsFrequency = 'lifestyle-insured-drugs-heroin-consumption-habits-frequency',
  lifestyleInsuredDrugsHeroinUseStillConsume = 'lifestyle-insured-drugs-heroin-use-still-consume',
  lifestyleInsuredDrugsHeroinUseStartDate = 'lifestyle-insured-drugs-heroin-use-start-date',
  lifestyleInsuredDrugsHeroinUseEndDate = 'lifestyle-insured-drugs-heroin-use-end-date',
  lifestyleInsuredDrugsHeroinPastUse = 'lifestyle-insured-drugs-heroin-past-use',
  lifestyleInsuredDrugsHeroinPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-heroin-past-consumption-habits-quantity',
  lifestyleInsuredDrugsHeroinPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-heroin-past-consumption-habits-frequency',
  lifestyleInsuredDrugsHeroinPastUseStartDate = 'lifestyle-insured-drugs-heroin-past-use-start-date',
  lifestyleInsuredDrugsHeroinPastUseEndDate = 'lifestyle-insured-drugs-heroin-past-use-end-date',
  lifestyleInsuredDrugsHeroinPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-heroin-past-change-habit-consumption-date',
  lifestyleInsuredDrugsHeroinPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-heroin-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsFentanylConsumptionHabitsQuantity = 'lifestyle-insured-drugs-fentanyl-consumption-habits-quantity',
  lifestyleInsuredDrugsFentanylConsumptionHabitsFrequency = 'lifestyle-insured-drugs-fentanyl-consumption-habits-frequency',
  lifestyleInsuredDrugsFentanylUseStillConsume = 'lifestyle-insured-drugs-fentanyl-use-still-consume',
  lifestyleInsuredDrugsFentanylUseStartDate = 'lifestyle-insured-drugs-fentanyl-use-start-date',
  lifestyleInsuredDrugsFentanylUseEndDate = 'lifestyle-insured-drugs-fentanyl-use-end-date',
  lifestyleInsuredDrugsFentanylPastUse = 'lifestyle-insured-drugs-fentanyl-past-use',
  lifestyleInsuredDrugsFentanylPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-fentanyl-past-consumption-habits-quantity',
  lifestyleInsuredDrugsFentanylPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-fentanyl-past-consumption-habits-frequency',
  lifestyleInsuredDrugsFentanylPastUseStartDate = 'lifestyle-insured-drugs-fentanyl-past-use-start-date',
  lifestyleInsuredDrugsFentanylPastUseEndDate = 'lifestyle-insured-drugs-fentanyl-past-use-end-date',
  lifestyleInsuredDrugsFentanylPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-fentanyl-past-change-habit-consumption-date',
  lifestyleInsuredDrugsFentanylPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-fentanyl-past-change-habit-consumption-reason',

  lifestyleInsuredDrugsAnabolicSteroidsConsumptionHabitsQuantity = 'lifestyle-insured-drugs-anabolic-steroids-consumption-habits-quantity',
  lifestyleInsuredDrugsAnabolicSteroidsConsumptionHabitsFrequency = 'lifestyle-insured-drugs-anabolic-steroids-consumption-habits-frequency',
  lifestyleInsuredDrugsAnabolicSteroidsUseStillConsume = 'lifestyle-insured-drugs-anabolic-steroids-use-still-consume',
  lifestyleInsuredDrugsAnabolicSteroidsUseStartDate = 'lifestyle-insured-drugs-anabolic-steroids-use-start-date',
  lifestyleInsuredDrugsAnabolicSteroidsUseEndDate = 'lifestyle-insured-drugs-anabolic-steroids-use-end-date',
  lifestyleInsuredDrugsAnabolicSteroidsPastUse = 'lifestyle-insured-drugs-anabolic-steroids-past-use',
  lifestyleInsuredDrugsAnabolicSteroidsPastConsumptionHabitsQuantity = 'lifestyle-insured-drugs-anabolic-steroids-past-consumption-habits-quantity',
  lifestyleInsuredDrugsAnabolicSteroidsPastConsumptionHabitsFrequency = 'lifestyle-insured-drugs-anabolic-steroids-past-consumption-habits-frequency',
  lifestyleInsuredDrugsAnabolicSteroidsPastUseStartDate = 'lifestyle-insured-drugs-anabolic-steroids-past-use-start-date',
  lifestyleInsuredDrugsAnabolicSteroidsPastUseEndDate = 'lifestyle-insured-drugs-anabolic-steroids-past-use-end-date',
  lifestyleInsuredDrugsAnabolicSteroidsPastChangeHabitConsumptionDate = 'lifestyle-insured-drugs-anabolic-steroids-past-change-habit-consumption-date',
  lifestyleInsuredDrugsAnabolicSteroidsPastChangeHabitConsumptionReason = 'lifestyle-insured-drugs-anabolic-steroids-past-change-habit-consumption-reason',

  investmentInformation = 'investment-information',
  investmentAccountType = 'investment-account-type',
  investmentManagedAccount = 'investment-managed-account',
  investmentInterestAccount = 'investment-interest-account',
  investmentIndexedAccount = 'investment-indexed-account',
  investmentOtherAccount = 'investment-other-account',
  investmentPercentage = 'investment-percentage',

  advisorNetwork = 'advisor-network',
  advisorCode = 'advisor-code',

  // Used by Dynamic-PDF signature fields
  insuredSignature = 'insured-signature',
  ownerSignature = 'owner-signature',
  legalGuardianSignature = 'legal-guardian-signature',
  payerSignature = 'payer-signature',
  ownerOrganizationSignature = 'owner-organization-signature',
  primaryBeneficiarySignature = 'primary-beneficiary-signature',
  contingentBeneficiarySignature = 'contingent-beneficiary-signature',
  agentSignature = 'agent-signature',
  translatorSignature = 'translator-signature',
  signingAuthoritySignature = 'signing-authority-signature',
  trusteeSignature = 'trustee-signature',
  soleProprietorSignature = 'sole-proprietor-signature',
  officerSignature = 'officer-signature',

  tempNodeId1 = '1-temp-node-id',
  tempNodeId2 = '2-temp-node-id',
  tempNodeId3 = '3-temp-node-id',
  tempNodeId4 = '4-temp-node-id',
  tempNodeId5 = '5-temp-node-id',
  tempNodeId6 = '6-temp-node-id',
  tempNodeId7 = '7-temp-node-id',
  tempNodeId8 = '8-temp-node-id',
  tempNodeId9 = '9-temp-node-id',
  tempNodeId10 = '10-temp-node-id',
  tempNodeId11 = '11-temp-node-id',
  tempNodeId12 = '12-temp-node-id',
  tempNodeId13 = '13-temp-node-id',
  tempNodeId14 = '14-temp-node-id',
  tempNodeId15 = '15-temp-node-id',
  tempNodeId16 = '16-temp-node-id',
  tempNodeId17 = '17-temp-node-id',
  tempNodeId18 = '18-temp-node-id',
  tempNodeId19 = '19-temp-node-id',
  tempNodeId20 = '20-temp-node-id',
  tempNodeId21 = '21-temp-node-id',
  tempNodeId22 = '22-temp-node-id',
  tempNodeId23 = '23-temp-node-id',
  tempNodeId24 = '24-temp-node-id',
  tempNodeId25 = '25-temp-node-id',
  tempNodeId26 = '26-temp-node-id',
  tempNodeId27 = '27-temp-node-id',
  tempNodeId28 = '28-temp-node-id',
  tempNodeId29 = '29-temp-node-id',
  tempNodeId30 = '30-temp-node-id',
  tempNodeId31 = '31-temp-node-id',
  tempNodeId32 = '32-temp-node-id',
  tempNodeId33 = '33-temp-node-id',
  tempNodeId34 = '34-temp-node-id',
  tempNodeId35 = '35-temp-node-id',
  tempNodeId36 = '36-temp-node-id',
  tempNodeId37 = '37-temp-node-id',
  tempNodeId38 = '38-temp-node-id',
  tempNodeId39 = '39-temp-node-id',
  tempNodeId40 = '40-temp-node-id',
  tempNodeId41 = '41-temp-node-id',
  tempNodeId42 = '42-temp-node-id',
  tempNodeId43 = '43-temp-node-id',
  tempNodeId44 = '44-temp-node-id',
  tempNodeId45 = '45-temp-node-id',
  tempNodeId46 = '46-temp-node-id',
  tempNodeId47 = '47-temp-node-id',
  tempNodeId48 = '48-temp-node-id',
  tempNodeId49 = '49-temp-node-id',
  tempNodeId50 = '50-temp-node-id',
  tempNodeId51 = '51-temp-node-id',
  tempNodeId52 = '52-temp-node-id',
  tempNodeId53 = '53-temp-node-id',
  tempNodeId54 = '54-temp-node-id',
  tempNodeId55 = '55-temp-node-id',
  tempNodeId56 = '56-temp-node-id',
  tempNodeId57 = '57-temp-node-id',
  tempNodeId58 = '58-temp-node-id',
  tempNodeId59 = '59-temp-node-id',
  tempNodeId60 = '60-temp-node-id',
  tempNodeId61 = '61-temp-node-id',
  tempNodeId62 = '62-temp-node-id',
  tempNodeId63 = '63-temp-node-id',
  tempNodeId64 = '64-temp-node-id',
  tempNodeId65 = '65-temp-node-id',
  tempNodeId66 = '66-temp-node-id',
  tempNodeId67 = '67-temp-node-id',
  tempNodeId68 = '68-temp-node-id',
  tempNodeId69 = '69-temp-node-id',
  tempNodeId70 = '70-temp-node-id',
  tempNodeId71 = '71-temp-node-id',
  tempNodeId72 = '72-temp-node-id',
  tempNodeId73 = '73-temp-node-id',
  tempNodeId74 = '74-temp-node-id',
  tempNodeId75 = '75-temp-node-id',
  tempNodeId76 = '76-temp-node-id',
  tempNodeId77 = '77-temp-node-id',
  tempNodeId78 = '78-temp-node-id',
  tempNodeId79 = '79-temp-node-id',
  tempNodeId80 = '80-temp-node-id',
  tempNodeId81 = '81-temp-node-id',
  tempNodeId82 = '82-temp-node-id',
  tempNodeId83 = '83-temp-node-id',
  tempNodeId84 = '84-temp-node-id',
  tempNodeId85 = '85-temp-node-id',
  tempNodeId86 = '86-temp-node-id',
  tempNodeId87 = '87-temp-node-id',
  tempNodeId88 = '88-temp-node-id',
  tempNodeId89 = '89-temp-node-id',
  tempNodeId90 = '90-temp-node-id',
  tempNodeId91 = '91-temp-node-id',
  tempNodeId92 = '92-temp-node-id',
  tempNodeId93 = '93-temp-node-id',
  tempNodeId94 = '94-temp-node-id',
  tempNodeId95 = '95-temp-node-id',
  tempNodeId96 = '96-temp-node-id',
  tempNodeId97 = '97-temp-node-id',
  tempNodeId98 = '98-temp-node-id',
  tempNodeId99 = '99-temp-node-id',
  tempNodeId100 = '100-temp-node-id',
  tempNodeId101 = '101-temp-node-id',
  tempNodeId102 = '102-temp-node-id',
  tempNodeId103 = '103-temp-node-id',
  tempNodeId104 = '104-temp-node-id',
  tempNodeId105 = '105-temp-node-id',
  tempNodeId106 = '106-temp-node-id',
  tempNodeId107 = '107-temp-node-id',
  tempNodeId108 = '108-temp-node-id',
  tempNodeId109 = '109-temp-node-id',
  tempNodeId110 = '110-temp-node-id',
  tempNodeId111 = '111-temp-node-id',
  tempNodeId112 = '112-temp-node-id',
  tempNodeId113 = '113-temp-node-id',
  tempNodeId114 = '114-temp-node-id',
  tempNodeId115 = '115-temp-node-id',
  tempNodeId116 = '116-temp-node-id',
  tempNodeId117 = '117-temp-node-id',
  tempNodeId118 = '118-temp-node-id',
  tempNodeId119 = '119-temp-node-id',
  tempNodeId120 = '120-temp-node-id',
  tempNodeId121 = '121-temp-node-id',
  tempNodeId122 = '122-temp-node-id',
  tempNodeId123 = '123-temp-node-id',
  tempNodeId124 = '124-temp-node-id',
  tempNodeId125 = '125-temp-node-id',
  tempNodeId126 = '126-temp-node-id',
  tempNodeId127 = '127-temp-node-id',
  tempNodeId128 = '128-temp-node-id',
  tempNodeId129 = '129-temp-node-id',
  tempNodeId130 = '130-temp-node-id',
  tempNodeId131 = '131-temp-node-id',
  tempNodeId132 = '132-temp-node-id',
  tempNodeId133 = '133-temp-node-id',
  tempNodeId134 = '134-temp-node-id',
  tempNodeId135 = '135-temp-node-id',
  tempNodeId136 = '136-temp-node-id',
  tempNodeId137 = '137-temp-node-id',
  tempNodeId138 = '138-temp-node-id',
  tempNodeId139 = '139-temp-node-id',
  tempNodeId140 = '140-temp-node-id',
  tempNodeId141 = '141-temp-node-id',
  tempNodeId142 = '142-temp-node-id',
  tempNodeId143 = '143-temp-node-id',
  tempNodeId144 = '144-temp-node-id',
  tempNodeId145 = '145-temp-node-id',
  tempNodeId146 = '146-temp-node-id',
  tempNodeId147 = '147-temp-node-id',
  tempNodeId148 = '148-temp-node-id',
  tempNodeId149 = '149-temp-node-id',
  tempNodeId150 = '150-temp-node-id',
  tempNodeId151 = '151-temp-node-id',
  tempNodeId152 = '152-temp-node-id',
  tempNodeId153 = '153-temp-node-id',
  tempNodeId154 = '154-temp-node-id',
  tempNodeId155 = '155-temp-node-id',
  tempNodeId156 = '156-temp-node-id',
  tempNodeId157 = '157-temp-node-id',
  tempNodeId158 = '158-temp-node-id',
  tempNodeId159 = '159-temp-node-id',
  tempNodeId160 = '160-temp-node-id',
  tempNodeId161 = '161-temp-node-id',
  tempNodeId162 = '162-temp-node-id',
  tempNodeId163 = '163-temp-node-id',
  tempNodeId164 = '164-temp-node-id',
  tempNodeId165 = '165-temp-node-id',
  tempNodeId166 = '166-temp-node-id',
  tempNodeId167 = '167-temp-node-id',
  tempNodeId168 = '168-temp-node-id',
  tempNodeId169 = '169-temp-node-id',
  tempNodeId170 = '170-temp-node-id',
  tempNodeId171 = '171-temp-node-id',
  tempNodeId172 = '172-temp-node-id',
  tempNodeId173 = '173-temp-node-id',
  tempNodeId174 = '174-temp-node-id',
  tempNodeId175 = '175-temp-node-id',
  tempNodeId176 = '176-temp-node-id',
  tempNodeId177 = '177-temp-node-id',
  tempNodeId178 = '178-temp-node-id',
  tempNodeId179 = '179-temp-node-id',
  tempNodeId180 = '180-temp-node-id',
  tempNodeId181 = '181-temp-node-id',
  tempNodeId182 = '182-temp-node-id',
  tempNodeId183 = '183-temp-node-id',
  tempNodeId184 = '184-temp-node-id',
  tempNodeId185 = '185-temp-node-id',
  tempNodeId186 = '186-temp-node-id',
  tempNodeId187 = '187-temp-node-id',
  tempNodeId188 = '188-temp-node-id',
  tempNodeId189 = '189-temp-node-id',
  tempNodeId190 = '190-temp-node-id',
  tempNodeId191 = '191-temp-node-id',
  tempNodeId192 = '192-temp-node-id',
  tempNodeId193 = '193-temp-node-id',
  tempNodeId194 = '194-temp-node-id',
  tempNodeId195 = '195-temp-node-id',
  tempNodeId196 = '196-temp-node-id',
  tempNodeId197 = '197-temp-node-id',
  tempNodeId198 = '198-temp-node-id',
  tempNodeId199 = '199-temp-node-id',
  tempNodeId200 = '200-temp-node-id',
  tempNodeId201 = '201-temp-node-id',
  tempNodeId202 = '202-temp-node-id',
  tempNodeId203 = '203-temp-node-id',
  tempNodeId204 = '204-temp-node-id',
  tempNodeId205 = '205-temp-node-id',
  tempNodeId206 = '206-temp-node-id',
  tempNodeId207 = '207-temp-node-id',
  tempNodeId208 = '208-temp-node-id',
  tempNodeId209 = '209-temp-node-id',
  tempNodeId210 = '210-temp-node-id',
  tempNodeId211 = '211-temp-node-id',
  tempNodeId212 = '212-temp-node-id',
  tempNodeId213 = '213-temp-node-id',
  tempNodeId214 = '214-temp-node-id',
  tempNodeId215 = '215-temp-node-id',
  tempNodeId216 = '216-temp-node-id',
  tempNodeId217 = '217-temp-node-id',
  tempNodeId218 = '218-temp-node-id',
  tempNodeId219 = '219-temp-node-id',
  tempNodeId220 = '220-temp-node-id',
  tempNodeId221 = '221-temp-node-id',
  tempNodeId222 = '222-temp-node-id',
  tempNodeId223 = '223-temp-node-id',
  tempNodeId224 = '224-temp-node-id',
  tempNodeId225 = '225-temp-node-id',
  tempNodeId226 = '226-temp-node-id',
  tempNodeId227 = '227-temp-node-id',
  tempNodeId228 = '228-temp-node-id',
  tempNodeId229 = '229-temp-node-id',
  tempNodeId230 = '230-temp-node-id',
  tempNodeId231 = '231-temp-node-id',
  tempNodeId232 = '232-temp-node-id',
  tempNodeId233 = '233-temp-node-id',
  tempNodeId234 = '234-temp-node-id',
  tempNodeId235 = '235-temp-node-id',
  tempNodeId236 = '236-temp-node-id',
  tempNodeId237 = '237-temp-node-id',
  tempNodeId238 = '238-temp-node-id',
  tempNodeId239 = '239-temp-node-id',
  tempNodeId240 = '240-temp-node-id',
  tempNodeId241 = '241-temp-node-id',
  tempNodeId242 = '242-temp-node-id',
  tempNodeId243 = '243-temp-node-id',
  tempNodeId244 = '244-temp-node-id',
  tempNodeId245 = '245-temp-node-id',
  tempNodeId246 = '246-temp-node-id',
  tempNodeId247 = '247-temp-node-id',
  tempNodeId248 = '248-temp-node-id',
  tempNodeId249 = '249-temp-node-id',
  tempNodeId250 = '250-temp-node-id',
  tempNodeId251 = '251-temp-node-id',
  tempNodeId252 = '252-temp-node-id',
  tempNodeId253 = '253-temp-node-id',
  tempNodeId254 = '254-temp-node-id',
  tempNodeId255 = '255-temp-node-id',
  tempNodeId256 = '256-temp-node-id',
  tempNodeId257 = '257-temp-node-id',
  tempNodeId258 = '258-temp-node-id',
  tempNodeId259 = '259-temp-node-id',
  tempNodeId260 = '260-temp-node-id',
  tempNodeId261 = '261-temp-node-id',
  tempNodeId262 = '262-temp-node-id',
  tempNodeId263 = '263-temp-node-id',
  tempNodeId264 = '264-temp-node-id',
  tempNodeId265 = '265-temp-node-id',
  tempNodeId266 = '266-temp-node-id',
  tempNodeId267 = '267-temp-node-id',
  tempNodeId268 = '268-temp-node-id',
  tempNodeId269 = '269-temp-node-id',
  tempNodeId270 = '270-temp-node-id',
  tempNodeId271 = '271-temp-node-id',
  tempNodeId272 = '272-temp-node-id',
  tempNodeId273 = '273-temp-node-id',
  tempNodeId274 = '274-temp-node-id',
  tempNodeId275 = '275-temp-node-id',
  tempNodeId276 = '276-temp-node-id',
  tempNodeId277 = '277-temp-node-id',
  tempNodeId278 = '278-temp-node-id',
  tempNodeId279 = '279-temp-node-id',
  tempNodeId280 = '280-temp-node-id',
  tempNodeId281 = '281-temp-node-id',
  tempNodeId282 = '282-temp-node-id',
  tempNodeId283 = '283-temp-node-id',
  tempNodeId284 = '284-temp-node-id',
  tempNodeId285 = '285-temp-node-id',
  tempNodeId286 = '286-temp-node-id',
  tempNodeId287 = '287-temp-node-id',
  tempNodeId288 = '288-temp-node-id',
  tempNodeId289 = '289-temp-node-id',
  tempNodeId290 = '290-temp-node-id',
  tempNodeId291 = '291-temp-node-id',
  tempNodeId292 = '292-temp-node-id',
  tempNodeId293 = '293-temp-node-id',
  tempNodeId294 = '294-temp-node-id',
  tempNodeId295 = '295-temp-node-id',
  tempNodeId296 = '296-temp-node-id',
  tempNodeId297 = '297-temp-node-id',
  tempNodeId298 = '298-temp-node-id',
  tempNodeId299 = '299-temp-node-id',
  tempNodeId300 = '300-temp-node-id',
  tempNodeId301 = '301-temp-node-id',
  tempNodeId302 = '302-temp-node-id',
  tempNodeId303 = '303-temp-node-id',
  tempNodeId304 = '304-temp-node-id',
  tempNodeId305 = '305-temp-node-id',
  tempNodeId306 = '306-temp-node-id',
  tempNodeId307 = '307-temp-node-id',
  tempNodeId308 = '308-temp-node-id',
  tempNodeId309 = '309-temp-node-id',
  tempNodeId310 = '310-temp-node-id',
  tempNodeId311 = '311-temp-node-id',
  tempNodeId312 = '312-temp-node-id',
  tempNodeId313 = '313-temp-node-id',
  tempNodeId314 = '314-temp-node-id',
  tempNodeId315 = '315-temp-node-id',
  tempNodeId316 = '316-temp-node-id',
  tempNodeId317 = '317-temp-node-id',
  tempNodeId318 = '318-temp-node-id',
  tempNodeId319 = '319-temp-node-id',
  tempNodeId320 = '320-temp-node-id',
  tempNodeId321 = '321-temp-node-id',
  tempNodeId322 = '322-temp-node-id',
  tempNodeId323 = '323-temp-node-id',
  tempNodeId324 = '324-temp-node-id',
  tempNodeId325 = '325-temp-node-id',
  tempNodeId326 = '326-temp-node-id',
  tempNodeId327 = '327-temp-node-id',
  tempNodeId328 = '328-temp-node-id',
  tempNodeId329 = '329-temp-node-id',
  tempNodeId330 = '330-temp-node-id',
  tempNodeId331 = '331-temp-node-id',
  tempNodeId332 = '332-temp-node-id',
  tempNodeId333 = '333-temp-node-id',
  tempNodeId334 = '334-temp-node-id',
  tempNodeId335 = '335-temp-node-id',
  tempNodeId336 = '336-temp-node-id',
  tempNodeId337 = '337-temp-node-id',
  tempNodeId338 = '338-temp-node-id',
  tempNodeId339 = '339-temp-node-id',
  tempNodeId340 = '340-temp-node-id',
  tempNodeId341 = '341-temp-node-id',
  tempNodeId342 = '342-temp-node-id',
  tempNodeId343 = '343-temp-node-id',
  tempNodeId344 = '344-temp-node-id',
  tempNodeId345 = '345-temp-node-id',
  tempNodeId346 = '346-temp-node-id',
  tempNodeId347 = '347-temp-node-id',
  tempNodeId348 = '348-temp-node-id',
  tempNodeId349 = '349-temp-node-id',
  tempNodeId350 = '350-temp-node-id',
  tempNodeId351 = '351-temp-node-id',
  tempNodeId352 = '352-temp-node-id',
  tempNodeId353 = '353-temp-node-id',
  tempNodeId354 = '354-temp-node-id',
  tempNodeId355 = '355-temp-node-id',
  tempNodeId356 = '356-temp-node-id',
  tempNodeId357 = '357-temp-node-id',
  tempNodeId358 = '358-temp-node-id',
  tempNodeId359 = '359-temp-node-id',
  tempNodeId360 = '360-temp-node-id',
  tempNodeId361 = '361-temp-node-id',
  tempNodeId362 = '362-temp-node-id',
  tempNodeId363 = '363-temp-node-id',
  tempNodeId364 = '364-temp-node-id',
  tempNodeId365 = '365-temp-node-id',
  tempNodeId366 = '366-temp-node-id',
  tempNodeId367 = '367-temp-node-id',
  tempNodeId368 = '368-temp-node-id',
  tempNodeId369 = '369-temp-node-id',
  tempNodeId370 = '370-temp-node-id',
  tempNodeId371 = '371-temp-node-id',
  tempNodeId372 = '372-temp-node-id',
  tempNodeId373 = '373-temp-node-id',
  tempNodeId374 = '374-temp-node-id',
  tempNodeId375 = '375-temp-node-id',
  tempNodeId376 = '376-temp-node-id',
  tempNodeId377 = '377-temp-node-id',
  tempNodeId378 = '378-temp-node-id',
  tempNodeId379 = '379-temp-node-id',
  tempNodeId380 = '380-temp-node-id',
  tempNodeId381 = '381-temp-node-id',
  tempNodeId382 = '382-temp-node-id',
  tempNodeId383 = '383-temp-node-id',
  tempNodeId384 = '384-temp-node-id',
  tempNodeId385 = '385-temp-node-id',
  tempNodeId386 = '386-temp-node-id',
  tempNodeId387 = '387-temp-node-id',
  tempNodeId388 = '388-temp-node-id',
  tempNodeId389 = '389-temp-node-id',
  tempNodeId390 = '390-temp-node-id',
  tempNodeId391 = '391-temp-node-id',
  tempNodeId392 = '392-temp-node-id',
  tempNodeId393 = '393-temp-node-id',
  tempNodeId394 = '394-temp-node-id',
  tempNodeId395 = '395-temp-node-id',
  tempNodeId396 = '396-temp-node-id',
  tempNodeId397 = '397-temp-node-id',
  tempNodeId398 = '398-temp-node-id',
  tempNodeId399 = '399-temp-node-id',
  tempNodeId400 = '400-temp-node-id',
  tempNodeId401 = '401-temp-node-id',
  tempNodeId402 = '402-temp-node-id',
  tempNodeId403 = '403-temp-node-id',
  tempNodeId404 = '404-temp-node-id',
  tempNodeId405 = '405-temp-node-id',
  tempNodeId406 = '406-temp-node-id',
  tempNodeId407 = '407-temp-node-id',
  tempNodeId408 = '408-temp-node-id',
  tempNodeId409 = '409-temp-node-id',
  tempNodeId410 = '410-temp-node-id',
  tempNodeId411 = '411-temp-node-id',
  tempNodeId412 = '412-temp-node-id',
  tempNodeId413 = '413-temp-node-id',
  tempNodeId414 = '414-temp-node-id',
  tempNodeId415 = '415-temp-node-id',
  tempNodeId416 = '416-temp-node-id',
  tempNodeId417 = '417-temp-node-id',
  tempNodeId418 = '418-temp-node-id',
  tempNodeId419 = '419-temp-node-id',
  tempNodeId420 = '420-temp-node-id',
  tempNodeId421 = '421-temp-node-id',
  tempNodeId422 = '422-temp-node-id',
  tempNodeId423 = '423-temp-node-id',
  tempNodeId424 = '424-temp-node-id',
  tempNodeId425 = '425-temp-node-id',
  tempNodeId426 = '426-temp-node-id',
  tempNodeId427 = '427-temp-node-id',
  tempNodeId428 = '428-temp-node-id',
  tempNodeId429 = '429-temp-node-id',
  tempNodeId430 = '430-temp-node-id',
  tempNodeId431 = '431-temp-node-id',
  tempNodeId432 = '432-temp-node-id',
  tempNodeId433 = '433-temp-node-id',
  tempNodeId434 = '434-temp-node-id',
  tempNodeId435 = '435-temp-node-id',
  tempNodeId436 = '436-temp-node-id',
  tempNodeId437 = '437-temp-node-id',
  tempNodeId438 = '438-temp-node-id',
  tempNodeId439 = '439-temp-node-id',
  tempNodeId440 = '440-temp-node-id',
  tempNodeId441 = '441-temp-node-id',
  tempNodeId442 = '442-temp-node-id',
  tempNodeId443 = '443-temp-node-id',
  tempNodeId444 = '444-temp-node-id',
  tempNodeId445 = '445-temp-node-id',
  tempNodeId446 = '446-temp-node-id',
  tempNodeId447 = '447-temp-node-id',
  tempNodeId448 = '448-temp-node-id',
  tempNodeId449 = '449-temp-node-id',
  tempNodeId450 = '450-temp-node-id',
  tempNodeId451 = '451-temp-node-id',
  tempNodeId452 = '452-temp-node-id',
  tempNodeId453 = '453-temp-node-id',
  tempNodeId454 = '454-temp-node-id',
  tempNodeId455 = '455-temp-node-id',
  tempNodeId456 = '456-temp-node-id',
  tempNodeId457 = '457-temp-node-id',
  tempNodeId458 = '458-temp-node-id',
  tempNodeId459 = '459-temp-node-id',
  tempNodeId460 = '460-temp-node-id',
  tempNodeId461 = '461-temp-node-id',
  tempNodeId462 = '462-temp-node-id',
  tempNodeId463 = '463-temp-node-id',
  tempNodeId464 = '464-temp-node-id',
  tempNodeId465 = '465-temp-node-id',
  tempNodeId466 = '466-temp-node-id',
  tempNodeId467 = '467-temp-node-id',
  tempNodeId468 = '468-temp-node-id',
  tempNodeId469 = '469-temp-node-id',
  tempNodeId470 = '470-temp-node-id',
  tempNodeId471 = '471-temp-node-id',
  tempNodeId472 = '472-temp-node-id',
  tempNodeId473 = '473-temp-node-id',
  tempNodeId474 = '474-temp-node-id',
  tempNodeId475 = '475-temp-node-id',
  tempNodeId476 = '476-temp-node-id',
  tempNodeId477 = '477-temp-node-id',
  tempNodeId478 = '478-temp-node-id',
  tempNodeId479 = '479-temp-node-id',
  tempNodeId480 = '480-temp-node-id',
  tempNodeId481 = '481-temp-node-id',
  tempNodeId482 = '482-temp-node-id',
  tempNodeId483 = '483-temp-node-id',
  tempNodeId484 = '484-temp-node-id',
  tempNodeId485 = '485-temp-node-id',
  tempNodeId486 = '486-temp-node-id',
  tempNodeId487 = '487-temp-node-id',
  tempNodeId488 = '488-temp-node-id',
  tempNodeId489 = '489-temp-node-id',
  tempNodeId490 = '490-temp-node-id',
  tempNodeId491 = '491-temp-node-id',
  tempNodeId492 = '492-temp-node-id',
  tempNodeId493 = '493-temp-node-id',
  tempNodeId494 = '494-temp-node-id',
  tempNodeId495 = '495-temp-node-id',
  tempNodeId496 = '496-temp-node-id',
  tempNodeId497 = '497-temp-node-id',
  tempNodeId498 = '498-temp-node-id',
  tempNodeId499 = '499-temp-node-id',
  tempNodeId500 = '500-temp-node-id',
  tempNodeId501 = '501-temp-node-id',
  tempNodeId502 = '502-temp-node-id',
  tempNodeId503 = '503-temp-node-id',
  tempNodeId504 = '504-temp-node-id',
  tempNodeId505 = '505-temp-node-id',
  tempNodeId506 = '506-temp-node-id',
  tempNodeId507 = '507-temp-node-id',
  tempNodeId508 = '508-temp-node-id',
  tempNodeId509 = '509-temp-node-id',
  tempNodeId510 = '510-temp-node-id',
  tempNodeId511 = '511-temp-node-id',
  tempNodeId512 = '512-temp-node-id',
  tempNodeId513 = '513-temp-node-id',
  tempNodeId514 = '514-temp-node-id',
  tempNodeId515 = '515-temp-node-id',
  tempNodeId516 = '516-temp-node-id',
  tempNodeId517 = '517-temp-node-id',
  tempNodeId518 = '518-temp-node-id',
  tempNodeId519 = '519-temp-node-id',
  tempNodeId520 = '520-temp-node-id',
  tempNodeId521 = '521-temp-node-id',
  tempNodeId522 = '522-temp-node-id',
  tempNodeId523 = '523-temp-node-id',
  tempNodeId524 = '524-temp-node-id',
  tempNodeId525 = '525-temp-node-id',
  tempNodeId526 = '526-temp-node-id',
  tempNodeId527 = '527-temp-node-id',
  tempNodeId528 = '528-temp-node-id',
  tempNodeId529 = '529-temp-node-id',
  tempNodeId530 = '530-temp-node-id',
  tempNodeId531 = '531-temp-node-id',
  tempNodeId532 = '532-temp-node-id',
  tempNodeId533 = '533-temp-node-id',
  tempNodeId534 = '534-temp-node-id',
  tempNodeId535 = '535-temp-node-id',
  tempNodeId536 = '536-temp-node-id',
  tempNodeId537 = '537-temp-node-id',
  tempNodeId538 = '538-temp-node-id',
  tempNodeId539 = '539-temp-node-id',
  tempNodeId540 = '540-temp-node-id',
  tempNodeId541 = '541-temp-node-id',
  tempNodeId542 = '542-temp-node-id',
  tempNodeId543 = '543-temp-node-id',
  tempNodeId544 = '544-temp-node-id',
  tempNodeId545 = '545-temp-node-id',
  tempNodeId546 = '546-temp-node-id',
  tempNodeId547 = '547-temp-node-id',
  tempNodeId548 = '548-temp-node-id',
  tempNodeId549 = '549-temp-node-id',
  tempNodeId550 = '550-temp-node-id',
  tempNodeId551 = '551-temp-node-id',
  tempNodeId552 = '552-temp-node-id',
  tempNodeId553 = '553-temp-node-id',
  tempNodeId554 = '554-temp-node-id',
  tempNodeId555 = '555-temp-node-id',
  tempNodeId556 = '556-temp-node-id',
  tempNodeId557 = '557-temp-node-id',
  tempNodeId558 = '558-temp-node-id',
  tempNodeId559 = '559-temp-node-id',
  tempNodeId560 = '560-temp-node-id',
  tempNodeId561 = '561-temp-node-id',
  tempNodeId562 = '562-temp-node-id',
  tempNodeId563 = '563-temp-node-id',
  tempNodeId564 = '564-temp-node-id',
  tempNodeId565 = '565-temp-node-id',
  tempNodeId566 = '566-temp-node-id',
  tempNodeId567 = '567-temp-node-id',
  tempNodeId568 = '568-temp-node-id',
  tempNodeId569 = '569-temp-node-id',
  tempNodeId570 = '570-temp-node-id',
  tempNodeId571 = '571-temp-node-id',
  tempNodeId572 = '572-temp-node-id',
  tempNodeId573 = '573-temp-node-id',
  tempNodeId574 = '574-temp-node-id',
  tempNodeId575 = '575-temp-node-id',
  tempNodeId576 = '576-temp-node-id',
  tempNodeId577 = '577-temp-node-id',
  tempNodeId578 = '578-temp-node-id',
  tempNodeId579 = '579-temp-node-id',
  tempNodeId580 = '580-temp-node-id',
  tempNodeId581 = '581-temp-node-id',
  tempNodeId582 = '582-temp-node-id',
  tempNodeId583 = '583-temp-node-id',
  tempNodeId584 = '584-temp-node-id',
  tempNodeId585 = '585-temp-node-id',
  tempNodeId586 = '586-temp-node-id',
  tempNodeId587 = '587-temp-node-id',
  tempNodeId588 = '588-temp-node-id',
  tempNodeId589 = '589-temp-node-id',
  tempNodeId590 = '590-temp-node-id',
  tempNodeId591 = '591-temp-node-id',
  tempNodeId592 = '592-temp-node-id',
  tempNodeId593 = '593-temp-node-id',
  tempNodeId594 = '594-temp-node-id',
  tempNodeId595 = '595-temp-node-id',
  tempNodeId596 = '596-temp-node-id',
  tempNodeId597 = '597-temp-node-id',
  tempNodeId598 = '598-temp-node-id',
  tempNodeId599 = '599-temp-node-id',
  tempNodeId600 = '600-temp-node-id',
  tempNodeId601 = '601-temp-node-id',
  tempNodeId602 = '602-temp-node-id',
  tempNodeId603 = '603-temp-node-id',
  tempNodeId604 = '604-temp-node-id',
  tempNodeId605 = '605-temp-node-id',
  tempNodeId606 = '606-temp-node-id',
  tempNodeId607 = '607-temp-node-id',
  tempNodeId608 = '608-temp-node-id',
  tempNodeId609 = '609-temp-node-id',
  tempNodeId610 = '610-temp-node-id',
  tempNodeId611 = '611-temp-node-id',
  tempNodeId612 = '612-temp-node-id',
  tempNodeId613 = '613-temp-node-id',
  tempNodeId614 = '614-temp-node-id',
  tempNodeId615 = '615-temp-node-id',
  tempNodeId616 = '616-temp-node-id',
  tempNodeId617 = '617-temp-node-id',
  tempNodeId618 = '618-temp-node-id',
  tempNodeId619 = '619-temp-node-id',
  tempNodeId620 = '620-temp-node-id',
  tempNodeId621 = '621-temp-node-id',
  tempNodeId622 = '622-temp-node-id',
  tempNodeId623 = '623-temp-node-id',
  tempNodeId624 = '624-temp-node-id',
  tempNodeId625 = '625-temp-node-id',
  tempNodeId626 = '626-temp-node-id',
  tempNodeId627 = '627-temp-node-id',
  tempNodeId628 = '628-temp-node-id',
  tempNodeId629 = '629-temp-node-id',
  tempNodeId630 = '630-temp-node-id',
  tempNodeId631 = '631-temp-node-id',
  tempNodeId632 = '632-temp-node-id',
  tempNodeId633 = '633-temp-node-id',
  tempNodeId634 = '634-temp-node-id',
  tempNodeId635 = '635-temp-node-id',
  tempNodeId636 = '636-temp-node-id',
  tempNodeId637 = '637-temp-node-id',
  tempNodeId638 = '638-temp-node-id',
  tempNodeId639 = '639-temp-node-id',
  tempNodeId640 = '640-temp-node-id',
  tempNodeId641 = '641-temp-node-id',
  tempNodeId642 = '642-temp-node-id',
  tempNodeId643 = '643-temp-node-id',
  tempNodeId644 = '644-temp-node-id',
  tempNodeId645 = '645-temp-node-id',
  tempNodeId646 = '646-temp-node-id',
  tempNodeId647 = '647-temp-node-id',
  tempNodeId648 = '648-temp-node-id',
  tempNodeId649 = '649-temp-node-id',
  tempNodeId650 = '650-temp-node-id',
  tempNodeId651 = '651-temp-node-id',
  tempNodeId652 = '652-temp-node-id',
  tempNodeId653 = '653-temp-node-id',
  tempNodeId654 = '654-temp-node-id',
  tempNodeId655 = '655-temp-node-id',
  tempNodeId656 = '656-temp-node-id',
  tempNodeId657 = '657-temp-node-id',
  tempNodeId658 = '658-temp-node-id',
  tempNodeId659 = '659-temp-node-id',
  tempNodeId660 = '660-temp-node-id',
  tempNodeId661 = '661-temp-node-id',
  tempNodeId662 = '662-temp-node-id',
  tempNodeId663 = '663-temp-node-id',
  tempNodeId664 = '664-temp-node-id',
  tempNodeId665 = '665-temp-node-id',
  tempNodeId666 = '666-temp-node-id',
  tempNodeId667 = '667-temp-node-id',
  tempNodeId668 = '668-temp-node-id',
  tempNodeId669 = '669-temp-node-id',
  tempNodeId670 = '670-temp-node-id',
  tempNodeId671 = '671-temp-node-id',
  tempNodeId672 = '672-temp-node-id',
  tempNodeId673 = '673-temp-node-id',
  tempNodeId674 = '674-temp-node-id',
  tempNodeId675 = '675-temp-node-id',
  tempNodeId676 = '676-temp-node-id',
  tempNodeId677 = '677-temp-node-id',
  tempNodeId678 = '678-temp-node-id',
  tempNodeId679 = '679-temp-node-id',
  tempNodeId680 = '680-temp-node-id',
  tempNodeId681 = '681-temp-node-id',
  tempNodeId682 = '682-temp-node-id',
  tempNodeId683 = '683-temp-node-id',
  tempNodeId684 = '684-temp-node-id',
  tempNodeId685 = '685-temp-node-id',
  tempNodeId686 = '686-temp-node-id',
  tempNodeId687 = '687-temp-node-id',
  tempNodeId688 = '688-temp-node-id',
  tempNodeId689 = '689-temp-node-id',
  tempNodeId690 = '690-temp-node-id',
  tempNodeId691 = '691-temp-node-id',
  tempNodeId692 = '692-temp-node-id',
  tempNodeId693 = '693-temp-node-id',
  tempNodeId694 = '694-temp-node-id',
  tempNodeId695 = '695-temp-node-id',
  tempNodeId696 = '696-temp-node-id',
  tempNodeId697 = '697-temp-node-id',
  tempNodeId698 = '698-temp-node-id',
  tempNodeId699 = '699-temp-node-id',
  tempNodeId700 = '700-temp-node-id',
  tempNodeId701 = '701-temp-node-id',
  tempNodeId702 = '702-temp-node-id',
  tempNodeId703 = '703-temp-node-id',
  tempNodeId704 = '704-temp-node-id',
  tempNodeId705 = '705-temp-node-id',
  tempNodeId706 = '706-temp-node-id',
  tempNodeId707 = '707-temp-node-id',
  tempNodeId708 = '708-temp-node-id',
  tempNodeId709 = '709-temp-node-id',
  tempNodeId710 = '710-temp-node-id',
  tempNodeId711 = '711-temp-node-id',
  tempNodeId712 = '712-temp-node-id',
  tempNodeId713 = '713-temp-node-id',
  tempNodeId714 = '714-temp-node-id',
  tempNodeId715 = '715-temp-node-id',
  tempNodeId716 = '716-temp-node-id',
  tempNodeId717 = '717-temp-node-id',
  tempNodeId718 = '718-temp-node-id',
  tempNodeId719 = '719-temp-node-id',
  tempNodeId720 = '720-temp-node-id',
  tempNodeId721 = '721-temp-node-id',
  tempNodeId722 = '722-temp-node-id',
  tempNodeId723 = '723-temp-node-id',
  tempNodeId724 = '724-temp-node-id',
  tempNodeId725 = '725-temp-node-id',
  tempNodeId726 = '726-temp-node-id',
  tempNodeId727 = '727-temp-node-id',
  tempNodeId728 = '728-temp-node-id',
  tempNodeId729 = '729-temp-node-id',
  tempNodeId730 = '730-temp-node-id',
  tempNodeId731 = '731-temp-node-id',
  tempNodeId732 = '732-temp-node-id',
  tempNodeId733 = '733-temp-node-id',
  tempNodeId734 = '734-temp-node-id',
  tempNodeId735 = '735-temp-node-id',
  tempNodeId736 = '736-temp-node-id',
  tempNodeId737 = '737-temp-node-id',
  tempNodeId738 = '738-temp-node-id',
  tempNodeId739 = '739-temp-node-id',
  tempNodeId740 = '740-temp-node-id',
  tempNodeId741 = '741-temp-node-id',
  tempNodeId742 = '742-temp-node-id',
  tempNodeId743 = '743-temp-node-id',
  tempNodeId744 = '744-temp-node-id',
  tempNodeId745 = '745-temp-node-id',
  tempNodeId746 = '746-temp-node-id',
  tempNodeId747 = '747-temp-node-id',
  tempNodeId748 = '748-temp-node-id',
  tempNodeId749 = '749-temp-node-id',
  tempNodeId750 = '750-temp-node-id',
  tempNodeId751 = '751-temp-node-id',
  tempNodeId752 = '752-temp-node-id',
  tempNodeId753 = '753-temp-node-id',
  tempNodeId754 = '754-temp-node-id',
  tempNodeId755 = '755-temp-node-id',
  tempNodeId756 = '756-temp-node-id',
  tempNodeId757 = '757-temp-node-id',
  tempNodeId758 = '758-temp-node-id',
  tempNodeId759 = '759-temp-node-id',
  tempNodeId760 = '760-temp-node-id',
  tempNodeId761 = '761-temp-node-id',
  tempNodeId762 = '762-temp-node-id',
  tempNodeId763 = '763-temp-node-id',
  tempNodeId764 = '764-temp-node-id',
  tempNodeId765 = '765-temp-node-id',
  tempNodeId766 = '766-temp-node-id',
  tempNodeId767 = '767-temp-node-id',
  tempNodeId768 = '768-temp-node-id',
  tempNodeId769 = '769-temp-node-id',
  tempNodeId770 = '770-temp-node-id',
  tempNodeId771 = '771-temp-node-id',
  tempNodeId772 = '772-temp-node-id',
  tempNodeId773 = '773-temp-node-id',
  tempNodeId774 = '774-temp-node-id',
  tempNodeId775 = '775-temp-node-id',
  tempNodeId776 = '776-temp-node-id',
  tempNodeId777 = '777-temp-node-id',
  tempNodeId778 = '778-temp-node-id',
  tempNodeId779 = '779-temp-node-id',
  tempNodeId780 = '780-temp-node-id',
  tempNodeId781 = '781-temp-node-id',
  tempNodeId782 = '782-temp-node-id',
  tempNodeId783 = '783-temp-node-id',
  tempNodeId784 = '784-temp-node-id',
  tempNodeId785 = '785-temp-node-id',
  tempNodeId786 = '786-temp-node-id',
  tempNodeId787 = '787-temp-node-id',
  tempNodeId788 = '788-temp-node-id',
  tempNodeId789 = '789-temp-node-id',
  tempNodeId790 = '790-temp-node-id',
  tempNodeId791 = '791-temp-node-id',
  tempNodeId792 = '792-temp-node-id',
  tempNodeId793 = '793-temp-node-id',
  tempNodeId794 = '794-temp-node-id',
  tempNodeId795 = '795-temp-node-id',
  tempNodeId796 = '796-temp-node-id',
  tempNodeId797 = '797-temp-node-id',
  tempNodeId798 = '798-temp-node-id',
  tempNodeId799 = '799-temp-node-id',
  tempNodeId800 = '800-temp-node-id',
  tempNodeId801 = '801-temp-node-id',
  tempNodeId802 = '802-temp-node-id',
  tempNodeId803 = '803-temp-node-id',
  tempNodeId804 = '804-temp-node-id',
  tempNodeId805 = '805-temp-node-id',
  tempNodeId806 = '806-temp-node-id',
  tempNodeId807 = '807-temp-node-id',
  tempNodeId808 = '808-temp-node-id',
  tempNodeId809 = '809-temp-node-id',
  tempNodeId810 = '810-temp-node-id',
  tempNodeId811 = '811-temp-node-id',
  tempNodeId812 = '812-temp-node-id',
  tempNodeId813 = '813-temp-node-id',
  tempNodeId814 = '814-temp-node-id',
  tempNodeId815 = '815-temp-node-id',
  tempNodeId816 = '816-temp-node-id',
  tempNodeId817 = '817-temp-node-id',
  tempNodeId818 = '818-temp-node-id',
  tempNodeId819 = '819-temp-node-id',
  tempNodeId820 = '820-temp-node-id',
  tempNodeId821 = '821-temp-node-id',
  tempNodeId822 = '822-temp-node-id',
  tempNodeId823 = '823-temp-node-id',
  tempNodeId824 = '824-temp-node-id',
  tempNodeId825 = '825-temp-node-id',
  tempNodeId826 = '826-temp-node-id',
  tempNodeId827 = '827-temp-node-id',
  tempNodeId828 = '828-temp-node-id',
  tempNodeId829 = '829-temp-node-id',
  tempNodeId830 = '830-temp-node-id',
  tempNodeId831 = '831-temp-node-id',
  tempNodeId832 = '832-temp-node-id',
  tempNodeId833 = '833-temp-node-id',
  tempNodeId834 = '834-temp-node-id',
  tempNodeId835 = '835-temp-node-id',
  tempNodeId836 = '836-temp-node-id',
  tempNodeId837 = '837-temp-node-id',
  tempNodeId838 = '838-temp-node-id',
  tempNodeId839 = '839-temp-node-id',
  tempNodeId840 = '840-temp-node-id',
  tempNodeId841 = '841-temp-node-id',
  tempNodeId842 = '842-temp-node-id',
  tempNodeId843 = '843-temp-node-id',
  tempNodeId844 = '844-temp-node-id',
  tempNodeId845 = '845-temp-node-id',
  tempNodeId846 = '846-temp-node-id',
  tempNodeId847 = '847-temp-node-id',
  tempNodeId848 = '848-temp-node-id',
  tempNodeId849 = '849-temp-node-id',
  tempNodeId850 = '850-temp-node-id',
  tempNodeId851 = '851-temp-node-id',
  tempNodeId852 = '852-temp-node-id',
  tempNodeId853 = '853-temp-node-id',
  tempNodeId854 = '854-temp-node-id',
  tempNodeId855 = '855-temp-node-id',
  tempNodeId856 = '856-temp-node-id',
  tempNodeId857 = '857-temp-node-id',
  tempNodeId858 = '858-temp-node-id',
  tempNodeId859 = '859-temp-node-id',
  tempNodeId860 = '860-temp-node-id',
  tempNodeId861 = '861-temp-node-id',
  tempNodeId862 = '862-temp-node-id',
  tempNodeId863 = '863-temp-node-id',
  tempNodeId864 = '864-temp-node-id',
  tempNodeId865 = '865-temp-node-id',
  tempNodeId866 = '866-temp-node-id',
  tempNodeId867 = '867-temp-node-id',
  tempNodeId868 = '868-temp-node-id',
  tempNodeId869 = '869-temp-node-id',
  tempNodeId870 = '870-temp-node-id',
  tempNodeId871 = '871-temp-node-id',
  tempNodeId872 = '872-temp-node-id',
  tempNodeId873 = '873-temp-node-id',
  tempNodeId874 = '874-temp-node-id',
  tempNodeId875 = '875-temp-node-id',
  tempNodeId876 = '876-temp-node-id',
  tempNodeId877 = '877-temp-node-id',
  tempNodeId878 = '878-temp-node-id',
  tempNodeId879 = '879-temp-node-id',
  tempNodeId880 = '880-temp-node-id',
  tempNodeId881 = '881-temp-node-id',
  tempNodeId882 = '882-temp-node-id',
  tempNodeId883 = '883-temp-node-id',
  tempNodeId884 = '884-temp-node-id',
  tempNodeId885 = '885-temp-node-id',
  tempNodeId886 = '886-temp-node-id',
  tempNodeId887 = '887-temp-node-id',
  tempNodeId888 = '888-temp-node-id',
  tempNodeId889 = '889-temp-node-id',
  tempNodeId890 = '890-temp-node-id',
  tempNodeId891 = '891-temp-node-id',
  tempNodeId892 = '892-temp-node-id',
  tempNodeId893 = '893-temp-node-id',
  tempNodeId894 = '894-temp-node-id',
  tempNodeId895 = '895-temp-node-id',
  tempNodeId896 = '896-temp-node-id',
  tempNodeId897 = '897-temp-node-id',
  tempNodeId898 = '898-temp-node-id',
  tempNodeId899 = '899-temp-node-id',
  tempNodeId900 = '900-temp-node-id',
  tempNodeId901 = '901-temp-node-id',
  tempNodeId902 = '902-temp-node-id',
  tempNodeId903 = '903-temp-node-id',
  tempNodeId904 = '904-temp-node-id',
  tempNodeId905 = '905-temp-node-id',
  tempNodeId906 = '906-temp-node-id',
  tempNodeId907 = '907-temp-node-id',
  tempNodeId908 = '908-temp-node-id',
  tempNodeId909 = '909-temp-node-id',
  tempNodeId910 = '910-temp-node-id',
  tempNodeId911 = '911-temp-node-id',
  tempNodeId912 = '912-temp-node-id',
  tempNodeId913 = '913-temp-node-id',
  tempNodeId914 = '914-temp-node-id',
  tempNodeId915 = '915-temp-node-id',
  tempNodeId916 = '916-temp-node-id',
  tempNodeId917 = '917-temp-node-id',
  tempNodeId918 = '918-temp-node-id',
  tempNodeId919 = '919-temp-node-id',
  tempNodeId920 = '920-temp-node-id',
  tempNodeId921 = '921-temp-node-id',
  tempNodeId922 = '922-temp-node-id',
  tempNodeId923 = '923-temp-node-id',
  tempNodeId924 = '924-temp-node-id',
  tempNodeId925 = '925-temp-node-id',
  tempNodeId926 = '926-temp-node-id',
  tempNodeId927 = '927-temp-node-id',
  tempNodeId928 = '928-temp-node-id',
  tempNodeId929 = '929-temp-node-id',
  tempNodeId930 = '930-temp-node-id',
  tempNodeId931 = '931-temp-node-id',
  tempNodeId932 = '932-temp-node-id',
  tempNodeId933 = '933-temp-node-id',
  tempNodeId934 = '934-temp-node-id',
  tempNodeId935 = '935-temp-node-id',
  tempNodeId936 = '936-temp-node-id',
  tempNodeId937 = '937-temp-node-id',
  tempNodeId938 = '938-temp-node-id',
  tempNodeId939 = '939-temp-node-id',
  tempNodeId940 = '940-temp-node-id',
  tempNodeId941 = '941-temp-node-id',
  tempNodeId942 = '942-temp-node-id',
  tempNodeId943 = '943-temp-node-id',
  tempNodeId944 = '944-temp-node-id',
  tempNodeId945 = '945-temp-node-id',
  tempNodeId946 = '946-temp-node-id',
  tempNodeId947 = '947-temp-node-id',
  tempNodeId948 = '948-temp-node-id',
  tempNodeId949 = '949-temp-node-id',
  tempNodeId950 = '950-temp-node-id',
  tempNodeId951 = '951-temp-node-id',
  tempNodeId952 = '952-temp-node-id',
  tempNodeId953 = '953-temp-node-id',
  tempNodeId954 = '954-temp-node-id',
  tempNodeId955 = '955-temp-node-id',
  tempNodeId956 = '956-temp-node-id',
  tempNodeId957 = '957-temp-node-id',
  tempNodeId958 = '958-temp-node-id',
  tempNodeId959 = '959-temp-node-id',
  tempNodeId960 = '960-temp-node-id',
  tempNodeId961 = '961-temp-node-id',
  tempNodeId962 = '962-temp-node-id',
  tempNodeId963 = '963-temp-node-id',
  tempNodeId964 = '964-temp-node-id',
  tempNodeId965 = '965-temp-node-id',
  tempNodeId966 = '966-temp-node-id',
  tempNodeId967 = '967-temp-node-id',
  tempNodeId968 = '968-temp-node-id',
  tempNodeId969 = '969-temp-node-id',
  tempNodeId970 = '970-temp-node-id',
  tempNodeId971 = '971-temp-node-id',
  tempNodeId972 = '972-temp-node-id',
  tempNodeId973 = '973-temp-node-id',
  tempNodeId974 = '974-temp-node-id',
  tempNodeId975 = '975-temp-node-id',
  tempNodeId976 = '976-temp-node-id',
  tempNodeId977 = '977-temp-node-id',
  tempNodeId978 = '978-temp-node-id',
  tempNodeId979 = '979-temp-node-id',
  tempNodeId980 = '980-temp-node-id',
  tempNodeId981 = '981-temp-node-id',
  tempNodeId982 = '982-temp-node-id',
  tempNodeId983 = '983-temp-node-id',
  tempNodeId984 = '984-temp-node-id',
  tempNodeId985 = '985-temp-node-id',
  tempNodeId986 = '986-temp-node-id',
  tempNodeId987 = '987-temp-node-id',
  tempNodeId988 = '988-temp-node-id',
  tempNodeId989 = '989-temp-node-id',
  tempNodeId990 = '990-temp-node-id',
  tempNodeId991 = '991-temp-node-id',
  tempNodeId992 = '992-temp-node-id',
  tempNodeId993 = '993-temp-node-id',
  tempNodeId994 = '994-temp-node-id',
  tempNodeId995 = '995-temp-node-id',
  tempNodeId996 = '996-temp-node-id',
  tempNodeId997 = '997-temp-node-id',
  tempNodeId998 = '998-temp-node-id',
  tempNodeId999 = '999-temp-node-id',
  tempNodeId1000 = '1000-temp-node-id',
  tempNodeId1001 = '1001-temp-node-id',
  tempNodeId1002 = '1002-temp-node-id',
  tempNodeId1003 = '1003-temp-node-id',
  tempNodeId1004 = '1004-temp-node-id',
  tempNodeId1005 = '1005-temp-node-id',
  tempNodeId1006 = '1006-temp-node-id',
  tempNodeId1007 = '1007-temp-node-id',
  tempNodeId1008 = '1008-temp-node-id',
  tempNodeId1009 = '1009-temp-node-id',
  tempNodeId1010 = '1010-temp-node-id',
  tempNodeId1011 = '1011-temp-node-id',
  tempNodeId1012 = '1012-temp-node-id',
  tempNodeId1013 = '1013-temp-node-id',
  tempNodeId1014 = '1014-temp-node-id',
  tempNodeId1015 = '1015-temp-node-id',
  tempNodeId1016 = '1016-temp-node-id',
  tempNodeId1017 = '1017-temp-node-id',
  tempNodeId1018 = '1018-temp-node-id',
  tempNodeId1019 = '1019-temp-node-id',
  tempNodeId1020 = '1020-temp-node-id',
  tempNodeId1021 = '1021-temp-node-id',
  tempNodeId1022 = '1022-temp-node-id',
  tempNodeId1023 = '1023-temp-node-id',
  tempNodeId1024 = '1024-temp-node-id',
  tempNodeId1025 = '1025-temp-node-id',
  tempNodeId1026 = '1026-temp-node-id',
  tempNodeId1027 = '1027-temp-node-id',
  tempNodeId1028 = '1028-temp-node-id',
  tempNodeId1029 = '1029-temp-node-id',
  tempNodeId1030 = '1030-temp-node-id',
  tempNodeId1031 = '1031-temp-node-id',
  tempNodeId1032 = '1032-temp-node-id',
  tempNodeId1033 = '1033-temp-node-id',
  tempNodeId1034 = '1034-temp-node-id',
  tempNodeId1035 = '1035-temp-node-id',
  tempNodeId1036 = '1036-temp-node-id',
  tempNodeId1037 = '1037-temp-node-id',
  tempNodeId1038 = '1038-temp-node-id',
  tempNodeId1039 = '1039-temp-node-id',
  tempNodeId1040 = '1040-temp-node-id',
  tempNodeId1041 = '1041-temp-node-id',
  tempNodeId1042 = '1042-temp-node-id',
  tempNodeId1043 = '1043-temp-node-id',
  tempNodeId1044 = '1044-temp-node-id',
  tempNodeId1045 = '1045-temp-node-id',
  tempNodeId1046 = '1046-temp-node-id',
  tempNodeId1047 = '1047-temp-node-id',
  tempNodeId1048 = '1048-temp-node-id',
  tempNodeId1049 = '1049-temp-node-id',
  tempNodeId1050 = '1050-temp-node-id',
  tempNodeId1051 = '1051-temp-node-id',
  tempNodeId1052 = '1052-temp-node-id',
  tempNodeId1053 = '1053-temp-node-id',
  tempNodeId1054 = '1054-temp-node-id',
  tempNodeId1055 = '1055-temp-node-id',
  tempNodeId1056 = '1056-temp-node-id',
  tempNodeId1057 = '1057-temp-node-id',
  tempNodeId1058 = '1058-temp-node-id',
  tempNodeId1059 = '1059-temp-node-id',
  tempNodeId1060 = '1060-temp-node-id',
  tempNodeId1061 = '1061-temp-node-id',
  tempNodeId1062 = '1062-temp-node-id',
  tempNodeId1063 = '1063-temp-node-id',
  tempNodeId1064 = '1064-temp-node-id',
  tempNodeId1065 = '1065-temp-node-id',
  tempNodeId1066 = '1066-temp-node-id',
  tempNodeId1067 = '1067-temp-node-id',
  tempNodeId1068 = '1068-temp-node-id',
  tempNodeId1069 = '1069-temp-node-id',
  tempNodeId1070 = '1070-temp-node-id',
  tempNodeId1071 = '1071-temp-node-id',
  tempNodeId1072 = '1072-temp-node-id',
  tempNodeId1073 = '1073-temp-node-id',
  tempNodeId1074 = '1074-temp-node-id',
  tempNodeId1075 = '1075-temp-node-id',
  tempNodeId1076 = '1076-temp-node-id',
  tempNodeId1077 = '1077-temp-node-id',
  tempNodeId1078 = '1078-temp-node-id',
  tempNodeId1079 = '1079-temp-node-id',
  tempNodeId1080 = '1080-temp-node-id',
  tempNodeId1081 = '1081-temp-node-id',
  tempNodeId1082 = '1082-temp-node-id',
  tempNodeId1083 = '1083-temp-node-id',
  tempNodeId1084 = '1084-temp-node-id',
  tempNodeId1085 = '1085-temp-node-id',
  tempNodeId1086 = '1086-temp-node-id',
  tempNodeId1087 = '1087-temp-node-id',
  tempNodeId1088 = '1088-temp-node-id',
  tempNodeId1089 = '1089-temp-node-id',
  tempNodeId1090 = '1090-temp-node-id',
  tempNodeId1091 = '1091-temp-node-id',
  tempNodeId1092 = '1092-temp-node-id',
  tempNodeId1093 = '1093-temp-node-id',
  tempNodeId1094 = '1094-temp-node-id',
  tempNodeId1095 = '1095-temp-node-id',
  tempNodeId1096 = '1096-temp-node-id',
  tempNodeId1097 = '1097-temp-node-id',
  tempNodeId1098 = '1098-temp-node-id',
  tempNodeId1099 = '1099-temp-node-id',
  tempNodeId1100 = '1100-temp-node-id',
  tempNodeId1101 = '1101-temp-node-id',
  tempNodeId1102 = '1102-temp-node-id',
  tempNodeId1103 = '1103-temp-node-id',
  tempNodeId1104 = '1104-temp-node-id',
  tempNodeId1105 = '1105-temp-node-id',
  tempNodeId1106 = '1106-temp-node-id',
  tempNodeId1107 = '1107-temp-node-id',
  tempNodeId1108 = '1108-temp-node-id',
  tempNodeId1109 = '1109-temp-node-id',
  tempNodeId1110 = '1110-temp-node-id',
  tempNodeId1111 = '1111-temp-node-id',
  tempNodeId1112 = '1112-temp-node-id',
  tempNodeId1113 = '1113-temp-node-id',
  tempNodeId1114 = '1114-temp-node-id',
  tempNodeId1115 = '1115-temp-node-id',
  tempNodeId1116 = '1116-temp-node-id',
  tempNodeId1117 = '1117-temp-node-id',
  tempNodeId1118 = '1118-temp-node-id',
  tempNodeId1119 = '1119-temp-node-id',
  tempNodeId1120 = '1120-temp-node-id',
  tempNodeId1121 = '1121-temp-node-id',
  tempNodeId1122 = '1122-temp-node-id',
  tempNodeId1123 = '1123-temp-node-id',
  tempNodeId1124 = '1124-temp-node-id',
  tempNodeId1125 = '1125-temp-node-id',
  tempNodeId1126 = '1126-temp-node-id',
  tempNodeId1127 = '1127-temp-node-id',
  tempNodeId1128 = '1128-temp-node-id',
  tempNodeId1129 = '1129-temp-node-id',
  tempNodeId1130 = '1130-temp-node-id',
  tempNodeId1131 = '1131-temp-node-id',
  tempNodeId1132 = '1132-temp-node-id',
  tempNodeId1133 = '1133-temp-node-id',
  tempNodeId1134 = '1134-temp-node-id',
  tempNodeId1135 = '1135-temp-node-id',
  tempNodeId1136 = '1136-temp-node-id',
  tempNodeId1137 = '1137-temp-node-id',
  tempNodeId1138 = '1138-temp-node-id',
  tempNodeId1139 = '1139-temp-node-id',
  tempNodeId1140 = '1140-temp-node-id',
  tempNodeId1141 = '1141-temp-node-id',
  tempNodeId1142 = '1142-temp-node-id',
  tempNodeId1143 = '1143-temp-node-id',
  tempNodeId1144 = '1144-temp-node-id',
  tempNodeId1145 = '1145-temp-node-id',
  tempNodeId1146 = '1146-temp-node-id',
  tempNodeId1147 = '1147-temp-node-id',
  tempNodeId1148 = '1148-temp-node-id',
  tempNodeId1149 = '1149-temp-node-id',
  tempNodeId1150 = '1150-temp-node-id',
  tempNodeId1151 = '1151-temp-node-id',
  tempNodeId1152 = '1152-temp-node-id',
  tempNodeId1153 = '1153-temp-node-id',
  tempNodeId1154 = '1154-temp-node-id',
  tempNodeId1155 = '1155-temp-node-id',
  tempNodeId1156 = '1156-temp-node-id',
  tempNodeId1157 = '1157-temp-node-id',
  tempNodeId1158 = '1158-temp-node-id',
  tempNodeId1159 = '1159-temp-node-id',
  tempNodeId1160 = '1160-temp-node-id',
  tempNodeId1161 = '1161-temp-node-id',
  tempNodeId1162 = '1162-temp-node-id',
  tempNodeId1163 = '1163-temp-node-id',
  tempNodeId1164 = '1164-temp-node-id',
  tempNodeId1165 = '1165-temp-node-id',
  tempNodeId1166 = '1166-temp-node-id',
  tempNodeId1167 = '1167-temp-node-id',
  tempNodeId1168 = '1168-temp-node-id',
  tempNodeId1169 = '1169-temp-node-id',
  tempNodeId1170 = '1170-temp-node-id',
  tempNodeId1171 = '1171-temp-node-id',
  tempNodeId1172 = '1172-temp-node-id',
  tempNodeId1173 = '1173-temp-node-id',
  tempNodeId1174 = '1174-temp-node-id',
  tempNodeId1175 = '1175-temp-node-id',
  tempNodeId1176 = '1176-temp-node-id',
  tempNodeId1177 = '1177-temp-node-id',
  tempNodeId1178 = '1178-temp-node-id',
  tempNodeId1179 = '1179-temp-node-id',
  tempNodeId1180 = '1180-temp-node-id',
  tempNodeId1181 = '1181-temp-node-id',
  tempNodeId1182 = '1182-temp-node-id',
  tempNodeId1183 = '1183-temp-node-id',
  tempNodeId1184 = '1184-temp-node-id',
  tempNodeId1185 = '1185-temp-node-id',
  tempNodeId1186 = '1186-temp-node-id',
  tempNodeId1187 = '1187-temp-node-id',
  tempNodeId1188 = '1188-temp-node-id',
  tempNodeId1189 = '1189-temp-node-id',
  tempNodeId1190 = '1190-temp-node-id',
  tempNodeId1191 = '1191-temp-node-id',
  tempNodeId1192 = '1192-temp-node-id',
  tempNodeId1193 = '1193-temp-node-id',
  tempNodeId1194 = '1194-temp-node-id',
  tempNodeId1195 = '1195-temp-node-id',
  tempNodeId1196 = '1196-temp-node-id',
  tempNodeId1197 = '1197-temp-node-id',
  tempNodeId1198 = '1198-temp-node-id',
  tempNodeId1199 = '1199-temp-node-id',
  tempNodeId1200 = '1200-temp-node-id',
}
